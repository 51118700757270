//轨迹查询
import React, { Component } from 'react'
import './trackQuery.css'
import {
    findObjTrackMulti,
    selectRangeQuery,
    getLayerInfo,
} from '../../data/dataStore'
import { DatePicker, Input, TreeSelect, Tree, Select, Icon, Slider, Switch, Button, Timeline, message, Form, Spin, Tooltip, Table, Row, Col } from 'antd';
import moment from 'moment';
import config_JSON from "./default_JSON/Default_map.json";
import MyComponentFactory from './Toolkit/MyComponentFactoryPackage/MyComponentFactory';
import ComponentWorkspace from './Toolkit/MyComponentFactoryPackage/ComponentWorkspace';
const { TreeNode, DirectoryTree } = Tree;
const { Option } = Select;
const { RangePicker } = DatePicker;
const maths = {
    'mapSwitch': "",
    'mapSwitchLight': "",
    'tool': "",
    'toolLight': "",
    'ellipse': "",
    'rectangle': "",
    'range': "",
    'polygon': "",
    'area': "",
    'play': "",
    'suspend': "",
}
Object.keys(maths).forEach(item => maths[item] = require("../../assets/img/" + item + ".png"))
// let newArr=[]
let colors = [
    '#0066CC',
    '#FF7F00',
    '#42C9D3',
    '#660066',
    '#009900',
    '#F9E103',
];
class TrackQuery extends ComponentWorkspace {
    constructor(props) {
        super(props)
        this.state = {
            visible: true,
            mapArr: [],
            text_Measure_DropdownButton: '测量工具',
            text_Measure_DropdownButton_search: '搜索工具',
            menuShow: true,//控制树形的显示和隐藏
            ulshow: false,//控制搜索到的显示和隐藏
            name: '',
            toolShow: false,
            switchShow: false,
            pointShow: false,
            portShow: false,
            locusShow: false,
            areaShow: false,
            toolArr: [
                // { img: maths.port, act: maths.portLight, name: '停靠点监控', click: false, key: 'port' },
                // { img: maths.point, act: maths.pointLight, name: '重点区域管理', click: false, key: 'point' },
                { img: maths.mapSwitch, act: maths.mapSwitchLight, name: '地图开关', click: false, key: 'mapSwitch' },
                { img: maths.tool, act: maths.toolLight, name: '工具箱', click: false, key: 'tool' },
            ],
            switchArr: [
                { title: '地图信息' },
                // { name: '卫星遥感图', key: 'weixing', optionArr: [{ name: '遥感图-2017', key: 2017 }, { name: '遥感图-2018', key: 2018 }, { name: '遥感图-2019', key: 2019 }] },
                {
                    name: '影像底图', key: 'menu', defaultChecked: false,
                    optionArr: [
                        { name: '遥感影像底图', key: 'default_WMTS_tdRemoteSensing', target: 'default_WMTS_tdRemoteSensing', defaultChecked: false }
                    ]
                },
                // { name: '船舶信息图层', key: 'shipInfo_overlay', target: 'shipInfo_overlay', defaultChecked: false ,isOverlay:true},
                // { name: '航拍图', key: 'hangpai', optionArr: [{ name: '航拍图-2017', key: 'hp2017' }, { name: '航拍图-2018', key: 'hp2018' }, { name: '航拍图-2019', key: 'hp2019' },] },
                // { title: '图层信息' },
                // { name: '船舶图层', key: 'chuanbo', defaultChecked: true },
                // { name: '摄像头图层', key: 'layers_queryAllCamera', target: 'layers_queryAllCamera', defaultChecked: true },
                { name: '停靠点图层', key: 'target_mapLayer_Polygon_gangkou', defaultChecked: false },
                { name: '重点区域图层', key: 'target_mapLayer_Polygon_pointArea', defaultChecked: false },
                { name: '电子围栏图层', key: 'target_mapLayer_Polygon_dianzi', defaultChecked: false },
                // { name: '停靠点图层', key: 'target_mapLayer_Polygon_gangkou', optionArr: [{ name: '全选7个', key: '' }, { name: '第一个', key: '1' }, { name: '第2个', key: '2' },] },
                // { name: '重点区域图层', key: 'target_mapLayer_Polygon_pointArea', optionArr: [{ name: '全部停靠点', key: '' }, { name: '吴中港', key: 'wz' }, { name: '虎口', key: 'hk' },] },
                // { name: '电子围栏图层', key: 'target_mapLayer_Polygon_dianzi', optionArr: [{ name: '重点区域', key: '' }, { name: '不知道', key: 'dont' }, { name: '无所谓', key: 'noway' },] },
            ],
            dynamic: false,//控制船舶动态
            player: true,
            playing: false,
            fast: false,
            back: false,
            inputvalue: 0,//控制进度条的进度
            maxnum: 100,//滑动条的最大数字
            autoTime: 1000,//控制播放条的快进快退
            viewSearchValue: "",//搜索框的value
            value_DatePicker: [moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')],
            startTime: moment().format('YYYY-MM-DD 00:00:00'),//默认开始时间是今天零点
            endTime: moment().format('YYYY-MM-DD 23:59:59'),//默认开始时间是今天23点
            loadingStatus: {
                'selectRangeQuery': false,
                'findObjTrackMulti': false,
            },
            value_Select_shipDynamic: { key: '', label: '' },
            doubleSpeedNum: 1,
            backgroundColors_shipDynamic: [
                '#0066CC',
                'white',
                'white',
                'white',
                'white',
                'white',
            ],
            colors_shipDynamic: [
                'white',
                '#FF7F00',
                '#42C9D3',
                '#660066',
                '#009900',
                '#F9E103',
            ],
            visible_Tooltip_searchList: false,
            text_Tooltip_searchList: '查询船舶轨迹',
            cardNumTypeData: {},
            cardNumData: {},
            isReset: false,
            value_TreeSelect_shipName: this.props.default_shipData ? [this.props.default_shipData.objectId] : [],
            value_TreeSelect_shipName_isReset: [],
        }
        this.developerModePowerLibrary = {
            'developerMode_SelectShipCard': 0,
        };//开发者模式权限库
        this.noNeedRemoveList = [];//不需要清除的图层名
    }

    addLoadingStatus = (key) => {
        let loadingStatus = this.state.loadingStatus;
        loadingStatus = {
            ...loadingStatus,
            [key]: true,
        }
        this.setState({
            loadingStatus: loadingStatus
        })
    }

    closeLoadingStatus = (key) => {
        let loadingStatus = {
            ...this.state.loadingStatus,
            [key]: false,
        }
        this.setState({
            loadingStatus: loadingStatus
        })
    }

    clear_developerModePowerLibrary = () => {
        let developerModePowerLibraryKeys = Object.keys(this.developerModePowerLibrary);
        if (developerModePowerLibraryKeys) {
            developerModePowerLibraryKeys.map((item, index) => {
                this.developerModePowerLibrary[item] = 0;
            })
        }

    }
    componentWillUnmount() {
        this.clear_developerModePowerLibrary();
    }

    componentDidMount() {
        this.getLayerInfo();
        let treeData = this.handleTreeData(this.props.treeData)
        // let cardNumTypeData = this.getDictData('card_num_type');
        // let cardNumData = cardNumTypeData;
        let value_TreeSelect_shipName = this.state.value_TreeSelect_shipName;
        this.setState({
            treeData,
            // cardNumTypeData,
            // cardNumData,
        }, () => {
            if (this.props.default_shipData && Object.keys(this.props.default_shipData).length > 0) {
                this.changeSelect([this.props.default_shipData.unique + ',' + this.props.default_shipData.objectId], [this.props.default_shipData.shipName]);
            }

        })
        console.log(treeData, 'treeData')
        this.draw_defaultLayers();
    }
    //根据图层数据接口的数据，重新装配地图配置文件
    assembling_map_defaultJSON = (data) => {
        let layers = {}
        if (this.state.config_JSON) {
            let attribute = config_JSON.map_componentConfig.attribute;
            layers = attribute.layers;
        }
        if (typeof (layers) == 'undefined' && data.length > 0) {
            layers = {
                'wms': []
            };
        }
        else if (typeof (layers.wms) == 'undefined') {
            layers = {
                ...layers,
                'wms': []
            };
        }
        data.map((item, index) => {
            item.children.map((itemC, indexC) => {
                layers.wms.push({
                    "optionalParams": {
                        "visible": false,
                        "zIndex": "13"
                    },
                    "target": itemC.target,
                    "layerName": itemC.layerName,
                    "url": itemC.layerUrl
                })
            })
        });
        this.state.componentRepository.map.addWMSLayer(layers);
    }
    //根据图层数据接口的数据，重新装配图层数组switchArr
    assembling_layerInfoArr = (data) => {
        let switchArr = this.state.switchArr;
        data.map((item, index) => {
            let layerMenu = {
                'name': item.name,
                'key': item.unique + 'trackQuery',
                'defaultChecked': false,
                'optionArr': [],
            }
            if (item.children && item.children.length > 0) {
                item.children.map((itemC, indexC) => {
                    let layer = {
                        'name': itemC.layerTitle,
                        'key': itemC.target,
                        'target': itemC.target,
                        'defaultChecked': false,
                    }
                    layerMenu.optionArr.push(layer);
                })
            }
            switchArr.splice(1, 0, layerMenu);
        })
        if (switchArr.length != this.state.switchArr.length) {
            this.setState({
                switchArr
            })
        }
    }
    // 图层资源（遥感和航拍）
    async getLayerInfo() {
        let params = {
        }
        let data = await getLayerInfo(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                data_layersInfo: data.rows,
            }, () => {
                this.assembling_map_defaultJSON(this.state.data_layersInfo);
                this.assembling_layerInfoArr(this.state.data_layersInfo);
            })
        } else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    draw_defaultLayers = () => {
        let { pointData, portData, limitAreaData } = this.props.defaultDataToChild;
        let params = {
            target: 'target_mapLayer_Polygon_pointArea',
            zIndex: 14,
            color: 'rgba(255,127,0,0.5)',
            visible: false,
            isChangeZoomAndCenter: false,
        }
        this.noNeedRemoveList = [
            ...this.noNeedRemoveList,
            params.target
        ]
        this.state.componentRepository.map.showMap_DBData(pointData, params);
        params = {
            target: 'target_mapLayer_Polygon_gangkou',
            zIndex: 14,
            color: 'rgba(0,153,0,0.5)',
            visible: false,
            isChangeZoomAndCenter: false,
        }
        this.noNeedRemoveList = [
            ...this.noNeedRemoveList,
            params.target
        ]
        this.state.componentRepository.map.showMap_DBData(portData, params);
        params = {
            target: 'target_mapLayer_Polygon_dianzi',
            zIndex: 14,
            color: 'rgba(249,225,3,0.5)',
            visible: false,
            isChangeZoomAndCenter: false,
        }
        this.noNeedRemoveList = [
            ...this.noNeedRemoveList,
            params.target
        ]
        this.state.componentRepository.map.showMap_DBData(limitAreaData, params);
    }

    componentWillUnmount() {

    }
    // 工具条的控制
    clickTool = (index, key) => {
        let arr = this.state.toolArr;

        switch (key) {
            case 'locus':
                this.setState({
                    locusShow: !this.state.locusShow,
                    toolShow: false,
                    switchShow: false,
                    pointShow: false,
                    portShow: false,
                    default_shipData: undefined
                }, () => {
                    if (!this.state.locusShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'locus_detail':
                this.setState({
                    locusShow: !this.state.locusShow,
                    toolShow: false,
                    switchShow: false,
                    pointShow: false,
                    portShow: false,
                    default_shipData: this.state.shipData
                }, () => {
                    if (!this.state.locusShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'port':
                this.setState({
                    portShow: !this.state.portShow,
                    toolShow: false,
                    switchShow: false,
                    pointShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.portShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'point':
                this.setState({
                    pointShow: !this.state.pointShow,
                    toolShow: false,
                    switchShow: false,
                    portShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.pointShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'mapSwitch':
                this.setState({
                    switchShow: !this.state.switchShow,
                    toolShow: false,
                    pointShow: false,
                    portShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.switchShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'tool':
                this.setState({
                    toolShow: !this.state.toolShow,
                    switchShow: false,
                    pointShow: false,
                    portShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.toolShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
        }
        for (let i in arr) {
            arr[i].click = false;
            arr[index].click = true
        }
        this.setState({
            toolArr: arr
        })
    }
    showDrawer = () => {
        this.setState({
            visible: !this.state.visible
        })
    }
    haveTime = (a, value) => {
        let startTime = value[0];
        let endTime = value[1];
        // endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
        this.setState({
            startTime,
            endTime,
            value_DatePicker: [startTime, endTime],
        })
        console.log(a, value, 'haveTime')
    }
    showPlayer = () => {
        this.setState({
            player: !this.state.player
        })
    }
    playSlider = (type) => {
        let { strs, trackLine } = this.state;
        if (strs && strs.length > 0 && Object.keys(trackLine) && Object.keys(trackLine).length > 0) {
            console.log(this.state.autoTime, 'autoTime')
            switch (type) {
                case 1:
                    this.setState({
                        back: true,
                        fast: false,
                        autoTime: Math.floor(this.state.autoTime + 200)
                    }, () => {
                    })
                        ; break;
                case 2:
                    if (this.state.playing) {
                        clearInterval(this.auto)
                        this.setState({
                            playing: !this.state.playing,
                            back: false,
                            fast: false,
                            isRunning_trajectory: false,
                        })
                        this.state.componentRepository.map.stop_trajectory_points();
                    } else {
                        this.auto = setInterval(() => {
                            let num = this.state.inputvalue + 1;
                            this.setState({
                                //time: timeArr[num],//根据经度条的点获取相对应下标的时间
                                inputvalue: num
                            }, () => {
                                // this.changeData(this.state.time)
                                if (this.state.inputvalue >= this.state.maxnum) {
                                    this.setState({
                                        inputvalue: this.state.maxnum
                                    })
                                    clearInterval(this.auto)
                                }
                                // console.log(this.state.inputvalue, this.state.maxnum, 'auto')
                            })
                        }, this.state.autoTime)
                        // this.child.clickPlay()
                        console.log(this.state.trackLine, '------------------trackLine-----------')
                        this.state.componentRepository.map.start_trajectory_points();
                        this.setState({
                            playing: !this.state.playing,
                            back: false,
                            fast: false,
                            isRunning_trajectory: true,
                        }, () => {

                        })
                    }
                    ; break;
                case 3:
                    if (this.state.autoTime > 0) {
                        this.setState({
                            fast: true,
                            back: false,
                            autoTime: Math.floor(this.state.autoTime - 200)
                        })
                    }
                    ; break;
            }
        } else {
            //.error('至少查询一条船舶信息 或者 当前查询船舶没有信息！！！')
        }
    }
    changeValue = (value) => {
        console.log(value, 'value')
        // const { allSiteData } = this.state
        // let timeArr = allSiteData.timeAxis
        this.setState({
            // time: timeArr[value],
            inputvalue: value
        }, () => {
            // this.changeData(this.state.time)
        })
    }
    //处理树选择框的数据
    handleTreeData = (treeData) => {
        if (treeData) {

            let newArr = []
            treeData.map((item) => {
                let obj = {
                    id: item.unique,
                    key: item.unique,
                    // selectable: item.objectName ? true : false,
                    disableCheckbox: item.objectName ? false : true,
                    title: item.name ? item.name : item.objectName,
                    // value: item.id ? item.nodeType ? 'node_' + String(item.id) : item.id : item.unique,
                    value: item.unique + ',' + item.id,
                    objectName: item.objectName,
                    // value: {
                    //     objectId:item.id ? item.id : item.unique,
                    //     cardNumType:item.cardNumType,
                    // }
                }
                if (item.objectName == '苏苏渔40680') {
                    console.log(obj, '----------------------苏苏渔40680----------------')
                }
                let keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        if (keyArr[j] != 'deviceVOList') {
                            let child = this.handleTreeData(item[keyArr[j]])
                            obj.children = child
                            obj.selectable = false
                        } else {
                            obj.deviceVOList = item[keyArr[j]];
                        }
                    }
                }
                newArr.push(obj)
            })
            return newArr
        }

    }
    qureHandleSearch = (e) => {
        // this.setState({
        //     viewSearchValue:e.target.value
        // })
    }
    forbiddenSelectForTreeData = (data, strs) => {
        if (data) {
            let newArr = []
            data.map((item) => {
                let obj = {
                    ...item,
                }
                let isSame = false;
                strs.map((itemS, index) => {
                    if (itemS.node.id == obj.id) {
                        isSame = true;
                    }
                })
                if (obj.objectName && strs.length < 6) {
                    isSame = true;
                }
                obj.disableCheckbox = obj.objectName && isSame ? false : true;
                let keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        if (keyArr[j] != 'deviceVOList') {
                            let child = this.forbiddenSelectForTreeData(item[keyArr[j]], strs)
                            obj.children = child
                            obj.selectable = false
                        } else {
                            obj.deviceVOList = item[keyArr[j]];
                        }
                    }
                }
                newArr.push(obj)
            })
            return newArr
        }
    }
    //获取树选择选中的所有value值，包含deviceId-deviceType
    changeSelect = (value, text, extra) => {
        console.log(value, text, extra, '------value,text,strs--------------changeSelect-------------------')
        let selectArr = [];
        let strs = [];
        for (let i = 0; i < value.length; i++) {
            let paramsStrs = {};
            let paramsSelectArr = {};
            if (value && value[i]) {
                let valueUUID = value[i].split(',')[0];
                let valueID = value[i].split(',')[1];
                paramsSelectArr = {
                    ...paramsSelectArr,
                    value: valueID,

                }
                paramsStrs = {
                    ...paramsStrs,
                    value: valueID,

                }
            }
            if (text && text[i]) {
                paramsSelectArr = {
                    ...paramsSelectArr,
                    text: text[i],

                }
                paramsStrs = {
                    ...paramsStrs,
                    text: text[i]
                }
            }
            if (extra && extra.allCheckedNodes && extra.allCheckedNodes[i]) {
                let node = extra.allCheckedNodes[i].node ? extra.allCheckedNodes[i].node.props : extra.allCheckedNodes[i].props ? extra.allCheckedNodes[i].props : undefined;
                paramsStrs = {
                    ...paramsStrs,
                    node: node,
                }
            }
            selectArr = [
                ...selectArr,
                paramsSelectArr,
            ]
            strs = [
                ...strs,
                paramsStrs,
            ]
        }

        if (this.props.default_shipData && Object.keys(this.props.default_shipData).length > 0) {
            let default_shipData = this.props.default_shipData;
            default_shipData.deviceVOList = default_shipData.lbsPositionList
            strs = [
                {
                    node: default_shipData,
                    text: default_shipData.objectName,
                    value: default_shipData.objectId,
                }
            ]
        }
        let treeData = this.state.treeData;
        if (value && text && extra) {
            treeData = this.forbiddenSelectForTreeData(treeData, strs);
        }
        // let strs;
        // value.forEach((val) => {
        //     strs = val.split("-");
        // })
        if (this.state.isReset) {
            this.setState({
                strs,
                selectArr,
                value_Select_shipDynamic: { key: value.length > 0 ? value[0].split(',')[1] : '', label: text.length > 0 ? text[0] : '' },
                treeData: treeData,
                value_TreeSelect_shipName_isReset: value.length > 0 ? value : [],
                value_TreeSelect_shipName: value.length > 0 ? value : [],
                haveDataList: {},
            }, () => {
                if (typeof (extra) == 'undefined') {
                    this.findObjTrackMulti();
                }
            })
        } else {
            this.setState({
                strs,
                selectArr,
                value_Select_shipDynamic: { key: value[0], label: text[0] },
                treeData: treeData,
                value_TreeSelect_shipName: value.length > 0 ? value : [],
                value_TreeSelect_shipName_isReset: value.length > 0 ? value : [],
                haveDataList: {},
            }, () => {
                if (typeof (extra) == 'undefined') {
                    this.findObjTrackMulti();
                }
            })
        }
    }
    searchList = () => {
        this.findObjTrackMulti()
    }
    resetMap = () => {
        this.changeSelect([], [])
        this.props.form.resetFields();
        let startTime = moment().format('YYYY-MM-DD') + " 00:00:00"
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        let value_TreeSelect_shipName = this.state.value_TreeSelect_shipName;

        let treeData = this.props.treeData;
        treeData = this.handleTreeData(treeData);//重置树结构
        this.setState({
            treeData,
            startTime,
            endTime,
            value_DatePicker: [startTime, endTime],//时间重置
            strs: undefined,//选中船舶信息重置
            selectArr: [],
            shipVoyageInfoData: undefined,
            trackLine: undefined,//选中船舶后后台接口查询出来的信息
            playing: false,//播放按钮重置
            isRunning_trajectory: false,
            doubleSpeedNum: 1,
            isReset: true,
            value_Select_shipDynamic: { key: '', label: '' },
            visible_Tooltip_searchList: false,
        }, () => {
            this.state.componentRepository.map.stop_trajectory_points();
            this.state.componentRepository.map.clearMap(this.noNeedRemoveList);
            this.props.parentObj.resetModal_trajectory();
            // this.state.componentRepository.map.clearMap(this.state.targetList);
        })
    }
    stop_trajectory_points = () => {
        this.setState({
            playing: false,//播放按钮重置
            isRunning_trajectory: false,
        }, () => {
        })
    }

    onChange_Select_shipDynamic = (data, index) => {
        console.log(data, '---data-------------onChange_Select_shipDynamic   ------------');
        let backgroundColors_shipDynamic = this.state.backgroundColors_shipDynamic;
        let colors_shipDynamic = this.state.colors_shipDynamic;
        backgroundColors_shipDynamic.map((item, indexC) => {
            if (index == indexC) {
                backgroundColors_shipDynamic[indexC] = colors[indexC];
                colors_shipDynamic[indexC] = 'white';
            } else {
                backgroundColors_shipDynamic[indexC] = 'white';
                colors_shipDynamic[indexC] = colors[indexC];
            }
        })
        this.setState({
            value_Select_shipDynamic: data,
            colors_shipDynamic: colors_shipDynamic,
            backgroundColors_shipDynamic: backgroundColors_shipDynamic,
        })
        let startTime = moment().format('YYYY-MM-DD') + " 00:00:00"
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (this.state.startTime) {
            startTime = this.state.startTime;
        }
        if (this.state.endTime) {
            endTime = this.state.endTime;
        }
        let params = {
            objectId: data.value,
            startTime: startTime,
            endTime: endTime,
            cardNumber:this.props.default_shipData.cardNumber,
        }
        // this.selectRangeQuery(params);
    }
    async selectRangeQuery(value) {
        this.addLoadingStatus('selectRangeQuery');
        let params = {};
        if (value) {
            params = value;
        }
        let data = await selectRangeQuery(params)
            .then(res => { return res.json() }).catch(ex => { });
        if (data.code === 200) {
            data.data.dynamicStateList.map((item, index) => {
                switch (item.eventType) {
                    case 302261:
                        item.eventTypeName = '违规驶入'
                        break;
                    case 302262:
                        item.eventTypeName = '驶出停靠点'
                        break;
                    case 302263:
                        item.eventTypeName = '驶入停靠点'
                        break;
                    case 302264:
                        item.eventTypeName = '拆卸报警'
                        break;
                    case 302265:
                        item.eventTypeName = '低电量报警'
                        break;
                    case 302266:
                        item.eventTypeName = '倾斜报警'
                        break;
                }
            })
            this.setState({
                shipVoyageInfoData: data.data,
            }, () => {
                console.log(this.state.shipVoyageInfoData, '-----------selectRangeQuery---------');
                this.closeLoadingStatus('selectRangeQuery');
            });
        } else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }

    async findObjTrackMulti() {
        this.state.componentRepository.map.stop_trajectory_points();
        this.state.componentRepository.map.clearMap(this.noNeedRemoveList);
        // let cardNumData = this.state.cardNumData;
        let cardNumData = {
            "302500": "GPS",
            "302501": "BD",
            "302502": "AIS"
        }
        if (cardNumData && Object.keys(cardNumData).length > 0) {
        } else {
            cardNumData = this.state.cardNumTypeData;
        }
        let { startTime, endTime, strs } = this.state;
        if (strs && strs.length > 0 && strs.length < 7) {
            this.addLoadingStatus('findObjTrackMulti');
            console.log(strs, '456')
            let objectInfos = [];
            strs.map((item, index) => {
                let deviceInfos = [];
                let deviceVOList = item.node && item.node.deviceVOList ? item.node.deviceVOList : [];
                deviceVOList.map((itemD, indexD) => {
                    if (cardNumData[itemD.cardNumType]) {
                        deviceInfos = [
                            ...deviceInfos,
                            {
                                deviceId: itemD.deviceId,
                                cardNumType: itemD.cardNumType,
                            }
                        ]
                    }
                })
                objectInfos = [
                    ...objectInfos,
                    {
                        objectId: item.value,
                        deviceInfos: deviceInfos,
                    }
                ]
            })
            console.log("------------------------------------------"+this.props.default_shipData.cardNumber)
            let params = {
                // objectInfos,
                cardNumber: this.props.default_shipData.cardNumber,
                startTime,
                endTime,
            }
            // this.selectRangeQuery(
            //     {
            //         objectId: strs[0].value,
            //         startTime,
            //         endTime,
            //     });
            let data = await findObjTrackMulti(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => { });
            if (data && data.code == 200) {
                let features = []
                // let trackLine={
                //     type:"FeatureCollection",
                //     name:data.data[0].objectName,
                //     crs:{ "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                //     features:data.data
                // }
                let targetList = [];
                let haveDataList = [];
                console.log('data.rows=========',data.rows)
                // Object.keys(data.rows).map(key => {
                data.rows.map((content,key) => {
                    let value = data.rows[key];
                    // if (value.length > 0) {
                    if (value.id && value.cardNumber) {
                        targetList = [
                            ...targetList,
                            'vectorImg_' + key
                        ];
                        haveDataList = [
                            ...haveDataList,
                            // [key]: value,
                            value
                        ]
                    }
                })
                let dynamic = this.state.dynamic;
                if (targetList.length > 0) {
                    dynamic = true;
                }
                console.log(haveDataList, 'haveDataList')
                this.setState({
                    trackLine: data.rows,
                    targetList: targetList,
                    haveDataList: haveDataList,
                    dynamic,
                }, () => {
                    // this.child.playingTrackLine()
                    if (this.state.isRunning_trajectory) {
                        this.state.componentRepository.map.start_trajectory_points();
                    } else {
                        this.state.componentRepository.map.clearMap(this.noNeedRemoveList);
                        // Object.keys(this.state.haveDataList).map(key => {
                        this.state.haveDataList.map((content,key) => {
                            let value = this.state.haveDataList[key];
                            console.log(value,'value----------')
                            // debugger
                            // if (value.length > 0) {
                            if (value) {
                                let endZoom = 19;
                                let noWorkingZoom = 15;
                                // this.state.componentRepository.map.showMap_trajectory_points(value, 'vectorImg_' + key);
                                this.state.componentRepository.map.showMap_trajectory_points(this.state.haveDataList, 'vectorImg_' + key);
                                console.log(value,'476854785478')
                            }
                        })
                    }
                    console.log(data.rows, '----------------------findObjTrackMulti--------------------')
                    this.closeLoadingStatus('findObjTrackMulti');
                })
            } else if (data.code == 505) {
                message.error(data.msg)
                if (this.props.history) {
                    this.props.history.push('/login')
                }
            }
        }
    }

    onClick_Tooltip_searchList = () => {
        let strs = this.state.strs;
        this.setState({
            visible_Tooltip_searchList: false,
        }, () => {
            if (strs && strs.length > 6) {
                this.setState({
                    visible_Tooltip_searchList: true,
                    text_Tooltip_searchList: '最多选择六条船！！！'
                })
            } else if (typeof (strs) == 'undefined' || strs.length <= 0) {
                this.setState({
                    visible_Tooltip_searchList: true,
                    text_Tooltip_searchList: '至少选择一条船！！！'
                })
            }
        })
    }
    out_Tooltip_searchList = () => {
        this.setState({
            visible_Tooltip_searchList: false,
        });
    }

    playingTrack = () => {
        this.setState({

        })
    }
    showShipDynamic = () => {
        let dynamic = !this.state.dynamic;
        this.setState({
            dynamic: dynamic
        })
    }
    onRef = (ref) => {
        this.child = ref;
    }

    onClick_doubleForSpeed_trajectory = () => {
        let doubleSpeedNum = this.state.doubleSpeedNum;
        if (doubleSpeedNum == 32) {
            doubleSpeedNum = 1;
        } else {
            doubleSpeedNum = doubleSpeedNum * 2;
        }
        this.setState({
            doubleSpeedNum: doubleSpeedNum
        }, () => {
            this.state.componentRepository.map.changeTrajectorySpeed(doubleSpeedNum);
        })
    }

    onChange_cardNumType = (data) => {
        let cardNumData = {};
        if (data && data.length > 0) {
            data.map((item, index) => {
                cardNumData = {
                    ...cardNumData,
                    [item.key]: item.label,
                }
            })
            console.log(cardNumData, '---------------------onChange_cardNumType-------------------');
            this.setState({
                cardNumData: cardNumData,
            })
        }
    }
    controlLayer = (data, checked) => {

        // let { mapArr } = this.state
        let mapArr = [];

        // if (mapArr.length > 0) {
        //     var result = mapArr.some(item => {
        //         if (item.key == data.key) {
        //             return true
        //         }
        //     })
        //     console.log(result) // 如果listArr数组对象中含有id:'',就会返回true，否则返回false
        //     if (result) { // 如果存在
        //         mapArr.forEach((val) => {
        //             if (val.key === data.key) {
        //                 if (typeof (checked) === 'boolean') {
        //                     val.visible = checked
        //                 } else {
        //                     val.key = checked
        //                 }
        //             }
        //         })
        //     } else {
        //         mapArr.push({
        //             key: data.key,
        //             visible: checked,
        //             name: data.name
        //         })
        //     }
        // } else {
        mapArr.push({
            key: data.key,
            visible: checked,
            name: data.name,
            optionArr: data.optionArr,
            isOverlay: data.isOverlay,
        })
        // }

        let switchArr = this.state.switchArr;
        switchArr.map((itemSwitchArr, indexSwitchArr) => {
            if (itemSwitchArr.key == data.key) {
                if (typeof (checked) == 'boolean') {
                    itemSwitchArr.defaultChecked = checked;
                } else {
                    itemSwitchArr.defaultChecked = true;
                    itemSwitchArr.optionArr.map(itemOptionArr => {
                        if (checked == itemOptionArr.target) {
                            itemOptionArr.defaultChecked = true
                        } else {
                            itemOptionArr.defaultChecked = false
                        }
                    })
                }
            }
        })
        this.setState({
            mapArr,
            switchArr
        }, () => {
            console.log(this.state.mapArr, 'mapArr');
            this.state.mapArr.map((item, index) => {
                let key = item.key;
                let visible = item.visible;
                let optionArr = item.optionArr;
                let isOverlay = item.isOverlay;
                if (typeof (optionArr) == 'undefined') {
                    if (typeof (isOverlay) == 'boolean') {
                        if (visible) {
                            this.state.componentRepository.map.openOverlayAll();
                        } else {
                            this.state.componentRepository.map.closeOverlayAll();
                        }
                    } else {
                        if (visible) {
                            this.state.componentRepository.map.openLayerVisibleBylayerName(key);
                        } else {
                            this.state.componentRepository.map.closeLayerVisibleBylayerName(key);
                        }
                    }
                } else {
                    if (visible) {
                        if (optionArr && optionArr.length > 0) {
                            let haveTrue = false;
                            optionArr.map((item_optionArr, index_optionArr) => {
                                if (item_optionArr.defaultChecked) {
                                    this.state.componentRepository.map.openLayerVisibleBylayerName(item_optionArr.target);
                                    haveTrue = true;
                                } else {
                                    this.state.componentRepository.map.closeLayerVisibleBylayerName(item_optionArr.target);
                                }
                            })
                            if (!haveTrue) {
                                this.state.componentRepository.map.openLayerVisibleBylayerName(optionArr[0].target);
                            }
                        }
                    } else {
                        if (optionArr && optionArr.length > 0) {
                            optionArr.map((item_optionArr, index_optionArr) => {
                                this.state.componentRepository.map.closeLayerVisibleBylayerName(item_optionArr.target);
                            })
                        }
                    }
                }
            })
        })

    }
    clickSearch = (key) => {
        let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
        if (key == 'measure') {
            if (text_Measure_DropdownButton == '取消测量') {
                this.setState({
                    text_Measure_DropdownButton: '测量工具'
                }, () => {
                    this.state.componentRepository.map.handleButtonClick_Measure();
                })
            }
        }
        let text_Measure_DropdownButton_search = this.state.text_Measure_DropdownButton_search;
        switch (key[0]) {
            case 'range':
                this.setState({
                    text_Measure_DropdownButton: '取消测量'
                }, () => {
                    this.state.componentRepository.map.handleMenuClick_Measure(key[0]);
                })
                    ; break;
            case 'area':
                this.setState({
                    text_Measure_DropdownButton: '取消测量'
                }, () => {
                    this.state.componentRepository.map.handleMenuClick_Measure(key[0]);
                })
                    ; break;
            case 'rectangle':
                this.setState({
                    areaShow: true
                })
                    ; break;

        }

    }

    getDictData = (type) => {
        let dictData = JSON.parse(sessionStorage.getItem('dictData'))
        let arr = {}
        dictData.forEach((item) => {
            if (item.category === type) {
                arr = {
                    ...arr,
                    [item.typeCode]: item.typeName,
                }
            }
        })
        return arr;
    }

    //根据参数开启对应的开发者模式权限
    onClick_developerMode = (name) => {
        if (typeof (this.developerModePowerLibrary[name]) != 'undefined') {
            if (this.developerModePowerLibrary[name] == 5) {
                this.developerModePowerLibrary[name] = -1
                this.setState({
                    ['visible' + name]: true,
                })
            } else {
                this.developerModePowerLibrary[name] = this.developerModePowerLibrary[name] + 1;
            }
        }
    }

    render() {
        let loadingStatus = this.state.loadingStatus;
        let isLoading = false;
        if (Object.keys(loadingStatus).length > 0) {
            Object.keys(loadingStatus).map((key, index) => {
                let value = loadingStatus[key];
                if (value) {
                    isLoading = true;
                }
            })
        }
        const columns1 = [
            {
                title: '重点区域',
                key: 'areaName',
                dataIndex: 'areaName',
                align: "center",
                render: (text, record) => {
                    return <div>
                        <span
                        // style={{ cursor: 'pointer' }}
                        // onClick={(e) => this.onClick_Table_row_portManageTable(e, record)}
                        >{text}</span>
                    </div>
                }
            },
            {
                title: '区域当前总数',
                key: 'inCount',
                dataIndex: 'inCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#00C160' }}>{text}</span>
                }
            },
            {
                title: '区域驶离总数',
                key: 'leaveCount',
                dataIndex: 'leaveCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#F31C11' }}>{text}</span>
                }
            },
        ]
        const columns2 = [
            {
                title: '停靠点',
                key: 'areaName',
                dataIndex: 'areaName',
                align: "center",
                render: (text, record) => {
                    return <div>
                        <span
                        // style={{ cursor: 'pointer' }}
                        // onClick={(e) => this.onClick_Table_row_portManageTable(e, record)}
                        >{text}</span>
                    </div>
                }
            },
            // {
            //     title:'区域',
            //     key:'area',
            //     dataIndex:'area',
            //     align:"center",
            // },
            {
                title: '驶入',
                key: 'inCount',
                dataIndex: 'inCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#00C160' }}>{text}</span>
                }
            },
            {
                title: '驶离',
                key: 'leaveCount',
                dataIndex: 'leaveCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#F31C11' }}>{text}</span>
                }
            },
        ]
        let { pointData, portData } = this.props.defaultDataToChild;
        const { shipVoyageInfoData, toolArr, back, playing, fast, player, toolShow, switchShow, pointShow, portShow, maxnum, inputvalue, visible, switchArr, treeData, viewSearchValue, dynamic, selectArr, trackLine } = this.state;
        const { getFieldDecorator } = this.props.form;
        let value_TreeSelect_shipName = this.state.isReset ? this.state.value_TreeSelect_shipName_isReset : this.state.value_TreeSelect_shipName;
        return (
            <div className="trackQuery">
                <div className="GISMAP_body_div"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%'
                        // left: '24.5%',
                        // top: '43%',
                        // backgroundColor: 'rgba(19,35,130,0.31)',
                        // border: '1px solid white'
                    }}
                >
                    <MyComponentFactory
                        type={'map'}
                        componentLoad={this.componentLoad}
                        config_JSON={config_JSON}
                        // playerMapPointCarera={this.playerMapPointCarera}
                        parentObj={this}
                    ></MyComponentFactory>
                </div>
                <div className="clickBtn" onClick={this.showDrawer}>
                    {visible ? <Icon type="double-left" /> : <Icon type="double-right" />}
                </div>
                {
                    visible && <div className="screenBigBox">
                        <Spin size="large"
                            tip="正在努力加载中..."
                            spinning={isLoading}
                            style={{
                                marginTop: '25%',
                                // marginLeft: '50%'
                            }}
                        >
                            <div className="screenBox">
                                <div>
                                    <Form>
                                        <Form.Item key={'value_DatePicker'} label={
                                            <span>选择日期</span>
                                        }
                                            {...{
                                                labelCol: {
                                                    xs: { span: 24 },
                                                    sm: { span: 4 },
                                                },
                                                wrapperCol: {
                                                    xs: { span: 24 },
                                                    sm: { span: 20 },
                                                },
                                            }}
                                        >
                                            {getFieldDecorator('value_DatePicker', {
                                                rules: [
                                                ],
                                                initialValue: [moment(this.state.value_DatePicker[0]), moment(this.state.value_DatePicker[1])],
                                                valuePropName: 'moment',
                                            })(
                                                <RangePicker
                                                    ranges={{
                                                        '今天': [moment(), moment()],
                                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                                    }}
                                                    style={{ width: 370 }}
                                                    placeholder={[moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]}
                                                    format={"YYYY-MM-DD HH:mm:ss"}
                                                    showTime={{ defaultValue: [moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')] }}
                                                    onChange={this.haveTime}
                                                    value={[moment(this.state.value_DatePicker[0]), moment(this.state.value_DatePicker[1])]}
                                                />
                                            )}
                                        </Form.Item>
                                        {typeof (this.props.default_shipData) == 'undefined' ?
                                            <Form.Item key={'value_TreeSelect'} label={
                                                <span>船舶名称</span>
                                            }
                                                {...{
                                                    labelCol: {
                                                        xs: { span: 24 },
                                                        sm: { span: 4 },
                                                    },
                                                    wrapperCol: {
                                                        xs: { span: 24 },
                                                        sm: { span: 20 },
                                                    },
                                                }}
                                            >
                                                {getFieldDecorator('value_TreeSelect', {
                                                    rules: [
                                                    ],
                                                    // initialValue: this.state.value_DatePicker,
                                                    // valuePropName: 'moment',
                                                })(
                                                    <TreeSelect
                                                        allowClear
                                                        multiple
                                                        style={{ width: 370 }}
                                                        placeholder='请选择（最多选择6项）'
                                                        searchPlaceholder="输入船名"
                                                        treeData={treeData}
                                                        treeNodeFilterProp="title"
                                                        treeCheckable={true}
                                                        // onSearch={this.qureHandleSearch}
                                                        // searchValue={viewSearchValue}
                                                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                                        onChange={this.changeSelect}
                                                    />
                                                )}
                                            </Form.Item>
                                            : ''
                                        }
                                    </Form>
                                </div>
                                {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <span> 船舶名称：</span>
                                <TreeSelect
                                    allowClear
                                    multiple
                                    style={{ width: 350 }}
                                    placeholder='请选择（最多选择20项）'
                                    searchPlaceholder="输入船名"
                                    treeData={treeData}
                                    treeNodeFilterProp="title"
                                    treeCheckable={true}
                                    // onSearch={this.qureHandleSearch}
                                    // searchValue={viewSearchValue}
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    onChange={this.changeSelect}
                                />

                            </div> */}
                                <div className="btnBox">
                                    {/*{*/}
                                    {/*    this.state.haveDataList && Object.keys(this.state.haveDataList).length > 0*/}
                                    {/*        ?*/}
                                    {/*        <Button*/}
                                    {/*            style={{*/}
                                    {/*                position: 'absolute',*/}
                                    {/*                left: '40%',*/}
                                    {/*                top: '160%',*/}
                                    {/*                width: '80px',*/}
                                    {/*                height: '20px',*/}
                                    {/*                border: 'unset'*/}
                                    {/*            }}*/}
                                    {/*            onClick={this.showShipDynamic}*/}
                                    {/*        >*/}
                                    {/*            {*/}
                                    {/*                dynamic*/}
                                    {/*                    ?*/}
                                    {/*                    <Icon type="double-left" rotate={90} />*/}
                                    {/*                    :*/}
                                    {/*                    <Icon type="double-right" rotate={90} />*/}
                                    {/*            }*/}
                                    {/*        </Button>*/}
                                    {/*        :*/}
                                    {/*        ''*/}
                                    {/*}*/}
                                    <Tooltip
                                        trigger={'click'}
                                        title={
                                            (
                                                <div
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                >
                                                    {
                                                        this.state.text_Tooltip_searchList
                                                    }
                                                </div>
                                            )
                                        }

                                        visible={this.state.visible_Tooltip_searchList}
                                    >
                                        <Row
                                            type={'flex'}
                                            align={'middle'}
                                            justify={'center'}
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <Col
                                                span={8}
                                                style={{
                                                    height: '100%'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    onClick={() => this.onClick_developerMode('developerMode_SelectShipCard')}
                                                >
                                                    {
                                                        this.state['visible' + 'developerMode_SelectShipCard'] &&
                                                        <Select
                                                            style={{ width: 135 }}
                                                            mode={'tags'}
                                                            maxTagCount={1}
                                                            allowClear={true}
                                                            labelInValue={true}
                                                            placeholder={'请选择设备...'}
                                                            // defaultValue={{ key: '302501', label: 'BD' }}
                                                            onChange={this.onChange_cardNumType}>
                                                            {
                                                                Object.keys(this.state.cardNumTypeData).map(key => {
                                                                    let value = this.state.cardNumTypeData[key];
                                                                    return (
                                                                        <Option value={key}>{value}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    }
                                                </div>
                                            </Col>
                                            <Col
                                                span={4}
                                            >
                                                <Button
                                                    type="primary"
                                                    onClick={this.searchList}
                                                    onMouseDown={this.onClick_Tooltip_searchList}
                                                    onMouseOut={this.out_Tooltip_searchList}
                                                >查询</Button>
                                            </Col>
                                            {/*<Col*/}
                                            {/*    span={4}*/}
                                            {/*>*/}
                                            {/*    <Button onClick={this.resetMap}>重置</Button>*/}
                                            {/*</Col>*/}
                                            <Col
                                                span={4}
                                            >
                                                <Button
                                                    onClick={this.playSlider.bind(this, 2)}
                                                    disabled={this.state.haveDataList && Object.keys(this.state.haveDataList).length > 0 ? false : true}
                                                    onMouseDown={this.onClick_Tooltip_searchList}
                                                    onMouseOut={this.out_Tooltip_searchList}
                                                >{playing ? '暂停' : '播放'}</Button>
                                            </Col>
                                            <Col
                                                span={4}
                                            >
                                                <Button
                                                    type={'primary'}
                                                    onClick={this.onClick_doubleForSpeed_trajectory}
                                                    onMouseDown={this.onClick_Tooltip_searchList}
                                                    onMouseOut={this.out_Tooltip_searchList}
                                                    disabled={this.state.haveDataList && Object.keys(this.state.haveDataList).length > 0 ? false : true}
                                                >倍速X{this.state.doubleSpeedNum}</Button>
                                            </Col>
                                        </Row>
                                    </Tooltip>
                                </div>
                            </div>
                            {/*{*/}
                            {/*    dynamic && <div className="shipDynamic">*/}
                            {/*        <div*/}
                            {/*        // onChange={this.onChange_Select_shipDynamic}*/}
                            {/*        // placeholder={'请选择...'}*/}
                            {/*        // labelInValue={true}*/}
                            {/*        // value={this.state.value_Select_shipDynamic}*/}
                            {/*        >*/}
                            {/*            <h3>航程统计</h3>*/}
                            {/*            {*/}
                            {/*                selectArr && selectArr.map((val, index) => (*/}
                            {/*                    <Button*/}
                            {/*                        style={{*/}
                            {/*                            backgroundColor: this.state.backgroundColors_shipDynamic[index],*/}
                            {/*                            color: this.state.colors_shipDynamic[index],*/}
                            {/*                            marginLeft: 20,*/}
                            {/*                            marginBottom: 10*/}
                            {/*                        }}*/}
                            {/*                        onClick={() => this.onChange_Select_shipDynamic(val, index)}*/}
                            {/*                    >{val.text}</Button>*/}
                            {/*                ))*/}
                            {/*            }*/}
                            {/*        </div>*/}
                            {/*        <div className="tongji">*/}
                            {/*            <Row*/}
                            {/*                type="flex" justify="center" align="middle"*/}
                            {/*                style={{*/}
                            {/*                    fontSize: 16,*/}
                            {/*                    textAlign: 'center'*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                <Col*/}
                            {/*                    span={8}*/}
                            {/*                >*/}
                            {/*                    <Row>*/}
                            {/*                        <Col*/}
                            {/*                            span={5}*/}
                            {/*                            offset={4}*/}
                            {/*                        >*/}
                            {/*                            <img src={require("../../assets/img/voyageSmall.png")} />*/}
                            {/*                        </Col>*/}
                            {/*                        <Col*/}
                            {/*                            span={14}*/}
                            {/*                        >*/}
                            {/*                            航程<span style={{ fontSize: 12 }}>(公里)</span>*/}
                            {/*                        </Col>*/}
                            {/*                    </Row>*/}
                            {/*                    <p className="bigP">{shipVoyageInfoData ? (parseInt(shipVoyageInfoData.totalMileage) / 1000).toFixed(2) : 0}</p>*/}
                            {/*                </Col>*/}
                            {/*                <Col*/}
                            {/*                    span={8}*/}
                            {/*                >*/}
                            {/*                    <Row>*/}
                            {/*                        <Col*/}
                            {/*                            span={5}*/}
                            {/*                            offset={2}*/}
                            {/*                        >*/}
                            {/*                            <img src={require("../../assets/img/jinchu.png")} />*/}
                            {/*                        </Col>*/}
                            {/*                        <Col*/}
                            {/*                            span={16}*/}
                            {/*                        >*/}
                            {/*                            进/出港<span style={{ fontSize: 12 }}>(次数)</span>*/}
                            {/*                        </Col>*/}
                            {/*                    </Row>*/}
                            {/*                    <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.arrivalCount + '/' + shipVoyageInfoData.leaveCount : '0/0'}</p>*/}
                            {/*                </Col>*/}
                            {/*                <Col*/}
                            {/*                    span={8}*/}
                            {/*                >*/}
                            {/*                    <Row>*/}
                            {/*                        <Col*/}
                            {/*                            span={5}*/}
                            {/*                            offset={4}*/}
                            {/*                        >*/}
                            {/*                            <img src={require("../../assets/img/weigui.png")} />*/}
                            {/*                        </Col>*/}
                            {/*                        <Col*/}
                            {/*                            span={14}*/}
                            {/*                        >*/}
                            {/*                            违规<span style={{ fontSize: 12 }}>(次数)</span>*/}
                            {/*                        </Col>*/}
                            {/*                    </Row>*/}
                            {/*                    <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.violationCount : 0}</p>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*            /!* <ul>*/}
                            {/*                <li>*/}
                            {/*                    <p><img src={require("../../assets/img/voyageSmall.png")} />航程<p style={{fontSize:12}}>(公里)</p></p>*/}
                            {/*                    <p className="bigP">{shipVoyageInfoData ? (parseInt(shipVoyageInfoData.totalMileage) / 1000).toFixed(2) : 0}</p>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <p><img src={require("../../assets/img/jinchu.png")} />进/出港<p style={{fontSize:12}}>(次数)</p></p>*/}
                            {/*                    <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.arrivalCount + '/' + shipVoyageInfoData.leaveCount : '0/0'}</p>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <p><img src={require("../../assets/img/weigui.png")} />违规<p style={{fontSize:12}}>(次数)</p></p>*/}
                            {/*                    <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.violationCount : 0}</p>*/}
                            {/*                </li>*/}
                            {/*            </ul> *!/*/}
                            {/*        </div>*/}
                            {/*        <h4>航程动态</h4>*/}
                            {/*        <div className="dongtai">*/}
                            {/*            <Timeline>*/}
                            {/*                {shipVoyageInfoData && shipVoyageInfoData.dynamicStateList && shipVoyageInfoData.dynamicStateList.length > 0*/}
                            {/*                    ?*/}
                            {/*                    shipVoyageInfoData.dynamicStateList.map(item => (*/}
                            {/*                        <Timeline.Item color="red">*/}
                            {/*                            <p>{item.eventTypeName}</p>*/}
                            {/*                            <p>{item.eventRemark}</p>*/}
                            {/*                            <p>{item.gmtCreate}</p>*/}
                            {/*                        </Timeline.Item>*/}
                            {/*                    ))*/}
                            {/*                    :*/}
                            {/*                    <img src={require('./img/img_emptyData.png')}></img>*/}
                            {/*                }*/}
                            {/*            </Timeline>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </Spin>
                    </div>
                }
                 <ul className="toolBox">
                     {
                        toolArr.map((item, index) => (
                            <li key={index} onClick={this.clickTool.bind(this, index, item.key)}>
                                {item.line && <i>|</i>}
                                <img src={item.click ? item.act : item.img} />
                                <span style={item.click ? { color: '#169cfd' } : { color: '#999' }}>{item.name}</span>

                            </li>
                        ))
                    }
                </ul>
                {
                    pointShow && <div className="pointBox">
                        <Table
                            columns={columns1}
                            dataSource={pointData}
                            pagination={false}
                            locale={{
                                filterConfirm: '确定',
                                filterReset: '重置',
                                emptyText: '暂无数据',
                            }}
                            // showHeader={ false }
                            scroll={{
                                y: '400px',
                                x: '100%',
                                scrollToFirstRowOnChange: true,
                            }}
                        />
                    </div>
                }
                {
                    portShow && <div className="portBox">
                        <Table
                            columns={columns2}
                            dataSource={portData}
                            pagination={false}
                            locale={{
                                filterConfirm: '确定',
                                filterReset: '重置',
                                emptyText: '暂无数据',
                            }}
                            // showHeader={ false }
                            scroll={{
                                y: '400px',
                                x: '100%',
                                scrollToFirstRowOnChange: true,
                            }}
                        />
                    </div>
                }
                {
                    switchShow && <div className="switchBox">
                        {
                            switchArr.map((item, index) => {
                                if (item.title) {
                                    return <h4 key={index}>{item.title}</h4>
                                } else {
                                    if (item.optionArr) {
                                        return <div key={item.key ? item.key : index} className="mapSwitch">
                                            <Switch checkedChildren="开" unCheckedChildren="关" size="small" checked={item.defaultChecked} onChange={this.controlLayer.bind(this, item)} /> <span className="switchName">{item.name}</span>
                                            <Select size="small" onSelect={this.controlLayer.bind(this, item)}>
                                                {
                                                    item.optionArr.map((val) => (
                                                        <Option key={val.key} value={val.key}>{val.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    } else {
                                        return <div key={item.key ? item.key : index} className="mapSwitch"><Switch checkedChildren="开" unCheckedChildren="关" defaultChecked={item.defaultChecked} size="small" onChange={this.controlLayer.bind(this, item)} /> <span className="switchName">{item.name}</span></div>
                                    }
                                }
                            })
                        }
                    </div>
                }
                {
                    toolShow && <div className="survey">
                        <Tree
                            showIcon
                            defaultExpandAll
                            switcherIcon={<Icon type="down" />}
                            onSelect={this.clickSearch}
                        >
                            <TreeNode title={this.state.text_Measure_DropdownButton} key="measure" >
                                <TreeNode icon={<img src={maths.range} />} title="距离测量" key="range" disableCheckbox />
                                <TreeNode icon={<img src={maths.area} />} title="面积测量" key="area" />
                            </TreeNode>
                        </Tree >
                    </div>
                }
            </div >
        )
    }
}

export default Form.create()(TrackQuery);
// export default TrackQuery
