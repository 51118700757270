import {
    getSession_login_h5stream,
    getSession_Keepalive_h5stream,
    getAllSrc_h5stream,
    getSnapshot_h5stream,
    getScreenshots_Snapshot_h5stream,
    getVideoLastImg_h5stream,
    getVideoRecord_h5stream,
    manualRecordStart_h5stream,
    manualRecordStop_h5stream,
    remoteControl_h5stream,
    setPreset_h5stream,
    pathRecord_h5stream,
    delPreset_h5stream,
} from '../MyComponentFactoryPackage/masterControlFactory/interfaceRequest/RequestImpl'

/**
 *@Description:{ 登陆 获取session}
 *@InterfaceAddress:{ }
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-09 14:04:46
*/
async function h5stream_login(obj, params) {
    let data = await getSession_login_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        obj.setSession_h5stream(data);
    }
}

/**
 *@Description:{ session 存活期10分钟，向h5发送保活请求，每次保活10分钟 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-09 14:05:01
*/
async function h5stream_Keepalive(obj, session) {
    let params = {};
    params.session = session;
    let data = await getSession_Keepalive_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        obj.KeepaliveSession_h5stream(data);
    }
}

/**
 *@Description:{ 获取 全部 摄像头信息}
 *@InterfaceAddress:{ }
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-09 14:05:45
*/
async function h5stream_getAllSrc(obj, session) {
    let params = {};
    params.session = session;
    let data = await getAllSrc_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data) {
        obj.getAllSrc_h5stream(data);
    }
}

/**
 *@Description:{ 获取摄像头快照 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                session
                token
                fileName(可不填，不带扩展名)
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-09 14:06:01
*/
async function h5stream_Snapshot(obj, params) {
    let data = await getSnapshot_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getSnapshot_h5stream(data);
    }
}
/**
 *@Description:{ 获取摄像头快照-抓图 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                session
                token
                fileName(可不填，不带扩展名)
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-09 14:06:01
*/
async function h5stream_Snapshot_Screenshots(obj, params) {
    let data = await getScreenshots_Snapshot_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getScreenshots_Snapshot_h5stream(data);
    }
}

/**
 *@Description:{ 获取 摄像头 最后一针图片缓存}
 *@InterfaceAddress:{ }
 *@parameter: { 
                session
                token
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 12:26:48
*/
async function h5stream_videoLastImg(obj, params) {
    let data = await getVideoLastImg_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getSnapshot_h5stream(data);
    }
}

/**
 *@Description:{ 获取 摄像头录像 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                session 必须
                token 必须  视频源
                duration 必须 录像时长
                filename 可选 录像文件名，如果空，自动生成文 件名，文件名不用带文件扩展
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 15:01:18
*/
async function h5stream_getVideoRecord(obj, params) {
    let data = await getVideoRecord_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getVideoRecord_h5stream(data);
    }
}

/**
 *@Description:{  开始录像 }
 *@InterfaceAddress:{ }
 *@parameter: {                         描述
                session         必须    session id
                token           必须    视频源 token
                alwayscreate    可选    true/false 是否创建新的 session
                limittime       可选    如果一直创建 session，录像到这 个时间自动停止，单位是秒
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 15:10:31
*/
async function h5stream_ManualRecordStart(obj, params) {
    let data = await manualRecordStart_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getManualRecordStart_h5stream(data);
    }
}

/**
 *@Description:{ 结束录像 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                                        描述
                session         必须    session id
                token           必须    视频源 token
                filename        可选    如果一直创建 session 的时候可以 使用返回的 filename 停止对应的 session
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 15:11:24
*/
async function h5stream_ManualRecordStop(obj, params) {
    let data = await manualRecordStop_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getManualRecordStop_h5stream(data);
    }
}
/**
 *@Description:{ 云台遥控摄像头 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                参数         是否可选        描述
                session     必须            session id
                token       必须            ONIVF 视频源 token
                action      必须            云台指令 up/down/left/right/ zoomin/zoomout/stop / preset
                speed       可选            stop 指令可以不带 speed 范围为 0-1 可以是小数
                preset      可选            指定的预置点
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 15:32:32
*/
async function h5stream_remoteControl(obj, params) {
    console.log(params, '---------------h5stream_remoteControl--')
    let data = await remoteControl_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getRemoteControl_h5stream(data);
    }
}
/**
 *@Description:{  设置预支点 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                参数         是否可选        描述
                session     必须            session id
                token       必须            ONIVF 视频源 token
                presetname  必须
                presettoken 必须         
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 15:41:21
*/
async function h5stream_SetPreset(obj, params) {
    let data = await setPreset_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getSetPreset_h5stream(data);
    }
}
/**
 *@Description:{  删除预支点 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                参数         是否可选        描述
                session     必须            session id
                token       必须            ONIVF 视频源 token
                presettoken 必须         
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-10 15:41:21
*/
async function h5stream_DelPreset(obj, params) {
    let data = await delPreset_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getDelPreset_h5stream(data);
    }
}
/**
 *@Description:{ 预录像接口，可以设置开始和结束时间}
 *@InterfaceAddress:{ }
 *@parameter: { 
                参数            是否可选        描述
                session         必须            session id
                token           必须            ONIVF 视频源 token
                duration        必须            录像时长
                start1          必须            开始时间 1 2018-3-29 年-月-日
                start2          必须            开始时间 2 20-3-29 小时-分钟-秒 可以按下面路径直接访问录像  /mediastore/record/token1/2018- 3-29TZ8/20-3-29/filename.mp4
                tz              可选            8 或者-8，如果没有此参数默认使 用服务器所在的时区
                filename        必须            录像文件名，文件名不用带文件扩 展
                alwayscreate    可选            true 或者 false,默认 flase，是否永 远创建新文件，如果没有该选项， 如果录像正在进行，则会在原来的 基础上延长对应的时长
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-13 14:32:57
*/
async function h5stream_PathRecord(obj, params) {
    let data = await pathRecord_h5stream(params)
        .then(res => {
            return res.json()
        })
        .catch(ex => { });
    if (data.bStatus === true) {
        data = {
            ...data,
            ...params,
        }
        obj.getPathRecord_h5stream(data);
    }
}

export {
    h5stream_login,
    h5stream_Keepalive,
    h5stream_getAllSrc,
    h5stream_Snapshot,
    h5stream_Snapshot_Screenshots,
    h5stream_videoLastImg,
    h5stream_getVideoRecord,
    h5stream_ManualRecordStart,
    h5stream_ManualRecordStop,
    h5stream_remoteControl,
    h5stream_SetPreset,
    h5stream_PathRecord,
    h5stream_DelPreset
};