import * as types from './types';
export default (state,{type,payload})=>{
    console.log(type,payload,state,'payload')
    switch (type){
        case types.VIEW_FOOT:
            return {...state,bFoot:payload}
        ;
        case types.UPDATE_userRights:
            return {...state,userRights:payload}
        ;
        default:return state;
    }
}