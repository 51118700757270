import React, { Component } from 'react'
// import ComponentOrder from './ComponentOrder.js'
import MasterControlFactory from './masterControlFactory/MasterControlFactory.js'

//import config json file
// import config_JSON from './default_JSON/Default_map.json'
class MyComponentFactory extends Component {
    constructor(props) {
        super(props)
    }

    // componentLoad1 = (type, obj) => {
    //     this.props.componentLoad(type, obj)
    // }

    render() {
        return (
            <MasterControlFactory
                type={ this.props.type }
                config_JSON={ this.props.config_JSON }
                componentLoad={ this.props.componentLoad }
                playerMapPointCarera={ this.props.playerMapPointCarera }
                parentObj={ this.props.parentObj }
            // setWFSFeaturesDict={ this.props.setWFSFeaturesDict }
            // setWFSFeaturesDictFinish={ this.props.setWFSFeaturesDictFinish }
            ></MasterControlFactory>
        )
    }
}

export default MyComponentFactory;