import React, { Component } from 'react'
import MapFactoryImpl from './mapFactoryImpl/MapFactoryImpl.js'

//import config json file
import config_JSON from '../default_JSON/Default_map.json'

class MasterControlFactory extends Component {
    constructor(props) {
        super(props)
    }
    // componentLoad1 = (type, obj) => {
    //     this.props.componentLoad(type, obj)
    // }
    factoryControll = () => {
        // let factoryType = this.props.type;//由外部配置文件配置属性生成组件
        let factoryType = "map";
        switch (factoryType) {
            // case "input": return <InputFactory ComponentOrder={ ComponentOrder } />; break;
            // case "map": return <MapFactory ComponentOrder={ ComponentOrder } />; break;
            case "map": return < MapFactoryImpl
                config_JSON={ this.props.config_JSON ? this.props.config_JSON : config_JSON }
                componentLoad={ this.props.componentLoad }
                setWFSFeaturesDict={ this.props.setWFSFeaturesDict }
                setWFSFeaturesDictFinish={ this.props.setWFSFeaturesDictFinish }
                playerMapPointCarera={ this.props.playerMapPointCarera }
                parentObj={ this.props.parentObj }
            ></MapFactoryImpl>; break;
            // case "3": return <img src={ gift3 } />; break;
            default: return null;
        }

    }

    render() {
        return (
            <div>
                { this.factoryControll() }
            </div>
        )
    }
}

export default MasterControlFactory
