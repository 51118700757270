// 固件升级状态
import React, { Component } from 'react'
import SearchTop from '../System/components/seachTop/SeachTop'
import { Table, Input, Switch, Select, Divider, Icon, Modal, message, Button, Tabs, Form, Progress } from 'antd';
import ModalForm from '../../componts/ModalForm';
import { updateDeviceParameter, deviceUpgrade, upgradeHistory, selectDeviceParameterAndStatus } from '../../data/dataStore'
import './ManagementDevice.css'
const { TabPane } = Tabs;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class FirmwareStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            modifyShow: false,
            ulshow: false,
            deviceType: '',
            eventStatusArr: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            clickNum: 0,
            name: '',
            hidden: true,
            write: true,
            visible: false,
            historyModalShow: false
        }
    }
    componentDidMount() {
        this.deviceUpgrade()
        let dictData = JSON.parse(sessionStorage.getItem("dictData"))
        let deviceTypeArr = [];
        dictData.forEach((item) => {
            if (item.category === "device_type") {
                deviceTypeArr.push({
                    text: item.typeName,
                    value: item.typeCode
                })
            }
        })
        this.setState({
            deviceTypeArr,
        })
    this.timer = setInterval(()=>this.deviceUpgrade(),2000)
    }
    componentWillUnmount(){
        this.timer && clearTimeout(this.timer);
    }
    // 历史查询
    async upgradeHistory() {
        let { deleteData, journalData } = this.state;
        let params = {
            deviceId: journalData.deviceId,
        }
        let data = await upgradeHistory(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                upgradeHistoryData: data.data
            })

        } else {
        }
        this.setState({
            deleteShow: false
        })
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.deviceUpgrade()
            })
        } else {
            this.setState({
                name: eve.target.value,
                deviceId: name,
            }, () => {
                this.deviceUpgrade(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.deviceUpgrade(data)
        this.setState({
            name: data
        })
    }
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            // let { eventStatus } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 150 }} placeholder={'请选择'}
                        showSearch
                    >
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301010)}
                                value={301010} >船讯宝</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301013)}
                                value={301013} >船讯宝电池单发设备</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301510)}
                                value={301510} >海电院太阳能设备</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301011)}
                                value={301011} >自研太阳能设备</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301012)}
                                value={301012} >便携式终端</ Option>
                        }
                    </Select>,
            </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData = (value) => {
        this.setState({
            eventStatusArr: value,
        }, () => {
            this.deviceUpgrade()
        });
    }
    // 查询设备参数
    async deviceUpgrade(value, type) {
        let { pagination, name, eventStatusArr } = this.state;
        console.log(name && name, 'name')
        let params = {
            deviceId: value ? value : name,
            deviceType: eventStatusArr,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await deviceUpgrade(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UrlArr: data.data.items,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                pagination.total = data.data.pageTotal
                this.setState({
                    ulshow: false,
                    dataSource: data.data.items,
                    pagination,
                    loading: false
                })
            }


        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            // this.deviceUpgrade()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    onSelectChange = (selectedRowKeys) => {
        this.setState({
            selectedRowKeys
        })
    }
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }

    closeModal = () => {
        this.setState({
            modifyShow: false,
            deleteShow: false,
            clickNum: 0,
            hidden: true,
            write: true,
        })
    }
    switchChange = (checked) => {
        this.setState({
            write: !checked
        })
    }
    showHistoryModal = (record) => {
        this.setState({
            journalData: record,
            historyModalShow: true
        }, () => {
            this.upgradeHistory()
        })
    }
    closeHistoryModal = () => {
        this.setState({
            historyModalShow: false
        })
    }

    render() {
        let { selectedRowKeys, UrlArr, journalData, deviceTypeArr, ulshow, dataSource, modifyData, upgradeHistoryData } = this.state;
        let ullAlls = this.state.UrlArr && this.state.UrlArr.map((item, index) => (item.deviceId))
        let ullAllss = [...new Set(ullAlls)];
        let imageData = []
        upgradeHistoryData && upgradeHistoryData.map((item) => {
            imageData.push(
                item.updateStatus
            )
        }

        )
        let myImg = ""
        var arr = ["first", "second", "third", "fourth", 3, 5, 8];
        for (var i = 0; i < imageData.length; i++) {
            console.log(imageData[i], 'second');
            if (imageData[i] === 2) {
                myImg = "icon-dengdaishenhe";
            }
            else {
                myImg = "icon-chenggong";
            }
        }
        // for (var i = 0; i++; i < imageData.length) {
        //     console.log(imageData[i] && imageData[i],'imageData');
        //     if (imageData[i] === 2) {
        //         myImg = "icon-cuowu";
        //     }
        //     else if (imageData[i] === 4) {
        //         myImg = "icon-chenggong";
        //     } else {
        //         myImg = "";
        //     }
        // }

        console.log(imageData, 'journalData')
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            // {
            //     title: '船舶名称',
            //     key: 'objectName',
            //     dataIndex: 'objectName',
            //     width: '20%',
            //     fixed: 'left'
            // },
            {
                title: '设备ID',
                key: 'deviceId',
                dataIndex: 'deviceId',
                width: '13%',
            },

            {
                title: '设备类型',
                key: 'deviceType',
                dataIndex: 'deviceType',
                width: '8%',
                render: (text) => {
                    let newText;
                    if (deviceTypeArr) {
                        deviceTypeArr.forEach((item) => {
                            if (item.value == text) {
                                newText = item.text
                            }
                        })
                    }
                    return newText
                },
                ...this.getColumnSearchProps('deviceType'),
            },
            {
                title: '目标版本',
                key: 'updatePort',
                dataIndex: 'updatePort',
                width: '8%',
                render: (text, row, index) => {
                    return (<span>终端{row.majorVersion}.{row.minorVersion}</span>)
                },
            },
            {
                title: '开始时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
                width: '12%',
                render: (text, row, index) => {
                    function renderTime(date) {
                        var dateee = new Date(date).toJSON();
                        return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
                    }
                    var sTime = renderTime(row.gmtCreate); //开始时间
                    return  sTime
                },
            },
            {
                title: '持续时间(单位/秒)',
                key: 'holdTime',
                dataIndex: 'holdTime',
                width: '12%',
                render: (text, row, index) => {
                    var startTime = row.gmtCreate
                    var endTime = row.gmtModified
                    function renderTime(date) {
                        var dateee = new Date(date).toJSON();
                        return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
                    }
                    var sTime = renderTime(startTime); //开始时间
                    var eTime = renderTime(endTime); //结束时间
                    // var s1 = sTime.getTime(),s2 = eTime.getTime();
                    // var total = (s2 - s1)/1000;
                    var t1 = Date.parse(new Date(sTime.replace(/-/g, "/")));
                    var t2 = Date.parse(new Date(eTime.replace(/-/g, "/")));
                    var total = (t2-t1)/1000;
                    console.log(total, 'renderTime')
                    return <span> {total}</span>
                },
            },
            {
                title: '进度',
                key: 'uploadMax',
                dataIndex: 'uploadMax',
                width: '15%',
                render: (text, row, index) => {
                    return <div style={{ display: 'flex' }}>
                        <Progress showInfo={false} percent={(row.uploadIndex) / (row.uploadMax) * 100} size="small" /><span style={{ width: 60 }}>{row.uploadIndex}/{row.uploadMax}</span>

                    </div>
                },
            },
            // {
            //     title: '当前升级的包',
            //     key: 'uploadIndex',
            //     dataIndex: 'uploadIndex',
            //     width: '8%',
            // },
            {
                title: '操作',
                key: 'detele',
                width: '10%',
                // fixed: 'right',
                render: (text, record) => {

                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.showHistoryModal(record)}>历史</span>
                    </div>

                },
            },
        ]
        return (
            <div className='deviceManagement contentBox'>
                <div>
                    <label>设备查询: </label>
                    <Input
                        value={this.state.name}
                        className='Searchvalue'
                        onSearch={value => this.deviceUpgrade(value, 'select')}
                        onChange={value => this.controlList(value)}
                        placeholder="设备ID" />
                    <Button className='search'
                        onClick={() => {
                            this.setState({
                                name: '',
                            }, () => {
                                this.deviceUpgrade()
                            })
                        }}
                    >重置</Button>
                    <Button type='primary' className='search' onClick={() => this.deviceUpgrade()}>查询</Button>
                </div>
                {
                    ulshow && <ul className="searchList"
                        style={{
                            position: 'absolute',
                            left: 83,
                            width: 200
                        }}
                    >
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div style={{ width: '100%' }}>
                    <Table
                        columns={columns}
                        // rowSelection={rowSelection}
                        dataSource={dataSource}
                        // scroll={{ x: 2000 }}
                        pagination={{ ...this.state.pagination, showQuickJumper: true }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <Modal
                    visible={this.state.historyModalShow}
                    onCancel={this.closeHistoryModal}
                    title='升级历史'
                    footer={null}
                    className="ModalUl"
                    width="45%"
                >
                    <div>
                        <ul className="searchList1">
                            {
                                upgradeHistoryData && upgradeHistoryData.map((item, index) => (
                                    <li
                                        key={item.id}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: 16,
                                            display: 'flex',
                                            alignItems: "center",
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 16 }} >{item.srcVersionName}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.srcMajorVersion}.{item.srcMinorVersion}&nbsp; &gt;&nbsp;</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 16, width: 80 }} >{item.versionName}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.majorVersion}.{item.majorVersion}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.blkSize}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14, width: 55 }} >{item.uploadIndex}/{item.uploadMax}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.retryIndex}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.gmtModified}~{item.gmtCreate}</span>
                    &nbsp;&nbsp;&nbsp;<MyIcon style={{ float: 'left' }} type={myImg} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                </Modal>
            </div>
        )
    }
}
export default FirmwareStatus