/**
 *   实时视频框
 */
import React from 'react';
import { Button, Dropdown, Menu, Icon } from 'antd';
import ComponentWorkspace from '../MyComponentFactoryPackage/ComponentWorkspace.js'
import './RealTimeMonitorning.css'
var player
var player1
var flag = true
class VideoModel extends ComponentWorkspace {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };

    }
    //停止
    position2(value) {
        let { videoToken } = this.props;
        let paramsStop = {
            // token: '925f--1',
            token: videoToken.token,
            session: this.state.session_h5stream,
            action: 'stop',
            speed: 0.5,
        }
        this.h5stream_remoteControl(this, paramsStop); //上
    }
    //左 
    sendPanLeftMsg() {
        let { videoToken } = this.props;
        console.log(videoToken, 'videoToken这是穿过来的值')
        let paramsLeft = {
            // token: '925f--1',
            token: videoToken.token,
            session: this.state.session_h5stream,
            action: 'left',
            speed: 0.5,
        }
        this.h5stream_remoteControl(this, paramsLeft); //上

    }
    sendPanRightMsg() {
        // 右
        let { videoToken } = this.props;
        let paramsRight = {
            //  token: '925f--1',
            token: videoToken.token,
            session: this.state.session_h5stream,
            action: 'right',
            speed: 0.5,
        }
        this.h5stream_remoteControl(this, paramsRight); //上

    }
    sendTiltDownMsg() {
        // 下
        let { videoToken } = this.props;
        let paramsDown = {
            // token: '925f--1',
            token: videoToken.token,
            session: this.state.session_h5stream,
            action: 'down',
            speed: 0.5,
        }
        this.h5stream_remoteControl(this, paramsDown); //上
    }
    sendTiltUpMsg() {
        // 上
        let { videoToken } = this.props;
        let paramsUp = {
            // token: '925f--1',
            token: videoToken.token,
            session: this.state.session_h5stream,
            action: 'up',
            speed: 0.5,
        }
        this.h5stream_remoteControl(this, paramsUp); //上
    }
    sendTiltleftUpMsg() {
        // 左上
        let { videoToken } = this.props;
        let paramsUpLeft = {
            // token: '925f--1',
            token: videoToken.token,
            session: this.state.session_h5stream,
            action: 'upleft',
            speed: 0.5,
        }
        this.h5stream_remoteControl(this, paramsUpLeft); //上
    }
    // 左上 h5升级到11.2以上版本才支持斜向动作
    sendTiltLeftUpMsg() {
        this.sendTiltleftUpMsg();

    }
    // 右上
    sendTiltRightUpMsg() {
        this.sendPanRightMsg()
        this.sendTiltUpMsg()

    }
    // 左下
    sendPanLeftDownMsg() {
        this.sendPanLeftMsg()
        this.sendTiltDownMsg()
    }
    // 右下
    sendPanRightDownMsg() {
        this.sendPanRightMsg()
        this.sendTiltDownMsg()
    }
    // 获取设置点
    async getPresent(e) {
        let { videoToken } = this.props;
        let param = {
            cameraId: videoToken.id,
            presetPosition: e.target.value,
        }
        let data = await getPresent(param)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {
            console.log(data.data, '设置点信息')
            this.setState({
                presetId: data.data && data.data[0] ? data.data[0].id : ''
            });
        }
    }
    //保存预支点 
    async addUpdatePreset(e) {
        let { inputValue } = this.state;
        let { videoToken } = this.props;

        let param = {
            cameraId: videoToken.id,  //摄像头Id
            presetName: inputValue, //名称
            presetToken: videoToken.token, //token 
            presetPosition: inputValue, //预支点的位置


        }
        let data = await addUpdatePreset(param)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {
            message.success(data.msg)
        } else {
            message.error(data.msg)
        }
    }
    // 删除预支点deletePresentById
    async deletePresentById() {
        let { videoToken, inputValue, presetId } = this.state;

        let param = {
            id: presetId,  //摄像头Id
        }
        let data = await deletePresentById(param)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {
            console.log(data.data, '保存预支点信息')
            message.success(data.msg)

        } else {
            message.error(data.msg)
        }
    }




    render() {
        console.log(this.props.videoToken, '------------videoToken-------------');

        return (
            <div ref={(node) => { this.div = node; }} className={"ps-video-contNew"} id='cameraParent' onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} onDragEnter={this.onDragEnter} onDrag={this.onDragStart}>

                <div id='show_title' >

                    <div className="ps-video-tools_child">
                        {/* 上 */}
                        <Button
                            type="primary"
                            className="ps-video-up"
                            icon="caret-up"
                            onMouseDown={this.sendTiltUpMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        />
                        {/* 下 */}
                        <Button
                            type="primary"
                            className="ps-video-down"
                            icon="caret-down"
                            onMouseDown={this.sendTiltDownMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        />
                        {/* 左 */}
                        <Button
                            type="primary"
                            className="ps-video-left"
                            icon="caret-left"
                            onMouseDown={this.sendPanLeftMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        />
                        {/* 右 */}
                        <Button
                            type="primary"
                            className="ps-video-right"
                            icon="caret-right"
                            onMouseDown={this.sendPanRightMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        />
                        <div className="screen-cap" />
                        {/* 左上 */}
                        {/* <Button
                            type="primary"
                            className="ps-video-leftUp"
                            icon="caret-up"
                            onMouseDown={this.sendTiltLeftUpMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        /> */}
                        {/* 右下 */}
                        {/* <Button
                            type="primary"
                            className="ps-video-rightUp"
                            icon="caret-down"
                            onMouseDown={this.sendTiltRightUpMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        /> */}
                        {/* 左下 */}
                        {/* <Button
                            type="primary"
                            className="ps-video-leftDown"
                            icon="caret-left"
                            onMouseDown={this.sendPanLeftDownMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        /> */}
                        {/* 右下 */}
                        {/* <Button
                            type="primary"
                            className="ps-video-rightDown"
                            icon="caret-right"
                            onMouseDown={this.sendPanRightDownMsg.bind(this)} onMouseUp={this.position2.bind(this)}
                        /> */}
                    </div>

                    {/* <Button onClick={this.menuItemClick.bind(this,1)} type="primary">主码流</Button>   <Button onClick={this.menuItemClick.bind(this,2)} type="primary">辅码流</Button> */}

                    {/* <Dropdown overlay={menu} trigger={['click']}  placement="topCenter">
                    <Button className="ps-video-medi" type="primary" ghost>选择码流</Button>
                </Dropdown> */}
                    {/* <Button  className="ps-video-medi2">{this.props.titleData ? this.props.titleData.title : ''}</Button> */}
                    <Button className="ps-video-medi1" type="primary" onClick={this.closeVideo.bind(this)} ghost><Icon type="close" /></Button>
                </div>
            </div>
        );
    }

    createH5Video() {
        let { videoToken } = this.props;
        console.log(videoToken, '看看videoTokenvideoTokenvideoTokenvideoToken--------')
        var parent = document.getElementById('cameraParent');
        let child = document.createElement('video');
        child.setAttribute("id", 'camera');
        parent.appendChild(child);
        var conf = {
            // videoid:'video1',
            videoid: child.getAttribute("id"),
            protocol: 'http:', //http: or https:
            host: '116.228.75.54:8090',
            // host:this.props.inOutHost.host,
            rootpath: '/', // '/' or window.location.pathname
            token: videoToken.token,
            // token:  this.token ?  this.token : this.props.videoData.cameraTokenHigh,
            hlsver: 'v1', //v1 is for ts, v2 is for fmp4
            session: '5bf7ef39-4640-432b-b70c-c7456f8f621f' //session got from login
        };
        player = new H5sPlayerWS(conf);
        if (player) {
            player.connect();
        }

    }


    // 关闭窗口
    closeVideo(e) {
        console.log('------------------closeVideo-------running----------')
        var parent = document.getElementById('cameraParent');
        var child = document.getElementById('camera');
        parent.removeChild(child);
        if (player) {
            player.disconnect()
        }


    }
    // 移入
    mouseEnter() {
        let obj = document.getElementById('show_title')
        obj.style.position = 'absolute'
        obj.style.height = '35px';
        obj.style.background = '#808080'
        obj.style.zIndex = '9999999'
        obj.style.border = '1px solid #e8e8e8';
        obj.style.bottom = '0px';
        obj.style.left = '0px';
        obj.style.width = '100%'
        obj.style.display = 'block';
    }
    // 移出
    onMouseLeave() {
        document.getElementById('show_title').style.display = 'none'
    }
    componentDidMount() {
        this.h5stream_login(this);
        document.getElementById('show_title').style.display = 'none'
        this.createH5Video();

    }
    componentWillUnmount() {
        if (player) {
            player.disconnect()
        }
        this.closeVideo()

    }
    componentDidUpdate(prevProps, prevState) {
        //  console.log(prevProps.titleData,'prevProps.titleData上个的值')         let {videoToken}=this.props;

        if (this.props.videoToken !== prevProps.videoToken) {
            if (player) {
                player.disconnect()
            }

            this.closeVideo()
            this.token = null
            this.createH5Video()
            // player.connect()
        }
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.videoToken !== nextProps.videoToken) {
            return true;
        } else {
            return false;
        }
    }

    componentWillUpdate(nextProps, nextState) {

        // if(this.props.videoData==nextProps.videoData){
        //         player.disconnect();
        //         this.token=null
        //         this.createH5Video()
        // }
    }

    onDragEnter(e) {
        console.log(e, '00000')
    }
    onDragStart(e) {
        console.log(e)
    }





}
export default VideoModel;