import React, { Component } from 'react'
import {selectAreaSimple} from '../data/dataStore'
import {Select} from 'antd'
const {Option} = Select
export default class portSelect extends Component {
    constructor(props){
        super(props)
        this.state={
            portData:[],
        }
    }
    componentDidMount(){
        this.selectAreaSimple()
    }
    // 资源结构树
    async selectAreaSimple(){
        let params={
        }
        let data = await selectAreaSimple(params)
        .then(res => {
            return res.json();
        })
        .catch(ex => { });
        if(data&&data.code==200&&data.data.length>0){
            this.setState({
                portData:data.data
            })
        }else if(data.code==500){
            message.error('用户未登录')
            this.props.history.push('/login')
        }
    }
    
    render() {
        let {portData}=this.state;
        return (
            <div>
                <Select style={{width:200}} {...this.props}>
                    {
                        portData.map((item)=>(
                        <Option key={item.id} value={item.id}>{item.areaName}</Option>
                        ))
                    }
                </Select>
            </div>
        )
    }
}
