// 7天统计信息查询
import React, { Component } from 'react'
import './css/system.css'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload } from 'antd';
import moment from 'moment'
import {
    select7DaysDataMsg
} from '../../data/dataStore'
import {defaultTextAlign} from "ol/render/canvas";
import $ from "jquery";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class Select7daysDataMsg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            historyShow: false,
            deleteData: {},
            historyData: {},
            selectedKeys: [],
            cardNumber:'',
            taskNum:'',
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: '',
            userGroupData: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
            trajectoryDateList: [
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
            ],//上报时间
            flowList: [],
            // 诊断信息
            warnMsg:'',
        }
        this.select7DaysDataMsg = this.select7DaysDataMsg.bind(this)
    }
    componentDidMount() {
        //7天内统计信息
        // this.select7DaysDataMsg()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.exportBdCardList(names)
        this.setState({
            name: names
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.select7DaysDataMsg()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }
    // 分页查询
    async select7DaysDataMsg(value, type) {
        let { pagination} = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await select7DaysDataMsg(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            console.log("<<<"+JSON.stringify(data))
            let selectedKeys = []
            //remark--->data.row
            let newData = this.setKey(data.row.shipInfo7DayDTOList);
            selectedKeys = this.getdefSelectKey(data.row.shipInfo7DayDTOList)
            //let errMsg = data.row.remark;
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys,
                        warnMsg: data.row.remark

                    }
                )
            } else {

                this.setState({
                        //给dataSource赋值
                        dataSource: data.row.shipInfo7DayDTOList,
                        userGroupData: data.row.shipInfo7DayDTOList,
                        UallArr: data.row.shipInfo7DayDTOList,
                        ulshow: false,
                        pageTotal: data.total,
                        selectedKeys,
                        warnMsg: data.row.remark
                    }
                )
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }else if (data.code == 501) {
             message.error( data.message)
        }
    }

    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }

    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.exportBdCardList()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.select7DaysDataMsg()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }

    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.exportBdCardList()
        });
    }

    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;
                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }

    render() {
        let {nowModalData,userGroupData,selectedKeys,ulshow,selectMenuDataObj = {} ,pageTotal} = this.state;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '日期',
                key: 'reportDate',
                dataIndex: 'reportDate',
                render: (reportDate) => {
                    if (reportDate != null){
                        let time = moment(parseInt(reportDate)).format('YYYY-MM-DD')
                        return time
                    }
                }
            },
            {
                title: '上报成功率',
                key: 'successRate',
                dataIndex: 'successRate',

            },
            {
                title: '当日里程',
                key: 'mileage',
                dataIndex: 'mileage',
            },

            {
                title: '离线次数',
                key: 'offlineFrequency',
                dataIndex: 'offlineFrequency',
            },

            {
                title: '离线总时长',
                key: 'offlineTimes',
                dataIndex: 'offlineTimes',
            },
            {
                title: '拆卸数量',
                key: 'tearDown',
                dataIndex: 'tearDown',

            },
            {
                title: '太阳能充电',
                key: 'battery0',
                dataIndex: 'battery0',
            },
            {
                title: '电池包1',
                key: 'battery1',
                dataIndex: 'battery1',
            },
            {
                title: '电池包2',
                key: 'battery2',
                dataIndex: 'battery2',
            },
            {
                title: '外部供电数量',
                key: 'externalPower',
                dataIndex: 'externalPower',
            },
            {
                title: '电池供电数量',
                key: 'batteryQuantity',
                dataIndex: 'batteryQuantity',
            },
        ]

        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">7天统计信息</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                北斗卡号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>
                        {/*<div className="cardManagement_title_menuSelect_part">*/}
                        {/*    <div className="cardManagement_title_menuSelect_part_div">*/}
                        {/*        时间：*/}
                        {/*        <Input placeholder="请输入" style={{width: '70%'}} name='taskNum' onChange={value => this._changeValue(value)}/>*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:60,
                                    border: 'unset'
                                }}
                                onClick={() => this.select7DaysDataMsg()}
                            >查询</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:90,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                 }}
                                onClick={() =>{
                                    this.setState({
                                        cardNumber: '',

                                    },() =>{

                                    })
                                }}

                            >重置</Button>

                        </div>

                    </div>

                </div>

                <div className="cardIIManagement">
                    <Table
                        columns={columns}
                        //rowSelection={distribute_rowSelect}
                        onRowClick={this.onRowClick}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:515}}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.select7DaysDataMsg(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />

                    <h3 style={{textAlign:"center",color:"red"}}>诊断信息：{this.state.warnMsg}</h3>
                </div>

            </div>
        )
    }
}
export default Select7daysDataMsg