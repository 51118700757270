import React, { Component } from 'react'

import 'ol/ol.css';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Map from './map_TechnicalSupport/OL_Map.js';
import BaseSubPage from '../../BaseSubPage.js'
import {
    fromLonLat,
    get,
    transform,
    getTransform
} from 'ol/proj.js';
import {
    Modal, Icon, Button, Row, Col, Table, Radio, Menu, Dropdown, message
} from 'antd'
import Measure from '../../tools/Measure'
import DrawPolygonSearch from '../../tools/DrawPolygonSearch'
import SelectPointAnimation from '../../tools/SelectPointAnimation'
import PointAnimation from '../../tools/PointAnimation'
import TrajectoryAnimation from '../../tools/TrajectoryAnimation'
// import TrajectoryAnimation from '../../tools/TrajectoryAnimationNew'
import {//前后端数据交互接口
    getProblemDetail,
    getAreaListNew,
    getCameraVOByCondition,
    getCameraAllAlarmLogById,
} from '../interfaceRequest/RequestImpl';

// import imgMap_ModalBg from '../../../../img/imgMap/imgModal/bg_fbt_tk.png';
// import imgMap_ModalX from '../../../../img/imgMap/imgModal/bg_tk_shipin.png';
import TileSource from 'ol/source/Tile';
import { none } from 'ol/centerconstraint';

import './MapFactoryImpl.css';
/**
 *@Description:{ }
 *@InterfaceAddress:{ }
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2019-12-27 13:55:39
*/
class MapFactoryImpl extends BaseSubPage {
    constructor(props, context) {
        super(props, context);
        this.state = {
            layersResourceBase_zoom_control: {},//各个图层当前资源库
            text_Measure_DropdownButton: '测量工具',
            text_Measure_DropdownButton_search: '搜索工具',
            attribute: this.props.config_JSON.map_componentConfig.attribute,
            map: null,
            visible: false,
            imgIndex: -1,
            pointDetailData: false,//弹窗基本信息
            modalData_cameraAllAlarmLogData: false,//弹窗报警列表
            mapStyle: {
                width: '100% !important',
                height: '100% !important',
            }
        };
        this.map = null;


    }
    setWFSFeaturesDict = (item, target) => {
        //.log(item, "----------MapFactoryImpl here : features")
        // let dict_library_wfsFeatures = {}
        // if (target == "river_name_liu") {
        //     item.map((item) => {
        //         let key = item.values_['河名']
        //         let value = item
        //         dict_library_wfsFeatures = {
        //             ...dict_library_wfsFeatures,
        //             [key]: value
        //         }
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures, '------------------setWFSFeaturesDict- all river--')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures, target)
        //     })
        // }
        // else if (target == "zhenjie") {
        //     item.map((item) => {
        //         let key = item.values_["镇名"]
        //         let value = item
        //         dict_library_wfsFeatures = {
        //             ...dict_library_wfsFeatures,
        //             [key]: value
        //         }
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures_zhenjie: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures_zhenjie, '-----------------------zhenjie ')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures_zhenjie, target)
        //     })

        // }
        // else if (target == "zhenguanpianqu") {
        //     item.map((item) => {
        //         let key = item.values_.pianqu;
        //         let value = item
        //         dict_library_wfsFeatures = {
        //             ...dict_library_wfsFeatures,
        //             [key]: value
        //         }
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures_zhenguanpianqu: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures_zhenguanpianqu, '-----------------------zhenguanpianqu ')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures_zhenguanpianqu, target)
        //     })

        // }
        // else if (target == "areaBorder_city") {
        //     Object.keys(item).map((key) => {
        //         let valueC = {};
        //         valueC.geometry = {
        //             coordinates: item[key]
        //         }
        //         valueC.target = target;
        //         let value = [];
        //         value = value.concat(valueC);
        //         if (!key.includes('镇') && key.includes('片')) {
        //             if (key.includes('练塘片')) {
        //                 key = '练塘片';
        //                 if (dict_library_wfsFeatures[key]) {
        //                     dict_library_wfsFeatures[key] = dict_library_wfsFeatures[key].concat(value);
        //                 } else {
        //                     dict_library_wfsFeatures = {
        //                         ...dict_library_wfsFeatures,
        //                         [key]: value
        //                     }
        //                 }
        //             } else {
        //                 dict_library_wfsFeatures = {
        //                     ...dict_library_wfsFeatures,
        //                     [key]: value
        //                 }
        //             }
        //         }
        //         //.log(dict_library_wfsFeatures, '------------------练塘片----dict_library_wfsFeatures---')
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures_areaBorder_city: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures_areaBorder_city, '-----------------------areaBorder_city ')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures_areaBorder_city, target)
        //     })

        // }
    }

    showModal = () => {
        //.log(this.state.pointDetailDatas, '---------------------pointDetailData')
        this.setState({
            visible: true,
        });
    }
    hideModal = () => {
        this.setState({
            visible: false,
        });
    }
    // showModal = (imgModal, imgList) => {
    //     let imgIndex = this.state.imgIndex;
    //     if (imgList && imgIndex == -1) {
    //         imgIndex = 0;
    //     }
    //     if (imgModal) {
    //         this.setState({
    //             visible: true,
    //             imgModal: imgModal,
    //             imgList: imgList,
    //             imgIndex: imgIndex
    //         });
    //     } else {
    //         imgModal = this.state.imgList[this.state.imgIndex];
    //         this.setState({
    //             visible: true,
    //             imgModal: imgModal,
    //         });
    //     }
    // };
    // hideModal = () => {
    //     let imgIndex = -1;
    //     let imgList = [];
    //     this.setState({
    //         visible: false,
    //         imgIndex: imgIndex,
    //         imgList: imgList,
    //     });
    // };
    changeImg_Modal_left = () => {
        let imgIndex = this.state.imgIndex - 1;
        //.log(imgIndex, this.state.imgList.length, '-------changeImg_Modal_left----imgIndex--')
        this.setState({
            imgIndex: imgIndex,
        }, () => {
            this.showModal();
        })
    }
    changeImg_Modal_right = () => {
        let imgIndex = this.state.imgIndex + 1;
        //.log(imgIndex, this.state.imgList.length, '-------changeImg_Modal_right----imgIndex--')
        this.setState({
            imgIndex: imgIndex,
        }, () => {
            this.showModal();
        })
    }

    setMapStyle = (style) => {
        let mapStyle = this.state.mapStyle;
        if (style) {
            mapStyle = style;
        }
        this.setState({
            mapStyle: mapStyle,
        }, () => {
            setTimeout(() => {
                this.map.map.updateSize();
            }, 100);
            // this.createMap();
            // window.location.reload();
        })
    }

    playerMapPointCarera = () => {
        if (this.props.playerMapPointCarera) {
            this.props.playerMapPointCarera(this.state.pointDetailData);
        }
    }

    click_pointDetailDatasList = (e, data) => {
        console.log(data, '------------click_pointDetailDatasList--------');
        let id = data.id;
        let pointDetailData = {
            ...this.state.pointDetailDatas[id]
        };
        this.setState({
            pointDetailData: pointDetailData,
            rowId: id,
        }, () => {
            //.log(this.state.pointDetailData, '--------click_pointDetailDatasList here----pointDetailData-----')
        });
    }
    setRowClassName = (record) => {
        return record.id === this.state.rowId ? 'clickRowStyl' : '';
    }

    handleButtonClick_Measure = (value) => {
        console.log(value, this.map.layers.vector_draw, '-----------handleButtonClick_Measure----------');
        let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
        if (text_Measure_DropdownButton == '取消测量') {
            this.setState({
                text_Measure_DropdownButton: '测量工具',
            }, () => {
            });
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure();
            this.map.layers.vector_draw.setVisible(false);
            this.map.layers.vector_draw.getSource().clear();
            this.map.onClick_getFeatureInfo(this);
        }
    }

    handleMenuClick_Measure = (value) => {
        let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
        console.log(text_Measure_DropdownButton, '------------handleMenuClick_Measure---------');
        if (text_Measure_DropdownButton == '测量工具') {
            this.setState({
                text_Measure_DropdownButton: '取消测量',
            }, () => {
                this.map.unClick_getFeatureInfo(this);
            });
        } else {
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure();
            this.map.layers.vector_draw.setVisible(false);
            this.map.layers.vector_draw.getSource().clear();
        }
        let valueKey = '';
        switch (value) {
            case 'range':
                valueKey = 'LineString ';
                ; break;
            case 'area':
                valueKey = 'area';
                ; break;
        }
        Measure.measure(this.map, valueKey);
        // DrawPolygonSearch.measure(this, 'Circle');
    }
    handleButtonClick_Measure_search = (value) => {
        console.log(value, this.map.layers.vector_draw_search, '-----------handleButtonClick_Measure----------');
        let text_Measure_DropdownButton_search = this.state.text_Measure_DropdownButton_search;
        if (text_Measure_DropdownButton_search == '取消搜索') {
            this.setState({
                text_Measure_DropdownButton_search: '搜索工具',
            }, () => {
            });
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure_search();
            this.map.layers.vector_draw_search.setVisible(false);
            this.map.layers.vector_draw_search.getSource().clear();
            this.map.onClick_getFeatureInfo(this);
        }
    }

    handleMenuClick_Measure_search = (value) => {
        let text_Measure_DropdownButton_search = this.state.text_Measure_DropdownButton_search;
        console.log(text_Measure_DropdownButton_search, '------------handleMenuClick_Measure---------');
        if (text_Measure_DropdownButton_search == '搜索工具') {
            this.setState({
                text_Measure_DropdownButton_search: '取消搜索',
            }, () => {
                this.map.unClick_getFeatureInfo(this);
            });
        } else {
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure_search();
            this.map.layers.vector_draw_search.setVisible(false);
            this.map.layers.vector_draw_search.getSource().clear();
        }
        DrawPolygonSearch.measure(this, value);
    }

    setSearchByPolygonAndCircleParams = (params) => {
        console.log(params, '--------params--------------------setSearchByPolygonAndCircleParams---------------');
        this.props.parentObj.searchByPolygonAndCircle(params);
    }

    render() {

        const columns_modal_pointData = [
            {
                title: '报警时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    let color = '#E2EEFE';
                    return (
                        <span
                            style={ {
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            } }
                        >{ text }</span>
                    )
                }
            },
            {
                title: '报警类型',
                dataIndex: 'alarmSubTypeName',
                key: 'alarmSubTypeName',
                align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    let color = 'red';
                    return (
                        <span
                            style={ {
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            } }
                        >{ text }</span>
                    )
                }
            },
            {
                title: '持续时间',
                dataIndex: 'duration',
                key: 'duration',
                align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    let color = '#FC2A2A';
                    return (
                        <span
                            style={ {
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            } }
                        >{ text }</span>
                    )
                }
            },
        ];


        let { config_JSON } = this.props;
        let heightBody = this.state.attribute.height;
        let cameraRunningImg = '';
        let cameraUnRunningImg = '';

        //点击地图摄像头信息列表组

        let pointDetailDatasList = [];
        if (this.state.pointDetailDatas) {
            Object.keys(this.state.pointDetailDatas).map((key) => {
                pointDetailDatasList = pointDetailDatasList.concat(this.state.pointDetailDatas[key]);
            }
                // <Radio.Button value={ key }
                //     style={ {
                //         // width: 180,
                //         // height: 40,
                //         // position: 'absolute',
                //         // top: 0,
                //         // left: 48,
                //         fontFamily: 'SourceHanSansCN-Regular',
                //         fontSize: 16,
                //         color: '#27C5FF',
                //         marginTop: '5%',
                //         backgroundColor: 'rgba(15,39,96,0.5)',
                //         // backgroundImage: '-webkit-linear-gradient(top, #F1ECEC, #0C67BB)',
                //         // WebkitTextFillColor: 'transparent',
                //         // WebkitBackgroundClip: 'text',
                //         textAlign: 'center',
                //         verticalAlign: 'middle',
                //         // lineHeight: '40px',
                //         // vertical- align: middle;display: table-cell;
                //     } }
                // >{ this.state.pointDetailDatas[key].name }</Radio.Button>
            );
            //.log(pointDetailDatasList, '----------pointDetailDatasList----')
        }
        let name = '';
        if (this.state.pointDetailData) {
            name = this.state.pointDetailData.name;
        }
        const columns_pointDetailDatasList = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                render: (text, record) => {
                    let color = '#27C5FF';
                    if (record.networkState == 0) {
                        color = 'red';
                    }
                    return (
                        <span
                            style={ {
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14,
                                cursor: 'pointer'
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            } }
                        >
                            <img src={ record.networkState == 0 ? cameraUnRunningImg : cameraRunningImg }
                                style={ {

                                } }>
                            </img>
                            &nbsp;
                            { text }
                        </span>
                    )
                }
            },
        ]

        const menu_Measure = (
            <Menu onClick={ this.handleMenuClick_Measure }>
                <Menu.Item key="area">
                    <Icon type="rollback" />
                    面积
                </Menu.Item>
                <Menu.Item key="LineString">
                    <Icon type="arrows-alt" />
                    长度
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="div_Map">
                <div ref={ (node) => { this.div = node; } } id={ this.state.attribute.target } style={ {
                    width: this.state.mapStyle.width,
                    // height: this.state.mapStyle.height,
                    height: heightBody,
                } }>
                </div>
                {
                    typeof (this.state.attribute.createDefaultTool) != 'undefined' ? this.state.attribute.createDefaultTool : true
                        &&
                        <div id="mouse-position" class="mouse-position-wrapper">
                            <div class="custom-mouse-position"></div>
                        </div>
                }
                {/* <div id="mapControl_scaleLineControl" class="MapControl_scaleLineControl">
                    <div class="custom-MapControl_scaleLineControl"></div>
                </div> */}
                {/* <div id="mapControl_OverviewMap" class="MapControl_OverviewMap">
                    <div class="custom-MapControl_OverviewMap"></div>
                </div> */}
                <div id="menu">
                    <Dropdown.Button
                        overlay={ menu_Measure }
                        icon={ <Icon type="edit" theme="twoTone" /> }
                        onClick={ this.handleButtonClick_Measure }
                        style={ {
                            display: 'none'
                        } }
                    >
                        { this.state.text_Measure_DropdownButton }
                    </Dropdown.Button>
                </div>
            </div >

        );
    }

    closeAllLayers = () => {
        this.map.closeAllLayers()
    }

    changeZoomAndCenter_for_geom = (polygonsDatas) => {
        this.map.changeZoomAndCenter_for_geom(polygonsDatas);
    }

    showMap_DBData = (polygonsDatas, params) => {
        //.log(polygonsDatas, "----------------------------------showMap_DBData polygonsDatas")
        // this.map.removeLayerAll()
        // this.map.removeLayerAll()
        return this.map.addLayer_Polygon_DBData(polygonsDatas, params);
        // this.map.addIcon_FeatureAndStyle()
    }
    clearMap = (noNeedRemoveList) => {
        this.map.removeAll(noNeedRemoveList);
        this.clear_draw_getAreaPointData();
        this.map.changeZoomAndCenter(this.map, this.state.attribute.zoom, undefined, JSON.parse(this.state.attribute.center))
        TrajectoryAnimation.clear_TrajectoryAnimation(this);
        //SelectPointAnimation.clearAllListener(this);
    }
    showMap_border = (features) => {
        let target = 'vectorBorder';
        //.log(features, '-----------------------------showMap_border')
        this.map.addLayer_polygon_border(features, target);
    }

    showMap_trajectory_points = (points) => {
        if (points) {
        } else {
            points = [
                {
                    time: '2020-03-30 10:00:00',
                    lon: 102.245722,
                    lat: 27.865106,
                },
                {
                    time: '2020-03-30 10:05:00',
                    lon: 102.245463,
                    lat: 27.788754,
                },
                {
                    time: '2020-03-30 10:08:00',
                    lon: 102.381849,
                    lat: 27.808182,
                },
                {
                    time: '2020-03-30 10:12:00',
                    lon: 102.385849,
                    lat: 27.812182,
                },
                {
                    time: '2020-03-30 10:30:00',
                    lon: 102.352495,
                    lat: 27.876032,
                },
            ]
        }
        let vectorTrajectory = this.map.layers.vectorTrajectory;
        // this.map.changeZoomAndCenter_point(point,zoom,noWorkingZoom)
        this.map.changeZoomAndCenter(this, 10, points, undefined, true, offset)
        TrajectoryAnimation.clear_TrajectoryAnimation();
        TrajectoryAnimation.addTrajectoryAnimation(this.map, vectorTrajectory, points);
    }
    start_trajectory_points = () => {
        TrajectoryAnimation.start_TrajectoryAnimation();
    }
    stop_trajectory_points = () => {
        TrajectoryAnimation.stop_TrajectoryAnimation();
    }

    showMap_addPoints_zoom_control = (isOverRule) => {
        //.log(points, '-----------------------showMap_addPoints here points');
        // //SelectPointAnimation.clearAllListener(this);
        if (this.state.divOverlay) {
            this.state.divOverlay.setAttribute("style", "display:none")
        }
        let layersResourceBase_zoom_control = this.state.layersResourceBase_zoom_control;
        Object.keys(layersResourceBase_zoom_control).map(key => {
            let points = layersResourceBase_zoom_control[key].points;
            let params = layersResourceBase_zoom_control[key].params;
            this.map.removeLayerAll()
            this.map.removeLayerWithTarget(key)
            if (points && points.length > 0) {
                this.map.addIcon_FeatureAndStyle(points, params, isOverRule);
            }
        })
    }
    showMap_addPoints = (points, params) => {
        //.log(points, '-----------------------showMap_addPoints here points');
        // //SelectPointAnimation.clearAllListener(this);
        if (this.state.divOverlay) {
            this.state.divOverlay.setAttribute("style", "display:none")
        }
        let layersResourceBase_zoom_control = this.state.layersResourceBase_zoom_control;
        layersResourceBase_zoom_control[params.target] = {
            'points': points,
            'params': params,
        };
        this.setState({
            layersResourceBase_zoom_control: layersResourceBase_zoom_control,
        })
        this.map.removeLayerAll()
        this.map.removeLayerWithTarget('vectorCluster')
        this.map.removeLayerWithTarget(params.target)
        if (points && points.length > 0) {
            this.map.addIcon_FeatureAndStyle(points, params);
        }
    }

    showMap_addPoint = (point, params) => {
        if (point) {
            this.map.addIcon_point(point, params)//画点)//画点
        }
    }
    //创建网格图层
    createGraticuleLayer = (params) => {
        this.map.createGraticuleLayer(params)
    }

    //开启一组图层
    openLayerVisibleByGroupName = (name, childName) => {
        this.map.openLayerVisibleByGroupName(name, childName);
    }
    //关闭一组图层
    closeLayerVisibleByGroupName = (name, childName, isClear) => {
        this.map.closeLayerVisibleByGroupName(name, childName, isClear);
    }
    //开启图层
    openLayerVisibleBylayerName = (name) => {
        this.map.openLayerVisibleBylayerName(name);
    }
    //关闭图层
    closeLayerVisibleBylayerName = (name) => {
        this.map.closeLayerVisibleBylayerName(name);
    }
    //根据点位置调整视窗
    changeZoomAndCenter_point = (point, zoom, noWorkingZoom) => {
        this.map.changeZoomAndCenter_point(point, zoom, noWorkingZoom)
    }
    autoChangeStyle = (isFlicker) => {
        this.map.autoChangeLayerStyle(isFlicker)
        // this.map.getFitWithExtent()
    }
    autoChangeStyle_border = (isFlicker) => {
        let target = 'vectorBorder';
        this.map.autoChangeLayerStyle_border(isFlicker, target)
        // this.map.getFitWithExtent()
    }

    getPointDetail = (values) => {
        console.log(values, '------id-----------------getPointDetail here-------');
        if (values && values.length > 0 && this.props.parentObj && this.props.parentObj.onClick_map_point) {
            this.props.parentObj.onClick_map_point(values);
        }
    }
    onClick_empty = () => {
        if (this.props.parentObj && this.props.parentObj.onClick_map_empty) {
            this.props.parentObj.onClick_map_empty();
        }
    }
    onMove_empty = () => {
        if (this.props.parentObj && this.props.parentObj.onMove_map_empty) {
            this.props.parentObj.onMove_map_empty();
        }
    }
    addOverlay_withDiv = (params) => {
        this.map.addOverlay_withDiv(params, this)
    }
    addEventChangeResolution = (fun) => {
        return this.map.addEventChangeResolution(this, fun);
    }
    clearEventChangeResolution = (fun, listener) => {
        this.map.clearEventChangeResolution(this, fun, listener);
    }


    componentDidMount() {
        // this.getAreaListNew();
        this.createMap();
        // this.map.createEventChangeResolution(this);
        this.props.componentLoad("map", this);
        // //.log(this.props.setWFSFeaturesDictFinish, '------------------this.props.setWFSFeaturesDictFinish')
        // if (this.props.setWFSFeaturesDictFinish) {
        // } else {
        //     this.map.createWFSLayerDict(this, 'sunt:river_name_liu', 'river_name_liu', '河道大数据分析')
        // }
        // this.map.createWFSLayerDict(this, 'sunt:zhenjie', 'zhenjie', '河道大数据分析镇界')
        // this.map.createWFSLayerDict(this, 'sunt:zhenguanpianqu', 'zhenguanpianqu', '河道大数据分析镇管东中西片区')
        // this.setWFSFeaturesDict(features)
    }

    clearAllListener = () => {
        //SelectPointAnimation.clearAllListener(this.map);
        PointAnimation.clearAllListener(this.map);
    }

    init_addPointAnimation = (point, params) => {
        let target = params.target;
        let pointLayer = undefined;
        if (typeof (params.groupName) == 'undefined') {
            params.groupName = 'layers';
        }
        if (target) {
            if (typeof (pointLayer) == 'undefined') {
                this.map.creatAndAddNewlayer(params);
            }
            pointLayer = this.map[params.groupName][target];
        }
        console.log(pointLayer, point, '--------pointLayer------11-----createMap-----------------------');
        let feature = new Feature({
            geometry: new Point(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857')),
        })
        // PointAnimation.clearAllListener(this.map,params);
        PointAnimation.click_addPointAnimation(this.map, pointLayer, feature, point.id);
    }

    click_addPointAnimation = (point, params) => {
        let pointLayer = undefined;
        let target = params.target;
        if (typeof (params.groupName) == 'undefined') {
            params.groupName = 'layers';
        }
        if (target) {
            if (typeof (pointLayer) == 'undefined') {
                this.map.creatAndAddNewlayer(params);
            }
            pointLayer = this.map[params.groupName][target];
        }
        console.log(pointLayer, point, '--------pointLayer------11-----createMap-----------------------');
        let feature = new Feature({
            geometry: new Point(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857')),
        })
        SelectPointAnimation.clearAllListener(this.map);
        SelectPointAnimation.click_addPointAnimation(this.map, pointLayer, feature, point.id);
    }

    addHeatMapLayer = (data, params) => {
        this.map.addHeatMapLayer(data, params);
    }

    addWMSLayer = (layers) => {
        let attribute = this.state.attribute;
        attribute.layers = layers;
        this.setState({
            attribute
        }, () => {
            Object.keys(layers).map(key => {
                let value = layers[key];
                switch (key) {
                    case 'wms':
                        if (value && value.length > 0) {
                            value.map((item, index) => {
                                this.map.addWMSLayer(item);
                            })
                        }
                        break;
                }
            })
        })
    }
    addTile = (params) => {
        this.map.addTile(params);
    }

    createMap() {
        this.map = new Map({
            target: this.state.attribute.target,
            center: JSON.parse(this.state.attribute.center),
            zoom: parseInt(this.state.attribute.zoom),
            minZoom: parseInt(this.state.attribute.minZoom),
            maxZoom: parseInt(this.state.attribute.maxZoom),
            createDefaultTianDi: typeof (this.state.attribute.createDefaultTianDi) != 'undefined' ? this.state.attribute.createDefaultTianDi : true,//此属性为false表示不需要默认天地图，有配置wms数据创建天地图
            createDefaultTool: typeof (this.state.attribute.createDefaultTool) != 'undefined' ? this.state.attribute.createDefaultTool : true,//此属性为false表示不需要默认天地图，有配置wms数据创建天地图
        });
        // let testUrl = 'http://221.181.88.134:6706/geoserver/sunt/wms?service=WMS'
        // let tileImg = require("../../img/mask_map.png")
        // this.addTile({
        //     url: tileImg,
        //     visible: true,
        //     key: "layer_tileLayer_mask",
        //     opacity: 0.9,
        //     target:'layer_tileLayer_mask',
        //     groupName:'layers_default',
        // });
        // let layersName_qingpu = 'sunt:zuozhantu'
        // // let layersName_qingpu = 'sunt:qpmap_hddsj'
        // this.map.addWMSLayer(testUrl, layersName_qingpu, 99)//青浦区图层
        // let toumingLayerlayersName = 'sunt:river_touming'
        // this.map.addWMSLayer(testUrl, toumingLayerlayersName, 200)//河流名字透明图层
        // //.log(this.props.inspectedRiverSliceData ? '存在' : '不存在', 'this.props.inspectedRiverSliceData')
        this.map.addMapMonitoringEvents(this);
        this.map.addOverLayer("problemOverLayer", this);
        let pointLayer = this.map.layers.vectorImg;
        let vectorTrajectory = this.map.layers.vectorTrajectory;
        console.log(pointLayer, '--------pointLayer-----------createMap-----------------------');
        ////SelectPointAnimation.addPointAnimation(this.map, pointLayer);

        //如果配置文件中存在wms参数，则生成并添加wms
        let layers = this.state.attribute.layers;
        if (layers) {
            this.addWMSLayer(layers);
        }
    };
}

export default MapFactoryImpl