// 离线统计Report管理
import React, { Component } from 'react'
import './css/system.css'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
import {
    selectOfflineInfo
} from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
let format = 'YYYY-MM-DD HH:mm:ss';
class OfflineReportMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            historyShow: false,
            deleteData: {},
            historyData: {},
            selectedKeys: [],
            cardNumber:'',
            taskNum:'',
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: '',
            userGroupData: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
            trajectoryDateList: [

                //本月
                // moment(moment().startOf('month')),
                // moment(moment().endOf('month'))
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
                // moment(moment().endOf('month'))
                // moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss'),

                // moment(moment.add(-1,'M')),
                // moment(moment.add(0,'M'))

            ],//上报时间
            flowList: []
        }
        this.selectOfflineInfo = this.selectOfflineInfo.bind(this)
    }
    componentDidMount() {
        //查询离线信息
        // this.selectOfflineInfo()
    }
    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectOfflineInfo(names)
        this.setState({
            name: names
        })
    }
    // 分页查询
    async selectOfflineInfo(value, type, pageSizeApi, coloums,id) {
        // this.setState({ loading: true })
        let { pagination, regionNameValue, cardNumber, taskNum,pageTotal } = this.state;
        let params = {
            id: id ? id : '',
            cardNumber:   this.state.cardNumber,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
            taskNum: this.state.taskNum
        }
        let data = await selectOfflineInfo(params)

            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.rows)
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys
                    }

                )
            } else {
                this.setState({
                        //给dataSource赋值
                        dataSource: data.rows,
                        userGroupData: data.rows,
                        UallArr: data.rows,
                        ulshow: false,
                        pageTotal: data.total,
                        selectedKeys
                    }

                )
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }

    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }

    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectOfflineInfo()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectOfflineInfo()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }

    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectOfflineInfo()
        });
    }

    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }

    render() {
        let {flowList,cardNumber ,pageIndex, dataSource, modifyData,nowModalData,userGroupData,selectedKeys,deleteAll, UallArr, LabelSize, ulshow, labelData, shipTypeArr,selectMenuDataObj = {} ,pageTotal} = this.state;
        // console.log(modifyData, 'modifyData')
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '卡号',
                key: 'cardNumber',
                dataIndex: 'cardNumber',
            },
            {
                title: '船名',
                key: 'shipName',
                dataIndex: 'shipName',

            },
            {
                title: '区域',
                key: 'regionName',
                dataIndex: 'regionName',
            },

            {
                title: '上次报位时间',
                key: 'lastLocationTime',
                dataIndex: 'lastLocationTime',
            },

            {
                title: '经度',
                key: 'lon',
                dataIndex: 'lon',
            },
            {
                title: '纬度',
                key: 'lat',
                dataIndex: 'lat',

            },
            {
                title: '离线时长',
                key: 'offlineTime',
                dataIndex: 'offlineTime',
            },
        ]

        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">离线统计管理</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                北斗卡号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cardNumber' onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                时间：
                                <Input placeholder="请输入" style={{width: '70%'}} name='taskNum' onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectOfflineInfo()}
                            >超1小时</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectOfflineInfo()}
                            >超2小时</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:60,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectOfflineInfo()}
                            >查询</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:90,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}

                            >重置</Button>

                        </div>

                    </div>

                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }

                <div className="cardIIManagement">
                    <Table
                        columns={columns}
                        rowSelection={distribute_rowSelect}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:515}}
                        pagination={{
                            ...this.state.pagination,
                            // position: ['bottomCenter'],
                            // showQuickJumper: true,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectOfflineInfo(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>

            </div>
        )
    }
}
export default OfflineReportMonitoring