/**
 * Defines the const of rest api base path
 */
// export const RESTAPI_BASE_PATH = process.env.RESTAPI_BASE_PATH || "http://192.168.200.248:9020";
// export const RESTAPI_BASE_PATH = process.env.RESTAPI_BASE_PATH || "http://116.62.65.142:8020";
const REST_API_PATH = {
    "localhost:8099": "polapi",
    "127.0.0.1:8099": "polapi",   
};

let host = window.location.host;
// let host="127.0.0.1";

// export const RESTAPI_BASE_PATH = process.env.RESTAPI_BASE_PATH || "https://xmwebapi.jsure.com";
export const RESTAPI_BASE_PATH = REST_API_PATH['127.0.0.1:8099'];

/**
 * Defines the const of rest api time out
 */
export const RESTAPI_TIME_OUT = 10000;

/**
 * Defines the const of timer interval
 */
export const TIMER_INTERVAL = 4000;

/**
 * Defines the const of rest api time out
 */
export const UPLOAD_RESTAPI_TIME_OUT = 120000;

/**
 * 大屏入口地址
 */

export const BIG_SCREEN_ADDRESS = 'https://221.181.88.134:3000/bdspt/#/';
