import React from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import './assets/css/base.css'
import { connect } from 'react-redux';
import { BrowserRouter, Route, HashRouter } from 'react-router-dom';
import Routers from './router/Routers';
import Header from './componts/header/Header';
class App extends React.Component {
  render() {
    return (
      <div>

        <ConfigProvider locale={zhCN}>
          {/* <Header /> */}
          <Routers />
        </ConfigProvider>
      </div>

    );
  }
}
const initMapStateToProps = state => ({
  // bFoot:state.bFoot,
})
const initMapDispatchToprops = dispatch => ({
})
export default connect(
  initMapStateToProps,
  initMapDispatchToprops
)(App);
