import { postDataNew, getData, postData, postLSData, postFormData, postLsFormData, postLsJSONData } from "../../tools/Tools_POST_Request";

//  查询数据字典
export function postDict(data) {
    return postData("/typeDictionary/getByCategorys", data);
}
/**
 *@Description:{ request data dictionary}
 *@InterfaceAddress:{ }
 *@parameter: { 
                   keys
               }
 *@ClassAuthor: ZDong
 *@Date: 2019-11-16 18:04:56
*/
export async function getDict(keys) {
    if (!Array.isArray(keys)) {
        keys = [keys];
    }
    let nullKey = [],
        returnData = {};
    keys.forEach(key => {
        if (dict[key] != null) {
            returnData[key] = dict[key];
        } else {
            nullKey.push(key);
        }
    });
    if (nullKey.length) {
        let data = await postDict({ categorys: nullKey })
            .then(res => {
                return res.ok ? res.json() : Promise.reject("字典接口出错");
            })
            .then(data => {
                if (data.code === 200) {
                    return data.data;
                }
                return Promise.reject(data.msg);
            });

        dict = { ...dict, ...data };
        return { ...data, ...returnData };
    } else {
        return returnData;
    }
}

/**
 *@Description:{ 禅道：1464
        描述：报警模块-摄像头实时报警查询

}
 *@InterfaceAddress:{ 
            /videoAlarm/getCameraAlarmList
}
 *@parameter: { 
                   String start,
                   String end,
                   Long cameraId(摄像头id),
                   Long damId(区域id),
                   Integer sysType(可为空，为空则查看所有 河道系统1 水闸系统2)

               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-08 10:58:13
*/
export function getCameraAlarmList(data) {
    return postData("/videoAlarm/getCameraAlarmList", data)
}
/**
 *@Description:{ 禅道：1496
                描述：查询摄像头所有信息
  }
 *@InterfaceAddress:{ 
                    接口地址：/cameraPlatform/getAllCameraInfo
 }
 *@parameter: { 
                   id
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-13 14:54:29
*/
// export function getAllCameraInfo(data) {
//     return postData("/cameraPlatform/getAllCameraInfo", data)
// }

/**
 *@Description:{ 禅道：1497
                描述：摄像头弹框-获取指定摄像头的历史报警记录}
 *@InterfaceAddress:{ /videoAlarm/getCameraAllAlarmLogById}
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-13 14:57:13
*/
export function getCameraAllAlarmLogById(data) {
    return postData("/videoAlarm/getCameraAllAlarmLogById", data)
}

//树结构 1477
export function getCameraTree(data) {
    return postData("/cameraPlatform/getCameraTree", data);
}

/**
 *@Description:{ 禅道：3227
                描述：摄像头数据列表，凉山
            }
 *@InterfaceAddress:{ /cameraManage/getTree}
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-13 14:57:13
*/
export function getCameraLSTree(data) {
    return postLSData("/cameraManage/getTree", data);
}

//模糊搜索
export function getCameraListByName(data) {
    return postData("/cameraPlatform/getCameraListByName", data);
}




/**
 *@Description:{ h5 login 请求 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                    user
                    password
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-08 10:48:50
*/
export function getSession_login_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/Login';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/Login'
    }

    return getData(url, data);
}
export function getSession_Keepalive_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/Keepalive';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/Keepalive';
    }

    return getData(url, data);
}
export function getAllSrc_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/GetSrc';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/GetSrc';
    }
    return getData(url, data);
}
export function getSnapshot_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/Snapshot';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/Snapshot'
    }
    return getData(url, data);
}
export function getScreenshots_Snapshot_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/Snapshot';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/Snapshot'
    }
    return getData(url, data);
}
export function getVideoLastImg_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/GetImage';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/GetImage'
    }
    return getData(url, data);
}
export function getVideoRecord_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/Record';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/Record'
    }
    return getData(url, data);
}
export function manualRecordStart_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/ManualRecordStart';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/ManualRecordStart'
    }
    return getData(url, data);
}
export function manualRecordStop_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/ManualRecordStop';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/ManualRecordStop'
    }
    return getData(url, data);
}
export function pathRecord_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/PathRecord';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/PathRecord'
    }
    return getData(url, data);
}
export function remoteControl_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/Ptz';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/Ptz'
    }
    return getData(url, data);
}
export function setPreset_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/SetPreset';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/SetPreset'
    }
    return getData(url, data);
}
export function delPreset_h5stream(data) {
    let url = 'http://116.228.75.54:8090/api/v1/DelPreset';
    if (data.h5stream_url && data.h5stream_url != '') {
        url = data.h5stream_url + '/api/v1/DelPreset'
    }
    return getData(url, data);
}

// 内外网
export function getCameraPara(data) {
    return postData("/facilityUnite/getCameraPara", data);
}
export function getDownloadImg(data) {
    return getData("/api/cameraTask/downloadPic", data);
}

//凉山
/**
 *@Description:{ 禅道：1496
                描述：查询摄像头所有信息
  }
 *@InterfaceAddress:{ 
                    接口地址：/cameraPlatform/getAllCameraInfo
 }
 *@parameter: { 
                   id
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-01-13 14:54:29
*/
export function getAllCameraInfo(data) {
    return postLSData("/cameraPlatform/getAllCameraInfo", data)
}
//码头管理
/**
 *@Description:{ 禅道：3908
    码头选择列表数据 }
    *@InterfaceAddress:{ /terminal/selectTerminalList }
    *@parameter: { 
        
    }
    *@ClassAuthor: ZDong
    *@Date: 2020-03-09 09:29:51
    */
export function getDockSelectListData(data) {
    return postLSData("/terminal/selectTerminalList", data);
}
/**
 *@Description:{ 禅道：4223
                        历史-摄像头列表数据 }
    *@InterfaceAddress:{ /cameraManage/getCameraVOByCondition }
    *@parameter: { 
        
    }
    *@ClassAuthor: ZDong
    *@Date: 2020-03-09 09:29:51
    */
export function getCameraVOByCondition(data) {
    return postLSData("/cameraManage/getCameraVOByCondition", data);
}
/**
 *@Description:{ 禅道：4225
                        历史-录像列表数据 }
    *@InterfaceAddress:{ /cameraTask/getVideoLogByCondition }
    *@parameter: { 
                    cameraId
    }
    *@ClassAuthor: ZDong
    *@Date: 2020-03-09 09:29:51
    */
export function getVideoLogByCondition(data) {
    return postLSData("/cameraTask/getVideoLogByCondition", data);
}

/**
 *@Description:{ 禅道：3511 
                    根据所选择等码头id，时间段，获取船舶所载人数数据}
 *@InterfaceAddress:{ /shipNumber/countTerminalShip }
 *@parameter: { 
                   TerminalId : 码头id,
                   start:开始时间 2020-02-28,
                   end:结束时间
               }
 *@ClassAuthor: ZDong
 *@Date: 2020-03-09 10:11:54
*/
export function getShipInfoCountListData(data) {
    return postLSData("/shipNumber/countTerminalShip", data);
}

/**
 *@Description:{ 禅道：3249
    添加新的码头
}
*@InterfaceAddress:{ /terminal/addTerminal }
*@parameter: { 
    "terminalId": null, 码头id
    "terminalName": null, 码头名称
    "terminalCompany": null, 所属公司
    "companyPhone": null, 公司电话
    "govDepartment": null, 政府部门
    "departPeople": null, 部门电话
    "departPhone": null, 部分负责人
    "remark": null 备注
}
*@ClassAuthor: ZDong
*@Date: 2020-03-10 10:17:00
*/
export function addNewDockWithInfo(data) {
    return postLsJSONData("/terminal/addTerminal", data);
}
/**
 *@Description:{ 禅道：3250
    修改码头信息
}
*@InterfaceAddress:{ /terminal/addTerminal }
*@parameter: { 
    "terminalId": null, 码头id
    "terminalName": null, 码头名称
    "terminalCompany": null, 所属公司
    "companyPhone": null, 公司电话
    "govDepartment": null, 政府部门
    "departPeople": null, 部门电话
    "departPhone": null, 部分负责人
    "remark": null 备注
}
*@ClassAuthor: ZDong
*@Date: 2020-03-10 10:17:00
*/
export function updateDockWithInfo(data) {
    return postLsJSONData("/terminal/updateTerminal", data);
}
/**
 *@Description:{ 禅道：3527
    删除/批量删除码头
}
*@InterfaceAddress:{ /terminal/deleteTerminal }
*@parameter: { 
    "terminalId[]": , 码头id数组
}
*@ClassAuthor: ZDong
*@Date: 2020-03-10 10:17:00
*/
export function delDockWithInfo(data) {
    return postLSData("/terminal/deleteTerminal", data);
}

//凉山-船舶
/**
 *@Description:{ 禅道：3524
    船舶信息列表查询
}
*@InterfaceAddress:{ /ship/selectShipList }
*@parameter: { 模糊搜索 }
*@Data:{
    "shipId": null, 船舶id
    "shipName": null, 船舶名称
    "shipPeople": null, 船主
    "shipPhone": null, 船主电话
    "shipPeopleNumber": null, 船主 身份证
    "shipPeopleAddress": null, 船主地址
    "shipType": null, 船舶种类
    "shipPort": null,船籍港,码头
    "shipSize": null, 船尺寸
    "shipTonnage": null, 船吨位
    "shipDate": null, 船完工日期
    "shipMaterial": null, 船材质
    "hostModel": null, 主机类型
    "hostCount": null,  主机数量
    "hostPower": null, 主机功率
    "workType": null, 作业类型
    "workPlace": null, 作业场所
    "workTime": null, 作业时限
    "shipLoad": null, 船舶载重
    "shipUnit": null, 船舶监督单位
    "shipUnitPeople": null, 船舶监督单位负责人
    "shipUnitPeoplePhone": null,船舶监督单位负责人电话
    "shipAddress": null 船舶位置
}
*@ClassAuthor: ZDong
*@Date: 2020-03-10 10:17:00
*/
export function selectShipInfoList(data) {
    return postLSData("/ship/selectShipList", data);
}
/**
 *@Description:{ 禅道：3516
    船舶承载数据信息列表查询
}
*@InterfaceAddress:{ /shipNumber/selectShipNumberList }
*@parameter: { 模糊搜索 }
*@Data:{
           "id":2,船舶 运输id 唯一
            "shipId": null, 船舶id
            "shipName": null, 船舶名称
            "shipLoad": null,船舶载重
            "terminalName": null, 停靠码头
            "onShipCount": null, 上船人数
            "underShipCount": null,下船人数
            "date": null, 记录时间
            "loadPeopleCount": null, 实际载 人数
            "isLoad": null, 是否超载  1 超载 0 没超载
            "unitPeople": null,  监督单位负责人
            "unitPeoplePhone": null 监督单位负责人电话
}
*@ClassAuthor: ZDong
*@Date: 2020-03-10 10:17:00
*/
export function selectShipDataInfoList(data) {
    return postLSData("/shipNumber/selectShipNumberList", data);
}

/**
 *@Description:{ 禅道：3521
    删除/批量删除码头
}
*@InterfaceAddress:{ /ship/deleteShip }
*@parameter: { 
    "terminalId[]": , 码头id数组
}
*@ClassAuthor: ZDong
*@Date: 2020-03-10 10:17:00
*/
export function delShipWithInfo(data) {
    return postLSData("/ship/deleteShip", data);
}

/**
     *@Description:{ 禅道：3523
                        添加新的船舶
    }
     *@InterfaceAddress:{ /ship/addShip }
     *@parameter: {
                        "shipName": null, 船舶名称
                        "shipPeople": null, 船主
                        "shipPhone": null, 船主电话
                        "shipPeopleNumber": null, 船主 身份证
                        "shipPeopleAddress": null, 船主地址
                        "shipType": null, 船舶种类
                        "terminalId": 船籍港码头 id
                        "shipSize": null, 船尺寸
                        "shipTonnage": null, 船吨位
                        "shipDate": null, 船完工日期 年月日2019-01-24
                        "shipMaterial": null, 船材质
                        "hostModel": null, 主机类型
                        "hostCount": null,  主机数量
                        "hostPower": null, 主机功率
                        "workType": null, 作业类型
                        "workPlace": null, 作业场所
                        "workTime": null, 作业时限
                        "shipLoad": null, 船舶载重
                        "shipUnit": null, 船舶监督单位
                        "shipUnitPeople": null, 船舶监督单位负责人
                        "shipUnitPeoplePhone": null,船舶监督单位负责人电话
                        "shipAddress": null 船舶位置
                   }
     *@ClassAuthor: ZDong
     *@Date: 2020-03-10 10:17:00
    */
export function addNewShipWithInfo(data) {
    return postLsJSONData("/ship/addShip", data);
}

/**
     *@Description:{ 禅道：3522
                        修改船舶信息
    }
     *@InterfaceAddress:{ /ship/updateShip }
     *@parameter: {
                            "shipId": null, 船舶id
                            "shipName": null, 船舶名称
                            "shipPeople": null, 船主
                            "shipPhone": null, 船主电话
                            "shipPeopleNumber": null, 船主 身份证
                            "shipPeopleAddress": null, 船主地址
                            "shipType": null, 船舶种类
                            “terminal_id":"1",”    码头id，船籍港的id
                            "shipSize": null, 船尺寸
                            "shipTonnage": null, 船吨位
                            "shipDate": null, 船完工日期
                            "shipMaterial": null, 船材质
                            "hostModel": null, 主机类型
                            "hostCount": null,  主机数量
                            "hostPower": null, 主机功率
                            "workType": null, 作业类型
                            "workPlace": null, 作业场所
                            "workTime": null, 作业时限
                            "shipLoad": null, 船舶载重
                            "shipUnit": null, 船舶监督单位
                            "shipUnitPeople": null, 船舶监督单位负责人
                            "shipUnitPeoplePhone": null,船舶监督单位负责人电话
                            "shipAddress": null 船舶位置
                   }
     *@ClassAuthor: ZDong
     *@Date: 2020-03-10 10:17:00
    */
export function updateShipWithInfo(data) {
    return postLsJSONData("/ship/updateShip", data);
}