import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './warMap.css'
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import { fetch as fetchPro } from "whatwg-fetch";
import moment from 'moment';
import { Tabs, Icon, message, Switch } from 'antd'
import MyComponentFactory from '../home/Toolkit/MyComponentFactoryPackage/MyComponentFactory';
import ComponentWorkspace from '../home/Toolkit/MyComponentFactoryPackage/ComponentWorkspace';
import config_JSON from './default_JSON/Default_map.json'
import { selectRangeStatisticsToday, alarmSimpleStatistics, homePortMonitor, alarmSimpleStatisticsToday, system_config, alarmStatistics, shipTypeStatistics, allshipPos, specialRegionStatistics, mineShipRegionStatistics, mineAlarmStatistics } from '../../data/dataStore.js'
import { contains } from 'ol/format/filter';
import Stopwatch from './Stopwatch'
moment.locale('zh-cn')
const { TabPane } = Tabs;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_snrm07c5f5c.js'
})
let new_tableData = [];
let flag = true;
const maths = {
    'alarmbbg': "",
    'alarmBg': "",
    'littleBg': "",
    'weather': "",
    'voyageBg': "",
    'title': "",
    'titleBg': "",
    'duoyun': "",
    '100': "",
    '101': "",
    '104': "",
    '302': "",
    '305': "",
    '306': "",
    '307': "",
    '313': "",
    '400': "",
    '401': "",
    '402': "",
    '404': "",
    '501': "",
}
Object.keys(maths).forEach(item => maths[item] = require("../../assets/img/" + item + ".png"))
export default class WarMap extends ComponentWorkspace {
    constructor(props) {
        super(props)
        this.state = {
            tableShow: false,
            startTime: moment().format("YYYY-MM-DD") + ' 00:00:00',
            endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            shipPieData: [],
            roseData: [],
            warnArr: [],
            shipZhuData: {},
            voyageData: {},
            portZhuData: {},
            alarmChecked: true,
        }
        this.layersStatus_history = {}
        let mp3File = require("./mp3/radar_alarm.mp3")
        this.mp3_radar_alarm = new Audio(mp3File);
        this.mp3_radar_alarm.loop = true;//播放 mp3这个音频对象
    }
    componentDidMount() {
        fetchPro('https://free-api.heweather.net/s6/weather/now?location=吴中&key=1db6cbb258d2425aa9af2255a495f6af')
            .then(req => req.json())
            .then(data => {
                this.setState({
                    weatherData: data.HeWeather6[0]
                }, () => {

                    console.log(this.state.weatherData, 'data') //请求到的数据
                })
            })
        let dictData = JSON.parse(sessionStorage.getItem('dictData'))
        this.setState({
            dictData: dictData ? dictData : []
        }, () => {
            // this.alarmSimpleStatisticsToday()
        })
        this.homePortMonitor()
        this.system_config()
        this.alarmSimpleStatistics()
        this.mineShipRegionStatistics()
        this.selectRangeStatisticsToday()
        this.alarmStatistics()
        this.allshipPos(true);
        this.specialRegionStatistics();
        let obj = this;
        this.interval_allshipPos = setInterval(function () {
            obj.allshipPos();
        }, 180000);
    }
    componentWillUnmount() {
        this.mp3_radar_alarm.pause();
        clearInterval(this.interval_allshipPos);
        clearInterval(this.interval_flashingMask_alert);
        this.state.componentRepository.map.clearAllListener();
    }
    getInterval_flashingMask_alert = () => {
        // let obj = this;
        // let tileImg = require("./img/mask_red_map.png")
        // let params = {
        //     url: tileImg,
        //     visible: true,
        //     key: "layer_tileLayer_mask",
        //     opacity: 0.9,
        //     target: 'layer_tileLayer_mask',
        //     groupName: 'layers_ship',
        //     zIndex:99,
        // }
        // if (typeof (this.layersStatus_history[params.target]) == 'undefined') {
        //     this.layersStatus_history[params.target] = params;
        // }else{
        //     params = this.layersStatus_history[params.target];
        // }
        // this.state.componentRepository.map.addTile(params);
        // let params_alert = {
        //     ...params
        // }
        // let interval_flashingMask_alert = setInterval(function () {
        //     if (obj.state.componentRepository) {
        //         if (params_alert.visible) {
        //             obj.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layer_tileLayer_mask']);
        //             params_alert.visible = false;

        //         } else {
        //             obj.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layer_tileLayer_mask']);
        //             params_alert.visible = true;
        //         }
        //         if (obj.layersStatus_history['layer_tileLayer_mask']) {
        //             obj.layersStatus_history['layer_tileLayer_mask'].visible = params_alert.visible;
        //         }
        //     }
        // }, 500);
        // return interval_flashingMask_alert;
    }
    // 获取所有船的位置
    async allshipPos(isInit) {

        let data = await allshipPos()
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let shipDataIDDict = {};
            let demoAlarmShip = [];
            let shipImg = require('./img/ship_blue.png');
            let shipImgLawEnforcement = require('./img/ship_LawEnforcement.png');
            let shipImgSelectedLawEnforcement = require('./img/ship_selected_LawEnforcement.png');
            let ship_selected_blue = require('./img/ship_selected_blue.png');
            let ship_selected_black = require('./img/ship_selected_black.png');
            let ship_selected_yellow = require('./img/ship_selected_yellow.png');
            data.data.forEach((item, index) => {
                let location = {}
                location.lon = item.lon;
                location.lat = item.lat;
                item.location = location;
                item.oid = item.id;
                item.isClickPoint = false;
                item.name = item.objectName;
                item.isShip = true;
                item.pointTextColor = '#0066CC';
                if (item.shipType == 302403) {
                    item.pointImg = shipImgLawEnforcement;
                } else {
                    item.pointImg = shipImg;
                }
                if (item.emergencyStatus && item.emergencyStatus == 1) {
                    demoAlarmShip = demoAlarmShip.concat(item);
                    data.data.splice(index, 1);
                }
                shipDataIDDict = {
                    ...shipDataIDDict,
                    [item.id]: item,
                }
            })
            if (demoAlarmShip.length > 0) {
                // this.interval_flashingMask_alert = this.getInterval_flashingMask_alert()
                demoAlarmShip.map((item, index) => {
                    data.data = [
                        ...data.data,
                        item
                    ]
                    let params = {
                        target: 'layer_shipAlarm',
                        zIndex: 15,
                        clear: false,
                        groupName: 'layers_ship',
                        visible: false,
                        interval:
                        {
                            intervalName: 'interval_flashingMask_alert',
                            intervalCallBackFun: this.getInterval_flashingMask_alert,
                        },
                    }
                    this.state.componentRepository.map.init_addPointAnimation(item, params);
                    if (this.layersStatus_history[params.target]) {
                    } else {
                        this.layersStatus_history = {
                            ...this.layersStatus_history,
                            [params.target]: params,
                        }
                    }
                    if (this.layersStatus_history[params.target].visible) {
                        this.mp3_radar_alarm.play();
                    }
                })
            }
            this.setState({
                allship: data.data,
                shipDataIDDict,
            }, () => {
                // this.state.componentRepository.map.clearAllListener();
                let params = {};
                let switchArr = this.state.switchArr;
                let isOpen = false;
                if (isOpen
                    && this.state.shipData
                    && this.state.shipData.lbsPositionList
                    && this.state.shipData.lbsPositionList[0]
                    && this.state.shipData.lbsPositionList[0].lon != null
                    && this.state.shipData.lbsPositionList[0].lat != null) {
                    // this.state.componentRepository.map.click_addPointAnimation(this.state.shipData.lbsPositionList[0]);
                    params = {
                        target: 'layer_selectShip',
                        zIndex: 15,
                        clear: true,
                        groupName: 'layers_ship',
                        visible: true,
                    }
                    if (this.layersStatus_history[params.target]) {
                    } else {
                        this.layersStatus_history = {
                            ...this.layersStatus_history,
                            [params.target]: params,
                        }
                    }
                    this.state.componentRepository.map.click_addPointAnimation(this.state.shipData, params);
                    this.state.componentRepository.map.showMap_addPoints([this.state.shipData], params);
                    this.state.componentRepository.map.changeZoomAndCenter_point(this.state.shipData);
                }
                console.log(this.state.allship, 'ddd');
                params = {
                    target: 'layers_ship_all_smallZoom',
                    zIndex: 14,
                    isOverRule: true,
                    havePointText: false,
                    clear: true,
                    groupName: 'layers_ship'
                }
                if (isInit) {
                    params.visible = true;
                } else {
                    params = { ...this.layersStatus_history[params.target] }
                }
                if (this.layersStatus_history[params.target]) {
                } else {
                    this.layersStatus_history = {
                        ...this.layersStatus_history,
                        [params.target]: params,
                    }
                }
                this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);
                params = {
                    target: 'layers_ship_all_bigZoom',
                    zIndex: 14,
                    isOverRule: false,
                    havePointText: false,
                    clear: true,
                    groupName: 'layers_ship'
                }
                if (isInit) {
                    params.visible = false;
                } else {
                    params = { ...this.layersStatus_history[params.target] }
                }
                if (this.layersStatus_history[params.target]) {
                } else {
                    this.layersStatus_history = {
                        ...this.layersStatus_history,
                        [params.target]: params,
                    }
                }
                this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);
                params = {
                    target: 'layers_ship_all_bigZoom_withName',
                    zIndex: 14,
                    isOverRule: false,
                    havePointText: true,
                    clear: true,
                    groupName: 'layers_ship'
                }
                if (isInit) {
                    params.visible = false;
                } else {
                    params = { ...this.layersStatus_history[params.target] }
                }
                if (this.layersStatus_history[params.target]) {
                } else {
                    this.layersStatus_history = {
                        ...this.layersStatus_history,
                        [params.target]: params,
                    }
                }
                this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);

                console.log(this.layersStatus_history, '------layersStatus_history--------------allshipPos------------')
                if (isInit && typeof (this.interval_ChangeResolution_layers_ship) == 'undefined') {
                    this.interval_ChangeResolution_layers_ship = this.state.componentRepository.map.addEventChangeResolution(this.callBack_addEventChangeResolution_ship);
                }

                // this.state.componentRepository.map.addHeatMapLayer(this.state.allship, { target: 'layer_heatMapDemo', zIndex: 15 });//热力图测试
            })
        } else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    callBack_addEventChangeResolution_ship = (map) => {
        let zoom = map.getView().getZoom();
        if (
            zoom >= 11
            && this.layersStatus_history['layers_ship_all_bigZoom']
            && !this.layersStatus_history['layers_ship_all_bigZoom'].visible
            && this.layersStatus_history['layers_ship_all_smallZoom']
            && this.layersStatus_history['layers_ship_all_smallZoom'].visible
        ) {
            this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom']);
            this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_smallZoom']);
            if (this.layersStatus_history['layers_ship_all_bigZoom']) {
                this.layersStatus_history['layers_ship_all_bigZoom'].visible = true;
            }
            if (this.layersStatus_history['layers_ship_all_smallZoom']) {
                this.layersStatus_history['layers_ship_all_smallZoom'].visible = false;
            }
        }
        else if (
            zoom < 11
            && this.layersStatus_history['layers_ship_all_bigZoom']
            && this.layersStatus_history['layers_ship_all_bigZoom'].visible
            && this.layersStatus_history['layers_ship_all_smallZoom']
            && !this.layersStatus_history['layers_ship_all_smallZoom'].visible
        ) {
            this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layers_ship_all_smallZoom']);
            this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom']);
            if (this.layersStatus_history['layers_ship_all_bigZoom']) {
                this.layersStatus_history['layers_ship_all_bigZoom'].visible = false;
            }
            if (this.layersStatus_history['layers_ship_all_smallZoom']) {
                this.layersStatus_history['layers_ship_all_smallZoom'].visible = true;
            }
        }
        else if (
            zoom >= 19
            && this.layersStatus_history['layers_ship_all_bigZoom_withName']
            && !this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible
        ) {
            this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom_withName']);
            this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_smallZoom']);
            this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom']);
            if (this.layersStatus_history['layers_ship_all_bigZoom_withName']) {
                this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible = true;
            }
            if (this.layersStatus_history['layers_ship_all_smallZoom']) {
                this.layersStatus_history['layers_ship_all_smallZoom'].visible = false;
            }
            if (this.layersStatus_history['layers_ship_all_bigZoom']) {
                this.layersStatus_history['layers_ship_all_bigZoom'].visible = false;
            }
            // let params = {
            //     target: 'layers_ship_all_bigZoom',
            //     zIndex: 14,
            //     isOverRule: false,
            //     groupName: 'layers_ship',
            //     clear: true,
            //     visible: true,
            // }
            // this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);
        }
        else if (
            zoom < 19
            && this.layersStatus_history['layers_ship_all_bigZoom_withName']
            && this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible
        ) {
            this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom_withName']);
            this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_smallZoom']);
            this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom']);
            if (this.layersStatus_history['layers_ship_all_bigZoom_withName']) {
                this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible = false;
            }
            if (this.layersStatus_history['layers_ship_all_smallZoom']) {
                this.layersStatus_history['layers_ship_all_smallZoom'].visible = false;
            }
            if (this.layersStatus_history['layers_ship_all_bigZoom']) {
                this.layersStatus_history['layers_ship_all_bigZoom'].visible = true;
            }
            // let params = {
            //     target: 'layers_ship_all_bigZoom',
            //     zIndex: 14,
            //     isOverRule: false,
            //     groupName: 'layers_ship',
            //     clear: true,
            //     visible: true,
            // }
            // this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);
        }
    }
    // 今天历史报警简要统计
    // async alarmSimpleStatisticsToday() {
    //     let { startTime, endTime ,dictData} = this.state
    //     let params = {
    //         startTime,
    //         endTime
    //     }
    //     let data = await alarmSimpleStatisticsToday(params)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data.code == 200) {
    //         var colorArr = ['#cd5d30', '#1192ea', '#1d6db5', '#08c7c9', '#27ba6a', '#e2dd20']
    //         let warnArr = []
    //         if (dictData) {
    //             dictData.forEach((val) => {
    //                 data.data.children.forEach((item, index) => {
    //                     if (item.eventType == val.typeCode) {
    //                         warnArr.push({
    //                             name: val.typeName + '    ' + item.eventCount,
    //                             value: item.eventCount,
    //                             alarmname: val.typeName,
    //                             color: colorArr[index]
    //                         })
    //                     }
    //                 })
    //             })
    //         }
    //         this.setState({
    //             warnArr,
    //             allAlarm: data.data.alarmTotalCount
    //         })
    //     } 
    //     else if (data && data.code == 500) {
    //         message.error('用户未登录')
    //         if (this.props.history) {
    //             this.props.history.push('/login')
    //         }
    //     }

    // }
    // 截止到目前为止还在报警的记录
    async alarmSimpleStatistics() {
        let { startTime, endTime } = this.state
        let params = {
        }
        let data = await alarmSimpleStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            var colorArr = ['#cd5d30', '#1192ea', '#1d6db5', '#08c7c9', '#27ba6a', '#e2dd20']
            let { dictData } = this.state;
            let warnArrs = []
            let warnArr = []
            if (dictData) {
                dictData.forEach((val) => {
                    data.data.children.forEach((item, index) => {
                        if (item.eventType == val.typeCode) {
                            console.log(val.typeName, 'typeName')
                            warnArrs.push({
                                name: val.typeName,
                                value: item.eventCount,
                            })
                            warnArr.push({
                                name: val.typeName + '    ' + item.eventCount,
                                value: item.eventCount,
                                alarmname: val.typeName,
                                color: colorArr[index]
                            })
                        }
                    })
                })
            }
            sessionStorage.setItem("key", JSON.stringify(warnArrs));
            this.setState({
                warnArrs,
                warnArr
            }, () => {

                console.log(warnArrs, 'allAlarms')
            })
        } else if (data && data.code == 500) {

        }
    }
    //系统配置信息
    async system_config() {
        let params = {

        }
        let data = await system_config(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {
            this.setState({
                configData: data.data
            })

        } else if (data.code == 500) {
        }
    }
    // 大屏历史报警
    async alarmStatistics() {
        let { startTime, endTime } = this.state;
        let params = {
            objectName: '',
            // startTime,
            // endTime,
            pageSize: 999999,
            pageIndex: '1',
            eventStatus: "1"
        }
        let data = await alarmStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let { dictData } = this.state;
            if (dictData) {
                dictData.forEach((val) => {
                    data.data.items.forEach((item, index) => {
                        if (item.eventType == val.typeCode) {
                            item.eventName = val.typeName
                        }
                    })
                })
            }
            console.log(data.data.items, 'data.data.items')
            this.setState({
                alarmTableData: data.data.items
            })

        } else if (data.code == 500) {
        }
    }
    // 大屏船舶统计
    async shipTypeStatistics() {
        let params = {
        }
        let data = await shipTypeStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let color = ['#cd5d30', '#1192ea', '#1d6db5', '#08c7c9', '#27ba6a', '#e2dd20', '#dd9d2c', '#sd5d30']
            let roseData = []
            data.data.forEach((item, index) => {
                roseData.push({
                    name: item.typeName + " : " + item.count,
                    value: item.count,
                    color: color[index]
                })
            })
            console.log(roseData, 'roseData')
            this.setState({
                roseData
            })

        } else if (data.code == 500) {
        }
    }
    // 大屏左上角
    async mineShipRegionStatistics() {
        let params = {
        }
        let data = await mineShipRegionStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let color = ['#cd5d30', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea']
            let roseData = []
            data.data.children.forEach((item, index) => {
                roseData.push({
                    name: item.ownerAddress + " : " + item.count,
                    value: item.count,
                    color: color[index]
                })
            })
            function rule(key) {
                return function (a, b) { // sort 默认接受a,b两个参数表示数组中的值
                    var value1 = a[key]
                    var value2 = b[key]
                    return value2 - value1
                }
            }
            roseData.sort(rule("value"))
            console.log(roseData, 'shipnum')
            this.setState({
                roseData,
            })

        } else if (data.code == 500) {
        }
    }
    // 大屏航程
    async selectRangeStatisticsToday() {
        let { startTime, endTime } = this.state;
        let params = {
            startTime,
            endTime
        }
        let data = await selectRangeStatisticsToday(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let myData = data.data.slice(0, 10)
            let voyageData = {}
            voyageData.nameArr = []
            voyageData.voyageArr = []
            myData.forEach((item) => {
                voyageData.nameArr.push(item.shipName)
                voyageData.voyageArr.push(((item.driveDistance) / 1000).toFixed(1))
            })

            this.setState({
                voyageData
            })

        }
    }
    // 重点区域管理
    async specialRegionStatistics() {
        let { startTime, endTime } = this.state;
        let params = {
            startTime,
            endTime
        }
        let data = await specialRegionStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let portData = [];

            let portZhuData = {}
            portZhuData.nameArr = []
            portZhuData.leaveArr = []
            portZhuData.enterArr = []
            data.data.children.forEach((item) => {
                // enterNum += val.inCount;
                // leaveNum += val.leaveCount;
                portZhuData.nameArr.push(item.areaName)
                portZhuData.leaveArr.push(item.leaveCount)
                portZhuData.enterArr.push(item.inCount)
            })
            portData.push({
                name: '区域当前总数：' + data.data.inCount,
                value: data.data.inCount,
                color: '#34df8d'
            })
            console.log(portZhuData, 'portZhuData')
            portData.push({
                name: '区域驶离总数：' + data.data.leaveCount,
                value: data.data.leaveCount,
                color: '#3198f9'
            })
            this.setState({
                portData,
                portZhuData
            })
        }
    }
    // 大屏停靠点监控
    async homePortMonitor() {
        let { startTime, endTime } = this.state;
        let params = {
            startTime,
            endTime
        }
        let data = await homePortMonitor(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let shipPieData = []

            let shipZhuData = {}
            shipZhuData.nameArr = []
            shipZhuData.leaveArr = []
            shipZhuData.enterArr = []
            data.data.children.forEach((item) => {
                shipZhuData.nameArr.push(item.areaName)
                shipZhuData.leaveArr.push(item.leaveCount)
                shipZhuData.enterArr.push(item.inCount)
            })
            shipPieData.push({
                name: '当前停靠总数：' + data.data.inCount,
                value: data.data.inCount,
                color: '#34df8d'
            })
            shipPieData.push({
                name: '今日驶离总数：' + data.data.leaveCount,
                value: data.data.leaveCount,
                color: '#3198f9'
            })
            this.setState({
                shipPieData,
                shipZhuData,
            })

        } else if (data.code == 500) {
        }
    }
    //获取今天是星期几
    todayIsWeek = () => {
        let today = moment().day();
        switch (today) {
            case 1: return "星期一"; break;
            case 2: return "星期二"; break;
            case 3: return "星期三"; break;
            case 4: return "星期四"; break;
            case 5: return "星期五"; break;
            case 6: return "星期六"; break;
            case 0: return "星期日"; break;
        }
    }

    //断开饼状图
    breakPie = (scaleData) => {
        if (!scaleData) {
            return {
            }
        }
        var rich = {
            white: {
                color: '#fff',
                align: 'center',
                padding: [5, 0]
            }
        };
        var placeHolderStyle = {
            normal: {
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                },
                color: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0
            }
        };
        var data = [];
        var sunText = 0;
        for (var i = 0; i < scaleData.length; i++) {
            sunText += scaleData[i].value
            data.push({
                value: scaleData[i].value,
                name: scaleData[i].name,
                itemStyle: {
                    normal: {
                        borderWidth: 5,
                        color: scaleData[i].color
                    }
                }
            },
                // {
                //     value: 4,
                //     name: '',
                //     itemStyle: placeHolderStyle
                // }
            );
        }
        var seriesObj = [{
            name: '',
            type: 'pie',
            clockWise: false,
            radius: [50, 60],
            center: ['30%', "50%"],
            hoverAnimation: false,
            itemStyle: {
                normal: {
                    borderWidth: 10,	//边框的宽度
                    borderColor: 'Rgba(6,38,101)',	//边框的颜色
                    label: {
                        show: false,
                        position: 'center',
                        color: 'red',
                        // formatter: function (params) {
                        //     // console.log(params)
                        // },
                        // rich: rich
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    }
                }
            },
            selectedMode: false,
            data: data,
        }];
        return {
            title: [{
                text: sunText ? sunText : 0,
                textStyle: {
                    fontSize: 16,
                    color: '#cfcfcf'
                },
                left: sunText > 10 ? (sunText > 1000 ? "23%" : "25%") : "28%",
                top: "45%",
            }],
            legend: {
                orient: 'vertical',
                // selectedMode:false,
                x: '55%',
                y: 'center',
                itemWidth: 10,
                itemHeight: 10,
                align: 'left',
                textStyle: {
                    fontSize: 12,
                    color: '#cfcfcf'
                },
                data: scaleData,
                selectedMode: false,
            },
            tooltip: {
                show: true,
                trigger: 'item',
                formatter: "{b} ({d}%)",
                position: ['50%', '50%']
            },
            toolbox: {
                show: false
            },
            series: seriesObj
        }
    }
    onChartClick = (e) => {

        let { alarmTableData } = this.state;
        let newArr = alarmTableData.filter((item) => {
            if (e.name.indexOf(item.eventName) != -1) {
                return true
            }
        })
        // console.log(new_tableShow, 'eeeeeee')
        new_tableData = newArr
        // if(flag){
        //     new_tableShow=true;
        //     flag=false;
        //     // alert('111')
        // }else{
        // //    alert('222')
        //     new_tableShow=false;
        //     flag=true;
        // }
        // new_tableShow=!this.state.tableShow;
        this.setState({
            tableShow: !this.state.tableShow, //表格
            newTableData: newArr
        })
        // console.log(new_tableShow,'new_tableShow')
    }


    //  componentDidUpdate(prevProps, prevState) {
    //             if(this.props.titleData!==prevProps.titleData){
    //                 let {domId,domTitleId,parentId} = this.props;
    //                 document.getElementById(parentId).style.display='block';
    //                 if(this.videos[this.props.domId]){
    //                     let prevPlayer = this.videos[this.props.domId];
    //                         prevPlayer.disconnect();
    //                 }
    //             //  let DomID=this.props.domId 
    //              this.token=null
    //              this.createH5Video()
    //             } 
    //          }
    //     shouldComponentUpdate(nextProps, nextState) {
    //         if (this.props.titleData!==nextProps.titleData) {
    //             return true;
    //         }
    //         return false;
    //     }

    AreaColumnclick(params, echart) {
        let newSun = 0;
        let keyArr = Object.keys(params.selected)

        keyArr.forEach((item) => {
            if (params.selected[item]) {
                newSun += parseInt(item.replace(/[^0-9]/ig, ""));
            }
        })
        const echarts_instance = echart.getEchartsInstance();
        const option = echarts_instance.getOption();
        option.title[0].text = newSun
        echarts_instance.setOption(option)
        console.log(echarts_instance, 'keyArr')
    }

    AreaColumnclick2(params, echart) {
        let newSun = 0;
        let keyArr = Object.keys(params.selected)

        keyArr.forEach((item) => {
            if (params.selected[item]) {
                newSun += parseInt(item.replace(/[^0-9]/ig, ""));
            }
        })
        const echarts_instance = echart.getEchartsInstance();
        const option = echarts_instance.getOption();
        option.title[0].text = newSun
        echarts_instance.setOption(option)
        console.log(echarts_instance, 'keyArr')
    }
    // onclick = {
    //     'click': this.clickEchartsPie.bind(this)
    // }
    // clickEchartsPie(e) {
    //     this.onChartClick(e)
    // }
    alarmSwitchCheck = (checked) => {
        this.setState({
            alarmChecked: checked
        }, () => {
            let layer_shipAlarm = this.layersStatus_history['layer_shipAlarm'];
            if (layer_shipAlarm) {
                if (this.state.alarmChecked) {
                    this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layer_shipAlarm']);
                    this.mp3_radar_alarm.pause()
                } else {
                    this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layer_shipAlarm']);
                    this.mp3_radar_alarm.play()
                }
            }
        })
    }




    render() {

        let { weatherData, tableShow, configData, shipZhuData, voyageData, warnArr, alarmTableData, roseData, portData, portZhuData, newTableData, } = this.state;
        let shipkeyname = configData && configData[0].configValue
        localStorage.setItem('shipname', shipkeyname)
        console.log(tableShow, '什么状态22')
        return (

            <div className="warMapBox warMap">
                {/* <span  className='Bd_title'>
                {
                             configData&&configData[0].configValue  
                            }
                </span> */}
                <div className="goHome">
                    <Link to={"/home"}>
                        进入监控系统  &gt;&gt;
                    </Link>
                </div>
                <div id="psMap">
                    <MyComponentFactory
                        type={'psMap'}
                        componentLoad={this.componentLoad}
                        // playerMapPointCarera={this.playerMapPointCarera}
                        config_JSON={config_JSON}
                        parentObj={this}
                    ></MyComponentFactory>
                </div>
                <Stopwatch title={configData && configData[0].configValue} />
                <div className="warMap_left">
                    <div className="shipCensus">
                        <h4 className="warMapTitle">
                            {
                                configData && configData[2].configValue
                            }
                        </h4>
                        <div className="echarts1">
                            {this.state.shipPieData && <ReactEcharts
                                option={this.breakPie(this.state.roseData)}
                                style={{ width: "100%", height: '25vh', borderBottom: '1px solid #015093' }}
                                // onEvents={{
                                //     // 'legendselectchanged': (params) => {
                                //     //     this.AreaColumnclick(params, this.echart_rose)
                                //     // },
                                // }}
                                ref={(e) => this.echart_rose = e}
                            />}
                        </div>
                    </div>
                    <div className="shipFlow">
                        <h4 className="warMapTitle">
                            区域统计
                            </h4>
                        <div className="tabsBox">
                            <Tabs
                                tabBarStyle={{ borderBottomColor: "#015093", color: "#d2ecfe", paddingLeft: 26 }}
                            >
                                <TabPane
                                    tab="停靠点"
                                    key="port"
                                    value="port"
                                >
                                    {this.state.shipPieData && <ReactEcharts
                                        notMerge={true}
                                        lazyUpdate={true}
                                        onEvents={{
                                            // 'legendselectchanged': (params) => {
                                            //     this.AreaColumnclick(params, this.echart)
                                            // },
                                        }}
                                        ref={(e) => this.echart = e}
                                        option={this.breakPie(this.state.shipPieData)}
                                        style={{ width: "100%", height: '16vh', borderBottom: '1px solid #015093' }}
                                    />}
                                    <span style={{ color: '#78858F', left: 20, fontSize: 11, fontSize: 11, marginTop: 30, position: 'absolute' }}>(停靠点名称)</span><ReactEcharts
                                        option={{
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            grid: {
                                                left: '3%',
                                                right: '4%',
                                                bottom: '3%',
                                                containLabel: true
                                            },

                                            xAxis: {
                                                type: 'value',
                                                boundaryGap: [0, 5],
                                                axisTick: { show: false },
                                                axisLabel: {
                                                    color: '#9eb4c6'
                                                },
                                                axisLine: {
                                                    lineStyle: { color: '#0d447b' }
                                                },
                                                splitLine: {
                                                    lineStyle: { color: '#0d447b' }
                                                }
                                            },
                                            yAxis: {
                                                type: 'category',
                                                data: shipZhuData.nameArr,
                                                axisTick: { show: false },
                                                axisLine: {
                                                    lineStyle: { color: '#0d447b' }
                                                },
                                                axisLabel: {
                                                    color: '#9eb4c6'
                                                },
                                            },

                                            series: [
                                                {
                                                    name: '当前停靠总数',
                                                    type: 'bar',

                                                    data: shipZhuData.enterArr,
                                                    barWidth: '8px',
                                                    itemStyle: {
                                                        normal: {
                                                            color: '#34df8d',
                                                            barBorderRadius: [0, 30, 30, 0],
                                                            textStyle: {
                                                                color: "#a5bbcc",
                                                            }
                                                        }
                                                    },
                                                },
                                                {
                                                    name: '当前驶离总数',
                                                    type: 'bar',

                                                    data: shipZhuData.leaveArr,
                                                    barWidth: '8px',
                                                    itemStyle: {
                                                        normal: {
                                                            color: "#1192ea",
                                                            barBorderRadius: [0, 30, 30, 0]
                                                        }
                                                    },
                                                }
                                            ]
                                        }}
                                    />
                                </TabPane>
                                <TabPane
                                    tab="重点区域"
                                    value="point"
                                    key="point"
                                >
                                    {this.state.portData && <ReactEcharts
                                        option={this.breakPie(this.state.portData)}
                                        style={{ width: "100%", height: '16vh', borderBottom: '1px solid #015093' }}
                                        onEvents={{
                                            // 'legendselectchanged': (params) => {
                                            //     this.AreaColumnclick(params, this.echart_point)
                                            // },
                                        }}
                                        ref={(e) => this.echart_point = e}
                                    />}
                                    <span style={{ color: '#78858F', left: 20, marginTop: 30, fontSize: 11, position: 'absolute' }}>(重点区域名称)</span>
                                    <ReactEcharts
                                        option={{
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            grid: {
                                                left: '3%',
                                                right: '4%',
                                                bottom: '3%',
                                                containLabel: true
                                            },

                                            xAxis: {
                                                type: 'value',
                                                boundaryGap: [0, 1],
                                                axisTick: { show: false },
                                                axisLabel: {
                                                    color: '#9eb4c6'
                                                },
                                                axisLine: {
                                                    lineStyle: { color: '#0d447b' }
                                                },
                                                splitLine: {
                                                    lineStyle: { color: '#0d447b' }
                                                }
                                            },
                                            yAxis: {
                                                // name:"(重点区域名称)",
                                                type: 'category',
                                                data: portZhuData.nameArr,
                                                axisTick: { show: false },
                                                axisLine: {
                                                    lineStyle: { color: '#0d447b' }
                                                },
                                                axisLabel: {
                                                    color: '#9eb4c6'
                                                },
                                            },
                                            series: [
                                                {
                                                    name: '区域当前总数',
                                                    type: 'bar',
                                                    data: portZhuData.enterArr,
                                                    barWidth: '8px',
                                                    itemStyle: {
                                                        normal: {
                                                            color: '#34df8d',
                                                            barBorderRadius: [0, 30, 30, 0],
                                                            textStyle: {
                                                                color: "#a5bbcc",
                                                            }
                                                        }
                                                    },
                                                },
                                                {
                                                    name: '区域驶离总数',
                                                    type: 'bar',
                                                    data: portZhuData.leaveArr,
                                                    barWidth: '8px',
                                                    itemStyle: {
                                                        normal: {
                                                            color: "#1192ea",
                                                            barBorderRadius: [0, 30, 30, 0]
                                                        }
                                                    },
                                                }
                                            ]
                                        }}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
                {/* <div className="warMap_center"></div> */}
                <div className="warMap_right">
                    <h4 className="warMapTitle">
                        实时天气状况
                        </h4>
                    <div className="tianqi">
                        <div style={{ display: 'flex', justifyContent: "space-between", fontSize: '1.8vh' }}>
                            <span>{moment().format('MM-DD')} {this.todayIsWeek()}</span>
                            <span><MyIcon type={'icon-dingwei'} /> 吴中区</span>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <img src={weatherData && maths[weatherData.now.cond_code] ? weatherData && maths[weatherData.now.cond_code] : maths.duoyun} /><br />
                            <span style={{ color: "#14f5f4", fontSize: '2vh' }}>{weatherData && weatherData.now.tmp + "℃"}</span><br />
                            <span style={{ fontSize: '1.6vh' }}><span>{weatherData && weatherData.now.cond_txt}</span> <span>{weatherData && weatherData.now.wind_dir}</span></span>
                        </div>
                        <ul >
                            <li>
                                <MyIcon style={{ marginRight: 3 }} type={'icon-kongqizhiliang'} /> <span>能见度：{weatherData && weatherData.now.vis + "km"}</span>
                            </li>
                            <li>
                                <MyIcon type={'icon-IOTtubiao_huabanfuben'} /> <span>降水量：{weatherData && weatherData.now.pcpn + "mm"}</span>
                            </li>
                            <li>
                                <MyIcon type={'icon-icon-temperature'} /> <span>相对湿度：{weatherData && weatherData.now.hum + "%"}</span>
                            </li>
                            <li>
                                <MyIcon style={{ marginRight: 7 }} type={'icon-qiya'} /> <span>气 压：{weatherData && weatherData.now.pres + "pa"}</span>
                            </li>
                        </ul>
                    </div>
                    <h4 className="warMapTitle">
                        今日航程排名
                        </h4>
                    <div className="hangcheng">
                        <span style={{ color: '#78858F', left: 30, marginTop: 10, fontSize: 8, position: 'absolute' }}>(公里)</span>
                        <ReactEcharts
                            onEvents={{
                                // 'legendselectchanged': (params) => {
                                //     this.AreaColumnclick(params, this.echart_rose)
                                // },
                            }}
                            option={{

                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow'
                                    },
                                    formatter: '{b}<br/>{c} 公里',
                                },
                                grid: {
                                    top: '15%',
                                    right: '3%',
                                    left: '18%',
                                    bottom: '23%'
                                },
                                xAxis: [{
                                    type: 'category',
                                    data: voyageData.nameArr,
                                    axisLine: {
                                        lineStyle: {
                                            color: '#0d447b'
                                        }
                                    },
                                    axisTick: { show: false },
                                    axisLabel: {
                                        interval: 0,
                                        rotate: 40,
                                        color: '#cfcfcf',
                                        textStyle: {
                                            fontSize: 12
                                        },
                                    },
                                }],
                                yAxis: [{
                                    axisLabel: {
                                        formatter: '{value}',
                                        color: '#cfcfcf',
                                    },
                                    axisLine: {
                                        lineStyle: {
                                            color: '#0d447b'
                                        }
                                    },
                                    axisTick: { show: false },
                                    splitLine: {
                                        lineStyle: {
                                            color: '#0d447b'
                                        }
                                    }
                                }],
                                series: [{
                                    type: 'bar',
                                    data: voyageData.voyageArr,
                                    barWidth: '10px',
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                offset: 0,
                                                color: '#48f8b0' // 0% 处的颜色
                                            }, {
                                                offset: 1,
                                                color: '#1664b8' // 100% 处的颜色
                                            }], false),
                                            barBorderRadius: [30, 30, 0, 0],
                                            shadowColor: 'rgba(0,160,221,1)',
                                            shadowBlur: 4,
                                        }
                                    },
                                    label: {
                                        normal: {
                                            show: true,
                                            distance: 1,
                                            position: 'top',
                                            color: '#cfcfcf'
                                        }
                                    }
                                }]

                            }}
                            style={{ height: '100%', width: '100%' }}
                        />
                    </div>
                    <h4 className="warMapTitle">
                        报警统计
                        </h4>
                    <div className="baojing">
                        <div className="alarmPrompt">
                            <span>报警提示</span>
                            <Switch
                                checkedChildren="关" unCheckedChildren="开"
                                className="alarmSwitch"
                                size="small"
                                checked={this.state.alarmChecked}
                                onChange={this.alarmSwitchCheck}
                            />
                        </div>
                        {this.state.warnArr && <ReactEcharts
                            option={this.breakPie(this.state.warnArr)}
                            style={{ width: "100%", height: "14vh", right: 0 }}
                            notMerge={true}
                            lazyUpdate={true}
                            onEvents={{
                                'click': (params) => {
                                    this.onChartClick(params)
                                },
                                // 'legendselectchanged': (params) => {
                                //     this.AreaColumnclick2(params, this.echart_warn)
                                // },
                            }}

                            ref={(e) => this.echart_warn = e}
                        >
                        </ReactEcharts>}
                    </div>
                </div>
                {/* </div> */}
                {
                    tableShow && <div className="detailTable">
                        <table className="showTable">
                            <thead>
                                <tr style={{ marginRight: 200 }}>
                                    <th>船舶</th>
                                    <th>报警名称</th>
                                    {/* <th>报警区域</th> */}
                                    <th>时间</th>
                                </tr>
                            </thead>
                            <tbody className="dataTables_scrollBody">
                                {
                                    new_tableData && new_tableData.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.objectName}</td>
                                            <td>{item.eventName}</td>
                                            {/* <td>{item.regionName}</td> */}
                                            <td style={{ width: 150 }}>{item.gmtCreate}</td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                        </table>
                    </div>

                }
            </div>
        )
    }
}