// 权限管理
import React, { Component } from 'react'
import SearchTop from './components/seachTop/SeachTop'
import { Table, Input, Modal, Icon, Tabs, Select, message, TreeSelect, Button } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import $ from 'jquery'
import {
    //查询角色
    selectRoleDetails,
    //删除角色
    deleteRoleById,
    getMenuTree,
    //新增角色
    insertRole,
    //修改角色
    updateRole,
    //获取区域树
    selectByRegion,
    //菜单绑定
    bindRolePermission,
    //区域菜单树
    bindRgRole,
} from '../../data/dataStore'
const { TabPane } = Tabs;
let checkedArr = []

export default class Authorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            addFather: false,
            deleteData: {},
            activeKey: 'interface',
            selectedRowKeys: null,
            required: true,
            interfaceData: [],
            operationData: [],
            resourceData: [],
            fatherData: {},
            modifyData: {},
            distribution: false,
            showManage: false,
            manageData: {},
            hasBindList: [],
            allList: [],
            hasBindKeyList: [],
            fenpei_titlte: '',
            selectedKeys: [],
            searchValue: '',//界面搜索
            titleName: "功能接口名称",
            key:"",
            dataIndex:""
        }
        this.selectRoleDetails = this.selectRoleDetails.bind(this)
        this.insertRole = this.insertRole.bind(this)
        this.deleteRoleById = this.deleteRoleById.bind(this)
        this.updateRole = this.updateRole.bind(this)
        this.selectByRegion = this.selectByRegion.bind(this)
    }
    componentDidMount() {
        //查询角色详情
        this.selectRoleDetails()
        //获取菜单树
        this.getMenuTree()
        //查询区域树
        this.selectByRegion()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id;
            item.click = item.hasAcl ? true : false
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    // 扁平化多重嵌套数组对象
    flatten = (arr) => {
        let myArr = []
        for (const item of arr) {
            myArr.push(item);
            if (item.children && item.children.length > 0) {
                myArr.push(...this.flatten(item.children))
            }
        }
        return myArr
    }

    newgetSelectKey = (data, key) => {
        let arr = key ? [...key] : []
        let alldata = this.flatten(data)
        data.forEach((item) => {
            if (!item.click) {
                alldata.forEach((val) => {
                    arr.push(val.id)
                    val.click = true
                })
            } else {
                let newArr = [];
                alldata.map((val, index) => {
                    arr.map((v, ii) => {
                        if (v == val.id) {
                            arr.splice(ii, 1)
                        }
                        val.click = false
                    })
                })
            }
        })
        arr = arr.filter((item, index) => arr.indexOf(item) === index)
        return arr
    }
    // 权限管理列表
    async getRoleList(id) {
        let params = {
            id: id ? id : ''
        }
        let data = await getRoleList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            let myData = this.flatten(data.rows)
            myData.forEach((item) => {
                if (item.check) {
                    selectedKeys.push(item.id)
                }
            })
            this.handleTreeData(newData)
            this.setState({
                interfaceData: newData,
                selectedKeys
            })
        } else if (data.code == 505) {
            this.props.history.push('/login')
        }
    }
    // 删除角色
    async deleteRoleById() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.id
        }
        let data = await deleteRoleById(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.setState({
                deleteShow: false,
            })
            this.selectRoleDetails()
        } else {
            message.error("删除失败:" +data.message)
        }
    }

    // 新增角色
    async insertRole(obj) {
        let data = await insertRole(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            this.setState({
                // addFather:false,
            })
            this.selectRoleDetails()
        } else {
            message.error("新增失败")
        }
    }
    // 修改角色
    async updateRole(obj) {
        let data = await updateRole(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("修改成功")
            this.setState({
                modifyShow: false,
            })
            this.selectRoleDetails()
        } else {
            message.error(data.message)
        }
    }


    // 菜单管理
    // 层级树查询
    async getMenuTree(id) {
        let params = {
            id: id ? id : '',
        }
        let data = await getMenuTree(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let myData = this.flatten(data.rows)
            myData.forEach((item) => {
                if (item.check) {
                    selectedKeys.push(item.id)
                }
            })
            let newData = this.setKey(data.rows);
            this.handleTreeData(newData)
            this.setState({
                operationData: newData,
                selectedKeys
            })
        }
    }

    // 区域管理的接口
    // 层级树查询
    async selectByRegion(id) {
        let params = {
            id: id ? id : '',
        }
        let data = await selectByRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            let myData = this.flatten(data.rows)
            myData.forEach((item) => {
                if (item.check) {
                    selectedKeys.push(item.id)
                }
            })
            this.handleTreeData(newData)
            this.setState({
                resourceData: newData,
                selectedKeys
            }, () => {
                console.log(this.state.resourceData, 'resourceData >>> ')
            })
        }
    }

    // 用户组管理的接口
    // 层级树查询
    async selectRoleDetails() {
        let params = {
            id: '',
        }
        let data = await selectRoleDetails(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let newData = this.setKey(data.rows);
            console.log(newData, 'newData')
            this.handleTreeData(newData)
            this.setState({
                userGroupData: newData,
            })
        }
    }
    // 新增用户组
    async insertUgroup(obj) {
        let data = await insertUgroup(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            // this.setState({
            //     addFather:false,
            // })
            this.selectRoleDetails()
        } else {
            message.error("新增失败")
        }
    }

    // 修改角色
    async updateRole(obj) {
        let data = await updateRole(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("修改成功")
            this.setState({
                modifyShow: false,
            })
            this.selectRoleDetails()
        } else {
            message.error(data.message)
        }
    }
    //区域绑定方法
    async bindRgRole() {
        let { selectedKeys, manageData, distribute_dataSource } = this.state
        let dataArr = []
        if (distribute_dataSource && distribute_dataSource.length > 0) {
            dataArr = this.flatten(distribute_dataSource)
        }
        let myArr = []
        if (selectedKeys.length > 0) {
            dataArr.forEach((item) => {
                selectedKeys.forEach((val) => {
                    if (item.id == val) {
                        myArr.push({
                            roleId: manageData.id,
                            regionId: val,
                        })
                    }
                })
            })
        } else {
            myArr.push({
                roleId: manageData.id,
                // permissionId: '',
                // resourceType: '100305',
                // acl: ''
            })
        }
        let data = await bindRgRole(myArr)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.selectRoleDetails(manageData.id)
            message.success("绑定成功")
        } else {
            message.error("绑定失败")
        }
    }
    // 菜单绑定函数
    async bindRolePermission() {
        let { selectedKeys, manageData, distribute_dataSource } = this.state
        let dataArr = []
        if (distribute_dataSource && distribute_dataSource.length > 0) {
            dataArr = this.flatten(distribute_dataSource)
        }
        let myArr = []
        if (selectedKeys.length > 0) {
            dataArr.forEach((item) => {
                selectedKeys.forEach((val) => {
                    if (item.id == val) {
                        myArr.push({
                            roleId: manageData.id,
                            menuId: val,
                        })
                    }
                })
            })
        } else {
            myArr.push({
                menuId: manageData.id,
                roleId: '',
            })
        }
        let data = await bindRolePermission(myArr)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        console.log("<<<<<<<"+JSON.stringify(data))
        if (data && data.code == 200) {
            this.selectRoleDetails(manageData.id)
            message.success("绑定成功")
        } else {
            message.error("绑定失败")
        }
    }
    // 根据tab页进行新增
    addFatherNode = (obj) => {
        let { activeKey, fatherData } = this.state;
        obj.parentId ? obj.parentId = fatherData.id : obj.parentId = ""
        switch (activeKey) {
            case 'interface':
                this.insertRole(obj)
                    ; break;
            case 'operation':
                this.insertPermission(obj)
                    ; break;
            case 'resource':
                this.insertRgroup(obj)
                    ; break;
            case 'interface':
                this.insertUgroup(obj)
                    ; break;
        }
    }
    // 根据tab页进行houduan修改
    modifNode = (obj) => {
        let { activeKey, modifyData } = this.state;
        obj.id = modifyData.id
        console.log(obj, 'objj')
        switch (activeKey) {
            case 'interface':
                this.updateRole(obj)
                    ; break;
            case 'operation':
                this.updatePermission(obj)
                    ; break;
            case 'resource':
                this.updateRgroup(obj)
                    ; break;
            case 'interface':
                this.updateRole(obj)
                    ; break;
        }
    }
    //切换tabs发生的事件
    changePage = (key) => {
        let typeArr;
        switch (key) {
            case 'interface':
                typeArr = this.getDictData("type_acl")
                    ; break;
            case 'operation':
                typeArr = this.getDictData("type_permission")
                    ; break;
            case 'resource':
                typeArr = this.getDictData("type_resource_group")
                    ; break;
            case 'userGroup':
                typeArr = this.getDictData("type_ugroup")
                    ; break;
        }
        this.setState({
            activeKey: key,
            typeArr,
            selectedRowKeys: null,
        })

    }
    //点击新增和添加子节点的事件
    addClickShow = (data, tt) => {
        console.log(tt, 'ttttt')
        if (tt == 2) {
            this.setState({
                addShow: true,
                fatherData: data,
            })
        } else {
            this.setState({
                addFather: true
            })
        }

    }
    deleteClickShow = (data) => {
        let { activeKey } = this.state;
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    deleteAllShow = (data) => {
        let { selectedRowKeys } = this.state;
        if (selectedRowKeys) {
            this.setState({
                deleteAll: true,
            })

        } else {
            message.warning("请先选择要删除的数据！")
        }
    }
    //调试删除一个数据的接口
    removeOneData = () => {
        let { activeKey } = this.state;
        switch (activeKey) {
            case 'interface':
                this.deleteRoleById()
                    ; break;
            case 'operation':
                this.deletePermissionById()
                    ; break;
            case 'resource':
                this.deleteRgroupById()
                    ; break;
            case 'userGroup':
                this.deleteUgroupById()
                    ; break;
        }
    }
    // 批量删除的接口
    removeAllData = () => {
        let { activeKey } = this.state;
        switch (activeKey) {
            case 'operation':
                this.deletePermissionByIds()
                    ; break;
            case 'resource':
                this.deleteRgroupByIds()
                    ; break;
            case 'userGroup':
                this.deleteUgroupByIds()
                    ; break;
        }
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            addFather: false,
            modifyShow: false,
            showManage: false,
        })
    }
    authorityDetails = (data) => {

    }
    onSelectChange = (data) => {
        console.log(data, 'dddd')
        let { activeKey, selectedRowKeys } = this.state;
        let newKey;
        switch (activeKey) {
            case 'interface':
                newKey = this.newgetSelectKey([data], selectedRowKeys)
                    ; break;
            case 'operation':
                newKey = this.newgetSelectKey([data], selectedRowKeys)
                    ; break;
            case 'resource':
                newKey = this.newgetSelectKey([data], selectedRowKeys)
                    ; break;
            case 'userGroup':
                newKey = this.newgetSelectKey([data], selectedRowKeys)
                    ; break;
        }
        this.setState({
            selectedRowKeys: newKey ? newKey : selectedRowKeys
        })
    }
    distribute_onChage = (data) => {
        let keyArr = this.newgetSelectKey([data], this.state.selectedKeys)
        this.setState({
            selectedKeys: keyArr
        })
    }
    // 用户组管理弹框table的全选
    modalTableCheckedAll = (checked, alldata) => {
        let arr = [];
        if (checked) {
            alldata.map(item => {
                arr.push(item.id)
            })
        } else {
            arr = []
        }
        this.setState({
            selectedKeys: arr
        })
    }
    //table的全选
    tableCheckedAll = (checked, alldata) => {
        let arr = [];
        if (checked) {
            alldata.map(item => {
                arr.push(item.id)
            })
        } else {
            arr = []
        }
        this.setState({
            selectedRowKeys: arr
        })
    }
    //类别选择其它的时候url非必选
    selectType = (value) => {
        if (value == 100306) {
            this.setState({
                required: false
            })
        } else {
            this.setState({
                required: true
            })
        }
    }
    //处理树选择框的数据
    handleTreeData = (treeData) => {
        if (treeData) {
            let newArr = []
            treeData.map((item) => {
                //console.log(item, 'iiitem1111')
                let obj = {
                    id: item.id,
                    key: item.id,
                    click: item.hasAcl,
                    title: item.name ? item.name : item.aliasName,
                    value: item.id ? item.id : item.unique,
                    // value: {
                    //     objectId:item.id ? item.id : item.unique,
                    //     cardNumType:item.cardNumType,
                    // }
                }
                let keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let child = this.handleTreeData(item[keyArr[j]])
                        obj.children = child
                        // obj.selectable = false
                    }
                }
                newArr.push(obj)
                //console.log(obj, newArr, 'iiitem')

            })
            return newArr
        }

    }
    // 点击表格修改事件,切换tab改变父节点的值
    modifyClick = (data) => {
        let { activeKey } = this.state;
        let treeData;
        switch (activeKey) {
            case 'interface':
                treeData = this.handleTreeData(this.state.interfaceData)
                    ; break;
            case 'operation':
                treeData = this.handleTreeData(this.state.operationData)
                    ; break;
            case 'resource':
                treeData = this.handleTreeData(this.state.resourceData)
                    ; break;
            case 'userGroup':
                treeData = this.handleTreeData(this.state.userGroupData)
                    ; break;
        }
        this.setState({
            modifyShow: true,
            modifyData: data,
            treeData
        })
    }
    // 模糊搜索
    getSearchValue = (data, value) => {
        let dataSource = this.flatten(data)
        let newArr;
        if (value) {
            newArr = dataSource.filter((item) => {
                console.log(value, item.name.indexOf(value), 'item')
                return item.name.indexOf(value) != -1
            })
        } else {
            newArr = data
        }

        return newArr;
    }
    // 切换不同页面进行模糊搜索
    searchData = (value) => {
        let { activeKey } = this.state;
        let nowArr;
        switch (activeKey) {
            case 'interface':
                nowArr = this.getSearchValue(this.state.interfaceData, value)
                this.setState({
                    nowinterfaceData: nowArr
                })
                    ; break;
            case 'operation':
                nowArr = this.getSearchValue(this.state.operationData, value)
                this.setState({
                    nowoperationData: nowArr
                })
                    ; break;
            case 'resource':
                nowArr = this.getSearchValue(this.state.resourceData, value)
                this.setState({
                    nowresourceData: nowArr
                })
                    ; break;
            case 'userGroup':
                nowArr = this.getSearchValue(this.state.userGroupData, value)
                this.setState({
                    nowuserGroupData: nowArr
                })
                    ; break;
        }
        console.log(nowArr, 'nowArr')

    }
    // 切换不同页面进行模糊搜索
    modalTableSearch = (value) => {
        let { distribute_dataSource } = this.state;
        let nowArr = this.getSearchValue(distribute_dataSource, value)
        this.setState({
            nowModalData: nowArr,
            searchValue: value
        })
    }
    //按钮点击事件
    clickManagement = (data, tt) => {
        let {operationData, resourceData } = this.state;
        console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"+tt)
        if (tt === 4) {
            this.setState({
                manageData: data,

            }, () => {
                this.selectResourceByRgroupId()
            })
        } else {
            switch (tt) {
                case 2:
                    this.getMenuTree(data.id)
                    this.setState({
                        fenpei_titlte: '菜单分配',
                        titleName: "菜单名称",
                        key:"label",
                        dataIndex:"label",
                        distribution: true,
                        distribute_dataSource: operationData,
                        manageData: data,
                        clickType: "operation",
                        nowModalData: null,
                        ttClick: tt
                    })
                        ; break;
                case 3:
                    this.selectByRegion(data.id)
                    this.setState({
                        fenpei_titlte: '区域分配',
                        titleName: "区域名称",
                        key:"regionName",
                        dataIndex:"regionName",
                        distribution: true,
                        distribute_dataSource: resourceData,
                        manageData: data,
                        ttClick: tt,
                        clickType: "resource",
                        nowModalData: null
                    })
                        ; break;
            }
        }
    }
    //点击事件
    AllgroupClick = (data, tt) => {
        switch (tt) {
            case 1:
                // this.getRoleList(data.id)

                    ; break;
            case 2:
                // this.getMenuTree(data.id)

                    ; break;
            case 3:
                // this.selectByRegion(data.id)

                    ; break;
        }
        console.log("<<<<<<"+tt)
    }
    //分配管理点击类型
    fenpeiguanli = () => {
        let { clickType } = this.state;
        switch (clickType) {
            case 'interface':
                this.bindServiceUgroup()
                    ; break;
            case 'operation':
                this.bindRolePermission()
                    ; break;
            case 'resource':
                this.bindRgRole()
                    ; break;
        }
    }
    resetRowKey = () => {
        this.setState({
            selectedKeys: checkedArr
        })
    }

    render() {
        let { fenpei_titlte,deleteShow, selectedRowKeys, modifyData,userGroupData, distribution,selectedKeys, distribute_dataSource,nowuserGroupData, nowModalData, titleName,key,dataIndex } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onSelect: this.onSelectChange,
            onSelectAll: this.tableCheckedAll,
        };
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            // onChange: this.distribute_onChage,
            onSelect: this.distribute_onChage,
            onSelectAll: this.modalTableCheckedAll,
        };
        const columns3 = [

            {
                title: titleName,
                key: key,
                dataIndex: dataIndex,
            },
            {
                title: 'id',
                key: 'id',
                dataIndex: 'id',
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },

        ]
        //权限管理列表展示的
        const columns4 = [
            {
                title: '权限名称',
                key: 'roleName',
                dataIndex: 'roleName',
                ellipsis: true,
            },
            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
                render: (status) => {
                    let newText;
                    if (status == 0) {
                        newText = '启用'
                    }else if(status == 1){
                        newText = '禁用'
                    }
                    return newText;
                },

            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
                ellipsis: true,
                render: (text) => {
                    return text ? text : "--"
                }
            },
            {
                title: '菜单分配',
                key: 'guanli2',
                ellipsis: true,
                render: (text, record) => {
                    return <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.clickManagement(record, 2)}>管理</span>
                },
            },
            {
                title: '区域分配',
                key: 'guanli3',
                ellipsis: true,
                render: (text, record) => {
                    return <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.clickManagement(record, 3)}>管理</span>
                },
            },
            {
                title: '操作',
                key: 'detele',
                ellipsis: true,
                width: 200,
                render: (text, record) => {
                    return <div>

                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modifyClick(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 5px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>
                },
            },
        ]

        //新增角色
        const fatherList = [
            {
                label: '角色名称',
                id: 'roleName',
                component: <Input placeholder={'请输入'} />,
                options: { rules: [{ required: true, message: '角色名称不能为空!' }] }
            },
            {
                label: '备注',
                id: 'remark',
                component: <Input.TextArea placeholder={'请输入'} style={{ height: 80 }} />,
                // options:{rules: [{ required: true, message: '备注不能为空!' }]}
            },
        ]
        //修改角色
        const modifyList = [
            {
                label: '角色名称', //表单label
                id: 'roleName', //表单Item的Key值
                component: <Input placeholder={'请输入'}  />,
                options:  { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.roleName }
            },
            {
                label: '备注',
                id: 'remark',
                component: <Input.TextArea placeholder={'请输入'} style={{ height: 80 }} />,
                options: { initialValue: modifyData.remark }
            },
        ]

        return (
            <div className="authorization contentBox" id="AuthorManagement">
                <Tabs tabBarStyle={{ borderBottom: 'none' }} onChange={this.changePage}>
                    <TabPane tab="权限管理" key="userGroup">
                        <Table
                            columns={columns4}
                            rowSelection={rowSelection}
                            dataSource={nowuserGroupData ? nowuserGroupData : userGroupData}
                        />
                    </TabPane>
                </Tabs>
                <div className="searchBox">
                    <SearchTop
                        allDet="true"
                        adding="true"
                        select="true"
                        onclick={this.addClickShow}
                        onRemove={this.deleteAllShow}
                        onSearch={this.searchData} />
                </div>

                <ModalForm
                    visible={this.state.addFather}
                    formItemList={fatherList}
                    closeModal={this.closeModal}
                    title="新增"
                    submit={this.addFatherNode}
                    destroyOnClose
                />
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    title="修改"
                    submit={this.modifNode}
                />
                <Modal
                    visible={deleteShow}
                    title="删除提醒"
                    onCancel={() => { this.setState({ deleteShow: false }) }}
                    onOk={this.removeOneData}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData && this.state.deleteData.roleName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>

                {/* 用户组点击管理出现的页面 */}
                <Modal
                    visible={distribution}
                    onCancel={() => {
                        this.setState({ distribution: false }, () => {
                            this.AllgroupClick(this.state.manageData, this.state.ttClick)
                        })
                    }}
                    footer={false}
                    width={1000}
                    destroyOnClose>
                    <div className="distribution_top">
                        <span>{fenpei_titlte}</span>
                    </div>
                    <Table
                        columns={columns3}
                        rowSelection={distribute_rowSelect}
                        dataSource={nowModalData ? nowModalData : distribute_dataSource}
                        scroll={{ y: '50vh' }}
                        style={{ height: '60vh' }}
                        pagination={false}
                        defaultExpandAllRows
                        indentSize={10}
                    />

                    <div className="distribution_btnBox">
                        <Button
                            onClick={() => {
                                this.setState({ distribution: false }, () => {
                                    this.AllgroupClick(this.state.manageData, this.state.ttClick)
                                })
                            }}
                        >取消</Button>
                        <Button
                            style={{ margin: "0 10px" }}
                            onClick={this.resetRowKey}
                        >重置</Button>
                        <Button
                            onClick={this.fenpeiguanli}
                            type="primary"
                        >
                            确定
                       </Button>
                    </div>
                </Modal>

            </div>
        )
    }
}
