// 用户管理
import React, { Component } from 'react'
import './css/system.css'
import { Table, Input, Modal, Icon, Select, message, Button } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import {
    selectUser,
    insertUser,
    deleteUserById,
    updatePwd,
    updateUser,
    bindUserRole,
    updateLoginCount ,
    getRoleList
} from '../../data/dataStore'
import moment from 'moment';
// 引入jQuery
import $ from 'jquery'
const { TextArea, Search } = Input;

const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class UserMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            deleteData: {},
            ulshow: false,
            selectedKeys: [],
            userName: '',
            id: '',
            name:'',
            status: 0,
            userData: [],
            pasValue: '',
            modifyData: {},
            roleData: [],
            pageNo: 1,
            pageSize: 10,
            pageTotal: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
        }
        this.insertUser = this.insertUser.bind(this)
        this.deleteUserById = this.deleteUserById.bind(this)
        this.updatePwd = this.updatePwd.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.bindUserRole = this.bindUserRole.bind(this)
    }
    componentDidMount() {
        this.selectUser()
        let user = JSON.parse(sessionStorage.getItem('isLogin'))
        this.setState({
            admin:user.data
        })
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectUser()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    // 新增用户
    async insertUser(obj) {
        const secretKey = '123456789';
        obj = {
            ...obj,
            password: obj.password

        }
        let data = await insertUser(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                addShow: false,
            })
            this.selectUser()
            message.success("新增成功")
        } else {
            message.error("新增失败")
        }
    }
    // 修改用户
    async updateUser(obj) {
        let { modifyData } = this.state
        const secretKey = '123456789';
        obj = {
            ...obj,
            password: obj.password,
        }
        //     obj.password = CryptoJS.DES.encrypt(obj.password, CryptoJS.enc.Utf8.parse(secretKey), {
        //         mode= CryptoJS.mode.ECB,
        //         padding= CryptoJS.pad.Pkcs7
        //     }).toString(),
        // }
        obj.id = modifyData.userId
        console.log(obj, 'obj')
        let data = await updateUser(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                modifyShow: false,
            })
            this.selectUser()
            message.success("修改成功")
        } else {
            message.error("修改失败")
        }
    }
    // 重置密码
    async updatePwd(obj) {

        let { resetData } = this.state
        const secretKey = '123456789';
        obj.id = resetData.userId
        // obj = {
        //     ...obj,
        //     oldPwd: CryptoJS.DES.encrypt(obj.oldPwd, CryptoJS.enc.Utf8.parse(secretKey), {
        //         mode: CryptoJS.mode.ECB,
        //         padding: CryptoJS.pad.Pkcs7
        //     }).toString(),
        //     newPwd:CryptoJS.DES.encrypt(obj.newPwd, CryptoJS.enc.Utf8.parse(secretKey), {
        //         mode: CryptoJS.mode.ECB,
        //         padding: CryptoJS.pad.Pkcs7
        //     }).toString(),
        // }
        let data = await updatePwd(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                resetShow: false,
            })
            this.selectUser()
            message.success("重置密码成功")
        } else {
            message.error("重置密码失败")
        }
    }

    // 删除用户---postJSONData方法
    async deleteUserById() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.userId
        }
        let data = await deleteUserById(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                deleteShow: false,
            })
            this.selectUser()
            message.success("删除成功")
        } else {
            message.error("删除失败")
        }
    }
    // 用户组管理的接口
    // 层级树查询
    async getRoleList(id) {
        console.log(">>>",">>>"+id)
        let params = {
            userId: id ? id : '',
        }
        let data = await getRoleList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            console.log("runhere>>>",data.rows)
            selectedKeys = this.getdefSelectKey(data.rows)
            console.log(">>>",...selectedKeys)
            this.setState({
                roleData: data.rows,
                selectedKeys,
            })
            console.log(">>>",selectedKeys)
        }
    }
    // 绑定用户权限
    async bindUserRole() {
        let { selectedKeys, manageData } = this.state
        console.log(">>>",selectedKeys)
        let params = {
            userId: [manageData.userId],
            roles: selectedKeys
        }
        let data = await bindUserRole(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            // this.getRoleList(manageData.id)
            message.success("绑定成功")
            this.setState({
                manageShow: false,
            })

        } else {
            message.error("绑定失败:"+data.message)
        }
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectUser()
            })
        } else {
            this.setState({
                    name: eve.target.value,
                    shipName: name,
                }, () => {
                    this.selectUser(this.state.name, 'select')
                }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.selectUser(data.name)
        this.setState({
            name: data.name
        })
    }
    // 查询所有用户
    async selectUser(value, type) {
        let { id, userName,status, name,pageTotal,pagination } = this.state;
        let params = {
            id,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
            keyWords: value ? value : name,
            status: status ? status: 0,
        }
        let data = await selectUser(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        userDataArr: data.rows,
                        ulshow: true,
                        loading: false
                    }
                )
            } else {
                this.setState({
                    ulshow: false,
                    userData: data.rows,
                    pageTotal: data.total
                })
            }

            } else if (data.code == 505) {
                this.props.history.push('/login')
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.roleId
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        console.log("<<<",data)
        let arr = []
        data.forEach((item) => {
            item.key = item.roleId
            if (item.check) {
                arr.push(item.roleId)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        console.log(">>>",arr)
        return arr
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 点击表格删除事件
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 点击表格修改事件
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }
    // 点击表格重置密码事件
    resetPassword = (data) => {
        this.setState({
            resetShow: true,
            resetData: data
        })
    }
    // 点击表格解锁事件
    async updateLoginCount(user){
        let {admin}=this.state;
        console.log(admin,user,'dddddd')
        let params={
            id:user.name,
            adminId:admin.name
        }
        let data= await updateLoginCount(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if(data&&data.code==200){
            message.success(data.message)
        }else{
            message.error(data.message)
        }
    }
    // 点击表格管理的事件
    clickManage = (data) => {
        //this.getUgroupTree(data.id)
        console.log(">>>",">>>"+JSON.stringify(data))
        this.getRoleList(data.userId)
        this.setState({
            manageShow: true,
            manageData: data
        })
    }
    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }

    // 自定义判断两次密码是否一样
    compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.state.pasValue) {
            callback('两次密码不一致!');
        } else {
            callback();
        }
    };
    // 获取第一次输入的密码
    haveNewValue = (e) => {
        this.setState({
            pasValue: e.target.value
        })
    }
    //用户分配权限的选中
    distribute_onChage = (selectedRowKeys) => {
        //str = str.substring(0, str.lastIndexOf(','));
        console.log(">>>","-------------------"+selectedRowKeys)
        let keyArr = this.getSelectKey(this.state.roleData, selectedRowKeys)
        console.log("+++++++++++++++++++++++++"+keyArr);
        this.setState({
            selectedKeys: keyArr
        })
    }
    // 扁平化多重嵌套数组对象
    flatten = (arr) => {
        let myArr = []
        for (const item of arr) {
            myArr.push(item);
            if (item.children && item.children.length > 0) {
                myArr.push(...this.flatten(item.children))
            }
        }
        return myArr
    }
    // 模糊搜索
    getSearchValue = (data, value) => {
        let dataSource = this.flatten(data)
        let newArr;
        if (value) {
            newArr = dataSource.filter((item) => {
                console.log(value, item.name.indexOf(value), 'item')
                return item.name.indexOf(value) != -1
            })
        } else {
            newArr = data
        }

        this.setState({
            nowModalData: newArr
        })
    }
    getSelectKey = (data, key) => {
        console.log('-----------------------checking------------------------------'+JSON.stringify(data),JSON.stringify(key))
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }

    handleChange = (value) => {
      console.log(`selected ${value}`);
            this.setState({
                status: value,
            })
    };

    render() {
        let { userData, modifyData, userDataArr, nowModalData, ulshow, selectedKeys, roleData, pageTotal } = this.state
        const secretKey = '123456789';
        // let password= CryptoJS.DES.encrypt('1234', CryptoJS.enc.Utf8.parse(secretKey), {
        //     mode: CryptoJS.mode.ECB,
        //     padding: CryptoJS.pad.Pkcs7
        // }).toString()
        // let passwordArr= CryptoJS.DES.decrypt(password, CryptoJS.enc.Utf8.parse(secretKey), {
        //     mode: CryptoJS.mode.ECB,
        //     padding: CryptoJS.pad.Pkcs7
        // }).toString(CryptoJS.enc.Utf8)

        /*  console.log(password, '||',passwordArr,'12312321')*/
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };

        //用户类型下拉框
        let userType = [{value:"0",text:"管理员"},{value:"1",text:"普通用户"},{value:"2",text:"推送用户"},{value:"3",text:"微信用户"}]

        let userStatus = [{value:"0",text:"启用"},{value:"1",text:"禁用"}]
        //用户类型下拉框
        const columns = [
            {
                title: '用户编号',
                key: 'code',
                dataIndex: 'code',
            },
            {
                title: '用户名称',
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: '类型',
                key: 'type',
                dataIndex: 'type',

                render: (type) => {
                    let newText;
                    if (type == 0) {
                        newText = '管理员'
                    }else if(type == 1){
                        newText = '普通用户'
                    }else if (type == 2) {
                        newText = '推送用户';
                    }else if (type == 3) {
                        newText = '微信用户';
                    } else {
                        newText ='没填值'
                    }
                    return newText;
                },
            },
            {
                title: '手机号',
                key: 'mobile',
                dataIndex: 'mobile',
            },
            {
                title: '状态',
                key: 'accountState',
                dataIndex: 'accountState',

                render: (accountState) => {
                    let newText;
                    if (accountState == 0) {
                        newText = '启用'
                    }else if(accountState == 1){
                        newText = '禁用'
                    }
                    return newText;
                },
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },
            {
                title: '创建时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
                render: (gmtCreate) => {
                    let time = moment(parseInt(gmtCreate)).format('YYYY-MM-DD ')
                    return time;
                },

            },

            {
                title: '操作',
                key: 'tool',
                render: (text, record) => {
                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.modifyData(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.resetPassword(record)}>重置</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.clickManage(record)}>权限</span>
                    </div>
                },
            },
        ]
        const columns2 = [
            {
                title: '权限名称',
                key: 'roleName',
                dataIndex: 'roleName',
            },

            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
                render: (status) => {
                    let newText;
                    if (status === 0) {
                        newText = '启用'
                    }else if(status === 1){
                        newText = '禁用'
                    } else {
                        newText ='没记录'
                    }
                    return newText;
                },
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
                render: (text) => {
                    return text ? text : '--'
                }
            },
        ]
        //添加用户的表单
        const formItemList = [
            {
                label: '用户编号', //表单label
                id: 'code', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }] }
            },
            {
                label: '用户名称', //表单label
                id: 'name', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }] }
            },
                {
                label: '用户类别：',
                id: 'type',
                component: <Select style={{ width: 100 }} placeholder={'请选择'}>
                    {
                        <Select.Option value={0} >管理员</Select.Option>
                    }
                    {
                        <Select.Option value={1} >普通员工</Select.Option>
                    }
                    {
                        <Select.Option value={2} >推送用户</Select.Option>
                    }
                    {
                        <Select.Option value={3} >微信用户</Select.Option>
                    }
                </Select>,
                options:  { rules: [{ required: true, message: '不能为空!' }] }
            },

            {
                label: '密码', //表单label
                id: 'password', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' },
                {
                    ///^[A-Z]((?![^a-z]+$)(?!\D+$).{7,14}$)$/
                    pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
                    //密码至少8位，包括数字、大小写字母和特殊字符三种及以上
                    message: '请输入以字母开头,长度至少是8位以上的数字、大小写字母和特殊字符的新密码'
                }] }
            },
            {
                label: '手机号', //表单label
                id: 'mobile', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <TextArea placeholder={'请输入'} style={{ height: 80 }} />, //表单受控组件
            },
        ]
        const resetList = [
            {
                label: '新密码',
                id: 'userId',
                component: <Input placeholder={'请输入'} type="password" onBlur={this.haveNewValue} />,
                options: { rules: [{ required: true, message: '不能为空!' },
                    {
                        pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
                        //密码至少8位，包括数字、大小写字母和特殊字符三种及以上
                        message: '请输入以字母开头,长度至少是8位以上的数字、大小写字母和特殊字符的新密码'

                    }], }
            },
            {
                label: '确认密码',
                id: 'newPass',
                component: <Input placeholder={'请输入'} type="password" />,
                options: { rules: [{ required: true, message: '不能为空!' },
                    {
                        pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
                        //密码至少8位，包括数字、大小写字母和特殊字符三种及以上
                        message: '请输入以字母开头,长度至少是8位以上的数字、大小写字母和特殊字符的新密码'

                    } ,{ validator: this.compareToFirstPassword }] },
                // validator:true
            },
        ]
        const modifyList = [
            {
                label: '用户编号', //表单label
                id: 'code', //表单Item的Key值
                rereadOnly: true,
                component: <Input placeholder={'请输入'}  readOnly/>, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.code ? modifyData.code : '' }
            },
            {
                label: '用户名称', //表单label
                id: 'name', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }],initialValue: modifyData.name ? modifyData.name : '' }
            },
            {
                label: '用户类别', //表单label
                id: 'type', //表单Item的Key值
                component: <Select placeholder={'请选择'}>
                    {
                        userType.map((item) => (
                            <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.type ? modifyData.type : '' }
            },
            {
                label: '用户状态', //表单label
                id: 'accountState', //表单Item的Key值
                component: <Select placeholder={'请选择'}>
                    {
                        userStatus.map((item) => (
                            <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.accountState ? modifyData.accountState : '' }
            },
            // {
            //     label: '密码', //表单label
            //     id: 'password', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            //     options: { rules: [{ required: true, message: '不能为空!' }], initialValue: CryptoJS.DES.decrypt(modifyData.password ? modifyData.password:'', CryptoJS.enc.Utf8.parse(secretKey), {
            //             mode: CryptoJS.mode.ECB,
            //             padding: CryptoJS.pad.Pkcs7
            //         }).toString(CryptoJS.enc.Utf8)  ? CryptoJS.DES.decrypt(modifyData.password ? modifyData.password:'', CryptoJS.enc.Utf8.parse(secretKey), {
            //             mode: CryptoJS.mode.ECB,
            //             padding: CryptoJS.pad.Pkcs7
            //         }).toString(CryptoJS.enc.Utf8) : '' }
            // },
            {
                label: '手机号', //表单label
                id: 'mobile', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.mobile ? modifyData.mobile : '' }
            },
            {
                label: '剩余次数', //表单labelC
                id: 'loginCount', //表单Item的Key值
                component: <Input placeholder={'距离锁定还剩次数'} />, //表单受控组件
                options: {   initialValue: modifyData.loginCount ? modifyData.loginCount : '' }
            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <TextArea placeholder={'请输入'} style={{ height: 80 }} />, //表单受控组件
                options: { initialValue: modifyData.remark ? modifyData.remark : '' }
            },
        ]

        return (
            <div className='shipmonitoring contentBox'>
                <div>
                    <label style={{width:150,marginTop:50,marginLeft:10}}>用户编号或用户名称: </label>
                    <Input
                        style={{ display: 'inline', width: 215,marginLeft:10,marginBottom:10}}
                        value={this.state.name}
                        className='Searchvalue'
                        onPressEnter={() => this.selectUser()}
                        // onSearch={value => this.selectUser(value, 'select')}
                        onChange={value => this.controlList(value)}
                        placeholder="请输入用户名称或用户编号" />

                     <label style={{width:150,marginTop:50,marginLeft:10}}>用户状态: </label>
                     <Select
                                placeholder={'请选择'}
                                style={ {width:150, marginLeft:10,marginBottom:10}}
                                name='status'
                                defaultValue="0"
                                onChange={value => this.handleChange(value)} >
                                {
                                    userStatus.map((item) => (
                                        <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                                    ))
                                }
                    </Select>,

                    {/* <SearchTop style={{display: 'inline'}}  adding="true" className="SearchTopvalue" onclick={this.addClickShow} onSearch={this.getSearchValue} /> */}
                    <Button type='primary' style={{ position: 'absolute', right: 200 }} onClick={() => this.selectUser()}>查询</Button>
                    <Button
                        style={{ position: 'absolute',
                            right: 120,
                            color:"white",
                        backgroundColor: '#FE7247',
                        border: 'unset' }}
                             onClick={() => {
                                 this.setState({
                                     name: '',
                                 }, () => {
                                     this.selectUser()
                                 })
                             }}
                    >重置</Button>
                    <Button onClick={() => this.addClickShow()} type="primary" style={{ position: 'absolute', right: 40 }}>新增</Button>
                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            this.state.userDataArr && this.state.userDataArr.map((item, index) => (
                                <li
                                    key={item.id}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div className="userManagement">
                    <Table
                        columns={columns}
                        dataSource={userData}
                        scroll={{y:600}}
                        //此行代码用以解决唯一key值问题
                        rowKey={record=>record.id}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectUser(selectMenuDataObj);
                                })
                            }
                        }}
                        onChange={this.handleTableChange}

                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertUser}
                    title="新增"
                />
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    submit={this.updateUser}
                    title="修改"
                />
                <ModalForm
                    visible={this.state.resetShow}
                    formItemList={resetList}
                    closeModal={this.closeModal}
                    submit={this.updatePwd}
                    title="重置密码"
                />
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.deleteUserById}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.code}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.manageShow}
                    onCancel={this.closeModal}
                    title="权限分配管理"
                    // footer={false}
                    width={826}
                    className="fenpeiModal"
                    onOk={this.bindUserRole}
                >
                    <Search
                        placeholder="模糊搜索"
                        onSearch={value => this.getSearchValue(this.state.roleData, value)}
                        style={{ width: 200, float: 'right', margonBottom: 50 }}
                    />
                    <Table
                        //rowKey={record => record.id}
                        columns={columns2}
                        //默认是复选框
                        rowSelection={{ type:"radio",...distribute_rowSelect }}
                        //去掉复选框  onRowClick={this.onRowClick}
                        dataSource={nowModalData ? nowModalData : roleData}
                    />
                </Modal>
            </div>
        )
    }
}
export default UserMonitoring