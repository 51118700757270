import "ol/ol.css";
import {
    formatDegree
} from "../../../tools/Tools_POST_Request";
import {
    fromLonLat,
    get,
    transform,
    getTransform
} from 'ol/proj.js';
import Render3D from 'ol-ext/layer/Render3D'
import WFS from 'ol/format/WFS.js'
import filter from 'ol/format/filter'
import GeoJSON from 'ol/format/GeoJSON'
import OSM from 'ol/source/OSM.js';
import Olmap from 'ol/Map.js';
import View from 'ol/View.js';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Coordinate from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import CircleStyle from 'ol/style/Circle';
import CircleGeom from 'ol/geom/Circle'
import ImageStyle from 'ol/style/Image';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
// import GeometryType from 'ol/geom/GeometryType';
import TileLayer from 'ol/layer/Tile.js';
import XYZ from 'ol/source/XYZ';
import ImageLayer from 'ol/layer/Image.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import Cluster from 'ol/source/Cluster';
import Draw from 'ol/interaction/Draw';
import KML from 'ol/format/KML';
import TileWMS from 'ol/source/TileWMS.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import {
    createBox
} from 'ol/interaction/Draw';
import Collection from 'ol/Collection';
import Modify from 'ol/interaction/Modify';
import {
    shiftKeyOnly,
    singleClick
} from 'ol/events/condition';
import {
    unByKey
} from 'ol/Observable';
import { MousePosition, OverviewMap } from 'ol/control';
import ScaleLine from 'ol/control/ScaleLine';
import {
    defaults
} from 'ol/control';
import SelectPointAnimation from '../../../tools/SelectPointAnimation'
import closeImg from '../../../img/close.png'
import {
    getTianditu,
} from '../../../../tool_map/MapToolFun'
export default (function (window) {
    let projection = get("EPSG:3857");
    let sprojection = get('EPSG:4326');
    let Map = function (opt) {
        //地图divID
        /*传入参数的样子
            {
                target:"ubiMap",
                center:[121,29],
                zoom:1
            }
         */
        this.target = opt.target ? opt.target : "ubiMap";
        this.map = null;
        this.measureTooltipOverlayList = [];//测量工具，存储数据标识图层
        this.layers = {};//point layer
        this.layers_wms = {};//point layer
        this.layers_default = {};//point layer
        this.layersPolygon = {};//polygon layer
        this.clusterSource = {};
        this.layerStyle = {};
        this.showTextLayer = {};
        this.overlay = {};
        this.controls = {};
        this.hlLayers = []; // 启用高亮
        this.sfLayers = []; // 启用的点击交互图层
        this.clusterLayer = {};
        this._init.call(this, opt);
        this.draw_Measure = undefined;
        this.draw_Measure_search = undefined;
    };

    //每次测绘结束，存储测绘数据标识图层
    Map.prototype.addTooltipOverlayList = function (overlayList) {
        this.measureTooltipOverlayList = this.measureTooltipOverlayList.concat(overlayList);
    }
    //用户结束测绘功能，清空地图上所有标识
    Map.prototype.clearTooltipOverlayList = function () {
        this.measureTooltipOverlayList.map(item => {
            this.map.removeOverlay(item);
        })
    }
    Map.prototype.addDraw_Measure_search = function (draw) {
        this.draw_Measure_search = draw;
    }
    Map.prototype.clearDraw_Measure_search = function () {
        this.map.removeInteraction(this.draw_Measure_search);
    }
    Map.prototype.addDraw_Measure = function (draw) {
        this.draw_Measure = draw;
    }
    Map.prototype.clearDraw_Measure = function () {
        this.map.removeInteraction(this.draw_Measure);
    }


    //添加瓦片图层
    Map.prototype.addTile = function (param, urlFunc) {
        if (!param || !param.key) return;

        var oneurl = urlFunc ? urlFunc() : param.url;
        var tilelayer = new TileLayer({
            visible: param.visible == null ? true : param.visible,
            opacity: param.opacity == null ? 1 : param.opacity,
            source: new XYZ({
                url: oneurl
            })
        });
        tilelayer.set("id", param.key);
        tilelayer.set("type", "tile");
        this.map.addLayer(tilelayer);
        // this.layers[param.key] = tilelayer;

    };
    /**
     *@Description:{ 初始化map }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-16 18:52:42
    */
    Map.prototype._init = function (opt) {
        let tdAnnotation = getTianditu({
            'params': {
                'target': 'default_WMTS_tdAnnotation',
                'zIndex': '12',
            },
            'type': '矢量注记',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdAnnotation'] = tdAnnotation;
        let tdRoads = getTianditu({
            'params': {
                'target': 'default_WMTS_tdRoads',
            },
            'type': '矢量底图',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdRoads'] = tdRoads;
        let tdRemoteSensing = getTianditu({
            'params': {
                'target': 'default_WMTS_tdRemoteSensing',
                'visible': false,
            },
            'type': '影像底图',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdRemoteSensing'] = tdRemoteSensing;
        let tdAnnotationSensing = getTianditu({
            'params': {
                'target': 'default_WMTS_tdAnnotationSensing',
                'visible': false,
                'zIndex': '12',
            },
            'type': '影像注记',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdAnnotationSensing'] = tdAnnotationSensing;

        //聚合图层
        // let vectorCluster = new VectorLayer({
        //     target: 'vectorCluster',
        //     zIndex: 107,
        //     visible: true

        // })

        //点图标展示图层数据
        var sourceTrajectory = new VectorSource({
            wrapX: false
        });
        let vectorTrajectory = new VectorLayer({
            target: 'vectorTrajectory',
            zIndex: 110,
            visible: true,
            source: sourceTrajectory,
        })
        this.layers.vectorTrajectory = vectorTrajectory;
        //点图标展示图层数据
        var sourceImg = new VectorSource({
            wrapX: false
        });
        let vectorImg = new VectorLayer({
            target: 'vectorImg',
            zIndex: 108,
            visible: false,
            source: sourceImg,

        })

        let vectorBorder = new VectorLayer({
            target: 'vectorBorder',
            zIndex: 106,
            visible: true

        })
        var r3D = new Render3D({
            height: 100, defaultHeight: 90.5, style: new Style({ //图层样式
                fill: new Fill({
                    color: 'rgba(10, 239, 247, 0.7)' //填充颜色
                }),
                stroke: new Stroke({
                    color: 'rgba(11, 148, 228, 0.8)', //边框颜色
                    width: 2 // 边框宽度
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'rgba(11, 148, 228, 0.8)'
                    })
                })
            })
        });
        vectorImg.setRender3D(r3D);
        this.layers.vectorImg = vectorImg;
        // this.layers.vectorCluster = vectorCluster;

        this.layersPolygon.vectorBorder = vectorBorder;//边界图层

        //加载画图搜索的绘制矢量层
        var source_draw_search = new VectorSource(); //图层数据源
        var vector_draw_search = new VectorLayer({
            target: 'vectorMapping_draw_search',
            name: 'vectorMapping_draw_search',
            zIndex: 113,
            source: source_draw_search,
            style: new Style({ //图层样式
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)' //填充颜色
                }),
                stroke: new Stroke({
                    color: 'rgba(11, 148, 228, 0.8)', //边框颜色
                    width: 2 // 边框宽度
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'rgba(11, 148, 228, 0.8)'
                    })
                })
            })
        });
        vector_draw_search.setRender3D(r3D);
        this.layers.vector_draw_search = vector_draw_search;
        //加载测量的绘制矢量层
        var source_draw = new VectorSource(); //图层数据源
        var vector_draw = new VectorLayer({
            target: 'vectorMapping_draw',
            name: 'vectorMapping_draw',
            zIndex: 109,
            source: source_draw,
            style: new Style({ //图层样式
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)' //填充颜色
                }),
                stroke: new Stroke({
                    color: 'rgba(11, 148, 228, 0.8)', //边框颜色
                    width: 2 // 边框宽度
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'rgba(11, 148, 228, 0.8)'
                    })
                })
            })
        });
        vector_draw.setRender3D(r3D);
        this.layers.vector_draw = vector_draw;
        // Set 3D renderer

        let mapDefaultToolList = (opt.createDefaultTool || typeof (opt.createDefaultTool) == 'undefined') ? [
            new MousePosition({
                coordinateFormat: (cood) => {
                    cood = transform(cood, 'EPSG:3857', 'EPSG:4326');
                    if (this._format) {
                        return formatDegree(cood[0]) + ',' + formatDegree(cood[1]);
                    }
                    return cood[0].toFixed(6) + ',' + cood[1].toFixed(6);
                },
                className: 'custom-mouse-position',
                target: document.getElementById('mouse-position'),
                zoom: false

            }),
            // new OverviewMap({
            //     className: 'custom-MapControl_OverviewMap',
            //     target: document.getElementById('mapControl_OverviewMap'),
            //     collapsible: false
            // })//全局地图控件，显示当前视口中的地图位于全局地图的哪一部分，
        ] : [];
        this.map = new Olmap({
            layers: [
                // tdRoads,
                // tdRemoteSensing,
                // tdAnnotationSensing,
                // tdAnnotation,
                // vectorCluster,
                vectorImg,
                vectorBorder,
                vector_draw,
                vector_draw_search,
                vectorTrajectory,
            ],
            view: new View({
                center: transform(opt.center, get('EPSG:4326'), get('EPSG:3857')),
                zoom: opt.zoom,
                maxZoom: opt.maxZoom ? opt.maxZoom : 18,
                minZoom: opt.minZoom ? opt.minZoom : 2
            }),
            target: this.target,
            controls: defaults({
                zoom: false,//禁用缩放按钮
            }).extend(
                mapDefaultToolList
            )
        });
        //判断是否需要加载默认天地图
        if (opt.createDefaultTianDi || typeof (opt.createDefaultTianDi) == 'undefined') {
            this.map.addLayer(tdRoads);
            this.map.addLayer(tdRemoteSensing);
            this.map.addLayer(tdAnnotationSensing);
            this.map.addLayer(tdAnnotation);
        }
        //添加 比例尺 控件
        if (opt.createDefaultTool || typeof (opt.createDefaultTool) == 'undefined') {
            var scaleLineControl = new ScaleLine({
                units: 'metric',
                target: 'scalebar',
                className: 'ol-scale-line'
                // className: 'custom-MapControl_scaleLineControl',
                // target: document.getElementById('mapControl_scaleLineControl'),
            });
            this.map.addControl(scaleLineControl);
        }
    };

    //关闭图层
    Map.prototype.closeOverlayAll = function () {
        let overlay = this.overlay;
        if (overlay) {
            let overlayKeys = Object.keys(overlay);
            if (overlayKeys && overlayKeys.length > 0) {
                this.map.getOverlays().clear();
            }
        }

    }
    //关闭图层
    Map.prototype.closeLayerVisibleBylayerName = function (layerName) {
        let layer = this.layers[layerName];
        if (layer) {
            layer.setVisible(false);
        }
        let layerPolygon = this.layersPolygon[layerName];
        if (layerPolygon) {
            layerPolygon.setVisible(false);
        }
        let layer_tiandi = this.layers_default[layerName];
        if (layer_tiandi) {
            layer_tiandi.setVisible(false);
        }
        let layers_wms = this.layers_wms[layerName];
        if (layers_wms) {
            layers_wms.setVisible(false);
        }
    }
    //开启图层
    Map.prototype.openLayerVisibleBylayerName = function (layerName) {
        let layer = this.layers[layerName];
        if (layer) {
            layer.setVisible(true);
        }
        let layerPolygon = this.layersPolygon[layerName];
        if (layerPolygon) {
            layerPolygon.setVisible(true);
        }
        let layer_tiandi = this.layers_default[layerName];
        if (layer_tiandi) {
            layer_tiandi.setVisible(true);
        }
        let layers_wms = this.layers_wms[layerName];
        if (layers_wms) {
            layers_wms.setVisible(true);
        }
    }
    Map.prototype.createEventChangeResolution = function (obj) {
        let map = this.map;
        let view = this.map.getView();
        // let vectorCluster = this.layers.vectorCluster;
        let vectorImg = this.layers.vectorImg;
        let isBigZoom = false;
        //.log(view.getZoom(), "------------------createEventChangeResolution zoom")
        view.on('change:resolution', function () {
            let zoom = view.getZoom();
            let isCanChange = false;
            if (zoom >= 13) {
                if (!isBigZoom) {
                    isBigZoom = true;
                    obj.openOverlayAll();
                }
            }
            else {
                if (isBigZoom) {
                    isBigZoom = false;
                    obj.closeOverlayAll();
                }

            }
        })
    }

    /**
     *@Description:{ 
         所选区域（镇，片，河）的边界闪烁
         因为是边界闪烁，所以不填充切片
         }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-12-23 14:16:33
    */
    Map.prototype.autoChangeLayerStyle_border = function (isFlicker, target) {
        let layer
        let styleF = new Style({ //默认样式,Object.assign合并对象
            // fill: new Fill({
            //     color: 'rgba(12, 222, 214, 1.0)',
            //     // width: 3

            // }),
            stroke: new Stroke({
                color: 'rgba(12, 222, 214, 1.0)',
                width: 5
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            // })
        })
        let styleNoF = new Style({ //默认样式,Object.assign合并对象
            // fill: new Fill({
            //     color: 'rgba(0, 0, 255, 1.0)',
            // }),
            stroke: new Stroke({
                color: 'rgba(22, 202, 234, 0.3)',
                // color: 'rgba(12, 12, 160,, 0.2)',
                width: 5
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 0.2)'
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 0.2)',
            //         // width: 5
            //     }),
            // })
        })
        layer = this.layersPolygon[target]
        //.log(isFlicker, '----------------------------border----auto change layer style')
        if (layer && isFlicker) {
            layer.setStyle(styleF)
        } else if (layer && !isFlicker) {
            layer.setStyle(styleNoF)
        }
    }

    /**
     *@Description:{ 自动切换 应巡河道切片样式}
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-28 11:02:36
    */
    Map.prototype.autoChangeLayerStyle = function (isFlicker) {
        let layer
        let styleF = new Style({ //默认样式,Object.assign合并对象
            fill: new Fill({
                color: 'rgba(12, 222, 214, 1.0)',
                // width: 3

            }),
            stroke: new Stroke({
                color: 'rgba(12, 222, 214, 1.0)',
                width: 0
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            // })
        })
        let styleNoF = new Style({ //默认样式,Object.assign合并对象
            fill: new Fill({
                color: 'rgba(0, 0, 255, 1.0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 255, 1.0)',
                // color: 'rgba(12, 12, 160,, 0.2)',
                width: 0
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 0.2)'
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 0.2)',
            //         // width: 5
            //     }),
            // })
        })
        Object.keys(this.layersPolygon).map(key => {
            if (key === 'showMap_DBData_standard') {
                layer = this.layersPolygon[key]
            }
        })
        // //.log(layer, '--------------------------------auto change layer style')
        if (layer && isFlicker) {
            layer.setStyle(styleF)
        } else if (layer && !isFlicker) {
            layer.setStyle(styleNoF)
        }
    }

    /**
     *@Description:{ 添加边界图层数据 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-12-23 14:11:30
    */
    Map.prototype.addLayer_polygon_border = function (features, target) {
        if (features && features.length > 0) {

            let color = 'rgba(12, 222, 214, 1.0)';
            let vectorSource = new VectorSource({
                wrapX: false, //不在地图上重复
                // features: (new GeoJSON().readFeatures(features)),
            });
            let style = new Style({ //默认样式,Object.assign合并对象
                // fill: new Fill({
                //     color: color,
                //     width: 1

                // }),
                stroke: new Stroke({
                    color: color,
                    width: 5
                }),
                // image: new CircleStyle({
                //     // radius: 7,
                //     fill: new Fill({
                //         color: 'rgba(12, 12, 160,, 1.0)',
                //         // width: 3
                //     }),
                //     stroke: new Stroke({
                //         color: 'rgba(12, 12, 160,, 1.0)',
                //         // width: 3
                //     }),
                // })
            })
            let featurePolygons = [];
            features.map((item, index) => {
                if (item.target && item.target == 'areaBorder_city') {
                    let featurePolygon;
                    const polygonsData = item.geometry.coordinates;
                    let polygon = new Polygon(JSON.parse(polygonsData));
                    polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
                    featurePolygon = new Feature(polygon);
                    featurePolygons = featurePolygons.concat(featurePolygon);
                    vectorSource.addFeature(featurePolygon);
                } else {
                    vectorSource.addFeature(item);
                }
            })
            if (featurePolygons.length > 0) {
                changeZoomAndCenter(this, 10, featurePolygons);
            } else {
                changeZoomAndCenter(this, 10, features);
            }


            let vectorLayer = this.layersPolygon[target];
            vectorLayer.setSource(vectorSource);
            vectorLayer.setVisible(true);
            vectorLayer.setStyle(style);
            //.log(vectorLayer, '---------------addLayer_polygon_border--')

        } else {
            let vectorLayer = this.layersPolygon[target];
            vectorLayer.setVisible(false);
        }
    }
    /**
     *@Description:{ add vector layer}
        *@InterfaceAddress:{ }
        *@parameter: { 
            polygonsData:Polygons data
            id:feature id
            style
    }
    *@ClassAuthor: ZDong
    *@Date: 2019-11-16 18:53:24
    */
    Map.prototype.addLayer_Polygon_DBData = function (polygonsDatas, params) {
        let color = params.color ? params.color : 'rgba(255,127,0,0.5)';
        let target = params.target ? params.target : 'default_polygon_Layer';
        let zIndex = params.zIndex ? params.zIndex : 11;
        let visible = typeof (params.visible) != 'undefined' ? params.visible : true;
        let isChangeZoomAndCenter = typeof (params.isChangeZoomAndCenter) != 'undefined' ? params.isChangeZoomAndCenter : true;
        let vectorSource = new VectorSource({
            wrapX: false, //不在地图上重复
            // features: (new GeoJSON().readFeatures(polygonsDataGJ)),
        });
        let featuresFit = []
        for (let index = 0; index < polygonsDatas && polygonsDatas.length; index++) {
            let featurePolygon
            const polygonsData = polygonsDatas[index];
            let polygon = new Polygon(polygonsData.geom);
            polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
            featurePolygon = new Feature(polygon);
            if (polygonsData.name) {
                featurePolygon.setStyle(new Style({ //默认样式,Object.assign合并对象
                    text: new Text({
                        font: '14px Microsoft YaHei',
                        text: polygonsData.name,
                        fill: new Fill({
                            color: '#666'
                        })
                    }),
                    fill: new Fill({
                        color: color,
                    }),
                    stroke: new Stroke({
                        color: color,
                        width: 2
                    }),
                }))
            }
            vectorSource.addFeature(featurePolygon);
            featuresFit = [
                ...featuresFit,
                featurePolygon
            ]
        }
        if (isChangeZoomAndCenter && featuresFit.length > 0) {
            this.changeZoomAndCenter(this, 11, featuresFit)
        }
        let vectorLayer = this.layersPolygon[target]
        if (vectorLayer) {
            vectorLayer.setSource(vectorSource)
            vectorLayer.setVisible(true)
        } else {
            vectorLayer = new VectorLayer({
                zIndex: zIndex,
                target: target,
                source: vectorSource,
                visible: visible,
                style: new Style({ //默认样式,Object.assign合并对象
                    fill: new Fill({
                        color: color,
                    }),
                    stroke: new Stroke({
                        color: color,
                        width: 2
                    }),
                    // image: new CircleStyle({
                    //     radius: 7,
                    //     fill: new Fill({
                    //         color: color
                    //     })
                    // })
                }),
            });
            this.map.addLayer(vectorLayer);
            this.layersPolygon = {
                ...this.layersPolygon,
                [target]: vectorLayer
            }
        }
    }

    /**
     *@Description:{ 添加WMS图层 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-16 20:08:45
    */
    Map.prototype.addWMSLayer = function (params) {
        let wmsUrl = params.url ? params.url : '';
        let optionalParams = params.optionalParams ? params.optionalParams : {};
        let lyr = new ImageLayer({
            ...optionalParams,
            target: params.target,
            source: new ImageWMS({
                url: wmsUrl,
                // projection:"EPSG:4326",
                params: {
                    // 'FORMAT':'application/openlayers',
                    // 'FORMAT': 'image/png',
                    // 'VERSION': '1.1.0',
                    //'BBOX':[113.029319763184,22.7097873687744,113.95068359375,23.7140617370605],
                    // 'tiled': false,
                    //'TILED':false,
                    // 'singleTile':true, 
                    'TILED': false,
                    'LAYERS': params.layerName
                    // 'STYLES': '',
                    // 'CRS':'EPSG:4326',
                },
                serverType: 'geoserver'
                // strategy: bboxStrategy
            })
        });
        this.map.addLayer(lyr);
        this.layers_wms[params.target] = lyr;
    }


    /**
     *@Description:{ 通过url向GeoServe请求Feature，创建wfs图层 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                    data:{
                        url:
                        params:{
                            layerName:'sunt: ',
                            srs: 'EPSG:3857',
                            name: ' ',
                        }
                    }
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-17 19:17:11
    */
    Map.prototype.createWFSLayerDict = function (obj, layerName, target, name) {
        let data = {
            url: 'http://221.181.88.134:6706/geoserver/sunt/wfs',
            params: {
                layerName: layerName,
                // layerName: 'sunt:river_name_liu',
                srs: 'EPSG:3857',
                name: name,
                // name: '水闸监控图层'
            },
            target: target,
        }
        //     let wfsUrl = ''
        // let vectorSource = new VectorSource({
        //     url: wfsUrl = function (extent) {
        //         return data.url + '?service=WFS&' +
        //             'version=1.1.0&request=GetFeature&typename=' + data.params.layerName +
        //             '&outputFormat=application/json&srsname=' + data.params.srs +
        //             '&bbox=' + extent.join(',') + ',' + data.params.srs;
        //     },
        //     strategy: bboxStrategy
        // })
        // //.log(wfsUrl, "----------------wfsUrl")
        // let featureRequest = new WFS().writeGetFeature({
        //     srsName: 'EPSG:3857',
        //     featureNS: 'http://openstreemap.org',
        //     featurePrefix: 'osm',
        //     featureTypes: ['water_areas'],
        //     outputFormat: 'application/json',
        //     filter: filter.and(
        //         filter.like('name', 'Mississippi*'),
        //         filter.equalTo('waterway', 'riverbank')
        //     )
        // });
        // fetch(wmsUrl, {
        //     method: 'GET',
        //     // body: new XMLSerializer().serializeToString(featureRequest)
        // }).then(function (response) {
        //     return response.json();
        // }).then(function (json) {
        //     //.log(JSON.stringify(json), "wfs请求后台，返回的json数据");
        //     // alert("加载到的要素信息："+JSON.stringify(json));
        //     let features = new ol.format.GeoJSON().readFeatures(json);
        //     vectorSource.addFeatures(features);
        //     map.getView().fit(vectorSource.getExtent());
        //     let fs = vectorSource.getFeatures();
        //     // //.log(fs);
        //     //.log(vectorSource.getFeatures(), "--------getFeatures")
        //     //.log(vectorSource.getFeatures()[1].values_, "--------getFeatures")
        // });
        let features = []
        let vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: function (extent) {
                let url = data.url + '?service=WFS&' +
                    'version=1.1.0&request=GetFeature&typename=' + data.params.layerName +
                    '&outputFormat=application/json&srsname=' + data.params.srs +
                    '&bbox=' + extent.join(',') + ',' + data.params.srs;
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                let onError = function () {
                    vectorSource.removeLoadedExtent(extent);
                }
                xhr.onerror = onError;
                xhr.onload = function () {
                    if (xhr.status == 200) {
                        if (obj.state.dict_library_wfsFeatures) {
                        } else {
                            features = vectorSource.getFormat().readFeatures(xhr.responseText)
                            obj.setWFSFeaturesDict(features, target)
                        }
                        // vectorSource.addFeatures(features)
                    } else {
                        onError();
                    }
                }
                xhr.send();
            },
            strategy: bboxStrategy
        });
        let vector = new VectorLayer({
            visible: true,
            source: vectorSource,
            name: data.name,
            target: data.target,
        });
        this.map.addLayer(vector);
        // this.map.removeLayer(vector)
        return features;
    }

    /**
     *@Description:{ 根据features 创建图层}
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-21 11:49:58
    */
    Map.prototype.createWFSLayer = function (features) {
        let vectorSource = new VectorSource({
            format: new GeoJSON(),
            strategy: bboxStrategy
        });
        vectorSource.addFeatures(features)
        let vector = new VectorLayer({
            visible: true,
            source: vectorSource,
            name: data.name
        });
        return vector;
    }

    /**
     *@Description:{ 传递参数调用创建图层方法，并且把返回的layer添加进地图 }
     *@InterfaceAddress:{ }
     *@parameter: {
         data:{
             url:
             params:{
                 layerName:'sunt: ',
                 srs: 'EPSG:3857',
                 name: ' ',
                }
            }
        }
        *@ClassAuthor: ZDong
        *@Date: 2019-11-17 19:17:11
        */
    Map.prototype.addLayer_WFS = function (data) {
        let newLayer = this.createWFSLayer(data)
        this.map.addLayer(newLayer);
    }


    Map.prototype.addIcon_FeatureAndStyle_Line = function (feature, vectorStyle) {
        if (this.layers[vectorStyle.target] && this.layers[vectorStyle.target].getSource()) {
            this.layers[vectorStyle.target].getSource().clear();
        } else {
            let vector = new VectorLayer({
                target: vectorStyle.target,
                zIndex: vectorStyle.zIndex,
                visible: false,
            })
            this.map.addLayer(vector);
            this.layers[vectorStyle.target] = vector;
        }
        let source = this.layers[vectorStyle.target].getSource();
        if (source) {
        } else {
            source = new VectorSource({
                wrapX: false
            });
            this.layers[vectorStyle.target].setSource(source);
        }
        source.addFeature(feature);
        // this.layers[vectorStyle.target].setStyle(style);
        this.layers[vectorStyle.target].setSource(source);
        this.layers[vectorStyle.target].setVisible(true);
    }
    Map.prototype.addIcon_FeatureAndStyle_head = function (feature, vectorStyle) {
        if (this.layers[vectorStyle.target] && this.layers[vectorStyle.target].getSource()) {
            this.layers[vectorStyle.target].getSource().clear();
        } else {
            let vector = new VectorLayer({
                target: vectorStyle.target,
                zIndex: vectorStyle.zIndex,
                visible: false,
            })
            this.map.addLayer(vector);
            this.layers[vectorStyle.target] = vector;
        }
        let source = this.layers[vectorStyle.target].getSource();
        if (source) {
        } else {
            source = new VectorSource({
                wrapX: false
            });
            this.layers[vectorStyle.target].setSource(source);
        }
        source.addFeature(feature);
        // this.layers[vectorStyle.target].setStyle(style);
        this.layers[vectorStyle.target].setSource(source);
        this.layers[vectorStyle.target].setVisible(true);
    }
    Map.prototype.addIcon_Feature = function (feature, vectorStyle) {
        let vector;
        if (this.layers[vectorStyle.target]) {
            vector = this.layers[vectorStyle.target];
        } else {
            vector = new VectorLayer({
                target: vectorStyle.target,
                zIndex: vectorStyle.zIndex,
                visible: false,
            })
            this.map.addLayer(vector);
            this.layers[vectorStyle.target] = vector;
        }
        let source = this.layers[vectorStyle.target].getSource();
        if (source) {
        } else {
            source = new VectorSource({
                wrapX: false
            });
            this.layers[vectorStyle.target].setSource(source);
        }
        source.addFeature(feature);
        // this.layers[vectorStyle.target].setStyle(style);
        this.layers[vectorStyle.target].setSource(source);
        this.layers[vectorStyle.target].setVisible(true);
    }
    Map.prototype.add_Features = function (features, vectorStyle) {
        let vector;
        if (this.layers[vectorStyle.target]) {
            vector = this.layers[vectorStyle.target];
        } else {
            vector = new VectorLayer({
                target: vectorStyle.target,
                zIndex: vectorStyle.zIndex,
                visible: false,
            })
            this.map.addLayer(vector);
            this.layers[vectorStyle.target] = vector;
        }
        let source = this.layers[vectorStyle.target].getSource();
        if (source) {
        } else {
            source = new VectorSource({
                wrapX: false
            });
            this.layers[vectorStyle.target].setSource(source);
        }
        if (features && features.length > 0) {
            features.map((feature, index) => {
                source.addFeature(feature);
            })
        }
        // this.layers[vectorStyle.target].setStyle(style);
        this.layers[vectorStyle.target].setSource(source);
        this.layers[vectorStyle.target].setVisible(true);
    }
    Map.prototype.addIcon_FeatureAndStyle = function (points, vectorStyle) {
        // if (this.layers.vectorCluster && this.layers.vectorCluster.getSource()) {
        //     this.layers.vectorCluster.getSource().clear();
        // }
        let vector;
        if (this.layers[vectorStyle.target]) {
            vector = this.layers[vectorStyle.target];
        } else {
            vector = new VectorLayer({
                target: vectorStyle.target,
                zIndex: vectorStyle.zIndex,
                visible: false,
            })
            this.map.addLayer(vector);
            this.layers[vectorStyle.target] = vector;
        }
        let source = this.layers[vectorStyle.target].getSource();
        if (source) {
        } else {
            source = new VectorSource({
                wrapX: false
            });
            this.layers[vectorStyle.target].setSource(source);
        }
        let shipImg = require('../../../img/ship_blue.png');
        let ship_selected_blue = require('../../../img/ship_selected_blue.png');
        let ship_selected_black = require('../../../img/ship_selected_black.png');
        let ship_selected_yellow = require('../../../img/ship_selected_yellow.png');
        let cameraUnRunningImg = '';
        let selectIconParams = {
            // scale: 1,
            // offset:[-30,-45],
            // offsetOrigin:'bottom-right',
            // size:[100,100]

        }

        points.map((item, index) => {
            if (item.values_ && item.values_.isFeature) {
                source.addFeature(item)
            } else {
                if (item.lon && item.lat
                    && item.lon < 180 && item.lon > -180
                    && item.lat < 90 && item.lat > -90
                ) {
                    let iconImg = shipImg;
                    let selectIconParamsSure = {};
                    if (item.isSelect) {
                        iconImg = ship_selected_blue;
                        selectIconParamsSure = {
                            ...selectIconParams,
                        }
                    }
                    // switch (item.networkFlag) {
                    //     case 0: iconImg = cameraUnRunningImg; break
                    //     case 1: iconImg = cameraRunningImg; break
                    // }
                    let coordinate = [item.lon, item.lat]
                    let feature = new Feature({
                        geometry: new Point(transform(coordinate, 'EPSG:4326', 'EPSG:3857')),
                        ...item,
                    })
                    if (iconImg) {
                        let icon = new Icon({
                            src: iconImg,
                            // anchor: [0, 0],
                            rotateWithView: true,
                            rotation: String((item.heading) * Math.PI / 180),
                            ...selectIconParamsSure,
                        });
                        feature.setStyle(new Style({
                            image: icon
                        }))
                        source.addFeature(feature)
                    }
                }
            }
        })
        this.layers[vectorStyle.target].setSource(source);
        this.layers[vectorStyle.target].setVisible(true);
    }
    Map.prototype.removeOverlay = function (id) {
        if (!id || !this.overlay[id]) return;
        this.map.removeOverlay(this.overlay[id]);
        delete this.overlay[id];
    };
    Map.prototype.addOverLayer = function (id, obj) {
        this.map.removeOverlay(id)
        let div = document.createElement("div")
        let overlay = new Overlay({
            id: id,
            //设置弹出框的容器
            element: div,
            //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
            autoPan: true,
        });
        this.overlay[id] = overlay;
        this.onClick_getFeatureInfo(obj, div);
        // this.map.on('click', function (e) {
        //     //在点击时获取像素区域
        //     let pixel = this.getEventPixel(e.originalEvent);
        //     let ids = [];
        //     this.forEachFeatureAtPixel(pixel, function (feature) {
        //         //coodinate存放了点击时的坐标信息
        //         let coodinate = e.coordinate;
        //         let id = feature.ol_lm.propertychange[0].target.values_.id
        //         //.log(id, div, overlay, coodinate, '-----id, div, overlay, coodinate------地图点击图标事件-------')
        //         // obj.getPointDetail(id, div, overlay, coodinate)
        //         ids = ids.concat(id);
        //     });
        //     if (ids.length>0) {
        //         obj.getPointDetail(ids);//将获取到的点id传给父组件，并且展示modal
        //     }
        // });
    }
    Map.prototype.addMapMonitoringEvents = function (obj) {
        let thisObj = this;
        let listener_canClickFeatureMouseChangeHand = this.map.on('pointermove', function (e) {
            //在鼠标在地图上移动时获取像素区域
            let pixel = this.getEventPixel(e.originalEvent);
            let features = [];
            thisObj.map.getTargetElement().style.cursor = "auto"
            this.forEachFeatureAtPixel(pixel, function (feature) {
                //添加选中特效
                // let pointLayer =  map.layers.vectorImg;
                // SelectPointAnimation.addPointAnimation(map,pointLayer,feature);

                //coodinate存放了点击时的坐标信息
                let coodinate = e.coordinate;
                // let id = feature.values_.isClickPoint ? feature.values_.id : undefined;
                //.log(id, coodinate, div, '-----id, div, overlay, coodinate----on--地图点击图标事件-------')
                // obj.getPointDetail(id, div, overlay, coodinate)
                if (feature.values_.isClickPoint) {
                    // features = features.concat(feature);
                    thisObj.map.getTargetElement().style.cursor = "pointer"
                }
            });
            // if (features.length > 0) {
            //     obj.getPointDetail(features);//将获取到的点id传给父组件，并且展示modal
            // }
        });
    }
    var listener;
    Map.prototype.onClick_getFeatureInfo = function (obj) {
        let map = this;
        //.log('---------------onClick_getFeatureInfo----------------------')
        listener = this.map.on('click', function (e) {
            //在点击时获取像素区域
            let pixel = this.getEventPixel(e.originalEvent);
            let features = [];
            this.forEachFeatureAtPixel(pixel, function (feature) {
                //添加选中特效
                let coodinate = e.coordinate;
                if (feature.values_.isClickPoint) {
                    features = features.concat(feature);
                }
            });
            if (features.length > 0) {
                obj.getPointDetail(features);//将获取到的点id传给父组件，并且展示modal
            }
        });
    }
    Map.prototype.unClick_getFeatureInfo = function (obj) {
        //.log('---------------unClick_getFeatureInfo----------------------')
        unByKey(listener);
        this.map.un('click', function (e) {
            //在点击时获取像素区域
            let pixel = this.getEventPixel(e.originalEvent);
            let ids = [];
            this.forEachFeatureAtPixel(pixel, function (feature) {
                //coodinate存放了点击时的坐标信息
                let coodinate = e.coordinate;
                let id = feature.ol_lm.propertychange ? feature.ol_lm.propertychange[0].target.values_.id : undefined;
                //.log(id, coodinate, '-----id, div, overlay, coodinate----un--地图点击图标事件-------')
                // obj.getPointDetail(id, div, overlay, coodinate)
                if (id) {
                    ids = ids.concat(id);
                }
            });
            if (ids.length > 0) {
                obj.getPointDetail(ids);//将获取到的点id传给父组件，并且展示modal
            }
        });
    }

    /*支持 IE 浏览器*/
    /*支持 FireFox 浏览器*/
    /*支持 Chrome, Opera, Safari 等浏览器*/
    Map.prototype.addOverlay_setPosition = function (features, obj) {
        // let closeImg = `<img id='closeOverlay' src=${require('../../../../img/close.png')} class='jk' alt='' style='margin-left: 230px;' />`
        let closeImg = document.createElement("img");
        //给按钮添加单击事件
        closeImg.setAttribute("id", "closeOverlay");
        closeImg.setAttribute("src", `${require('../../../img/close.png')}`);
        closeImg.setAttribute("style", "right: 10px;position:absolute;top:10px;z-index: 9999;cursor: pointer; ");

        let data = features[0].values_;
        let overlayId = 'trajectoryPointId_' + data.objectId;
        let coodinate = [data.lon, data.lat];
        coodinate = transform(coodinate, 'EPSG:4326', 'EPSG:3857');
        let div = document.createElement("div");
        obj.map.removeOverlay(overlayId);
        let overlay = new Overlay({
            id: overlayId,
            //设置弹出框的容器
            element: div,
            positioning: 'bottom-center'
            //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
            // autoPan: true,
        });
        div.setAttribute("style", "display:block");
        div.setAttribute("id", "div_trajectory_head_overlay_" + overlayId);
        div.innerHTML = ""
        div.appendChild(closeImg)
        // let divMask = document.createElement("div");
        // divMask.setAttribute("style", "position:absolute;top:0;bottom:0;right:0;left:0;background:rgba(0,0,0,0.6)")
        // divMask.onclick = function () {
        //     divMask.setAttribute("style", "display:none")
        // }
        // let divModal = document.createElement("div");
        // divModal.setAttribute("style", "width:600px;height:400px;margin:0 auto;margin-top:30px;z-index:99999")
        // divMask.appendChild(divModal)
        // let p_reportPicture = document.createElement("p");
        // p_reportPicture.innerHTML = p_reportPicture.innerHTML + "船舶名字 :"
        // for (let index = 0, len = data.reportPictureList.length; index < len; index++) {
        //     const element = data.reportPictureList[index];
        //     if (element && element != null) {
        //         let img_reportPicture = document.createElement("img");
        //         img_reportPicture.setAttribute("width", "50");
        //         img_reportPicture.setAttribute("height", "50");
        //         img_reportPicture.setAttribute("src", element);
        //         p_reportPicture.appendChild(img_reportPicture)
        //         img_reportPicture.onclick = function () {
        //             console.log(element, '-------------------------img_reportPicture')
        //             obj.showModal(element, data.reportPictureList)
        //         }
        //     }
        // }
        // let p_rectPicture = document.createElement("p");
        // p_rectPicture.innerHTML = p_rectPicture.innerHTML + "整改照片 :"
        // for (let index = 0, len = data.rectPictureList.length; index < len; index++) {
        //     const element = data.rectPictureList[index];
        //     if (element && element != null) {
        //         let img_rectPicture = document.createElement("img");
        //         img_rectPicture.setAttribute("width", "50");
        //         img_rectPicture.setAttribute("height", "50");
        //         img_rectPicture.setAttribute("src", element);
        //         p_rectPicture.appendChild(img_rectPicture)
        //         img_rectPicture.onclick = function () {
        //             obj.showModal(element, data.rectPictureList)
        //         }
        //     }
        // }

        let p_name = document.createElement("h3");
        p_name.innerHTML = p_name.innerHTML + ((data.objectName && data.objectName != null) ? data.objectName : '');

        let p_time = document.createElement("p");
        p_time.innerHTML = p_time.innerHTML + '时间: ' + ((data.locationTime && data.locationTime != null) ? data.locationTime : '');

        let p_speed = document.createElement("p");
        p_speed.innerHTML = p_speed.innerHTML + '速度: ' + ((data.speed && data.speed != null) ? data.speed + ' km/h' : '0' + ' km/h');

        let p_lon = document.createElement("p");
        let p_lat = document.createElement("p");
        let lonlat = transform(coodinate, 'EPSG:3857', 'EPSG:4326');
        lonlat = [
            lonlat[0].toFixed(8),
            lonlat[1].toFixed(8),
        ]
        p_lon.innerHTML = p_lon.innerHTML + '经度: ' + ((lonlat[0] && lonlat[0] != null) ? ToDegrees(lonlat[0], 'lon') : '');
        p_lat.innerHTML = p_lat.innerHTML + '纬度: ' + ((lonlat[1] && lonlat[1] != null) ? ToDegrees(lonlat[1], 'lat') : '');

        let divLevel2 = document.createElement("div");
        divLevel2.setAttribute("style",
            "margin-left: 10%; " +
            "position: absolute;" +
            // "-webkit-transform: translateY(-50%);" +
            // "-ms-transform: translateY(-50 %);" +
            // "-o-transform: translateY(-50 %);" +
            // "transform: translateY(-50 %);"
            "top: 5%;" +
            "padding:1vh;" +
            "background: rgba(255, 255, 255, 0.8);" +
            "border-radius: 5px;" +
            "width: 100%;"
        )
        divLevel2.appendChild(p_name)
        divLevel2.appendChild(p_time)
        divLevel2.appendChild(p_lon)
        divLevel2.appendChild(p_lat)
        divLevel2.appendChild(p_speed)

        let divLevel1 = document.createElement("div");
        divLevel1.setAttribute("style",
            "position: relative;" +
            "text-align:left;" +
            "margin:0 auto;" +
            "filter: alpha(opacity = 80);" +
            "-moz-opacity: 0.80;" +
            "color:black;" +
            // "border-radius: 5%;" +
            // "background: rgba(255, 255, 255, 0.8);" +
            "min-width:10vw;" +
            // "height:18vh;" +
            // "padding:1px;"+
            // "overflow: auto;" +
            "font-size: 14px;"
        )
        divLevel1.appendChild(divLevel2);
        div.appendChild(divLevel1)
        //设置overlay的显示位置
        closeImg.onclick = function () {
            //.log("关掉Overlayer")
            //1.创建节点
            // div.remove()
            div.setAttribute("style", "display:none")
        }
        var img = document.getElementById('closeOverlay');
        overlay.setPosition(coodinate);//显示overlay
        overlay.setVisible(true)
        obj.map.map.addOverlay(overlay);
        obj.map.overlay[overlayId] = overlay;
    }

    Map.prototype.closeAllLayers = function () {
        Object.keys(this.layers).map(key => {
            this.layers[key].setVisible(false)
        })
        Object.keys(this.layersPolygon).map(key => {
            this.layersPolygon[key].setVisible(false)
        })
        Object.keys(this.overlay).map(key => {
            this.map.removeOverlay(key)
        })
        let extent = [
            ...transform([120.493141, 30.938789], 'EPSG:4326', 'EPSG:3857'),
            ...transform([121.735883, 31.314396], 'EPSG:4326', 'EPSG:3857')
        ]
        // //.log(extent, '--------------------closeAllLayers here extent')
        this.map.getView().fit(extent)
    }
    Map.prototype.removeLayerAll = function () {
        // this.layers.vectorCluster.setVisible(false)
        this.layers.vectorImg.setVisible(false)
        Object.keys(this.overlay).map(key => {
            this.map.removeOverlay(key)
        })
    }
    Map.prototype.removeAll = function (noNeedRemoveList) {
        Object.keys(this.layers).map(item => {
            let isNeedRemove = true;
            if (noNeedRemoveList && noNeedRemoveList.length > 0) {
                noNeedRemoveList.map((target, index) => {
                    if (target == item) {
                        isNeedRemove = false;
                    }
                })
            }
            if (isNeedRemove && this.layers[item] && this.layers[item].getSource()) {
                this.layers[item].getSource().clear();
            }
        })
        Object.keys(this.layersPolygon).map(item => {
            let isNeedRemove = true;
            if (noNeedRemoveList && noNeedRemoveList.length > 0) {
                noNeedRemoveList.map((target, index) => {
                    if (target == item) {
                        isNeedRemove = false;
                    }
                })
            }
            if (isNeedRemove && this.layersPolygon[item] && this.layersPolygon[item].getSource()) {
                this.layersPolygon[item].getSource().clear();
            }
        })
        Object.keys(this.overlay).map(key => {
            this.overlay[key].setVisible(false)
            let div1 = document.getElementById('div_RegionalPort_head_overlay_' + key);
            if (div1 && div1 != null) {
                div1.setAttribute("style", "display:none");
            }
            let div2 = document.getElementById('div_trajectory_head_overlay_' + key);
            if (div2 && div2 != null) {
                div2.setAttribute("style", "display:none");
            }
            this.map.removeOverlay(key);
            delete this.overlay[key];
        })
        // this.layers={};
        // this.overlay={};
    }
    Map.prototype.removeLayerWithTarget = function (target) {
        Object.keys(this.overlay).map(key => {
            if (key === target) {
                this.map.removeOverlay(key)
            }
        })
        Object.keys(this.layers).map(key => {
            if (key === target) {
                this.layers[key].setVisible(false)
                if (this.layers[key].getSource()) {
                    this.layers[key].getSource().clear();
                }
                // this.layers[key].getSource() ? this.layers[key].getSource().clear() : ''
                // this.map.removeLayer(key)
            }
        })
        Object.keys(this.layersPolygon).map(key => {
            if (key === target) {
                this.layersPolygon[key].setVisible(false)
                // this.layersPolygon[key].getSource() ? this.layersPolygon[key].getSource().clear() : ''
                if (this.layersPolygon[key].getSource()) {
                    this.layersPolygon[key].getSource().clear()
                }
                // this.map.removeLayer(key)
            }
        })
    }
    Map.prototype.changeZoomAndCenter_point = function (point, zoom) {
        let view = this.map.getView();
        flyTo(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'), zoom, view, function () { });
        // view.animate({
        //     center: transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'),
        //     duration: 1000
        //   });
    }
    Map.prototype.changeZoomAndCenter = function (obj, zoom, features, isPoint, center) {
        if (features && features.length > 0) {
            let extent = getFitWithExtent(features, isPoint);
            obj.map.getView().fit(extent)
        } else {
            obj.map.getView().setCenter(transform(center, 'EPSG:4326', 'EPSG:3857'));
        }
        if (zoom) {
            obj.map.getView().setZoom(zoom)
        }
        // let point = obj.map.getView().getCenter()
        // point = transform(point, 'EPSG:3857', 'EPSG:4326')
        // let coordinate = [point[0] + 0.2, point[1]]
        // obj.map.getView().setCenter(transform(coordinate, 'EPSG:4326', 'EPSG:3857'));
        // if (point) {
        //     this.map.getView().setZoom(zoom)
        //     if (this.map.getView().getZoom() >= 58) {
        //         this.layers.vectorCluster.setVisible(false);
        //         this.layers.vectorImg.setVisible(true);
        //     }
        //     if (this.map.getView().getZoom() < 58) {
        //         this.layers.vectorCluster.setVisible(true);
        //         this.layers.vectorImg.setVisible(false);
        //     }
        // }
    }
    Map.prototype.changeZoomAndCenter_Data = function (obj, zoom, features, center, isPoint) {
        let extent;
        if (features && features.length > 0) {
            extent = getFitWithExtent(features, isPoint);
            center = [(extent[2] - extent[0]) / 2 + extent[0], (extent[3] - extent[1]) / 2 + extent[1]];
        } else {
            center = transform(center, 'EPSG:4326', 'EPSG:3857');
        }
        let view = this.map.getView();
        // flyTo(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'),view, function() {});//zoom加减达到飞往的效果
        if (extent) {
            view.animate({
                center: center,
                zoom: zoom ? zoom : 9,
                duration: 1000,
            }, () => {
                view.fit(extent, {
                    duration: 1500,
                })
            });
        } else {
            view.animate(
                {
                    center: center,
                    zoom: 9,
                    duration: 1000,
                },
                {
                    center: center,
                    zoom: zoom,
                    duration: 1500,
                }
            );

        }


        // let point = obj.map.getView().getCenter()
        // point = transform(point, 'EPSG:3857', 'EPSG:4326')
        // let coordinate = [point[0] + 0.2, point[1]]
        // obj.map.getView().setCenter(transform(coordinate, 'EPSG:4326', 'EPSG:3857'));
        // if (point) {
        //     this.map.getView().setZoom(zoom)
        //     if (this.map.getView().getZoom() >= 58) {
        //         this.layers.vectorCluster.setVisible(false);
        //         this.layers.vectorImg.setVisible(true);
        //     }
        //     if (this.map.getView().getZoom() < 58) {
        //         this.layers.vectorCluster.setVisible(true);
        //         this.layers.vectorImg.setVisible(false);
        //     }
        // }
    }
    function flyTo(location, zoom, view, done) {
        var duration = 2000;
        zoom = zoom ? zoom : view.getZoom();
        var parts = 2;
        var called = false;
        function callback(complete) {
            --parts;
            if (called) {
                return;
            }
            if (parts === 0 || !complete) {
                called = true;
                done(complete);
            }
        }
        view.animate({
            center: location,
            duration: duration
        }, callback);
        view.animate({
            zoom: zoom - 1,
            duration: duration / 2
        }, {
            zoom: zoom,
            duration: duration / 2
        }, callback);
    }

    //度°分′秒″转度
    function ToDigital(strDu, strFen, strMiao, len) {
        len = (len > 6 || typeof (len) == "undefined") ? 6 : len;//精确到小数点后最多六位   
        strDu = (typeof (strDu) == "undefined" || strDu == "") ? 0 : parseFloat(strDu);
        strFen = (typeof (strFen) == "undefined" || strFen == "") ? 0 : parseFloat(strFen) / 60;
        strMiao = (typeof (strMiao) == "undefined" || strMiao == "") ? 0 : parseFloat(strMiao) / 3600;
        var digital = strDu + strFen + strMiao;
        if (digital == 0) {
            return "";
        } else {
            return digital.toFixed(len);
        }

    }
    //度转度°分′秒″
    function ToDegrees(val, type) {
        if (typeof (val) == "undefined" || val == "") {
            return "";
        }
        val = String(val);
        var i = val.indexOf('.');
        var strDu = i < 0 ? val : val.substring(0, i);//获取度
        var strFen = 0;
        var strMiao = 0;
        if (i > 0) {
            var strFen = "0" + val.substring(i);
            strFen = strFen * 60 + "";
            i = strFen.indexOf('.');
            if (i > 0) {
                strMiao = "0" + strFen.substring(i);
                strFen = strFen.substring(0, i);//获取分
                strMiao = strMiao * 60 + "";
                i = strMiao.indexOf('.');
                strMiao = strMiao.substring(0, i + 4);//取到小数点后面三位
                strMiao = parseFloat(strMiao).toFixed(2);//精确小数点后面两位
            }
        }
        let result = '';
        switch (type) {
            case 'lon':
                result = strDu + "°" + strFen + "′" + strMiao + '″E';
                break;
            case 'lat':
                result = strDu + "°" + strFen + "′" + strMiao + '″N';
                break;

            default:
                break;
        }
        return result;
    }
    function getFitWithExtent(features, isPoint) {
        //.log(features, '-----------------getFitWithExtent----extent first')
        let point_max_x = 0;//extent
        let point_max_y = 0;//extent
        let point_min_x = 0;//extent
        let point_min_y = 0;//extent
        if (isPoint) {
            features.map((item, index) => {
                let lonlat = [item.lon, item.lat]
                if (index == 0) {
                    point_min_x = lonlat[0]
                    point_min_y = lonlat[1]
                }
                if (index == 1) {
                    point_max_x = lonlat[0]
                    point_max_y = lonlat[1]
                }
                point_min_x = point_min_x <= lonlat[0] ? point_min_x : lonlat[0]
                point_min_y = point_min_y <= lonlat[1] ? point_min_y : lonlat[1]
                point_max_x = point_max_x >= lonlat[0] ? point_max_x : lonlat[0]
                point_max_y = point_max_y >= lonlat[1] ? point_max_y : lonlat[1]
            })
        }
        else {
            features.map((item, index) => {
                let extent = item.getGeometry().getExtent();
                // //.log(extent, '---------------------extent first')
                if (index == 0) {
                    point_min_x = extent[0]
                    point_min_y = extent[1]
                    point_max_x = extent[2]
                    point_max_y = extent[3]
                }
                if (extent[0] == 0 || extent[1] == 0 || extent[2] == 0 || extent[3] == 0) {
                } else {
                    point_min_x = point_min_x <= extent[0] ? point_min_x : extent[0]
                    point_min_y = point_min_y <= extent[1] ? point_min_y : extent[1]
                    point_max_x = point_max_x >= extent[2] ? point_max_x : extent[2]
                    point_max_y = point_max_y >= extent[3] ? point_max_y : extent[3]
                }

            })
        }

        //根据业务要求添加偏移量
        let lonlatMin = [
            point_min_x,
            point_min_y
        ]
        let lonlatMax = [
            point_max_x,
            point_max_y
        ]
        if (isPoint) {
        } else {
            lonlatMin = transform(lonlatMin, 'EPSG:3857', 'EPSG:4326')
            lonlatMax = transform(lonlatMax, 'EPSG:3857', 'EPSG:4326')
        }

        lonlatMin = [
            (lonlatMin[0] - 0.0000),
            (lonlatMin[1] - 0.0000),
        ]
        lonlatMin = transform(lonlatMin, 'EPSG:4326', 'EPSG:3857')
        lonlatMax = [
            (lonlatMax[0] + 0.0000),
            (lonlatMax[1] + 0.0000),
        ]
        lonlatMax = transform(lonlatMax, 'EPSG:4326', 'EPSG:3857')
        let extent = [
            ...lonlatMin,
            ...lonlatMax
        ]


        // let extent = [
        //     point_min_x,
        //     point_min_y,
        //     point_max_x,
        //     point_max_y
        // ]
        //.log(extent, '----------------------------extent end')
        return extent
    }



    return Map;
})(window);