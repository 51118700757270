import React from 'react'
import { Form, Modal, Button } from 'antd'
import './index.css'

class ModalFormDevice extends React.Component {
  state = {
    loading: false,
  }
  onOk = () => {
    const { submit } = this.props
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log(values,'values')
      if (!err) {
        submit(values, {
          onSuccess: () => {
            this.props.form.resetFields()
            this.setState({
              loading: false,
            })
          },
        })
      }
    })
  }
  onCancel = () => {
    //关闭弹窗不清空里面的内容
    const { closeModal } = this.props
    this.setState({
      loading: false,
    })
    closeModal()
  }
  handleCancel = () => {
    //关闭弹窗并清空表单
    this.setState({
      loading: false,
    })
    this.onCancel()
    this.props.form.resetFields()
    if(this.props.closeSwitch){
      this.props.closeSwitch()
    }
  }
  hasErrors = () => {
    //表单验证不通过则返回true
    const { getFieldsError } = this.props.form
    const fieldsError = getFieldsError()
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }
  render() {
    const { loading } = this.state
    const { visible, formItemList = [], className, title, width=500 } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form
    return (
      <Modal
        visible={visible}
        onCancel={this.handleCancel}
        style={{ marginBottom: '100px', textAlign: 'center' }}
        className={`modal_form1 ${className}`}
        footer={null}
        width={width}
        title={title}
      >
        {this.props.clickHidden&&<div className="hiddenDiv" onClick={()=>this.props.clickHidden()}></div>}
        <Form style={{ display: 'flex', flexWrap: 'wrap',overflow:'auto',overflowX:'hidden'}} >
          {formItemList.map(item => {
            const { label, id, options, component, premise, divider, specialItem ,hidden} = item
            // premise属性是根据他id属性的value值控制该FormItem是否显示
            if (premise && !(getFieldValue(premise.id) === premise.value)) {
              return null
            }
            return (
              <div key={id}>
                  {/* 如果divider属性为true则显示分隔 */}
                {divider && component}
                {
                    !divider &&!hidden&& (
                        <Form.Item className={specialItem} key={id} label={label}>
                            {getFieldDecorator(id, options)(component)}
                        </Form.Item>
                    )
                }
              </div>
            )
          })}
        </Form>
        <div className="footer">
            <Button onClick={this.handleCancel}>
              取消
            </Button>
            <Button
              type="primary"
              onClick={this.onOk}
              disabled={loading || this.hasErrors()}
              style={{marginLeft: '20px'}}
            >
              保存
            </Button>
            
        </div>
      </Modal>
    )
  }
}

export default Form.create()(ModalFormDevice)
