// 航次统计报表
import React, { Component } from 'react'
import { DatePicker, Input, TreeSelect, Tree, Select, Icon, Slider, Switch, Button, Timeline, Table, Modal, Pagination, message } from 'antd';
import moment from 'moment';
import TrackQuery from '../TrackQuery/TrackQuery.js';
import VoyageTrajectory from './VoyageTrajectory/VoyageTrajectory.js'
import './statistics.css'
import config_JSON from '../TrackQuery/default_JSON/Default_map.json'
import Datayears from './Datayears/Datayears'
import FuzzySearch from '../../componts/FuzzySearch/FuzzySearch'
import './statistics.css'
import { exportExcel } from 'xlsx-oc';
import { selectRangeStatistics, selectShip, getDict, selectShipPage, selectRangeQuery, exportRangeStatistics, specialRegionStatistics, homePortMonitor, async_areaList } from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const date = new Date();
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
const dayList1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
function debounce(fn, ms = 500) {
  let timeoutId
  return function () {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      fn.apply(this, arguments)
    }, ms)
  }
}
class Voyage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startTime: dayList1,//默认开始时间是金泰你零点，
      endTime: dayList,//默认开始时间是今天23点
      pageIndex: 0,
      ulshow: false,
      pageTotal: null,
      pageSize: 100,
      name: '',
      // loading:true,
      dataSource: [],
      pointData: [], portData: [], limitAreaData: [],
      excelData: [],
      pageTotal: null,
      pagination11: {
        total: 0,
        current: 1,
        pageSize: 3000,
        showQuickJumper: true

      },
      pagination: {
        defaultPageSize: 10,
        defaultCurrent: 1,
        current: 1,
        showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
        // showSizeChanger: true,
        showQuickJumper: true,
      },
      searchValue: '',
    }
    this.selectRangeStatistics = this.selectRangeStatistics.bind(this)
  }
  componentDidMount() {
    this.selectRangeStatistics();
    this.specialRegionStatistics();
    this.homePortMonitor();
    this.async_areaList({ 'areaType': 300002 })
    let dictData = JSON.parse(sessionStorage.getItem("dictData"))
    let shipTypeArr = []
    dictData.forEach((item) => {
      if (item.category === "ship_type") {
        shipTypeArr.push({
          text: item.typeName,
          value: item.typeCode
        })
      }
    })
    this.setState({
      shipTypeArr
    })
  }
  // 重点区域管理
  async specialRegionStatistics() {
    let params = {
      startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
      endTime: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    let data = await specialRegionStatistics(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });
    if (data && data.code == 200) {

      this.setState({
        pointData: data.data.children
      })
    } else if (data && data.code == 505) {
      message.error(data.msg)
      if (this.props.history) {
        this.props.history.push('/login')
      }
    }
  }
  // 停靠点管理
  async homePortMonitor() {
    let params = {
      startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
      endTime: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    let data = await homePortMonitor(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });
    if (data && data.code == 200) {

      this.setState({
        portData: data.data.children
      })
    } else if (data && data.code == 505) {
      message.error(data.msg)
      if (this.props.history) {
        this.props.history.push('/login')
      }
    }
  }
  // 区域查询 （根据区域类型获取相应的区域 Ps：比如限定区300002）
  async async_areaList(value) {
    let params = value;
    params = {
      ...params,
      startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
      endTime: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    let data = await async_areaList(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });
    if (data && data.code == 200) {

      this.setState({
        limitAreaData: data.data
      })
    } else if (data && data.code == 505) {
      message.error(data.msg)
      if (this.props.history) {
        this.props.history.push('/login')
      }
    }
  }

  // 导出接口
  async exportRangeStatistics() {
    let { startTime, endTime } = this.state
    let params = {
      startTime,
      endTime
    }
    let data = await exportRangeStatistics(params)
      .then(res => {
        if (res.ok) {
          res.blob().then((blob) => {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            //        var filename = "航次统计报表";
            a.href = url;
            //        a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
        } else {
          return Promise.reject("导出失败");
        }
      })
      .catch(ex => {
        let exMsg = ex instanceof Object ? ex.toString() : ex;
        message.error(exMsg, 5);
      });

  }
  //导出
  exportDefaultExcel = (e, coloums) => {
    let dockInfoData = this.state.dataSource;
    if(dockInfoData){
      dockInfoData.map((item)=>{
        item.driveDistance = (item.driveDistance / 1000).toFixed(1)
      })
    }
    delete coloums[5]
    let headers = [];
   
    coloums.map((item, index) => {
      let kv = {
        k: item.dataIndex ? item.dataIndex : item.key,
        v: item.title
      }
      headers = headers.concat(kv);
    })
    if (dockInfoData && dockInfoData.length > 0) {
      exportExcel(headers, dockInfoData, '航程统计报表.xlsx');
    }
    else {
      message.error('导出数据失败！！！');
    }
  }
  haveTime = (e, value) => {
    let startTime = value[0] + " 00:00:00";
    let endTime = value[1];
    endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
    this.setState({
      startTime,
      endTime,
      value_DatePicker: [startTime, endTime],
    })
  }
  showDetail = (record) => {
    this.setState({
      detailShow: true,
      detailData: record
    }, () => {
      this.selectRangeQuery();
    })
  }
  trajectoryDetail = (record) => {
    this.setState({
      trajectoryShow: true,
      detailData: record
    }, () => {
      this.selectRangeQuery();
    })
  }
  //详情
  async selectRangeQuery() {
    let { detailData, startTime, endTime } = this.state
    let params = {
      startTime,
      endTime,
      objectId: detailData.objectId
    };
    let data = await selectRangeQuery(params)
      .then(res => { return res.json() }).catch(ex => { });
    if (data.code === 200) {

      this.setState({
        shipVoyageInfoData: data.data,
      });
    } else if (data && data.code == 505) {
      message.error(data.msg)
      if (this.props.history) {
        this.props.history.push('/login')
      }
    }
  }
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  //输入框为空时搜索列表不显示
  controlList = (eve) => {
    console.log(eve, 'vvvvvv')
    if (!eve.target.value) {
      this.setState({
        ulshow: false,
        name: ''
      }, () => {
        this.selectRangeStatistics()
      })
    } else {
      this.setState({
        name: eve.target.value,
        shipName: name,
      }, () => {
        this.selectRangeStatistics(this.state.name, 'select')
      }
      )
    }
  }
  //点击搜索列表的事件
  clickSearchDatavalue = (names) => {
    this.selectRangeStatistics(names)
    this.setState({
      name: names
    })

  }
  //航程统计报表
  async selectRangeStatistics(value, type) {
    this.setState({loading:true})
    let { startTime, endTime, searchValue, loading, pagination,pagination11, name } = this.state;
    let params = {
      shipName: value ? value : name,
      pageIndex: pagination11.current,
      pageSize: pagination11.pageSize,
      pageTotal: this.state.total1,
      startTime,
      endTime
    }

    let data = await selectRangeStatistics(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data.code == 200) {

      if (type === 'select') {

        this.setState({
          loading: false,
          ullAll: data.data.items,
          ulshow: true,
        }
        )
      } else {
        pagination.current=1
        // pagination.total = data.data.pageTotal
        this.setState({
          ulshow: false,
          loading: false,
          dataSource: data.data.items,
          pageTotal: data.pageTotal,
          pagination,
        })
      }
    } else {
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      // pagination: { ...pagination }
      pagination:pagination
    }, () => {
      // this.selectRangeStatistics()
    })
    console.log(pagination, filters, sorter, 'sorter')
  }
  getsearchValue = (value) => {
    this.setState({
      searchValue: value
    }, () => {
      this.selectRangeStatistics()
    })
  }
  exportDefault = () => {
    this.setState({
    }, () => {
      this.exportRangeStatistics()
    })
  }

  render() {

    const { shipVoyageInfoData, shipTypeArr, dataSource, ullAll, trajectoryShow, pointData, portData, limitAreaData, ulshow, startTime, endTime, detailData,pagination } = this.state;
    let milli = ((detailData ? detailData.driveDistance : '') / 1000).toFixed(2)
    let mills = (milli / 1000).toFixed(2)
    let detailDatade = dataSource.toFixed
    console.log(detailDatade, 'detailDatade')
    let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.shipName))
    let ullAllss = [...new Set(ullAlls)];
    const columns = [
      {
        title: '船名',
        dataIndex: 'shipName',
        key: 'shipName'
        // defaultSortOrder: 'descend',
        // sorter: (a,b) =>a.age - b.age,
      },
      {
        title: '船舶类型',
        dataIndex: 'shipType',
        render: (text) => {
          let newText;
          if (shipTypeArr) {
            shipTypeArr.forEach((item) => {
              if (item.value == text) {
                newText = item.text
              }
            })
          }
          return newText
        },

      },
      {
        title: '行驶里程(公里)',
        dataIndex: 'driveDistance',
        key: 'driveDistance',
        render: (driveDistance) => {
          return (
            driveDistance = (driveDistance / 1000).toFixed(1)
          )
        },
        sorter: (a, b) => a.driveDistance - b.driveDistance,
      },
      {
        title: '进出港次数',
        dataIndex: 'leaveAndArrivalCount',
        key: 'leaveAndArrivalCount',
        sorter: (a, b) => a.leaveAndArrivalCount - b.leaveAndArrivalCount,
      },
      {
        title: '违规次数',
        dataIndex: 'violationCount',
        key: 'violationCount',
        sorter: (a, b) => a.violationCount - b.violationCount,
      },
      {
        title: '操作',
        dataIndex: 'action',
        dataIndex: 'action',
        key:'action',
        render: (text, record) => {
          return <div>
            <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.trajectoryDetail(record)}>轨迹</span>
            <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
            <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.showDetail(record)}>详情</span>
          </div>
        }
      },
    ];
    let defaultDataToChild = {
      pointData, portData, limitAreaData
    }
    console.log(defaultDataToChild, '456')

    return (
      <div className='voyage_mnue'>
        <div className='voyage_mnue' style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            <span style={{ width: 75, marginTop: 5, textAlign: 'center' }} >船名：</span>
            <Input
              value={this.state.name}
              onSearch={value => this.selectRangeStatistics(value, 'select')}
              onChange={value => this.controlList(value)}
              onPressEnter={() => this.selectRangeStatistics()}
              placeholder="请输入" />
          </div>
          {
            ulshow && <ul className="searchList">
              {
                ullAllss && ullAllss.map((item, index) => (
                  <li
                    key={item.index}
                    onClick={this.clickSearchDatavalue.bind(this, item)}
                    style={{
                      cursor: 'pointer',
                      fontSize: 16,
                      display: 'flex',
                      alignItems: "center",
                    }}
                  ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item
                  }</span>
                  </li>
                ))
              }
            </ul>
          }
          <text style={{ marginTop: 5, marginLeft: 30, marginRight: 15 }} >选择日期:</text>
          <RangePicker
            defaultValue={[moment(startTime), moment(endTime)]}
            ranges={{
              今日: [moment(), moment()],
              '本周': [moment().startOf('week'), moment().endOf('week')],
              '本月': [moment().startOf('month'), moment().endOf('month')],
              '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
              '今年': [moment().startOf('year'), moment().endOf('year')],
            }}
            allowClear={false}
            value={[moment(startTime), moment(endTime)]}
            onChange={this.haveTime}
          />
          <Button style={{ marginLeft: 40 }} type="primary" onClick={() => this.selectRangeStatistics()}>查询</Button>
          <Button style={{ marginLeft: 20 }} onClick={() => {
            this.setState({
              searchValue: '',
              name: '',
              startTime: dayList1,
              endTime: dayList
            }
              , () => {
                this.selectRangeStatistics()
              })
          }}>重置</Button>
           <Button style={{ marginLeft: 650 }} onClick={(e) => this.exportDefaultExcel(e, columns)}>导出</Button>
        </div>
        <Table style={{ marginTop: 25 }}
          className='Voyage_icon'
          columns={columns}
          loading={this.state.loading}
          dataSource={this.state.dataSource}
          pagination={pagination}
          locale={{
            filterConfirm: '确定',
            filterReset: '重置',
            emptyText: '暂无数据',
          }}
          // showHeader={ false }
          scroll={{
            y: '60vh',
            // x: '100%',
            scrollToFirstRowOnChange: true,
          }}
          onChange={this.handleTableChange}
        />
        <Modal className='modal'
          visible={this.state.detailShow}
          footer={null}
          onCancel={() => { this.setState({ detailShow: false }) }}
        >
          <div className="shipDynamic" style={{}}>
            <div className="tongji" >
              <span style={{ fontSize: 18, fontWeight: 'bold' }} >
                {detailData ? detailData.shipName : 0}
              </span>
              <span style={{ fontSize: 20 }}  ></span>
              <span style={{ marginLeft: 30 }}>{shipVoyageInfoData ? shipVoyageInfoData.startTime + '~' + shipVoyageInfoData.endTime : '0~0'} </span><br /><br />
              <hr></hr>
              <h4 style={{ position: 'relative' }}>航程统计</h4>
              <ul style={{ textAlign: 'center' }} >
                <li >
                  <div className='portmilli' >
                    <p><img style={{ width: 80 }} /></p>
                    <p style={{ margin: 0, marginTop: 50, fontSize: 15 }}>{((detailData ? detailData.driveDistance : '') / 1000).toFixed(1)}</p>
                    <p style={{ margin: 0, fontSize: 14 }}>航程/公里</p>
                  </div>
                </li>
                <li style={{ marginLeft: 40 }}>
                  <p >进出港/次数</p>
                  <p className="bigP" style={{ fontSize: 24 }}>{detailData ? detailData.leaveAndArrivalCount : 0} </p>
                  <text><img src={require("../../assets/img/enter.png")} />{shipVoyageInfoData ? shipVoyageInfoData.arrivalCount : 0} <img src={require("../../assets/img/leave.png")} />&nbsp;{shipVoyageInfoData ? shipVoyageInfoData.leaveCount : 0}</text>
                </li>
                <li style={{ marginLeft: 40 }}>
                  <p>违规/次数</p>
                  <p className="bigP" style={{ fontSize: 24 }}>{detailData ? detailData.violationCount : 0}</p>
                </li>
              </ul>
              <br />
            </div>
            <div className="dongtai" style={{ overflowY: 'auto', height: 400 }}>
              <h4 style={{ position: 'fixed' }}>航程动态</h4>
              <br></br>
              <Timeline>
                {shipVoyageInfoData && shipVoyageInfoData.dynamicStateList && shipVoyageInfoData.dynamicStateList.length > 0 ? shipVoyageInfoData.dynamicStateList.map(item => (
                  <Timeline.Item color="red">
                    <p>{item.eventTypeName}</p>
                    <p>{item.eventRemark}</p>
                    <p>{item.gmtCreate}</p>
                  </Timeline.Item>
                )) : ''
                }
              </Timeline>
            </div>
          </div>
        </Modal>
        {/* <Modal
          visible={this.state.trajectoryShow}
          footer={null}
          title='轨迹'
          bodyStyle={{ height: '50vh', padding: 0 }}
          destroyOnClose={true}
          width="50vw"
          onCancel={() => { this.setState({ trajectoryShow: false }) }}
        >
          <VoyageTrajectory
          
            trajectorydata={this.state.detailData1}
          />
        </Modal> */}
        <Modal
          onCancel={() => { this.setState({ trajectoryShow: false }) }}
          visible={trajectoryShow}
          footer={null}
          title="轨迹查询"
          bodyStyle={{ height: '80vh', padding: 0 }}
          destroyOnClose={true}
          width="90vw">
          <TrackQuery
            treeData={[]}
            default_shipData={this.state.detailData}
            config_JSON={config_JSON}
            parentObj={this}
            defaultDataToChild={defaultDataToChild}
          ></TrackQuery>
        </Modal>
      </div>

    )
  }
}
export default Voyage
