import "whatwg-fetch";

/*
*   适配低版本浏览器
*/
if (!window.requestAnimationFrame) {
    window.requestAnimFrame = (function () {
        return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
    })();
}
if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = (function () {
        return window.cancelAnimationFrame ||
            window.webkitCancelAnimationFrame ||
            window.mozCancelAnimationFrame ||
            function (id) {
                window.clearTimeout(id);
            };
    })();
}

/*
*   请求数据
*/
function postData(url, data) {
    url = true ? ("/api" + url) : url;

    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        // 注意 post 时候参数的形式
        body: data ? appendParam(data) : null
    }).then((res) => {
        let cloneRes = res.clone();
        try {
            let d = cloneRes.json();
            d.then((data) => {
                // console.log(data);
            });
        } catch (ex) {

        }
        return res;
    })
}
/*
*   请求数据
*/
function postLSData(url, data) {
    url = true ? ("/lsapi" + url) : url;

    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        // 注意 post 时候参数的形式
        body: data ? appendParam(data) : null
    }).then((res) => {
        let cloneRes = res.clone();
        try {
            let d = cloneRes.json();
            d.then((data) => {
                // console.log(data);
            });
        } catch (ex) {

        }
        return res;
    })
}
// *
// * 请求formdate数据
//     * /
function postLsFormData(url, data) {
    url = true ? ("/lsapi" + url) : url;
    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Accept': 'application/json, text/plain, */*',
            // 'Content-Type': 'application/json'
        },
        // 注意 post 时候参数的形式
        body: data
    });
}
// *
// * 请求formdate数据
//     * /
function postFormData(url, data) {
    url = true ? ("/api" + url) : url;
    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Accept': 'application/json, text/plain, */*',
            // 'Content-Type': 'application/json'
        },
        // 注意 post 时候参数的形式
        body: data
    });
}

/**
 *@Description:{ Request data from the background by post }
 *@InterfaceAddress:{ }
 *@parameter: { 
                   url
                   request data
               }
 *@ClassAuthor: ZDong
 *@Date: 2019-11-16 17:36:25
*/
function postDataNew(url, data) {
    url = true ? ("/bigdataapi" + url) : url;

    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        // 注意 post 时候参数的形式
        body: data ? appendParam(data) : null
    }).then((res) => {
        let cloneRes = res.clone();
        try {
            // let d = cloneRes.json(); 
            // d.then((data) => {
            //     // console.log(data);
            // });
        } catch (ex) {

        }
        return res;
    })
}
/*
*   请求数据
*/
function postJSONData(url, data) {
    url = true ? ("/api" + url) : url;
    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        // 注意 post 时候参数的形式
        body: data ? JSON.stringify(data) : null
    });
}
/*
*   请求数据
*/
function postLsJSONData(url, data) {
    url = true ? ("/lsapi" + url) : url;
    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        // 注意 post 时候参数的形式
        body: data ? JSON.stringify(data) : null
    });
}

/**
 *@Description:{ parameter splice }
 *@InterfaceAddress:{ }
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2019-11-16 17:42:19
*/
function appendParam(data) {
    if (data) {
        var str = "";
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                str += key + "=" + data[key] + "&"
            }
        }
        return str;
    }
}

/**
 *@Description:{ 将度转换成为度分秒 }
 *@InterfaceAddress:{ }
 *@parameter: { 
                  value 
               }
 *@ClassAuthor: ZDong
 *@Date: 2019-11-16 17:38:08
*/
function formatDegree(value) {
    value = Math.abs(value);
    var v1 = Math.floor(value);//度  
    var v2 = Math.floor((value - v1) * 60);//分  
    v2 = v2 < 10 ? (v2 = "0" + v2) : v2;
    var v3 = Math.round((value - v1) * 3600 % 60);//秒  
    v3 = v3 < 10 ? (v3 = "0" + v3) : v3;
    return v1 + '°' + v2 + '\'' + v3 + '"';
}

function getData(url, params) {
    let paramsArray = [];
    //拼接参数
    Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
    if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
    } else {
        url += '&' + paramsArray.join('&')
    }

    return fetch(url, {
        method: 'GET',
        // credentials: 'include',
        mode: 'cors',
        cache: 'default',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        // // 注意 post 时候参数的形式
        // body: data ? appendParam(data) : null
    }).then((res) => {
        let cloneRes = res.clone();
        try {
            // let d = cloneRes.json(); 
            // d.then((data) => {
            //     // console.log(data);
            // });
        } catch (ex) {

        }
        return res;
    })
}

export { postDataNew, formatDegree, getData, postData, postLSData, postFormData, postLsFormData, postLsJSONData }