// 设备类型
import React, { Component } from 'react'
import './css/system.css'
import SearchTop from './components/seachTop/SeachTop'
import AddFormItem from './components/addFormItem/AddFormItem'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
// 引入jQuery
import $ from 'jquery'
import {
    selectDeviceTypeList,
    addDeviceType,
    updateDeviceType,
    delDeviceType,
} from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class DeviceType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            menuName:'',
            taskNum:'',
            Label: false,
            ulshow: false,
            modifyShow: false,
            deviceTypeName: '',
            shipOrDeviceId: '',
            dataSource: [],
            userGroupData: [],
            pageTotal: '',
            pageNo: 1,
            pageSize: 10,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
        }
        this.delDeviceType = this.delDeviceType.bind(this)
        this.selectDeviceTypeList = this.selectDeviceTypeList.bind(this)
        this.addDeviceType = this.addDeviceType.bind(this)
        this.updateDeviceType = this.updateDeviceType.bind(this)
        // this.bindShipDevice = this.bindShipDevice.bind(this)
    }
    componentDidMount() {
        //查询设备类型-列表
        this.selectDeviceTypeList()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });

    }


    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectDeviceTypeList(names)
        this.setState({
            menuName: names
        })
    }

    // 分页查询
    async selectDeviceTypeList(value, type) {
        let { pagination } = this.state;
        let params = {
            deviceTypeName: this.state.deviceTypeName,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await selectDeviceTypeList(params)

            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.rows)
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys
                    }

                )
            } else {
                this.setState({
                        //给dataSource赋值
                        dataSource: newData,
                        userGroupData: newData,
                        UallArr: newData,
                        ulshow: false,
                        selectedKeys,
                        pageTotal: data.total
                    }

                )
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }

    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectDeviceTypeList()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }


    modify = (data) => {
        // console.log('gogogo-----'+JSON.stringify(data))
        this.setState({
            modifyShow: true,
            modifyData: data,
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 新增设备类型
    async addDeviceType(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await addDeviceType(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.selectDeviceTypeList()
            message.success("新增成功")
            callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败："+data.message)
        }
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }
    // 删除菜单
    async delDeviceType() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.id,
        }
        let data = await delDeviceType(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectDeviceTypeList()
        } else if (data.code == 500) {
            message.error("删除失败："+data.message)
        }
        this.setState({
            deleteShow: false,
        })
    }

        //菜单修改
    async updateDeviceType(obj) {
        let { modifyData } = this.state;
        obj.id = modifyData.id
        // console.log('modify----'+JSON.stringify(obj))
        let data = await updateDeviceType(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("修改成功")
            this.selectDeviceTypeList()
        } else if (data.code == 500) {
            message.error("修改失败："+data.message)
        }
        this.setState({
            modifyShow: false,
        })
    }

    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            Label: false,
            modifyShow: false,
        })
    }
    // exportDefault = () => {
    //     this.setState({
    //     }, () => {
    //         this.exportShipTemplate()
    //     })
    // }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectDeviceTypeList()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectDeviceTypeList()
        });
    }
    //回车搜索
    onKeyup = (e) => {
        // this.selectDeviceTypeList()
    }
    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }
    render() {
        let { dataSource, modifyData,nowModalData,userGroupData,selectedKeys,deleteAll, UallArr, LabelSize, ulshow, labelData, shipTypeArr,selectMenuDataObj = {} ,pageTotal} = this.state;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '设备型号',
                key: 'deviceType',
                dataIndex: 'deviceType',
                ellipsis: true,
            },
            {
                title: '设备协议',
                key: 'protocol',
                dataIndex: 'protocol',
            },
            {
                title:'厂家',
                key:'factory',
                dataIndex:'factory',
            },
            {
                title: '网络类型',
                key: 'network',
                dataIndex: 'network',
            },
            {
                title: '版本号',
                key: 'version',
                dataIndex: 'version',
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },

            {
                title: '操作',
                key: 'detele',
                width: 300,
                render: (text, record) => {
                    return <div>

                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modify(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>
                },
            },
        ]
        //添加对话框
        const addFormItemList = [
            {
                label: '设备型号', //表单label
                id: 'deviceType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '设备型号不能为空!' }] }
            },
            {
                label: '设备协议', //表单label
                id: 'protocol', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '厂家', //表单label
                id: 'factory', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '网络类型', //表单label
                id: 'network', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '版本号', //表单label
                id: 'version', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },

            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
         ]
        const modifyList = [
            {
                label: '设备型号', //表单label
                id: 'deviceType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.deviceType }
            },
            {
                label: '设备协议', //表单label
                id: 'protocol', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.protocol }
            },
            {
                label: '厂家', //表单label
                id: 'factory', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.factory }

            },
            {
                label: '网络类型', //表单label
                id: 'network', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.network }
            },
            {
                label: '版本号', //表单label
                id: 'version', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.version }
            },

            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.remark }
            },
        ]
        return (
            <div className='menuConfig contentBox'>
                <div className="othersManagement_title menuTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">设备类型</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                设备型号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='deviceTypeName' value={this.state.deviceTypeName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectDeviceTypeList()}
                            >查询</Button>


                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={() =>{
                                    this.setState({
                                        deviceTypeName: '',

                                    },() =>{

                                    })

                                }}

                            >重置</Button>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4" style={{marginTop:0,marginLeft:50}}>
                            <SearchTop
                                adding="true"
                                onclick={this.addClickShow}
                            />

                        </div>


                    </div>

                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }

                <div className="cardIIManagement">
                    {/*
                    //
                    //
                    //列表显示数据
                    //
                    //
                    //

                    */}
                    <Table
                        columns={columns}
                        // rowSelection={distribute_rowSelect}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:515}}
                        pagination={{
                            ...this.state.pagination,
                            // position: ['bottomCenter'],
                            // showQuickJumper: true,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectDeviceTypeList(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={addFormItemList}
                    closeModal={this.closeModal}
                    submit={this.addDeviceType}
                    title="新增"
                />
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    submit={this.updateDeviceType}
                    title="修改"
                />

                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.delDeviceType}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.deviceType}"菜单将要被删除，删后不可恢复，确认删除吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.Label}
                    onCancel={this.closeModal}
                    title="标签管理"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={600}
                >
                    <AddFormItem data={this.state.labelData} onOk={this.bindShipDevice} onClose={this.closeModal} />
                </Modal>
            </div>
        )
    }
}
export default DeviceType