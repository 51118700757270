
import React, { Component } from 'react'
import { Radio, Spin, Button, Menu, Input, Select, Row, Col, Tree, Icon, Divider, message, Dropdown, Modal, List, Tabs, Table } from "antd";
import DetailVideo from './DetailVideo.js'
import './RealTimeMonitorning.css'
class RealTimeDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectVideoData: false,//选中的摄像头设备信息数据
        }
    }
    // 点击行
    click_pointDetailDatasList(e, data) {
        console.log(data, '-----------------click_pointDetailDatasList--------')
        let onClick_showCarmeraInfoData = this.props.onClick_showCarmeraInfoData;
        let selectVideoData = onClick_showCarmeraInfoData[data.id];
        console.log(selectVideoData, '-----------------click_pointDetailDatasList------selectVideoData--')
        this.setState({
            selectVideoData: selectVideoData,
        }, () => {
            this.props.setTitle_showCarmeraInfoData(data.name);
        })
    }

    render() {
        let onClick_showCarmeraInfoData = this.props.onClick_showCarmeraInfoData;
        let onClick_showCarmeraInfoData_List = [];
        if (onClick_showCarmeraInfoData) {
            Object.keys(onClick_showCarmeraInfoData).map(key => {
                let item = onClick_showCarmeraInfoData[key];
                onClick_showCarmeraInfoData_List = onClick_showCarmeraInfoData_List.concat(item);
            })
        }
        if (!this.state.selectVideoData && onClick_showCarmeraInfoData_List.length > 0) {
            this.setState({
                selectVideoData: onClick_showCarmeraInfoData_List[0],
            })
        }
        console.log(onClick_showCarmeraInfoData, onClick_showCarmeraInfoData_List, '--------onClick_showCarmeraInfoData_List---onClick_showCarmeraInfoData------------')
        let cameraRunningImg = require('../../img/icon_shexiangtou_zc.png');
        let cameraUnRunningImg = require('../../img/icon_shexiangtou_yc.png');
        const columns_pointDetailDatasList = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                render: (text, record) => {
                    console.log(text,record,'------------columns_pointDetailDatasList-----------------')
                    let color = '#27C5FF';
                    if (record.id === this.state.rowId) {
                        color = '#27C5FF';
                    }
                    if (record.networkFlag == 0) {
                        color = 'red';
                    }
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14,
                                cursor: 'pointer'
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        >
                            < img src={record.networkState == 0 ? cameraUnRunningImg : cameraRunningImg}
                                style={{

                                }}>
                            </img>
                            &nbsp;
                            { text}
                        </span>
                    )
                }
            },
        ]

        let selectVideoData = this.state.selectVideoData;
        return (
            <div className='RealTimeDetail'>
                <Row>
                   {/* <Col span={5}>
                         <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                backgroundColor: 'rgba(27,39,80,0.8)',
                                color: '#27C5FF',
                                border: 'unset'
                            }}
                        >
                            <Table
                                columns={columns_pointDetailDatasList}
                                dataSource={onClick_showCarmeraInfoData_List}
                                childrenColumnName={'noChildren'}
                                // onChange={ onChange }
                                showHeader={false}
                                tableLayout={'fixed'}
                                bordered={false}
                                ellipsis={true}//内容超过宽度自动省略
                                pagination={false}//禁用分页
                                // loading={ this.state.VideoSystemIndex_videoAlarmListDivTable_isLoading }
                                scroll={{
                                    // y: heightBody * 0.4,
                                    y: 500,
                                    // y: 'max-content',
                                    x: 'max-content',
                                    scrollToFirstRowOnChange: true
                                }}
                                rowClassName={this.setRowClassName}
                                onRow={record => {
                                    return {
                                        onClick: (e) => this.click_pointDetailDatasList(e, record), // 点击行
                                        onDoubleClick: event => { },
                                        onContextMenu: event => { },
                                        onMouseEnter: event => { }, // 鼠标移入行
                                        onMouseLeave: event => { },
                                    };
                                }}
                            />
                        </div> 
                    </Col>*/}
                    <Col span={24}>
                        <div >
                            <div className="Base_zf-top">
                                <div><span className='smallIcon'></span>基础信息 </div>
                                <Row>
                                    <Col span={8}>设备编号：{selectVideoData ? selectVideoData.model : 0}</Col>
                                    <Col span={8}>所属区域：{selectVideoData && selectVideoData.location ? selectVideoData.location : '雷波'}</Col>
                                    <Col span={8}>接入时间：{selectVideoData ? selectVideoData.gmtCreate : '2020-2-14'}</Col>
                                    <Col span={8}>地址：{selectVideoData && selectVideoData.location ? selectVideoData.location : '跃进圩区/中横泾/香花桥'}</Col>
                                    <Col span={8}>经纬度：{selectVideoData ? selectVideoData.lon + "," + selectVideoData.lat : '38.26,120.11'}</Col>
                                    <Col span={8}>设备状态：< img src={selectVideoData ? (selectVideoData.networkFlag && selectVideoData.networkFlag == 1) ? cameraRunningImg : cameraUnRunningImg : cameraUnRunningImg}></img></Col>
                                </Row>
                            </div>
                            <div clssName='RealTimeDetail_right'>
                                {/* {
                                    this.state.selectVideoData ? */}
                                        <DetailVideo
                                            videoToken={this.state.selectVideoData}
                                        ></DetailVideo>
                                        {/* :
                                        ''
                                } */}
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }
}
export default RealTimeDetail