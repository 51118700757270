//短信发送历史
import React, { Component } from 'react'
import { Table, Radio, Modal,Divider ,Input,Menu,Icon,Button,Select,DatePicker,Pagination} from "antd";
import moment from 'moment';
import {msgStatistics,selectShipPage} from '../../data/dataStore'
import FuzzySearch from '../../componts/FuzzySearch/FuzzySearch'
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
const {SubMenu} = Menu;
const {Search}  = Input;
const {TextArea} =Input;
const {  Option } = Select;
const {RangePicker} = DatePicker;
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth()+1<10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
const day = date.getDate()<10 ? '0'+date.getDate() :date.getDate();
const hour = date.getHours()<10 ? '0'+date.getHours() :date.getHours();
const minute = date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()
const second = date.getSeconds() <10 ? '0'+date.getSeconds() : date.getSeconds();
const dayList1=year+'-'+month+'-'+day+' '+'00:00:00';
const dayList=year+'-'+month+'-'+day+' '+ hour +':'+ minute+':'+second ;
const MyIcon=Icon.createFromIconfontCN({
  // scriptUrl:'//at.alicdn.com/t/font_1733642_8n1g1hg79m.css'
})
export default class VoyageNum extends Component {
  constructor(props){
    super(props)
    this.state={
      visible: false,
      startTime:dayList1,//默认开始时间是今天零点
      endTime:dayList,//默认开始时间是今天23点
      dataSource:[],
      pageTotal:null,
      sendagainShow:false,
      pagination:{
          total:0,
          current:1,
          pageSize:5
      }
    }
    this.msgStatistics=this.msgStatistics.bind(this)
  }
  componentDidMount(){
    this.msgStatistics ();
  }
  //导出
exportDefaultExcel = (e, coloums) => {
          let dockInfoData = this.state.dataSource;
          let headers = [];
          coloums.map((item, index) => {
              let kv = {
                  k: item.dataIndex ? item.dataIndex : item.key,
                  v: item.title
              }
              headers = headers.concat(kv);
          })
          if (dockInfoData && dockInfoData.length > 0) {
              exportExcel(headers, dockInfoData, '短信发送历史.xlsx');
          } 
           else {
              message.error('导出数据失败！！！');
          }
      }
  haveTime=(a,value)=>{
    let startTime = value[0] + " 00:00:00";
    let endTime = value[1];
    endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
    this.setState({
        startTime,
        endTime,
        value_DatePicker: [startTime, endTime],
    })
}
sendagainClickShow=()=>{
  this.setState({
    sendagainShow:true
  })
}
closeModal=()=>{
  this.setState({
    sendagainShow:false,
  })
}
showDetail=(record)=>{
  console.log()

  this.setState({
    detailShow:true,
    detailData:record,
  })
}
  // 短信发送
  async msgStatistics(name){
    this.setState({loading:true})
    let {startTime,endTime,searchValue,pagination}=this.state;
    let params={
      title:searchValue,
      pageIndex:pagination.current,
      pageSize:pagination.pageSize,
      pageTotal:this.state.total1,
      startTime,
      endTime,
    }
    
    let data = await msgStatistics(params)
    .then(res => {
        return res.json();
    })
    .catch(ex => { });
    if(data.code==200){
      pagination.total=data.data.pageTotal
      this.setState({
        dataSource:data.data,
        pageTotal:data.pageTotal,
        pagination,
        loading:false
      })
    }else{
    }
  }
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
        pagination:{...pagination}
    },()=>{
        this.msgStatistics()
    })
    console.log(pagination,filters,sorter,'sorter')
}
getsearchValue=(value)=>{
  this.setState({
    searchValue:value
  },()=>{
   this.msgStatistics()
  })
}
    render() {
      const { visible, loading } = this.state;

      const columns = [
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
            title: '正文',
            dataIndex: 'content',
          },
          {
            title: '发布时间',
            dataIndex: 'recordTime',
            key:'recordTime',
            sorter: (a,b)=>{
                if (a.recordTime > b.recordTime) { 
                    return 1;
                }else if(a.recordTime < b.recordTime){
                    return -1;
                }else{
                    return 0;
                }
             },  
          },
        {
          title: '发送状态',
          dataIndex: 'state',
          render(value,state){
            if(state=1){
               return (
                 <div
                 ><MyIcon type="icon-chenggong" />&nbsp;&nbsp;发送成功</div>
               )
            }
            else if(state=0){
              return (
                <span><MyIcon type="icon-dengdaishenhe" />&nbsp;&nbsp;发送中</span>
              )
            }
            else{
              return(
                <span><MyIcon type="icon-cuowu" />&nbsp;&nbsp;发送失败</span>
              )
            }
           },
          //  filters: [
          //   {
          //     text: '发送成功',
          //     value: '1',
          //   },
          //   {
          //     text: '发送中',
          //     value: '0',
          //   },
          //   {
          //     text: '发送失败',
          //     value: '-1',
          //   }
          // ],
          // onFilter: (value, record) => record.state=== value,
          },
        {
          title: '操作',
          key: 'action',
          render:(data,record)=>{
            return <span style={{color:'Rgb(24, 144, 255)',cursor:'pointer'}} onClick={()=>this.sendagainClickShow(record)}>再次发送</span>
          }
      },
      ];
      let {detailData,endTime,startTime}=this.state;
      const formItemList = [
        {
            label: '标题', //表单label
            id: 'title', //表单Item的Key值
            component: <Input placeholder={'请输入'}/>, //表单受控组件
        },
        {
            label: '正文', 
            id: 'text',
            component: <TextArea placeholder={'请输入'} style={{height: 80}}/>,
        }]
        return (
            <div>
              <div  style={{display:'flex'}} >
              <FuzzySearch  name='关键字' onSearch={this.getsearchValue} value={this.state.searchValue} placeholder="请输入"/>
                     <text style={{marginTop:5,marginLeft:30,marginRight:15}} >选择日期:</text>
                     <RangePicker 
                           defaultValue={[moment(startTime), moment(endTime)]}
                           ranges={{
                            今日: [moment(), moment()],
                            '本周': [moment().startOf('week'), moment().endOf('week')],
                            '本月': [moment().startOf('month'), moment().endOf('month')],
                            '上月': [moment().month(moment().month() - 1).startOf('month'),moment().month(moment().month() - 1).endOf('month')],
                            '今年': [moment().startOf('year'), moment().endOf('year')],
                          }}     
                          allowClear={false}
                          value={[moment(startTime), moment(endTime)]} 
                           onChange={this.haveTime}
                    />
                    <Button  style={{marginLeft:40}} type="primary" onClick={()=>this.msgStatistics()}>查询</Button>
                            <Button style={{marginLeft:20}} 
                            
                            onClick={()=>{
                              this.setState({
                              searchValue:'',
                              startTime:dayList1,
                              endTime:dayList}
                              ,()=>{
                                this.msgStatistics()
                              })
                               }}>重置</Button>
                            <Button style={{marginLeft:650}} onClick={(e)=>this.exportDefaultExcel(e,columns)}>导出</Button> 
                  </div>
                  <Table style={{marginTop:25}} 
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                  columns={columns} dataSource={this.state.dataSource}  />
                  <ModalForm
                visible={this.state.sendagainShow}
                formItemList={formItemList}
                closeModal={this.closeModal}
                title="再次发送"
                />
            </div>
              
        )
    }
}
