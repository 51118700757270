//报警记录查询
import React, { Component } from 'react'
import { Table, Radio, DatePicker, Divider, Input, Menu, Icon, Button, Select, Pagination, Modal, InputNumber, message } from "antd";
import moment from 'moment';
import './statistics.css'
import { alarmStatistics, getDict, selectShipPage, removeAlarm, getArgs } from '../../data/dataStore';
import FuzzySearch from '../../componts/FuzzySearch/FuzzySearch'
import { getIdentificationInfo, selectShip } from '../../data/dataStore';
import { exportExcel } from 'xlsx-oc';
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
const { Option } = Select;
const { SubMenu } = Menu;
const { RangePicker } = DatePicker;
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
const dayList1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList2 = '2019' + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
function debounce(fn, ms = 500) {
  let timeoutId
  return function () {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      fn.apply(this, arguments)
    }, ms)
  }
}
function onChange2(pageNumber) {
  console.log('Page: ', pageNumber);
}
function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}
export default class Alarmhistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // visible: false,
      startTime1: dayList2,//默认开始时间是今天零点
      endTime: dayList,//默认开始时间是今天23点
      startTime:dayList1,
      pageSize: 10000,
      eventStatusArr:this.props.eventStatusArr,
      ulshow: false,
      InputValue: '',
      pageIndex: 0,
      name:'',
      eventStatusArr:'',
      dataSource: [],
      pageTotal: null,
      pagination11: {
        total: 0,
        current: 1,
        pageSize: 10000,
        showQuickJumper: true

      },
      pagination:{
        defaultPageSize: 10,
        showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
        // showSizeChanger: true,
        showQuickJumper: true,
      },
      selectedRowKeys: []
    }
    this.alarmStatistics = this.alarmStatistics.bind(this)
  }
  componentDidMount() {
    let eventStatusArr = JSON.parse(sessionStorage.getItem("eventStatusArr"));
    // let eventStatusArr=eventStatusArr && eventStatusArr
      console.log(eventStatusArr,'eventStatusArrAll')
      this.setState({
        eventStatusArr
      },()=>{
        this.alarmStatistics()
      }
      )
    this.getArgs();
    let dictData = JSON.parse(sessionStorage.getItem("dictData"))
    let shipTypeArr = []
    let eventTypeArr = []
    dictData.forEach((item) => {
      if (item.category === "ship_type") {
        shipTypeArr.push({
          text: item.typeName,
          value: item.typeCode
        })
      }
    })
    dictData.forEach((item) => {
      if (item.category === "event_type") {
        eventTypeArr.push({
          text: item.typeName,
          value: item.typeCode
        })
      }
    })
    this.setState({
      shipTypeArr,
      eventTypeArr,
      dictData,
    }
    )
  }
  showDetail = (record) => {
    console.log()
    this.setState({
      detailShow: true,
      detailData: record,
    })
  }
  getRowKeys = (selectedRowKeys,selectedRow) => {
    const deviceId = selectedRow && selectedRow.map(o => o.id)
    this.setState({
      selectedRowKeys,
      deviceId
    })
    console.log(selectedRowKeys,deviceId, 'selectedRowKeys')
  }
  deleteAll = () => {
    let { selectedRowKeys,deviceId } = this.state;
    if (deviceId && deviceId.length > 0) {
      this.removeAlarm(deviceId)
    } else {
      message.warning("请选择要解除报警的数据")
    }
  }
  //分页筛选
  async getArgs(value) {
    let params = {

    }
    let data = await getArgs(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data && data.code == 200) {
      this.setState({
        objectNameArr: data.data,

      })


    } else {
    }
  }
  getColumnSearchProps = () => ({
    filterDropdown: () => {
      // let { eventStatus } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 100 }} placeholder={'请选择'}
            showSearch
          >
            {
              <Option
                onClick={this.clickSearchData.bind(this, 0)}
                value={0} >已解除</ Option>
            }
            {
              <Option
                onClick={this.clickSearchData.bind(this, 1)}
                value={1} >未解除</ Option>


            }
          </Select>,
        </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData = (value) => {
    this.setState({
      eventStatusArr: value,

    }, () => {
      this.alarmStatistics()
    });
  }
  //导出
  exportDefaultExcel = (e, coloums) => {
    let dockInfoData = this.state.dataSource;
    delete coloums[6]
    if(dockInfoData){
      dockInfoData.map((item)=>{
        if(item.eventStatus == 1){
          item.eventStatus = "未解除"
        }else{
          item.eventStatus = "已解除"
        }
      })
    }
    let headers = [];
    coloums.map((item, index) => {
      let kv = {
        k: item.dataIndex ? item.dataIndex : item.key,
        v: item.title
      }
      headers = headers.concat(kv);
    })
    if (dockInfoData && dockInfoData.length > 0) {
      exportExcel(headers, dockInfoData, '报警记录报表.xlsx');
    }
    else {
      message.error('导出数据失败！！！');
    }
  }
  //解除报警接口
  async removeAlarm(keyArr) {
    let { InputValue } = this.state;
    let params = {}
    if (keyArr.length > 1) {
      params = {
        areaEventIds: keyArr,
        timeNum: ''
      }
    } else {
      params = {
        areaEventIds: keyArr,
        timeNum: InputValue
      }
    }

    let data = await removeAlarm(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });
    if (data.code == 200) {
      this.setState({
        loading: false,
        detailShow: false,
        startTime1: dayList2
      }, () => {
        this.alarmStatistics()
      }

      )
      message.success("解除成功")
    } else {
      message.error("解除失败")
    }
  }
  Relievevalue = (event) => {
    this.setState({
      InputValue: event
    })
  }
  handleOk = () => {
    const { InputValue, detailData } = this.state
    this.setState({
      // loading: true 
    }, () => {
      this.removeAlarm([detailData.id])
    });
  };
  haveTime = (e, value) => {
    let startTime = value[0] + " 00:00:00";
    let endTime = value[1];
    endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
    this.setState({
      startTime,
      endTime,
      value_DatePicker: [startTime, endTime],
    })
  }
  //输入框为空时搜索列表不显示
  controlList = (eve) => {
    console.log(eve, 'vvvvvv')
    if (!eve.target.value) {
      this.setState({
        ulshow: false,
        name: ''
      }, () => {
        this.alarmStatistics()
      })
    } else {
      this.setState({
        name: eve.target.value,
        objectName: name,
      }, () => {
        this.alarmStatistics(this.state.name, 'select')
      }
      )
    }
  }
  //点击搜索列表的事件
  clickSearchDatavalue = (names) => {
    this.alarmStatistics(names)
    this.setState({
      name: names
    })
  }
  getColumnSearchPropss = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 100 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.eventType.map((item) => (
                <Option
                  onClick={this.clickSearchData1.bind(this, item)}
                  value={item} >{item}</Option>
              ))
            }
          </Select>,
      </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData1 = (value) => {
    console.log(value, '000')
    let values = ''
    if (value === '拆卸报警') {
      values = 302264

    } else if (value === '低电量报警') {
      values = 302265
    }
    else if (value === '违规驶入') {
      values = 302261
    }
    else if (value === 'SOS报警') {
      values = 302267
    }
    else if (value === '倾斜报警') {
      values = 302266
    }
    else if (value === '违规驶离') {
      values = 302260
    }
    else if (value === '重置') {
      values = ''
    }
    this.setState({
      eventTypevalue: values,
    }, () => {
      this.alarmStatistics()
    });
  }
  历史报警
  async alarmStatistics(value, type) {
    this.setState({loading:true})
    let { startTime,startTime1, endTime, pagination11, pagination,eventTypevalue, eventStatusArr, searchValue, filters, name } = this.state;

    let params = {
      objectName: value ? value : name,
      eventStatus: eventStatusArr,
      startTime:startTime1 ? startTime1:startTime,
      endTime,
      eventType: eventTypevalue,
      pageIndex: pagination11.current,
      pageSize: pagination11.pageSize,
      pageTotal: this.state.total1,
      ...filters,
    }

    let data = await alarmStatistics(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });
    if (data.code == 200) {
      this.setState({
        startTime1:undefined
      })
      // data.data.items&&data.data.items.map((item)=>{
      //   item.key=item.id
      // })
      if (type === 'select') {
        this.setState({
          ullAll: data.data.items,
          ulshow: true,
          loading: false
        }
        )
      } else {
        pagination.current=1
        this.setState({
          ulshow: false,
          loading: false,
          dataSource: data.data.items,
          pageTotal: data.pageTotal,
          pagination,
          loading: false,
        })
      }
    } else {
    }
  }

  state = {
    visible: false,
    loading: false,
  };
  relieve = () => {
    this.setState({

    })
  }
  showModal = () => {
    this.setState({
      visible: false,
    });
  };

  alarmStatisticsonClick=()=>{
    this.setState({
      eventStatusArr:'',
    },()=>{
      this.alarmStatistics()
    })
  }
  handleCancel = () => {
    this.setState({
      visible: false,
      detailShow: false
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      // pagination: { ...pagination }
      pagination:pagination
    }, () => {
     
    })
    console.log(pagination, filters, sorter, 'sorter')
  }

  render() {
    let { dictData, ulshow, shipTypeArr, ullAll, selectedRowKeys, eventTypeArr, stateList1, visible, loading, startTime,startTime1,eventStatusArr, endTime,pagination } = this.state;
    let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.objectName))
    let ullAllss = [...new Set(ullAlls)];
    console.log(eventStatusArr,'测试')

    const rowSelection = {
      selectedRowKeys,
      onChange: this.getRowKeys,
      getCheckboxProps: record => ({
        disabled: record.eventStatus == '0', // Column configuration not to be checked
        name: record.eventStatus,
      }),
    };
    const columns = [
      {
        title: '船名',
        dataIndex: 'objectName',

      },
      {
        title: '船舶类型',
        dataIndex: 'shipType',
        render: (text) => {
          let newText;
          if (shipTypeArr) {
            shipTypeArr.forEach((item) => {
              if (item.value == text) {
                newText = item.text
              }
            })
          }
          return newText
        },

      },
      {
        title: '报警类型',
        dataIndex: 'eventType',
        ...this.getColumnSearchPropss('eventType'),
        render: (text) => {
          let newText;
          if (eventTypeArr) {
            eventTypeArr.forEach((item) => {
              if (item.value == text) {
                newText = item.text
              }
            })
          }
          return newText
        },
        // filters:eventTypeArr,
        // onFilter: (value, record) => record.eventType=== value,
      },
      {
        title: '报警详情',
        dataIndex: 'eventRemark'
      },
      {
        title: '初次报警时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        sorter: (a, b) => {
          if (a.gmtCreate > b.gmtCreate) {
            return 1;
          } else if (a.gmtCreate < b.gmtCreate) {
            return -1;
          } else {
            return 0;
          }
        },
        // defaultSortOrder: 'descend',
        // sorter: (a,b) =>a.age - b.age,
      },
      // {
      //     title: '最后报警时间',
      //     dataIndex: 'eventStatus',
      //     defaultSortOrder: 'descend',
      //     sorter: (a,b) =>a.age - b.age,
      //   },
      //   {
      //    title:'持续时间',
      //    dataIndex: 'persistTime',
      //   },
      {
        title: '处理状态',
        dataIndex: 'eventStatus',
        ...this.getColumnSearchProps('eventStatus'),
        render(eventStatus) {
          if (eventStatus == "1") {
            return (
              <div style={{ color: '#E84335' }}>未解除</div>
            )
          }
          else if (eventStatus == "0") {
            return (
              <div>已解除</div>
            )
          }
        },
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (e, record) => {
          if (record.eventStatus == "1") {
            return (
              <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={this.showDetail.bind(this, record)}>解除</span>
            )
          }
          else if (record.eventStatus == "0") {
            return (
              <span >解除</span>

            )
          }
        }
      },
    ];
    return (
      <div>
        <div className='alarm_menu'  >
          <div style={{ display: 'flex' }}>
            <span style={{ width: 75, marginTop: 5, textAlign: 'center' }} >船名：</span>
            <Input 
            value={this.state.name} 
            // onSearch={value => this.selectVoyageStatistics(value, 'select')} 
            onChange={value => this.controlList(value)} 
            onPressEnter={() => this.alarmStatistics()}
            placeholder="请输入" />
          </div>
          {
            ulshow && <ul className="searchList">
              {
                ullAllss && ullAllss.map((item, index) => (
                  <li
                    key={item.index}
                    onClick={this.clickSearchDatavalue.bind(this, item)}
                    style={{
                      cursor: 'pointer',
                      fontSize: 16,
                      display: 'flex',
                      alignItems: "center",
                    }}
                  ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                  </li>
                ))
              }
            </ul>
          }
          <text style={{ marginTop: 5, marginLeft: 30, marginRight: 15 }} >选择日期:</text>
          <RangePicker
            defaultValue={[moment(startTime), moment(endTime)]}
            ranges={{
              今日: [moment(), moment()],
              '本周': [moment().startOf('week'), moment().endOf('week')],
              '本月': [moment().startOf('month'), moment().endOf('month')],
              '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
              '今年': [moment().startOf('year'), moment().endOf('year')],
            }}
            allowClear={false}
            // value={[moment(startTime), moment(endTime)]}
            onChange={this.haveTime}
            
          />
          <Button style={{ marginLeft: 40 }} type="primary" 
          // onClick={() => this.alarmStatistics()}
          onClick={ this.alarmStatisticsonClick}
          >查询</Button>
          <Button style={{ marginLeft: 20 }}
            onClick={() => {
              this.setState({
                searchValue: '',
                name: '',
                startTime: dayList1,
                endTime: dayList,
                eventStatusArr: '',
                eventTypevalue:'',

              }
                , () => {
                  this.alarmStatistics()
                })
            }}
          >重置</Button>
          <Button style={{ marginLeft: 530 }} onClick={this.deleteAll}>批量解除</Button>
          <Button style={{ marginLeft: 20 }} onClick={(e) => this.exportDefaultExcel(e, columns)}>导出</Button>
        </div>
        <Table
          className='alarmhistory_icon'
          loading={this.state.loading}
          pagination={pagination}          onChange={this.handleTableChange}
          style={{ marginTop: 25 }}
          rowSelection={{
            ...rowSelection
          }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={this.state.dataSource} />
        <Modal
          className='modal_relieve'
          title="解除"
          visible={this.state.detailShow}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button onClick={this.handleCancel}>
              取消
                  </Button>,
            <Button type="primary" loading={loading} onClick={this.handleOk}>
              解除
                  </Button>,
          ]}
          onCancel={() => { this.setState({ detailShow: false }) }}
        >
          <div style={{}}>
            <InputNumber style={{ width: 55, fontSize: 14, }} min={1} max={24} autoFocus={true} defaultValue={3} value={this.state.InputValue} onChange={this.Relievevalue} />
            <span style={{ fontWeight: "bold" }}>小时内,不再进行该报警交互</span>
          </div>
        </Modal>
      </div>

    )
  }
}
