import React, { Component } from 'react';
import {
    //h5 请求接口
    h5stream_login,
    h5stream_Keepalive,
    h5stream_getAllSrc,
    h5stream_Snapshot,
    h5stream_Snapshot_Screenshots,
    h5stream_videoLastImg,
    h5stream_getVideoRecord,
    h5stream_ManualRecordStart,
    h5stream_ManualRecordStop,
    h5stream_remoteControl,
    h5stream_SetPreset,
    h5stream_PathRecord,
    h5stream_DelPreset,
} from '../h5streamTool/h5streamTool'

import {
    getDownloadImg,
} from './masterControlFactory/interfaceRequest/RequestImpl'
class ComponentWorkspace extends Component {
    constructor(props) {
        super(props)
        this.state = {
            h5stream_url: 'http://116.228.75.54:8090',
            componentRepository: {},
            session_h5stream: '',
            snapshotLibrary: [],
        }
    }


    componentLoad = (type, obj) => {
        this.state.componentRepository = {
            ...this.state.componentRepository,
            [type]: obj
        }
        console.log(type + "," + obj, "---ComponentWorkspace here : the new component load success ")
        console.log(this.state.componentRepository, "---ComponentWorkspace here : componentRepository")
        return this;
    }

    /**
     *@Description:{ h5 操作模块  }
     *@ClassAuthor: ZDong
     *@Date: 2020-01-08 22:28:49
    */
    h5stream_login = (obj, params) => {
        console.log(params, '-----------------h5stream_login--')
        if (typeof (params) == 'undefined') {
            params = {}
        }
        params.user = 'admin';
        params.password = '12345';
        params.password = '827ccb0eea8a706c4c34a16891f84e7b';//md5加密 12345
        params.h5stream_url = obj.state.h5stream_url;
        h5stream_login(obj, params);
    }
    h5stream_getAllSrc = (obj) => {
    }
    h5stream_Snapshot = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;
        h5stream_Snapshot(obj, params);
    }
    // 拍照
    h5stream_Snapshot_Screenshots = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;
        h5stream_Snapshot_Screenshots(obj, params);
    }
    h5stream_videoLastImg = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;

        h5stream_videoLastImg(obj, params);
    }
    h5stream_getVideoRecord = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;

        h5stream_getVideoRecord(obj, params);
    }
    h5stream_ManualRecordStart = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;

        h5stream_ManualRecordStart(obj, params);
    }
    h5stream_ManualRecordStop = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;

        h5stream_ManualRecordStop(obj, params);
    }
    h5stream_PathRecord = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;

        h5stream_PathRecord(obj, params);
    }
    h5stream_remoteControl = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;

        h5stream_remoteControl(obj, params);
    }
    h5stream_SetPreset = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;
        h5stream_SetPreset(obj, params);
    }
    h5stream_DelPreset = (obj, params) => {
        params.h5stream_url = obj.state.h5stream_url;
        h5stream_DelPreset(obj, params);
    }


    setSession_h5stream = (data) => {
        this.setState({
            session_h5stream: data.strSession,
            // h5stream_url: 'http://116.228.75.54:8090',
            // h5stream_lastImg_url: 'http://116.228.75.54:8090/api/v1/GetImage',

        }, () => {
            console.log(data, '-------setSession_h5stream here-------set session successfully--')
            h5stream_Keepalive(this, this.state.session_h5stream);
            h5stream_getAllSrc(this, this.state.session_h5stream);
            if (this.setInitsnapshotLibrary) {
                this.setInitsnapshotLibrary();
            }
        })
    }
    KeepaliveSession_h5stream = (data) => {
        console.log(data, '-------KeepaliveSession_h5stream here-------keep alive session successfully--')
    }
    getAllSrc_h5stream = (data) => {
        console.log(data, '-------getAllSrc_h5stream here-------getAllSrc_h5stream successfully--')
        this.setState({
            h5streamAllSrc: data.src,
        })
    }
    getScreenshots_Snapshot_h5stream = (data) => {
        console.log(data, '-------getScreenshots_Snapshot_h5stream here-------getScreenshots_Snapshot_h5stream successfully--')
        if (data.bStatus) {
            var url = data.strUrl;
            console.log(url, '--------------getScreenshots_Snapshot_h5stream---')
            this.downloadImg(url);
        } else {
            alert('抓图失败!');
        }
    }
    async downloadImg(url) {
        let param = {
            url: url,
        };
        let data = await getDownloadImg(param)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == '200') {
            console.log(data.data, '-------getScreenshots_Snapshot_h5stream---- here ----');
            let downloadUrl = '/api' + data.data;
            var link = document.createElement('a');
            //设置下载的文件名
            link.download = '';
            link.style.display = 'none';
            //设置下载路径
            link.href = downloadUrl;
            //触发点击
            document.body.appendChild(link);
            link.click();
            //移除节点
            document.body.removeChild(link);
        } else {
            alert('下载失败!请检查网络');
        }
    }
    getSnapshot_h5stream = (data) => {
        console.log(data, '-------getSnapshot_h5stream here-------getSnapshot_h5stream successfully--')
        let snapshotLibrary = this.state.snapshotLibrary;
        snapshotLibrary = snapshotLibrary.concat(data);
        let snapshotLibrarySavedNum = this.state.snapshotLibrarySavedNum + 1;
        let snapshotLibraryNeedSaveNum = this.state.snapshotLibraryNeedSaveNum;
        let isFinishSnapshotLibrary = false;
        if (snapshotLibrarySavedNum == snapshotLibraryNeedSaveNum) {
            isFinishSnapshotLibrary = true;
        }
        this.setState({
            snapshotLibrary: snapshotLibrary,
            snapshotLibrarySavedNum: snapshotLibrarySavedNum,
            isFinishSnapshotLibrary: isFinishSnapshotLibrary,
        }, () => {
            console.log(this.state.snapshotLibrary, this.state.snapshotLibrarySavedNum, this.state.snapshotLibraryNeedSaveNum, '-------------------snapshotLibrary 快照库增加一份数据')
            if (isFinishSnapshotLibrary) {
                this.state.carouselWithSwiperObj.initSwiper();
            }
        })
    }
    getVideoLastImg_h5stream = (data) => {
        console.log(data, '-------getVideoLastImg_h5stream here-------getSnapshot_h5stream successfully--')
        let videoLastImgLibrary = this.state.videoLastImgLibrary;
        videoLastImgLibrary = videoLastImgLibrary.concat(data);
        let videoLastImgLibrarySavedNum = this.state.videoLastImgLibrarySavedNum + 1;
        let videoLastImgLibraryNeedSaveNum = this.state.videoLastImgLibraryNeedSaveNum;
        let isFinishVideoLastImgLibraryLibrary = false;
        if (videoLastImgLibrarySavedNum == videoLastImgLibraryNeedSaveNum) {
            isFinishVideoLastImgLibraryLibrary = true;
        }
        this.setState({
            videoLastImgLibrary: videoLastImgLibrary,
            videoLastImgLibrarySavedNum: videoLastImgLibrarySavedNum,
            isFinishVideoLastImgLibraryLibrary: isFinishVideoLastImgLibraryLibrary,
        }, () => {
            console.log(this.state.videoLastImgLibrary, this.state.videoLastImgLibrarySavedNum, this.state.videoLastImgLibraryNeedSaveNum, '-------------------videoLastImgLibrary 摄像头最后一针照片缓存库增加一份数据')
            if (isFinishVideoLastImgLibraryLibrary) {
                this.state.carouselWithSwiperObj.initSwiper();
            }
        })
    }

    getVideoRecord_h5stream = (data) => {
        console.log(data, '-------data------------getVideoRecord_h5stream--')
        this.setState({
            videoRecordData: data,
        })
    }
    getManualRecordStart_h5stream = (data) => {
        console.log(data, '-------data------------getManualRecordStart_h5stream--')
        this.setState({
            videoManualRecordStart: true,
            videoManualRecordStartData: data,
        }, () => {
            this.addVideoInfo(this.state.videoManualRecordStartData, 600);
            this.state.componentRepository.videoTree.refreshVideoTreeData();
        })
    }
    getManualRecordStop_h5stream = (data) => {
        console.log(data, '-------data------------getManualRecordStop_h5stream--')
        this.setState({
            videoManualRecordStart: false,
        }, () => {
            // this.addVideoInfo(this.state.setPathRecord,this.state.time);
            // this.state.componentRepository.videoTree.refreshVideoTreeData();
        })
    }
    getRemoteControl_h5stream = (data) => {
        console.log(data, '-------data------------getRemoteControl_h5stream--')
        this.setState({
            remoteControlData: data,
        })
    }
    getSetPreset_h5stream = (data) => {
        console.log(data, '-------data------------getSetPreset_h5stream--')
        this.setState({
            setPresetData: data,
        })
    }
    getPathRecord_h5stream = (data) => {
        console.log(data, '-------data------------getSetPreset_h5stream--')
        this.setState({
            setPathRecord: data,
        }, () => {
            this.addVideoInfo(this.state.setPathRecord, this.state.time);
        })
    }
    getDelPreset_h5stream = (data) => {
        console.log(data, '-------data------------getDelPreset_h5stream--')
        this.setState({
            delPathRecord: data,
        })
    }
}
export default ComponentWorkspace
