import React, { Component } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom';
import Form from '../../componts/form/Form'
import { Icon, Badge, Modal, Select, Button, Popover, Input, Dropdown, Menu, message, Popconfirm, Table, DatePicker } from 'antd'
import './header.css'
import warnType from '../../assets/img/warnType.png'
import { alarmSimpleStatistics, logout, alarmSimpleStatisticsToday, system_config, fastSelectShip, getMessage, sendMessage, cardNumTypefastSelectShip } from '../../data/dataStore'
import moment from 'moment';
import ModalForm from '../ModalForm/index'
const { Option, OptGroup } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { TextArea } = Input;
// const maths = {
//     'mail' : "" ,
//     'mailLight': "" ,
//     'warnType' : "" ,
//     'locusLight' : "" ,
// }
// Object.keys(maths).forEach(item => maths[item] = require("../../assets/img/" + item+".pn
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
const dayList1 = year + '-' + month + '-' + day + ' ';
const dayList = year + '-' + month + '-' + day + ' ';
let newwarnArr = [
    {
        type: '模板一', key: 1,
        warn: [
            { title: '暴风灾害预警', value: 5, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' },
            { title: '暴风灾害预警', value: 9, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' },
            { title: '暴风灾害预警', value: 10, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }]
    },

    {
        type: '模板二', key: 2,
        warn: [
            { title: '暴雨灾害预警', value: 6, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警222' }, { title: '暴风灾害预警', value: 11, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }, { title: '暴风灾害预警', value: 12, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }]
    },
    { type: '模板三', key: 3, warn: [{ title: '大雾灾害预警', value: 7, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警666' }, { title: '暴风灾害预警', value: 13, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }, { title: '暴风灾害预警', value: 14, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }] },
    { type: '模板四', key: 4, warn: [{ title: '雷电灾害预警', value: 8, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警888' }, { title: '暴风灾害预警', value: 15, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }, { title: '暴风灾害预警', value: 16, more: '暴风灾害预警暴风灾害预警暴风灾害预警暴风灾害预警1111' }] },
]
class Header extends Component {
    constructor() {
        super()
        this.state = {
            // warnArrs: null,

            warnShow: false,
            AppShow: false,
            msgShow: false,
            userData: null,
            ReceiptShow: true,
            hairShow: false,
            sendingShow: false,
            shipsmsName: '',
            startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
            endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            shuaShow: true
        }
        this.getMessage = this.getMessage.bind(this)
        this.getMessage2 = this.getMessage2.bind(this)
        this.alarmSimpleStatistics = this.alarmSimpleStatistics.bind(this)
    }
    componentDidMount() {
        let dictData = JSON.parse(sessionStorage.getItem('dictData'))
        this.setState({
            dictData: dictData ? dictData : []
        }, () => {
            // this.alarmSimpleStatisticsToday()
        })
        let userData = JSON.parse(sessionStorage.getItem("insLogin"))
        // let warnArrs = JSON.parse(sessionStorage.getItem("key"));
        // let allAlarm = 0
        // warnArrs && warnArrs.map((item) => {
        //     allAlarm += item.value
        // })
        // let systemData = Json.parse(sessionStorage.getItem("syetemkey"))
        this.fastSelectShip()
        // this.getMessage()
        this.getMessage2()
        this.alarmSimpleStatistics()
        this.cardNumTypefastSelectShip()
        this.setState({
            userData,
            // warnArrs,
            // allAlarm,
            // systemData,
        })
        this.system_config()
        this.timer = setInterval(() => this.getMessage2(), 60000)
        this.timer = setInterval(() => this.alarmSimpleStatistics(), 60000)
    }
    // 查询所有的msg
    async getMessage() {
        let { shipsmsName, startTime, endTime } = this.state
        let params = {
            objectName: shipsmsName,
            startTime: startTime,
            endTime: endTime

        }
        let data = await getMessage(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            // let msgIdArr = Object.keys(data.data)
            // for (let i in data.data) {
            //     data.data[i].reverse()
            // }
            let FaData = data.data.items.filter(item => item.msgType == 304112)
            let shData = data.data.items.filter(item => item.msgType == 304111)

            this.setState({
                FaData: FaData,
                shData: shData
            })

        } else {
            // Toast.info(data?data.msg:'接口错误')
        }
    }
    // 查询所有的msg
    async getMessage2() {
        let params = {
            startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
            endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        }
        let data = await getMessage(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            // let msgIdArr = Object.keys(data.data)
            // for (let i in data.data) {
            //     data.data[i].reverse()
            // }
            let shData = data.data.items.filter(item => item.msgType == 304111)
            this.setState({
                allsmsDatas: shData

            })

        } else {
            // Toast.info(data?data.msg:'接口错误')
        }
    }
    // 查询报警数目
    async alarmSimpleStatistics() {
        let params = {
            // shipNameOrDeviceId: val ? val : ''
        }
        let data = await alarmSimpleStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let { dictData } = this.state;
            let warnArrs = []
            let warnArr = []
            if (dictData) {
                dictData.forEach((val) => {
                    data.data.children.forEach((item, index) => {
                        if (item.eventType == val.typeCode) {
                            console.log(val.typeName, 'typeName')
                            warnArrs.push({
                                name: val.typeName,
                                value: item.eventCount,
                            })
                            warnArr.push({
                                name: val.typeName + '    ' + item.eventCount,
                                value: item.eventCount,
                                alarmname: val.typeName,
                            })
                        }
                    })
                })
            }
            this.setState({
                warnArrs: warnArrs,
                allAlarm: data.data.alarmTotalCount
            })

        } else {
            // Toast.info(data?data.msg:'接口错误')
        }
    }
    //短信发送接口
    async sendMessage(params) {
        // let params = {
        //     // shipNameOrDeviceId: val ? val : ''
        // }
        let data = await sendMessage(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success('发送成功')

        } else {
            // Toast.info(data?data.msg:'接口错误')
        }
    }
    // 登出接口
    async logout(value) {
        let data = await logout(value)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            window.sessionStorage.clear()
            this.props.history.push('/login')
        }
    }
    handleClick = () => {
        this.setState({
        }, () => {
            this.logout()
        })
    }
    haveTime = (moment, str) => {
        console.log(str, 'sssssssss')
        this.setState({
            startTime: str[0] ? str[0] + ' 00:00:00' : '',
            endTime: str[1] ? str[1] + ' 23:59:59' : '',
        })
    }
    // baojing
    // async alarmSimpleStatisticsToday() {
    //     let params = {
    //         startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
    //         endTime: moment().format('YYYY-MM-DD HH:mm:ss')
    //     }
    //     let data = await alarmSimpleStatisticsToday(params)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data && data.code == 200) {
    //         this.setState({
    //             alarmData: data.data.children,
    //             allAlarm: data.data.alarmTotalCount
    //         }, () => {
    //             console.log(this.state.alarmData, this.state.allAlarm, 'allAlarm')
    //         })
    //         this.alarmSimpleStatisticsToday()
    //     } else if (data && data.code == 500) {
    //         message.error('用户未登录')
    //         if (this.props.history) {
    //             this.props.history.push('/login')
    //         }
    //     }
    // }
    //系统配置信息
    async system_config() {
        let params = {

        }
        let data = await system_config(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                configData: data.data
            })

        } else if (data && data.code == 500) {
        }
    }
    // 搜索船名和标签id
    async fastSelectShip(value) {
        let params = {
        }

        let data = await fastSelectShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.data,
            })


        } else if (data && data.code == 505) {
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    //查询北斗卡号
    // 搜索船名和标签id
    async cardNumTypefastSelectShip(value) {
        let { } = this.state
        let params = {
            cardNumType: 302501
        }

        let data = await cardNumTypefastSelectShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                allsmsData: data.data.shipList,
                shuaShow: false
            })


        } else if (data && data.code == 505) {
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    showWran = () => {
        let { getDict, alarmData } = this.state;
        this.setState({
            warnShow: !this.state.warnShow,
        })
    }
    ApphandleClick = () => {
        this.setState({
            AppShowstyle: { color: '#159DFF' },
            // { color: '#FFFFFF' },
            // AppShow: !this.state.AppShow,
            msgShow: true
        })
    }
    showMsg = () => {
        this.setState({
            msgShow2: !this.state.msgShow2,
            ReceiptShow: true,
            hairShow: false,
        })
        this.getMessage()
    }
    deleteWarn = (e) => {
        e.stopPropagation()
        console.log(3333)
    }
    onMouseOver = (val) => {

    }
    closeModal = () => {
        let { AppShowstyle } = this.state
        this.setState({
            AppShow: false,
            msgShow: false,
            msgShow2: false,
            AppShowstyle: { color: '#FFFFFF' },

        })
    }
    ReceiptClick = () => {
        let { ReceiptShow, hairShow } = this.state
        this.setState({
            ReceiptShow: true,
            hairShow: false,
            shipsmsName: ''

        }, () => {
            this.getMessage()
        })
    }
    hairClick = () => {
        let { ReceiptShow, hairShow } = this.state
        this.setState({
            ReceiptShow: false,
            hairShow: true,
            shipsmsName: ''
        }, () => {
            this.getMessage()
        })

    }
    sendingClick = () => {
        this.setState({
            sendingShow: true
        })
    }
    closeModalsending = () => {
        this.setState({
            sendingShow: false
        })
    }
    ReceiptInput = (value) => {
        this.setState({
            shipsmsName: value.target.value
        })


    }
    hairInput = (value) => {
        this.setState({
            shipsmsName: value.target.value
        })
    }
    render() {
        let { warnArr, warnShow, msgShow2, msgShow, allsmsDataNum, userData, getDict, allAlarm, shuaShow, configData, warnArrs, startTime, endTime, AppShow, AppShowstyle, ReceiptShow, hairShow, objectNameArr, msgList, shData, FaData, allsmsData, allsmsDatas } = this.state;

        if (shuaShow) {
            this.fastSelectShip()
            this.getMessage2()
            this.alarmSimpleStatistics()

            this.cardNumTypefastSelectShip()
            this.setState({
                userData,
                shuaShow: false
            })
        }
        let systemname = localStorage.getItem("shipname")
        console.log(allsmsData, '80008080')
        let userdata = JSON.parse(sessionStorage.getItem("isLogin"))
        // let warnArrs = JSON.parse(sessionStorage.getItem("key"));
        // warnArrs && warnArrs.map((item) => {
        //     allAlarm += item.value
        // })
        const formItemList = [
            {
                label: '目标船舶', //表单label
                id: 'bdNumber', //表单Item的Key值
                component: <Select placeholder={'请选择目标船舶'}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        allsmsData && allsmsData.map((item) => (
                            <Select.Option key={item.deviceId} value={item.deviceId} >{item.objectName}</Select.Option>
                        ))
                    }
                </Select>,
                options: { rules: [{ required: true, message: '船舶名称不能为空!' }] }
            },
            {
                label: '短信内容',
                id: 'msg',
                options: {
                    rules: [
                        {
                            required: true,
                            message: '请输入黑名单原因！',
                        },
                        {
                            max: 30,
                            message: '不许超过30！！！',
                        },
                    ],
                },
                component:
                    <TextArea
                        placeholder={'请输入'}
                        style={{ height: 10 }}
                        allowClear={true}
                        autoSize={{
                            minRows: 3,
                            maxRows: 5,
                        }}
                        maxLength={30}
                    />,
            }]
        const columnsReceipt = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                ellipsis: true,
                width: '10vh'
            },

            {
                title: '收件时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
                width: '20vh'
            },
            {
                title: '收件方',
                dataIndex: 'objectName',
                key: 'objectName',
                ellipsis: true,
                width: '15vh'
            },
            {
                title: '北斗号码',
                dataIndex: 'dst',
                key: 'dst',
                ellipsis: true,
                width: '10vh'
            },
            {
                title: '短信内容',
                dataIndex: 'content',
                key: 'content',
                ellipsis: true,
            },
        ]
        const columnsSendout = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '10vh',
                ellipsis: true,
            },
            {
                title: '发件时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                ellipsis: true,
                width: '20vh'
            },
            {
                title: '目标船舶',
                dataIndex: 'objectName',
                key: 'objectName',
                ellipsis: true,
                width: '15vh'
            },
            {
                title: '北斗号码',
                dataIndex: 'dst',
                key: 'dst',
                ellipsis: true,
                width: '10vh'
            },
            {
                title: '短信内容',
                dataIndex: 'content',
                key: 'content',
                ellipsis: true,
            },
        ]
        const { location: { pathname } } = this.props
        const hideHeaderPath = ['/login', '/warMap', '/']
        const hideHeader = hideHeaderPath.includes(pathname.trim())
        // const formItemList = [
        //     {
        //         label: '标题', //表单label
        //         id: 'username', //表单Item的Key值
        //         component: <Input placeholder={'请输入'} />, //表单受控组件
        //     },
        //     {
        //         label: '正文',
        //         id: 'password',
        //         component: <TextArea placeholder={'请输入'} style={{ height: 80 }} />,
        //     }]
        return hideHeader ? null : (
            <div className="header" >
                <div className="header_left">
                    <h3 onClick={() => this.props.history.push('/warMap')}><span>
                        {
                            systemname && systemname
                        }
                    </span></h3>
                    <ul>
                        <li><NavLink to="/home"
                            activeStyle={{
                                fontWeight: 'bold',
                                color: '#00b3f7'
                            }}>实时监控</NavLink>
                        </li>
                        <li><NavLink to="/RealTimeTyphoonMonitoring"
                            activeStyle={{
                                fontWeight: 'bold',
                                color: '#00b3f7'
                            }}
                        >实时台风</NavLink></li>
                        <li><NavLink to="/statistics"
                            activeStyle={{
                                fontWeight: 'bold',
                                color: '#00b3f7'
                            }}
                        >查询统计</NavLink></li>
                        <li><NavLink to="/enforceTheLaw"
                            activeStyle={{
                                fontWeight: 'bold',
                                color: '#00b3f7'
                            }}
                        >执法管理</NavLink></li>
                        <li><NavLink to="/system"
                            activeStyle={{
                                fontWeight: 'bold',
                                color: '#00b3f7'
                            }}
                        >系统管理</NavLink></li>
                    </ul>
                </div>
                <div className="header_right">
                    {/* 发送短信图标 */}
                    {/* <Popconfirm  okText="" cancelText="">
                      app下载
                    </Popconfirm>, */}
                    <span onClick={this.ApphandleClick}
                        className='AppShowBox'
                        style={msgShow ? { color: '#159DFF' } : { color: '#FFFFFF' }}
                    // style={AppShowstyle}
                    >app下载</span>
                    <Badge className='BadgeStyle' count={allsmsDatas && allsmsDatas.length} overflowCount={9999}>
                        <Icon type="mail" theme="filled" onClick={this.showMsg} style={msgShow2 ? { color: '#159DFF' } : { color: '#6F7681', }} />

                    </Badge >

                    <Badge count={allAlarm} overflowCount={9999}>
                        <Icon type="alert" theme="filled" onClick={this.showWran} style={warnShow ? { color: '#159DFF' } : { color: '#6F7681' }} />
                    </Badge>
                    <Dropdown
                        overlay={
                            <div>
                                <div className="dropMenu1">
                                    <Button onClick={this.handleClick} >退出登录</Button >
                                </div>
                            </div>
                        }
                    >
                        <span>
                            <Icon type="user" style={{ marginRight: 10 }} />
                            {userdata && userdata.data.name}
                            <Icon type="down" style={{ margin: '0 10px', color: '#fff' }} />
                        </span>
                    </Dropdown>
                </div>
                {
                    warnShow && <div className="warnBox">
                        <h3 onClick={() => {
                            this.setState({
                                warnShow: !this.state.warnShow,
                            })
                        }}

                        >报警通知</h3>
                        {
                            warnArrs && warnArrs.map((item, index) => (
                                <div className="warnTypebox">
                                    <img src={warnType} />
                                    <span className="warntype">{item.name}</span>
                                    <span className="huihui">{item.value}</span>
                                </div>
                            ))
                        }
                        <p onClick={() => { this.setState({ warnShow: false }) }}><Link to={{ pathname: "/statistics", state: { key: 'alarmHistory', eventStatusArr: 1, } }}>查看详情&gt;&gt;</Link></p>
                    </div>
                }
                {/* {
                    AppShow && <div className='AppBox'> 
                         </div>
                } */}
                {/* <modal></modal> */}
                <Modal
                    visible={msgShow}
                    // cancelText="存为模板"
                    // okText="发送"
                    footer={false}
                    title={false}
                    // className="ps_warn"
                    closeModal={this.closeModal}
                    className='AppBox1'
                    width={300}
                    zIndex={10}
                    onCancel={() => this.setState({ msgShow: false })}
                >
                    <div className='AppBox'>

                    </div>
                </Modal>
                {msgShow2 && <div className='ps_warn'>
                    <div className='buttonswitch'>
                        <Button type={ReceiptShow ? 'primary' : ''} onClick={this.ReceiptClick} >收件箱</Button>
                        <Button type={hairShow ? 'primary' : ''} style={{ marginLeft: '2vh' }} onClick={this.hairClick} >发件箱</Button></div>
                    <div>
                        {ReceiptShow && <div className='divborder'> <div><span style={{ color: 'black' }}>船舶名称:</span>
                            <Input
                                style={{ width: '20vh', marginRight: '2vh', height: '3vh' }}
                                onChange={value => this.ReceiptInput(value)}
                            ></Input>
                            查询时段：<RangePicker
                                defaultValue={[moment(startTime), moment(endTime)]}
                                onChange={this.haveTime} style={{ marginRight: '2vh' }}></RangePicker>
                            <Button type={ReceiptShow ? 'primary' : ''} onClick={this.getMessage}>查询</Button></div>
                            <Table
                                className='TableStyle'
                                columns={columnsReceipt}
                                dataSource={shData}
                                pagination={{
                                    defaultPageSize: 8,
                                    defaultCurrent: 1,
                                    showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
                                    // showSizeChanger: true,
                                    showQuickJumper: true,
                                }}
                            ></Table> </div>}
                        {hairShow && <div className='divborder'> <div> <span style={{ color: 'black' }}>船舶名称:</span>
                            <Input
                                style={{ width: '20vh', marginRight: '2vh', height: '3vh' }}
                                onChange={value => this.hairInput(value)}
                            ></Input>
                            查询时段：<RangePicker
                                defaultValue={[moment(startTime), moment(endTime)]}
                                onChange={this.haveTime} style={{ marginRight: '2vh' }}>
                            </RangePicker><Button type={hairShow ? 'primary' : ''} onClick={this.getMessage}>查询</Button>
                            <Button style={{ marginLeft: '2vh' }} type='primary' onClick={this.sendingClick}> 发短信</Button></div>
                            <Table
                                className='TableStyle'
                                dataSource={FaData}
                                columns={columnsSendout}
                                pagination={{
                                    defaultPageSize: 8,
                                    defaultCurrent: 1,
                                    showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
                                    // showSizeChanger: true,
                                    showQuickJumper: true,
                                }}
                            ></Table> </div>}
                    </div>

                </div>}
                <ModalForm
                    visible={this.state.sendingShow}
                    formItemList={formItemList}
                    closeModal={this.closeModalsending}
                    submit={this.sendMessage}
                    title="短信发送"
                />
            </div >
        )
    }
}



export default withRouter(Header)
