//多卡号查询
import React, {Component} from "react";
import {Icon, message, Button, Spin, Upload, Steps} from 'antd';
import './css/system.css'
const { Dragger } = Upload;
const { Step } = Steps;

const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};


export default  class QueryMultipleCards extends Component{
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
    }



    render() {
        return (<div id='content' className="contentBox">
            <div className="othersManagement_title cardTitle">
                <div className="cardManagement_title_leftVerticalBar"/>
                <div className="cardManagement_title_text">多卡号查询</div>
                <div className="cardManagement_title_rightLine"/>
            </div>

            <div style={{width:300, height:150}}>
                支持格式：<span style={{color:'red'}}>xlsx</span>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或拖拽到此区域进行上传文件</p>

                </Dragger>
            </div>
        </div>)
    }
}