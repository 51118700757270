import React, { Component } from 'react'
import {NavLink,Link} from 'react-router-dom'
import './statistics.css'
import AlarmHistory from './AlarmHistory.js'
import AlarmTimeout from './AlarmTimeout'
import ImportAndExportPort from './ImportAndExportPort.js'
import Voyage from './Voyage.js'
import AreaTrajectorySearch from './AreaTrajectorySearch.js'
import SendSms from './SendSms.js'
import VoyageNum from './VoyageNum.js'
import { Table, Radio, Divider ,Input,Menu,Icon,Button,Select,DatePicker} from "antd"
import moment from 'moment';
const {SubMenu} = Menu;
const {Search}  = Input;
const {  Option } = Select;
const MyIcon=Icon.createFromIconfontCN({
  scriptUrl:'//at.alicdn.com/t/font_1733642_1mx9i5l5y9r.js'
})
export default class Statistics extends Component {   
  constructor(props,context){
    super(props,context);
    this.state = {
        collapsed: false,
        eventStatusArr:'',
        visible: false,
        defaultKey:"areaTrajectorySearch"
      };
  }
  componentDidMount(){
    let eventStatusArr= this.props.history.location.state&&this.props.history.location.state.eventStatusArr?this.props.history.location.state.eventStatusArr:'' ;
    const {dispatch,location,}=this.props;
  console.log(eventStatusArr,'eventStatusArralls')
        let defaultKey;
        
        if(location.state){//判断当前有参数
          defaultKey=location.state.key;
          this.setState({
            defaultKey,eventStatusArr

          })
        }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  
    
      toggleCollapsed = () => {

        this.setState({
          collapsed: !this.state.collapsed,
        });
      };   
      changepage=(item,key)=>{
        this.setState({
          defaultKey:item.key,
          eventStatusArr:'1'
        })
      } 
      // changepage=(item,key)=>{
      //   this.setState({
      //     defaultKey:item.key
      //   })
      // } 
    render() {
      let {defaultKey,changepage=(item,key)=>{
        this.setState({
          defaultKey:item.key
        })
      },eventStatusArr }=this.state
      console.log(eventStatusArr && eventStatusArr,'eventStatusArrsss')
      sessionStorage.setItem("eventStatusArr", JSON.stringify(eventStatusArr));
      const REG_PAGES = {
        "areaTrajectorySearch": { title: '区域轨迹查询', content: <AreaTrajectorySearch history={this.props.history}/>, key: 'areaTrajectorySearch',  },
        "voyageNum": { title: '航次统计报表', content: <VoyageNum history={this.props.history}/>, key: 'voyageNum',  },
        "voyage": { title: '航程统计报表', content: <Voyage history={this.props.history}/>, key: 'voyage' },
        "exportPort": { title: '进出港统计报表', content: <ImportAndExportPort history={this.props.history}/>, key: 'exportPort' },
        // "sendSms": { title: '短信发送历史', content: <SendSms />, key: 'sendSms' },
        "alarmHistory": { title: '历史报警统计', content: <AlarmHistory history={this.props.history}/>, key: 'alarmHistory' },
        "alarmTimeout": { title: '超时船舶管理', content: <AlarmTimeout history={this.props.history}/>, key: 'alarmTimeout' }
      }
        return (
            <div className="statistics">
               <div className = 'menu' style= {{ width : 256}}>
                 <Menu
                   selectedKeys={[defaultKey]}
                   mode="inline"
                   theme="dark"
                   inlineCollapsed={this.state.collapsed}
                   onClick={this.changepage}
                 >
                    <Menu.Item key="areaTrajectorySearch">
                    <MyIcon type="icon-quyuguijichaxun"/>
                     <span>区域轨迹查询</span>
                   </Menu.Item>
                    <Menu.Item key="voyageNum">
                    <MyIcon type="icon-hangchengtongjibaobiao"/>
                     <span>航次统计报表</span>
                   </Menu.Item>
                   <Menu.Item key="voyage">
                     <MyIcon type="icon-hangcitongjibaobiao1" />
                       <span>航程统计报表</span>
                   </Menu.Item>
                   <Menu.Item key="exportPort">
                   <MyIcon type="icon-jinchugang" />
                     <span>进出统计报表</span>
                   </Menu.Item>
                   {/* <Menu.Item key="sendSms">
                     <MyIcon type="icon-duanxin" />
                     <span>短信发送历史</span>
                   </Menu.Item> */}
                   <Menu.Item key="alarmHistory">
                     <MyIcon type="icon-lishibaojing_fill" />
                     <span>报警记录查询</span>
                   </Menu.Item>
                   <Menu.Item key="alarmTimeout">
                     <MyIcon type="icon-lishibaojing_fill" />
                     <span>超时船舶管理</span>
                   </Menu.Item>
                 </Menu>             
                 </div>
                 
                  <div  id='content' >
                    {
                      [REG_PAGES[defaultKey]].map((item,index)=>(
                        <div>

                          {item.content}
                        </div>
                      ))
                    }
                  </div>
         </div>
        )
    }
}
