import React, { Component } from 'react'
import './shipDetail.css'
import {Row,Col,Button,Input,DatePicker} from 'antd'
import moment from 'moment';
import Form from '../../componts/form/Form'
let  format='YYYY-MM-DD HH:mm:ss'
class ShipDetail extends Component {
    state={
        shipData:{},
        modifyData:this.props.modifyData,
    }
    componentWillMount(){
        // const {dispatch,location}=this.props;
 
        // let shipData;
        
        // console.log(location,'location')
        // if(location.state){//判断当前有参数
        //     shipData=location.state;
        //     sessionStorage.setItem('data',JSON.stringify(shipData));// 存入到sessionStorage中
        // }else{
        //     shipData=JSON.parse(sessionStorage.getItem('data'));// 当state没有参数时，取sessionStorage中的参数
        // }
        // this.setState({
        //     modifyData:shipData
        // })
    }
    render() {
        let {shipData,modifyData}=this.state;
        // let data=this.props.location.state
        // console.log(shipData,'shipData')
        const modifyList=[
            {
                label: '基本信息', 
                id: 'divider5', 
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{marginLeft:8}}>基本信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '船名', 
                id: 'shipName',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shipName}
            },
            {
                label: '船主', 
                id: 'owerName',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.owerName}
            },
            {
                label: '船主地址', 
                id: 'owerAddress',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.owerAddress}
            },
            {
                label: '船主电话', 
                id: 'owerPhone',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.owerPhone}
            },
            {
                label: '船主身份证', 
                id: 'owerIdcardNum',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.owerIdcardNum}
            },
            {
                label: '船籍港', 
                id: 'harborName',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.harborName}
            },
            {
                label: '船舶属地', 
                id: 'regionId',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.regionId}
            },
            {
                label: '入库时间', 
                id: 'gmtCreate',
                component: <DatePicker placeholder="暂无信息" format={format}/>,
                options:{initialValue:modifyData&&moment(modifyData.gmtCreate,format)}
            },
            {
                label: '船体信息', 
                id: 'divider6', 
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{marginLeft:8}}>船体信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '总长', 
                id: 'shipTotalLength',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shipTotalLength}
            },
            {
                label: '船舶长度', 
                id: 'shipLength',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shipLength}
            },
            {
                label: '型宽', 
                id: 'shpWidth',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shpWidth}
            },
            {
                label: '型深', 
                id: 'shipDepth',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shipDepth}
            },
            {
                label: '最大吃水深度', 
                id: 'maxDraughtMark',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.maxDraughtMark}
            },
            {
                label: '船体材质', 
                id: 'hullMaterial',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.hullMaterial}
            },
            {
                label: '船舶类型', 
                id: 'shipType',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shipType}
            },
            {
                label: '完工日期', 
                id: 'completionTime',
                component: <DatePicker placeholder="暂无信息" format={format}/>,
                options:{initialValue:modifyData&&moment(modifyData.gmtCreate,format)}
            },
            {
                label: '船舶制造厂', 
                id: 'manufacturer',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.manufacturer}
            },{
                label: '总吨位', 
                id: 'grossTonnage',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.grossTonnage}
            },{
                label: '净吨位', 
                id: 'netTonnage',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.netTonnage}
            },{
                label: '主机总功率', 
                id: 'mainPower',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.mainPower}
            },
            {
                label: '捕捞许可证信息', 
                id: 'divider7', 
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{marginLeft:8}}>捕捞许可证信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '捕捞许可证号', 
                id: 'fishingLicenseNumber',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.fishingLicenseNumber}
            },
            {
                label: '渔船编码', 
                id: 'objectId',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.objectId}
            },
            {
                label: '船舶检验证书', 
                id: 'inspectionCertificate',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.inspectionCertificate}
            },
            {
                label: '国际证书', 
                id: 'nationalityCertificate',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.nationalityCertificate}
            },
            {
                label: '渔具数量', 
                id: 'toolCount',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.toolCount}
            },
            {
                label: '渔具规格', 
                id: 'toolSpecification',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.toolSpecification}
            },
            {
                label: '作业方式/类型', 
                id: 'workMode',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.workMode}
            },
            {
                label: '作业场所', 
                id: 'workPlace',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.workPlace}
            },
            {
                label: '作业时限', 
                id: 'timeLimit',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.timeLimit}
            },
            {
                label: '主要捕捞品种', 
                id: 'fishingVarieties',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.fishingVarieties}
            },
            {
                label: '捕捞限额', 
                id: 'fishingWeightLimit',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.fishingWeightLimit}
            },
            {
                label: '签发人', 
                id: 'fishingLicenseIssuer',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.fishingLicenseIssuer}
            },
            {
                label: '发证机关', 
                id: 'fishingLicenseCertificationUnit',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.fishingLicenseCertificationUnit}
            },
            {
                label: '船舶所有权证书', 
                id: 'ownershipCertificate',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.ownershipCertificate}
            },
            {
                label: '船舶检验证书信息', 
                id: 'divider8', 
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{marginLeft:8}}>船舶检验证书信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '船舶检验证书编号', 
                id: 'inspectionCertificateNum',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.inspectionCertificateNum}
            },
            {
                label: '检验注册码', 
                id: 'inspectionRegNum',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.inspectionRegNum}
            },
            {
                label: '发证机关', 
                id: 'inspectionCertificateIssuer',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.inspectionCertificateIssuer}
            },
            {
                label: '发证日期', 
                id: 'inspectionCertificateIssueDate',
                component: <DatePicker placeholder="暂无信息" format={format}/>,
                options:{initialValue:modifyData&&moment(modifyData.inspectionCertificateIssueDate,format)}
            },
            {
                label: '船舶验船师', 
                id: 'shipSurveyor',
                component: <Input placeholder="暂无信息" />,
                options:{initialValue:modifyData&&modifyData.shipSurveyor}
            },
            {
                label: '下次检验日期', 
                id: 'nextInspectionDate',
                component: <DatePicker placeholder="暂无信息" format={format}/>,
                options:{initialValue:modifyData&&moment(modifyData.nextInspectionDate,format)}
            },
        ]
        // let alldetail=[
        //     {title:'基本信息',shipData:[{name:'船名',value:shipData.owerName},{name:'船主',value:shipData.owerName},{name:'船主地址',value:shipData.owerAddress},{name:'船主电话',value:shipData.owerPhone},{name:'船主身份证',value:shipData.owerIdcardNum},{name:'船籍港',value:shipData.harborName},{name:'船舶属地',value:shipData.regionId},{name:'入库时间',value:data.gmtCreate?data.gmtCreate:''},
        //     ]},
        //     {title:'船体信息',shipData:[{name:'总长',value:shipData.shipTotalLength},{name:'船舶长度',value:shipData.shipLength},{name:'型宽',value:shipData.shpWidth},{name:'型深',value:shipData.shipDepth},{name:'最大吃水深度',value:shipData.maxDraughtMark},{name:'船体材质',value:shipData.hullMaterial},{name:'船舶类型',value:shipData.shipType},{name:'完工日期',value:shipData.completionTime},{name:'船舶制造厂',value:shipData.manufacturer},{name:'总吨位',value:shipData.grossTonnage},{name:'净吨位',value:shipData.netTonnage},{name:'主机总功率',value:shipData.mainPower},]},
            
        //     {title:'捕捞许可证信息',shipData:[{name:'捕捞许可证号',value:shipData.fishingLicenseNumber},{name:'渔船编码',value:shipData.objectId},{name:'船舶检验证书',value:shipData.inspectionCertificate},{name:'国际证书',value:shipData.nationalityCertificate},{name:'渔具数量',value:shipData.toolCount},{name:'渔具规格',value:shipData.toolSpecification},{name:'作业方式/类型',value:shipData.workMode},{name:'作业场所',value:shipData.workPlace},{name:'作业时限',value:shipData.timeLimit},{name:'主要捕捞品种',value:shipData.fishingVarieties},{name:'捕捞限额',value:shipData.fishingWeightLimit},{name:'签发人',value:shipData.fishingLicenseIssuer},{name:'发证机关',value:shipData.fishingLicenseCertificationUnit},{name:'船舶所有权证书',value:shipData.ownershipCertificate},]},
            
        //     {title:'船舶检验证书信息',shipData:[{name:'船舶检验证书编号',value:shipData.inspectionCertificateNum},{name:'检验注册码',value:shipData.inspectionRegNum},{name:'发证机关',value:shipData.inspectionCertificateIssuer},{name:'发证日期',value:shipData.inspectionCertificateIssueDate},{name:'船舶验船师',value:shipData.shipSurveyor},{name:'下次检验日期',value:shipData.nextInspectionDate},]},
            
        // ]
        return (
            <div className="shipDetail">
                {/* <Button className="goBack" onClick={()=>this.props.history.go(-1)}>返回</Button> */}
                <Button className="goBack" onClick={()=>this.props.closePage()}>返回</Button>
                <Form formItemList={modifyList} footCls="footerno"/>
                {/* <div>
                    {
                        alldetail.map((item,index)=>(
                            <Row   key={index} > 
                                <Col span={24}>{item.title}</Col>
                                {
                                    item.shipData.map((val,index)=>(
                                        <Col span={12}  key={index}>
                                            <Row >
                                                <Col span={8} >{val.name}</Col>
                                                <Col span={16}>{val.value}</Col>
                                            </Row>
                                        </Col>
                                    ))
                                }
                            </Row>
                        ))
                    }
                </div> */}
            </div>
        )
    }
}

export default ShipDetail

