import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import { MultiPoint, Point, LineString, Polygon } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from 'ol/style';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import {
  fromLonLat,
  get,
  transform,
  getTransform
} from 'ol/proj.js';
import moment from 'moment'
// showMap_trajectory_points = (points) => { //在地图显示轨迹
//   if (points) {
//   } else {
//       points = [
//           {
//               time: '2020-03-30 10:00:00',
//               lon: 102.245722,
//               lat: 27.865106,
//           },
//           {
//               time: '2020-03-30 10:05:00',
//               lon: 102.245463,
//               lat: 27.788754,
//           },
//           {
//               time: '2020-03-30 10:08:00',
//               lon: 102.381849,
//               lat: 27.808182,
//           },
//           {
//               time: '2020-03-30 10:30:00',
//               lon: 102.352495,
//               lat: 27.876032,
//           },
//       ]
//   }
//   let vectorTrajectory = this.map.layers.vectorTrajectory;
//   TrajectoryAnimation.addTrajectoryAnimation(this.map, vectorTrajectory, points);
// }
let shipImgs = [
  require('../img/ship_blue.png'),
  require('../img/ship_orange.png'),
  require('../img/ship_cyan.png'),
  require('../img/ship_purple.png'),
  require('../img/ship_green.png'),
  require('../img/ship_yellow.png'),
]
let colors = [
  '#0066CC',
  '#FF7F00',
  '#42C9D3',
  '#660066',
  '#009900',
  '#F9E103',
]
let defaultPointImgs = [
  require('../img/point_hollow_blue.png'),
  require('../img/point_hollow_orange.png'),
  require('../img/point_hollow_cyan.png'),
  require('../img/point_hollow_purple.png'),
  require('../img/point_hollow_green.png'),
  require('../img/point_hollow_yellow.png'),
]
let haveEventPointImgs = {
  'red': require('../img/point_solid_red.png'),
  'blue': require('../img/point_solid_blue.png'),
  'green': require('../img/point_solid_green.png'),
  'start': require('../img/point_start.png'),
  'end': require('../img/point_end.png'),
}
let listener_postcomposes = {};
let params = {}
let defaultParams = {}
let isStart = false;
let isOpenShipHeadOverlay = false;
let isFirst = true;
var speed = 40;
var speedDefault = 40;
export default {
  changeTrajectorySpeed(num) {
    speed = speedDefault / num;
  },
  start_TrajectoryAnimation(obj) {
    isStart = true;
    let isAllFinish = true;
    Object.keys(params).map(key => {
      let value = params[key];
      if (!value.isFinish) {
        isAllFinish = false;
      }
    })
    if (isAllFinish) {
      params = defaultParams;
      obj.clearMap_matchWords('_point');
    }
  },
  stop_TrajectoryAnimation(obj) {
    isStart = false;
  },
  openShipHeadOverlay(obj) {
    isOpenShipHeadOverlay = true;
  },
  closeShipHeadOverlay(obj) {
    isOpenShipHeadOverlay = false;
  },
  clear_TrajectoryAnimation(obj) {
    if (listener_postcomposes && Object.keys(listener_postcomposes).length > 0) {
      Object.keys(listener_postcomposes).map(key => {
        unByKey(listener_postcomposes[key]);
      })
      listener_postcomposes = {}
    }
    if (params && Object.keys(params).length > 0) {
      params = {};
    }
    isStart = false;
    isFirst = true;
    speed = 40;
  },
  addTrajectoryAnimation(obj, points, target) {
    // var tileLayer = new TileLayer({
    //   source: new OSM()
    // });

    // var map = new Map({
    //   layers: [tileLayer],
    //   target: 'map',
    //   view: new View({
    //     center: [0, 0],
    //     zoom: 2
    //   })
    // });

    // var imageStyleFinish = new Style({
    //   image: new CircleStyle({
    //     radius: 5,
    //     fill: new Fill({ color: 'yellow' }),
    //     stroke: new Stroke({ color: 'red', width: 1 })
    //   })
    // });
    // var imageStyleStandard = new Style({
    //   image: new CircleStyle({
    //     radius: 5,
    //     fill: new Fill({ color: 'grey' }),
    //     stroke: new Stroke({ color: 'grey', width: 1 })
    //   })
    // });

    // var headInnerImageStyle = new Style({
    //   image: new CircleStyle({
    //     radius: 2,
    //     fill: new Fill({ color: 'blue' })
    //   })
    // });

    // var headOuterImageStyle = new Style({
    //   image: new CircleStyle({
    //     radius: 5,
    //     fill: new Fill({ color: 'black' }),
    //     // src: shipImg,
    //     // anchor: [0.75, 0.5],
    //     // rotateWithView: true,
    //     // rotation: -rotation
    //   })
    // });
    // function patchPointsAllFun() {
    //   if (!points) {
    //     return [];
    //   }
    //   let coordinatesAfterPathAll = [];
    //   for (let index = 0, len = points.length; index < (len - 1); index++) {
    //     const pointStart = points[index];
    //     const pointEnd = points[index + 1];
    //     let patchPoints = patchPoint(pointStart, pointEnd);
    //     coordinatesAfterPathAll = [
    //       ...coordinatesAfterPathAll,
    //       ...patchPoints,
    //     ]
    //   }
    //   return coordinatesAfterPathAll;
    // }
    function patchPoint(pointStart, pointEnd, indexNum) {
      console.log(pointStart, pointEnd, '-------------------patchPoint-------------------')
      let patchPointConstant = 10000;//ms
      // let timeStart = moment(pointStart.time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      // let timeEnd = moment(pointEnd.time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      // let timeInterval = timeEnd - timeStart;//两点间时间间隔
      // let patchPointCount = timeInterval / patchPointConstant;//需要补点的数量
      let patchPointCount = speed;//需要补点的数量
      let patchPointInterval = (pointEnd[0] - pointStart[0]) / patchPointCount;//补点间的经度间隔
      let k = pointEnd[0] == pointStart[0] ? 1 : (pointEnd[1] - pointStart[1]) / (pointEnd[0] - pointStart[0]);//两边斜比率
      let pointsAfterPatch = [pointStart];
      let coordinatesAfterPath = [
        // transform([pointStart.lon, pointStart.lat], 'EPSG:4326', 'EPSG:3857'),3655828.921188992
      ]
      for (let index = 0; index < patchPointCount; index++) {
        let x = pointStart[0] + patchPointInterval * (index + 1);
        let y = k * (x - pointEnd[0]) + pointEnd[1];
        // let time = moment(timeStart + patchPointConstant * (index + 1)).format('YYYY-MM-DD HH:mm:ss');
        pointsAfterPatch = [
          ...pointsAfterPatch,
          {
            // time: time,
            lon: x,
            lat: y,
          }
        ]
        coordinatesAfterPath = [
          ...coordinatesAfterPath,
          // transform([x, y], 'EPSG:4326', 'EPSG:3857'),
          [x, y]
        ]
      }
      // pointsAfterPatch = [
      //   ...pointsAfterPatch,
      //   transform([pointEnd.lon, pointEnd.lat], 'EPSG:4326', 'EPSG:3857'), ,
      // ]
      // return coordinatesAfterPath.slice(0, indexNum - 1);
      return coordinatesAfterPath.slice(0, indexNum - 1);
    }
    function createCoordinatesPoints(points) {
      let coordinatesStops = [];
      points.map((item, index) => {
        coordinatesStops = [
          ...coordinatesStops,
          transform(
            [item.lon, item.lat],
            'EPSG:4326', 'EPSG:3857'),
        ]
        item.coordinate = transform([item.lon, item.lat], 'EPSG:4326', 'EPSG:3857');

      })
      return points;
    };
    function createStops(points) {
      let coordinatesStops = [];
      points.map((item, index) => {
        coordinatesStops = [
          ...coordinatesStops,
          transform(
            [item.lon, item.lat],
            'EPSG:4326', 'EPSG:3857'),
        ]
        item.coordinate = [
          transform(
            [item.lon, item.lat],
            'EPSG:4326', 'EPSG:3857'),
        ]
      })
      return coordinatesStops;
    };
    addAndInitNewTrajectory(points, target);
    function addAndInitNewTrajectory(points, target) {
      let coordinatesAllPoints = createCoordinatesPoints(points);
      let coordinatesAll = createStops(points);
      let indexNow = Object.keys(params).length % 6;
      params = {//新增轨迹，添加并初始化参数库
        ...params,
        [target]: {
          isFinish: false,
          indexFlag: 0,//当前节点数索引
          coordinatesAllPoints: coordinatesAllPoints,
          coordinatesAll: coordinatesAll,
          coordinatesAfterPathAllFinish_points: [coordinatesAllPoints[0]],
          coordinatesAfterPathAllFinish_addPatch_points: [coordinatesAllPoints[0]],
          coordinatesAfterPathAllFinish: [coordinatesAll[0]],
          coordinatesAfterPathAllFinish_addPatch: [coordinatesAll[0]],
          coordinatesAfterPathAllStandard: coordinatesAll,
          indexNum: 1,//当前节点内补点索引
          progress: 0,
          pointStart: coordinatesAll[0],
          pointEnd: coordinatesAll[1],
          shipImg: shipImgs[indexNow],
          color: colors[indexNow],
          defaultPointImg: defaultPointImgs[indexNow],
        }
      }
      defaultParams = {
        ...defaultParams,
        [target]: {
          isFinish: false,
          indexFlag: 0,//当前节点数索引
          coordinatesAllPoints: coordinatesAllPoints,
          coordinatesAll: coordinatesAll,
          coordinatesAfterPathAllFinish_points: [coordinatesAllPoints[0]],
          coordinatesAfterPathAllFinish_addPatch_points: [coordinatesAllPoints[0]],
          coordinatesAfterPathAllFinish: [coordinatesAll[0]],
          coordinatesAfterPathAllFinish_addPatch: [coordinatesAll[0]],
          coordinatesAfterPathAllStandard: coordinatesAll,
          indexNum: 1,//当前节点内补点索引
          progress: 0,
          pointStart: coordinatesAll[0],
          pointEnd: coordinatesAll[1],
          shipImg: shipImgs[indexNow],
          color: colors[indexNow],
          defaultPointImg: defaultPointImgs[indexNow],
        }
      }
      // let div = document.createElement("div")
      // let overlay = new Overlay({
      //   id: target,
      //   //设置弹出框的容器
      //   element: div,
      //   //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
      //   autoPan: true,
      //   visible: true,
      // });
      // obj.map.map.addOverlay(overlay);
      // map.overlay[target] = overlay;
    }

    //已知直线方向两点和两条骨架的长度，求方向三角形的三个顶点
    function getPoint_twoPointAndLengths(pointCenter, len) {
      let pointCenter_EPSG4326 = transform([pointCenter.lon, pointCenter.lat], 'EPSG:3857', 'EPSG:4326');
      pointCenter = { lon: parseFloat(pointCenter_EPSG4326[0]), lat: parseFloat(pointCenter_EPSG4326[1]) }

      // let pointE_EPSG4326 = transform([pointE.lon, pointE.lat], 'EPSG:3857', 'EPSG:4326');
      // pointE = { lon: parseFloat(pointE_EPSG4326[0].toFixed(5)), lat: parseFloat(pointE_EPSG4326[1].toFixed(5)) }

      let pointOne = {};
      let pointTwo = {};
      let pointThree = {};
      let pointFour = {};

      pointOne.lon = pointCenter.lon;
      pointFour.lon = pointCenter.lon;

      pointTwo.lon = pointCenter.lon + len * 0.5;
      pointThree.lon = pointCenter.lon - len * 0.5;

      pointOne.lat = pointCenter.lat + len;
      pointFour.lat = pointCenter.lat - len;


      pointTwo.lat = pointFour.lat;
      pointThree.lat = pointFour.lat;
      // }
      // else if (pointE.lat == pointCenter.lat) {
      //   pointOne.lat = pointCenter.lat;
      //   pointFour.lat = pointCenter.lat;

      //   pointTwo.lat = pointCenter.lat + len;
      //   pointThree.lat = pointCenter.lat - len;

      //   if (pointE.lon > pointCenter.lon) {//方向向右
      //     pointOne.lon = pointCenter.lon + len;
      //     pointFour.lon = pointCenter.lon - len;
      //   } else {
      //     pointOne.lon = pointCenter.lon - len;
      //     pointFour.lon = pointCenter.lon + len;
      //   }

      //   pointTwo.lon = pointFour.lon;
      //   pointThree.lon = pointFour.lon;

      // } else {
      //   let k_SE = parseFloat(((pointE.lat - pointCenter.lat) / (pointE.lon - pointCenter.lon)).toFixed(5));//获取轨迹直线的斜率
      //   let k_SE_vertical = parseFloat((-1 / k_SE).toFixed(5));//获取轨迹直线垂直的底边斜率;
      //   let direction = 'left';
      //   if (pointE.lon - pointCenter.lon > 0) {
      //     direction = 'right';
      //   }
      //   if (direction == 'left') {
      //     pointOne.lon = pointCenter.lon - len;
      //     pointOne.lat = k_SE * (pointOne.lon - pointCenter.lon) + pointCenter.lat;

      //     pointFour.lon = pointOne.lon + len * 2;
      //     pointFour.lat = k_SE * (pointFour.lon - pointOne.lon) + pointOne.lat;

      //     pointTwo.lon = pointFour.lon + len;
      //     pointTwo.lat = k_SE_vertical * (pointTwo.lon - pointFour.lon) + pointFour.lat;

      //     pointThree.lon = pointFour.lon - len;
      //     pointThree.lat = k_SE_vertical * (pointThree.lon - pointFour.lon) + pointFour.lat;


      //   } else {
      //     pointOne.lon = pointCenter.lon + len;
      //     pointOne.lat = k_SE * (pointOne.lon - pointCenter.lon) + pointCenter.lat;

      //     pointFour.lon = pointOne.lon - len * 2;
      //     pointFour.lat = k_SE * (pointFour.lon - pointOne.lon) + pointOne.lat;

      //     pointTwo.lon = pointFour.lon + len;
      //     pointTwo.lat = k_SE_vertical * (pointTwo.lon - pointFour.lon) + pointFour.lat;

      //     pointThree.lon = pointFour.lon - len;
      //     pointThree.lat = k_SE_vertical * (pointThree.lon - pointFour.lon) + pointFour.lat;
      //   }
      //   let demoK = parseFloat(((pointOne.lat-pointFour.lat)/(pointOne.lon-pointFour.lon)).toFixed(5));
      //   if (demoK == k_SE) {
      //     console.log(demoK)
      //   }

      // }

      let data = [[
        [pointOne.lon, pointOne.lat],
        [pointTwo.lon, pointTwo.lat],
        [pointThree.lon, pointThree.lat],
        [pointOne.lon, pointOne.lat],
      ]]
      return data;
    }

    function setAngle(first, second) {
      let y = second[1] - first[1];
      let x = second[0] - first[0];
      let radAngle = Math.atan2(y, x);
      // if (y <= 0 && x >= 0) {//第二象限
      //   radAngle = 90+180 * (-radAngle) / Math.PI;
      //   //console.log('第二象限');
      // } else if (x >= 0 && y >= 0) {//第一象限
      //   radAngle = 90-180 * radAngle / Math.PI;
      //   //console.log('第一象限');
      // } else if (x <= 0 && y >= 0) {//第四象限
      //   radAngle = 360-180 * radAngle / Math.PI;
      //   //console.log('第四象限');
      // } else if (x <= 0 && y <= 0) {//第三象限
      //   radAngle = 90+180 * (-radAngle) / Math.PI;
      //   //console.log('第三象限');
      // }
      //   if (y <= 0 && x >= 0) {//第二象限
      //     radAngle = -radAngle;
      //     //console.log('第二象限');
      // } else if (x >= 0 && y >= 0) {//第一象限
      //     radAngle = -radAngle;
      //     //console.log('第一象限');
      // } else if (x <= 0 && y >= 0) {//第四象限
      //     radAngle =  -radAngle;
      //     //console.log('第四象限');
      // } else if (x <= 0 && y <= 0) {//第三象限
      //     radAngle =  - radAngle;
      //     //console.log('第三象限');
      // }
      return -radAngle;
    }

    function getShipPolygon(coordinate, pointInfo) {
      let len = 0.00008;
      let dataThreePoint = getPoint_twoPointAndLengths(
        {
          lon: coordinate[0],
          lat: coordinate[1]
        },
        len);
      let trajectory_polygon = new Polygon(dataThreePoint);
      trajectory_polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
      trajectory_polygon.rotate(String((360 - pointInfo.heading) * Math.PI / 180), coordinate);
      let featurePolygon = new Feature({
        geometry: trajectory_polygon,
        ...pointInfo,
        isClickPoint: true,
        isFeature: true,
      });
      featurePolygon.setStyle(new Style({ //默认样式,Object.assign合并对象
        fill: new Fill({
          color: 'rgba(255,69,0, 0.5)',
        }),
        stroke: new Stroke({
          color: 'rgba(255,69,0, 1)',
          width: 2
        }),
      }))
      return featurePolygon;
    }

    function animate(event, target) {
      var vectorContext = event.vectorContext;
      // var vectorContext = getVectorContext(event);

      let data = params[target];

      if (data) {
        if (data.progress % speed == 0 && data.coordinatesAllPoints.length > 1 && isStart) {
          data.indexNum = 1;
          data.indexFlag += 1;
          data.progress = 0;
          //临时
          data.coordinatesAfterPathAllFinish_points = data.coordinatesAllPoints.slice(0, data.indexFlag);
          data.coordinatesAfterPathAllFinish = data.coordinatesAll.slice(0, data.indexFlag);
          data.coordinatesAfterPathAllStandard = [];


          //通过当前节点+下一个节点计算出，节点间运行补点，按照自增长索引加载补点
          data.pointStart = data.coordinatesAll[data.indexFlag - 1];
          data.pointEnd = data.coordinatesAll[data.indexFlag];

          console.log(data.pointStart, data.pointEnd, data.indexFlag, data, '--------------data.pointStart, data.pointEnd-----------')
          //画轨迹点


          let trajectoryPointInfo = data.coordinatesAfterPathAllFinish[data.coordinatesAfterPathAllFinish.length - 1];
          var trajectoryPoint = new Point(trajectoryPointInfo);
          let lastPoint = data.coordinatesAfterPathAllFinish_points[data.coordinatesAfterPathAllFinish_points.length - 1];
          let iconImg = data.defaultPointImg;
          switch (lastPoint.eventType) {
            case 0: iconImg = data.defaultPointImg; break;
            case 302262: iconImg = haveEventPointImgs['blue']; break;
            case 302263: iconImg = haveEventPointImgs['green']; break;
            default: iconImg = haveEventPointImgs['red']; break;
            // case 1: iconImg = cameraRunningImg; break
          }
          let feature_trajectoryPoint = new Feature({
            geometry: trajectoryPoint,
            ...lastPoint,
            isClickPoint: true,
          })
          var rotation = (lastPoint.heading - 90) * Math.PI / 180;
          if (data.pointStart && data.pointEnd) {
            rotation = setAngle(data.pointStart, data.pointEnd);
            if (rotation == 0) {
              //console.log('------------rotation==0---------')
            }
          }
          let anchor = [0.5, 0.5];
          if (data.coordinatesAfterPathAllFinish.length == 1) {
            iconImg = haveEventPointImgs['start'];
            rotation = 0;
            anchor = [0.5, 1]
          } else if (data.indexFlag == data.coordinatesAll.length - 1) {
            iconImg = haveEventPointImgs['end'];
            rotation = 0;
            anchor = [0.5, 1]
          }
          feature_trajectoryPoint.setStyle(new Style({
            image: new Icon({
              radius: 5,
              // fill: new Fill({ color: 'black' }),
              src: iconImg,
              anchor: anchor,
              rotateWithView: true,
              rotation: String(rotation),
            })
          }));
          //
          let featurePolygon = getShipPolygon(data.pointStart, lastPoint);
          obj.showMap_addPoint(
            // featurePolygon,
            feature_trajectoryPoint, //测试后打开
            {
              target: target + '_point',
              zIndex: 113,
            });

          //   // // 最后一个标记点的坐标
          //   // var lngX = coordinatesAfterPathAllFinish[coordinatesAfterPathAllFinish.length-1][0];
          //   // var lngY = coordinatesAfterPathAllFinish[coordinatesAfterPathAllFinish.length-1][1];

          //   // var interval = setInterval(function () {
          //   //   // lngX = lngX - Math.random() * 30;
          //   //   // lngY = lngY + Math.random() * 30;
          //   //   var newPoint = [lngX, lngY];
          //   //   coordinatesAfterPathAllFinish.shift();
          //   //   coordinatesAfterPathAllFinish.push(newPoint);
          //   //   geometryT.setCoordinates(coordinatesAfterPathAllFinish);
          //   // }, 3000);

          //   // setTimeout(function () {
          //   //   clearInterval(interval);
          //   // }, 5000);
          //   // for (i = 0; i < n; ++i) {
          //   //   var t = theta + 2 * Math.PI * i / n;
          //   //   var x = (R + r) * Math.cos(t) + p * Math.cos((R + r) * t / r);
          //   //   var y = (R + r) * Math.sin(t) + p * Math.sin((R + r) * t / r);
          //   //   let lonlatMax = transform([x,y], 'EPSG:3857', 'EPSG:4326')
          //   //   coordinates.push([x, y]);
          //   // }

          //   // vectorContext.setStyle(imageStyleStandard);
          //   // vectorContext.drawGeometry(new MultiPoint(coordinatesAfterPathAllStandard));
          // vectorContext.setStyle(imageStyleFinish);
          // vectorContext.drawGeometry(new MultiPoint(coordinatesAfterPathAllFinish));
          // // vectorContext.drawLineString(geometryF);
        }

        if (!isStart) {
          data.coordinatesAfterPathAllStandard = data.coordinatesAll;
          let layer = obj.map.layers[target + '_pointAll'];
          if (layer && layer.getSource().getFeatures().length > 0) {
            layer.setVisible(true);
          } else {
            let featuresAll = [];
            let startPoint = data.coordinatesAllPoints[0];
            let endPoint = data.coordinatesAllPoints[data.coordinatesAllPoints.length - 1];
            let centerPoints = data.coordinatesAllPoints.slice(1, data.coordinatesAllPoints.length - 2);
            centerPoints = centerPoints.concat(startPoint);
            centerPoints = centerPoints.concat(endPoint);
            let textStart = '起点';
            let textEnd = '终点';
            centerPoints.map((item, index) => {
              let itemNext;
              let rotationStop = (item.heading - 90) * Math.PI / 180;
              if (index < (centerPoints.length - 1)) {
                itemNext = centerPoints[index + 1];
                rotationStop = setAngle([item.lon, item.lat], [itemNext.lon, itemNext.lat]);
              }
              var trajectoryPoint = new Point(item.coordinate[0]);
              let iconImg = data.defaultPointImg;
              switch (item.eventType) {
                case 0: iconImg = data.defaultPointImg; break;
                case 302262: iconImg = haveEventPointImgs['blue']; break;
                case 302263: iconImg = haveEventPointImgs['green']; break;
                default: iconImg = haveEventPointImgs['red']; break;
                // case 1: iconImg = cameraRunningImg; break
              }
              // if (index == centerPoints.length - 2 || index == centerPoints.length - 1) {
              let feature_trajectoryPoint = new Feature({
                geometry: trajectoryPoint,
                ...item,
                isClickPoint: true,
                isFeature: true,
              })
              let anchor = [0.5, 0.5];
              if (index == centerPoints.length - 2) {
                iconImg = haveEventPointImgs['start'];
                rotationStop = 0;
                anchor = [0.5, 1];
              } else if (index == centerPoints.length - 1) {
                iconImg = haveEventPointImgs['end'];
                rotationStop = 0;
                anchor = [0.5, 1];
              }
              feature_trajectoryPoint.setStyle(new Style({
                image: new Icon({
                  radius: 5,
                  // fill: new Fill({ color: 'black' }),
                  src: iconImg,
                  anchor: anchor,
                  rotateWithView: true,
                  rotation: rotationStop
                })
              }));
              featuresAll = featuresAll.concat(feature_trajectoryPoint);
              // } else {
              //   featuresAll = featuresAll.concat(getShipPolygon(item.coordinate[0], item));
              // }
            })
            obj.showMap_addPoints(featuresAll, {
              target: target + '_pointAll',
              zIndex: 114,
            });
          }
        } else {
          obj.map.layers[target + '_pointAll'].setVisible(false);
        }

        data.coordinatesAfterPathAllFinish_addPatch = [
          ...data.coordinatesAfterPathAllFinish,
        ];
        if (isStart && data.pointStart && data.pointEnd) {
          let pointSupply = patchPoint(data.pointStart, data.pointEnd, data.indexNum);
          data.coordinatesAfterPathAllFinish_addPatch = [
            ...data.coordinatesAfterPathAllFinish,
            ...pointSupply,
          ];
        }
        //实例一个线(标记点)的全局变量
        var geometryF; //线,Point 点,Polygon 面
        var geometryS; //线,Point 点,Polygon 面
        var geometryP; //线,Point 点,Polygon 面

        //   // //添加标记点
        function addPonitToGeometry(arrF, arrS, arrP) {
          geometryF = new LineString(arrF);
          geometryS = new LineString(arrS);
          geometryP = new LineString(arrP);
          // for (var i = 0; i < arr.length; i++) {
          //   geometryT.appendCoordinate(arr[i]);
          // }
        }
        if (data.coordinatesAfterPathAllFinish_addPatch.length > 0) {
          addPonitToGeometry(data.coordinatesAfterPathAllFinish, data.coordinatesAfterPathAllStandard, data.coordinatesAfterPathAllFinish_addPatch);

          var featureLine_finish = new Feature(geometryF); //绘制线的数据
          var featureLine_standard = new Feature(geometryS); //绘制线的数据
          var featureLine_patch = new Feature(geometryP); //绘制线的数据
          // vectorTrajectory.getSource().clear();
          //设置样式
          function getStyleF() {
            var style = new Style({
              fill: new Fill({//填充样式
                color: data.color,
                width: 5,
              }),
              stroke: new Stroke({//边框样式
                color: data.color,
                width: 5,
                lineCap: 'square'
              }),
              image: new CircleStyle({//点样式使用一个圆
                radius: 7,
                fill: new Fill({
                  color: data.color
                })
              })
            });
            return style;
          }
          function getStyleS() {
            var style = new Style({
              fill: new Fill({//填充样式
                color: 'gray'
              }),
              stroke: new Stroke({//边框样式
                color: 'gray',
                width: 1,
                lineCap: 'square'
              }),
              image: new CircleStyle({//点样式使用一个圆
                radius: 7,
                fill: new Fill({
                  color: 'gray'
                })
              })
            });
            return style;
          }
          featureLine_finish.setStyle(getStyleF());
          featureLine_patch.setStyle(getStyleF());
          featureLine_standard.setStyle(getStyleS());
          // vectorTrajectory.setStyle(getStyle())
          //添加轨迹
          // vectorTrajectory.getSource().addFeature(featureLine_standard);
          // vectorTrajectory.getSource().addFeature(featureLine_finish);

          // vectorContext.setStyle(getStyleS());
          // vectorContext.drawLineString(geometryS);
          // vectorContext.setStyle(getStyleF());
          // vectorContext.drawLineString(geometryF);
          // vectorContext.setStyle(getStyleF());
          // vectorContext.drawLineString(geometryP);

          // vectorContext.setStyle(imageStyleFinish);
          // vectorContext.drawGeometry(new MultiPoint(coordinatesAfterPathAllFinish));
          let headPointInfo = data.coordinatesAfterPathAllFinish_addPatch[data.coordinatesAfterPathAllFinish_addPatch.length - 1];
          var headPoint = new Point(headPointInfo);
          var rotation = -90 * Math.PI / 180;
          if (data.coordinatesAfterPathAllFinish_addPatch.length > 1) {
            let headPointInfo_point_end = data.coordinatesAfterPathAllFinish_addPatch[data.coordinatesAfterPathAllFinish_addPatch.length - 1];
            let headPointInfo_point_start = data.coordinatesAfterPathAllFinish_addPatch[data.coordinatesAfterPathAllFinish_addPatch.length - 2];
            // var rotation = setAngle(transform(headPointInfo_point_start.coordinate[0], 'EPSG:3857', 'EPSG:4326'), transform(headPointInfo_point_end.coordinate[0], 'EPSG:3857', 'EPSG:4326'));
            // var rotation = setAngle(headPointInfo_point_start.coordinate[0],headPointInfo_point_end.coordinate[0]);
            if (headPointInfo_point_start && headPointInfo_point_end) {
              rotation = setAngle(headPointInfo_point_start, headPointInfo_point_end);
              if (rotation == 0) {
                //console.log('------------rotation==0---------')
              }
            } else {
              //console.log(headPointInfo_point_start, headPointInfo_point_end, '------------------headPointInfo_point_start,headPointInfo_point_end-------------------')
            }
          } else {
            //console.log(data.coordinatesAfterPathAllFinish_addPatch, '------------------data.coordinatesAfterPathAllFinish_points-------------------')
          }
          function getStyleHead() {
            let style = new Style({
              image: new Icon({
                radius: 5,
                // fill: new Fill({ color: 'black' }),
                src: data.shipImg,
                // anchor: [0.75, 0.5],
                rotateWithView: true,
                rotation: String(rotation),
              })
            });
            return style;
          }
          let styleHead = getStyleHead();
          let featureHead = new Feature({
            geometry: headPoint,
            id: target,
          })
          featureHead.setStyle(styleHead);


          obj.showMap_addFeature_Line(featureLine_finish, target + '_Line_finish');
          obj.showMap_addFeature_Line(featureLine_standard, target + '_Line_standard');
          obj.showMap_addFeature_Line(featureLine_patch, target + '_Line_patch');
          obj.showMap_addPoints_head(featureHead, target + '_head');//测试后打开
          // obj.showMap_addPoints(data.coordinatesAfterPathAllFinish_points, target, data.defaultPointImg);

          let data_Overlay_head = data.coordinatesAfterPathAllFinish_points[data.coordinatesAfterPathAllFinish_points.length - 1];
          let coodinate_Overlay_head = headPointInfo;
          if (isOpenShipHeadOverlay) {
            addOverlay_setPosition(data_Overlay_head, coodinate_Overlay_head, obj);
          }
          function addOverlay_setPosition(data, coodinate, obj) {
            // let closeImg = `<img id='closeOverlay' src=${require('../../../../img/close.png')} class='jk' alt='' style='margin-left: 230px;' />`
            // let closeImg = document.createElement("img")
            //给按钮添加单击事件
            // closeImg.setAttribute("id", "closeOverlay")
            // closeImg.setAttribute("src", `${require('../../../img/close.png')}`)
            // closeImg.setAttribute("style", "margin-left: 265px;position:absolute;margin-top:-390px;z-index: 999;")
            // closeImg.setAttribute("style", "margin-left: 265px")
            let div = document.createElement("div");
            obj.map.removeOverlay(data.objectId);
            let overlay = new Overlay({
              id: data.objectId,
              //设置弹出框的容器
              element: div,
              positioning: 'bottom-center'
              //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
              // autoPan: true,
            });
            div.setAttribute("style", { "display": "block", "padding": "0.6vh" });
            div.setAttribute("id", "div_trajectory_head_overlay_" + data.objectId);
            div.innerHTML = ""
            // let divMask = document.createElement("div");
            // divMask.setAttribute("style", "position:absolute;top:0;bottom:0;right:0;left:0;background:rgba(0,0,0,0.6)")
            // divMask.onclick = function () {
            //     divMask.setAttribute("style", "display:none")
            // }
            // let divModal = document.createElement("div");
            // divModal.setAttribute("style", "width:600px;height:400px;margin:0 auto;margin-top:30px;z-index:99999")
            // divMask.appendChild(divModal)
            // let p_reportPicture = document.createElement("p");
            // p_reportPicture.innerHTML = p_reportPicture.innerHTML + "船舶名字 :"
            // for (let index = 0, len = data.reportPictureList.length; index < len; index++) {
            //     const element = data.reportPictureList[index];
            //     if (element && element != null) {
            //         let img_reportPicture = document.createElement("img");
            //         img_reportPicture.setAttribute("width", "50");
            //         img_reportPicture.setAttribute("height", "50");
            //         img_reportPicture.setAttribute("src", element);
            //         p_reportPicture.appendChild(img_reportPicture)
            //         img_reportPicture.onclick = function () {
            //             //console.log(element, '-------------------------img_reportPicture')
            //             obj.showModal(element, data.reportPictureList)
            //         }
            //     }
            // }
            // let p_rectPicture = document.createElement("p");
            // p_rectPicture.innerHTML = p_rectPicture.innerHTML + "整改照片 :"
            // for (let index = 0, len = data.rectPictureList.length; index < len; index++) {
            //     const element = data.rectPictureList[index];
            //     if (element && element != null) {
            //         let img_rectPicture = document.createElement("img");
            //         img_rectPicture.setAttribute("width", "50");
            //         img_rectPicture.setAttribute("height", "50");
            //         img_rectPicture.setAttribute("src", element);
            //         p_rectPicture.appendChild(img_rectPicture)
            //         img_rectPicture.onclick = function () {
            //             obj.showModal(element, data.rectPictureList)
            //         }
            //     }
            // }

            let p_name = document.createElement("h3");
            p_name.innerHTML = p_name.innerHTML + ((data.objectName && data.objectName != null) ? data.objectName : '');

            let p_time = document.createElement("p");
            p_time.innerHTML = p_time.innerHTML + '时间: ' + ((data.locationTime && data.locationTime != null) ? data.locationTime : '');

            let p_speed = document.createElement("p");
            p_speed.innerHTML = p_speed.innerHTML + '速度: ' + ((data.speed && data.speed != null) ? data.speed + ' km/h' : '0' + ' km/h');

            let p_lon = document.createElement("p");
            let p_lat = document.createElement("p");
            let lonlat = transform(coodinate, 'EPSG:3857', 'EPSG:4326');
            lonlat = [
              lonlat[0].toFixed(8),
              lonlat[1].toFixed(8),
            ]
            p_lon.innerHTML = p_lon.innerHTML + '经度: ' + ((lonlat[0] && lonlat[0] != null) ? ToDegrees(lonlat[0], 'lon') : '');
            p_lat.innerHTML = p_lat.innerHTML + '纬度: ' + ((lonlat[1] && lonlat[1] != null) ? ToDegrees(lonlat[1], 'lat') : '');

            let divLevel2 = document.createElement("div");
            divLevel2.setAttribute("style",
              "margin-left: 10%; " +
              "position: absolute;" +
              // "-webkit-transform: translateY(-50%);" +
              // "-ms-transform: translateY(-50 %);" +
              // "-o-transform: translateY(-50 %);" +
              // "transform: translateY(-50 %);"
              "top: 5%;"
            )
            divLevel2.appendChild(p_name)
            divLevel2.appendChild(p_time)
            // divLevel2.appendChild(p_lon)
            // divLevel2.appendChild(p_lat)
            divLevel2.appendChild(p_speed)

            let divLevel1 = document.createElement("div");
            divLevel1.setAttribute("style",
              "position: relative;" +
              "text-align:left;" +
              "margin:0 auto;" +
              "filter: alpha(opacity = 80);" +
              "-moz-opacity: 0.80;" +
              "color:black;" +
              "border-radius: 5%;" +
              "background: rgba(255, 255, 255, 0.8); " +
              "min-width:10vw;" +
              // "min-height:10vh;" +
              "overflow: auto;" +
              "font-size: 14px;"
            )
            divLevel1.appendChild(divLevel2);
            div.appendChild(divLevel1)
            //设置overlay的显示位置
            // div.appendChild(closeImg)
            // closeImg.onclick = function () {
            //     //.log("关掉Overlayer")
            //     //1.创建节点
            //     // div.remove()
            //     div.setAttribute("style", "display:none")
            // }
            // var img = document.getElementById('closeOverlay');
            overlay.setPosition(coodinate);//显示overlay
            obj.map.map.addOverlay(overlay);
            obj.map.overlay[data.objectId] = overlay;
          }
          // vectorContext.setStyle(headInnerImageStyle);
          // vectorContext.drawGeometry(headPoint);
        } else {
        }
        if (data.indexFlag == (data.coordinatesAll.length - 1)) {
          data.indexFlag = 0;
          data.progress = 0;
          data.indexNum = 1;
          // coordinatesAfterPathAllFinish = [];
          // obj.map.map.un('postcompose', function (event) { });
          // unByKey(listener_postcomposes[target]);
          data.isFinish = true;
          let isAllFinish = true;
          Object.keys(params).map(key => {
            let value = params[key];
            if (!value.isFinish) {
              isAllFinish = false;
            }
          })
          if (isAllFinish) {
            obj.stop_trajectory_points();
          }
        } else {
          // var interval = setInterval(function () {
          // indexFlag++;
          // }, 3000);
          // setTimeout(function () {
          //   clearInterval(interval);
          // }, 3100);
          data.progress += 1;
          if (isStart) {
            obj.map.map.render();
            data.indexNum++;
          }
        }
      }
    }
    let listener_postcompose = obj.map.map.on('postrender', e => animate(e, target))
    listener_postcomposes = {
      [target]: listener_postcompose,
    }
    obj.map.map.render();
  }
}

//度°分′秒″转度
function ToDigital(strDu, strFen, strMiao, len) {
  len = (len > 6 || typeof (len) == "undefined") ? 6 : len;//精确到小数点后最多六位   
  strDu = (typeof (strDu) == "undefined" || strDu == "") ? 0 : parseFloat(strDu);
  strFen = (typeof (strFen) == "undefined" || strFen == "") ? 0 : parseFloat(strFen) / 60;
  strMiao = (typeof (strMiao) == "undefined" || strMiao == "") ? 0 : parseFloat(strMiao) / 3600;
  var digital = strDu + strFen + strMiao;
  if (digital == 0) {
    return "";
  } else {
    return digital.toFixed(len);
  }

}
//度转度°分′秒″
function ToDegrees(val, type) {
  if (typeof (val) == "undefined" || val == "") {
    return "";
  }
  val = String(val);
  var i = val.indexOf('.');
  var strDu = i < 0 ? val : val.substring(0, i);//获取度
  var strFen = 0;
  var strMiao = 0;
  if (i > 0) {
    var strFen = "0" + val.substring(i);
    strFen = strFen * 60 + "";
    i = strFen.indexOf('.');
    if (i > 0) {
      strMiao = "0" + strFen.substring(i);
      strFen = strFen.substring(0, i);//获取分
      strMiao = strMiao * 60 + "";
      i = strMiao.indexOf('.');
      strMiao = strMiao.substring(0, i + 4);//取到小数点后面三位
      strMiao = parseFloat(strMiao).toFixed(2);//精确小数点后面两位
    }
  }
  let result = '';
  switch (type) {
    case 'lon':
      result = strDu + "°" + strFen + "′" + strMiao + '″E';
      break;
    case 'lat':
      result = strDu + "°" + strFen + "′" + strMiao + '″N';
      break;

    default:
      break;
  }
  return result;
}