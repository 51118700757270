//进出港统计报表
import React, { Component } from 'react'
import { Table, Radio, Divider, Input, Menu, Icon, Button, Select, DatePicker, Pagination } from "antd";
import moment from 'moment';
import './statistics.css'
import FuzzySearch from '../../componts/FuzzySearch/FuzzySearch'
import { leaveAndArrivalStatistics, getArgs } from '../../data/dataStore';
import { exportExcel } from 'xlsx-oc';
const { RangePicker } = DatePicker;
const date = new Date();
const { Search } = Input;
const { Option } = Select;
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
const dayList1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
function debounce(fn, ms = 500) {
  let timeoutId
  return function () {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
    }, ms)
  }
}
function onChange2(pageNumber) {
  console.log('Page: ', pageNumber);
}
function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

export default class VoyageNum extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // visible: false,
      startTime: dayList1,//默认开始时间是今天零点
      endTime: dayList,//默认开始时间是今天23点
      pageSize: 100,
      pageIndex: 0,
      ulArr: false,
      dataSource: [],
      pageTotal: null,
      ulArr: [],
      pagination11: {
        total: 0,
        current: 1,
        pageSize: 10000,
        showQuickJumper: true
      },
      pagination:{
        defaultPageSize: 10,
        showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
        // showSizeChanger: true,
        showQuickJumper: true,
      },
      loading: false,
      searchValue: ''
    }
    this.leaveAndArrivalStatistics = this.leaveAndArrivalStatistics.bind(this)
  }
  componentDidMount() {
    this.leaveAndArrivalStatistics();
    this.getArgs();
    let dictData = JSON.parse(sessionStorage.getItem("dictData"))
    let shipTypeArr = []
    let eventTypeArr = []
    dictData.forEach((item) => {
      if (item.category === "ship_type") {
        shipTypeArr.push({
          text: item.typeName,
          value: item.typeCode
        })
      }
    })
    dictData.forEach((item) => {
      if (item.category === "event_type") {
        eventTypeArr.push({
          text: item.typeName,
          value: item.typeCode
        })
      }
    })
    this.setState({
      shipTypeArr,
      eventTypeArr
    })
  };
  exportDefaultExcel = (e, coloums) => {
    let dockInfoData = this.state.dataSource;
    if(dockInfoData){
      dockInfoData.map((items)=>{
        if (this.state.shipTypeArr) {
          this.state.shipTypeArr.map((item) => {
            if (item.value == items.shipType) {
              items.shipType = item.text
            }
          })
        }
        if (this.state.eventTypeArr) {
          this.state.eventTypeArr.map((item) => {
            if (item.value == items.eventType) {
              items.eventType = item.text
            }
          })
        }
      })
    }
    let headers = [];
    coloums.map((item, index) => {
      let kv = {
        k: item.dataIndex ? item.dataIndex : item.key,
        v: item.title
      }
      headers = headers.concat(kv);
    })
    if (dockInfoData && dockInfoData.length > 0) {
      exportExcel(headers, dockInfoData, '进出港统计报表.xlsx');
    }
    else {
      message.error('导出数据失败！！！');
    }
  }
  haveTime = (e, value) => {
    let startTime = value[0] + " 00:00:00";
    let endTime = value[1];
    endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
    this.setState({
      startTime,
      endTime,
      value_DatePicker: [startTime, endTime],
    })
  }
  //分页筛选
  async getArgs(value) {
    let params = {

    }
    let data = await getArgs(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data && data.code == 200) {
      this.setState({
        objectNameArr: data.data,

      })


    } else {
    }
  }
  getColumnSearchProps1 = () => ({
    filterDropdown: () => {
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 100 }} placeholder={'请选择'}
            showSearch
          >
            {
              <Option
                onClick={this.clickSearchData2.bind(this, 302262)}
                value={302262} >驶出停靠点</ Option>
            }
            {
              <Option
                onClick={this.clickSearchData2.bind(this, 302263)}
                value={302263} >驶入停靠点</ Option>
            }
          </Select>,
        </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData2 = (value) => {
    this.setState({
      eventTypeValue: value,
    }, () => {
      this.leaveAndArrivalStatistics()
    });
  }
  getColumnSearchProps = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 100 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.regionNames.map((item) => (
                <Option
                  onClick={this.clickSearchData1.bind(this, item)}
                  value={item} >{item}</Option>
              ))
            }
          </Select>,
      </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData1 = (value) => {
    this.setState({
      regionNamevalue: value,
    }, () => {
      this.leaveAndArrivalStatistics()
    });
  }
  getColumnSearchPropss = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 200 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.areaNames.map((item) => (
                <Option
                  onClick={this.clickSearchData.bind(this, item)}
                  value={item} >{item}</Option>
              ))
            }
          </Select>,
      </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData = (value) => {
    this.setState({
      areaNamevalue: value,
    }, () => {
      this.leaveAndArrivalStatistics()
    });
  }
  //输入框为空时搜索列表不显示
  controlList = (eve) => {
    console.log(eve, 'vvvvvv')
    if (!eve.target.value) {
      this.setState({
        ulshow: false,
        name: ''
      }, () => {
        this.leaveAndArrivalStatistics()
      })
    } else {
      this.setState({
        name: eve.target.value,
        objectName: name,
      }, () => {
        this.leaveAndArrivalStatistics(this.state.name, 'select')
      }
      )
    }
  }
  //点击搜索列表的事件
  clickSearchDatavalue = (names) => {
    this.leaveAndArrivalStatistics(names)
    this.setState({
      name: names
    })
  }
  // 进出港报表
  async leaveAndArrivalStatistics(value, type) {
    this.setState({ loading: true })
    let { startTime, endTime, pagination11,pagination, areaNamevalue, regionNamevalue, eventTypeValue, searchValue, name } = this.state;
    let params = {
      objectName: value ? value : name,
      areaName: areaNamevalue,
      eventType: eventTypeValue,
      regionName: regionNamevalue,
      startTime,
      endTime,
      pageIndex: pagination11.current,
      pageSize: pagination11.pageSize,
      pageTotal: this.state.total1,
    }
    let data = await leaveAndArrivalStatistics(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data.code == 200) {
      if (type === 'select') {
        this.setState({
          ullAll: data.data.items,
          ulshow: true,
          loading: false
        }
        )
      } else {
        pagination.current=1
        this.setState({
          ulshow: false,
          dataSource: data.data.items,
          pageTotal: data.pageTotal,
          pagination,
          loading: false,
        })
      }
    } else {
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      // pagination: { ...pagination }
      pagination:pagination
    }, () => {
      // this.leaveAndArrivalStatistics()
    })
  }
  getsearchvalue = (value) => {
    this.setState({
      searchValue: value
    }, () => {
      // this.leaveAndArrivalStatistics()
    })
  }
  render() {
    let { dictData, startTime, ulshow, endTime, shipTypeArr, eventTypeArr, areaNameArr, areaNameArrList ,pagination} = this.state;
    let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.objectName))
    let ullAllss = [...new Set(ullAlls)];
    const columns = [

      {
        title: '船名',
        dataIndex: 'objectName',
      },
      {
        title: '船舶类型',
        dataIndex: 'shipType',
        render: (text) => {
          let newText;
          if (shipTypeArr) {
            shipTypeArr.forEach((item) => {
              if (item.value == text) {
                newText = item.text
              }
            })
          }
          return newText
        },

      },
      {
        title: '地区名称',
        dataIndex: 'regionName',
        // filters: areaNameArrList,
        // onFilter: (value, record) => record.regionName.indexOf(value) === 0,
        ...this.getColumnSearchProps('regionName'),
      },
      {
        title: '停靠点名称',
        dataIndex: 'areaName',
        ...this.getColumnSearchPropss('areaName'),
      },
      {
        title: '进出状态',
        dataIndex: 'eventType',
        ...this.getColumnSearchProps1('eventType'),
        render: (text) => {
          let newText;
          if (eventTypeArr) {
            eventTypeArr.forEach((item) => {
              if (item.value == text) {
                newText = item.text
              }
            })
          }
          return newText
        },
      },
      {
        title: '进出港时间',
        dataIndex: 'gmtCreate',
        width: 200,
        key: 'gmtCreate',
        sorter: (a, b) => {
          if (a.gmtCreate > b.gmtCreate) {
            return 1;
          } else if (a.gmtCreate < b.gmtCreate) {
            return -1;
          } else {
            return 0;
          }
        },
      }
    ];
    return (
      <div>
        <div className="voyagenum_mune">
          <div className='input_button' style={{ display: 'flex' }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: 75, marginTop: 5, textAlign: 'center' }} >船名：</span>
              <Input
                value={this.state.name}
                onSearch={value => this.selectVoyageStatistics(value, 'select')}
                onChange={value => this.controlList(value)}
                onPressEnter={() => this.leaveAndArrivalStatistics()}
                placeholder="请输入" />
            </div>
            {
              ulshow && <ul className="searchList">
                {
                  ullAllss && ullAllss.map((item, index) => (
                    <li
                      key={item.index}
                      onClick={this.clickSearchDatavalue.bind(this, item)}
                      style={{
                        cursor: 'pointer',
                        fontSize: 16,
                        display: 'flex',
                        alignItems: "center",
                      }}
                    ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item
                    }</span>
                    </li>
                  ))
                }
              </ul>
            }
            <text style={{ marginTop: 5, marginLeft: 30, marginRight: 15 }} >选择日期:</text>
            <RangePicker
              defaultValue={[moment(startTime), moment(endTime)]}
              ranges={{
                今日: [moment(), moment()],
                '本周': [moment().startOf('week'), moment().endOf('week')],
                '本月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                '今年': [moment().startOf('year'), moment().endOf('year')],
              }}
              allowClear={false}
              onChange={this.haveTime}
              value={[moment(startTime), moment(endTime)]}
            />
            <Button style={{ marginLeft: 40 }} type="primary" onClick={() => this.leaveAndArrivalStatistics()}>查询</Button>
            <Button style={{ marginLeft: 20 }}
              onClick={() => {
                this.setState({
                  searchValue: '',
                  name: '',
                  startTime: dayList1,
                  endTime: dayList,
                  areaNamevalue: '',
                  eventTypeValue: '',
                  regionNamevalue: '',
                }
                  , () => {
                    this.leaveAndArrivalStatistics()
                  })
              }}
            > 重置</Button>
            <Button style={{ marginLeft: 650 }} onClick={(e) => this.exportDefaultExcel(e, columns)} >导出</Button>


          </div>
          <Table
            className='importand_icon'
            loading={this.state.loading}
            pagination={pagination} onChange={this.handleTableChange}
            style={{ marginTop: 25 }} columns={columns}
            dataSource={this.state.dataSource} />
        </div>
      </div >

    )
  }
}
