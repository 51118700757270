import React, { Component } from 'react'

import 'ol/ol.css';
import Map from './map_TechnicalSupport/OL_Map.js';
import BaseSubPage from '../../BaseSubPage.js'

import {
    Modal, Icon, Button, Row, Col, Table, Radio, Menu, Dropdown, message
} from 'antd'
import DrawPolygonSearch from '../../tools/DrawPolygonSearch'
import Measure from '../../tools/Measure'
import SelectPointAnimation from '../../tools/SelectPointAnimation'
import TrajectoryAnimation from '../../tools/TrajectoryAnimation'
// import TrajectoryAnimation from '../../tools/TrajectoryAnimationNew'
import {//前后端数据交互接口
    getProblemDetail,
    getAreaListNew,
    getCameraVOByCondition,
    getCameraAllAlarmLogById,
} from '../interfaceRequest/RequestImpl';

// import imgMap_ModalBg from '../../../../img/imgMap/imgModal/bg_fbt_tk.png';
// import imgMap_ModalX from '../../../../img/imgMap/imgModal/bg_tk_shipin.png';
import TileSource from 'ol/source/Tile';
import { none } from 'ol/centerconstraint';

import './MapFactoryImpl.css';
/**
 *@Description:{ }
 *@InterfaceAddress:{ }
 *@parameter: { 
                   
               }
 *@ClassAuthor: ZDong
 *@Date: 2019-12-27 13:55:39
*/
class MapFactoryImpl extends BaseSubPage {
    constructor(props, context) {
        super(props, context);
        this.state = {
            text_Measure_DropdownButton: '测量工具',
            text_Measure_DropdownButton_search: '搜索工具',
            attribute: this.props.config_JSON.map_componentConfig.attribute,
            map: null,
            visible: false,
            imgIndex: -1,
            pointDetailData: false,//弹窗基本信息
            modalData_cameraAllAlarmLogData: false,//弹窗报警列表
            mapStyle: {
                width: '100% !important',
                height: '100% !important',
            },
            featuresMap_trajectoryPoint: {},//轨迹点库，用以判断重复画点
        };
        this.map = null;


    }
    setWFSFeaturesDict = (item, target) => {
        //.log(item, "----------MapFactoryImpl here : features")
        // let dict_library_wfsFeatures = {}
        // if (target == "river_name_liu") {
        //     item.map((item) => {
        //         let key = item.values_['河名']
        //         let value = item
        //         dict_library_wfsFeatures = {
        //             ...dict_library_wfsFeatures,
        //             [key]: value
        //         }
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures, '------------------setWFSFeaturesDict- all river--')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures, target)
        //     })
        // }
        // else if (target == "zhenjie") {
        //     item.map((item) => {
        //         let key = item.values_["镇名"]
        //         let value = item
        //         dict_library_wfsFeatures = {
        //             ...dict_library_wfsFeatures,
        //             [key]: value
        //         }
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures_zhenjie: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures_zhenjie, '-----------------------zhenjie ')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures_zhenjie, target)
        //     })

        // }
        // else if (target == "zhenguanpianqu") {
        //     item.map((item) => {
        //         let key = item.values_.pianqu;
        //         let value = item
        //         dict_library_wfsFeatures = {
        //             ...dict_library_wfsFeatures,
        //             [key]: value
        //         }
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures_zhenguanpianqu: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures_zhenguanpianqu, '-----------------------zhenguanpianqu ')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures_zhenguanpianqu, target)
        //     })

        // }
        // else if (target == "areaBorder_city") {
        //     Object.keys(item).map((key) => {
        //         let valueC = {};
        //         valueC.geometry = {
        //             coordinates: item[key]
        //         }
        //         valueC.target = target;
        //         let value = [];
        //         value = value.concat(valueC);
        //         if (!key.includes('镇') && key.includes('片')) {
        //             if (key.includes('练塘片')) {
        //                 key = '练塘片';
        //                 if (dict_library_wfsFeatures[key]) {
        //                     dict_library_wfsFeatures[key] = dict_library_wfsFeatures[key].concat(value);
        //                 } else {
        //                     dict_library_wfsFeatures = {
        //                         ...dict_library_wfsFeatures,
        //                         [key]: value
        //                     }
        //                 }
        //             } else {
        //                 dict_library_wfsFeatures = {
        //                     ...dict_library_wfsFeatures,
        //                     [key]: value
        //                 }
        //             }
        //         }
        //         //.log(dict_library_wfsFeatures, '------------------练塘片----dict_library_wfsFeatures---')
        //     })
        //     this.setState({
        //         dict_library_wfsFeatures_areaBorder_city: dict_library_wfsFeatures,//河湖features数据字典的字典库，使用河道名字进行字典查询
        //         // dict_library_for_war: dict_library_for_war,
        //     }, () => {
        //         //.log(this.state.dict_library_wfsFeatures_areaBorder_city, '-----------------------areaBorder_city ')
        //         this.props.setWFSFeaturesDict(this.state.dict_library_wfsFeatures_areaBorder_city, target)
        //     })

        // }
    }

    showModal = () => {
        //.log(this.state.pointDetailDatas, '---------------------pointDetailData')
        this.setState({
            visible: true,
        });
    }
    hideModal = () => {
        this.setState({
            visible: false,
        });
    }
    // showModal = (imgModal, imgList) => {
    //     let imgIndex = this.state.imgIndex;
    //     if (imgList && imgIndex == -1) {
    //         imgIndex = 0;
    //     }
    //     if (imgModal) {
    //         this.setState({
    //             visible: true,
    //             imgModal: imgModal,
    //             imgList: imgList,
    //             imgIndex: imgIndex
    //         });
    //     } else {
    //         imgModal = this.state.imgList[this.state.imgIndex];
    //         this.setState({
    //             visible: true,
    //             imgModal: imgModal,
    //         });
    //     }
    // };
    // hideModal = () => {
    //     let imgIndex = -1;
    //     let imgList = [];
    //     this.setState({
    //         visible: false,
    //         imgIndex: imgIndex,
    //         imgList: imgList,
    //     });
    // };
    changeImg_Modal_left = () => {
        let imgIndex = this.state.imgIndex - 1;
        //.log(imgIndex, this.state.imgList.length, '-------changeImg_Modal_left----imgIndex--')
        this.setState({
            imgIndex: imgIndex,
        }, () => {
            this.showModal();
        })
    }
    changeImg_Modal_right = () => {
        let imgIndex = this.state.imgIndex + 1;
        //.log(imgIndex, this.state.imgList.length, '-------changeImg_Modal_right----imgIndex--')
        this.setState({
            imgIndex: imgIndex,
        }, () => {
            this.showModal();
        })
    }

    setMapStyle = (style) => {
        let mapStyle = this.state.mapStyle;
        if (style) {
            mapStyle = style;
        }
        this.setState({
            mapStyle: mapStyle,
        }, () => {
            setTimeout(() => {
                this.map.map.updateSize();
            }, 100);
            // this.createMap();
            // window.location.reload();
        })
    }

    playerMapPointCarera = () => {
        if (this.props.playerMapPointCarera) {
            this.props.playerMapPointCarera(this.state.pointDetailData);
        }
    }

    click_pointDetailDatasList = (e, data) => {
        //.log(data, '------------click_pointDetailDatasList--------');
        let id = data.id;
        let pointDetailData = {
            ...this.state.pointDetailDatas[id]
        };
        this.setState({
            pointDetailData: pointDetailData,
            rowId: id,
        }, () => {
            //.log(this.state.pointDetailData, '--------click_pointDetailDatasList here----pointDetailData-----')
        });
    }
    setRowClassName = (record) => {
        return record.id === this.state.rowId ? 'clickRowStyl' : '';
    }

    handleButtonClick_Measure = (value) => {
        //.log(value, this.map.layers.vector_draw, '-----------handleButtonClick_Measure----------');
        let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
        if (text_Measure_DropdownButton == '取消测量') {
            this.setState({
                text_Measure_DropdownButton: '测量工具',
            }, () => {
            });
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure();
            this.map.layers.vector_draw.setVisible(false);
            this.map.layers.vector_draw.getSource().clear();
            this.map.onClick_getFeatureInfo(this);
        }
    }

    handleMenuClick_Measure = (value) => {
        let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
        console.log(text_Measure_DropdownButton, '------------handleMenuClick_Measure---------');
        if (text_Measure_DropdownButton == '测量工具') {
            this.setState({
                text_Measure_DropdownButton: '取消测量',
            }, () => {
                this.map.unClick_getFeatureInfo(this);
            });
        } else {
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure();
            this.map.layers.vector_draw.setVisible(false);
            this.map.layers.vector_draw.getSource().clear();
        }
        let valueKey = '';
        switch (value) {
            case 'range':
                valueKey = 'LineString ';
                ; break;
            case 'area':
                valueKey = 'area';
                ; break;
        }
        Measure.measure(this.map, valueKey);
        // DrawPolygonSearch.measure(this, 'Circle');
    }
    handleButtonClick_Measure_search = (value) => {
        console.log(value, this.map.layers.vector_draw_search, '-----------handleButtonClick_Measure----------');
        let text_Measure_DropdownButton_search = this.state.text_Measure_DropdownButton_search;
        if (text_Measure_DropdownButton_search == '取消搜索') {
            this.setState({
                text_Measure_DropdownButton_search: '搜索工具',
            }, () => {
            });
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure_search();
            this.map.layers.vector_draw_search.setVisible(false);
            this.map.layers.vector_draw_search.getSource().clear();
            this.map.onClick_getFeatureInfo(this);
        }
    }

    handleMenuClick_Measure_search = (value) => {
        let text_Measure_DropdownButton_search = this.state.text_Measure_DropdownButton_search;
        console.log(text_Measure_DropdownButton_search, '------------handleMenuClick_Measure---------');
        if (text_Measure_DropdownButton_search == '搜索工具') {
            this.setState({
                text_Measure_DropdownButton_search: '取消搜索',
            }, () => {
                this.map.unClick_getFeatureInfo(this);
            });
        } else {
            this.map.clearTooltipOverlayList();
            this.map.clearDraw_Measure_search();
            this.map.layers.vector_draw_search.setVisible(false);
            this.map.layers.vector_draw_search.getSource().clear();
        }
        DrawPolygonSearch.measure(this, value);
    }
    draw_getAreaPointData = (value) => {
        this.map.unClick_getFeatureInfo(this);
        DrawPolygonSearch.measure(this, value);
    }
    stop_draw_getAreaPointData = () => {
        // this.map.clearTooltipOverlayList();
        this.map.clearDraw_Measure_search();
        this.map.onClick_getFeatureInfo(this);
    }
    clear_draw_getAreaPointData = (noClearDraw) => {
        this.map.clearTooltipOverlayList();
        this.map.clearDraw_Measure_search();
        if (typeof(noClearDraw)=='undefined' || !noClearDraw) {
            this.map.layers.vector_draw_search.setVisible(false);
            this.map.layers.vector_draw_search.getSource().clear();
        }
        this.map.onClick_getFeatureInfo(this);
    }

    setSearchByPolygonAndCircleParams = (params) => {
        console.log(params, '--------params--------------------setSearchByPolygonAndCircleParams---------------');
        // this.stop_draw_getAreaPointData();
        this.props.parentObj.searchByPolygonAndCircle(params);
    }

    render() {

        const columns_modal_pointData = [
            {
                title: '报警时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    let color = '#E2EEFE';
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        >{text}</span>
                    )
                }
            },
            {
                title: '报警类型',
                dataIndex: 'alarmSubTypeName',
                key: 'alarmSubTypeName',
                align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    let color = 'red';
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        >{text}</span>
                    )
                }
            },
            {
                title: '持续时间',
                dataIndex: 'duration',
                key: 'duration',
                align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    let color = '#FC2A2A';
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        >{text}</span>
                    )
                }
            },
        ];


        let { config_JSON } = this.props;
        let heightBody = config_JSON.map_componentConfig.attribute.height;

        let cameraRunningImg = '';
        let cameraUnRunningImg = '';

        //点击地图摄像头信息列表组

        let pointDetailDatasList = [];
        if (this.state.pointDetailDatas) {
            Object.keys(this.state.pointDetailDatas).map((key) => {
                pointDetailDatasList = pointDetailDatasList.concat(this.state.pointDetailDatas[key]);
            }
                // <Radio.Button value={ key }
                //     style={ {
                //         // width: 180,
                //         // height: 40,
                //         // position: 'absolute',
                //         // top: 0,
                //         // left: 48,
                //         fontFamily: 'SourceHanSansCN-Regular',
                //         fontSize: 16,
                //         color: '#27C5FF',
                //         marginTop: '5%',
                //         backgroundColor: 'rgba(15,39,96,0.5)',
                //         // backgroundImage: '-webkit-linear-gradient(top, #F1ECEC, #0C67BB)',
                //         // WebkitTextFillColor: 'transparent',
                //         // WebkitBackgroundClip: 'text',
                //         textAlign: 'center',
                //         verticalAlign: 'middle',
                //         // lineHeight: '40px',
                //         // vertical- align: middle;display: table-cell;
                //     } }
                // >{ this.state.pointDetailDatas[key].name }</Radio.Button>
            );
            //.log(pointDetailDatasList, '----------pointDetailDatasList----')
        }
        let name = '';
        if (this.state.pointDetailData) {
            name = this.state.pointDetailData.name;
        }
        const columns_pointDetailDatasList = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                render: (text, record) => {
                    let color = '#27C5FF';
                    if (record.networkState == 0) {
                        color = 'red';
                    }
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 14,
                                cursor: 'pointer'
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        >
                            <img src={record.networkState == 0 ? cameraUnRunningImg : cameraRunningImg}
                                style={{

                                }}>
                            </img>
                            &nbsp;
                            { text}
                        </span>
                    )
                }
            },
        ]

        const menu_Measure = (
            <Menu onClick={this.handleMenuClick_Measure}>
                <Menu.Item key="area">
                    <Icon type="rollback" />
                    面积
                </Menu.Item>
                <Menu.Item key="LineString">
                    <Icon type="arrows-alt" />
                    长度
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="div_Map_trajectory">
                <div ref={(node) => { this.div = node; }} id={this.state.attribute.target} style={{
                    width: this.state.mapStyle.width,
                    // height: this.state.mapStyle.height,
                    height: heightBody,
                }}>
                </div>
                {/* <div id="menu" style={{
                    position: 'absolute',
                    right: 30,
                    top: 30
                }}>
                    <Dropdown.Button
                        overlay={menu_Measure}
                        icon={<Icon type="edit" theme="twoTone" />}
                        onClick={this.handleButtonClick_Measure}
                    >
                        {this.state.text_Measure_DropdownButton}
                    </Dropdown.Button>
                </div> */}
                {
                    typeof (this.state.attribute.createDefaultTool) != 'undefined' ? this.state.attribute.createDefaultTool : true
                        &&
                        <div id="mouse-position_trajectory" class="mouse-position-wrapper_trajectory">
                            <div class="custom-mouse-position_trajectory"></div>
                        </div>
                }
                {/* <div id="mapControl_scaleLineControl_trajectory" class="MapControl_scaleLineControl_trajectory">
                    <div class="custom-MapControl_scaleLineControl_trajectory"></div>
                </div> */}
                {/* <div id="mapControl_OverviewMap_trajectory" class="MapControl_OverviewMap_trajectory">
                    <div class="custom-MapControl_OverviewMap_trajectory"></div>
                </div> */}
            </div >

        );
    }

    closeAllLayers = () => {
        this.map.closeAllLayers()
    }

    showMap_DBData = (polygonsDatas, params) => {
        //.log(polygonsDatas, "----------------------------------showMap_DBData polygonsDatas")
        // this.map.removeLayerAll()
        // this.map.removeLayerAll()
        return this.map.addLayer_Polygon_DBData(polygonsDatas, params);
        // this.map.addIcon_FeatureAndStyle()
    }

    showMap_border = (features) => {
        let target = 'vectorBorder';
        //.log(features, '-----------------------------showMap_border')
        this.map.addLayer_polygon_border(features, target);
    }

    showMap_trajectory_points = (points, target) => {
        if (points) {
        } else {
            points = [
                {
                    time: '2020-03-30 10:00:00',
                    lon: 102.245722,
                    lat: 27.865106,
                },
                {
                    time: '2020-03-30 10:05:00',
                    lon: 102.245463,
                    lat: 27.788754,
                },
                {
                    time: '2020-03-30 10:08:00',
                    lon: 102.381849,
                    lat: 27.808182,
                },
                {
                    time: '2020-03-30 10:12:00',
                    lon: 102.385849,
                    lat: 27.812182,
                },
                {
                    time: '2020-03-30 10:30:00',
                    lon: 102.352495,
                    lat: 27.876032,
                },
            ]
        }
        // this.map.changeZoomAndCenter(this.map, 10, points, true)
        // TrajectoryAnimation.clear_TrajectoryAnimation();
        this.map.changeZoomAndCenter_Data(this, 10, points, undefined, true)
        TrajectoryAnimation.addTrajectoryAnimation(this, points, target);
    }
    start_trajectory_points = () => {
        TrajectoryAnimation.start_TrajectoryAnimation(this);
    }
    stop_trajectory_points = () => {
        TrajectoryAnimation.stop_TrajectoryAnimation(this);
        this.parentObj.stop_trajectory_points();
    }
    clearAllListener = () => {
        TrajectoryAnimation.clear_TrajectoryAnimation(this);
    }
    clearMap = (noNeedRemoveList,isNoChangeZoom) => {
        this.map.removeAll(noNeedRemoveList);
        this.clear_draw_getAreaPointData();
        if (typeof(isNoChangeZoom)=='undefined' || !isNoChangeZoom) {
            this.map.changeZoomAndCenter(this.map, this.state.attribute.zoom, undefined, undefined, JSON.parse(this.state.attribute.center))
        }
        TrajectoryAnimation.clear_TrajectoryAnimation(this);
        SelectPointAnimation.clearAllListener(this);
    }
    clearMap_matchWords = (word) => {
        if (word) {
            Object.keys(this.map.layers).map(key => {
                let value = this.map.layers[key];
                if (key.includes(word)) {
                    value.getSource().clear();
                }
            })
        }
    }
    changeTrajectorySpeed = (doubleNum) => {
        TrajectoryAnimation.changeTrajectorySpeed(doubleNum);
    }

    showMap_addFeature_Line = (feature, target) => {
        //.log(feature, '-----------------------showMap_addPoints_head here points');
        SelectPointAnimation.clearAllListener(this);
        // if (this.state.divOverlay) {
        //     this.state.divOverlay.setAttribute("style", "display:none")
        // }
        // this.map.removeLayerAll()
        // this.map.removeLayerWithTarget('vectorCluster')
        this.map.removeLayerWithTarget(target)
        if (feature) {
            this.map.addIcon_FeatureAndStyle_head(feature,
                {
                    zIndex: 110,
                    target: target,
                },
            )//画点)//画点
        }
    }
    showMap_addPoints_head = (feature, target) => {
        //.log(feature, '-----------------------showMap_addPoints_head here points');
        SelectPointAnimation.clearAllListener(this);
        // if (this.state.divOverlay) {
        //     this.state.divOverlay.setAttribute("style", "display:none")
        // }
        // this.map.removeLayerAll()
        // this.map.removeLayerWithTarget('vectorCluster')
        this.map.removeLayerWithTarget(target)
        if (feature) {
            this.map.addIcon_FeatureAndStyle_head(feature,
                {
                    zIndex: 115,
                    target: target,
                },
            )//画点)//画点
        }
    }
    showMap_addPoints = (points, params, pointImg) => {
        //.log(points, '-----------------------showMap_addPoints here points');
        SelectPointAnimation.clearAllListener(this);
        if (this.state.divOverlay) {
            this.state.divOverlay.setAttribute("style", "display:none")
        }
        this.map.removeLayerAll()
        this.map.removeLayerWithTarget('vectorCluster')
        this.map.removeLayerWithTarget(params.target)
        if (points && points.length > 0) {
            this.map.addIcon_FeatureAndStyle(points, params, pointImg,
            )//画点)//画点
            let point = points[(points.length / 2).toFixed(0)]
            // this.map.changeZoomAndCenter(11, point, points.length)
        }
    }
    showMap_addPoint = (feature, params) => {
        if (feature && feature.values_.id) {
            let featuresMap_trajectoryPoint = this.state.featuresMap_trajectoryPoint;
            if (featuresMap_trajectoryPoint[feature.values_.id]) {
            } else {
                featuresMap_trajectoryPoint = {
                    ...featuresMap_trajectoryPoint,
                    [feature.values_.id]: feature,
                }
                this.map.addIcon_Feature(feature, params)//画点)//画点
            }

        }
    }
    //开启Overlay图层
    openOverlayAll = () => {
        TrajectoryAnimation.openShipHeadOverlay();
    }
    //关闭Overlay图层
    closeOverlayAll = () => {
        TrajectoryAnimation.closeShipHeadOverlay();
        this.map.closeOverlayAll();

    }
    //开启图层
    openLayerVisibleBylayerName = (layerName) => {
        this.map.openLayerVisibleBylayerName(layerName);
    }
    //关闭图层
    closeLayerVisibleBylayerName = (layerName) => {
        this.map.closeLayerVisibleBylayerName(layerName);
    }
    //根据点位置调整视窗
    changeZoomAndCenter_point = (point,zoom) => {
        this.map.changeZoomAndCenter_point(point,zoom)
    }
    changeZoomAndCenter_points = (points,zoom) => {
        this.map.changeZoomAndCenter(this.map, zoom, points, true)
    }
    autoChangeStyle = (isFlicker) => {
        this.map.autoChangeLayerStyle(isFlicker)
        // this.map.getFitWithExtent()
    }
    autoChangeStyle_border = (isFlicker) => {
        let target = 'vectorBorder';
        this.map.autoChangeLayerStyle_border(isFlicker, target)
        // this.map.getFitWithExtent()
    }

    getPointDetail = (features) => {
        //.log(ids, '------id-----------------getPointDetail here-------')
        this.map.addOverlay_setPosition(features, this);
    }

    onClick_Map_pointsInfo = (datas) => {
        this.props.parentObj.onClick_Map_pointsInfo(datas);
    }

    /**
     *@Description:{ 用于固定点信息展示面板调用}
     *@InterfaceAddress:{ }
     *@parameter: { 
                       id
                   }
     *@ClassAuthor: ZDong
     *@Date: 2020-01-13 10:34:17
    */
    async getCameraVOByCondition(id, isLast) {
        //.log(id, isLast, '--------------------getCameraVOByCondition-------------')
        let params = {}
        params.id = id
        let data = await getCameraVOByCondition(params)
            .then(res => {
                return res.json()
            })
            .catch(ex => { });
        if (data.code === 200) {
            let pointDetailDatas = this.state.pointDetailDatas;
            let pointDetailData = pointDetailDatas[id];
            pointDetailData = {
                ...pointDetailData,
                ...data.data[0]
            };
            if (pointDetailData.cameraName) {
                pointDetailData.name = pointDetailData.cameraName;
            }
            pointDetailDatas = {
                ...pointDetailDatas,
                [id]: pointDetailData,
            }
            if (isLast) {
                //.log(params, pointDetailDatas, '----params---------getPointDetails----')
                this.setState({
                    pointDetailDatas: pointDetailDatas,
                    pointDetailData: pointDetailData,
                    rowId: id,
                }, () => {
                    //.log(params, this.state.pointDetailDatas, '----params---------getPointDetails----')
                    this.onClick_Map_pointsInfo(this.state.pointDetailDatas);//测试用，带修改
                    // this.showModal();
                })
            } else {
                this.setState({
                    pointDetailDatas: pointDetailDatas
                }, () => {
                    //.log(params, this.state.pointDetailDatas, '----params---------getPointDetail----')
                    // this.showModal();
                })
            }
        }
    }
    /**
     *@Description:{ 获取 点的 摄像头报警信息列表 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       id
                   }
     *@ClassAuthor: ZDong
     *@Date: 2020-01-13 10:35:15
    */
    async getCameraAllAlarmLogById(id, isFirst) {
        let params = {}
        params.id = id
        let data = await getCameraAllAlarmLogById(params)
            .then(res => {
                return res.json()
            })
            .catch(ex => { });
        if (data.code === 200) {
            let pointDetailDatas = this.state.pointDetailDatas;
            let pointDetailData = pointDetailDatas[id];
            pointDetailData = {
                ...pointDetailData,
                alarmList: data.data,
            };
            pointDetailDatas = {
                ...pointDetailDatas,
                [id]: pointDetailData,
            }
            if (isFirst) {
                this.setState({
                    pointDetailDatas: pointDetailDatas,
                    pointDetailData: pointDetailData,
                }, () => {
                    //.log(params, this.state.pointDetailDatas, '----params---------getPointDetail----')
                    // this.showModal();
                })
            } else {
                this.setState({
                    pointDetailDatas: pointDetailDatas,
                }, () => {
                    //.log(params, this.state.pointDetailDatas, '----params---------getCameraAllAlarmLogById----')

                })
            }
        }
    }

    /**
     *@Description:{ 用于随点位置而变动的数据展示面板调用方法 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2020-01-13 10:33:36
    */
    // async getPointDetail(id, div, overlay, coodinate) {
    //     let param = {}
    //     param.problemId = problemId
    //     let data = await getProblemDetail(param)
    //         .then(res => {
    //             return res.json()
    //         })
    //         .catch(ex => { });
    //     if (data.code === 200) {
    //         this.setState({
    //             divOverlay: divObj
    //         })
    //         //.log(data.data, "--------getProblemDetail 数据装载成功 ")
    //         this.map.addOverlay_setPosition(data.data, divObj, overlay, coodinate, this)；//
    //     }
    // }

    /**
     *@Description:{ 获取片区边界数据 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-12-23 16:51:27
    */
    // async getAreaListNew() {

    //     let data = await getAreaListNew()
    //         .then(res => {
    //             return res.json()
    //         })
    //         .catch(ex => { });
    //     if (data.code === 200) {
    //         this.setState({
    //             areaBorder_city: data.data,
    //         }, () => {
    //             this.setWFSFeaturesDict(data.data, 'areaBorder_city');
    //         })
    //         // //.log(data.data, "--------getAreaListNew 数据装载成功 ")
    //         // this.map.addOverlay_setPosition(data.data, divObj, overlay, coodinate, this)
    //     }
    // }
    componentWillUnmount() {
        //.log('--------------------------componentWillUnmount--------------------------------')
        SelectPointAnimation.clearAllListener(this);
        TrajectoryAnimation.clear_TrajectoryAnimation(this);
    }

    componentDidMount() {
        // this.getAreaListNew();
        this.createMap();
        this.map.createEventChangeResolution(this);
        let parentObj = this.props.componentLoad("map", this);
        this.parentObj = parentObj;
        SelectPointAnimation.clearAllListener(this);
        TrajectoryAnimation.clear_TrajectoryAnimation(this);
        // //.log(this.props.setWFSFeaturesDictFinish, '------------------this.props.setWFSFeaturesDictFinish')
        // if (this.props.setWFSFeaturesDictFinish) {
        // } else {
        //     this.map.createWFSLayerDict(this, 'sunt:river_name_liu', 'river_name_liu', '河道大数据分析')
        // }
        // this.map.createWFSLayerDict(this, 'sunt:zhenjie', 'zhenjie', '河道大数据分析镇界')
        // this.map.createWFSLayerDict(this, 'sunt:zhenguanpianqu', 'zhenguanpianqu', '河道大数据分析镇管东中西片区')
        // this.setWFSFeaturesDict(features)
    }
    click_addPointAnimation = (point) => {
        let pointLayer = this.map.layers.vectorImg;
        //.log(pointLayer, point, '--------pointLayer-----------createMap-----------------------');
        let feature = new Feature({
            geometry: new Point(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857')),
        })
        SelectPointAnimation.click_addPointAnimation(this.map, pointLayer, feature, point.id);
    }
    addWMSLayer = (layers) => {
        let attribute = this.state.attribute;
        attribute.layers = layers;
        this.setState({
            attribute
        }, () => {
            Object.keys(layers).map(key => {
                let value = layers[key];
                switch (key) {
                    case 'wms':
                        if (value && value.length > 0) {
                            value.map((item, index) => {
                                this.map.addWMSLayer(item);
                            })
                        }
                        break;
                }
            })
        })
    }
    createMap() {
        this.map = new Map({
            target: this.state.attribute.target,
            center: JSON.parse(this.state.attribute.center),
            zoom: parseInt(this.state.attribute.zoom),
            minZoom: parseInt(this.state.attribute.minZoom),
            maxZoom: parseInt(this.state.attribute.maxZoom),
            createDefaultTianDi: typeof (this.state.attribute.createDefaultTianDi) != 'undefined' ? this.state.attribute.createDefaultTianDi : true,//此属性为false表示不需要默认天地图，有配置wms数据创建天地图
            createDefaultTool: typeof (this.state.attribute.createDefaultTool) != 'undefined' ? this.state.attribute.createDefaultTool : true,//此属性为false表示不需要默认天地图，有配置wms数据创建天地图
        });
        // let testUrl = 'http://221.181.88.134:6706/geoserver/sunt/wms?service=WMS'
        // this.map.addTile({
        //     url: require("../../img/mask_map.png"),
        //     visible: true,
        //     key: "heidi",
        //     opacity: 0.5,
        //     zIndex:999
        // });
        // let layersName_qingpu = 'sunt:zuozhantu'
        // // let layersName_qingpu = 'sunt:qpmap_hddsj'
        // this.map.addWMSLayer(testUrl, layersName_qingpu, 99)//青浦区图层
        // let toumingLayerlayersName = 'sunt:river_touming'
        // this.map.addWMSLayer(testUrl, toumingLayerlayersName, 200)//河流名字透明图层
        // //.log(this.props.inspectedRiverSliceData ? '存在' : '不存在', 'this.props.inspectedRiverSliceData')
        this.map.addMapMonitoringEvents(this);
        this.map.addOverLayer("problemOverLayer", this);
        let pointLayer = this.map.layers.vectorImg;
        let vectorTrajectory = this.map.layers.vectorTrajectory;
        //.log(pointLayer, '--------pointLayer-----------createMap-----------------------');
        // SelectPointAnimation.addPointAnimation(this.map, pointLayer);
        //如果配置文件中存在wms参数，则生成并添加wms
        let layers = this.state.attribute.layers;
        if (layers) {
            this.addWMSLayer(layers);
        }
    };
}

export default MapFactoryImpl