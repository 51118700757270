// 用户管理
import React, { Component } from 'react'
import './css/system.css'
import CryptoJS from 'crypto-js';
import { Table, Input, Modal, Icon, Select, message, Button,Spin,DatePicker } from 'antd';
import ModalForm from '../../componts/ModalForm'
import { exportExcel } from 'xlsx-oc';
import {
    updateLoginCount,
    selectCmdCards
} from '../../data/dataStore'
import moment from 'moment';
// import locale from "_antd@3.26.20@antd/es/date-picker/locale/zh_CN";
// import {DatePicker} from "_antd@3.26.20@antd";
const { TextArea, Search } = Input;
const { RangePicker } = DatePicker;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class CommanderCardListSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            loading: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            deleteData: {},
            ulshow: false,
            selectedKeys: [],
            cmdId: -1,
            id: '',
            name:'',
            userData: [],
            pasValue: '',
            modifyData: {},
            userGroupData: [],
            pageNo: 1,
            pageSize: 10,
            pageTotal: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            isLoading: false,
            columnsThis: {},
        }
        this.selectCmdCards = this.selectCmdCards.bind(this)
    }
    componentDidMount() {
        // this.selectCmdCards()
        let user=JSON.parse(sessionStorage.getItem('isLogin'))
        this.setState({
            admin:user.data

        })
        console.log("--------------------------"+user.message)
        // try {
        //     this.setState({ isLoading: true }, async () => {
        //         await this.selectCmdCards();
        //     });
        // } catch (e) {
        //     message.error(`获取数据失败`);
        // }

    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectCmdCards()
        })
        console.log("77"+JSON.stringify(pagination) , filters, sorter, 'sorter')
    }

    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectCmdCards()
            })
        } else {
            this.setState({
                    name: eve.target.value,
                    shipName: name,
                }, () => {
                    this.selectCmdCards(this.state.name, 'select')
                }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.selectCmdCards(data.name)
        this.setState({
            number: data.number
        })
    }
    // 查询指挥机发送统计数据
    async selectCmdCards(value, type) {
        // this.setState({ loading: true })
        console.log('kkkkkkk----------')
        let { id, userName, name,pageTotal,pagination } = this.state;
        let params = {
            cmdId: this.state.cmdId,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
            // number: this.state.pa
        }
        let data = await selectCmdCards(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        console.log("+++++++++++++++++++++++++++++++++++++++"+data.rows);

        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        userDataArr: data.rows,
                        ulshow: true,
                        isLoading: false,
                    }
                )
            } else {
                this.setState({
                    ulshow: false,
                    userData: data.rows,
                    pageTotal: data.total,
                    isLoading: false,
                })
            }
        }else if (data.code == 503) {
            message.success("查询无数据")
            this.setState({
                    ulshow: true,
                    isLoading: false,
                }
            )
        } else if (data.code == 505) {
            this.props.history.push('/login')
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 点击表格删除事件
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 点击表格修改事件
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }
    // 点击表格重置密码事件
    resetPassword = (data) => {
        this.setState({
            resetShow: true,
            resetData: data
        })
    }
    // 点击表格解锁事件
    async updateLoginCount(user){
        let {admin}=this.state;
        console.log(admin,user,'dddddd')
        let params={
            id:user.name,
            adminId:admin.name
        }
        let data= await updateLoginCount(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if(data&&data.code==200){
            message.success(data.message)
        }else{
            message.error(data.message)
        }
    }
    // 点击表格管理的事件
    clickManage = (data) => {
        // this.getUgroupTree(data.id)
        this.getRoleList(data.id)
        this.setState({
            manageShow: true,
            manageData: data
        })
    }
    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }

    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    // // 自定义判断两次密码是否一样
    // compareToFirstPassword = (rule, value, callback) => {
    //     if (value && value !== this.state.pasValue) {
    //         callback('两次密码不一致!');
    //     } else {
    //         callback();
    //     }
    // };
    // // 获取第一次输入的密码
    // haveNewValue = (e) => {
    //     this.setState({
    //         pasValue: e.target.value
    //     })
    // }
    //用户分配用户组的选中
    distribute_onChage = (selectedRowKeys) => {
        let keyArr = this.getSelectKey(this.state.userGroupData, selectedRowKeys)
        console.log("+++++++++++++453++++++++++++"+keyArr);
        this.setState({
            selectedKeys: keyArr
        })
    }
    // 扁平化多重嵌套数组对象
    flatten = (arr) => {
        let myArr = []
        for (const item of arr) {
            myArr.push(item);
            if (item.children && item.children.length > 0) {
                myArr.push(...this.flatten(item.children))
            }
        }
        return myArr
    }
    // 模糊搜索
    getSearchValue = (data, value) => {
        let dataSource = this.flatten(data)
        let newArr;
        if (value) {
            newArr = dataSource.filter((item) => {
                console.log(value, item.name.indexOf(value), 'item')
                return item.name.indexOf(value) != -1
            })
        } else {
            newArr = data
        }

        this.setState({
            nowModalData: newArr
        })
    }
    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }
    render() {
        const { isLoading } = this.state;
        let { userData, modifyData, userDataArr, nowModalData, ulshow, selectedKeys, userGroupData, pageTotal,trajectoryDateList  } = this.state
        const secretKey = '123456789';

        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        //用户类型下拉框
        let userType = [{value:"0",text:"管理员"},{value:"1",text:"普通用户"},{value:"2",text:"推送用户"},{value:"3",text:"微信用户"}]

        let userStatus = [{value:"0",text:"启用"},{value:"1",text:"禁用"}]
        //用户类型下拉框
        let type_ugroup = [{value:"0",text:"0"},{value:"1",text:"1"},{value:"2",text:"2"}]
        const columns = [
            // {
            //     title: 'uid',
            //     key: 'uid',
            //     dataIndex: 'uid',
            // },
            {
                title: '指挥卡号',
                key: 'card',
                dataIndex: 'card',
            },
        ]
        const columns2 = [
            {
                title: '权限名称',
                key: 'roleName',
                dataIndex: 'roleName',
            },

            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
                render: (text) => {
                    return text ? text : '--'
                }
            },
        ]
        let typeUser =  [{value:"0",text:"0"},{value:"1",text:"1"},{value:"2",text:"2"}]
        //console.log(typeUser, 'typeUser')
        //添加用户的表单
        const formItemList = [
            {
                label: '用户编号', //表单label
                id: 'code', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }] }
            },
            {
                label: '用户名称', //表单label
                id: 'name', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }] }
            },
            {
                label: '用户类别：',
                id: 'type',
                component: <Select style={{ width: 100 }} placeholder={'请选择'}>
                    {
                        <Option
                            value={0} >管理员</ Option>
                    }
                    {
                        <Option
                            value={1} >普通员工</ Option>
                    }
                    {
                        <Option
                            value={2} >推送用户</ Option>
                    }
                    {
                        <Option
                            value={3} >微信用户</ Option>
                    }
                </Select>,
                options:  { rules: [{ required: true, message: '不能为空!' }] }
            },

            {
                label: '密码', //表单label
                id: 'password', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }] }
            },
            {
                label: '手机号', //表单label
                id: 'mobile', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <TextArea placeholder={'请输入'} style={{ height: 80 }} />, //表单受控组件
            },
        ]
        const resetList = [
            {
                label: '新密码',
                id: 'userId',
                component: <Input placeholder={'请输入'} type="password" onBlur={this.haveNewValue} />,
                options: { rules: [{ required: true, message: '不能为空!' }], }
            },
            {
                label: '确认密码',
                id: 'newPass',
                component: <Input placeholder={'请输入'} type="password" />,
                options: { rules: [{ required: true, message: '不能为空!' }, { validator: this.compareToFirstPassword }] },
                // validator:true
            },
        ]
        const modifyList = [
            {
                label: '用户编号', //表单label
                id: 'code', //表单Item的Key值
                rereadOnly: true,
                component: <Input placeholder={'请输入'}  readOnly/>, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.code ? modifyData.code : '' }
            },
            {
                label: '用户名称', //表单label
                id: 'name', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }],initialValue: modifyData.name ? modifyData.name : '' }
            },
            {
                label: '用户类别', //表单label
                id: 'type', //表单Item的Key值
                component: <Select placeholder={'请选择'}>
                    {
                        userType.map((item) => (
                            <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.type ? modifyData.type : '' }
            },
            {
                label: '用户状态', //表单label
                id: 'accountState', //表单Item的Key值
                component: <Select placeholder={'请选择'}>
                    {
                        userStatus.map((item) => (
                            <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: modifyData.accountState ? modifyData.accountState : '' }
            },
            {
                label: '密码', //表单label
                id: 'password', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '不能为空!' }], initialValue: CryptoJS.DES.decrypt(modifyData.password ? modifyData.password:'', CryptoJS.enc.Utf8.parse(secretKey), {
                        mode: CryptoJS.mode.ECB,
                        padding: CryptoJS.pad.Pkcs7
                    }).toString(CryptoJS.enc.Utf8)  ? CryptoJS.DES.decrypt(modifyData.password ? modifyData.password:'', CryptoJS.enc.Utf8.parse(secretKey), {
                        mode: CryptoJS.mode.ECB,
                        padding: CryptoJS.pad.Pkcs7
                    }).toString(CryptoJS.enc.Utf8) : '' }
            },
            {
                label: '手机号', //表单label
                id: 'mobile', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <TextArea placeholder={'请输入'} style={{ height: 80 }} />, //表单受控组件
                options: { initialValue: modifyData.remark ? modifyData.remark : '' }
            },
        ]
        return (
            <div className='shipmonitoring contentBox'>
                <div className="cardManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text" style={{width:300}}>查询指挥机的卡号集合</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="cardManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                指挥机ID：  <Input placeholder="请输入" style={{width: '70%'}} name='cmdId' value={this.state.cmdId} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    backgroundColor: '#81BEF8',
                                    border: 'unset'
                                }}
                                onClick={() => {
                                    this.setState({
                                        pagination: {
                                            total: 0,
                                            current: 1,
                                            pageSize: 10,
                                        },
                                    })
                                    this.selectCmdCards()
                                }}
                            >查询</Button>
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cmdId: -1,
                                        }, () => {
                                            //
                                        })
                                    }
                                }
                            >重置</Button>
                        </div>
                    </div>

                    <div className="cardManagement_title_buttons">
                        <div className="cardManagement_title_buttons_button" onClick={() => {
                            this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})
                        }} style={{borderRight: '1px solid #8D959C'}}>导出</div>

                    </div>
                </div>

                <div className="userManagement">
                    <Spin spinning={isLoading} size={"large"} color={"#123abc"} tip={"数据可劲加载中..."}>
                        <Table
                            columns={columns}
                            dataSource={userData}
                            scroll={{y:615}}
                            pagination={{
                                ...this.state.pagination,
                                showSizeChanger: true,//设置每页显示数据条数
                                //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                                pageSizeOptions:['10','50','100','1000'],
                                total: pageTotal,
                                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                    console.log(pageSize);
                                    let { selectMenuDataObj = {} } = this.state;
                                    selectMenuDataObj = {
                                        ...selectMenuDataObj,
                                        pageIndex: current,
                                        pageSize
                                    }
                                    this.setState({
                                        selectMenuDataObj, pageIndex: current, pageSize
                                    }, () => {
                                        this.selectCmdCards(selectMenuDataObj);
                                    })
                                }
                            }}
                            onChange={this.handleTableChange}

                        />
                    </Spin>
                </div>

                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertUser}
                    title="新增"
                />
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    submit={this.updateUser}
                    title="修改"
                />
                <ModalForm
                    visible={this.state.resetShow}
                    formItemList={resetList}
                    closeModal={this.closeModal}
                    submit={this.updatePwd}
                    title="重置密码"
                />
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.deleteUserById}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.code}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.manageShow}
                    onCancel={this.closeModal}
                    title="权限分配管理"
                    // footer={false}
                    width={826}
                    className="fenpeiModal"
                    onOk={this.bindUgroupRelation}
                >
                    <Search
                        placeholder="模糊搜索"
                        onSearch={value => this.getSearchValue(this.state.userGroupData, value)}
                        style={{ width: 200, float: 'right', margonBottom: 50 }}
                    />
                    <Table
                        columns={columns2}
                        //默认是复选框
                        rowSelection={{type: 'radio' ,distribute_rowSelect}}
                        //去掉复选框  onRowClick={this.onRowClick}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                    />
                </Modal>
            </div>

        )
    }
}
export default CommanderCardListSearch