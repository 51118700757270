import "ol/ol.css";
import {
    formatDegree
} from "../../../tools/Tools_POST_Request";
import {
    fromLonLat,
    get,
    transform,
    getTransform
} from 'ol/proj.js';
import { extend, createEmpty, getWidth, getHeight } from 'ol/extent';
import Render3D from 'ol-ext/layer/Render3D'
import WFS from 'ol/format/WFS.js'
import filter from 'ol/format/filter'
import GeoJSON from 'ol/format/GeoJSON'
import OSM from 'ol/source/OSM.js';
import Olmap from 'ol/Map.js';
import View from 'ol/View.js';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Coordinate from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import CircleStyle from 'ol/style/Circle';
import CircleGeom from 'ol/geom/Circle'
import ImageStyle from 'ol/style/Image';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
// import GeometryType from 'ol/geom/GeometryType';
import TileLayer from 'ol/layer/Tile.js';
import Graticule from 'ol/layer/Graticule';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import ImageLayer from 'ol/layer/Image.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import Cluster from 'ol/source/Cluster';
import Draw from 'ol/interaction/Draw';
import KML from 'ol/format/KML';
import TileWMS from 'ol/source/TileWMS.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import {
    createBox
} from 'ol/interaction/Draw';
import Collection from 'ol/Collection';
import Modify from 'ol/interaction/Modify';
import {
    shiftKeyOnly,
    singleClick
} from 'ol/events/condition';
import {
    unByKey
} from 'ol/Observable';
import { MousePosition, OverviewMap } from 'ol/control';
import ScaleLine from 'ol/control/ScaleLine';
import {
    defaults
} from 'ol/control';
import SelectPointAnimation from '../../../tools/SelectPointAnimation'
import closeImg from '../../../img/close.png'
import { Circle } from "ol/style";
import {
    getTianditu,
} from '../../../../tool_map/MapToolFun'

export default (function (window) {
    let projection = get("EPSG:3857");
    let sprojection = get('EPSG:4326');
    let Map = function (opt) {
        //地图divID
        /*传入参数的样子
            {
                target:"ubiMap",
                center:[121,29],
                zoom:1
            }
         */
        this.target = opt.target ? opt.target : "ubiMap";
        this.map = null;
        this.measureTooltipOverlayList = [];//测量工具，存储数据标识图层
        this.layers = {};//point layer
        this.layers_default = {};//point layer
        this.layersPolygon = {};//polygon layer
        this.clusterSource = {};
        this.layerStyle = {};
        this.showTextLayer = {};
        this.overlay = {};
        this.controls = {};
        this.hlLayers = []; // 启用高亮
        this.sfLayers = []; // 启用的点击交互图层
        this.clusterLayer = {};
        this._init.call(this, opt);
        this.draw_Measure = undefined;
    };

    //每次测绘结束，存储测绘数据标识图层
    Map.prototype.addTooltipOverlayList = function (overlayList) {
        this.measureTooltipOverlayList = this.measureTooltipOverlayList.concat(overlayList);
    }
    //用户结束测绘功能，清空地图上所有标识
    Map.prototype.clearTooltipOverlayList = function () {
        this.measureTooltipOverlayList.map(item => {
            this.map.removeOverlay(item);
        })
    }
    Map.prototype.addDraw_Measure_search = function (draw) {
        this.draw_Measure_search = draw;
    }
    Map.prototype.clearDraw_Measure_search = function () {
        this.map.removeInteraction(this.draw_Measure_search);
    }
    Map.prototype.addDraw_Measure = function (draw) {
        this.draw_Measure = draw;
    }
    Map.prototype.clearDraw_Measure = function () {
        this.map.removeInteraction(this.draw_Measure);
    }


    //添加瓦片图层
    Map.prototype.addTile = function (params) {
        var tilelayer;
        if (params.groupName) {
            if (this[params.groupName]) {
                tilelayer = this[params.groupName][params.target];
                this.map.removeLayer(tilelayer);
            }
        }
        if (!params || !params.key) return;

        tilelayer = new TileLayer({
            ...params,
            source: new XYZ({
                url: params.url,
            })
        });
        tilelayer.set("id", params.key);
        tilelayer.set("type", "tile");
        tilelayer.setVisible(params.visible);
        this.map.addLayer(tilelayer);
        if (params.groupName) {
            if (this[params.groupName]) {
                this[params.groupName][params.target] = tilelayer;
            } else {
                this[params.groupName] = {};
                this[params.groupName][params.target] = tilelayer;
            }
        }
        // this.layers[param.key] = tilelayer;

    };
    /**
     *@Description:{ 初始化map }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-16 18:52:42
    */
    Map.prototype._init = function (opt) {
        let tdAnnotation = getTianditu({
            'params': {
                'target': 'default_WMTS_tdAnnotation',
                'zIndex': '12',
            },
            'type': '矢量注记',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdAnnotation'] = tdAnnotation;
        let tdRoads = getTianditu({
            'params': {
                'target': 'default_WMTS_tdRoads',
            },
            'type': '矢量底图',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdRoads'] = tdRoads;
        let tdRemoteSensing = getTianditu({
            'params': {
                'target': 'default_WMTS_tdRemoteSensing',
                'visible': false,
            },
            'type': '影像底图',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdRemoteSensing'] = tdRemoteSensing;
        let tdAnnotationSensing = getTianditu({
            'params': {
                'target': 'default_WMTS_tdAnnotationSensing',
                'visible': false,
                'zIndex': '12',
            },
            'type': '影像注记',
            'proj': '经纬度投影',
            'key': 'd9ca4d944d60b4539825dbfc65daaa05'
        });
        this.layers_default['default_WMTS_tdAnnotationSensing'] = tdAnnotationSensing;

        //聚合图层
        // let vectorCluster = new VectorLayer({
        //     target: 'vectorCluster',
        //     zIndex: 107,
        //     visible: true

        // })

        //点图标展示图层数据
        var sourceTrajectory = new VectorSource({
            wrapX: false
        });
        let vectorTrajectory = new VectorLayer({
            target: 'vectorTrajectory',
            zIndex: 110,
            visible: true,
            source: sourceTrajectory,
        })
        this.layers.vectorTrajectory = vectorTrajectory;
        //点图标展示图层数据
        var sourceImg = new VectorSource({
            wrapX: false
        });
        let vectorImg = new VectorLayer({
            target: 'vectorImg',
            zIndex: 108,
            visible: false,
            source: sourceImg,

        })

        let vectorBorder = new VectorLayer({
            target: 'vectorBorder',
            zIndex: 106,
            visible: true

        })
        var r3D = new Render3D({
            height: 100, defaultHeight: 90.5, style: new Style({ //图层样式
                fill: new Fill({
                    color: 'rgba(10, 239, 247, 0.7)' //填充颜色
                }),
                stroke: new Stroke({
                    color: 'rgba(11, 148, 228, 0.8)', //边框颜色
                    width: 2 // 边框宽度
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'rgba(11, 148, 228, 0.8)'
                    })
                })
            })
        });
        vectorImg.setRender3D(r3D);
        this.layers.vectorImg = vectorImg;
        // this.layers.vectorCluster = vectorCluster;

        this.layersPolygon.vectorBorder = vectorBorder;//边界图层

        //加载圈选的绘制矢量层
        var source_draw_search = new VectorSource(); //图层数据源
        var vector_draw_search = new VectorLayer({
            target: 'vectorMapping_draw_search',
            name: 'vectorMapping_draw_search',
            zIndex: 109,
            source: source_draw_search,
            style: new Style({ //图层样式
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)' //填充颜色
                }),
                stroke: new Stroke({
                    color: 'rgba(11, 148, 228, 0.8)', //边框颜色
                    width: 2 // 边框宽度
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'rgba(11, 148, 228, 0.8)'
                    })
                })
            })
        });
        vector_draw_search.setRender3D(r3D);
        this.layers.vector_draw_search = vector_draw_search;
        //加载测量的绘制矢量层
        var source_draw = new VectorSource(); //图层数据源
        var vector_draw = new VectorLayer({
            target: 'vectorMapping_draw',
            name: 'vectorMapping_draw',
            zIndex: 109,
            source: source_draw,
            style: new Style({ //图层样式
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)' //填充颜色
                }),
                stroke: new Stroke({
                    color: 'rgba(11, 148, 228, 0.8)', //边框颜色
                    width: 2 // 边框宽度
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'rgba(11, 148, 228, 0.8)'
                    })
                })
            })
        });
        vector_draw.setRender3D(r3D);
        this.layers.vector_draw = vector_draw;
        // Set 3D renderer
        let mapDefaultToolList = (opt.createDefaultTool || typeof (opt.createDefaultTool) == 'undefined') ? [
            new MousePosition({
                coordinateFormat: (cood) => {
                    cood = transform(cood, 'EPSG:3857', 'EPSG:4326');
                    if (this._format) {
                        return formatDegree(cood[0]) + ',' + formatDegree(cood[1]);
                    }
                    return cood[0].toFixed(6) + ',' + cood[1].toFixed(6);
                },
                className: 'custom-mouse-position',
                target: document.getElementById('mouse-position'),
                zoom: false

            }),
            // new OverviewMap({
            //     className: 'custom-MapControl_OverviewMap',
            //     target: document.getElementById('mapControl_OverviewMap'),
            //     collapsible: false
            // })//全局地图控件，显示当前视口中的地图位于全局地图的哪一部分，
        ] : [];
        this.map = new Olmap({
            layers: [
                // tdRoads,
                // tdRemoteSensing,
                // tdAnnotationSensing,
                // tdAnnotation,
                // vectorCluster,
                vectorImg,
                vectorBorder,
                vector_draw,
                vector_draw_search,
                vectorTrajectory,
            ],
            view: new View({
                center: transform(opt.center, get('EPSG:4326'), get('EPSG:3857')),
                zoom: opt.zoom,
                maxZoom: opt.maxZoom ? opt.maxZoom : 18,
                minZoom: opt.minZoom ? opt.minZoom : 2
            }),
            target: this.target,
            controls: defaults({
                zoom: false,//禁用缩放按钮
            }).extend(
                mapDefaultToolList
            )
        });
        //判断是否需要加载默认天地图
        if (opt.createDefaultTianDi || typeof (opt.createDefaultTianDi) == 'undefined') {
            this.map.addLayer(tdRoads);
            this.map.addLayer(tdRemoteSensing);
            this.map.addLayer(tdAnnotationSensing);
            this.map.addLayer(tdAnnotation);
        }

        //添加 比例尺 控件
        if (opt.createDefaultTool || typeof (opt.createDefaultTool) == 'undefined') {
            var scaleLineControl = new ScaleLine({
                units: 'metric',
                target: 'scalebar',
                className: 'ol-scale-line'
                // className: 'custom-MapControl_scaleLineControl',
                // target: document.getElementById('mapControl_scaleLineControl'),
            });
            this.map.addControl(scaleLineControl);
        }
    };

    //添加热力图
    Map.prototype.addHeatMapLayer = function (data, params) {
        let vectorSource = new VectorSource({
            wrapX: false, //不在地图上重复
            // features: (new GeoJSON().readFeatures(polygonsDataGJ)),
        });
        data.map((item, index) => {
            let feature = new Feature({
                geometry: new Point(transform([item.lon, item.lat], 'EPSG:4326', 'EPSG:3857')),
                weight: item.weight,
                ...item,
            })
            vectorSource.addFeature(feature);
        })
        var vector = new HeatmapLayer({
            ...params,
            source: vectorSource,
            blur: parseInt(15, 10),
            radius: parseInt(15, 10),
            weight: function (feature) {
                // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
                // standards-violating <magnitude> tag in each Placemark.  We extract it from
                // the Placemark's name instead.
                var weight = feature.get('weight');
                return weight;
            }
        });
        this.map.addLayer(vector);
        this.layers[params.target] = vector;
    }
    //关闭图层
    Map.prototype.closeLayerVisibleBylayerName = function (layerName) {
        let layer = this.layers[layerName];
        if (layer) {
            layer.setVisible(false);
        }
        let layerPolygon = this.layersPolygon[layerName];
        if (layerPolygon) {
            layerPolygon.setVisible(false);
        }
        let layer_tiandi = this.layers_default[layerName];
        if (layer_tiandi) {
            layer_tiandi.setVisible(false);
        }
    }
    //开启图层
    Map.prototype.openLayerVisibleBylayerName = function (layerName) {
        let layer = this.layers[layerName];
        if (layer) {
            layer.setVisible(true);
        }
        let layerPolygon = this.layersPolygon[layerName];
        if (layerPolygon) {
            layerPolygon.setVisible(true);
        }
        let layer_tiandi = this.layers_default[layerName];
        if (layer_tiandi) {
            layer_tiandi.setVisible(true);
        }
    }
    //开启一组图层
    Map.prototype.openLayerVisibleByGroupName = function (name, childName) {
        let layers = this[name];
        if (layers) {
            if (childName) {
                Object.keys(layers).map(key => {
                    let layer = layers[key];
                    childName.map(item => {
                        if (key == item && layer) {
                            layer.setVisible(true);
                        }
                    })
                })
            } else {
                Object.keys(layers).map(key => {
                    let layer = layers[key];
                    if (layer) {
                        layer.setVisible(true);
                    }
                })
            }
        }
    }
    //关闭一组图层
    Map.prototype.closeLayerVisibleByGroupName = function (name, childName, isClear,) {

        let layers = this[name];
        if (layers) {
            if (childName) {
                Object.keys(layers).map(key => {
                    let layer = layers[key];
                    childName.map(item => {
                        if (layer && key.indexOf('overlay') != -1) {
                            layer.setPosition(undefined);
                            layer = null;
                            this[name][key] = null;
                        }
                        else if (key == item && layer) {
                            layer.setVisible(false);
                            if (isClear) {
                                if (layer.getSource && layer.getSource().clear) {
                                    layer.getSource().clear();
                                }
                                this.map.removeLayer(key);
                                delete layers[key];
                            }
                        }
                    })
                })
            } else {
                Object.keys(layers).map(key => {
                    let layer = layers[key];
                    if (layer) {
                        layer.setVisible(false);
                        if (isClear) {
                            if (layer.getSource && layer.getSource().clear) {
                                layer.getSource().clear();
                            }
                            this.map.removeLayer(key);
                            delete layers[key];
                        }
                    }
                })
            }
        }
    }

    Map.prototype.addEventChangeResolution = function (obj, fun) {
        let map = this.map;
        let view = this.map.getView();
        fun(map);
        let interval_ChangeResolution = view.on('change:resolution',
            function () {
                fun(map)
            }
        );
        return interval_ChangeResolution;
    }
    Map.prototype.clearEventChangeResolution = function (obj, fun, listener) {
        console.log('---------------unClick_getFeatureInfo----------------------')
        unByKey(listener);
        let map = this.map;
        let view = this.map.getView();
        view.un('change:resolution',
            function () {
                fun(map)
            }
        );
        // this.map.un('click', function (e) {
        //     //在点击时获取像素区域
        //     let pixel = this.getEventPixel(e.originalEvent);
        //     let ids = [];
        //     this.forEachFeatureAtPixel(pixel, function (feature) {
        //         //coodinate存放了点击时的坐标信息
        //         let coodinate = e.coordinate;
        //         let id = feature.ol_lm.propertychange ? feature.ol_lm.propertychange[0].target.values_.id : undefined;
        //         console.log(id, coodinate, '-----id, div, overlay, coodinate----un--地图点击图标事件-------')
        //         // obj.getPointDetail(id, div, overlay, coodinate)
        //         if (id) {
        //             ids = ids.concat(id);
        //         }
        //     });
        //     if (ids.length > 0) {
        //         obj.getPointDetail(ids);//将获取到的点id传给父组件，并且展示modal
        //     }
        // });
    }
    // Map.prototype.createEventChangeResolution = function (obj) {
    //     let map = this.map;
    //     let view = this.map.getView();
    //     // let vectorCluster = this.layers.vectorCluster;
    //     let vectorImg = this.layers.vectorImg;
    //     let level = 12;
    //     let thisObj = this;
    //     view.on('change:resolution', function () {
    //         let zoom = view.getZoom();
    //         let isCanChange = false;
    //         if (zoom >= 11) {
    //             if (level == 12) {
    //                 isCanChange = true;
    //                 level = 10;
    //             }
    //         }
    //         else if (zoom < 11) {
    //             if (level == 10) {
    //                 isCanChange = true;
    //                 level = 12;
    //             }
    //         }
    //         console.log(view.getZoom(), level, "---------------删除聚合");
    //         if (isCanChange) {
    //             let isOverRule = level == 12;
    //             obj.showMap_addPoints_zoom_control(isOverRule);
    //             if (isOverRule) {
    //                 thisObj.unClick_getFeatureInfo(obj);
    //             } else {
    //                 thisObj.onClick_getFeatureInfo(obj);
    //             }
    //         }
    //         if (zoom > 16) {
    //             obj.showMap_addPoints_zoom_control(false);
    //         }

    //     })
    // }

    /**
     *@Description:{ 
         所选区域（镇，片，河）的边界闪烁
         因为是边界闪烁，所以不填充切片
         }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-12-23 14:16:33
    */
    Map.prototype.autoChangeLayerStyle_border = function (isFlicker, target) {
        let layer
        let styleF = new Style({ //默认样式,Object.assign合并对象
            // fill: new Fill({
            //     color: 'rgba(12, 222, 214, 1.0)',
            //     // width: 3

            // }),
            stroke: new Stroke({
                color: 'rgba(12, 222, 214, 1.0)',
                width: 5
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            // })
        })
        let styleNoF = new Style({ //默认样式,Object.assign合并对象
            // fill: new Fill({
            //     color: 'rgba(0, 0, 255, 1.0)',
            // }),
            stroke: new Stroke({
                color: 'rgba(22, 202, 234, 0.3)',
                // color: 'rgba(12, 12, 160,, 0.2)',
                width: 5
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 0.2)'
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 0.2)',
            //         // width: 5
            //     }),
            // })
        })
        layer = this.layersPolygon[target]
        console.log(isFlicker, '----------------------------border----auto change layer style')
        if (layer && isFlicker) {
            layer.setStyle(styleF)
        } else if (layer && !isFlicker) {
            layer.setStyle(styleNoF)
        }
    }

    /**
     *@Description:{ 自动切换 应巡河道切片样式}
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-28 11:02:36
    */
    Map.prototype.autoChangeLayerStyle = function (isFlicker) {
        let layer
        let styleF = new Style({ //默认样式,Object.assign合并对象
            fill: new Fill({
                color: 'rgba(12, 222, 214, 1.0)',
                // width: 3

            }),
            stroke: new Stroke({
                color: 'rgba(12, 222, 214, 1.0)',
                width: 0
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 1.0)',
            //         // width: 3
            //     }),
            // })
        })
        let styleNoF = new Style({ //默认样式,Object.assign合并对象
            fill: new Fill({
                color: 'rgba(0, 0, 255, 1.0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 255, 1.0)',
                // color: 'rgba(12, 12, 160,, 0.2)',
                width: 0
            }),
            // image: new CircleStyle({
            //     // radius: 7,
            //     fill: new Fill({
            //         color: 'rgba(12, 12, 160,, 0.2)'
            //     }),
            //     stroke: new Stroke({
            //         color: 'rgba(12, 12, 160,, 0.2)',
            //         // width: 5
            //     }),
            // })
        })
        Object.keys(this.layersPolygon).map(key => {
            if (key === 'showMap_DBData_standard') {
                layer = this.layersPolygon[key]
            }
        })
        // console.log(layer, '--------------------------------auto change layer style')
        if (layer && isFlicker) {
            layer.setStyle(styleF)
        } else if (layer && !isFlicker) {
            layer.setStyle(styleNoF)
        }
    }

    /**
     *@Description:{ 添加边界图层数据 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-12-23 14:11:30
    */
    Map.prototype.addLayer_polygon_border = function (features, target) {
        if (features && features.length > 0) {

            let color = 'rgba(12, 222, 214, 1.0)';
            let vectorSource = new VectorSource({
                wrapX: false, //不在地图上重复
                // features: (new GeoJSON().readFeatures(features)),
            });
            let style = new Style({ //默认样式,Object.assign合并对象
                // fill: new Fill({
                //     color: color,
                //     width: 1

                // }),
                stroke: new Stroke({
                    color: color,
                    width: 5
                }),
                // image: new CircleStyle({
                //     // radius: 7,
                //     fill: new Fill({
                //         color: 'rgba(12, 12, 160,, 1.0)',
                //         // width: 3
                //     }),
                //     stroke: new Stroke({
                //         color: 'rgba(12, 12, 160,, 1.0)',
                //         // width: 3
                //     }),
                // })
            })
            let featurePolygons = [];
            features.map((item, index) => {
                if (item.target && item.target == 'areaBorder_city') {
                    let featurePolygon;
                    const polygonsData = item.geometry.coordinates;
                    let polygon = new Polygon(JSON.parse(polygonsData));
                    polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
                    featurePolygon = new Feature(polygon);
                    featurePolygons = featurePolygons.concat(featurePolygon);
                    vectorSource.addFeature(featurePolygon);
                } else {
                    vectorSource.addFeature(item);
                }
            })
            if (featurePolygons.length > 0) {
                changeZoomAndCenter(this, 10, featurePolygons);
            } else {
                changeZoomAndCenter(this, 10, features);
            }


            let vectorLayer = this.layersPolygon[target];
            vectorLayer.setSource(vectorSource);
            vectorLayer.setVisible(true);
            vectorLayer.setStyle(style);
            console.log(vectorLayer, '---------------addLayer_polygon_border--')

        } else {
            let vectorLayer = this.layersPolygon[target];
            vectorLayer.setVisible(false);
        }
    }
    /**
     *@Description:{ add vector layer}
        *@InterfaceAddress:{ }
        *@parameter: { 
            polygonsData:Polygons data
            id:feature id
            style
    }
    *@ClassAuthor: ZDong
    *@Date: 2019-11-16 18:53:24
    */
    Map.prototype.addLayer_Polygon_DBData = function (polygonsDatas, params) {
        let color = params.color ? params.color : 'rgba(255,127,0,0.5)';
        let target = params.target ? params.target : 'default_polygon_Layer';
        let zIndex = params.zIndex ? params.zIndex : 11;
        let isChangeZoomAndCenter = typeof (params.isChangeZoomAndCenter) != 'undefined' ? params.isChangeZoomAndCenter : true;
        let vectorSource = new VectorSource({
            wrapX: false, //不在地图上重复
            // features: (new GeoJSON().readFeatures(polygonsDataGJ)),
        });
        let featuresFit = []
        for (let index = 0; index < polygonsDatas.length; index++) {
            let featurePolygon
            const polygonsData = polygonsDatas[index];
            let polygon = new Polygon(polygonsData.geom);
            polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
            featurePolygon = new Feature(polygon);
            if (polygonsData.name) {
                featurePolygon.setStyle(new Style({ //默认样式,Object.assign合并对象
                    text: new Text({
                        font: '14px Microsoft YaHei',
                        text: polygonsData.name,
                        fill: new Fill({
                            color: '#666'
                        })
                    }),
                    fill: new Fill({
                        color: color,
                    }),
                    stroke: new Stroke({
                        color: color,
                        width: 2
                    }),
                }))
            }
            vectorSource.addFeature(featurePolygon);
            featuresFit = [
                ...featuresFit,
                featurePolygon
            ]
        }
        if (isChangeZoomAndCenter && featuresFit.length > 0) {
            this.changeZoomAndCenter(this, 11, featuresFit)
        }
        let vectorLayer = this.layersPolygon[target]
        if (vectorLayer) {
            vectorLayer.setSource(vectorSource)
            vectorLayer.setVisible(true)
        } else {
            vectorLayer = new VectorLayer({
                zIndex: zIndex,
                target: target,
                source: vectorSource,
                visible: true,
                style: new Style({ //默认样式,Object.assign合并对象
                    fill: new Fill({
                        color: color,
                    }),
                    stroke: new Stroke({
                        color: color,
                        width: 2
                    }),
                    // image: new CircleStyle({
                    //     radius: 7,
                    //     fill: new Fill({
                    //         color: color
                    //     })
                    // })
                }),
            });
            this.map.addLayer(vectorLayer);
            this.layersPolygon = {
                ...this.layersPolygon,
                [target]: vectorLayer
            }
        }
    }

    Map.prototype.changeZoomAndCenter_for_geom = function (polygonsDatas) {
        let vectorSource = new VectorSource({
            wrapX: false, //不在地图上重复
            // features: (new GeoJSON().readFeatures(polygonsDataGJ)),
        });
        let featuresFit = []
        for (let index = 0; index < polygonsDatas.length; index++) {
            let featurePolygon
            const polygonsData = polygonsDatas[index];
            let polygon = new Polygon(polygonsData.geom);
            polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
            featurePolygon = new Feature(polygon);

            vectorSource.addFeature(featurePolygon);
            featuresFit = [
                ...featuresFit,
                featurePolygon
            ]
        }
        if (featuresFit.length > 0) {
            this.changeZoomAndCenter(this, 11, featuresFit)
        }
    }

    /**
     *@Description:{ 添加WMS图层 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-16 20:08:45
    */
    Map.prototype.addWMSLayer = function (params) {
        let wmsUrl = params.url ? params.url : 'http://221.181.88.134:6706/RS/wms?Service=WMS';
        let optionalParams = params.optionalParams ? params.optionalParams : {};
        let lyr = new ImageLayer({
            ...optionalParams,
            target: params.target,
            source: new ImageWMS({
                url: wmsUrl,
                // projection:"EPSG:4326",
                params: {
                    // 'FORMAT':'application/openlayers',
                    // 'FORMAT': 'image/png',
                    // 'VERSION': '1.1.0',
                    //'BBOX':[113.029319763184,22.7097873687744,113.95068359375,23.7140617370605],
                    // 'tiled': false,
                    //'TILED':false,
                    // 'singleTile':true, 
                    'TILED': false,
                    'LAYERS': params.layerName
                    // 'STYLES': '',
                    // 'CRS':'EPSG:4326',
                },
                serverType: 'geoserver'
                // strategy: bboxStrategy
            })
        });
        this.map.addLayer(lyr);
        this.layers[params.target] = lyr;
    }


    /**
     *@Description:{ 通过url向GeoServe请求Feature，创建wfs图层 }
     *@InterfaceAddress:{ }
     *@parameter: { 
                    data:{
                        url:
                        params:{
                            layerName:'sunt: ',
                            srs: 'EPSG:3857',
                            name: ' ',
                        }
                    }
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-17 19:17:11
    */
    Map.prototype.createWFSLayerDict = function (obj, layerName, target, name) {
        let data = {
            url: 'http://221.181.88.134:6706/geoserver/sunt/wfs',
            params: {
                layerName: layerName,
                // layerName: 'sunt:river_name_liu',
                srs: 'EPSG:3857',
                name: name,
                // name: '水闸监控图层'
            },
            target: target,
        }
        //     let wfsUrl = ''
        // let vectorSource = new VectorSource({
        //     url: wfsUrl = function (extent) {
        //         return data.url + '?service=WFS&' +
        //             'version=1.1.0&request=GetFeature&typename=' + data.params.layerName +
        //             '&outputFormat=application/json&srsname=' + data.params.srs +
        //             '&bbox=' + extent.join(',') + ',' + data.params.srs;
        //     },
        //     strategy: bboxStrategy
        // })
        // //.log(wfsUrl, "----------------wfsUrl")
        // let featureRequest = new WFS().writeGetFeature({
        //     srsName: 'EPSG:3857',
        //     featureNS: 'http://openstreemap.org',
        //     featurePrefix: 'osm',
        //     featureTypes: ['water_areas'],
        //     outputFormat: 'application/json',
        //     filter: filter.and(
        //         filter.like('name', 'Mississippi*'),
        //         filter.equalTo('waterway', 'riverbank')
        //     )
        // });
        // fetch(wmsUrl, {
        //     method: 'GET',
        //     // body: new XMLSerializer().serializeToString(featureRequest)
        // }).then(function (response) {
        //     return response.json();
        // }).then(function (json) {
        //     //.log(JSON.stringify(json), "wfs请求后台，返回的json数据");
        //     // alert("加载到的要素信息："+JSON.stringify(json));
        //     let features = new ol.format.GeoJSON().readFeatures(json);
        //     vectorSource.addFeatures(features);
        //     map.getView().fit(vectorSource.getExtent());
        //     let fs = vectorSource.getFeatures();
        //     // //.log(fs);
        //     //.log(vectorSource.getFeatures(), "--------getFeatures")
        //     //.log(vectorSource.getFeatures()[1].values_, "--------getFeatures")
        // });
        let features = []
        let vectorSource = new VectorSource({
            format: new GeoJSON(),
            loader: function (extent) {
                let url = data.url + '?service=WFS&' +
                    'version=1.1.0&request=GetFeature&typename=' + data.params.layerName +
                    '&outputFormat=application/json&srsname=' + data.params.srs +
                    '&bbox=' + extent.join(',') + ',' + data.params.srs;
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                let onError = function () {
                    vectorSource.removeLoadedExtent(extent);
                }
                xhr.onerror = onError;
                xhr.onload = function () {
                    if (xhr.status == 200) {
                        if (obj.state.dict_library_wfsFeatures) {
                        } else {
                            features = vectorSource.getFormat().readFeatures(xhr.responseText)
                            obj.setWFSFeaturesDict(features, target)
                        }
                        // vectorSource.addFeatures(features)
                    } else {
                        onError();
                    }
                }
                xhr.send();
            },
            strategy: bboxStrategy
        });
        let vector = new VectorLayer({
            visible: true,
            source: vectorSource,
            name: data.name,
            target: data.target,
        });
        this.map.addLayer(vector);
        // this.map.removeLayer(vector)
        return features;
    }

    /**
     *@Description:{ 根据features 创建图层}
     *@InterfaceAddress:{ }
     *@parameter: { 
                       
                   }
     *@ClassAuthor: ZDong
     *@Date: 2019-11-21 11:49:58
    */
    Map.prototype.createWFSLayer = function (features) {
        let vectorSource = new VectorSource({
            format: new GeoJSON(),
            strategy: bboxStrategy
        });
        vectorSource.addFeatures(features)
        let vector = new VectorLayer({
            visible: true,
            source: vectorSource,
        });
        return vector;
    }

    /**
     *@Description:{ 传递参数调用创建图层方法，并且把返回的layer添加进地图 }
     *@InterfaceAddress:{ }
     *@parameter: {
         data:{
             url:
             params:{
                 layerName:'sunt: ',
                 srs: 'EPSG:3857',
                 name: ' ',
                }
            }
        }
        *@ClassAuthor: ZDong
        *@Date: 2019-11-17 19:17:11
        */
    Map.prototype.addLayer_WFS = function (data) {
        let newLayer = this.creatAndAddNewlayer(data)
        this.map.addLayer(newLayer);
    }
    Map.prototype.creatAndAddNewlayer = function (params) {
        let target = params.target;
        if (this[params.groupName] && this[params.groupName][target] && this[params.groupName][target].getSource()) {
            this[params.groupName][target].getSource().clear();
        } else {
            if (typeof (this[params.groupName]) == 'undefined') {
                this[params.groupName] = {}
            }
            let vector = new VectorLayer({
                ...params,
                visible: true,
            })
            vector.setVisible(params.visible);
            this.map.addLayer(vector);
            this[params.groupName][target] = vector;
        }
        let source = this[params.groupName][target].getSource();
        if (source) {
        } else {
            source = new VectorSource({
                wrapX: false
            });
            this[params.groupName][target].setSource(source);
        }
    }

    Map.prototype.addIcon_point = function (point, vectorStyle) {
        let zoomNow = this.map.getView().getZoom();
        let isOverRule_pointText = false;
        if (zoomNow > 17) {
            isOverRule_pointText = true;
        }
        let target = vectorStyle.target;
        let vector;
        if (vectorStyle.groupName) {
            if (this[vectorStyle.groupName]) {
                if (this[vectorStyle.groupName][target]) {
                    vector = this[vectorStyle.groupName][target];
                } else {
                    vector = new VectorLayer({
                        ...vectorStyle
                    })
                    this[vectorStyle.groupName][target] = vector;
                    this.map.addLayer(vector);
                }
            } else {
                vector = new VectorLayer({
                    ...vectorStyle
                })
                this[vectorStyle.groupName] = {}
                this[vectorStyle.groupName][target] = vector;
                this.map.addLayer(vector);
            }
        } else {
            if (this.layers[target]) {
                vector = this.layers[target];
            } else {
                vector = new VectorLayer({
                    ...vectorStyle
                })
                this.layers[target] = vector;
                this.map.addLayer(vector);
            }
        }

        // let vector;
        // if (this.layers[vectorStyle.target]) {
        //     vector = this.layers[vectorStyle.target];
        // } else {
        //     vector = new VectorLayer({
        //         target: vectorStyle.target,
        //         zIndex: vectorStyle.zIndex,
        //         visible: false,
        //     })
        //     this.map.addLayer(vector);
        //     this.layers[vectorStyle.target] = vector;
        // }
        let source = vector.getSource();
        if (source) {
            if (vectorStyle.clear) {
                source.clear();
            }
        } else {
            source = new VectorSource({
                wrapX: false
            });
        }
        let feature = new Feature({
            geometry: new Point(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857')),
            ...point,
        })
        let iconImg = undefined;
        if (point.pointImg) {
            iconImg = point.pointImg;
        }
        if (iconImg) {
            let icon = new Icon({
                src: iconImg,
                // anchor: [0, 0],
                rotateWithView: true,
                rotation: point.heading ? String((point.heading) * Math.PI / 180) : String((0) * Math.PI / 180),
            });
            feature.setStyle(new Style({
                image: icon,
                //文本样式
                text: new Text({
                    offsetY: -10,
                    textBaseline: 'bottom',
                    //文本内容
                    text: isOverRule_pointText ? point.name : '',
                    //填充
                    fill: new Fill({
                        color: '#0066CC'
                    })
                })
            }))
        }
        source.addFeature(feature);
        vector.setSource(source);
        vector.setVisible(vectorStyle.visible);
        console.log(vector.getSource().getFeatures().length, this[vectorStyle.groupName], '----------------------addIcon_point----------------')
        // vector.setStyle(style);
    }
    //创建网格图层
    Map.prototype.createGraticuleLayer = function (params) {
        let thisObj = this;
        this.graticuleLayer = new Graticule({
            // map: this.map,
            maxLines: params.maxLines ? params.maxLines : 100,
            opacity: params.opacity ? params.opacity : 0.0,
            strokeStyle: new Stroke({
                color: params.color ? params.color : 'rgba(12, 12, 12, 0.8)',
                width: params.width ? params.width : 0.6,
                lineDash: [10, 10, 10, 10], // 重点在这
            }),
            targetSize: params.targetSize ? params.targetSize : 100,
            showLabels: true,
            lonLabelStyle: new Text({
                font: '12px Calibri,sans-serif',
                textBaseline: 'bottom',
                fill: new Fill({
                    color: 'rgba(0,0,0,1)'
                }),
                stroke: new Stroke({
                    color: 'rgba(255,255,255,1)',
                    width: 3
                })
            }),
            latLabelStyle: new Text({
                font: '12px Calibri,sans-serif',
                textAlign: 'end',
                fill: new Fill({
                    color: 'rgba(0,0,0,1)'
                }),
                stroke: new Stroke({
                    color: 'rgba(255,255,255,1)',
                    width: 3
                })
            })
        })
        this.graticuleLayer.setMap(this.map);
        if (params.callback) {
            setTimeout(function () {
                params.callback(thisObj.graticuleLayer);
            }, 1500);
        }
    }

    Map.prototype.addIcon_FeatureAndStyle = function (points, vectorStyle) {
        // if (this.layers.vectorCluster && this.layers.vectorCluster.getSource()) {
        //     this.layers.vectorCluster.getSource().clear();
        // }

        let target = vectorStyle.target;
        let zIndex = vectorStyle.zIndex;
        let isCluster = vectorStyle.isCluster;

        let vector;
        if (vectorStyle.groupName) {
            if (this[vectorStyle.groupName]) {
                if (this[vectorStyle.groupName][target]) {
                    vector = this[vectorStyle.groupName][target];
                } else {
                    vector = new VectorLayer({
                        ...vectorStyle
                    })
                    this[vectorStyle.groupName][target] = vector;
                    this.map.addLayer(vector);
                }
            } else {
                vector = new VectorLayer({
                    ...vectorStyle
                })
                this[vectorStyle.groupName] = {}
                this[vectorStyle.groupName][target] = vector;
                this.map.addLayer(vector);
            }
        } else {
            if (this.layers[target]) {
                vector = this.layers[target];
            } else {
                vector = new VectorLayer({
                    ...vectorStyle
                })
                this.layers[target] = vector;
                this.map.addLayer(vector);
            }
        }
        let source = vector.getSource();
        if (source) {
            if (vectorStyle.clear) {
                source.clear();
            }
        } else {
            source = new VectorSource({
                wrapX: false
            });
        }

        let pointImg = require('../../../img/point_solid_blue.png');
        // points.length = 100
        let pointStyle;
        points.map((item, index) => {
            if (item.lon && item.lat
                && item.lon < 180 && item.lon > -180
                && item.lat < 90 && item.lat > -90
            ) {
                let iconImg = pointImg;
                if (item && item.pointImg) {
                    iconImg = item.pointImg;
                }
                // switch (item.networkFlag) {
                //     case 0: iconImg = cameraUnRunningImg; break
                //     case 1: iconImg = cameraRunningImg; break
                // }

                let coordinate = [item.lon, item.lat]
                if (vectorStyle.isOverRule) {
                    let feature = new Feature({
                        geometry: new Point(transform(coordinate, 'EPSG:4326', 'EPSG:3857')),
                        ...item,
                    })
                    let color_CircleStyle = '#00FF7F'
                    switch (item.emergencyStatus) {
                        case 0: color_CircleStyle = '#00FF7F'; break
                        case 1: color_CircleStyle = '#DC143C'; break
                    }

                    feature.setStyle(new Style({
                        //点样式
                        image: new CircleStyle({
                            //点的半径
                            radius: 2,
                            //笔触
                            stroke: new Stroke({
                                color: '#fff',
                                width: 0.1
                            }),
                            //填充
                            fill: new Fill({
                                color: color_CircleStyle,
                            })
                        }),
                        //文本样式
                        text: new Text({
                            textBaseline: 'bottom',
                            offsetY: -20,
                            //文本内容
                            text: vectorStyle.havePointText ? item.name : '',
                            //填充
                            fill: new Fill({
                                color: item.pointTextColor ? item.pointTextColor : '#0066CC'
                            })
                        })
                    }))
                    source.addFeature(feature)
                } else {
                    let feature = new Feature({
                        geometry: new Point(transform(coordinate, 'EPSG:4326', 'EPSG:3857')),
                        ...item,
                    })
                    if (iconImg) {
                        let icon = new Icon({
                            src: iconImg,
                            // anchor: [0, 0],
                            rotateWithView: true,
                            rotation: item.heading ? String((item.heading) * Math.PI / 180) : String((0) * Math.PI / 180),
                        });
                        pointStyle = new Style({
                            image: icon,
                            //文本样式
                            text: new Text({
                                offsetY: -20,
                                textBaseline: 'bottom',
                                //文本内容
                                text: vectorStyle.havePointText ? item.name : '',
                                //填充
                                fill: new Fill({
                                    color: item.pointTextColor ? item.pointTextColor : '#0066CC'
                                })
                            })
                        })
                        feature.setStyle(pointStyle)
                    }
                    if (item.pointColor) {
                        pointStyle = new Style({
                            stroke: new Stroke({
                                color: item.pointColor ? item.pointColor : '#fff',
                                width: 1
                            }),
                            //填充
                            fill: new Fill({
                                color: item.pointColor ? item.pointColor : '#1AACFF',
                            }),
                            //点样式
                            image: new CircleStyle({
                                //点的半径
                                radius: 3,
                                //笔触
                                stroke: new Stroke({
                                    color: item.pointColor ? item.pointColor : '#fff',
                                    width: 1
                                }),
                                //填充
                                fill: new Fill({
                                    color: item.pointColor ? item.pointColor : '#1AACFF',
                                })
                            }),
                        })
                        feature.setStyle(pointStyle)
                    }
                    source.addFeature(feature)
                }
            }
        })
        vector.setSource(source);
        if (isCluster) {
            vector.setZIndex(16)
            let clusterStyleFun = function (feature, solution) {
                var style;
                var size = feature.get('features').length;//每个点当前的聚合点数
                let radius = feature.get('radius') ? feature.get('radius') : 10 + size
                if (size / 10000 > 1) {
                    radius = radius > 100 ? 100 : radius
                }
                else if (size / 1000 > 1) {
                    radius = radius > 80 ? 80 : radius
                }
                else if (size / 100 > 1) {
                    radius = radius > 60 ? 60 : radius
                }
                else if (size / 10 > 1) {
                    radius = radius > 40 ? 40 : radius
                }
                if (size > 1) {//设置聚合效果样式
                    radius = radius > 20 ? 20 : radius
                    // let maxFeatureCount = 0;
                    // var features = vector.getSource().getFeatures();
                    // var feature, radius;
                    // for (var i = features.length - 1; i >= 0; --i) {
                    //     feature = features[i];
                    //     var originalFeatures = feature.get('features');
                    //     var extent = createEmpty();
                    //     var j, jj;
                    //     for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
                    //         extend(extent, originalFeatures[j].getGeometry().getExtent());
                    //     }
                    //     maxFeatureCount = Math.max(maxFeatureCount, jj);
                    //     radius = 0.25 * (getWidth(extent) + getHeight(extent)) / solution;
                    // }

                    // feature.set('radius', radius);
                    style = new Style({
                        image: new CircleStyle({
                            radius: radius,//获取聚合圆圈的半径大小，聚合的点数越多，圆圈的半径越大
                            fill: new Fill({
                                color: [255, 153, 0, 0.5],
                                // color: [255, 153, 0, Math.min(0.8, 0.4 + (size / maxFeatureCount))],
                            })
                        }),
                        text: new Text({
                            text: size.toString(),
                            fill: new Fill({
                                color: '#1AACFF',
                            }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 5
                            }),
                        })
                    });

                } else {//设置没有聚合效果的原始样式
                    style = feature.get('features')[0].getStyle();
                }
                return style;
            };
            let clusterSource = new Cluster({
                distance: 50, // 标注元素之间的间距，单位是像素。
                source: source,//数据源
            });
            source = clusterSource
            vector.setStyle(clusterStyleFun)
        }
        vector.setSource(source);
        vector.setVisible(vectorStyle.visible);
    }
    Map.prototype.removeAll = function (noNeedRemove) {
        Object.keys(this.layers).map(item => {
            let isNeedRemove = true;
            if (noNeedRemove && noNeedRemove.length > 0) {
                noNeedRemove.map((target, index) => {
                    if (target == item) {
                        isNeedRemove = false;
                    }
                })
            }
            if (isNeedRemove && this.layers[item] && this.layers[item].getSource()) {
                this.layers[item].getSource().clear();
            }
        })
        Object.keys(this.layersPolygon).map(item => {
            let isNeedRemove = true;
            if (noNeedRemove && noNeedRemove.length > 0) {
                noNeedRemove.map((target, index) => {
                    if (target == item) {
                        isNeedRemove = false;
                    }
                })
            }
            if (isNeedRemove && this.layersPolygon[item] && this.layersPolygon[item].getSource()) {
                this.layersPolygon[item].getSource().clear();
            }
        })
        Object.keys(this.overlay).map(key => {
            // this.overlay[key].setVisible(false)
            let div = document.getElementById('div_RegionalPort_head_overlay_' + key);
            if (div && div != null) {
                div.setAttribute("style", "display:none");
            }
            this.map.removeOverlay(key);
            delete this.overlay[key];
        })
        // this.layers={};
        // this.overlay={};
    }
    Map.prototype.removeOverlay = function (id) {
        if (!id || !this.overlay[id]) return;
        this.map.removeOverlay(this.overlay[id]);
        delete this.overlay[id];
    };

    Map.prototype.addMapMonitoringEvents = function (obj) {
        let thisObj = this;
        let view = this.map.getView();
        let listener_canClickFeatureMouseChangeHand = this.map.on('pointermove', function (e) {
            //在鼠标在地图上移动时获取像素区域
            let pixel = this.getEventPixel(e.originalEvent);
            let features = [];
            let values = [];
            thisObj.map.getTargetElement().style.cursor = "auto"
            this.forEachFeatureAtPixel(pixel, function (feature) {
                //添加选中特效
                // let pointLayer =  map.layers.vectorImg;
                // SelectPointAnimation.addPointAnimation(map,pointLayer,feature);

                //coodinate存放了点击时的坐标信息
                let zoom = view.getZoom();
                let coodinate = e.coordinate;
                // let id = feature.values_.isClickPoint ? feature.values_.id : undefined;
                //.log(id, coodinate, div, '-----id, div, overlay, coodinate----on--地图点击图标事件-------')
                // obj.getPointDetail(id, div, overlay, coodinate)
                if (zoom >= 11 && (feature.values_.isClickPoint || (feature.values_.features && feature.values_.features.length == 1 && feature.values_.features[0].values_.isClickPoint))) {
                    // features = features.concat(feature);
                    thisObj.map.getTargetElement().style.cursor = "pointer"
                }

                if (zoom >= 11) {
                    if (feature.values_.mouseOverCallback) {
                        feature.values_.mouseOverCallback(feature.values_);
                        values = values.concat(feature.values_);
                    }
                    else if (feature.values_.features && feature.values_.features.length == 1 && feature.values_.features[0].values_.mouseOverCallback) {
                        feature.values_.features[0].values_.mouseOverCallback(feature.values_.features[0].values_);
                        values = values.concat(feature.values_.features[0].values_);
                    }
                }
            });
            if (values.length > 0) {
                // obj.getPointDetail(values);//将获取到的点id传给父组件，并且展示modal
            } else {
                obj.onMove_empty();//触发空地点击事件
            }
            // if (features.length > 0) {
            //     obj.getPointDetail(features);//将获取到的点id传给父组件，并且展示modal
            // }
        });
    }
    Map.prototype.addOverlay_withDiv = function (params, obj) {
        let { target, groupName, div, coordinate, offset } = params;
        let overlay;
        if (this[groupName]) {
            if (this[groupName][target]) {
                // this.map.removeOverlay(this[groupName][target]);
                this[groupName][target].setPosition(undefined);
                this[groupName][target] = null;
                this[groupName] = {};
            }
        } else {
            this[groupName] = {};
        }
        overlay = new Overlay({
            id: target,
            //设置弹出框的容器
            element: div,
            positioning: 'bottom-center',
            //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
            autoPan: typeof (params.autoPan) != 'undefined' ? params.autoPan : true,
            offset: offset ? offset : [0, 0],
            stopEvent: false
        });
        this[groupName][target] = overlay;
        coordinate = transform(coordinate, 'EPSG:4326', 'EPSG:3857')
        overlay.setPosition(coordinate);//显示overlay
        overlay.setVisible(true)
        this.map.addOverlay(overlay);
        // overlay && overlay.getSource() && overlay.getSource().refresh()
        // this.map.getLayers().forEach(layer => layer && layer.getSource() && layer.getSource().refresh());
        return this[groupName];
    }
    Map.prototype.addOverLayer = function (id, obj) {
        this.map.removeOverlay(id)
        let div = document.createElement("div")
        let overlay = new Overlay({
            id: id,
            //设置弹出框的容器
            element: div,
            //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
            // autoPan: true
        });
        this.overlay[id] = overlay;
        this.onClick_getFeatureInfo(obj);
        // this.map.on('click', function (e) {
        //     //在点击时获取像素区域
        //     let pixel = this.getEventPixel(e.originalEvent);
        //     let ids = [];
        //     this.forEachFeatureAtPixel(pixel, function (feature) {
        //         //coodinate存放了点击时的坐标信息
        //         let coodinate = e.coordinate;
        //         let id = feature.ol_lm.propertychange[0].target.values_.id
        //         console.log(id, div, overlay, coodinate, '-----id, div, overlay, coodinate------地图点击图标事件-------')
        //         // obj.getPointDetail(id, div, overlay, coodinate)
        //         ids = ids.concat(id);
        //     });
        //     if (ids.length>0) {
        //         obj.getPointDetail(ids);//将获取到的点id传给父组件，并且展示modal
        //     }
        // });
    }
    var listener;
    Map.prototype.onClick_getFeatureInfo = function (obj) {
        let map = this;
        listener = this.map.on('click', function (e) {
            console.log('---------------onClick_getFeatureInfo-------------地图点击图标事件---------')
            //在点击时获取像素区域
            let pixel = this.getEventPixel(e.originalEvent);
            let ids = [];
            let values = [];
            this.forEachFeatureAtPixel(pixel, function (feature) {
                //添加选中特效
                // let pointLayer =  map.layers.vectorImg;
                // SelectPointAnimation.addPointAnimation(map,pointLayer,feature);

                //coodinate存放了点击时的坐标信息
                let coodinate = e.coordinate;
                let value = feature.values_;
                console.log(value, coodinate, feature, '-----id, div, overlay, coodinate----on--地图点击图标事件-------')
                // obj.getPointDetail(id, div, overlay, coodinate)
                if (feature.values_.isClickPoint) {
                    values = values.concat(feature.values_);
                }
                if (feature.values_.features && feature.values_.features.length == 1 && feature.values_.features[0].values_.isClickPoint) {
                    values = values.concat(feature.values_.features[0].values_);
                }
            });
            if (values.length > 0) {
                obj.getPointDetail(values);//将获取到的点id传给父组件，并且展示modal
            } else {
                obj.onClick_empty();//触发空地点击事件
            }
        });
    }
    Map.prototype.unClick_getFeatureInfo = function (obj) {
        console.log('---------------unClick_getFeatureInfo----------------------')
        unByKey(listener);
        this.map.un('click', function (e) {
            //在点击时获取像素区域
            let pixel = this.getEventPixel(e.originalEvent);
            let ids = [];
            this.forEachFeatureAtPixel(pixel, function (feature) {
                //coodinate存放了点击时的坐标信息
                let coodinate = e.coordinate;
                let id = feature.ol_lm.propertychange ? feature.ol_lm.propertychange[0].target.values_.id : undefined;
                console.log(id, coodinate, '-----id, div, overlay, coodinate----un--地图点击图标事件-------')
                // obj.getPointDetail(id, div, overlay, coodinate)
                if (id) {
                    ids = ids.concat(id);
                }
            });
            if (ids.length > 0) {
                obj.getPointDetail(ids);//将获取到的点id传给父组件，并且展示modal
            }
        });
    }

    /*支持 IE 浏览器*/
    /*支持 FireFox 浏览器*/
    /*支持 Chrome, Opera, Safari 等浏览器*/
    Map.prototype.addOverlay_setPosition = function (data, div, overlay, coodinate, obj) {
        // let closeImg = `<img id='closeOverlay' src=${require('../../../../img/close.png')} class='jk' alt='' style='margin-left: 230px;' />`
        let closeImg = document.createElement("img")
        div.setAttribute("style", "display:block")
        //给按钮添加单击事件
        closeImg.setAttribute("id", "closeOverlay")
        closeImg.setAttribute("src", `${require('../../../img/close.png')}`)
        closeImg.setAttribute("style", "margin-left: 265px;position:absolute;margin-top:-390px;z-index: 999;")
        // closeImg.setAttribute("style", "margin-left: 265px")
        div.innerHTML = ""
        let divMask = document.createElement("div");
        divMask.setAttribute("style", "position:absolute;top:0;bottom:0;right:0;left:0;background:rgba(0,0,0,0.6)")
        divMask.onclick = function () {
            divMask.setAttribute("style", "display:none")
        }
        let divModal = document.createElement("div");
        divModal.setAttribute("style", "width:600px;height:400px;margin:0 auto;margin-top:30px;z-index:99999")
        divMask.appendChild(divModal)
        let p_reportPicture = document.createElement("p");
        p_reportPicture.innerHTML = p_reportPicture.innerHTML + "取证照片 :"
        for (let index = 0, len = data.reportPictureList.length; index < len; index++) {
            const element = data.reportPictureList[index];
            if (element && element != null) {
                let img_reportPicture = document.createElement("img");
                img_reportPicture.setAttribute("width", "50");
                img_reportPicture.setAttribute("height", "50");
                img_reportPicture.setAttribute("src", element);
                p_reportPicture.appendChild(img_reportPicture)
                img_reportPicture.onclick = function () {
                    console.log(element, '-------------------------img_reportPicture')
                    obj.showModal(element, data.reportPictureList)
                }
            }
        }
        let p_rectPicture = document.createElement("p");
        p_rectPicture.innerHTML = p_rectPicture.innerHTML + "整改照片 :"
        for (let index = 0, len = data.rectPictureList.length; index < len; index++) {
            const element = data.rectPictureList[index];
            if (element && element != null) {
                let img_rectPicture = document.createElement("img");
                img_rectPicture.setAttribute("width", "50");
                img_rectPicture.setAttribute("height", "50");
                img_rectPicture.setAttribute("src", element);
                p_rectPicture.appendChild(img_rectPicture)
                img_rectPicture.onclick = function () {
                    obj.showModal(element, data.rectPictureList)
                }
            }
        }

        let p_problemId = document.createElement("p");
        p_problemId.innerHTML = p_problemId.innerHTML + "问题编号 :" + ((data.problemId && data.problemId != null) ? data.problemId : '');

        let p_problemType = document.createElement("p");
        p_problemType.innerHTML = p_problemType.innerHTML + "问题类型 :" + (data.problemType && data.problemType != null ? data.problemType : '');

        let p_foundTime = document.createElement("p");
        p_foundTime.innerHTML = p_foundTime.innerHTML + "发现时间 :" + (data.foundTime && data.foundTime != null ? data.foundTime : '');

        let p_riverName = document.createElement("p");
        p_riverName.innerHTML = p_riverName.innerHTML + "所在河道 :" + (data.riverName && data.riverName != null ? data.riverName : '');

        let p_peopleName = document.createElement("p");
        p_peopleName.innerHTML = p_peopleName.innerHTML + "报告员名 :" + (data.peopleName && data.peopleName != null ? data.peopleName : '');

        let p_state = document.createElement("p");
        p_state.innerHTML = p_state.innerHTML + "问题状态 :" + (data.state && data.state != null ? data.state : '');
        let divLevel2 = document.createElement("div");
        divLevel2.setAttribute("style",
            "margin-left: 10%; " +
            "position: absolute;" +
            // "-webkit-transform: translateY(-50%);" +
            // "-ms-transform: translateY(-50 %);" +
            // "-o-transform: translateY(-50 %);" +
            // "transform: translateY(-50 %);"
            "top: 5%;"
        )
        divLevel2.appendChild(p_problemId)
        divLevel2.appendChild(p_problemType)
        divLevel2.appendChild(p_foundTime)
        divLevel2.appendChild(p_riverName)
        divLevel2.appendChild(p_peopleName)
        divLevel2.appendChild(p_state)
        divLevel2.appendChild(p_reportPicture)
        divLevel2.appendChild(p_rectPicture)

        let divLevel1 = document.createElement("div");
        divLevel1.setAttribute("style",
            "position: relative;" +
            "text-align:left;" +
            "margin:0 auto;" +
            "filter: alpha(opacity = 80);" +
            "-moz-opacity: 0.80;" +
            "color:white;" +
            "border-radius: 5%;" +
            "background: black; " +
            "min-width:300px;" +
            "height:400px;" +
            "overflow: auto;"
        )
        divLevel1.appendChild(divLevel2);
        div.appendChild(divLevel1)

        // div.innerHTML = div.innerHTML +
        //     "<div " +
        //     "style='" +
        //     "position: relative;" +
        //     "text-align:left;" +
        //     "margin:0 auto;" +
        //     "filter: alpha(opacity = 80);" +
        //     "-moz-opacity: 0.80;" +
        //     "opacity: 0.80;" +
        //     "color:white;" +
        //     "border-radius: 5%;" +
        //     "background: black; " +
        //     "min-width:300px;" +
        //     "min-height:400px;" +
        //     "'>" +
        //     "<div " +
        //     "style='" +
        //     "margin-left: 25px; " +
        //     "position: absolute;" +
        //     "top: 45%;" +
        //     "-webkit-transform: translateY(-50%);" +
        //     "-ms-transform: translateY(-50 %);" +
        //     "-o-transform: translateY(-50 %);" +
        //     "transform: translateY(-50 %);" +
        //     "'>" +
        //     // `${closeImg}` +
        //     // closeImg +
        //     // "<div style='background:url(../../../../img/close.png)'></div>" +
        //     // "<img alt='' class='has' width='50' height='50' src={" + closeImg + "}>" +
        //     "<p> 问题编号 :" + ((data.problemId && data.problemId != null) ? data.problemId : '') + "</p>" +
        //     "<p> 问题类型 :" + (data.problemType && data.problemType != null ? data.problemType : '') + "</p>" +
        //     "<p> 发现时间 :" + (data.foundTime && data.foundTime != null ? data.foundTime : '') + "</p>" +
        //     "<p> 所在河道 :" + (data.riverName && data.riverName != null ? data.riverName : '') + "</p>" +
        //     "<p> 报告员名 :" + (data.peopleName && data.peopleName != null ? data.peopleName : '') + "</p>" +
        //     "<p> 问题状态 :" + (data.state && data.state != null ? data.state : '') + "</p>" +
        //     "<p> 取证照片 :<img alt='' class='has' width='50' height='50' src='" + data.reportPicture + "'></p>" +
        //     "<p> 整改照片 :<img alt='' class='has' width='50' height='50' src='" + data.rectPicture + "'></p>" +
        //     "</div>" +
        //     "</div>";



        //设置overlay的显示位置
        div.appendChild(closeImg)
        closeImg.onclick = function () {
            //.log("关掉Overlayer")
            //1.创建节点
            // div.remove()
            div.setAttribute("style", "display:none")
        }
        // var img = document.getElementById('closeOverlay');
        overlay.setPosition(coodinate);//显示overlay
        overlay.setVisible(true)
        this.map.addOverlay(overlay);
    }

    Map.prototype.closeAllLayers = function () {
        Object.keys(this.layers).map(key => {
            this.layers[key].setVisible(false)
        })
        Object.keys(this.layersPolygon).map(key => {
            this.layersPolygon[key].setVisible(false)
        })
        Object.keys(this.overlay).map(key => {
            this.map.removeOverlay(key)
        })
        let extent = [
            ...transform([120.493141, 30.938789], 'EPSG:4326', 'EPSG:3857'),
            ...transform([121.735883, 31.314396], 'EPSG:4326', 'EPSG:3857')
        ]
        // console.log(extent, '--------------------closeAllLayers here extent')
        this.map.getView().fit(extent)
    }

    Map.prototype.removeLayerAll = function () {
        // this.layers.vectorCluster.setVisible(false)
        this.layers.vectorImg.setVisible(false)
        Object.keys(this.overlay).map(key => {
            this.map.removeOverlay(key)
        })
    }
    Map.prototype.removeLayerWithTarget = function (target) {
        Object.keys(this.overlay).map(key => {
            if (key === target) {
                this.map.removeOverlay(key)
            }
        })
        Object.keys(this.layers).map(key => {
            if (key === target) {
                this.layers[key].setVisible(false)
                if (this.layers[key].getSource()) {
                    this.layers[key].getSource().clear();
                }
                // this.layers[key].getSource() ? this.layers[key].getSource().clear() : ''
                // this.map.removeLayer(key)
            }
        })
        Object.keys(this.layersPolygon).map(key => {
            if (key === target) {
                this.layersPolygon[key].setVisible(false)
                // this.layersPolygon[key].getSource() ? this.layersPolygon[key].getSource().clear() : ''
                if (this.layersPolygon[key].getSource()) {
                    this.layersPolygon[key].getSource().clear()
                }
                // this.map.removeLayer(key)
            }
        })
    }

    Map.prototype.changeZoomAndCenter_point = function (point, zoom, noWorkingZoom) {
        let view = this.map.getView();
        // flyTo(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'),view, function() {});//zoom加减达到飞往的效果
        let startZoom = view.getZoom();
        let endZoom = zoom ? zoom : view.getZoom();
        if (noWorkingZoom) {
            if (startZoom >= noWorkingZoom) {
                endZoom = startZoom;
            }
        }
        view.animate(
            {
                center: transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'),
                zoom: startZoom,
                duration: 1000,
            },
            {
                center: transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'),
                zoom: endZoom,
                duration: 1000,
            }
        );
    }
    function flyTo(location, view, done) {
        var duration = 2000;
        var zoom = view.getZoom();
        var parts = 2;
        var called = false;
        function callback(complete) {
            --parts;
            if (called) {
                return;
            }
            if (parts === 0 || !complete) {
                called = true;
                done(complete);
            }
        }
        view.animate({
            center: location,
            duration: duration
        }, callback);
        view.animate({
            duration: duration / 2
        }, callback);
    }
    Map.prototype.changeZoomAndCenter = function (obj, zoom, features, center, isPoint, offset) {
        let extent;
        if (features && features.length > 0) {
            extent = getFitWithExtent(features, isPoint, offset);
            center = [(extent[2] - extent[0]) / 2 + extent[0], (extent[3] - extent[1]) / 2 + extent[1]];
        } else {
            center = transform(center, 'EPSG:4326', 'EPSG:3857');
        }
        let view = this.map.getView();
        // flyTo(transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'),view, function() {});//zoom加减达到飞往的效果
        if (extent) {
            view.animate({
                center: center,
                zoom: 9,
                duration: 1000,
            }, () => {
                view.fit(extent, {
                    duration: 1500,
                })
            });
        } else {
            view.animate(
                {
                    center: center,
                    zoom: 9,
                    duration: 1000,
                },
                {
                    center: center,
                    zoom: zoom,
                    duration: 1500,
                }
            );

        }


        // let point = obj.map.getView().getCenter()
        // point = transform(point, 'EPSG:3857', 'EPSG:4326')
        // let coordinate = [point[0] + 0.2, point[1]]
        // obj.map.getView().setCenter(transform(coordinate, 'EPSG:4326', 'EPSG:3857'));
        // if (point) {
        //     this.map.getView().setZoom(zoom)
        //     if (this.map.getView().getZoom() >= 58) {
        //         this.layers.vectorCluster.setVisible(false);
        //         this.layers.vectorImg.setVisible(true);
        //     }
        //     if (this.map.getView().getZoom() < 58) {
        //         this.layers.vectorCluster.setVisible(true);
        //         this.layers.vectorImg.setVisible(false);
        //     }
        // }
    }

    function getFitWithExtent(features, isPoint) {
        console.log(features, '-----------------getFitWithExtent----extent first')
        let point_max_x = 0;//extent
        let point_max_y = 0;//extent
        let point_min_x = 0;//extent
        let point_min_y = 0;//extent
        if (isPoint) {
            features.map((item, index) => {
                let lonlat = [item.lon, item.lat]
                if (index == 0) {
                    point_min_x = lonlat[0]
                    point_min_y = lonlat[1]
                }
                if (index == 1) {
                    point_max_x = lonlat[0]
                    point_max_y = lonlat[1]
                }
                point_min_x = point_min_x <= lonlat[0] ? point_min_x : lonlat[0]
                point_min_y = point_min_y <= lonlat[1] ? point_min_y : lonlat[1]
                point_max_x = point_max_x >= lonlat[0] ? point_max_x : lonlat[0]
                point_max_y = point_max_y >= lonlat[1] ? point_max_y : lonlat[1]
            })
        }
        else {
            features.map((item, index) => {
                let extent = item.getGeometry().getExtent();
                // console.log(extent, '---------------------extent first')
                if (index == 0) {
                    point_min_x = extent[0]
                    point_min_y = extent[1]
                    point_max_x = extent[2]
                    point_max_y = extent[3]
                }
                if (extent[0] == 0 || extent[1] == 0 || extent[2] == 0 || extent[3] == 0) {
                } else {
                    point_min_x = point_min_x <= extent[0] ? point_min_x : extent[0]
                    point_min_y = point_min_y <= extent[1] ? point_min_y : extent[1]
                    point_max_x = point_max_x >= extent[2] ? point_max_x : extent[2]
                    point_max_y = point_max_y >= extent[3] ? point_max_y : extent[3]
                }

            })
        }

        //根据业务要求添加偏移量
        let lonlatMin = [
            point_min_x,
            point_min_y
        ]
        let lonlatMax = [
            point_max_x,
            point_max_y
        ]
        if (isPoint) {
        } else {
            lonlatMin = transform(lonlatMin, 'EPSG:3857', 'EPSG:4326')
            lonlatMax = transform(lonlatMax, 'EPSG:3857', 'EPSG:4326')
        }

        lonlatMin = [
            (lonlatMin[0]),
            (lonlatMin[1]),
        ]
        lonlatMin = transform(lonlatMin, 'EPSG:4326', 'EPSG:3857')
        lonlatMax = [
            (lonlatMax[0]),
            (lonlatMax[1]),
        ]
        lonlatMax = transform(lonlatMax, 'EPSG:4326', 'EPSG:3857')
        let extent = [
            ...lonlatMin,
            ...lonlatMax
        ]


        // let extent = [
        //     point_min_x,
        //     point_min_y,
        //     point_max_x,
        //     point_max_y
        // ]
        console.log(extent, '----------------------------extent end')
        return extent
    }



    return Map;
})(window);