// 执法管理
import React, { Component } from 'react'
import './enforceTheLaw.css'
import KeyMonitoring from './KeyMonitoring'
import Blacklist from './Blacklist'
import RegionalPort from './RegionalPort'
import Delinquency from './Delinquency'

import {Menu,Icon} from 'antd';
const MyIcon=Icon.createFromIconfontCN({
  scriptUrl:'//at.alicdn.com/t/font_1733642_k1h9s3il2ff.js'
})
const maths = {
    'camera' : "" ,
    'gpss' : "" ,
}
Object.keys(maths).forEach(item => maths[item] = require("../../assets/img/" + item+".png"))
const REG_PAGES = {
    "delinquency": { title: '违法记录查询', content: <Delinquency/>, key: 'delinquency',icon:'icon-5' },
    "keyMonitoring": { title: '重点监控管理', content: <KeyMonitoring/>, key: 'keyMonitoring',icon:'icon-shexiangtou' },
    "blacklist": { title: '黑名单管理', content: <Blacklist />, key: 'blacklist',icon:'icon-heimingdan', },
    "regionalPort": { title: '区域停靠点管理', content: <RegionalPort />, key: 'regionalPort',icon:'icon-xiangqing-' },
  };
export default class EnforceTheLaw extends Component {
    constructor(props){
        super(props)
        this.state={
            defaultKey:"delinquency",
        }
    }

    changepage=(item,key)=>{
        this.setState({
          defaultKey:item.key
        })
      } 
    render() {
        let {defaultKey}=this.state;
        return (
            <div className="enforceTheLaw">
                <div className = 'menu' style= {{ width : 256}}>
                    <Menu
                    defaultSelectedKeys={[defaultKey]}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={this.state.collapsed}
                    onClick={this.changepage}
                    >
                       {
                            
                            Object.keys(REG_PAGES).map(item => (
                                <Menu.Item key={REG_PAGES[item].key}>
                                    <MyIcon type={REG_PAGES[item].icon}/>
                                    <span>{REG_PAGES[item].title}</span>
                                </Menu.Item>
                              ))
                        }
                    </Menu>  
                </div>
                 <div  id='content' >
                    {
                      [REG_PAGES[defaultKey]].map((item,index)=>(
                        <div key={item.key+index}>
                          {item.content}
                        </div>
                      ))
                    }
                  </div>
            </div>
        )
    }
}
