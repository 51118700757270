// 区域停靠点管理
import React, { Component } from 'react'
import { Table, Icon, Input, Button, Select, DatePicker, Radio, Modal, message, Tooltip } from 'antd'
import {
    getAreaInfoObscure,
    insertPortAreaInfo,
    deleteArea,
    updateArea,
} from '../../data/dataStore'
import Form from '../../componts/form/Form'
import rectangle from '../../assets/img/rectangle.png'
import ellipse from '../../assets/img/ellipse.png'
import polygon from '../../assets/img/polygon.png'
import MyComponentFactory from './Toolkit/MyComponentFactoryPackage/MyComponentFactory';
import ComponentWorkspace from './Toolkit/MyComponentFactoryPackage/ComponentWorkspace';
const { Search } = Input;
const { RangePicker } = DatePicker;
export default class RegionalPort extends ComponentWorkspace {
    constructor(props) {
        super(props)
        this.state = {
            visible: true,
            addShow: false,
            areaShow: false,
            pointShow: false,
            portShow: true,
            deleteShow: false,
            pageIndex: 0,
            pageSize: 2,
            areaName: '',
            dataSource: [],
            pageTotal: 0,
            loading: false,
            labelSize: 1,
            areaName: '',
            areaTypeArr: [],
            data_selectArreaType: 300001,
            title_actionType: '',
        }
        this.getAreaInfoObscure = this.getAreaInfoObscure.bind(this)
    }
    componentDidMount() {
        this.getAreaInfoObscure()
        let dictData = JSON.parse(sessionStorage.getItem('dictData'))
        let areaTypeArr = []
        dictData.forEach((item) => {
            if (item.category === "area_type") {
                areaTypeArr.push({
                    text: item.typeName,
                    value: item.typeCode
                })
            }
        })
        console.log(areaTypeArr, 'areaTypeArr')
        this.setState({
            areaTypeArr
        })
    }
      getColumnSearchProps = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 200 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.areaNames.map((item) => (
                <Option
                  onClick={this.clickSearchData.bind(this, item)}
                  value={item} >{item}</ Option>
              ))
            }
          </Select>,
        </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData = (value) => {
    this.setState({
      areaNamesvalue: value,
    }, () => {
      this.selectVoyageStatistics()
    });
  }
    //table数据
    async getAreaInfoObscure(value) {
        this.setState({ loading: true })
        let { areaName } = this.state;
        let params = {
            areaName,
            pageIndex: 1,
            pageSize: 99999,
        }
        let data = await getAreaInfoObscure(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                dataSource: data.data.items,
                pageTotal: data.data.pageTotal,
                loading: false
            })
        } else if (data.code == 500) {
            message.error(data.message);
        }
    }

    //删除区域
    async deleteArea(values) {
        let params = {};
        if (values) {
            params = values;
        }
        let data = await deleteArea(params)
            .then(res => { return res.json() }).catch(ex => { });
        if (data.code === 200) {
            console.log(data.data, '-----------deleteArea---------');
            this.getAreaInfoObscure();
            this.setState({
                deleteShow: false,
            });
        }
        else if (data.code == 500) {
            message.error(data.message);
        }
        else if (data.code == 505) {
            this.context.router.push('/login');
        }
    }

    //新增区域停靠点管理
    async insertPortAreaInfo(values) {
        let params = {};
        if (values) {
            params = values;
        }
        let data = await insertPortAreaInfo(params)
            .then(res => { return res.json() }).catch(ex => { });
        if (data.code === 200) {
            console.log(data.data, '-----------insertPortAreaInfo---------');
            this.getAreaInfoObscure();
            this.setState({});
        }
        else if (data.code == 500) {
            message.error(data.message);
        }
        else if (data.code == 505) {
            this.context.router.push('/login');
        }
    }
    //修改区域停靠点管理
    async updateArea(values) {
        let params = {};
        if (values) {
            params = values;
        }
        let data = await updateArea(params)
            .then(res => { return res.json() }).catch(ex => { });
        if (data.code === 200) {
            console.log(data.data, '-----------updateArea---------');
            this.getAreaInfoObscure();
            this.setState({
                data_modify_areaInfo: undefined,
            });
        }
        else if (data.code == 500) {
            message.error(data.message);
        }
        else if (data.code == 505) {
            this.context.router.push('/login');
        }
    }

    showDrawer = () => {
        this.setState({
            visible: !this.state.visible
        })
    }
    addbtnClick = () => {
        this.setState({
            areaShow: false,
            pointShow: false,
            portShow: true,
            data_selectArreaType: 300001,
            data_modify_areaInfo: {},
            addShow: true,
            title_actionType: '新增'
        }, () => {
            this.state.componentRepository.map.clearMap();
        })
    }
    changeAddModule = (value) => {
        switch (value) {
            case 300002:
                this.setState({
                    areaShow: true,
                    pointShow: false,
                    portShow: false,
                })
                    ; break;
            case 300003:
                this.setState({
                    areaShow: false,
                    pointShow: true,
                    portShow: false,
                })
                    ; break;
            case 300001:
                this.setState({
                    areaShow: false,
                    pointShow: false,
                    portShow: true,
                })
                    ; break;
        }
        console.log(value, 'changeAddModule')
        this.setState({
            data_selectArreaType: value,
        })

    }
    getPointValue = (value) => {
        console.log(value, 'valuevalue')
    }
    deleteClickShow = (data) => {
        console.log(data)
        this.setState({
            deleteShow: true,
            deleteData: data
        }, () => {

        })
    }
    onOk_Modal_deleteArea = (e) => {
        console.log(e, '------data------------onOk_Modal_deleteArea------------------');
        if (this.state.deleteData && this.state.deleteData.id) {
            this.deleteArea({ 'id': this.state.deleteData.id })
        }
    }
    closeModal = () => {
        this.setState({
            deleteShow: false
        })
    }

    onClick_Table_row_portManageTable = (e, record) => {
        console.log(record, '--------record----------onClick_Table_row_portManageTable--------');
        let polygonsDatas = [JSON.parse(record.geom)];
        let params = {
            target: 'target_mapLayer_PortArea',
            zIndex: 11,
            color: 'rgba(255,127,0,0.5)',
            isChangeZoomAndCenter: true,
            name: record.areaName,
        }
        this.state.componentRepository.map.showMap_DBData(polygonsDatas, params);
    }

    getPortValue = (values, callBackParams) => {
        console.log(values, callBackParams.onSuccess, '-------------------getPortValue-------------');
        let data_selectArreaType = this.state.data_selectArreaType;
        if (typeof (data_selectArreaType) == 'undefined' && this.state.areaTypeArr) {
            data_selectArreaType = this.state.areaTypeArr[0].value;
        }
        if (this.state.searchAreaPointData) {
            values = {
                ...values,
                ...this.state.searchAreaPointData,
            }
        }
        values.areaType = data_selectArreaType;
        if (this.state.data_modify_areaInfo && Object.keys(this.state.data_modify_areaInfo).length > 0) {
            values.id = this.state.data_modify_areaInfo.id;
            this.updateArea(values);
        } else {
            this.insertPortAreaInfo(values);
        }
        this.state.componentRepository.map.clear_draw_getAreaPointData();
        callBackParams.onSuccess();
    }

    searchByPolygonAndCircle = (params) => {
        console.log(params, '--------params----------------------searchByPolygonAndCircle---------');
        this.setState({
            searchAreaPointData: params,
        })
    }

    onChange_areaList = () => {
        this.state.componentRepository.map.draw_getAreaPointData('Polygon');
    }

    onClick_modify_areaInfo = (record) => {
        console.log(record, '-------record-------------------onClick_modify_areaInfo-------');
        let positionList = [];
        let geomList = JSON.parse(record.geom)[0];
        geomList.map((item, index) => {
            positionList = [
                ...positionList,
                {
                    'lon': item[0],
                    'lat': item[1],
                }
            ]
        })
        this.setState({
            addShow: true,
            data_modify_areaInfo: record,
            searchAreaPointData: { 'positionList': positionList },
            title_actionType: '修改',
        }, () => {
            this.changeAddModule(record.areaType);
            this.onClick_Table_row_portManageTable(undefined, record);
        })
    }
    render() {
        let { visible, addShow, areaShow, portShow, pointShow, dataSource, pageTotal, areaTypeArr, deleteData } = this.state;
        let default_areaType = undefined;
        areaTypeArr.map((item, index) => {
            if (this.state.data_modify_areaInfo && item.value == this.state.data_modify_areaInfo.areaType) {
                default_areaType = item.text;
            }
        })
        const columns = [
            {
                title: '区域名称',
                key: 'areaName',
                dataIndex: 'areaName',
                // align: 'center',
                ellipsis: true,
                render: (text, record) => {
                    return (
                        <Tooltip title={text}>
                            <span className="ellipsis" style={{ cursor: 'pointer' }} onClick={(e) => this.onClick_Table_row_portManageTable(e, record)}>{text}</span>
                        </Tooltip>
                    )
                }
            },
            {
                title: '属地',
                key: 'area',
                dataIndex: 'area',
                // align: 'center',
                ellipsis: true,
            },
            {
                title: '区域类型',
                key: 'areaType',
                dataIndex: 'areaType',
                // align: 'center',
                ellipsis: true,
                filters: areaTypeArr,
                onFilter: (value, record) => record.areaType ? record.areaType === value : null,
                render: (text) => {
                    let newtext = "";
                    areaTypeArr.forEach((item) => {
                        if (text == item.value) {
                            newtext = item.text
                        }
                    })
                    return newtext
                }
            },
            {
                title: '操作',
                key: 'tool',
                // align: 'center',
                render: (text, record) => {
                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.onClick_modify_areaInfo(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 6px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>
                }
            },
        ]
        //停靠点管理
        const portList = [
            {
                label: '区域名称', //表单label
                id: 'areaName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.areaName : '',
                }
            },
            {
                label: '激活状态',
                id: 'activeFlag',
                component: <Radio.Group defaultValue={this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.activeFlag : 1}>
                    <Radio value={1}>激活</Radio>
                    <Radio value={0}>
                        未激活
                </Radio>
                </Radio.Group>,
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.activeFlag : '',
                }
            },
            // {
            //     label: '属地', //表单label
            //     id: 'areaType', //表单Item的Key值
            //     component: <Select>
            //         {areaTypeArr.map((item) => (
            //             <Select.Option value={item.value} key={item.value}>{item.text}</Select.Option>
            //         ))}
            //     </Select>, //表单受控组件
            // },
            {
                label: '选择范围',
                id: 'area',
                component: <Radio.Group className="radioBox" onChange={this.onChange_areaList}>
                    {/*<Radio value={6}><img src={ellipse} />圆形框选</Radio>*/}
                    <Radio value={7}><img src={polygon} />多边形框选</Radio>
                </Radio.Group>,
            },
        ]
        const formItemList = [
            {
                label: '区域名称', //表单label
                id: 'areaName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.areaName : '',
                }
            },
            // {
            //     label: '选择日期',
            //     id: 'date',
            //     component: <Radio.Group>
            //         <Radio value={1}>永久保存</Radio>
            //         <Radio value={2}>
            //             <RangePicker
            //                 placeholder={['开始日期', '结束日期']}
            //                 size="small"
            //             />
            //         </Radio>
            //     </Radio.Group>,
            // },
            {
                label: '激活状态',
                id: 'activeFlag',
                component: <Radio.Group defaultValue={this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.activeFlag : 1}>
                    <Radio value={1}>激活</Radio>
                    <Radio value={0}>
                        未激活
                </Radio>
                </Radio.Group>,
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.activeFlag : '',
                }
            },
            {
                label: '选择范围',
                id: 'area',
                component: <Radio.Group className="radioBox" onChange={this.onChange_areaList}>
                    {/*<Radio value={6}><img src={ellipse} />圆形框选</Radio>*/}
                    <Radio value={7}><img src={polygon} />多边形框选</Radio>
                </Radio.Group>,
            },
        ]
        const areaList = [
            {
                label: '区域名称', //表单label
                id: 'areaName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.areaName : '',
                }
            },
            {
                label: '报警类型',
                id: 'areaOperationType',
                component: <Select placeholder="请选择" defaultValue={this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.areaOperationType : ''}>
                    <Select.Option value={302260}>违规驶离</Select.Option>
                    <Select.Option value={302261}>违规驶入</Select.Option>
                </Select>,
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.areaOperationType : '',
                }
            },
            // {
            //     label: '选择日期',
            //     id: 'date',
            //     component: <Radio.Group>
            //         <Radio value={1}>永久保存</Radio>
            //         <Radio value={2}>
            //             <RangePicker
            //                 placeholder={['开始日期', '结束日期']}
            //                 size="small"
            //             />
            //         </Radio>
            //     </Radio.Group>,
            // },
            {
                label: '激活状态',
                id: 'activeFlag',
                component: <Radio.Group defaultValue={this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.activeFlag : 1}>
                    <Radio value={1}>激活</Radio>
                    <Radio value={0}>
                        未激活
                </Radio>
                </Radio.Group>,
                options: {
                    initialValue: this.state.data_modify_areaInfo ? this.state.data_modify_areaInfo.activeFlag : '',
                }
            },
            {
                label: '选择范围',
                id: 'area',
                component: <Radio.Group className="radioBox" onChange={this.onChange_areaList}>
                    {/*<Radio value={6}><img src={ellipse} />圆形框选</Radio>*/}
                    <Radio value={7}><img src={polygon} />多边形框选</Radio>
                </Radio.Group>,
            },
        ]
        return (
            <div className="regionalPort">
                <MyComponentFactory
                    type={'portMap'}
                    componentLoad={this.componentLoad}
                    // playerMapPointCarera={this.playerMapPointCarera}
                    parentObj={this}
                ></MyComponentFactory>
                <div>
                    <div className="clickBtn" onClick={this.showDrawer}>
                        {visible ? <Icon type="double-left" /> : <Icon type="double-right" />}
                    </div>
                    {
                        visible && <div className="portManage">
                            {!addShow && <div>
                                <div className="portManage_search top">
                                    <Search
                                        placeholder="区域名称"
                                        onSearch={value => this.setState({ areaName: value }, () => { this.getAreaInfoObscure() })}
                                        onChange={
                                            value =>
                                                this.setState({
                                                    areaName: value.target.value
                                                }, () => { this.getAreaInfoObscure() })}
                                        style={{ width: 200 }}
                                    />
                                    <Button onClick={() => this.addbtnClick()}>新增</Button>
                                </div>
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    // size="small"
                                    loading={this.state.loading}
                                    style={{ height: '80%' }}
                                    // rowSelection={rowSelection}
                                    size="middle"
                                    pagination={false}
                                    locale={{
                                        filterConfirm: '确定',
                                        filterReset: '重置',
                                        emptyText: '暂无数据',
                                    }}
                                    // showHeader={ false }
                                    scroll={{
                                        y: '500px',
                                        // x: '100%',
                                        scrollToFirstRowOnChange: true,
                                    }}
                                // onRow={record => {
                                //     return {
                                //         onClick: (e) => this.onClick_Table_row_portManageTable(e, record), // 点击行
                                //         // onDoubleClick: event => { },
                                //         // onContextMenu: event => { },
                                //         // onMouseEnter: (e) => {
                                //         //     this.setState({
                                //         //         enterRowIndex: (record.key - 1),//获取点击行的索引
                                //         //         leaveRowIndex: -1,
                                //         //     })
                                //         // }, // 鼠标移入行
                                //         // onMouseLeave: (e) => {
                                //         //     this.setState({
                                //         //         enterRowIndex: -1,
                                //         //         leaveRowIndex: (record.key - 1)//获取点击行的索引
                                //         //     })
                                //         // },
                                //     };
                                // }}
                                />
                                <p style={{ margin: 10, color: '#999', fontSize: 14 }}>显示{pageTotal}项结果，共{pageTotal}项</p>
                            </div>}

                            {
                                addShow &&
                                <div className="addportBox">
                                    <div className="top addportBox_title">
                                        <span>{this.state.title_actionType}</span>
                                        <Select defaultValue={default_areaType ? default_areaType : areaTypeArr[0].text} onSelect={this.changeAddModule}>
                                            {areaTypeArr.map((item) => (
                                                <Select.Option value={item.value} key={item.value}>{item.text}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div>
                                        {
                                            areaShow && <div className="areaList mySelect">
                                                <Form
                                                    formItemList={areaList}
                                                    submit={this.getPortValue}
                                                    cancel={true}
                                                    footCls="footerBox"
                                                    closeModal={() => {
                                                        this.setState({
                                                            addShow: false
                                                        }, () => {
                                                            this.state.componentRepository.map.clearMap();
                                                        })
                                                    }}
                                                    data_modify_areaInfo={this.state.data_modify_areaInfo}
                                                />
                                            </div>
                                        }
                                        {
                                            portShow && <div className="mySelect">
                                                <Form
                                                    formItemList={portList}
                                                    submit={this.getPortValue}
                                                    cancel={true}
                                                    footCls="footerBox"
                                                    closeModal={() => {
                                                        this.setState({
                                                            addShow: false
                                                        }, () => {
                                                            this.state.componentRepository.map.clearMap();
                                                        })
                                                    }}
                                                    data_modify_areaInfo={this.state.data_modify_areaInfo}
                                                />
                                            </div>
                                        }
                                        {
                                            pointShow && <div>
                                                <Form
                                                    formItemList={formItemList}
                                                    submit={this.getPortValue}
                                                    cancel={true}
                                                    footCls="footerBox"
                                                    closeModal={() => {
                                                        this.setState({
                                                            addShow: false
                                                        }, () => {
                                                            this.state.componentRepository.map.clearMap();
                                                        })
                                                    }}
                                                    data_modify_areaInfo={this.state.data_modify_areaInfo}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div>
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    onOk={this.onOk_Modal_deleteArea}
                    title="删除提醒"

                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData && this.state.deleteData.areaName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
            </div>
        )
    }
}
