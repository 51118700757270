import React from 'react';
import { Tree, Icon } from 'antd';
import { remove } from 'ol/proj/transforms';
import chuanRed from '../../assets/img/chuanRed.png'
import chuan from '../../assets/img/chuan.png'
let { TreeNode } = Tree
class PsTree extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }
    haveMoreTree = (dataArr) => {
        if (!Array.isArray(node)) return;
        let treeNode = [];

    }
    establishTree = (data) => {
        if (!Array.isArray(data)) return;
        var allTree = []
        for (let i in data) {
            let imgUrl;

            switch (data[i].objectType) {
                case 100501: imgUrl = chuan; break;
                // case 3:imgUrl=blueCamera;break;
                case 4: imgUrl = data[i].networkState == 1 ? chuan : chuanRed; break;
                case 5: imgUrl = videotape; break;
            }
            let treeNode = <TreeNode icon={ <img src={ imgUrl } /> } title={ data[i].objectType ? data[i].objectName : `${data[i].name}(${data[i].nodeSize})`} key={ data[i].id } entiy={ data[i] } disabled={false}>
            </TreeNode>
            if (typeof (data[i]) === "object") {
                let keyArr = Object.keys(data[i])
                for (let j in keyArr) {
                    
                    if (Array.isArray(data[i][keyArr[j]]) && data[i][keyArr[j]].length > 0&&keyArr[j]!='deviceVOList') {
                        var nodes = this.establishTree(data[i][keyArr[j]])
                        treeNode = <TreeNode icon={data[i].objectType ? <img src={ imgUrl } />:'' } title={ data[i].objectType ? data[i].objectName : `${data[i].name}(${data[i].nodeSize})` } key={ data[i].id } entiy={ data[i] } disabled={false}>{ nodes }</TreeNode>
                    }
                }
            }
            allTree.push(treeNode)
        }
        return allTree
    }
    render() {
        let { dataSource } = this.props;
        return (
            <Tree
                { ...this.props }
                defaultExpandedKeys={ [null] }
                showIcon
            >
                {
                    this.establishTree(dataSource)
                }
            </Tree>

        );
    }
}
export default PsTree;