// 北斗卡II管理
import React, { Component } from 'react'
import './css/system.css'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload,Spin} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
import $ from 'jquery'
import {
    selectIICardDetails,
    deleteIICardByIds,
    insertIICard,
    exportIICard,
    exportShipTemplate,
    deleteIICard,
    updateIICardDetail,
    importBd2Cards,
    selectBdCardLogs,
    exportBdCardList,
    PsInterface
} from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class CardIIMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            historyShow: false,
            deleteData: {},
            historyData: {},
            selectedKeys: [],
            cardNumber:'',
            taskNum:'',
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: '',
            userGroupData: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
            flowList: [],
            datas: '',
            //loading标识符
            isLoading: false,

        }
        this.deleteIICard = this.deleteIICard.bind(this)
        this.selectIICardDetails = this.selectIICardDetails.bind(this)
        this.insertIICard = this.insertIICard.bind(this)
        this.selectBdCardLogs = this.selectBdCardLogs.bind(this)
        this.exportBdCardList = this.exportBdCardList.bind(this)
    }
    componentDidMount() {
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    //导出模板接口
    async exportShipTemplate() {
        let params = {

        }
        let data = await exportShipTemplate(params)
            .then(res => {
                if (res.ok) {
                    res.blob().then((blob) => {
                        var a = document.createElement('a');
                        var url = window.URL.createObjectURL(blob);
                        var filename = "北斗卡导入模板.xlsx";
                        a.href = url;
                        a.download = filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                } else {
                    return Promise.reject("导出失败");
                }
            })
            .catch(ex => {
                let exMsg = ex instanceof Object ? ex.toString() : ex;
                message.error(exMsg, 5);
            });

    }

    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectIICardDetails(names)
        this.setState({
            name: names
        })
    }

    // 分页查询
    async selectIICardDetails(value, type, pageSizeApi, coloums,id) {
        // this.setState({ loading: true })
        let { pagination, regionNameValue, cardNumber, taskNum,pageTotal } = this.state;
        let params = {
            id: id ? id : '',
            cardNumber:   this.state.cardNumber,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
            taskNum: this.state.taskNum,
            status: this.state.status? this.state.status:'',
        }
        let data = await selectIICardDetails(params)

            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.rows)
            console.log(selectedKeys,"selectedKeys")
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys
                    }

                )
            } else {
                this.setState({
                        //给dataSource赋值
                        dataSource: data.rows,
                        userGroupData: data.rows,
                        UallArr: data.rows,
                        ulshow: false,
                        pageTotal: data.total,
                        selectedKeys
                    }


                )
                console.log(">>>",selectedKeys)
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        } else {
            message.error(data.message)
        }
    }
    excelPort = () => {
        location.href = "/bd3api/cardBd2/exportBdCardList"
    }
    //导出卡管理所有数据
    request_exportExcelToNative = (data) =>{
        let params = {
            interfaceUrl: '/bd3api/cardBd2/exportBdCardList',
            interfaceType: '', //formData/JSONData or empty
            params: {
                ...data,
                isExport: true,
            },
        }
        PsInterface(params, (data, params_callBack) => {
            message.success("导出到Excel成功",2)

        })
    }
    //导出Excel
    async exportBdCardList(value, type) {
        this.setState({ loading: true })
        let { datas } = this.state;
        let params = {
            cardNumber:   this.state.cardNumber,
            taskNum: this.state.taskNum
        }
        let data = await exportBdCardList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                      datas: data,
                    }
                )
            } else {
                location.href = data.message
                this.setState({
                    datas: data,
                    loading: false
                })

            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        } else {
            message.error(data.message)
        }
    }
    //查询北斗卡历史弹出框
    historyClickShow = (data) => {
        console.log("<<<<465<<<<"+JSON.stringify(data))
        this.setState({
            historyShow: true,
            historyData: data

        })
    }
    //查询北斗卡历史日志
    async selectBdCardLogs(data){
        // console.log("<<<","data"+JSON.stringify(data))
        //根据ID去查
        let params = {
            cardNumber:data.cardNumber,

        }
        console.log(">>>>>>>>>>selectBdCardLogs"+JSON.stringify(params))
        let data1 = await selectBdCardLogs(params)

            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data1 && data1.code == 200) {
            let selectedKeys = []
                this.setState({
                        flowList: data1.rows,
                        // cardNumber:data,
                        selectedKeys,
                        historyShow: true,
                        historyData: data
                    }

                )

        } else if (data1 && data1.code == 505) {
            this.props.history.push('/login')
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }
    //导出
    // exportDefaultExcel = (e, coloums) => {
    //     let dockInfoData = this.state.dataSource;
    //     let headers = [];
    //     coloums.map((item, index) => {
    //         let kv = {
    //             k: item.dataIndex ? item.dataIndex : item.key,
    //             v: item.title
    //         }
    //         headers = headers.concat(kv);
    //     })
    //     if (dockInfoData && dockInfoData.length > 0) {
    //         exportExcel(headers, dockInfoData, '航次统计报表.xlsx');
    //     }
    //     else {
    //         message.error('导出数据失败！！！');
    //     }
    // }
    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectIICardDetails()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 新增II北斗卡
    async insertIICard(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await insertIICard(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.selectIICardDetails()
            message.success("新增成功")
            callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败")
        } else {
            message.error(data.message)
        }
        this.setState({
            addShow: false,
            deleteShow: false,
            // historyShow: false
        })
    }
    //导出默认Excel的api
    exportDefaultExcelApi = (e, coloums) => {
        this.selectIICardDetails(undefined, undefined, 9999999, coloums)
    }
    //导出
    exportDefaultExcel = (e, coloums) => {

        let dockInfoData = this.state.AlldataSource;
        delete coloums[7]
        if (dockInfoData) {
            dockInfoData.map((items) => {
                if (this.state.shipTypeArr) {
                    this.state.shipTypeArr.map((item) => {
                        if (item.value == items.shipType) {
                            items.shipType = item.text
                        }
                    })
                }
            })
        }
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (dockInfoData && dockInfoData.length > 0) {
            exportExcel(headers, dockInfoData, '北斗卡管理.xlsx');
        }
        else {
            message.error('导出数据失败！！！');
        }
    }
    // 修改II北斗详情
    async updateIICardDetail(obj) {
        console.log(obj, 'oooooooooo')
        let data = await updateIICardDetail(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                modify: false,
            })
            this.selectIICardDetails()
            message.success("修改成功")
        } else if (data.code == 500) {
            message.error("修改失败")
        } else {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
            // historyShow: false

        })
    }
    // 导入北斗II卡
    async importBd2Cards(fileData) {
        this.state.loading=true;
        let formData = new FormData()
        formData.append("file", fileData)
        // console.log(params,'params')
        let data = await importBd2Cards(formData)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success("上传成功"+data.message)
            this.selectIICardDetails();
        } else {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
            // historyShow: false,
            loading: false
        })
    }
    modify = (data) => {
        this.setState({
            modify: true,
            modifyData: data,
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }

    // 删除II代卡号
    async deleteIICard() {
        let { deleteData } = this.state;
        console.log("========================d当前删除的 ID是-------------------"+deleteData.id)
        let params = {
            id: deleteData.id,
        }
        let data = await deleteIICard(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("注销成功")
            this.selectIICardDetails()
        } else if (data.code == 500) {
            message.error("注销失败")
        }else  {
            message.error("失败:"+data.message)
        }
        this.setState({
            deleteShow: false,
        });
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            historyShow: false,
            Label: false,
            modify: false,
        })
    }
    exportDefault = () => {
        this.setState({
        }, () => {
            this.exportShipTemplate()
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectIICardDetails()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }
    //北斗卡修改
    getNewIICardData = (value) => {
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.id = modifyData.id
        this.updateIICardDetail(value)
        console.log(value, 'getNewIICardData')
    }
    // 获取文件后缀
    getFileSuffix = name => {
        const index = name.lastIndexOf(".");
        const len = name.length;
        return name.substring(index, len) || "-";
    };

    beforeUpload = file => {
        const suffix = this.getFileSuffix(file.name);
        const isLt2M = file.size / 1024 / 1024 < 2;
        const isValid = suffix === ".xlsx" || suffix === ".xls";
        if (!isValid) {
            message.warn("上传失败，请选择.xlsx或.xls格式");
        }
        //   if (!isLt2M) {
        //     message.warn("上传失败，大小不能超过2M");
        //   }
        return isValid;
    }
    //筛选
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            let { objectNameArr } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 100 }} placeholder={'请选择'}
                            showSearch
                            multiple
                    >
                        {
                            objectNameArr && objectNameArr.regionNames.map((item) => (
                                <Option
                                    onClick={this.clickSearchData1.bind(this, item)}
                                    value={item} >{item}</Option>
                            ))
                        }
                    </Select>,
                </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectIICardDetails()
        });
    }

    //用户分配用户组的选中
    distribute_onChage = (selectedRowKeys) => {
        console.log(">>>","》》》》"+selectedRowKeys)
        let keyArr = this.getSelectKey(this.state.userGroupData, selectedRowKeys)
        console.log("+++++++++++++545++++++++++++"+keyArr);
        this.setState({
            selectedKeys: keyArr
        })
    }

    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }
    // 批量注销北斗卡
    async deleteIICardByIds() {
        let { selectedKeys } = this.state
        if (JSON.stringify(selectedKeys) !== '[]'){
            console.log("___________________"+selectedKeys)
            let params = {
                ids: selectedKeys
            }
            console.log("....646......"+selectedKeys);
            let data = await deleteIICardByIds(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => { });
            if (data && data.code == 200) {
                message.success(data.message)
                this.setState({
                    deleteAll: false,
                })
                this.selectIICardDetails()
            } else {
                message.error(data.message)
            }

        }else {
            message.error("至少选择一条信息...")
        }
    }
    deleteAllShow = (data) => {
        let { selectedRowKeys } = this.state;
        if (selectedRowKeys) {
            this.setState({
                deleteAll: true,
            })

        } else {
            message.warning("请先选择要删除的数据！")
        }
    }

    handleChange = (value) => {
          console.log(`selected ${value}`);
                this.setState({
                    status: value,
                })
        };


    render() {
        let {flowList,cardNumber ,pageIndex, dataSource, modifyData,nowModalData,userGroupData,selectedKeys, ulshow, labelData, shipTypeArr,selectMenuDataObj = {} ,pageTotal} = this.state;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '卡号',
                key: 'cardNumber',
                dataIndex: 'cardNumber',
            },
            {
                title: '通讯比特数',
                key: 'bits',
                dataIndex: 'bits',
                // ...this.getColumnSearchProps('bits'),
            },
            {
                title: '频度',
                key: 'frequency',
                dataIndex: 'frequency',
                // ...this.getColumnSearchProps('frequency'),
            },
            {
                title: '通播号',
                key: 'multicastNumber',
                dataIndex: 'multicastNumber',
            },

            {
                title: '任务单号',
                key: 'taskNum',
                dataIndex: 'taskNum',
            },

            {
                title: '项目名',
                key: 'projectName',
                dataIndex: 'projectName',
            },
            {
                title: '卡状态',
                key: 'status',
                dataIndex: 'status',
                render: (status) => {
                    let newText;
                    if (status === 0) {
                        newText = '新录入'
                    }else if(status === 1){
                        newText = '已发货'
                    }else if (status === 2) {
                        newText = '正常使用';
                    }else if(status === 3){
                        newText = '随设备返修'
                    }else if (status === 4) {
                        newText = '报废待回收';
                    }else if(status === 5){
                        newText = '解绑'
                    }else if (status === 6) {
                        newText = '已回收';
                    }else if(status === 10){
                        newText = '待注销'
                    }else if (status === 11) {
                        newText = '已注销';
                    }else if (status === 12) {
                        newText = '丢失';
                    }else if (status === 96) {
                        newText = '录入待审';
                    }else if (status === 97) {
                        newText = '发货待审';
                    }else if (status === 98) {
                        newText = '安装待审';
                    }else if (status === 99) {
                        newText = '注销待审';
                    } else {
                        newText ='没填值'
                    }
                    return newText;
                },
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },
            {
                title: '操作',
                key: 'detele',
                width: 300,
                render: (text, record) => {
                    return <div>

                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modify(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>注销</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.selectBdCardLogs(record)}>历史</span>

                    </div>
                },
            },
        ]
        //添加对话框
        const formItemList = [
            {
                label: '北斗卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} autocomplete="off"/>, //表单受控组件
                options: { rules: [{ required: true, message: '卡号不能为空!' }] }
            },
            {
                label: '通信比特数', //表单label
                id: 'bits', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '频度', //表单label
                id: 'frequency', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '通播号', //表单label
                id: 'multicastNumber', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '任务单号', //表单label
                id: 'taskNum', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '项目名', //表单label
                id: 'projectName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
 ]
        let type_status = [{"value":0,"text":"新录入"},{"value":1,"text":"已发货"},
            {"value":2,"text":"正常使用"},{"value":3,"text":"随设备返修"},
            {"value":4,"text":"报废待回收"},{"value":5,"text":"解绑"},
            {"value":6,"text":"已回收"},{"value":10,"text":"待注销"},
            {"value":11,"text":"已注销"},{"value":12,"text":"丢失"},
            {"value":96,"text":"录入待审"},{"value":97,"text":"发货待审"},
            {"value":98,"text":"安装待审"},{"value":99,"text":"注销待审"},]
        const modifyList = [
            {
                label: '卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} readOnly/>, //表单受控组件
                options: { initialValue: modifyData && modifyData.cardNumber }
            },
            {
                label: '通信比特数', //表单label
                id: 'bits', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.bits }
            },
            {
                label: '频度', //表单label
                id: 'frequency', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.frequency }
            },
            {
                label: '通播号', //表单label
                id: 'multicastNumber', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.multicastNumber }

            },
            {
                label: '任务单号', //表单label
                id: 'taskNum', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.taskNum }
            },
            {
                label: '项目名', //表单label
                id: 'projectName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.projectName }
            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.remark }
            },
            {
                label: '卡状态', //表单label
                id: 'status', //表单Item的Key值
                component: <Select placeholder={'请选择'}>
                    {
                        type_status.map((item) => (
                            <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { initialValue: modifyData && modifyData.status }
            },
        ]
        const upload = {
            name: 'file',
            action: '/bd3api/importShips',
            headers: {
                authorization: 'authorization-text',
                // token: JSON.parse(sessionStorage.getItem('isLogin')).data.password
            },
            onChange(info) {
                if (info.file.code !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.code === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                } else if (info.file.code === 'error') {
                    message.error(`${info.file.name} 上传失败`);
                }
            },
            beforeUpload: this.beforeUpload
        };

        const props = {
            beforeUpload: file => {
                this.importBd2Cards(file)
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };



        let cardStatus = [{value:"0",text:"新录入"},{value:"1",text:"已发货"},{value:"2",text:"正常使用"},{value:"5",text:"解绑"},{value:"10",text:"待注销"},{value:"11",text:"已注销"}]

        return (
            <div className='shipmonitoring contentBox'>
                <div className="cardManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">北斗卡管理</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="cardManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                北斗卡号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                任务单号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='taskNum' value={this.state.taskNum} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                卡状态：
                              <Select  placeholder={'请选择'} style={{width: '70%'}} name='status'  value={this.state.status} onChange={value => this.handleChange(value)} >
                                {
                                    cardStatus.map((item) => (
                                        <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                                    ))
                                }
                            </Select>,

                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                // style={{
                                //     width: 100,
                                //     backgroundColor: '#81BEF8',
                                //     border: 'unset'
                                // }}
                                onClick={() => this.selectIICardDetails()}
                            >查询</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    // width: 100,
                                    // left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cardNumber: '',
                                            taskNum: '',
                                        }, () => {
                                            //
                                        })
                                    }
                                }

                            >重置</Button>

                        </div>

                    </div>

                    <div className="cardManagement_title_buttons">
                        <div className="cardManagement_title_buttons_button" onClick={() => this.deleteIICardByIds()}>批量注销</div>
                        <div className="cardManagement_title_buttons_button" > <Upload
                            {...props}
                            style={{ paddingTop: 19 }}
                        >
                            导入
                        </Upload></div>
                        <div className="cardManagement_title_buttons_button" onClick={() => {
                            // this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})
                            this.exportBdCardList()
                        }}>导出</div>

                  {/*      <div  className="cardManagement_title_buttons_button" onClick={() => {
                            this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})
                        }} >下载导入模板</div> */}

                        <div className="cardManagement_title_buttons_button"
                             onClick={this.addClickShow} style={{borderRight: '1px solid #8D959C'}}>新增
                        </div>

                    </div>
                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }

                <div className="cardIIManagement">
                    {/*
                    //
                    //
                    //列表显示数据
                    //
                    //
                    //

                    */}
                    <Table
                        columns={columns}
                        rowSelection={distribute_rowSelect}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:515}}
                        pagination={{
                            ...this.state.pagination,
                            // position: ['bottomCenter'],
                            // showQuickJumper: true,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectIICardDetails(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertIICard}
                    title="新增"
                />

                {
                    this.state.modify && <div className="modifyShipData">
                        <h3>修改</h3>
                        <Form formItemList={modifyList} cancel={true} submit={this.getNewIICardData} closeModal={this.closeModal} />
                    </div>
                }
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="注销提醒"
                    onOk={this.deleteIICard}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.cardNumber}"此卡将被注销，确认注销吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.historyShow}
                    onCancel={this.closeModal}
                    onOk={this.selectBdCardLogs}
                    title="北斗卡历史"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={800}
                    hight={600}
                >
                    {/*
                    北斗卡历史
                    */}
                    <div>
                        <div className="new-order-track">
                            <div className="img-content">
                            </div>
                            <div>
                                <span>北斗卡号：{this.state.historyData.cardNumber}</span>
                                {/*<span>北斗卡号：{cardNumber}</span>*/}
                            </div>
                        </div>

                        <div className="flow-list">
                            <div className="new-order-flow new-p-re">
                                <ul className="new-of-storey">
                                    {
                                        flowList.map((item, index) => {
                                            return <li key={index}>
                                                {
                                                    index === 0 ? <span className="top-white"/> : ''
                                                }
                                                {
                                                    index === flowList.length - 1 ?
                                                        <span className="bottom-white"/> : ''
                                                }
                                                <span className={`icon ${index === 0 ? 'on' : ''}`}/>
                                                <span className={index === 0 ? 'first' : ''}>
                                        {item['remark']}
                                    </span>
                                                <span
                                                    className={index === 0 ? 'first' : ''}>
                                        {/*
                                        日期格式化*/
                                        }
                                                    { moment((item['gmtCreate'])).format('YYYY-MM-DD HH:mm:ss')}
                                    </span>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default CardIIMonitoring