// 固件列表
import React, { Component } from 'react'
import SearchTop from '../System/components/seachTop/SeachTop'
import { Table, Input, Switch, Select, Divider, Icon, Modal, message, Button, Tabs, Upload, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ModalForm from '../../componts/ModalForm';
import { updateDeviceParameter, deleteFirmware, queryFirmwareList, queryFileName, UploadFirmware, queryFirmwareByVersion } from '../../data/dataStore'
// import reqwest from 'reqwest';
import './ManagementDevice.css'
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class FirmwareList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            modifyShow: false,
            ulshow: false,
            // MainversionData: 12, 
            // MinorversionData: 12, 
            DescriptionInformationData: 12,
            fileList: [],
            UploadTrue: false,
            queryFileData: true,
            // uploading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            shipName: '',
            showUploadListTrue:true,
            clickNum: 0,
            name: '',
            hidden: true,
            write: true,
            visible: false,
            uploadVisible: false
        }
        this.deleteFirmware = this.deleteFirmware.bind(this)
    }
    componentDidMount() {
        this.queryFirmwareList()
    }

    // 上传固件
    async UploadFirmware() {
        let { uploadName, fileData, versionData, MainversionData, MinorversionData, DescriptionInformationData } = this.state;
        let formData = new FormData()
        formData.append("firmware", fileData)
        formData.append("versionName", versionData)
        formData.append("majorVersion", MainversionData)
        formData.append("minorVersion", MinorversionData)
        formData.append("versionDesc", DescriptionInformationData)
        formData.append("fileType", 1)
        // console.log(params,'params')
        let data = await UploadFirmware(formData)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("上传成功")
            this.queryFirmwareList()
        } else {
            message.error("上传失败")
        }
        this.setState({
            deleteShow: false
        })
    }
    //删除
    async deleteFirmware() {
        let { deleteData } = this.state;
        console.log(deleteData, 'deleteData')
        let params = {
            id: deleteData.id,
        }
        let data = await deleteFirmware(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")

        } else {
            message.error("删除失败")
        }
        this.setState({
            deleteShow: false
        })
    }

    // 查询固件名是否重复
    async queryFileName() {
        let { uploadName } = this.state;
        let params = {
            fileName: uploadName,
        }
        let data = await queryFileName(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                queryFileData: data.data,
                showUploadListTrue:true
            })
            if(data.data == true){
                alert('固件名重复，请修改名称再次上传')
             }else if(data.data == false){
             }
            // alert('固件名已存在，请修改名称再次上传')
            // message.success("固件名不重复")

        } else {
            // message.error("固件名已存在，请修改名称再次上传")
            // alert('固件名已存在，请修改名称再次上传')
        }
    }
    // 查询固件版本是否重复
    async queryFirmwareByVersion() {
        let { uploadName, versionData, MainversionData, MinorversionData } = this.state;
        console.log(versionData,'versionData')
        let params = {
            versionName: versionData,
            majorVersion: MainversionData,
            minorVersion: MinorversionData,
        }
        let data = await queryFirmwareByVersion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                queryFileData: data.data
            })
            // console.log()
             if(data.data == true){
                alert('固件版本已存在，请修改版本名称再次上传')
             }else if(data.data == false){
            this.UploadFirmware()
        }
            // message.success("固件版本不重复")

        } else {
            // message.error("固件版本已存在，请修改名称再次上传")
            // alert('固件版本已存在，请修改名称再次上传')
        }
    }
    //上传参数
    handleUpload = () => {
        const { fileList,queryFileData } = this.state;
        // if(queryFileData && queryFileData==false){
        //     this.setState({
        //         formData: formData,
        //         uploading: false,
        //     }, () => {
        //         this.UploadFirmware()
        //         this.queryFirmwareByVersion()
        //     });
        // }else{
        //     this.setState({
        //         formData: formData,
        //         uploading: false,
        //     }, () => {
        //         // this.UploadFirmware()
        //         this.queryFirmwareByVersion()
        //     });
        // }
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('firmware', file);
        });
        this.setState({
            formData: formData,
            uploading: false,
        }, () => {
            this.queryFirmwareByVersion()
        });
        console.log(fileList, 'fileList')
    };
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.queryFirmwareList()
            })
        } else {
            this.setState({
                name: eve.target.value,
                versionName: name,
            }, () => {
                this.queryFirmwareList(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.queryFirmwareList(data.versionName)
        this.setState({
            name: data.versionName
        })
    }
    // 查询设备参数
    async queryFirmwareList(value, type) {
        let { shipName, pagination, name } = this.state;
        let params = {
            versionName: value ? value : name,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await queryFirmwareList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UrlArr: data.data.items,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                pagination.total = data.data.pageTotal
                this.setState({
                    ulshow: false,
                    dataSource: data.data.items,
                    pagination,
                    loading: false
                })
            }


        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            // this.queryFirmwareList()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    onSelectChange = (selectedRowKeys) => {
        this.setState({
            selectedRowKeys
        })
    }
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }

    closeModal = () => {
        this.setState({
            modifyShow: false,
            deleteShow: false,
            uploadVisible: false,
            clickNum: 0,
            hidden: true,
            defaultFileListArr:[],
            showUploadListTrue:false,
            write: true,
        })
    }
    showUploadModal = () => {
        this.setState({
            uploadVisible: true
        })
    }
    closeUploadModal = () => {
        this.setState({
            uploadVisible: false
        })
    }
    onChangeupload = (info) => {
        if (info.file.status !== 'uploading') {
            this.setState({
                uploadName: (info.file.name).toString()
            }, () => {
                this.queryFileName()
            })
        }
        if (info.file.code === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.code === 'error') {
            message.error(`${info.file.code} file upload failed.`);
        }
    };
    //监听版本名称
    versionName = (eve) => {
        console.log(eve.target.value, 'versionName1')
        this.setState({
            versionData: eve.target.value
        }
            // ,()=>{
            //     this.queryFirmwareByVersion()
            // }
        )
    }
    //监听主版本
    MainversionName = (value) => {
        console.log(value, 'versionName1')
        this.setState({
            MainversionData: value
        })
    }
    //监听次版本
    MinorversionName = (value) => {
        let {MainversionData,versionData,MinorversionData}=this.state
        console.log(value, 'versionName1')
        this.setState({
            MinorversionData: value
        })
        if (versionData != undefined && MainversionData != undefined && MinorversionData != undefined) {
            // this.queryFirmwareByVersion()
    }
    }
    //监听描述信息 
    DescriptionInformation = (eve) => {
        console.log(eve.target.value, 'versionName8')
        this.setState({
            DescriptionInformationData: eve.target.value
        })
       
    }
   
    render() {
        let { ulshow, dataSource, uploading, versionData, MainversionData, MinorversionData, UploadTrue,defaultFileListArr,showUploadListTrue, DescriptionInformationData, uploadName, fileList, queryFileData } = this.state;
        console.log(versionData, MainversionData, MinorversionData, 'versionData')
        console.log(queryFileData && queryFileData, 'queryFileData')
//         if(queryFileData && queryFileData == 'true'){
//   alert('')
//         }

        // if (versionData != undefined && MainversionData != undefined && MinorversionData != undefined) {
        //         this.queryFirmwareByVersion()
        // }
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                console.log(typeof (file), 'file123')
                // this.setState(state => ({
                //     fileList: [...state.fileList, file],
                // }));
                this.setState({
                    fileData: file
                })
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };
        const formItemList = [
            {
                label: '版本名称', //表单label
                id: 'parentId', //表单Item的Key值
                component: <Input onChange={value => this.versionName(value)} placeholder=" 版本名称" style={{ width: '16vh', marginLeft: 20 }} />
                , //表单受控组件
                options: { rules: [{ required: true, message: '版本名称不能为空!' }] }
            },
            {
                label: '主版本',
                id: 'name',
                component: <InputNumber onChange={value => this.MainversionName(value)} placeholder="主版本" style={{ width: '16vh', marginLeft: 20 }} />,
                options: { rules: [{ required: true, message: '主版本不能为空!' }] }
            },
            {
                label: '次版本',
                id: 'lcc',
                component: <InputNumber onChange={value => this.MinorversionName(value)} placeholder="次版本" style={{ width: '16vh', marginLeft: 21 }} />,
                options: { rules: [{ required: true, message: '次版本不能为空!' }] }
            },
            {
                label: '描述信息',
                id: 'miaosj', //表单Item的Key值
                component: <Input.TextArea autoSize={{minRows:3}} onChange={value => this.DescriptionInformation(value)} placeholder=" 描述信息" style={{ width: '16vh', marginLeft: 20 }} />
                , //表单受控组件
                options: { rules: [{ required: true, message: '描述信息不能为空!' }] }
            },
            {
                label: '选择固件',
                id: 'UploadOutlined',
                component: <Upload {...props}
                // defaultFileList={defaultFileListArr}
                // showUploadList={showUploadListTrue}
                >
                    <Button style={{ marginLeft: 21,width: '16vh'}}
                className='UploadOutlinedStyle'
                    >
                        <UploadOutlined /> 上传文件
                    </Button>
                </Upload>,
                options: {
                    rules: [{
                        required: true,
                        message: '固件不能为空'
                    }]
                }
            },
        ]
        const columns = [
            {
                title: '固件名称',
                key: 'versionName',
                dataIndex: 'versionName',
                width: '10%',
            },
            {
                title: '固件版本',
                key: 'deviceId',
                dataIndex: 'deviceId',
                width: '15%',
                render: (text, row, index) => {
                    return (<span>终端{row.majorVersion}.{row.minorVersion}</span>)
                },
            },
            {
                title: '文件名称',
                key: 'fileName',
                dataIndex: 'fileName',
                width: '10%',
            },
            {
                title: '文件地址',
                key: 'filePath',
                dataIndex: 'filePath',
                width: '10%',
            },
            {
                title: '上传时间',
                key: 'gmtModified',
                dataIndex: 'gmtModified',
                width: '15%',
            },
            {
                title: '操作',
                key: 'detele',
                width: '10%',
                render: (text, record) => {

                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>

                },
            },
        ]
        return (
            <div className='deviceManagement contentBox'>
                <div>
                    <label>设备查询: </label>
                    <Input
                        value={this.state.name}
                        // className='Searchvalue'
                        style={{width:'20vh'}}
                        onSearch={value => this.queryFirmwareList(value, 'select')}
                        onChange={value => this.controlList(value)}
                        placeholder="固件名称" />
                         <Button style={{ float:'right' }} onClick={this.showUploadModal}>上传</Button>
                    <Button style={{ float:'right' }} type='primary' onClick={() => this.queryFirmwareList()}>查询</Button>
                   
                </div>
                {
                    ulshow && <ul
                        className="searchList"
                        style={{
                            position: 'absolute',
                            left: 83,
                            width: 200
                        }}
                    >
                        {
                            this.state.UrlArr && this.state.UrlArr.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.versionName}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div style={{ width: '100%' }}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        // scroll={{ x: 2000 }}
                        pagination={{ ...this.state.pagination, showQuickJumper: true }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.deleteFirmware}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData && this.state.deleteData.versionName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
                <ModalForm
                    visible={this.state.uploadVisible}
                    title='固件上传'
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    width="20%"
                    submit={this.handleUpload}
                >
                </ModalForm>
            </div>
        )
    }
}
export default FirmwareList