//航次统计报表
import React, { Component } from 'react'
import './statistics.css'
import { Table, Radio, Divider, Input, Menu, Icon, Button, Select, Space, DatePicker, Modal, Pagination, message } from "antd";
import { selectVoyageStatistics, selectShip, selectShipPage, Form, getDict, getArgs } from '../../data/dataStore'
import FuzzySearch from '../../componts/FuzzySearch/FuzzySearch'
import PortSelect from '../../componts/portSelect'
import moment from 'moment';
import { exportExcel } from 'xlsx-oc';
import { getBottomLeft } from 'ol/extent';
const { SubMenu } = Menu;

const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
const { RangePicker, MonthPicker } = DatePicker;
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
const dayList1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
class VoyageNum extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // visible: false,
      startTime: dayList1,//默认开始时间是金泰你零点，
      endTime: dayList,//默认开始时间是今天23点
      pageIndex: 0,
      ulshow: false,//控制搜索到的显示和隐藏
      pageSize: 100,
      dataSource: [],//初始化
      ulArr: [],
      name: '',
      pageTotal: null,
      searchText: '',
      searchedColumn: '',
      pagination11: {
        total: 0,
        current: 1,
        pageSize: 9999,
        showQuickJumper: true,
      },
      pagination: {
        defaultPageSize: 10,
        defaultCurrent: 1,
        current: 1,
        showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
        // showSizeChanger: true,
        showQuickJumper: true,
      },
      loading: false,
      searchValue: ''
    }
    this.selectVoyageStatistics = this.selectVoyageStatistics.bind(this);
  }
  haveTime = (e, value) => {
    let startTime = value[0] + " 00:00:00";
    let endTime = value[1];
    endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
    this.setState({
      startTime,
      endTime,
    })
  }
  componentDidMount() {
    this.selectVoyageStatistics();
    this.getArgs()
    let dictData = JSON.parse(sessionStorage.getItem("dictData"))
    let shipTypeArr = []
    dictData.forEach((item) => {
      if (item.category === "ship_type") {
        shipTypeArr.push({
          text: item.typeName,
          value: item.typeCode

        })
      }
    })

    this.setState({
      shipTypeArr,
    })
  }
  changeSeach = (eve) => {
    this.props.setData && this.props.setData(eve)
    if (!eve) {
      this.setState({
        show: false,
        factor: eve,
      }, () => {
        console.log(this.state.factor, this.state.show, 'state')
        // if(!this.state.factor){
        //     this.props.seachChangeList()
        // }
      })
      this.props.search()
    } else {
      this.setState({
        factor: eve,
        show: true
      })
      this.seachCondition(eve)
    }
  }
  seachCondition = (eve) => {
    console.log(eve)
    if (this.props.other) {
      this.setState({
        show: false
      })
      this.props.search(eve)
    } else {
      this.selectVoyageStatistics(eve)
    }
  }
  //分页筛选
  async getArgs(value) {
    let params = {

    }
    let data = await getArgs(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data && data.code == 200) {
      this.setState({
        objectNameArr: data.data,
      })


    } else {
    }
  }


  getColumnSearchProps = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 200 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.areaNames.map((item) => (
                <Option
                  onClick={this.clickSearchData.bind(this, item)}
                  value={item} >{item}</ Option>
              ))
            }
          </Select>,
        </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData = (value) => {
    this.setState({
      areaNamesvalue: value,
    }, () => {
      this.selectVoyageStatistics()
    });
  }
  getColumnSearchPropss = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      console.log(objectNameArr, 'objectNameArr')
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 200 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.areaNames.map((item) => (
                <Option
                  onClick={this.clickSearchData.bind(this, item)}
                  value={item} >{item}</ Option>
              ))
            }
          </Select>,
        </div>
        <div>
        </div>
      </div>
    }
  });
  clickSearchData = (value) => {
    this.setState({
      leaveAreaNamevalue: value,
    }, () => {
      this.selectVoyageStatistics()
    });
  }
  //输入框为空时搜索列表不显示
  controlList = (eve) => {
    console.log(eve, 'vvvvvv')
    if (!eve.target.value) {
      this.setState({
        ulshow: false,
        name: ''
      }, () => {
        this.selectVoyageStatistics()
      })
    } else {
      this.setState({
        name: eve.target.value,
        shipName: name,
      }, () => {
        this.selectVoyageStatistics(this.state.name, 'select')
      }
      )
    }
  }
  //点击搜索列表的事件
  clickSearchDatavalue = (names) => {
    this.selectVoyageStatistics(names)
    this.setState({
      name: names
    })

    console.log(names, '666')
  }
  // 航次统计报表
  async selectVoyageStatistics(value, type) {
    this.setState({ loading: true })
    let { startTime, endTime, searchValue, areaNamesvalue, leaveAreaNamevalue, pagination11,pagination, strs, name, names } = this.state;
    let params = {
      arriveAreaName: areaNamesvalue,
      leaveAreaName: leaveAreaNamevalue,
      shipName: value ? value : name,
      pageIndex: pagination11.current,
      pageSize: pagination11.pageSize,
      pageTotal: this.state.total1,
      startTime,
      endTime,
    }

    let data = await selectVoyageStatistics(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data.code == 200) {
      if (type === 'select') {
        this.setState({
          ullAll: data.data.items,
          ulshow: true,
          
        }
        )
      } else {
        pagination.current=1
        // pagination.total = data.data.pageTotal
        this.setState({
          dataSource: data.data.items,
          pageTotal: data.pageTotal,
          pagination:pagination,
          ulshow: false,
          loading: false 
        })
      }
    } else {
    }
  }

  //导出
  exportDefaultExcel = (e, coloums) => {
    let dockInfoData = this.state.dataSource;
    if(dockInfoData){
      dockInfoData.map((items)=>{
        if (this.state.shipTypeArr) {
          this.state.shipTypeArr.map((item) => {
            if (item.value == items.shipType) {
              items.shipType = item.text
            }
          })
        }
      })
    }
    
    let headers = [];
    coloums.map((item, index) => {
      let kv = {
        k: item.dataIndex ? item.dataIndex : item.key,
        v: item.title
      }
      headers = headers.concat(kv);
    })
    if (dockInfoData && dockInfoData.length > 0) {
      exportExcel(headers, dockInfoData, '航次统计报表.xlsx');
    }
    else {
      message.error('导出数据失败！！！');
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
   
    this.setState({
      // pagination: { ...pagination }
      pagination:pagination
    }, () => {
      // this.selectVoyageStatistics()
    })
    console.log(pagination, filters, sorter, 'sorter')
  }
  render() {

    let { dictData, ulshow, startTime, ullAll, ulArr, endTime, dataSource, shipTypeArr, arriveAreaNameArrList, objectNameArr, list,pagination } = this.state;
    let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.shipName))
    let ullAllss = [...new Set(ullAlls)];
    console.log(ullAllss, 'ullAlls')
    console.log(pagination, 'pagination')
    const columns = [
      {
        title: '船名',
        dataIndex: 'shipName',
        alian: 'center',
      },
      {
        title: '船舶类型',
        dataIndex: 'shipType',
        alian: 'center',
        render: (text) => {
          let newText;
          if (shipTypeArr) {
            shipTypeArr.forEach((item) => {
              if (item.value == text) {
                newText = item.text
              }
            })
          }
          return newText
        },

      },
      {
        title: '离港名称',
        dataIndex: 'leaveAreaName',
        // filters: portList,
        // onFilter: (value, record) => record.leaveAreaName.indexOf(value) === 0,
        ...this.getColumnSearchPropss('leaveAreaName'),
      },
      {
        title: '离港时间',
        dataIndex: 'leaveAreaTime',
        key: 'leaveAreaTime',
        sorter: (a, b) => {
          if (a.leaveAreaTime > b.leaveAreaTime) {
            return 1;
          } else if (a.leaveAreaTime < b.leaveAreaTime) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: '到港名称',
        dataIndex: 'arriveAreaName',
        // filters: arriveAreaNameArrList,
        // onFilter: (value, record) => record.arriveAreaName.indexOf(value) === 0,
        ...this.getColumnSearchProps('arriveAreaName'),
      },
      {
        title: '到港时间',
        dataIndex: 'arriveAreaTime',
        key: 'arriveAreaTime',
        sorter: (a, b) => {
          if (a.arriveAreaTime > b.arriveAreaTime) {
            return 1;
          } else if (a.arriveAreaTime < b.arriveAreaTime) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: '行驶里程(公里)',
        dataIndex: 'totalMileage',
        alian: 'center',
        render: (totalMileage) => {
          return (
            totalMileage = (totalMileage / 1000).toFixed(1)
          )
        }
      },
      {
        title: '行驶平均速度',
        dataIndex: 'averageSpeed',

      },
      {
        title: '记录时间',
        dataIndex: 'gmtCreate',
        key: 'arriveAregmtCreateaTime',
        sorter: (a, b) => {
          if (a.gmtCreate > b.gmtCreate) {
            return 1;
          } else if (a.gmtCreate < b.gmtCreate) {
            return -1;
          } else {
            return 0;
          }
        },

      }
    ];
    return (
      <div className='voyagenum_mune'>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: 75, marginTop: 5, textAlign: 'center' }} >船名：</span>
              <Input
                value={this.state.name}
                onSearch={value => this.selectVoyageStatistics(value, 'select')}
                onChange={value => this.controlList(value)}
                onPressEnter={() => this.selectVoyageStatistics()}
                placeholder="请输入船名" />
            </div>
            {
              ulshow && <ul className="searchList">
                {
                  ullAllss && ullAllss.map((item, index) => (
                    <li
                      key={item.index}
                      onClick={this.clickSearchDatavalue.bind(this, item)}
                      style={{
                        cursor: 'pointer',
                        fontSize: 16,
                        display: 'flex',
                        alignItems: "center",
                      }}
                    ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item
                    }</span>
                    </li>
                  ))
                }
              </ul>
            }
          </div>
          <text style={{ marginTop: 5, marginLeft: 30, marginRight: 15 }} >选择日期:</text>
          <RangePicker
            defaultValue={[moment(startTime), moment(endTime)]}
            ranges={{
              今日: [moment(), moment()],
              '本周': [moment().startOf('week'), moment().endOf('week')],
              '本月': [moment().startOf('month'), moment().endOf('month')],
              '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
              '今年': [moment().startOf('year'), moment().endOf('year')],
            }}
            allowClear={false}
            value={[moment(startTime), moment(endTime)]}
            onChange={this.haveTime}
          />
          <Button style={{ marginLeft: 40 }} type="primary" onClick={() => this.selectVoyageStatistics()} >查询</Button>
          <Button style={{ marginLeft: 20 }} onClick={() => {
            this.setState({
              name: '',
              searchValue: '',
              startTime: dayList1,
              endTime: dayList,
              areaNamesvalue: '',
              leaveAreaNamevalue: '',
            }
              , () => {
                this.selectVoyageStatistics()
              })
          }}
          > 重置</Button>
          <Button style={{ marginLeft: 650 }} onClick={(e) => this.exportDefaultExcel(e, columns)}>导出</Button>
        </div>
        <Table
          className='VoyageNum_icon'
          style={{ marginTop: 25 }}
          columns={columns}
          loading={this.state.loading}
          dataSource={dataSource}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>

    )
  }
}
export default VoyageNum