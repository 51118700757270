import React, { Component } from 'react'
import {Input} from 'antd'
import './fuzzySearch.css'
const { Search } = Input;
function debounce(fn,ms=5000){
    let timeoutId;
    return function(){
        clearTimeout(timeoutId)
        timeoutId=setTimeout(()=>{
            fn.apply(this,arguments)
        },ms)
    }
}
export default class FuzzySearch extends Component {
    constructor(props){
        super(props)
        // this.getValue=debounce(this.getValue,1000).bind(this)
    }
    searchValue=(props)=>{
        this.getValue(props.target.value)
    }
    getValue=(value)=>{
        this.props.onSearch(value)
    }
    render() {
        let {name,onSearch,placeholder,value} = this.props;
        return (
            <div className="fuzzySearch">
                {name&&<span style={{marginTop:5, textAlign:'center'}} >{name}:</span>}
                <Input style={{width:200}} placeholder={placeholder}  onChange={(props)=>this.props.onSearch(props.target.value)} />
            </div>
        )
    }
}
