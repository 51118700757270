import React, { Component } from 'react'
import config_JSON from './default_JSON/Default_map.json'
import MyComponentFactory from './Toolkit/MyComponentFactoryPackage/MyComponentFactory.js';
import ComponentWorkspace from './Toolkit/MyComponentFactoryPackage/ComponentWorkspace.js';
import { findObjTrackMulti } from '../../../data/dataStore';

const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
const dayList1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
export default class VoyageTrajectory extends ComponentWorkspace {
    constructor(props) {
        super(props)
        this.state = {
            startTime: dayList1,//默认开始时间是今天零点
            endTime: dayList,//默认开始时间是今天23点
        }
    }
    componentDidMount() {
        this.findObjTrackMulti();
        let trajectorydata = this.props.trajectorydata;
        // console.log(trajectorydata,'978')
    }

    async findObjTrackMulti() {
        let { startTime, endTime } = this.state;
        const elements = this.props.trajectorydata;
        console.log(this.props.trajectorydata, '789')
        // this.props.trajectorydata.map((item) => {
        //     elements.push({objectId:item.objectId})
        //     console.log(elements, '789')
        // });
        let objectInfos = [];
        objectInfos = [
            ...objectInfos,
            {
                objectId: this.props.trajectorydata.objectId,
                deviceInfos: [],
            }
        ]
        let params = {
            objectInfos,
            startTime,
            endTime
        }
        let data = await findObjTrackMulti(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let targetList = [];
            let haveDataList = {};
            Object.keys(data.data).map(key => {
                let value = data.data[key];
                if (value.length > 0) {
                    targetList = [
                        ...targetList,
                        'vectorImg_' + key
                    ];
                    haveDataList = {
                        ...haveDataList,
                        [key]: value,
                    }
                }
            })
            console.log(haveDataList, 'haveDataList')
            this.setState({
                trajectoryData: data.data,
                targetList: targetList,
                haveDataList: haveDataList,
            }, () => {
                console.log(this.state.haveDataList ? this.state.haveDataList : '', 'haveDataList')
                Object.keys(this.state.haveDataList).map(key => {
                    let value = this.state.haveDataList[key];
                    if (value.length > 0) {
                        this.state.componentRepository.map.showMap_trajectory_points(value, 'vectorImg_' + key);
                    }
                })
            })
        } else {
        }
    }
    render() {
        let { trajectorydata } = this.props
        console.log(trajectorydata, '0987')
        return (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}>
                <MyComponentFactory
                    type={'map'}
                    componentLoad={this.componentLoad}
                    config_JSON={this.props.config_JSON}
                    parentObj={this}
                />
            </div>
        )
    }
}
