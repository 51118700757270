// 系统管理
import React, { Component } from 'react'
import './css/system.css'
import ShipMonitoring from './ShipMonitoring'
import UserMonitoring from './UserMonitoring'
import Authorization from './Authorization'
import DeviceConfig from '../test/DeviceConfig'
import FirmwareStatus from '../test/FirmwareStatus'
import FirmwareList from '../test/FirmwareList'
import {Menu, Icon, Popconfirm, Layout} from 'antd';
import { logout } from '../../data/dataStore'
import BdInfoSearch from "./BdInfoSearch";
import DeviceMonitoring from "./DeviceMonitoring";
import RegionMonitoring from "./RegionMonitoring";
import CardIIMonitoring from "./CardIIMonitoring";
import MenuConfig from "./MenuConfig";
import SmsMonitoring from "./SmsMonitoring";
import OriginalMsg from "./OriginalMsg";
import DeviceType from "./DeviceType";
import OnlineRateRule from "./OnlineRateRule";
import AlarmRule from "./AlarmRule";
import {Badge, Button, Dropdown} from "antd";
import CmdReportMonitoring from "./CmdReportMonitoring";
import OfflineReportMonitoring from "./OfflineReportMonitoring";
import CommanderSearch from "./CommanderSearch";
import CommanderCardListSearch from "./CommanderCardListSearch";
import CmdStatisticsSearch from "./CmdStatisticsSearch";
import CmdCardStatisticsSearch from "./CmdCardStatisticsSearch";
import OfflineReportMonitoring1 from "./Select7daysDataMsg";
import Select7daysDataMsg from "./Select7daysDataMsg";
import BatteryHistory from './BatteryHistory';
import Alarmhistory from "./AlarmHistory";
import CardLog from './CardLog';
import {BIG_SCREEN_ADDRESS} from "../../config";
const SubMenu = Menu.SubMenu;
const MyIcon=Icon.createFromIconfontCN({
    scriptUrl:'//at.alicdn.com/t/font_1733642_oqfja1ub1h.js'
})
const { Header, Content, Footer, Sider } = Layout;

export default class EnforceTheLaw extends Component {
    constructor(props){
        super(props)
        this.state={
            //首选项
            defaultKey:"bdInfoSearch",
        }
    }
    componentDidMount(){
        const {dispatch,location}=this.props;
        console.log(location,'location')
        let defaultKey;

        if(location.state){//判断当前有参数
            defaultKey=location.state.key;
            this.setState({
                defaultKey
            })
        }
        //获取login信息
        let userData = JSON.parse(sessionStorage.getItem("isLoginUserName"))
        console.log("<<<"+userData)
        this.setState({
            userData,
        })
    }
    changepage=(item,key)=>{
        this.setState({
            defaultKey:item.key,
            eventStatusArr:''
        })
    }
    // 登出接口
    async logout(value) {
        console.log('value----',value)
        let data = await logout(value)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            window.sessionStorage.clear()
            this.props.history.replace('/login')
        }
    }
    handleClick = () => {
        this.setState({
        }, () => {
            this.logout()
        })
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    render() {
        let {defaultKey,msgShow,allsmsDatas,msgShow2,allAlarm,warnShow,userdata}=this.state;
        // this.setState({
        //     userdata,
        // })
        let userData = JSON.parse(sessionStorage.getItem("isLoginUserName"));
        console.log("<<<"+userData)
        //注册页面展示
        const REG_PAGES = {
            //北斗卡展示
            "bdInfoSearch": { title: '北斗综合明细查询', content: <BdInfoSearch history={this.props.history} />, key: 'bdInfoSearch',icon:'icon-yonghu', },
            "shipMonitoring": { title: '船舶管理', content: <ShipMonitoring history={this.props.history}/>, key: 'shipMonitoring',icon:'icon-chuan', },
            'smsMonitoring': { title: '北斗短信查询', content: <SmsMonitoring history={this.props.history} />, key: 'smsMonitoring',icon:'icon-shebeiguanli', },
            'originalMsg': { title: '最新原始信息查询', content: <OriginalMsg history={this.props.history} />, key: 'originalMsg',icon:'icon-yonghu', },
            'select7daysDataMsg': { title: '7天统计信息查询', content: <Select7daysDataMsg history={this.props.history} />, key: 'select7daysDataMsg',icon:'icon-yonghu', },
            'alarmhistory': { title: '报警记录查询', content: <Alarmhistory history={this.props.history} />, key: 'alarmhistory',icon:'icon-yonghu', },
            'batteryHistory': { title: '电池电量查询', content: <BatteryHistory history={this.props.history} />, key: 'batteryHistory',icon:'icon-yonghu', },
            'cardLog': { title: '北斗操作日志', content: <CardLog history={this.props.history} />, key: 'cardLog',icon:'icon-yonghu', },
            'offlineReportMonitoring': { title: '离线统计报表', content: <OfflineReportMonitoring history={this.props.history} />, key: 'offlineReportMonitoring',icon:'icon-yonghu', },

        };
        // const device = {
        //     'deviceConfig': {title:'设备参数及配置',content: <DeviceConfig history={this.props.history}/>,key:'deviceConfig'},
        //     'firmwareStatus': {title:'固件升级状态',content: <FirmwareStatus history={this.props.history}/>,key:'firmwareStatus'},
        //     'firmwareList': {title:'固件列表',content: <FirmwareList history={this.props.history}/>,key:'firmwareList'}
        // }
        // const baseUIList = {
        //     "userMonitoring": { title: '用户管理', content: <UserMonitoring history={this.props.history} />, key: 'userMonitoring',icon:'icon-yonghu', },
        //     "authorization": { title: '权限管理', content: <Authorization history={this.props.history} />, key: 'authorization',icon:'icon-kaiqiquanxian', },
        //     "menuConfig": { title: '菜单管理', content: <MenuConfig history={this.props.history} />, key: 'menuConfig',icon:'icon-kaiqiquanxian', },
        // }
        const bdUIList = {
            // "cardIIMonitoring": { title: '北斗卡管理', content: <CardIIMonitoring history={this.props.history}/>, key: 'cardIIMonitoring',icon:'icon-chuan', },
            // "deviceMonitoring": { title: '设备管理', content: <DeviceMonitoring history={this.props.history} />, key: 'deviceMonitoring',icon:'icon-yonghu', },
            "shipMonitoring": { title: '船舶管理', content: <ShipMonitoring history={this.props.history}/>, key: 'shipMonitoring',icon:'icon-chuan', },
            // "regionMonitoring": { title: '区域管理', content: <RegionMonitoring history={this.props.history}/>, key: 'regionMonitoring',icon:'icon-chuan', },
            // "deviceType": { title: '设备类型', content: <DeviceType history={this.props.history} />, key: 'deviceType',icon:'icon-yonghu', },
            'cardLog': { title: '北斗操作日志', content: <CardLog history={this.props.history} />, key: 'cardLog',icon:'icon-yonghu', },
        }
        // const ruleUIList = {
        //     // "onlineRateRule": { title: '在线率规则', content: <OnlineRateRule history={this.props.history}/>, key: 'onlineRateRule',icon:'icon-chuan', },
        //     // "alarmRule": { title: '告警规则', content: <AlarmRule history={this.props.history} />, key: 'alarmRule',icon:'icon-yonghu', },
        // }
        const reportUIList = {
            'bdInfoSearch': { title: '北斗综合明细查询', content: <BdInfoSearch history={this.props.history} />, key: 'bdInfoSearch',icon:'icon-yonghu', },
            // 'smsMonitoring': { title: '北斗短信查询', content: <SmsMonitoring history={this.props.history} />, key: 'smsMonitoring',icon:'icon-yonghu', },
            'originalMsg': { title: '最新原始信息查询', content: <OriginalMsg history={this.props.history} />, key: 'originalMsg',icon:'icon-yonghu', },
            'select7daysDataMsg': { title: '7天统计信息查询', content: <Select7daysDataMsg history={this.props.history} />, key: 'select7daysDataMsg',icon:'icon-yonghu', },
            'alarmhistory': { title: '报警记录查询', content: <Alarmhistory history={this.props.history} />, key: 'alarmhistory',icon:'icon-yonghu', },
            'batteryHistory': { title: '电池电量查询', content: <BatteryHistory history={this.props.history} />, key: 'batteryHistory',icon:'icon-yonghu', },
            'offlineReportMonitoring': { title: '离线统计报表', content: <OfflineReportMonitoring history={this.props.history} />, key: 'offlineReportMonitoring',icon:'icon-yonghu', },
        }
        const cmdUIList = {
            // "commanderSearch": { title: '查询所有指挥机', content: <CommanderSearch history={this.props.history} />, key: 'commanderSearch',icon:'icon-yonghu', },
            // "commanderCardListSearch": { title: '查询指挥机的卡号集合', content: <CommanderCardListSearch history={this.props.history} />, key: 'commanderCardListSearch',icon:'icon-yonghu', },
            // "cmdStatisticsSearch": { title: '查询指挥机的发送统计', content: <CmdStatisticsSearch history={this.props.history} />, key: 'cmdStatisticsSearch',icon:'icon-yonghu', },
            // "cmdCardStatisticsSearch": { title: '查询指挥卡的发送统计', content: <CmdCardStatisticsSearch history={this.props.history} />, key: 'cmdCardStatisticsSearch',icon:'icon-yonghu', },
        }
        return (
            <div>
            <div className="header">
                <div className="header_left">
                    <ul>
                        {/*<li>实时监控</li>*/}
                        {/*<li>实时台风</li>*/}
                        {/*<li>运营统计</li>*/}
                        <li>系统管理</li>
                        {/*<a target='_blank' href={BIG_SCREEN_ADDRESS}>大屏入口</a>*/}
                    </ul>
                </div>
                <div className="header_right">

                    <Dropdown
                        overlay={
                            <div>
                                <div className="dropMenu1">
                                    <Button onClick={this.handleClick} >退出登录</Button >
                                </div>
                            </div>
                        }
                    >
                        <span>
                            <Icon type="user" style={{ marginRight: 10 }} />
                            {this.state.userData}
                            <Icon type="down" style={{ margin: '0 10px', color: '#fff' }} />
                        </span>
                    </Dropdown>

                </div>
            </div>
            {/*<div className="system" style={{display:"flex",alignContent:'space-between'}}>*/}
            <div className="system">
                <Layout>
                    <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.toggleCollapsed}>
                        {/*<div className = 'menu' style= {{ width : this.state.collapsed?80:256,  position: 'absolute', zIndex:2, top:'60px'}} ref={this.controlWidth}>*/}
                        <div className = 'menu' >
                            {/*<Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16,marginLeft:25 }}>*/}
                            {/*    <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />*/}
                            {/*</Button>*/}
                            <Menu
                                selectedKeys={[defaultKey]}
                                mode="inline"
                                theme="dark"
                                inlineCollapsed={this.state.collapsed}
                                onClick={this.changepage}
                            >
                                {/*<SubMenu key="sub2" title={ <span><Icon type="dashboard" /><span>基础管理</span></span> }>*/}
                                {/*    {*/}
                                {/*        Object.keys(baseUIList).map(item => (*/}
                                {/*            <Menu.Item key={baseUIList[item].key}>*/}
                                {/*                <span>{baseUIList[item].title}</span>*/}
                                {/*            </Menu.Item>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</SubMenu>*/}
                                <SubMenu key="sub3" title={ <span><Icon type="dashboard" /><span>北斗管理</span></span> }>
                                   {
                                       Object.keys(bdUIList).map(item => (
                                           <Menu.Item key={bdUIList[item].key}>
                                                <span>{bdUIList[item].title}</span>
                                           </Menu.Item>
                                        ))
                                    }
                                </SubMenu>

                                <SubMenu key="sub5" title={ <span><Icon type="dashboard" /><span>统计查询</span></span> }>
                                    {
                                        Object.keys(reportUIList).map(item => (
                                            <Menu.Item key={reportUIList[item].key}>
                                                <span>{reportUIList[item].title}</span>
                                            </Menu.Item>
                                        ))
                                    }
                                </SubMenu>

                            </Menu>
                        </div>
                    </Sider>
                    <Layout>
                        <Content>
                            {/*<div  id='content' style= {{ position: 'absolute', zIndex:2, top:'5px',left:this.state.collapsed?80:240,marginLeft:'20px'}}>*/}
                            <div  id='content'>
                                {
                                    REG_PAGES[defaultKey]?
                                        [REG_PAGES[defaultKey]].map((item,index)=>(
                                            <div key={item.key+index}>
                                                {item.content}
                                            </div>
                                        )):
                                        [device[defaultKey]].map((item,index)=>(
                                            <div key={item.key}>
                                                {item.content}
                                            </div>
                                        ))
                                }
                            </div>
                        </Content>
                    </Layout>
                </Layout>

            </div>
            </div>
        )
    }
}
