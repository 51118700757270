/*
 *   路由配置
 */
import { Router, Route, IndexRoute, Redirect, Switch } from 'react-router'
import React, { Component } from 'react'
import Login from '../pages/login/Login.js'
import Home from '../pages/home/Home.js'//首页
import RealTimeTyphoonMonitoring from '../pages/RealTimeTyphoonMonitoring/RealTimeTyphoonMonitoring'//首页
import Statistics from '../pages/Statistics/Statistics.js'
import System from '../pages/System/System.js'
import System1 from '../pages/System/System1.js'
import EnforceTheLaw from '../pages/EnforceTheLaw/EnforceTheLaw.js'//执法管理
import ShipDetail from '../pages/ShipDetail/ShipDetail.js'
import WarMap from '../pages/WarMap/WarMap.js'
import AuthLogin from './AuthLogin'
import Header from '../componts/header/Header';
import TrackQuery from "../pages/TrackQuery/TrackQuery";
import OfflineReportMonitoring1 from "../pages/System/Select7daysDataMsg";
import Select7daysDataMsg from "../pages/System/Select7daysDataMsg";
class Routers extends Component {
    render() {
        let dictData = JSON.parse(sessionStorage.getItem("dictData"))

        return (
            <div>

                {dictData &&  <Header />}
                <Switch>
                    {/*<Route path="/home" component={Home} />*/}
                    <Route path="/home" children={({match,...restProps})=>{
                        return <Home bdInfo={{...restProps.location.state}}/>
                    }} />
                    <AuthLogin path="/RealTimeTyphoonMonitoring" component={RealTimeTyphoonMonitoring} />
                    <AuthLogin path="/statistics" component={Statistics} />
                    <Route path="/trackQuery" component={TrackQuery} />
                    <Route path="/system" component={System} />
                    <Route path="/system1" component={System1} />
                    <AuthLogin path="/enforceTheLaw" component={EnforceTheLaw} />
                    <Route path="/login" component={Login} />
                    {/*// <Route path="/login" component={Login} />*/}
                    <AuthLogin path="/shipDetail" component={ShipDetail} />
                    {/*// <AuthLogin path="/shipDetail" component={ShipDetail} />*/}
                    <AuthLogin path="/warMap" component={WarMap} />
                    <AuthLogin path="/" component={WarMap} />
                    <Route path="/select7daysDataMsg" component={Select7daysDataMsg}></Route>

                </Switch>

            </div>



        )
    }
}

export default Routers;
