import React, { Component } from 'react';
import { Input, Checkbox, Button, Icon, message } from 'antd';
import CryptoJS from 'crypto-js';
// import { Router, Route, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import './login.css'
import {
    login,
    selectUser,
    // getDict,
    // getLayerInfo,
    // system_config
} from '../../data/dataStore';
import Form from '../../componts/form/Form.js'
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.sendLogin = this.sendLogin.bind(this)
    }
    componentDidMount() {
        // document.addEventListener('keydown', this.onkeydown)
        // this.getDict()
        // this.system_config()
    }

    // 数据字典
    async getDict() {
        let params = {
        }
        let data = await getDict(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200 && data.data.length > 0) {
            sessionStorage.setItem('dictData', JSON.stringify(data.data))
            this.setState({
                getDict: data.data
            }, () => {
                this.props.history.replace('/home')
            })
            sessionStorage.setItem('dictData', JSON.stringify(data.data))
        } else {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    // T图层数据
    async getLayerInfo() {
        let params = {
        }
        let data = await getLayerInfo(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200 && data.data.length > 0) {
            // this.setState({
            //     getDict: data.data
            // })
            // sessionStorage.setItem('dictData',JSON.stringify(data.data))
        } else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    //系统配置信息
    // async system_config() {
    //     let params = {
    //
    //     }
    //     let data = await system_config(params)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data && data.code == 200) {
    //         this.setState({
    //             configData: data.data
    //         }, () => {
    //
    //         })
    //
    //     } else if (data && data.code == 500) {
    //     }
    // }
    // 登录接口
    async sendLogin(value) {
        const secretKey = '123456789';
        let params = {
            userName: value.userName,
            password: value.password,

        }
        // console.log(params, '----------------------------------------0080979070')
        let data = await login(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        // console.log("--------data-----------"+JSON.stringify(data));
        // console.log("--------token-----------"+data.token);
        if (data && data.code==200) {
            // message.info(data.message)
            // let user={userId:data.data.client.userId,userName:data.data.username,title:data.data.companyName}
            sessionStorage.setItem('isLogin', JSON.stringify(data))
            // console.log(">>>"+JSON.parse(sessionStorage.getItem("isLogin")));
            sessionStorage.setItem('auth-token', data.token)
            //存储用户名
            sessionStorage.setItem('isLoginUserName',JSON.stringify(data.userInfo.code))
            //存页面权限
            sessionStorage.setItem('baseUIList',JSON.stringify(data.userInfo.baseUIList))
            sessionStorage.setItem('bdUIList',JSON.stringify(data.userInfo.bdUIList))
            sessionStorage.setItem('reportUIList',JSON.stringify(data.userInfo.reportUIList))
            //接口里面code即是用户名
            // console.log("<<<"+JSON.stringify(data.userInfo.code))
            // sessionStorage.setItem('token',data.data.password)
            //
            //
            // sessionStorage.setItem('loginData', JSON.stringify(data.data.name))
            // sessionStorage.setItem('subActiveKey_type', '')
            //后端测试端口 bdmngdevapi
            //后端正式端口 bdmngapi
            // sessionStorage.setItem('protectApi', 'bdmngdevapi')
            // sessionStorage.getItem('protectApi')
            // this.getLayerInfo();
            // this.getDict();
            // this.props.updateUserRights(data);
            // message.error(data && data.msg)
             this.props.history.replace("/system")
            // if (data.userInfo.code === 'admin'){
            //     this.props.history.replace("/system")
            // }else {
            //     this.props.history.replace("/system1")
            // }

        } else {
            message.error(data.message)
            // message.error("账号或密码错误，请重新输入！")
        }
    }
    render() {
        const formItemList = [
            {
                // label: '用户名', 
                id: 'userName', //表单Item的Key值
                component: <Input placeholder={'用户名'} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />, //表单受控组件
                options: { rules: [{ required: true, message: '用户名不能为空!' }] }
            },
            {
                // label: '密码', 
                id: 'password',
                component: <Input placeholder={'密码'} type="password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />,
                options: { rules: [{ required: true, message: '密码不能为空!' }] }
            }]
        const { } = this.state;
        return (
            <div className="login">
                {/*<span>北斗三运营平台</span>*/}
                <div className='login_from'>

                    <Form
                        formItemList={formItemList}
                        submit={this.sendLogin}
                        className='loginForm'
                    />
                    <p className="remark">
                        建议使用谷歌浏览器，屏幕分辨率1920*1080
                    </p>
                </div>
            </div>
        )
    }
}
const initMapStateToProps = state => ({
    userRights: state.userRights,
})
const initMapDispatchToprops = (dispatch) => ({
    updateUserRights: (data) => dispatch({ type: 'UPDATE_userRights', payload: data })
})
export default connect(initMapStateToProps, initMapDispatchToprops)(Login);
