import React, { Component } from 'react'
import moment from 'moment';
import './warMap.css'
class Stopwatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nowTime:moment().format("YYYY-MM-DD HH:mm:ss")
        }
    }

    componentDidMount(){
        var that = this
        clearInterval(this.time);
        this.time = setInterval(function () {
            that.getNowTime()
        }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this.time);
        clearInterval(this.interval_allshipPos);
    }
    getNowTime = () => {
        var nowTime;
        nowTime = moment().format("YYYY-MM-DD HH:mm:ss")
        this.setState({
            nowTime
        })
    }
    render() {
        return (
            <div className='title'>
                <span>{this.props.title}</span>
                <p>{
                    this.state.nowTime
                }</p>
            </div>
        );
    }
}
export default Stopwatch;