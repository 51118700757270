// 原始报位信息
import React, { Component } from 'react'
import './css/system.css'
import {Table, Input, Modal, Icon,message, Button, Spin, TreeSelect} from 'antd';
import {
    updateLoginCount,
    selectOriginalMsg,
    selectByRegion,
    exportOriginalList
} from '../../data/dataStore'
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {DatePicker} from 'antd';
import $ from "jquery";
const { TextArea, Search } = Input;
const { RangePicker } = DatePicker;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
//今天0点
const piker1 = moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss');
//今天23点59
const piker2 = moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss');

class OriginalMsg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            loading: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            deleteData: {},
            ulshow: false,
            selectedKeys: [],
            cardNumber: '',
            id: '',
            name:'',
            userData: [],
            pasValue: '',
            modifyData: {},
            userGroupData: [],
            pageNo: 1,
            pageSize: 10,
            pageTotal: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            //上个月今天
            startTime: moment(piker1).format('YYYY-MM-DD HH:mm:ss'),
            //今天的年月日
            endTime: moment(piker2).format('YYYY-MM-DD HH:mm:ss'),
            trajectoryDateList: [

                //本月
                // moment(moment().startOf('month')),
                // moment(moment().endOf('month'))
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
                // moment(moment().endOf('month'))
                // moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss'),

                // moment(moment.add(-1,'M')),
                // moment(moment.add(0,'M'))

            ],//上报时间
            isLoading: false,
            // 空树形结构数组
            treeData : [

            ],
            visible: false,
            textAreaValue:[],
        }
        this.selectOriginalMsg = this.selectOriginalMsg.bind(this)
        this.selectByRegion = this.selectByRegion.bind(this)
        this.exportOriginalList = this.exportOriginalList.bind(this)
    }
    componentDidMount() {
        // this.selectOriginalMsg()
        let user=JSON.parse(sessionStorage.getItem('isLogin'))
        this.setState({
            admin:user.data

        })
        console.log("--------------------------"+user.message)
        this.selectByRegion()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
        // try {
        //     this.setState({ isLoading: true }, async () => {
        //         await this.selectOriginalMsg();
        //     });
        // } catch (e) {
        //     message.error(`获取数据失败`);
        // }

    }
    //导出Excel
    async exportOriginalList(value, type) {
        this.setState({ loading: true })
        let { datas } = this.state;
        let params = {
            cardNumber: this.state.textAreaValue,
            taskNum: this.state.taskNum
        }
        let data = await exportOriginalList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        datas: data,
                    }
                )
            } else {
                location.href = data.message
                this.setState({
                    datas: data,
                    loading: false
                })

            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    onChangeBdCard = ({ target: { value } }) => {
        this.setState({textAreaValue:value});
    };
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectOriginalMsg()
        })
    }
    showModal = () => {
        this.setState({
            visible: true,
            textAreaValue:[],
        });
    };
    handleOk = e => {
        // console.log("this.state.textAreaValue",this.state.textAreaValue);
        const value = this.state.textAreaValue
        const arr = value.length!==0?value.split('\n'):[];
        // console.log('arrarrarrarr',arr)
        this.setState({
            visible: false,
            textAreaValue:arr
        });
    };
    handleCancel = e => {
        this.setState({
            visible: false,
            textAreaValue:[]
        });
    };

    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectOriginalMsg()
            })
        } else {
            this.setState({
                    name: eve.target.value,
                    shipName: name,
                }, () => {
                    this.selectOriginalMsg(this.state.name, 'select')
                }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.selectOriginalMsg(data.name)
        this.setState({
            number: data.number
        })
    }
    // 查询区域树形结构
    async selectByRegion(value, type) {
        let params = {

        }
        let data = await selectByRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            //console.log(">>>>>>>>>>selectByRegion"+JSON.stringify(data))
            let treeSelectData = this.handleData(data.rows);
            if (type === 'select') {
                this.setState({
                        treeData: treeSelectData,
                    }
                )
            } else {
                this.setState({
                    treeData: treeSelectData,
                })
            }
            //console.log(">>>>>>>>>>selectByRegion<<<<<<<<<<<<<<<<"+JSON.stringify(this.state.treeData))
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    selectByTime = (e, value) => {

        let startTime = value[0] + " 00:00:00";
        let endTime = value[1];
        endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
        console.log("<<<"+startTime)
        console.log("<<<"+endTime)
        this.setState({
            startTime: startTime,
            endTime: endTime,

        })
    }
    // 查询最新原始信息
    async selectOriginalMsg(value, type) {
        this.setState({ loading: true })
        console.log('kkkkkkk----------')
        let {startTime,endTime,pagination } = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
            // number: this.state.pa
            //增加开始时间与结束时间参数
            regionId: this.state.regionId,
            //增加开始时间与结束时间参数
            startTime,
            endTime
        }
        let data = await selectOriginalMsg(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        // console.log("+++++++++++++++++++++++++++++++++++++++"+data.rows);

        if (data && data.code == 200) {

                this.setState({
                    ulshow: false,
                    userData: data.rows,
                    pageTotal: data.total,
                    loading: false,
                })
            }else if (data.code == 500) {
            message.success(data.message)
            this.setState({
                    ulshow: true,
                    loading: false,
                }
            )
            }else if(data.code == 501){
                message.error("222222222"+data.message)
            } else if (data.code == 505) {
                this.props.history.push('/login')
            } else if (data.code == 599) {
                message.success("系统内部错误")
            this.setState({loading:false})
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 点击表格删除事件
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 点击表格修改事件
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }
    // 点击表格重置密码事件
    resetPassword = (data) => {
        this.setState({
            resetShow: true,
            resetData: data
        })
    }
    // 点击表格解锁事件
    async updateLoginCount(user){
        let {admin}=this.state;
        console.log(admin,user,'dddddd')
        let params={
            id:user.name,
            adminId:admin.name
        }
        let data= await updateLoginCount(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if(data&&data.code==200){
            message.success(data.message)
        }else{
            message.error(data.message)
        }
    }
    // 点击表格管理的事件
    clickManage = (data) => {
        // this.getUgroupTree(data.id)
        this.getRoleList(data.id)
        this.setState({
            manageShow: true,
            manageData: data
        })
    }
    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }

    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //用户分配用户组的选中
    distribute_onChage = (selectedRowKeys) => {
        let keyArr = this.getSelectKey(this.state.userGroupData, selectedRowKeys)
        console.log("+++++++++++++453++++++++++++"+keyArr);
        this.setState({
            selectedKeys: keyArr
        })
    }
    // 扁平化多重嵌套数组对象
    flatten = (arr) => {
        let myArr = []
        for (const item of arr) {
            myArr.push(item);
            if (item.children && item.children.length > 0) {
                myArr.push(...this.flatten(item.children))
            }
        }
        return myArr
    }
    // 模糊搜索
    getSearchValue = (data, value) => {
        let dataSource = this.flatten(data)
        let newArr;
        if (value) {
            newArr = dataSource.filter((item) => {
                console.log(value, item.name.indexOf(value), 'item')
                return item.name.indexOf(value) != -1
            })
        } else {
            newArr = data
        }

        this.setState({
            nowModalData: newArr
        })
    }
    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }
    /**
     * 递归生成数据  因为后端给的字段没有和ant design相关的 title value等属性值
     * 所以需要递归修改data属性值，配合treeSelect规范数据
     * @param {Object} data 原始数据
     */
    handleData = (data) => {
        const item = [];
        data.map((list) => {
            const newData = {};
            newData.value = list.regionName;
            newData.id = list.id
            newData.title = list.regionName;
            newData.children = list.children ? this.handleData(list.children) : []; // 如果还有子集，就再次调用自己
            item.push(newData);
            return false
        });
        //console.log("<<<"+JSON.stringify(item))
        return item;
    }

    async downloadFile(value, type, pageSizeApi, coloums,id){

        this.setState({ loading: true })
        let {startTime,endTime,pagination } = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            startTime,
            endTime,
            // pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            // pageSize: pagination.pageSize,
            regionId: this.state.regionId
        }
        let data = await exportOriginalList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        loading: false,
                        // downloadFileUrl:data.message,
                    }
                )
            } else {
                this.setState({
                    loading: false,
                    // downloadFileUrl:data.message,
                })
            }
            const downloadFileUrl=data.message;
            if (downloadFileUrl !==''){
                // window.open(downloadFileUrl);
                location.href=downloadFileUrl
            }else {
                message.warning('当前没有可下载的报表')
            }
        } else if (data && data.code == 401) {
            this.props.history.push('/login');
        } else {
            message.error(data.message)
            this.setState({loading:false})
        }

    }

    onChange = value => {
        console.log("<<<"+value);
        this.setState({
            value,
            regionName:value
        });
    };
    onSelect = (value,node,extra) => {
        console.log("<<<"+(node['props']['id']))
        console.log("<<<"+value,node.toString(),extra )
        this.setState({
            node,
            regionId:node['props']['id']
        })
    }
    render() {
        const { isLoading } = this.state;
        let { userData,textAreaValue, modifyData, userDataArr, nowModalData, ulshow, selectedKeys, userGroupData, pageTotal,trajectoryDateList,startTime,endTime  } = this.state
        const secretKey = '123456789';
        let {treeData, ...rest } = this.props;

        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '发送时间',
                key: 'sendTime',
                dataIndex: 'sendTime',
                // render: (sendTime) => {
                //     let time = moment((sendTime)).format('YYYY-MM-DD HH:mm:ss')
                //     return time;
                // },
            },
            {
                title: '发送卡号',
                key: 'src',
                dataIndex: 'src',
            },

            {
                title: '接收卡号',
                key: 'dst',
                dataIndex: 'dst',
            },
            {
                title: '类型',
                key: 'type',
                dataIndex: 'type',
            },
            {
                title: '发送内容',
                key: 'data',
                dataIndex: 'data',
                // render: (data) => {
                //     let txt =  data.substring(0,20)+'...'
                //     return txt;
                // },
            },
        ]
        const columns2 = [
            {
                title: '权限名称',
                key: 'roleName',
                dataIndex: 'roleName',
            },

            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
                render: (text) => {
                    return text ? text : '--'
                }
            },
        ]

        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">原始报文查询</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                北斗卡号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                报位时间：
                                <RangePicker
                                    // locale={locale}
                                    style={{
                                        width: '70%',
                                        alignItems: 'center'
                                    }}
                                    defaultValue={[moment(startTime), moment(endTime)]}
                                    ranges={{
                                        今日: [moment(), moment()],
                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                        '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                                        '今年': [moment().startOf('year'), moment().endOf('year')],
                                    }}
                                    value={[moment(startTime), moment(endTime)]}
                                    allowClear={false}
                                    onChange={this.selectByTime}

                                />
                            </div>
                        </div>
                    {/*    <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                区域：
                                {<TreeSelect
                                    style={{
                                        width: '70%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    id={this.state.value}
                                    value={this.state.regionName}
                                    allowClear={true}
                                    treeNodeLabelProp="regionName"
                                    placeholder="Please select"
                                    treeData={this.state.treeData}
                                    // treeDefaultExpandAll
                                    onChange={this.onChange}
                                    onSelect={this.onSelect}
                                    {...rest}>
                                </TreeSelect>}
                            </div>

                        </div> */}

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectOriginalMsg()}
                            >查询</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:10,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={() =>{
                                    this.setState({
                                        cardNumber: [],
                                        textAreaValue: '',
                                        regionName: '',
                                        regionId: '',

                                    },() =>{

                                    })
                                }}

                            >重置</Button>
                        </div>
                            <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    left:20,
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.downloadFile()}
                            >下载</Button>

                        </div>
                   {/*      <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:20,
                                    border: 'unset'
                                }}
                                onClick={() => {
                                    // this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})
                                    this.exportOriginalList()
                                }}

                            >下载</Button>
                        </div>  */}
                    </div>
                </div>

                <div className="userManagement">
                    <Spin spinning={isLoading} size={"large"} color={"#123abc"} tip={"数据可劲加载中..."}>
                    <Table
                        columns={columns}
                        dataSource={userData}
                        scroll={{y:615}}
                        loading={this.state.loading}
                        rowKey={record=>record.id}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectOriginalMsg(selectMenuDataObj);
                                })
                            }
                        }}
                        onChange={this.handleTableChange}

                    />
                    </Spin>
                </div>

                <Modal
                    title="请输入北斗卡号"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <TextArea
                        value={textAreaValue}
                        onChange={this.onChangeBdCard}
                        placeholder="请输入北斗卡号，确保一行一个,会自动转换格式,为空默认查全部"
                        // autoSize={{ minRows: 10, maxRows: 10 }}
                        style={{height:150}}
                    />
                </Modal>
            </div>

        )
    }
}
export default OriginalMsg