// 重点监控管理
import React, { Component } from 'react'
import SearchTop from './components/SearchTop/SearchTop'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { insertShipMonitorInfo, selectShipMonitorInfoObscure, deleteShipMonitorInfo, getArgs,fastSelectShip } from '../../data/dataStore'
const { TextArea } = Input;
const { Search } = Input;
const { Option } = Select;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class KeyMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            deleteData: {},
            pageIndex: 0,
            pageSize: 100,
            remark: '123',
            Label: false,
            ulshow: false,
            dataSource: [],
            shipName: '',
            name:'',
            monitorType: 302301,
            pageTotal: null,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10000,
                // showQuickJumper: true
            },
            loading: false,
            labelSize: 1,
        }
        this.deleteShipMonitorInfo = this.deleteShipMonitorInfo.bind(this)
        this.selectShipMonitorInfoObscure = this.selectShipMonitorInfoObscure.bind(this)
        this.insertShipMonitorInfo = this.insertShipMonitorInfo.bind(this)
    }
    componentDidMount() {
        this.selectShipMonitorInfoObscure();
        this.fastSelectShip();
        this.getArgs();
        let dictData = JSON.parse(sessionStorage.getItem("dictData"))
        let shipTypeArr = []
        dictData.forEach((item) => {
            if (item.category === "ship_type") {
                shipTypeArr.push({
                    text: item.typeName,
                    value: item.typeCode
                })
            }
        })
        this.setState({
            shipTypeArr
        })
    }
    // 搜索船名和标签id
    async fastSelectShip(value) {
        let params = {

        }
        let data = await fastSelectShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.data,
            })


        } else {
        }
    }
    clickSearchData = (value) => {
        this.setState({
            leaveAreaNamevalue: value,
        }, () => {
            this.selectShipMonitorInfoObscure()
        });
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectShipMonitorInfoObscure()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.selectShipMonitorInfoObscure(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
  clickSearchDatavalue = (names) => {
    this.selectShipMonitorInfoObscure(names)
    this.setState({
        name: names
      })
  }
    //分页查询
    async selectShipMonitorInfoObscure(value, type) {
        this.setState({ loading: true })
        let { pagination, shipName,regionNameArr,name } = this.state;
        let params = {
            regionName: regionNameArr,
            shipName: value ? value : name,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
        }

        let data = await selectShipMonitorInfoObscure(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {
            if (type === 'select') {
                this.setState({
                    ullAll:data.data.items,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                pagination.total = data.data.pageTotal
                this.setState({
                    ulshow: false,
                    loading: false,
                    dataSource: data.data.items,
                    pageTotal: data.pageTotal,
                    pagination,
                    loading: false
                })
            }
            pagination.total = data.data.pageTotal
        } else {
        }
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 新增船
    async insertShipMonitorInfo(obj) {
        obj.monitorType = 302301
        let data = await insertShipMonitorInfo(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            this.setState({
                addShow: false
            })
        }
        else if (data.code == 500) {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
        }, () => {
            this.selectShipMonitorInfoObscure()
        })
    }
    Labelmanagement = (data) => {
        this.setState({
            Label: true,
            labelData: data
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            // pagination: { ...pagination }
        }, () => {
            // this.selectShipMonitorInfoObscure()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }

    deleteClickShow = (data) => {
        console.log(data)
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 删除船
    async deleteShipMonitorInfo() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.id,
        }
        console.log(deleteData, params, 'params')
        let data = await deleteShipMonitorInfo(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectShipMonitorInfoObscure()
        } else if (data.code == 500) {
            message.error("删除失败")
        }
        this.setState({
            deleteShow: false,
        })
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }
     //分页筛选
  async getArgs(value) {
    let params = {

    }
    let data = await getArgs(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });

    if (data && data.code == 200) {
      this.setState({
        objectNameArrss: data.data,

      })


    } else {
    }
  }
    getColumnSearchProps = () => ({
        filterDropdown: () => {
          let { objectNameArrss } = this.state
          return <div style={{ padding: 8, display: 'block' }}>
            <div>
              <Select style={{ width: 100 }} placeholder={'请选择'}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  objectNameArrss && objectNameArrss.regionNames.map((item) => (
                    <Option
                      onClick={this.clickSearchData.bind(this, item)}
                      value={item} >{item}</ Option>
                  ))
                }
              </Select>,
            </div>
            <div>
            </div>
          </div>
        }
      });
      //点击搜索
      clickSearchData = (value) => {
        this.setState({
            regionNameArr: value,
        }, () => {
          this.selectShipMonitorInfoObscure()
        });
      }
    render() {
        let { dataSource,ullAll, modifyData, LabelSize, labelData, dictData, shipTypeArr, ulshow, objectNameArr } = this.state;
        let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        console.log(ullAllss, 'ullAlls')
        const columns = [
            {
                title: '船名',
                key: 'shipName',
                dataIndex: 'shipName',
            },
            {
                title: '船舶类型',
                key: 'shipType',
                dataIndex: 'shipType',
                render: (text) => {
                    let newText;
                    if (shipTypeArr) {
                        shipTypeArr.forEach((item) => {
                            if (item.value == text) {
                                newText = item.text
                            }
                        })
                    }
                    return newText
                },

            },
            {
                title: '船主',
                key: 'owerName',
                dataIndex: 'owerName',
            },
            {
                title: '联系方式',
                key: 'owerPhone',
                dataIndex: 'owerPhone',
            },
            {
                title: '属地',
                key: 'regionName',
                dataIndex: 'regionName',
        ...this.getColumnSearchProps('regionName'),

            },
            {
                title: '重点监控原因',
                key: 'remark',
                dataIndex: 'remark',
            },
            {
                title: '时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
                sorter: (a, b) => {
                    if (a.gmtCreate > b.gmtCreate) {
                        return 1;
                    } else if (a.gmtCreate < b.gmtCreate) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '操作',
                key: 'detele',
                render: (text, record) => {
                    return <span style={{ color: '#1890FF' }} onClick={this.deleteClickShow.bind(this, record)}>删除</span>
                },
            },
        ]
        const formItemList = [
            {
                label: '船舶名称', //表单label
                id: 'shipName', //表单Item的Key值
                component: <Select placeholder={'请选择'}
                    // filterOption={(input, option) =>
                    //     option.children.objectName().indexOf(input.toLowerCase()) >= 0
                    //   }
                    //   optionFilterProp="children"
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    // onChange={handleChange}
                    notFoundContent={null}
                >
                    {
                        objectNameArr && objectNameArr.map((item) => (
                            <Select.Option value={item.objectName} >{item.objectName}</Select.Option>
                        ))
                    }
                </Select>,
                options: { rules: [{ required: true, message: '船舶名称不能为空!' }] }
            },
            // {
            //     label: '船舶类型', //表单label
            //     id: 'monitorType', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            //     options:{rules: [{ required: true }]}
            // },
            {
                label: '监控原因', //表单label
                id: 'remark', //表单Item的Key值
                component: <TextArea placeholder={'请输入'} style={{ height: 80 }} />,
                options: { rules: [{ required: true, message: '监控原因不能为空!' }] }
            },]
        return (
            <div className="contentBox">
                <div style={{display:'flex'}}>
                 <Input 
                 value={this.state.name}
                  className='Searchvalue' 
                  onSearch={value => this.selectShipMonitorInfoObscure(value, 'select')} 
                  onChange={value => this.controlList(value)} 
                  onPressEnter={() => this.selectShipMonitorInfoObscure()}
                  placeholder="请输入船名" />
                <SearchTop className="SearchTopvalue"  onclick={this.addClickShow} onSearch={this.getSearchValue} />
                </div>
                {
                 ulshow && <ul className="searchList">
                {
                  ullAllss && ullAllss.map((item, index) => (
                    <li
                      key={item.index}
                      onClick={this.clickSearchDatavalue.bind(this, item)}
                      style={{
                        cursor: 'pointer',
                        fontSize: 16,
                        display: 'flex',
                        alignItems: "center",
                      }}
                    ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                    </li>
                  ))
                }
              </ul>
            }
                <div>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        loading={this.state.loading}
                        pagination={{
                          defaultPageSize: 10,
                          showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
                        //   showSizeChanger: true,
                          showQuickJumper: true,
                        }}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertShipMonitorInfo}
                    title="新增"
                />
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    onOk={this.deleteShipMonitorInfo}
                    title="删除提醒"

                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.shipName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
            </div>
        )
    }
}
export default KeyMonitoring