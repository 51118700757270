import React, { Component } from 'react'
import { Table, Input, Button, Icon, message } from 'antd'
import { alarmStatistics, getDict, selectShipPage, timeoutAlarmStatistics, getArgs } from '../../data/dataStore';
import moment from 'moment';
import { exportExcel } from 'xlsx-oc';
import './statistics.css'
const { Select } = { Input }
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
export default class AlarmTimeout extends Component {
    constructor(props) {
        super(props)
        this.state = {

            pageSize: 10000,
            ulshow: false,
            InputValue: '1',
            pageIndex: 0,
            dataSource: [],
            shipName: '',
            name: ' 24',
            pageTotal: 9990999,
            pagination11: {
                total: 0,
                current: 1,
                pageSize: 10000,
                showQuickJumper: true

            },
            pagination: {
                defaultPageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
                // showSizeChanger: true,
                showQuickJumper: true,
            },
            selectedRowKeys: []
        }
    }
    componentDidMount() {
        this.timeoutAlarmStatistics();
    }

    async timeoutAlarmStatistics(value, type) {
        // this.setState({loading:true})
        let { startTime, endTime, pagination11, pagination, eventTypevalue, eventStatusArr, searchValue, filters, name } = this.state;
        let params = {
            timeNum: value ? value : name,
            pageIndex: pagination11.current,
            pageSize: pagination11.pageSize,
            pageTotal: this.state.total1,
            ...filters,
        }
        let data = await timeoutAlarmStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {

            if (type === 'select') {
                this.setState({
                    ullAll: data.data.items,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                pagination.current = 1
                this.setState({
                    ulshow: false,
                    loading: false,
                    dataSource: data.data.items,
                    pageTotal: data.pageTotal,
                    pagination,
                    loading: false,
                })
            }
        } else {
        }
    }
    //导出
    exportDefaultExcel = (e, coloums) => {
        let dockInfoData = this.state.dataSource;
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (dockInfoData && dockInfoData.length > 0) {
            exportExcel(headers, dockInfoData, '超时船舶管理报表.xlsx');
        }
        else {
            message.error('导出数据失败！！！');
        }
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                // this.timeoutAlarmStatistics(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.timeoutAlarmStatistics(names)
        this.setState({
            name: names
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            // pagination: { ...pagination }
            pagination: pagination
        }, () => {
            // this.timeoutAlarmStatistics()
        })
    }
    //度°分′秒″转度
    ToDigital = (strDu, strFen, strMiao, len) => {
        len = (len > 6 || typeof (len) == "undefined") ? 6 : len;//精确到小数点后最多六位   
        strDu = (typeof (strDu) == "undefined" || strDu == "") ? 0 : parseFloat(strDu);
        strFen = (typeof (strFen) == "undefined" || strFen == "") ? 0 : parseFloat(strFen) / 60;
        strMiao = (typeof (strMiao) == "undefined" || strMiao == "") ? 0 : parseFloat(strMiao) / 3600;
        var digital = strDu + strFen + strMiao;
        if (digital == 0) {
            return "";
        } else {
            return digital.toFixed(len);
        }

    }
    //度转度°分′秒″
    ToDegrees = (val, type) => {
        if (typeof (val) == "undefined" || val == "") {
            return "";
        }
        val = String(val);
        var i = val.indexOf('.');
        var strDu = i < 0 ? val : val.substring(0, i);//获取度
        var strFen = 0;
        var strMiao = 0;
        if (i > 0) {
            var strFen = "0" + val.substring(i);
            strFen = strFen * 60 + "";
            i = strFen.indexOf('.');
            if (i > 0) {
                strMiao = "0" + strFen.substring(i);
                strFen = strFen.substring(0, i);//获取分
                strMiao = strMiao * 60 + "";
                i = strMiao.indexOf('.');
                strMiao = strMiao.substring(0, i + 4);//取到小数点后面三位
                strMiao = parseFloat(strMiao).toFixed(2);//精确小数点后面两位
            }
        }
        let result = '';
        switch (type) {
            case 'lon':
                result = strDu + "°" + strFen + "′" + strMiao + '″E';
                break;
            case 'lat':
                result = strDu + "°" + strFen + "′" + strMiao + '″N';
                break;

            default:
                break;
        }
        return result;
    }
    render() {
        let { dictData, ulshow, shipTypeArr, ullAll, selectedRowKeys, eventTypeArr, stateList1, visible, loading, startTime, endTime, pagination } = this.state;
        let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.object_name))
        console.log(ullAll, '999')
        let ullAllss = [...new Set(ullAlls)];
        const columns = [
            {
                title: '船名',
                dataIndex: 'object_name',
                key: 'object_name',
            },
            {
                title: '标签',
                dataIndex: 'device_id',
                key: 'device_id',
            },
            {
                title: '船舶属地',
                dataIndex: 'region_name',
                key: 'region_name',
            },
            {
                title: '经度',
                dataIndex: 'lon',
                key: 'lon',
                render: (text, record) => {
                    text = this.ToDegrees(text, 'lon')
                    return (
                        <span
                        // style={ {
                        //     color: color,
                        //     fontFamily: 'SourceHanSansCN-Regular',
                        //     fontSize: 12
                        //     // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                        // } }
                        // draggable="true"
                        // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
                        >{ text }</span>
                    )
                }
            },
            {
                title: '纬度',
                dataIndex: 'lat',
                key: 'lat',
                render: (text, record) => {
                    text = this.ToDegrees(text, 'lat')
                    return (
                        <span
                        // style={ {
                        //     color: color,
                        //     fontFamily: 'SourceHanSansCN-Regular',
                        //     fontSize: 12
                        //     // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                        // } }
                        // draggable="true"
                        // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
                        >{ text }</span>
                    )
                }
            },
            {
                title: '定位时间',
                dataIndex: 'location_time',
                key: 'location_time',
                sorter: (a, b) => {
                    if (a.location_time > b.location_time) {
                        return 1;
                    } else if (a.location_time < b.location_time) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
        ]
        return (
            <div className='contentBox1' >
                <div style={ { display: 'flex' } }>

                    <Input addonBefore="超时时间(小时)：" style={ { width: 387 } } value={ this.state.name }
                        // onSearch={value => this.timeoutAlarmStatistics(value, 'select')} 
                        onChange={ value => this.controlList(value) } placeholder="24" />
                    <Button style={ { marginLeft: 40 } } type="primary"
                        onClick={ () => this.timeoutAlarmStatistics() }
                    >查询</Button>
                    <Button style={ { marginLeft: 1000 } } type="primary" onClick={ (e) => this.exportDefaultExcel(e, columns) }>导出</Button>
                </div>
                {/* {
              ulshow && <ul className="searchList">
                {
                  ullAllss && ullAllss.map((item, index) => (
                    <li
                      onClick={this.clickSearchDatavalue.bind(this, item)}
                      style={{
                        cursor: 'pointer',
                        fontSize: 16,
                        display: 'flex',
                        alignItems: "center",
                      }}
                    ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item
                    }</span>
                    </li>
                  ))
                }
              </ul>
            } */}
                <Table
                    style={ { marginTop: 50 } }
                    columns={ columns }
                    pagination={ pagination }
                    onChange={ this.handleTableChange }
                    loading={ this.state.loading }
                    dataSource={ this.state.dataSource }

                ></Table>
            </div>
        )
    }
}
