import React, { Component } from 'react'
import './searchTop.css'
import { Input, Button } from 'antd';
const { Search } = Input;
export default class SearchTop extends Component {
    render() {
        return ( 
            <div className = "searchTop" > {
                /* <Input
                                placeholder="模糊搜索"
                                onChange={(props)=>this.props.onSearch(props.target.value)}
                                style={{ width: 200 }}
                                /> */
            } 
            <Button style = {
                { position: 'absolute', marginLeft: 1130 } }
            type = "primary"
            icon = "plus"
            onClick = {
                () => this.props.onclick() } > 新增 </Button> 
                </div>
        )
    }
}