import React, { Component } from 'react'
import './searchTop.css'
import { Input, Button, Upload, message } from 'antd';
const { Search } = Input;
export default class SeachTop extends Component {
    render() {
        let { lending, out, Systemupgrade, Alarmrelease, Alarmreset, adding, allDet, outTemplate,select } = this.props;
        return (
            <div className="searchTop">
                {select && <Search
                placeholder="模糊搜索"
                onChange={(props)=>this.props.onSearch(props.target.value)}
                style={{ width: 200 }}
                />}
                <div style={{
                    display: "flex"
                }}>
                    {Systemupgrade && <Button style={{marginLeft:930}}>系统升级</Button>}
                    {allDet && <Button style={{ margin: '0 10px' }} onClick={() => this.props.onRemove()}>批量删除</Button>}
                    {Alarmrelease && <Button style={{ margin: '10 10px' }}>报警解除</Button>}
                    {Alarmreset && <Button>报警复位</Button>}
                    {lending && <Upload {...this.props.upload}><Button style={{float:'right'}}>导入</Button></Upload>}
                    {out && <Button style={{ margin: '0 10px' }} onClick={this.props.exout} >导出</Button>}
                    {outTemplate && <Button style={{ margin: '0 10px' }} onClick={() => this.props.exoutTemplate()} >导出模板</Button>}
                    {adding && <Button type="primary" style={{margin: '0 10px' }} icon="plus" onClick={() => this.props.onclick()}>新增</Button>}
                </div>
            </div>
        )
    }
}
