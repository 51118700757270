// 指挥机统计查询
import React, { Component } from 'react'
import './css/system.css'
import AddFormItem from './components/addFormItem/AddFormItem'
import {Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload, Menu, Dropdown} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
import {
    getArgs,
    selectCmdReport,
    getCmdReportDetail,
} from '../../data/dataStore'
import $ from "jquery";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
//今天0点
const piker1 = moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss');
//今天23点59
const piker2 = moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss');
class CmdReportMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            cmdName:'',
            reportTime:'',
            //上个月今天
            startTime: moment(piker1).format('YYYY-MM-DD HH:mm:ss'),
            //今天的年月日
            endTime: moment(piker2).format('YYYY-MM-DD HH:mm:ss'),
            dataSource: [],
            dataSourceFromToDetails: [],
            pageTotal: null,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            userData: [],
            userGroupData: [],
            loadingServiceFlag: false,
            trajectoryDateList: [
                //本月
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
            ],//上报时间
        }
        this.select = this.select.bind(this)
    }
    componentDidMount() {
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }

    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.select()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.select(this.state.name, 'select')
            }
            )
        }
    }
    // 分页查询
    async select(value, type) {
        this.setState({ loading: true })
        let {startTime,endTime,pagination } = this.state;
        let params = {
            cmdName: this.state.cmdName,
            //增加开始时间与结束时间参数
            startTime,
            endTime,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
        }
        let data = await selectCmdReport(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UallArr: data.rows,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                    this.setState({
                        ulshow: false,
                        dataSource: data.rows,
                        pageTotal: data.total,
                        loading: false
                    })
                }
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }

    //查询详情
    async selectCmdDetail(data){
        //根据ID去查
        let params = {
            cmdId:data.cmdId,
        }
        console.log(">>>>>>>>>>selectCmdDetail",JSON.stringify(data.cmdId) )
        let data1 = await getCmdReportDetail(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data1 && data1.code == 200) {
            let selectedKeys = []
                this.setState({
                        historyShow: true,
                        historyData: data1,
                        selectedKeys,
                        dataSourceFromToDetails:data1.rows
                    }
                )
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            historyShow: false,
        })
    }
    //分页筛选
    async getArgs(value) {
        let params = {

        }
        let data = await getArgs(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.rows,
            })

        } else {
        }
    }

    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.select()
        });
    }
    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    render() {
        let { dataSource,dataSourceFromToDetails,pageTotal } = this.state;
        //列表字段
        const columns = [
            {
                title: '指挥机名',
                key: 'cmdName',
                dataIndex: 'cmdName',
            },
            {
                title: '卡数',
                key: 'cardNums',
                dataIndex: 'cardNums',
            },
            {
                title: '发送总数',
                key: 'sendCount',
                dataIndex: 'sendCount',
            },
            {
                title: '发送失败数',
                key: 'failCount',
                dataIndex: 'failCount',
            },
            {
                title: '统计时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
            },
            
            {
                title: '操作',
                key: 'detele',
                width: 300,
                render: (text, record) => {
                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.selectCmdDetail(record)}>详细信息</span>
                    </div>
                },
            },
        ]
        //详情
        const columns2 = [
            {
                title: '卡号',
                key: 'cardNumber',
                dataIndex: 'cardNumber',
            },
            {
                title: '卡数',
                key: 'cardNums',
                dataIndex: 'cardNums',
            },
            {
                title: '指挥机id',
                key: 'cmdId',
                dataIndex: 'cmdId',
            },
            {
                title: '指挥机名称',
                key: 'cmdName',
                dataIndex: 'cmdName',
            },
            {
                title: '发送失败数',
                key: 'failCount',
                dataIndex: 'failCount',
            },
            {
                title: '统计时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
            },
            {
                title: '发送总数',
                key: 'sendCount',
                dataIndex: 'sendCount',
            },
        ]
        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">指挥机统计</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                指挥机：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cmdName' value={this.state.cmdName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.select()}
                            >查询</Button>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cmdName: '',
                                        }, () => {
                                            //
                                        })
                                    }
                                }

                            >重置</Button>

                        </div>
                    </div>

                </div>

                <div className="cardManagement">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{y:515}}
                        rowKey={(record)=>record.cmdName}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.select(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <Modal
                    visible={this.state.historyShow}
                    onCancel={this.closeModal}
                    title="详情信息"
                    // footer={false}
                    width={1000}
                    className="DetailsModal"
                    onOk={this.closeModal}>
                    <Table
                        columns={columns2}
                        //默认是复选框
                        //rowSelection={distribute_rowSelect}
                        //去掉复选框  onRowClick={this.onRowClick}
                        dataSource={dataSourceFromToDetails}
                    />
                </Modal>
            </div>
        )
    }
}
export default CmdReportMonitoring