import React, { Component } from 'react'
import './trackQuery.css'
// import { createSTMap, createSTLayer } from '../../st/geo/geofunc';
import { CloseCircleFilled } from '@ant-design/icons';
import { exportExcel } from 'xlsx-oc';
import {
    findObjTrackMulti,
    selectRangeQuery,
    searchByPolygonAndCircle_trajectory,
    searchByPolygonAndCircle_shipInfo,
    allshipPos,
    specialRegionStatistics,
    homePortMonitor,
    async_areaList,
    getLayerInfo,
} from '../../data/dataStore'
// import TrackingController, { HaveUBIDataProvider } from '../../st/tracking/TrackingLayer';
import { Table, DatePicker, Input, TreeSelect, Tree, Select, Icon, Slider, Switch, Button, Timeline, message, Form, Spin, Tooltip, Radio, Modal, Row, Col } from 'antd';
import moment from 'moment';
// import TrackingMap from '../../st/trackingMap/trackingMap'
import rectangle from '../../assets/img/rectangle.png'
import ellipse from '../../assets/img/ellipse.png'
import polygon from '../../assets/img/polygon.png'
import MyComponentFactory from './Toolkit/MyComponentFactoryPackage/MyComponentFactory';
import ComponentWorkspace from './Toolkit/MyComponentFactoryPackage/ComponentWorkspace';
const { TreeNode, DirectoryTree } = Tree;
const { Option } = Select;
const { RangePicker } = DatePicker;
// const { SHOW_PARENT } = TreeSelect;
const maths = {
    'mapSwitch': "",
    'mapSwitchLight': "",
    'tool': "",
    'toolLight': "",
    'ellipse': "",
    'rectangle': "",
    'range': "",
    'polygon': "",
    'area': "",
    'play': "",
    'suspend': "",
}
Object.keys(maths).forEach(item => maths[item] = require("../../assets/img/" + item + ".png"))
// let newArr=[]
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

const dayList1 = year + '-' + month + '-' + day + ' ' + '00:00:00';
const dayList = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
let colors = [
    '#0066CC',
    '#FF7F00',
    '#42C9D3',
    '#660066',
    '#009900',
    '#F9E103',
];
class TrackQuerySelectArea extends ComponentWorkspace {
    constructor(props) {
        super(props)
        this.state = {
            disabled_RangePicker: false,//搜索结果后禁用时间组件，重置后开启
            visible_div_table_shipInfo: false,
            dataSource_div_Table_shipInfo: [],
            value_RadioGroup_PolygonSelect: '',
            text_Measure_DropdownButton: '测量工具',
            text_Measure_DropdownButton_search: '搜索工具',
            visible: true,
            mapArr: [],
            name: '',
            toolShow: false,
            switchShow: false,
            pointShow: false,
            portShow: false,
            locusShow: false,
            areaShow: false,
            toolArr: [
                // { img: maths.port, act: maths.portLight, name: '停靠点监控', click: false, key: 'port' },
                // { img: maths.point, act: maths.pointLight, name: '重点区域管理', click: false, key: 'point' },
                { img: maths.mapSwitch, act: maths.mapSwitchLight, name: '地图开关', click: false, key: 'mapSwitch' },
                { img: maths.tool, act: maths.toolLight, name: '工具箱', click: false, key: 'tool' },
            ],
            switchArr: [
                { title: '地图信息' },
                // { name: '卫星遥感图', key: 'weixing', optionArr: [{ name: '遥感图-2017', key: 2017 }, { name: '遥感图-2018', key: 2018 }, { name: '遥感图-2019', key: 2019 }] },
                {
                    name: '影像底图', key: 'menu', defaultChecked: false,
                    optionArr: [
                        { name: '遥感影像底图', key: 'default_WMTS_tdRemoteSensing', target: 'default_WMTS_tdRemoteSensing', defaultChecked: false }
                    ]
                },
                // { name: '航拍图', key: 'hangpai', optionArr: [{ name: '航拍图-2017', key: 'hp2017' }, { name: '航拍图-2018', key: 'hp2018' }, { name: '航拍图-2019', key: 'hp2019' },] },
                // { title: '图层信息' },
                // { name: '船舶图层', key: 'chuanbo', defaultChecked: true },
                // { name: '摄像头图层', key: 'layers_queryAllCamera', target: 'layers_queryAllCamera', defaultChecked: true },
                { name: '停靠点图层', key: 'target_mapLayer_Polygon_gangkou', defaultChecked: false },
                { name: '重点区域图层', key: 'target_mapLayer_Polygon_pointArea', defaultChecked: false },
                { name: '电子围栏图层', key: 'target_mapLayer_Polygon_dianzi', defaultChecked: false },
                // { name: '停靠点图层', key: 'target_mapLayer_Polygon_gangkou', optionArr: [{ name: '全选7个', key: '' }, { name: '第一个', key: '1' }, { name: '第2个', key: '2' },] },
                // { name: '重点区域图层', key: 'target_mapLayer_Polygon_pointArea', optionArr: [{ name: '全部停靠点', key: '' }, { name: '吴中港', key: 'wz' }, { name: '虎口', key: 'hk' },] },
                // { name: '电子围栏图层', key: 'target_mapLayer_Polygon_dianzi', optionArr: [{ name: '重点区域', key: '' }, { name: '不知道', key: 'dont' }, { name: '无所谓', key: 'noway' },] },
            ],
            dynamic: true,//控制船舶动态
            player: true,
            playing: false,
            fast: false,
            back: false,
            inputvalue: 0,//控制进度条的进度
            maxnum: 100,//滑动条的最大数字
            autoTime: 1000,//控制播放条的快进快退
            viewSearchValue: "",//搜索框的value
            // startTime: dayList1,//默认开始时间是今天零点
            // endTime: dayList,//默认开始时间是今天23点
            startTime: moment().format('YYYY-MM-DD') + " 00:00:00",//默认开始时间是今天零点
            endTime: moment().format('YYYY-MM-DD') + " 23:59:59",//默认开始时间是今天23点
            value_DatePicker: [moment((moment().format('YYYY-MM-DD') + " 00:00:00"), 'YYYY-MM-DD HH:mm:ss'), moment((moment().format('YYYY-MM-DD') + " 23:59:59"), 'YYYY-MM-DD HH:mm:ss')],
            loadingStatus: {
                'selectRangeQuery': false,
                'findObjTrackMulti': false,
            },
            value_Select_shipDynamic: { key: '', label: '' },
            doubleSpeedNum: 1,
            backgroundColors_shipDynamic: [
                '#0066CC',
                'white',
                'white',
                'white',
                'white',
                'white',
            ],
            colors_shipDynamic: [
                'white',
                '#FF7F00',
                '#42C9D3',
                '#660066',
                '#009900',
                '#F9E103',
            ],
            visible_Tooltip_searchList: false,
            text_Tooltip_searchList: '查询船舶轨迹',
            cardNumTypeData: {},
            cardNumData: {},
            value_TreeSelect_shipName: this.props.default_shipData ? [this.props.default_shipData.objectId] : [],
        }
        this.noNeedRemoveList=[];//不需要清除的图层名
    }

    addLoadingStatus = (key) => {
        let loadingStatus = this.state.loadingStatus;
        loadingStatus = {
            ...loadingStatus,
            [key]: true,
        }
        this.setState({
            loadingStatus: loadingStatus
        })
    }

    closeLoadingStatus = (key) => {
        let loadingStatus = {
            ...this.state.loadingStatus,
            [key]: false,
        }
        this.setState({
            loadingStatus: loadingStatus
        })
    }

    componentDidMount() {
        this.getLayerInfo();
        this.specialRegionStatistics();
        this.homePortMonitor();
        this.async_areaList({ 'areaType': 300002 })
        let treeData = []
        if (this.props.treeData) {
            treeData = this.handleTreeData(this.props.treeData)
        }
        let cardNumTypeData = this.getDictData('card_num_type');
        let cardNumData = cardNumTypeData;
        let value_TreeSelect_shipName = this.state.value_TreeSelect_shipName;
        // this.allshipPos();
        // let obj = this;
        // this.interval_allshipPos = setInterval(function () {
        //     obj.allshipPos();
        // }, 180000);
        this.setState({
            treeData,
            cardNumTypeData,
            cardNumData,
        }, () => {
            if (this.props.default_shipData && Object.keys(this.props.default_shipData).length > 0) {
                this.changeSelect([this.props.default_shipData.objectId], [this.props.default_shipData.shipName]);
            }

        })
        console.log(treeData, 'treeData')
    }
    componentWillUnmount() {
        clearInterval(this.interval_allshipPos);
    }

    exportDefaultExcel = (data, coloums) => {
        if(data){
            data.map((item)=>{
                if(item.driveTimes){
                    item.driveTimes = parseFloat(item.driveTimes / 60).toFixed(2);
                }
            })
        }
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (data && data.length > 0) {
            exportExcel(headers, data, '信息列表.xlsx');
        } else {
            message.error('导出数据失败！！！');
        }
    }

    //根据图层数据接口的数据，重新装配地图配置文件
    assembling_map_defaultJSON = (data) => {
        let layers = {}
        if (this.state.config_JSON) {
            let attribute = config_JSON.map_componentConfig.attribute;
            layers = attribute.layers;
        }
        if (typeof (layers) == 'undefined' && data.length > 0) {
            layers = {
                'wms': []
            };
        }
        else if (typeof (layers.wms) == 'undefined') {
            layers = {
                ...layers,
                'wms': []
            };
        }
        data.map((item, index) => {
            item.children.map((itemC, indexC) => {
                layers.wms.push({
                    "optionalParams": {
                        "visible": false,
                        "zIndex": "13"
                    },
                    "target": itemC.target,
                    "layerName": itemC.layerName,
                    "url": itemC.layerUrl
                })
            })
        });
        this.state.componentRepository.map.addWMSLayer(layers);
    }

    //根据图层数据接口的数据，重新装配图层数组switchArr
    assembling_layerInfoArr = (data) => {
        let switchArr = this.state.switchArr;
        data.map((item, index) => {
            let layerMenu = {
                'name': item.name,
                'key': item.unique,
                'defaultChecked': false,
                'optionArr': [],
            }
            if (item.children && item.children.length > 0) {
                item.children.map((itemC, indexC) => {
                    let layer = {
                        'name': itemC.layerTitle,
                        'key': itemC.target,
                        'target': itemC.target,
                        'defaultChecked': false,
                    }
                    layerMenu.optionArr.push(layer);
                })
            }
            switchArr.splice(1, 0, layerMenu);
        })
        if (switchArr.length != this.state.switchArr.length) {
            this.setState({
                switchArr
            })
        }
    }
    // 图层资源（遥感和航拍）
    async getLayerInfo() {
        let params = {
        }
        let data = await getLayerInfo(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                data_layersInfo: data.data,
            }, () => {
                this.assembling_map_defaultJSON(this.state.data_layersInfo);
                this.assembling_layerInfoArr(this.state.data_layersInfo);
            })
        } else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }

    onChange_areaList = (data) => {
        let type = data.target.value;
        this.setState({
            value_RadioGroup_PolygonSelect: type,
        })
        let noNeedRemoveList=[
            ...this.noNeedRemoveList,
            ...['vectorImg']
        ]
        this.state.componentRepository.map.clearMap(noNeedRemoveList, true);//第二个参数为true则清理地图后不进行zoom重置
        this.state.componentRepository.map.draw_getAreaPointData(type);
    }

    // 重点区域管理
    async specialRegionStatistics() {
        let params = {
            startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
            endTime: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        let data = await specialRegionStatistics(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let children = [];
            data.data.children.map((item, index) => {
                if (item.geom) {
                    item.geom = JSON.parse(item.geom);
                    item.name = item.areaName;
                    children = [
                        ...children,
                        item,
                    ]
                }
            })
            data.data.children = children;

            this.setState({
                pointData: data.data.children
            }, () => {
                let params = {
                    target: 'target_mapLayer_Polygon_pointArea',
                    zIndex: 11,
                    color: 'rgba(255,127,0,0.5)',
                    visible: false,
                    isChangeZoomAndCenter: false,
                }
                this.noNeedRemoveList=[
                    ...this.noNeedRemoveList,
                    params.target
                ]
                this.state.componentRepository.map.showMap_DBData(this.state.pointData, params);
            })
        } else if (data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    // 停靠点管理
    async homePortMonitor() {
        let params = {
            startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
            endTime: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        let data = await homePortMonitor(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let children = [];
            data.data.children.map((item, index) => {
                if (item.geom) {
                    item.geom = JSON.parse(item.geom);
                    item.name = item.areaName;
                    children = [
                        ...children,
                        item,
                    ]
                }
            })
            data.data.children = children;
            this.setState({
                portData: data.data.children
            }, () => {
                let params = {
                    target: 'target_mapLayer_Polygon_gangkou',
                    zIndex: 11,
                    color: 'rgba(0,153,0,0.5)',
                    visible: false,
                    isChangeZoomAndCenter: false,
                }
                this.noNeedRemoveList=[
                    ...this.noNeedRemoveList,
                    params.target
                ]
                this.state.componentRepository.map.showMap_DBData(this.state.portData, params);
            })
        } else if (data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    // 区域查询 （根据区域类型获取相应的区域 Ps：比如限定区300002）
    async async_areaList(value) {
        let params = value;
        params = {
            ...params,
            startTime: moment().format('YYYY-MM-DD') + " 00:00:00",
            endTime: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        let data = await async_areaList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            data.data.map((item, index) => {
                item.geom = JSON.parse(item.geom);
                item.name = item.areaName;
            })
            this.setState({
                limitAreaData: data.data
            }, () => {
                let params = {
                    target: 'target_mapLayer_Polygon_dianzi',
                    zIndex: 11,
                    color: 'rgba(249,225,3,0.5)',
                    visible: false,
                    isChangeZoomAndCenter: false,
                }
                this.noNeedRemoveList=[
                    ...this.noNeedRemoveList,
                    params.target
                ]
                this.state.componentRepository.map.showMap_DBData(this.state.limitAreaData, params);
            })
        } else if (data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    // 获取所有船的位置
    async allshipPos() {

        let data = await allshipPos()
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            data.data.forEach((item) => {
                let location = {}
                location.lon = item.lon;
                location.lat = item.lat;
                item.location = location;
                item.oid = item.id;
                item.isClickPoint = true;
            })

            this.setState({
                allship: data.data
            }, () => {
                // this.state.componentRepository.map.clearAllListener();
                if (this.state.shipData
                    && this.state.shipData.lbsPositionList
                    && this.state.shipData.lbsPositionList[0]
                    && this.state.shipData.lbsPositionList[0].lon != null
                    && this.state.shipData.lbsPositionList[0].lat != null) {
                    // this.state.componentRepository.map.click_addPointAnimation(this.state.shipData.lbsPositionList[0]);
                    let selectItem = false;
                    for (let index = 0, len = this.state.allship.length; index < len; index++) {
                        const item = this.state.allship[index];
                        if (item.deviceId == this.state.shipData.lbsPositionList[0].deviceId) {
                            item.isSelect = true;
                            selectItem = item;
                            this.state.allship.splice(index, 1);
                            index--;
                            len--;
                        }
                    }
                    if (selectItem) {
                        this.state.allship = [
                            ...this.state.allship,
                            selectItem,
                        ]
                    }
                }
                console.log(this.state.allship, 'ddd');
                this.state.componentRepository.map.showMap_addPoints(this.state.allship, {
                    target: 'vectorImg',
                    zIndex: 111
                });
            })
        } else if (data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    // 工具条的控制
    clickTool = (index, key) => {
        let arr = this.state.toolArr;

        switch (key) {
            case 'locus':
                this.setState({
                    locusShow: !this.state.locusShow,
                    toolShow: false,
                    switchShow: false,
                    pointShow: false,
                    portShow: false,
                    default_shipData: undefined
                }, () => {
                    if (!this.state.locusShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'locus_detail':
                this.setState({
                    locusShow: !this.state.locusShow,
                    toolShow: false,
                    switchShow: false,
                    pointShow: false,
                    portShow: false,
                    default_shipData: this.state.shipData
                }, () => {
                    if (!this.state.locusShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'port':
                this.setState({
                    portShow: !this.state.portShow,
                    toolShow: false,
                    switchShow: false,
                    pointShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.portShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'point':
                this.setState({
                    pointShow: !this.state.pointShow,
                    toolShow: false,
                    switchShow: false,
                    portShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.pointShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'mapSwitch':
                this.setState({
                    switchShow: !this.state.switchShow,
                    toolShow: false,
                    pointShow: false,
                    portShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.switchShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
            case 'tool':
                this.setState({
                    toolShow: !this.state.toolShow,
                    switchShow: false,
                    pointShow: false,
                    portShow: false,
                    locusShow: false,
                }, () => {
                    if (!this.state.toolShow) {
                        for (let i in arr) {
                            arr[i].click = false;
                        }
                        this.setState({
                            toolArr: arr
                        })
                    }
                })
                    ; break;
        }
        for (let i in arr) {
            arr[i].click = false;
            arr[index].click = true
        }
        this.setState({
            toolArr: arr
        })
    }
    showDrawer = () => {
        this.setState({
            visible: !this.state.visible
        })
    }
    haveTime = (a, value) => {
        let startTime = value[0];
        let endTime = value[1];

        // let splitData_start = value[0].split(' ');
        // let splitData_end = value[1].split(' ');
        // let startHMS = splitData_start[1];
        // let endHMS = splitData_end[1];
        // startTime = splitData_start[0] + " 00:00:00";
        // endTime = splitData_end[0] + " 23:59:59";
        // if (startHMS == endHMS) {
        //     startTime = splitData_start[0] + " 00:00:00";
        //     endTime = (splitData_end[0] == (moment().format('YYYY-MM-DD'))) ? splitData_end[0] + " " + moment().format('HH:mm:ss') : splitData_end[0] + " 23:59:59";
        // }
        let value_DatePicker = [moment(startTime), moment(endTime)];
        this.setState({
            startTime,
            endTime,
            value_DatePicker,
        })
    }
    showPlayer = () => {
        this.setState({
            player: !this.state.player
        })
    }
    playSlider = (type) => {
        let { strs, trackLine } = this.state;
        if (Object.keys(this.state.haveDataList) && Object.keys(this.state.haveDataList).length > 0 && Object.keys(trackLine) && Object.keys(trackLine).length > 0) {
            console.log(this.state.autoTime, 'autoTime')
            switch (type) {
                case 1:
                    this.setState({
                        back: true,
                        fast: false,
                        autoTime: Math.floor(this.state.autoTime + 200)
                    }, () => {

                    })
                        ; break;
                case 2:
                    if (this.state.playing) {
                        clearInterval(this.auto)
                        this.setState({
                            playing: !this.state.playing,
                            back: false,
                            fast: false,
                        })
                        this.state.componentRepository.map.stop_trajectory_points();
                    } else {
                        this.auto = setInterval(() => {
                            let num = this.state.inputvalue + 1;
                            this.setState({
                                //time: timeArr[num],//根据经度条的点获取相对应下标的时间
                                inputvalue: num
                            }, () => {
                                // this.changeData(this.state.time)
                                if (this.state.inputvalue >= this.state.maxnum) {
                                    this.setState({
                                        inputvalue: this.state.maxnum
                                    })
                                    clearInterval(this.auto)
                                }
                                // console.log(this.state.inputvalue, this.state.maxnum, 'auto')
                            })
                        }, this.state.autoTime)
                        // this.child.clickPlay()
                        console.log(this.state.trackLine, '------------------trackLine-----------')
                        this.state.componentRepository.map.start_trajectory_points();
                        this.setState({
                            playing: !this.state.playing,
                            back: false,
                            fast: false,
                            isRunning_trajectory: true,
                        }, () => {

                        })
                    }
                    ; break;
                case 3:
                    if (this.state.autoTime > 0) {
                        this.setState({
                            fast: true,
                            back: false,
                            autoTime: Math.floor(this.state.autoTime - 200)
                        })
                    }
                    ; break;
            }
        } else {
            message.error('至少查询一条船舶信息 或者 当前查询船舶没有信息！！！')
        }
    }
    changeValue = (value) => {
        console.log(value, 'value')
        // const { allSiteData } = this.state
        // let timeArr = allSiteData.timeAxis
        this.setState({
            // time: timeArr[value],
            inputvalue: value
        }, () => {
            // this.changeData(this.state.time)
        })
    }
    //处理树选择框的数据
    handleTreeData = (treeData) => {
        if (treeData) {

            let newArr = []
            treeData.map((item) => {
                let obj = {
                    id: item.unique,
                    key: item.unique,
                    // selectable: item.objectName ? true : false,
                    disableCheckbox: item.objectName ? false : true,
                    title: item.name ? item.name : item.aliasName,
                    value: item.id ? item.id : item.unique,
                    objectName: item.objectName,
                    // value: {
                    //     objectId:item.id ? item.id : item.unique,
                    //     cardNumType:item.cardNumType,
                    // }
                }
                let keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        if (keyArr[j] != 'deviceVOList') {
                            let child = this.handleTreeData(item[keyArr[j]])
                            obj.children = child
                            obj.selectable = false
                        } else {
                            obj.deviceVOList = item[keyArr[j]];
                        }
                    }
                }
                newArr.push(obj)
            })
            return newArr
        }

    }
    qureHandleSearch = (e) => {
        // this.setState({
        //     viewSearchValue:e.target.value
        // })
    }
    forbiddenSelectForTreeData = (data, strs) => {
        if (data) {
            let newArr = []
            data.map((item) => {
                let obj = {
                    ...item,
                }
                let isSame = false;
                strs.map((itemS, index) => {
                    if (itemS.node.id == obj.id) {
                        isSame = true;
                    }
                })
                if (obj.objectName && strs.length < 6) {
                    isSame = true;
                }
                obj.disableCheckbox = obj.objectName && isSame ? false : true;
                let keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        if (keyArr[j] != 'deviceVOList') {
                            let child = this.forbiddenSelectForTreeData(item[keyArr[j]], strs)
                            obj.children = child
                            obj.selectable = false
                        } else {
                            obj.deviceVOList = item[keyArr[j]];
                        }
                    }
                }
                newArr.push(obj)
            })
            return newArr
        }
    }
    //获取树选择选中的所有value值，包含deviceId-deviceType
    changeSelect = (value, text, extra) => {
        console.log(value, text, extra, '------value,text,strs--------------changeSelect-------------------')
        let selectArr = [];
        let strs = [];
        for (let i = 0; i < value.length; i++) {
            let paramsStrs = {};
            let paramsSelectArr = {};
            if (value && value[i]) {
                paramsSelectArr = {
                    ...paramsSelectArr,
                    value: value[i],

                }
                paramsStrs = {
                    ...paramsStrs,
                    value: value[i],

                }
            }
            if (text && text[i]) {
                paramsSelectArr = {
                    ...paramsSelectArr,
                    text: text[i],

                }
                paramsStrs = {
                    ...paramsStrs,
                    text: text[i]
                }
            }
            if (extra && extra.allCheckedNodes && extra.allCheckedNodes[i]) {
                let node = extra.allCheckedNodes[i].node ? extra.allCheckedNodes[i].node.props : undefined;
                paramsStrs = {
                    ...paramsStrs,
                    node: node,
                }
            }
            selectArr = [
                ...selectArr,
                paramsSelectArr,
            ]
            strs = [
                ...strs,
                paramsStrs,
            ]
        }
        let treeData = this.state.treeData;
        if (value && text && extra) {
            treeData = this.forbiddenSelectForTreeData(treeData, strs);
        }
        // let strs;
        // value.forEach((val) => {
        //     strs = val.split("-");
        // })
        this.setState({
            strs,
            selectArr,
            value_Select_shipDynamic: { key: value[0], label: text[0] },
            treeData: treeData,
            value_TreeSelect_shipName: value,
        })
    }
    searchList = () => {
        this.findObjTrackMulti()
    }
    resetMap = () => {
        this.props.form.resetFields();
        let startTime = moment().format('YYYY-MM-DD') + " 00:00:00"
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            startTime,
            endTime,
            // value_DatePicker: [startTime, endTime],//时间重置
            strs: undefined,//选中船舶信息重置
            trackLine: undefined,//选中船舶后后台接口查询出来的信息
            playing: false,//播放按钮重置
            isRunning_trajectory: false,
            doubleSpeedNum: 1,
            value_RadioGroup_PolygonSelect: '',
            disabled_RangePicker: false,
        }, () => {
            this.state.componentRepository.map.stop_trajectory_points();
            this.state.componentRepository.map.clear_draw_getAreaPointData();
            let noNeedRemoveList=[
                ...this.noNeedRemoveList,
                ...['vector_draw_search','vector_draw']
            ]
            this.state.componentRepository.map.clearMap(noNeedRemoveList);
            // this.allshipPos();
            // this.state.componentRepository.map.clearMap(this.state.targetList);
        })
    }
    stop_trajectory_points = () => {
        this.setState({
            playing: false,//播放按钮重置
        }, () => {
        })
    }

    onChange_Select_shipDynamic = (data, index) => {
        console.log(data, '---data-------------onChange_Select_shipDynamic   ------------');
        let backgroundColors_shipDynamic = this.state.backgroundColors_shipDynamic;
        let colors_shipDynamic = this.state.colors_shipDynamic;
        backgroundColors_shipDynamic.map((item, indexC) => {
            if (index == indexC) {
                backgroundColors_shipDynamic[indexC] = colors[indexC];
                colors_shipDynamic[indexC] = 'white';
            } else {
                backgroundColors_shipDynamic[indexC] = 'white';
                colors_shipDynamic[indexC] = colors[indexC];
            }
        })
        this.setState({
            value_Select_shipDynamic: data,
            colors_shipDynamic: colors_shipDynamic,
            backgroundColors_shipDynamic: backgroundColors_shipDynamic,
        })
        let startTime = moment().format('YYYY-MM-DD') + " 00:00:00"
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (this.state.startTime) {
            startTime = this.state.startTime;
        }
        if (this.state.endTime) {
            endTime = this.state.endTime;
        }
        let params = {
            objectId: data.value,
            startTime: startTime,
            endTime: endTime,
        }
        this.selectRangeQuery(params);
    }
    /**
     *@Description:{ 禅道：4449
                        航程统计查询 }
     *@InterfaceAddress:{/addressBook/selectCommunicationList }
     *@parameter: { 
                        search:(模糊搜索)
                   }
     *@ClassAuthor: ZDong
     *@Date: 2020-03-09 16:02:05
    */
    async selectRangeQuery(value) {
        this.addLoadingStatus('selectRangeQuery');
        let params = {};
        if (value) {
            params = value;
        }
        let data = await selectRangeQuery(params)
            .then(res => { return res.json() }).catch(ex => { });
        if (data.code === 200) {
            data.data.dynamicStateList.map((item, index) => {
                switch (item.eventType) {
                    case 302261:
                        item.eventTypeName = '违规驶入'
                        break;
                    case 302262:
                        item.eventTypeName = '驶出停靠点'
                        break;
                    case 302263:
                        item.eventTypeName = '驶入停靠点'
                        break;
                    case 302264:
                        item.eventTypeName = '拆卸报警'
                        break;
                    case 302265:
                        item.eventTypeName = '低电量报警'
                        break;
                    case 302266:
                        item.eventTypeName = '倾斜报警'
                        break;
                }
            })
            this.setState({
                shipVoyageInfoData: data.data,
            }, () => {
                console.log(this.state.shipVoyageInfoData, '-----------selectRangeQuery---------');
                this.closeLoadingStatus('selectRangeQuery');
            });
        } else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }

    async findObjTrackMulti() {
        let cardNumData = this.state.cardNumData;
        if (cardNumData && Object.keys(cardNumData).length > 0) {
        } else {
            cardNumData = this.state.cardNumTypeData;
        }
        let { startTime, endTime, strs } = this.state;
        if (strs && strs.length > 0 && strs.length < 7) {
            this.addLoadingStatus('findObjTrackMulti');
            let objectInfos = [];
            strs.map((item, index) => {
                let deviceInfos = [];
                let deviceVOList = item.node && item.node.deviceVOList ? item.node.deviceVOList : [];
                deviceVOList.map((itemD, indexD) => {
                    if (cardNumData[itemD.cardNumType]) {
                        deviceInfos = [
                            ...deviceInfos,
                            {
                                deviceId: itemD.deviceId,
                                cardNumType: itemD.cardNumType,
                            }
                        ]
                    }
                })
                objectInfos = [
                    ...objectInfos,
                    {
                        objectId: item.value,
                        deviceInfos: deviceInfos,
                    }
                ]
            })
            let params = {
                objectInfos,
                startTime,
                endTime,
            }
            this.selectRangeQuery(
                {
                    objectId: strs[0].value,
                    startTime,
                    endTime,
                });
            let data = await findObjTrackMulti(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => { });
            if (data && data.code == 200) {
                let features = []
                // let trackLine={
                //     type:"FeatureCollection",
                //     name:data.data[0].objectName,
                //     crs:{ "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                //     features:data.data
                // }
                let targetList = [];
                let haveDataList = {};
                Object.keys(data.data).map(key => {
                    let value = data.data[key];
                    if (value.length > 0) {
                        targetList = [
                            ...targetList,
                            'vectorImg_' + key
                        ];
                        haveDataList = {
                            ...haveDataList,
                            [key]: value,
                        }
                    }
                })
                this.setState({
                    trackLine: data.data,
                    targetList: targetList,
                    haveDataList: haveDataList,
                }, () => {
                    // this.child.playingTrackLine()
                    if (this.state.isRunning_trajectory) {
                        this.state.componentRepository.map.start_trajectory_points();
                    } else {
                        this.state.componentRepository.map.clearMap(this.noNeedRemoveList);
                        Object.keys(this.state.haveDataList).map(key => {
                            let value = this.state.haveDataList[key];
                            if (value.length > 0) {
                                this.state.componentRepository.map.showMap_trajectory_points(value, 'vectorImg_' + key);
                                // this.state.componentRepository.map.showMap_addPoints(value,'vectorImg_'+key);
                            }
                        })
                    }
                    console.log(data.data, '----------------------findObjTrackMulti--------------------')
                    this.closeLoadingStatus('findObjTrackMulti');
                })
            } else if (data && data.code == 505) {
                message.error(data.msg)
                if (this.props.history) {
                    this.props.history.push('/login')
                }
            }
        }
    }

    hover_Tooltip_searchList = () => {
        let strs = this.state.strs;
        this.setState({
            visible_Tooltip_searchList: false,
        }, () => {
            if (strs && strs.length > 6) {
                this.setState({
                    visible_Tooltip_searchList: true,
                    text_Tooltip_searchList: '最多选择六条船！！！'
                })
            } else if (typeof (strs) == 'undefined' || strs.length <= 0) {
                this.setState({
                    visible_Tooltip_searchList: true,
                    text_Tooltip_searchList: '至少选择一条船！！！'
                })
            }
        })
    }
    out_Tooltip_searchList = () => {
        this.setState({
            visible_Tooltip_searchList: false,
        });
    }

    playingTrack = () => {
        this.setState({

        })
    }
    showShipDynamic = () => {
        let dynamic = !this.state.dynamic;
        this.setState({
            dynamic: dynamic
        })
    }
    onRef = (ref) => {
        this.child = ref;
    }

    onClick_doubleForSpeed_trajectory = () => {
        let doubleSpeedNum = this.state.doubleSpeedNum;
        if (doubleSpeedNum == 32) {
            doubleSpeedNum = 1;
        } else {
            doubleSpeedNum = doubleSpeedNum * 2;
        }
        this.setState({
            doubleSpeedNum: doubleSpeedNum
        }, () => {
            this.state.componentRepository.map.changeTrajectorySpeed(doubleSpeedNum);
        })
    }

    onChange_cardNumType = (data) => {
        console.log(data, '---------------------onChange_cardNumType-------------------');
        let cardNumData = {};
        if (data && data.length > 0) {
            data.map((item, index) => {
                cardNumData = {
                    ...cardNumData,
                    [item.key]: item.label,
                }
            })
            this.setState({
                cardNumData: cardNumData,
            })
        }
    }

    getDictData = (type) => {
        let dictData = JSON.parse(sessionStorage.getItem('dictData'))
        let arr = {}
        dictData.forEach((item) => {
            if (item.category === type) {
                arr = {
                    ...arr,
                    [item.typeCode]: item.typeName,
                }
            }
        })
        return arr;
    }
    clickSearch = (key) => {
        let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
        if (key == 'measure') {
            if (text_Measure_DropdownButton == '取消测量') {
                this.setState({
                    text_Measure_DropdownButton: '测量工具'
                }, () => {
                    this.state.componentRepository.map.handleButtonClick_Measure();
                })
            }
        }
        let text_Measure_DropdownButton_search = this.state.text_Measure_DropdownButton_search;
        switch (key[0]) {
            case 'range':
                this.setState({
                    text_Measure_DropdownButton: '取消测量'
                }, () => {
                    this.state.componentRepository.map.handleMenuClick_Measure(key[0]);
                })
                    ; break;
            case 'area':
                this.setState({
                    text_Measure_DropdownButton: '取消测量'
                }, () => {
                    this.state.componentRepository.map.handleMenuClick_Measure(key[0]);
                })
                    ; break;
            case 'rectangle':
                this.setState({
                    areaShow: true
                })
                    ; break;

        }

    }
    // 圈选搜索
    async searchByPolygonAndCircle(value) {
        let params = value;
        let startTime = moment().format('YYYY-MM-DD') + " 00:00:00";
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (this.state.startTime && this.state.endTime) {
            startTime = this.state.startTime;
            endTime = this.state.endTime;
        }
        params = {
            ...params,
            startTime: startTime,
            endTime: endTime,
        }
        this.addLoadingStatus('searchByPolygonAndCircle');
        let data = await searchByPolygonAndCircle_trajectory(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            console.log(data.data, '--------------searchByPolygonAndCircle_trajectory-----------------')
            let targetList = [];
            let haveDataList = {};
            Object.keys(data.data.map).map(key => {
                let value = data.data.map[key];
                if (value.length > 0) {
                    targetList = [
                        ...targetList,
                        'vectorImg_' + key
                    ];
                    haveDataList = {
                        ...haveDataList,
                        [key]: value,
                    }
                }
            })
            let visible_div_table_shipInfo = this.state.visible_div_table_shipInfo;
            if (data.data.list && data.data.list.length > 0) {
                visible_div_table_shipInfo = true;
            } else {
                visible_div_table_shipInfo = false;
            }
            this.setState({
                disabled_RangePicker: true,
                trackLine: data.data.map,
                dataSource_Modal_Table_shipInfo: data.data.list,
                visible_div_table_shipInfo,
                targetList: targetList,
                haveDataList: haveDataList,
            }, () => {
                // this.child.playingTrackLine()
                this.state.componentRepository.map.closeLayerVisibleBylayerName('vectorImg');
                //     this.state.componentRepository.map.openLayerVisibleBylayerName('vectorImg');
                if (this.state.isRunning_trajectory) {
                    this.state.componentRepository.map.start_trajectory_points();
                } else {
                    this.state.componentRepository.map.clear_draw_getAreaPointData(true);
                    Object.keys(this.state.haveDataList).map(key => {
                        let value = this.state.haveDataList[key];
                        if (value.length > 0) {
                            this.state.componentRepository.map.showMap_trajectory_points(value, 'vectorImg_' + key);
                            // this.state.componentRepository.map.showMap_addPoints(value,'vectorImg_'+key);
                        }
                    })
                }
                console.log(data.data, '----------------------searchByPolygonAndCircle_trajectory--------------------')
                this.closeLoadingStatus('searchByPolygonAndCircle');
            })
        }
        else if (data && data.code == 500) {
            message.error(data.msg)
            this.closeLoadingStatus('searchByPolygonAndCircle');
            this.resetMap();
        }
        else if (data && data.code == 505) {
            message.error(data.msg)
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }

    controlLayer = (data, checked) => {

        // let { mapArr } = this.state
        let mapArr = [];

        // if (mapArr.length > 0) {
        //     var result = mapArr.some(item => {
        //         if (item.key == data.key) {
        //             return true
        //         }
        //     })
        //     console.log(result) // 如果listArr数组对象中含有id:'',就会返回true，否则返回false
        //     if (result) { // 如果存在
        //         mapArr.forEach((val) => {
        //             if (val.key === data.key) {
        //                 if (typeof (checked) === 'boolean') {
        //                     val.visible = checked
        //                 } else {
        //                     val.key = checked
        //                 }
        //             }
        //         })
        //     } else {
        //         mapArr.push({
        //             key: data.key,
        //             visible: checked,
        //             name: data.name
        //         })
        //     }
        // } else {
        mapArr.push({
            key: data.key,
            visible: checked,
            name: data.name,
            optionArr: data.optionArr,
        })
        // }

        let switchArr = this.state.switchArr;
        switchArr.map((itemSwitchArr, indexSwitchArr) => {
            if (itemSwitchArr.key == data.key) {
                if (typeof (checked) == 'boolean') {
                    itemSwitchArr.defaultChecked = checked;
                } else {
                    itemSwitchArr.defaultChecked = true;
                    itemSwitchArr.optionArr.map(itemOptionArr=>{
                        if(checked==itemOptionArr.target){
                            itemOptionArr.defaultChecked=true
                        }else{
                            itemOptionArr.defaultChecked=false
                        }
                    })
                }
            }
        })
        this.setState({
            mapArr,
            switchArr
        }, () => {
            console.log(this.state.mapArr, 'mapArr');
            this.state.mapArr.map((item, index) => {
                let key = item.key;
                let visible = item.visible;
                let optionArr = item.optionArr;

                if (typeof (optionArr) == 'undefined') {
                    if (visible) {
                        this.state.componentRepository.map.openLayerVisibleBylayerName(key);
                    } else {
                        this.state.componentRepository.map.closeLayerVisibleBylayerName(key);
                    }
                } else {
                    if (visible) {
                        if (optionArr && optionArr.length > 0) {
                            let haveTrue = false;
                            optionArr.map((item_optionArr, index_optionArr) => {
                                if (item_optionArr.defaultChecked) {
                                    this.state.componentRepository.map.openLayerVisibleBylayerName(item_optionArr.target);
                                    haveTrue = true;
                                } else {
                                    this.state.componentRepository.map.closeLayerVisibleBylayerName(item_optionArr.target);
                                }
                            })
                            if (!haveTrue) {
                                this.state.componentRepository.map.openLayerVisibleBylayerName(optionArr[0].target);
                            }
                        }
                    } else {
                        if (optionArr && optionArr.length > 0) {
                            optionArr.map((item_optionArr, index_optionArr) => {
                                this.state.componentRepository.map.closeLayerVisibleBylayerName(item_optionArr.target);
                            })
                        }
                    }
                }
            })
        })

    }

    onClick_table_shipInfo_row = (record) => {
        let trackLine = this.state.trackLine;
        let points = trackLine[record.objectId];
        // if (points && points.length > 1) {
        //     this.state.componentRepository.map.changeZoomAndCenter_points(points, 16);
        // }
        // else if (points.length == 1) {
        //     this.state.componentRepository.map.changeZoomAndCenter_point(points[0], 16);
        // }
        this.state.componentRepository.map.changeZoomAndCenter_point(points[0], 16);
        console.log(record, '------------onClick_table_shipInfo_row------');
    }

    render() {
        let loadingStatus = this.state.loadingStatus;
        let isLoading = false;
        if (Object.keys(loadingStatus).length > 0) {
            Object.keys(loadingStatus).map((key, index) => {
                let value = loadingStatus[key];
                if (value) {
                    isLoading = true;
                }
            })
        }
        const columns1 = [
            {
                title: '重点区域',
                key: 'areaName',
                dataIndex: 'areaName',
                align: "center",
                render: (text, record) => {
                    return <div>
                        <span
                        // style={{ cursor: 'pointer' }}
                        // onClick={(e) => this.onClick_Table_row_portManageTable(e, record)}
                        >{text}</span>
                    </div>
                }
            },
            {
                title: '区域当前总数',
                key: 'inCount',
                dataIndex: 'inCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#00C160' }}>{text}</span>
                }
            },
            {
                title: '区域驶离总数',
                key: 'leaveCount',
                dataIndex: 'leaveCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#F31C11' }}>{text}</span>
                }
            },
        ]
        const columns2 = [
            {
                title: '停靠点',
                key: 'areaName',
                dataIndex: 'areaName',
                align: "center",
                render: (text, record) => {
                    return <div>
                        <span
                        // style={{ cursor: 'pointer' }}
                        // onClick={(e) => this.onClick_Table_row_portManageTable(e, record)}
                        >{text}</span>
                    </div>
                }
            },
            // {
            //     title:'区域',
            //     key:'area',
            //     dataIndex:'area',
            //     align:"center",
            // },
            {
                title: '驶入',
                key: 'inCount',
                dataIndex: 'inCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#00C160' }}>{text}</span>
                }
            },
            {
                title: '驶离',
                key: 'leaveCount',
                dataIndex: 'leaveCount',
                align: "center",
                render: (text, record, index) => {
                    return <span style={{ color: '#F31C11' }}>{text}</span>
                }
            },
        ]
        const columns_Modal_table_shipInfo = [
            {
                title: '名称',
                key: 'objectName',
                dataIndex: 'objectName',
                ellipsis: true,
                // fixed: 'left',
                filterIcon: filtered => (
                    <Icon type="search" />
                ),
                render: (text, record) => {
                    let color = 'black';
                    // let value = this.getDictData('ship_type')[text];
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 12,
                                cursor: 'pointer'
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                            onClick={() => this.onClick_table_shipInfo_row(record)}
                        // draggable="true"
                        // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
                        >{text}</span>
                    )
                }

            },
            {
                title: '船主',
                key: 'owerName',
                dataIndex: 'owerName',
                ellipsis: true,
                render: (text, record) => {
                    let color = 'black';
                    // let value = this.getDictData('ship_type')[text];
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 12
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        // draggable="true"
                        // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
                        >{text}</span>
                    )
                }
            },
            {
                title: '电话',
                key: 'owerPhone',
                dataIndex: 'owerPhone',
                ellipsis: true,
                // width: 150,
                // filters: this.state.chilrenList ? this.state.chilrenList : [],
                // onFilter: (value, record) => record.num ? record.num.indexOf(value) === 0 : null,
                render: (text, record) => {
                    let color = 'black';
                    // let value = this.getDictData('ship_type')[text];
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 12
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        // draggable="true"
                        // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
                        >{text}</span>
                    )
                }
            },

            {
                title: '属地',
                key: 'regionName',
                dataIndex: 'regionName',
                ellipsis: true,
                width: 80,
                filters: this.state.chilrenList ? this.state.chilrenList : [],
                onFilter: (value, record) => record.leave ? record.leave.indexOf(value) === 0 : null,
                render: (text, record) => {
                    let color = 'black';
                    // let value = this.getDictData('ship_type')[text];
                    return (
                        <span
                            style={{
                                color: color,
                                fontFamily: 'SourceHanSansCN-Regular',
                                fontSize: 12
                                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
                            }}
                        // draggable="true"
                        // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
                        >{text}</span>
                    )
                }
            },
            {
                title: '驻留时间(小时)',
                key: 'driveTimes',
                dataIndex: 'driveTimes',
                ellipsis: true,
                sorter: (a, b) => {
                    if (a.driveTimes > b.driveTimes) {
                        return 1;
                    } else if (a.driveTimes < b.driveTimes) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
                sortDirections: ['descend', 'ascend'],
                render: (data, record) => {
                    let time = parseFloat(record.driveTimes / 60).toFixed(2);
                    return time + 'h'
                }
            },
        ]
        let { pointData, portData } = this.state;
        const { shipVoyageInfoData, toolArr, back, playing, fast, player, toolShow, switchShow, pointShow, portShow, maxnum, inputvalue, visible, switchArr, treeData, viewSearchValue, dynamic, selectArr, trackLine } = this.state;
        const { getFieldDecorator } = this.props.form;
        let value_TreeSelect_shipName = this.state.value_TreeSelect_shipName;
        return (
            <div className="trackQuery"
                style={{
                    position: 'relative'
                }}
            >
                {/* <TrackingMap trackLine={trackLine} playing={playing} onRef={this.onRef}/> */}
                {
                    this.state.visible_div_table_shipInfo &&
                    <div
                        className="className_div_ShipInfo"
                        style={{
                            top: '24vh',
                            left: '2%',
                            height: '62vh',
                            padding: '20px 20px',
                            width: "30vw",
                            position: 'absolute',
                            zIndex: 2,
                            backgroundColor: 'white'
                        }}
                    >
                        <Row
                        >
                            <Col
                                span={8}
                            >
                                <h3>框选区域（{this.state.dataSource_Modal_Table_shipInfo.length}）</h3>
                            </Col>
                            <Col
                                span={1}
                                offset={14}
                                onClick={() => { this.setState({ visible_div_table_shipInfo: false }) }}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                <CloseCircleFilled
                                    style={{
                                        fontSize: 30
                                    }}
                                />
                            </Col>
                        </Row>
                        <div
                            style={{
                                border: '1px solid #C0C0C0',
                                height: '85%',
                                marginTop: 20
                            }}
                        >
                            <Table
                                columns={columns_Modal_table_shipInfo}
                                dataSource={this.state.dataSource_Modal_Table_shipInfo}
                                style={{ height: '80%' }}
                                // rowSelection={rowSelection}
                                size="middle"
                                pagination={false}
                                locale={{
                                    filterConfirm: '确定',
                                    filterReset: '重置',
                                    emptyText: '暂无数据',
                                }}
                                // showHeader={ false }
                                scroll={{
                                    y: '430px',
                                    x: '100%',
                                    scrollToFirstRowOnChange: true,
                                }}
                            />
                        </div>
                        <Row
                            style={{
                                marginTop: 5
                            }}
                        >
                            <Col
                                span={5}
                                offset={19}
                            >
                                <Button
                                    onClick={() => this.exportDefaultExcel(this.state.dataSource_Modal_Table_shipInfo, columns_Modal_table_shipInfo)}
                                >导出</Button>
                            </Col>
                        </Row>
                    </div>
                }
                <div className="GISMAP_body_div"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        // height: '100%'
                        // left: '24.5%',
                        // top: '43%',
                        // backgroundColor: 'rgba(19,35,130,0.31)',
                        // border: '1px solid white'
                    }}
                >
                    <MyComponentFactory
                        type={'map'}
                        componentLoad={this.componentLoad}
                        // playerMapPointCarera={this.playerMapPointCarera}
                        config_JSON={this.props.config_JSON}
                        parentObj={this}
                    ></MyComponentFactory>
                </div>
                <div className="clickBtn" onClick={this.showDrawer}>
                    {visible ? <Icon type="double-left" /> : <Icon type="double-right" />}
                </div>
                <ul className="toolBox">
                    {
                        toolArr.map((item, index) => (
                            <li key={index} onClick={this.clickTool.bind(this, index, item.key)}>
                                {item.line && <i>|</i>}
                                <img src={item.click ? item.act : item.img} />
                                <span style={item.click ? { color: '#169cfd' } : { color: '#999' }}>{item.name}</span>

                            </li>
                        ))
                    }
                </ul>
                {pointShow && <div className="pointBox">
                    <Table
                        columns={columns1}
                        dataSource={pointData}
                        pagination={false}
                        locale={{
                            filterConfirm: '确定',
                            filterReset: '重置',
                            emptyText: '暂无数据',
                        }}
                        // showHeader={ false }
                        scroll={{
                            y: '400px',
                            x: '100%',
                            scrollToFirstRowOnChange: true,
                        }}
                    />
                </div>}
                {portShow && <div className="portBox">
                    <Table
                        columns={columns2}
                        dataSource={portData}
                        pagination={false}
                        locale={{
                            filterConfirm: '确定',
                            filterReset: '重置',
                            emptyText: '暂无数据',
                        }}
                        // showHeader={ false }
                        scroll={{
                            y: '400px',
                            x: '100%',
                            scrollToFirstRowOnChange: true,
                        }}
                    />
                </div>}
                {switchShow && <div className="switchBox">
                    {
                        switchArr.map((item, index) => {
                            if (item.title) {
                                return <h4 key={index}>{item.title}</h4>
                            } else {
                                if (item.optionArr) {
                                    return <div key={item.key ? item.key : index} className="mapSwitch">
                                        <Switch checkedChildren="开" unCheckedChildren="关" size="small" checked={item.defaultChecked} onChange={this.controlLayer.bind(this, item)} /> <span className="switchName">{item.name}</span>
                                        <Select size="small" onSelect={this.controlLayer.bind(this, item)}>
                                            {
                                                item.optionArr.map((val) => (
                                                    <Option key={val.key} value={val.key}>{val.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                } else {
                                    return <div key={item.key ? item.key : index} className="mapSwitch"><Switch checkedChildren="开" unCheckedChildren="关" defaultChecked={item.defaultChecked} size="small" onChange={this.controlLayer.bind(this, item)} /> <span className="switchName">{item.name}</span></div>
                                }
                            }
                        })
                    }
                </div>}
                {
                    toolShow && <div className="survey">
                        <Tree
                            showIcon
                            defaultExpandAll
                            switcherIcon={<Icon type="down" />}
                            onSelect={this.clickSearch}
                        >
                            <TreeNode title={this.state.text_Measure_DropdownButton} key="measure" >
                                <TreeNode icon={<img src={maths.range} />} title="距离测量" key="range" disableCheckbox />
                                <TreeNode icon={<img src={maths.area} />} title="面积测量" key="area" />
                            </TreeNode>
                        </Tree >
                    </div>
                }
                {
                    visible && <div className="screenBigBox">
                        <Spin size="large"
                            tip="正在努力加载中..."
                            spinning={isLoading}
                            style={{
                                marginTop: '25%',
                                // marginLeft: '50%'
                            }}
                        >
                            <div className="screenBox">
                                <div>
                                    <Form>
                                        <Form.Item key={'value_DatePicker'} label={
                                            <span>选择日期</span>
                                        }
                                            {...{
                                                labelCol: {
                                                    xs: { span: 24 },
                                                    sm: { span: 4 },
                                                },
                                                wrapperCol: {
                                                    xs: { span: 24 },
                                                    sm: { span: 20 },
                                                },
                                            }}
                                        >
                                            {getFieldDecorator('value_DatePicker', {
                                                rules: [
                                                ],
                                                // initialValue: this.state.value_DatePicker,
                                                // valuePropName: 'moment',
                                            })(
                                                <RangePicker
                                                    ranges={{
                                                        '今天': [moment(), moment()],
                                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                                    }}
                                                    style={{ width: 380 }}
                                                    placeholder={[moment().format('YYYY-MM-DD') + ' 00:00:00', moment().format('YYYY-MM-DD') + ' 23:59:59']}
                                                    format={"YYYY-MM-DD HH:mm:ss"}
                                                    showTime={{
                                                        hideDisabledOptions: true,
                                                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                                                    }}
                                                    onChange={this.haveTime}
                                                    disabled={this.state.disabled_RangePicker}
                                                    value={this.state.value_DatePicker}
                                                // defaultValue={this.state.value_default_DatePicker}
                                                // defaultPickerValue={this.state.value_default_DatePicker}
                                                />
                                            )}
                                        </Form.Item>
                                        {/* <Form.Item key={'value_TreeSelect'} label={
                                            <span>船舶名称</span>
                                        }
                                            {...{
                                                labelCol: {
                                                    xs: { span: 24 },
                                                    sm: { span: 4 },
                                                },
                                                wrapperCol: {
                                                    xs: { span: 24 },
                                                    sm: { span: 20 },
                                                },
                                            }}
                                        >
                                            {getFieldDecorator('value_TreeSelect', {
                                                rules: [
                                                ],
                                                // initialValue: this.state.value_DatePicker,
                                                // valuePropName: 'moment',
                                            })(
                                                <TreeSelect
                                                    allowClear
                                                    multiple
                                                    style={{ width: 380 }}
                                                    placeholder='请选择（最多选择6项）'
                                                    searchPlaceholder="输入船名"
                                                    treeData={treeData}
                                                    value={value_TreeSelect_shipName}
                                                    defaultValue={value_TreeSelect_shipName}
                                                    treeNodeFilterProp="title"
                                                    treeCheckable={true}
                                                    // onSearch={this.qureHandleSearch}
                                                    // searchValue={viewSearchValue}
                                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                                    onChange={this.changeSelect}
                                                />
                                            )}
                                        </Form.Item> */}
                                    </Form>
                                </div>
                                {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <span> 船舶名称：</span>
                                <TreeSelect
                                    allowClear
                                    multiple
                                    style={{ width: 350 }}
                                    placeholder='请选择（最多选择20项）'
                                    searchPlaceholder="输入船名"
                                    treeData={treeData}
                                    treeNodeFilterProp="title"
                                    treeCheckable={true}
                                    // onSearch={this.qureHandleSearch}
                                    // searchValue={viewSearchValue}
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    onChange={this.changeSelect}
                                />

                            </div> */}
                                <div className="btnBox">
                                    <div
                                        style={{ width: 180 }}
                                    >
                                        <Radio.Group className="radioBox" onChange={this.onChange_areaList} value={this.state.value_RadioGroup_PolygonSelect}>
                                            <Radio value={'Circle'}><img src={ellipse} />  圆形框选</Radio>
                                            <Radio value={'Polygon'}><img src={polygon} />  多边形框选</Radio>
                                        </Radio.Group>
                                        {/* <Button onClick={this.showShipDynamic}>船舶动态</Button>
                                    <Select
                                        style={{ width: 180 }}
                                        mode={'tags'}
                                        maxTagCount={1}
                                        allowClear={true}
                                        labelInValue={true}
                                        placeholder={'请选择设备,默认全选'}
                                        onChange={this.onChange_cardNumType}>
                                        {
                                            Object.keys(this.state.cardNumTypeData).map(key => {
                                                let value = this.state.cardNumTypeData[key];
                                                return (
                                                    <Option value={key}>{value}</Option>
                                                )
                                            })
                                        }
                                    </Select> */}
                                    </div>
                                    <div>
                                        {/* <Tooltip
                                            title={
                                                (
                                                    <div
                                                        style={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                        {
                                                            this.state.text_Tooltip_searchList
                                                        }
                                                    </div>
                                                )
                                            }
                                            visible={this.state.visible_Tooltip_searchList}
                                        > */}
                                        {/* <Button
                                                type="primary"
                                                onClick={this.searchList}
                                                onMouseOver={this.hover_Tooltip_searchList}
                                                onMouseOut={this.out_Tooltip_searchList}
                                            >查询</Button> */}
                                        {/* </Tooltip> */}
                                        {/* <Button onClick={this.showPlayer} style={{ margin: '0 10px' }}>回放</Button> */}
                                        <Button onClick={this.resetMap}>重置</Button>
                                    </div>
                                </div>
                            </div>
                            {
                                player && <div className="playerBox">
                                    {/* <div className="playSlider">
                                    <span style={{ float: "left" }}>{0}</span> */}
                                    {/* <div style={{ width: 400, margin: '0 10px' }}>

                                        <Slider
                                            value={typeof inputvalue === 'number' ? inputvalue : 0}
                                            onChange={this.changeValue}
                                            min={0}
                                            max={maxnum}
                                            tooltipVisible={false}
                                        />
                                    </div> */}
                                    {/* <span style={{ float: "right" }}>{100}</span> */}
                                    {/* </div> */}
                                    <div className="playTool">
                                        {/* <Icon type="backward" onClick={this.playSlider.bind(this, 1)} style={back ? { color: '#159DFF' } : { color: '#A0A0A0' }} /> */}
                                        {/* <Tooltip
                                            title={
                                                (
                                                    <div
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        {
                                                            this.state.text_Tooltip_searchList
                                                        }
                                                    </div>
                                                )
                                            }

                                            visible={this.state.visible_Tooltip_searchList}
                                        > */}
                                        <img
                                            src={playing ? maths.play : maths.suspend}
                                            onClick={this.playSlider.bind(this, 2)}
                                            disabled={this.state.haveDataList && Object.keys(this.state.haveDataList).length > 0 ? true : false}
                                            style={{
                                                cursor: this.state.haveDataList && Object.keys(this.state.haveDataList).length > 0 ? 'pointer' : 'not-allowed'
                                            }}
                                            onMouseOver={this.hover_Tooltip_searchList}
                                            onMouseOut={this.out_Tooltip_searchList}
                                        />
                                        <Button
                                            type={'primary'}
                                            onClick={this.onClick_doubleForSpeed_trajectory}
                                            style={{
                                                marginLeft: 10,
                                                height: 23,
                                            }}
                                            onMouseOver={this.hover_Tooltip_searchList}
                                            onMouseOut={this.out_Tooltip_searchList}
                                            disabled={this.state.haveDataList && Object.keys(this.state.haveDataList).length > 0 ? false : true}
                                        >X{this.state.doubleSpeedNum}</Button>
                                        {/* </Tooltip> */}
                                        {/* <Icon type="forward" onClick={this.playSlider.bind(this, 3)} style={fast ? { color: '#159DFF' } : { color: '#A0A0A0' }} /> */}
                                    </div>
                                </div>
                            }
                            {
                                // dynamic //航程统计 面板
                                false
                                && <div className="shipDynamic">
                                    <div
                                    // onChange={this.onChange_Select_shipDynamic}
                                    // placeholder={'请选择...'}
                                    // labelInValue={true}
                                    // value={this.state.value_Select_shipDynamic}
                                    >
                                        <h3>航程统计</h3>
                                        {
                                            selectArr && selectArr.map((val, index) => (
                                                <Button
                                                    style={{
                                                        backgroundColor: this.state.backgroundColors_shipDynamic[index],
                                                        color: this.state.colors_shipDynamic[index],
                                                        marginLeft: 20,
                                                        marginBottom: 10
                                                    }}
                                                    onClick={() => this.onChange_Select_shipDynamic(val, index)}
                                                >{val.text}</Button>
                                            ))
                                        }
                                    </div>
                                    <div className="tongji">
                                        <ul>
                                            <li>
                                                <p><img src={require("../../assets/img/voyageSmall.png")} />航程/公里</p>
                                                <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.totalMileage / 1000 : 0}</p>
                                            </li>
                                            <li>
                                                <p><img src={require("../../assets/img/jinchu.png")} />进出港/次数</p>
                                                <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.arrivalCount + '/' + shipVoyageInfoData.leaveCount : '0/0'}</p>
                                            </li>
                                            <li>
                                                <p><img src={require("../../assets/img/weigui.png")} />违规/次数</p>
                                                <p className="bigP">{shipVoyageInfoData ? shipVoyageInfoData.violationCount : 0}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dongtai">
                                        <h4>航程动态</h4>
                                        <Timeline>
                                            {shipVoyageInfoData && shipVoyageInfoData.dynamicStateList && shipVoyageInfoData.dynamicStateList.length > 0 ? shipVoyageInfoData.dynamicStateList.map(item => (
                                                <Timeline.Item color="green">
                                                    <p>{item.eventTypeName}</p>
                                                    <p>{item.eventRemark}</p>
                                                    <p>{item.gmtCreate}</p>
                                                </Timeline.Item>
                                            )) : ''
                                            }
                                        </Timeline>
                                    </div>
                                </div>
                            }
                        </Spin>
                    </div>
                }
            </div>
        )
    }
}

export default Form.create()(TrackQuerySelectArea);
// export default TrackQuerySelectArea
