import React, { Component } from 'react'

import './css/RealTimeTyphoonMonitoring.css'
class RealTimeTyphoonMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let heightBody = document.body.offsetHeight;
        return (
            <div
                className="div_RealTimeTyphoonMonitoring"
                style={{
                    position: 'relative',
                    zIndex:1,
                    top:'-55px'
                }}
            >
                <iframe
                    width="100%"
                    height={heightBody}
                    /*
                    http://www.tf110.com/
                    */
                    /*
                    https://typhoon.slt.zj.gov.cn/wap.htm
                    */
                    /*
                    http://www.42001.cn/
                    */
                    src={'https://tf.istrongcloud.com/'}
                    style={{
                        position:'absolute'
                    }}
                />
            </div>
        );
    }
}
export default RealTimeTyphoonMonitoring;