// 船舶管理---设备安装
import React, { Component } from 'react'
import './css/system.css'
import AddFormItem from './components/addFormItem/AddFormItem'
import {Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload, TreeSelect} from 'antd';
import type,{ ColumnsType } from 'antd/es/table';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
// 引入jQuery
import $ from 'jquery'
import {
    selectShipDetails,
    //单个审批
    auditingShip,
    //多个审批成功
    multipleAuditingShip,
    //多个审批失败
    refuseMultipleAuditingShip,
    refuseAuditingShip,
    getArgs,
    insertShipInstall,
    exportShipTemplate,
    deleteShip,
    bindShipDevice,
    importShips,
    updateShipInstall,
    selectByRegion,
    exportShipList,
    unbingDevice,
    selectDeviceTypeList
} from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class ShipMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            deleteData: {},
            //单个审核
            auditingData:{},
            //批量审核
            auditingData_All:{},
            Label: false,
            ulshow: false,
            modify: false,
            modifyShow: false,
            modifyData: {},
            cardNumber: '',
            deviceNumber: '',
            status: '',
            shipName: '',
            regionId: '',
            regionName: '',
            installTime:'',
            pageIndex: 1,
            pageSize: 10,
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: '',
            ShipGroupData: [],
            unbingData: {},
            pagination: {
                total: 0,
                current: 1,
                //
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            //选中船舶信息
            strs: undefined,
            // 空树形结构数组
            treeData : [

            ],
            //设备类型列表
            deviceTypeList:[],
            //用户选择的设备类型值
            deviceTypeValue:'',
        }
        this.deleteShip = this.deleteShip.bind(this)
        this.selectShipDetails = this.selectShipDetails.bind(this)
        this.auditingShip = this.auditingShip.bind(this)
        this.refuseAuditingShip = this.refuseAuditingShip.bind(this)
        this.insertShipInstall = this.insertShipInstall.bind(this)
        this.bindShipDevice = this.bindShipDevice.bind(this)
        this.updateShipInstall = this.updateShipInstall.bind(this)
        this.unbingDevice = this.unbingDevice.bind(this)
        //批量审批
        this.multipleAuditingShip = this.multipleAuditingShip.bind(this)
        this.refuseMultipleAuditingShip = this.refuseMultipleAuditingShip.bind(this)
        this.selectByRegion = this.selectByRegion.bind(this)
        this.exportShipList = this.exportShipList.bind(this)
    }
    componentDidMount() {
        this.selectByRegion()
        this.selectDeviceTypeList()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    // 查询设备类型列表
    async selectDeviceTypeList() {
        let data = await selectDeviceTypeList()
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            console.log('设备类型接口返回————————————',data.rows)
            this.setState({deviceTypeList:data.rows.map(item=>({id:item.id,deviceType:item.deviceType}))})

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    // 查询区域树形结构
    async selectByRegion(value, type) {
        let params = {
        }
        let data = await selectByRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let treeSelectData = this.handleData(data.rows);
            if (type === 'select') {
                this.setState({
                        treeData: treeSelectData,
                    }
                )
            } else {
                this.setState({
                    treeData: treeSelectData,
                })
            }
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    // 批量审批通过
    async multipleAuditingShip() {
        let { selectedKeys } = this.state
        if (JSON.stringify(selectedKeys) !== '[]'){
            console.log("___________________"+selectedKeys)
            let params = {
                ids: selectedKeys
            }
            console.log("....646......"+selectedKeys);
            let data = await multipleAuditingShip(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => { });
            if (data && data.code == 200) {
                message.success(data.message)
                this.setState({
                    deleteAll: false,
                    auditingShow_All: false
                })
                this.selectShipDetails()
            } else {
                this.setState({
                    auditingShow_All: false
                })
                message.error(data.message)
            }

        }else {
            message.error("至少选择一条船舶...")
        }

    }

    // 批量审批拒绝
    async refuseMultipleAuditingShip() {
        let { selectedKeys } = this.state
        if (JSON.stringify(selectedKeys) !== '[]'){
            console.log("___________________"+selectedKeys)
            let params = {
                ids: selectedKeys
            }
            console.log("....646......"+selectedKeys);
            let data = await refuseMultipleAuditingShip(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => { });
            if (data && data.code == 200) {
                message.success(data.message)
                this.setState({
                    deleteAll: false,
                    auditingShow_All: false
                })
                this.selectShipDetails()
            } else {
                this.setState({
                    auditingShow_All: false
                })
                message.error(data.message)
            }


        }else {
            message.error("至少选择一条船舶...")
        }

    }
    //导出模板接口
    async exportShipTemplate() {
        let params = {

        }
        let data = await exportShipTemplate(params)
            .then(res => {
                if (res.ok) {
                    res.blob().then((blob) => {
                        var a = document.createElement('a');
                        var url = window.URL.createObjectURL(blob);
                        var filename = "船舶管理导入模板.xlsx";
                        a.href = url;
                        a.download = filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                } else {
                    return Promise.reject("导出失败");
                }
            })
            .catch(ex => {
                let exMsg = ex instanceof Object ? ex.toString() : ex;
                message.error(exMsg, 5);
            });

    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectShipDetails()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.selectShipDetails(this.state.name, 'select')
            }
            )
        }
    }
    // 点击表格修改事件
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectShipDetails(names)
        this.setState({
            name: names
        })
    }
    // 分页查询
    async selectShipDetails(value, type) {
        this.setState({loading:true})

        let { pagination} = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            deviceNumber: this.state.deviceNumber,
            status: this.state.status,
            shipName: this.state.shipName,
            regionName: this.state.regionName,
            regionId: this.state.regionId,
            installTime: this.state.installTime,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await selectShipDetails(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UallArr: data.rows,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                    this.setState({
                        ShipGroupData: data.rows,
                        dataSource: data.rows,
                        pageTotal: data.total,
                        loading: false
                    })
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    ///导出Excel
    async exportShipList(value, type) {
        this.setState({ loading: true })
        let { datas } = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            status: this.state.status,
            shipName: this.state.shipName,
            regionName: this.state.regionName,
            regionId: this.state.regionId,
            installTime: this.state.installTime,
        }
        let data = await exportShipList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        datas: data,
                    }
                )
            } else {
                location.href = data.message
                this.setState({
                    datas: data,
                    loading: false
                })

            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    //复选框选中项
    distribute_onChage = (selectedRowKeys) => {
        let keyArr = this.getSelectKey(this.state.ShipGroupData, selectedRowKeys)
        console.log("+++++++++++++237++++++++++++"+keyArr);
        this.setState({
            selectedKeys: keyArr
        })
    }
    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }
    //导出
    // exportDefaultExcel = (e, coloums) => {
    //     let dockInfoData = this.state.dataSource;
    //     let headers = [];
    //     coloums.map((item, index) => {
    //         let kv = {
    //             k: item.dataIndex ? item.dataIndex : item.key,
    //             v: item.title
    //         }
    //         headers = headers.concat(kv);
    //     })
    //     if (dockInfoData && dockInfoData.length > 0) {
    //         exportExcel(headers, dockInfoData, '航次统计报表.xlsx');
    //     }
    //     else {
    //         message.error('导出数据失败！！！');
    //     }
    // }
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectShipDetails()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

    //解绑弹出框
    unbingDeviceShow= (data) => {
        this.setState({
            unbingShow: true,
            unbingData: data
        })
    }
    // 解绑
    async unbingDevice() {
        let { unbingData } = this.state;
        // console.log(unbingData, 'deleteData')
        let params = {
            id: unbingData.id,
        }
        console.log(unbingData, params, 'params')
        let data = await unbingDevice(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("解绑成功")
            this.selectShipDetails()
        } else if (data.code == 500) {
            message.error("解绑失败:"+data.message)
        }
        this.setState({
            unbingShow: false,
        })
    }

    // 新增安装信息
    async insertShipInstall(obj, callback) {
        obj.aliasName = obj.shipName
        // console.log('obj----',obj)
        let data = await insertShipInstall(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            callback.onSuccess()
        } else  {
            message.error("新增失败:"+data.message)
        }
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }
    // 绑定设备
    async bindShipDevice(arr) {
        let { labelData } = this.state;
        let params = [];
        console.log(arr, 'oooooooooo')
        arr.forEach((val) => {
            if (val) {
                params.push({
                    objectName: labelData.shipName,
                    objectId: labelData.objectInfoId,
                    deviceId: val.deviceId,
                    deviceType: val.deviceType,
                    cardNumType: val.cardNumType,
                })
            }

        })
        console.log(params, 'params')
        let data = await bindShipDevice(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success("绑定成功")
            this.selectShipDetails()
        } else {
            message.error(data.msg)
        }
        this.setState({
            Label: false,
        })
    }

    // 拒绝绑定设备
    async unBindShipDevice(arr) {
        let { labelData } = this.state;
        let params = [];
        console.log(arr, 'oooooooooo')
        console.log(params, 'params')
        this.setState({
            Label: false,
        })
    }


    exportDefaultExcelApi = (e, coloums) => {
        this.selectShipDetails(undefined, undefined, 9999999, coloums)
    }
    //导出
    exportDefaultExcel = (e, coloums) => {

        let dockInfoData = this.state.AlldataSource;
        delete coloums[7]
        if (dockInfoData) {
            dockInfoData.map((items) => {
                if (this.state.shipTypeArr) {
                    this.state.shipTypeArr.map((item) => {
                        if (item.value == items.shipType) {
                            items.shipType = item.text
                        }
                    })
                }
            })
        }
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (dockInfoData && dockInfoData.length > 0) {
            exportExcel(headers, dockInfoData, '船舶管理.xlsx');
        }
        else {
            message.error('导出数据失败！！！');
        }
    }
    // 修改船舶详情
    async updateShipInstall(obj) {
        let data = await updateShipInstall(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                modify: false,
            })
            this.selectShipDetails()
            message.success("修改成功")
        } else  {
            message.error("修改失败:"+data.message)
        }
        this.setState({
            deleteShow: false,
        })
    }
    // 导入船舶
    async importShips(fileData) {
         this.setState({ loading: true })
        let formData = new FormData()
        formData.append("file", fileData)
        // console.log(params,'params')
        let data = await importShips(formData)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success(data.message)
            this.selectShipDetails()
        } else {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
            loading: false
        })
    }
    Labelmanagement = (data) => {
        console.log(data, 'shipName1111')
        this.setState({
            Label: true,
            labelData: data
        }, () => {
            console.log(this.state.labelData, 'shipName1')
        })
    }
    modify = (data) => {
        this.setState({
            modify: true,
            modifyData: data,
        })
    }
        //审核
    remove = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    //审核
    auditingClickShow = (data) => {
        this.setState({
            auditingShow: true,
            auditingData: data
        })
    }
    //批量审核
    auditingClickShow_All = (data) => {
        this.setState({
            auditingShow_All: true,
            auditingData_All: data
        })
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            historyShow: false,
            Label: false,
            modify: false,
            auditingShow_All: false
        })
    }
    //  停用船
    async deleteShip() {
        let { deleteData } = this.state;

        let params = {
            id: deleteData.id
        }
        console.log(deleteData, 'deleteData')

        let data = await deleteShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("停用成功")
            this.selectShipDetails()
        } else   {
            message.error("停用失败:"+data.message)
        }
        this.setState({
            deleteShow: false,
        })
    }
    async auditingShip() {
        let { auditingData } = this.state;
        console.log(auditingData, 'auditingData')
        let params = {
            id: auditingData.id,
        }
        console.log(auditingData, params, 'params')
        let data = await auditingShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success(data.message)
            this.selectShipDetails()
        } else if (data.code == 500) {
            message.error(data.message)
        }
        this.setState({
            auditingShow: false,
        })
    }
    //refuseAuditingShip
    async refuseAuditingShip() {
        let { auditingData } = this.state;
        console.log(auditingData, 'auditingData')
        let params = {
            ids: auditingData.id,
        }
        console.log(auditingData, params, 'params')
        let data = await refuseAuditingShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success(data.message)
            this.selectShipDetails()
        } else if (data.code == 500) {
            message.error(data.message)
        }
        this.setState({
            auditingShow: false,
        })
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            auditingShow:false,
            modifyShow:false,
            auditingShow_All: false,
            Label: false,
            modify: false,
        })
    }
    exportDefault = () => {
        this.setState({
        }, () => {
            this.exportShipTemplate()
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectShipDetails()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    getNewShipData = (value) => {
        console.log(">>>",value)
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.id = modifyData.id
        this.updateShipInstall(value)
        console.log(value, 'getNewShipData')
    }
    // 获取文件后缀
    getFileSuffix = name => {
        const index = name.lastIndexOf(".");
        const len = name.length;
        return name.substring(index, len) || "-";
    };

    beforeUpload = file => {
        const suffix = this.getFileSuffix(file.name);
        const isLt2M = file.size / 1024 / 1024 < 2;
        const isValid = suffix === ".xlsx" || suffix === ".xls";
        if (!isValid) {
            message.warn("上传失败，请选择.xlsx或.xls格式");
        }
        //   if (!isLt2M) {
        //     message.warn("上传失败，大小不能超过2M");
        //   }
        return isValid;
    }
    //分页筛选
    async getArgs(value) {
        let params = {

        }
        let data = await getArgs(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.data,

            })


        } else {
        }
    }
    //筛选
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            let { objectNameArr } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 100 }} placeholder={'请选择'}
                        showSearch
                        multiple
                    >
                        {
                            objectNameArr && objectNameArr.regionNames.map((item) => (
                                <Option
                                    onClick={this.clickSearchData1.bind(this, item)}
                                    value={item} >{item}</Option>
                            ))
                        }
                    </Select>,
          </div>
                <div>
                </div>
            </div>
        }
    });
    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectShipDetails()
        });
    }
    //回车搜索
    onKeyup = (e) => {
        // this.selectShipDetails()
    }
    /**
     * 递归生成数据  因为后端给的字段没有和ant design相关的 title value等属性值
     * 所以需要递归修改data属性值，配合treeSelect规范数据
     * @param {Object} data 原始数据
     */
    handleData = (data) => {
        const item = [];
        data.map((list) => {
            const newData = {};
            newData.value = list.regionName;
            newData.id = list.id
            newData.title = list.regionName;
            newData.children = list.children ? this.handleData(list.children) : []; // 如果还有子集，就再次调用自己
            item.push(newData);
            return false
        });
        //console.log("<<<"+JSON.stringify(item))
        return item;
    }
    onChange = (value, label, extra) => {
        console.log("<<<value, label, extra"+value, label, extra);
        this.setState({
            // value,
            regionName:value,
            regionId:extra['triggerNode']?extra['triggerNode']['props']['id']:''
        });
    };
    onChange2 = (value, label, extra) => {
        console.log("<<<value, label, extra"+value, label, extra);
        this.setState({
            value,
            // regionName:value,
            // regionId:extra['triggerNode']?extra['triggerNode']['props']['id']:''
        });
    };
    // onSelect = (value,node,extra) => {
    //     console.log("<<<"+(node['props']['id']))
    //     console.log("<<<"+value,node.toString(),extra )
    //     this.setState({
    //         node,
    //         regionId:node['props']['id']
    //     })
    // }
    //处理新增按钮 选中设备类型
    handleDeviceTypeChange = (value)=>{
        console.log('value',value)
        this.setState({deviceTypeValue:value});
    }
    render() {
        let { modifyData, ulshow,pageTotal,nowModalData,selectedKeys,ShipGroupData } = this.state;
        let {treeData, ...rest } = this.props;
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const listColumns = [
            {
                title: '区域',
                key: 'regionName',
                dataIndex: 'regionName',
            },
            {
                title: '船名',
                key: 'shipName',
                dataIndex: 'shipName',
            },

            {
                title:'北斗卡号',
                key:'cardNumber',
                dataIndex:'cardNumber',
            },
            {
                title:'北斗型号',
                key:'deviceType',
                dataIndex:'deviceType',
            },
            {
                title:'主机编号',
                key:'deviceNumber',
                dataIndex:'deviceNumber',
            },
            {
                title:'屏型号',
                key:'displayType',
                dataIndex:'displayType',
            },
            // {
            //     title:'屏编号',
            //     key:'displayNumber',
            //     dataIndex:'displayNumber',
            // },
            {
                title: '船主',
                key: 'owner',
                dataIndex: 'owner',
            },
            {
                title: '联系方式',
                key: 'phone',
                dataIndex: 'phone',
            },

            {
                title: '安装时间',
                key: 'installTime',
                dataIndex: 'installTime',
            },

            {
                title: '修改时间',
                key: 'gmtModified',
                dataIndex: 'gmtModified',
                render: (gmtModified) => {
                    if (gmtModified != null){
                        let time = moment(parseInt(gmtModified)).format('YYYY-MM-DD HH:mm:ss')
                        return time
                    }
                }
            },
            {
                title: '操作',
                key: 'detele',
                render: (text, record) => {
                    return <div>
                        {<span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.modify(record)}>修改</span>}
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                         <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.remove(record)}>停用</span>
                    </div>
                },
            },
        ]


        //新增按钮 字段详情
        const addColumnsList = [
            {
                label: '船舶名称', //表单label
                id: 'shipName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '船舶名称不能为空!' }] }
            },
            {
                label: '北斗卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '设备号不能为空!' }] }
            },
            {
                label: '设备号', //表单label
                id: 'deviceNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '设备类型', //表单label
                id: 'deviceType', //表单Item的Key值
                // component: <Input placeholder={'请输入'} />, //表单受控组件
                component: <Select
                    placeholder={'请选择'}
                    // style={{ width: '37%', marginRight: '3%' }}
                    onChange={this.handleDeviceTypeChange}
                >
                    {
                        this.state.deviceTypeList && this.state.deviceTypeList.map((item) => (
                            <Option key={item.deviceType} value={item.deviceType}>{item.deviceType}</Option>
                        ))
                    }
                </Select> //表单受控组件
            },
            {
                label: '显示屏编号', //表单label
                id: 'displayNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '显示屏类型', //表单label
                id: 'displayType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '船舶类型', //表单label
                id: 'type', //表单Item的Key值shipTypeArr
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '37%', marginRight: '3%' }}
                // onChange={this.handleDeviceChange}
                >
                    {
                        this.state.shipTypeArr && this.state.shipTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select> //表单受控组件
            },

            {
                label: '区域', //表单label
                id: 'regionName', //表单Item的Key值
                // component: <Input placeholder={'请输入'} />, //表单受控组件
                component: <TreeSelect
                    style={{
                        width: '70%',
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    id={this.state.value}
                    allowClear={true}
                    treeNodeLabelProp="regionName"
                    placeholder="请选择"
                    treeData={this.state.treeData}
                    // treeDefaultExpandAll
                    onChange={this.onChange2}
                    // onSelect={this.onSelect}
                    {...rest}>
                </TreeSelect>,
                options: { rules: [{ required: true, message: '区域不能为空!' }] }


            },
            // {
            //     label: '所属单位', //表单label
            //     id: 'Subordinateunit', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            // },
            {
                label: '船主', //表单label
                id: 'owner', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '手机号码',
                id: 'phone',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '身份证',
                id: 'idCard',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '地址',
                id: 'address',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '安装人',
                id: 'installer',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
           {
                label: '安装时间',
                id: 'installTime',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
           {
                label: '船长(米)',
                id: 'length',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '船宽(米)',
                id: 'width',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '船高(米)',
                id: 'high',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
           {
                label: '吨位',
                id: 'tonnage',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
           {
                label: '功率',
                id: 'power',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
           {
                label: '备注',
                id: 'remark',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            ]
        /*
        *
        *
        *修改字段详情
        *
        * */
        const modifyList = [
             {
                label: '船舶名称', //表单label
                id: 'shipName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '船舶名称不能为空!' }] , initialValue: modifyData.shipName ? modifyData.shipName : '' }
            },
            {
                label: '北斗卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '北斗号不能为空!' }], initialValue: modifyData.cardNumber ? modifyData.cardNumber : '' }
            },
            {
                label: '设备号', //表单label
                id: 'deviceNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.deviceNumber ? modifyData.deviceNumber : '' }
            },
            {
                label: '设备类型', //表单label
                id: 'deviceType', //表单Item的Key值
                // component: <Input placeholder={'请输入'} />, //表单受控组件
                component: <Select
                    placeholder={'请选择'}
                    // style={{ width: '37%', marginRight: '3%' }}
                    onChange={this.handleDeviceTypeChange}

                >
                    {
                        this.state.deviceTypeList && this.state.deviceTypeList.map((item) => (
                            <Option key={item.deviceType} value={item.deviceType}>{item.deviceType}</Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { initialValue: modifyData.deviceType ? modifyData.deviceType : '' }
            },
            {
                label: '显示屏编号', //表单label
                id: 'displayNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.displayNumber ? modifyData.displayNumber : '' }
            },
            {
                label: '显示屏类型', //表单label
                id: 'displayType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.displayType ? modifyData.displayType : '' }
            },
            // {
            //     label: '显示屏编号', //表单label
            //     id: 'displayNumber', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            // },
            // {
            //     label: '显示屏类型', //表单label
            //     id: 'displayType', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            // },
            {
                label: '船舶类型', //表单label
                id: 'type', //表单Item的Key值shipTypeArr
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '37%', marginRight: '3%' }}
                // onChange={this.handleDeviceChange}
                >
                    {
                        this.state.shipTypeArr && this.state.shipTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select> //表单受控组件
            },

            // {
            //     label: '船籍港', //表单label
            //     id: 'Portregistry', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            // },
            {
                label: '区域名称', //表单label
                id: 'regionName', //表单Item的Key值
                // component: <Input placeholder={'请输入'} />, //表单受控组件
                component: <TreeSelect
                    style={{
                        width: '70%',
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    id={this.state.value}
                    allowClear={true}
                    treeNodeLabelProp="regionName"
                    placeholder="请选择"
                    treeData={this.state.treeData}
                    // treeDefaultExpandAll
                    onChange={this.onChange2}
                    // onSelect={this.onSelect}
                    {...rest}>
                </TreeSelect>, //表单受控组件
                options: { initialValue: modifyData.regionName ? modifyData.regionName : '' }
            },
            // {
            //     label: '所属单位', //表单label
            //     id: 'Subordinateunit', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            // },
            {
                label: '船主', //表单label
                id: 'owner', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.owner ? modifyData.owner : '' }
            },
            {
                label: '手机号码',
                id: 'phone',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.phone ? modifyData.phone : '' }
            },
            {
                label: '身份证',
                id: 'idCard',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.idCard ? modifyData.idCard : '' }
            },
            {
                label: '地址',
                id: 'address',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.address ? modifyData.address : '' }
            },
            {
                label: '安装人',
                id: 'installer',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.installer ? modifyData.installer : '' }
            },
           {
                label: '安装时间',
                id: 'installTime',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.installTime ? modifyData.installTime : '' }
            },
           {
                label: '船长(米)',
                id: 'length',
                component: <Input placeholder={'请输入'} />, //表单受控组件
               options: { initialValue: modifyData.length ? modifyData.length : '' }
            },
            {
                label: '船宽(米)',
                id: 'width',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.width ? modifyData.width : '' }
            },
            {
                label: '船高(米)',
                id: 'high',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.high ? modifyData.high : '' }
            },
           {
                label: '吨位',
                id: 'tonnage',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.tonnage ? modifyData.tonnage : '' }
            },
           {
                label: '功率',
                id: 'power',
                component: <Input placeholder={'请输入'} />, //
                options: { initialValue: modifyData.power ? modifyData.power : '' }
            },
           {
                label: '备注',
                id: 'remark',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.remark ? modifyData.remark : '' }
            },


        ]
        const upload = {
            name: 'file',
            action: '/bd3api/importShips',
            headers: {
                authorization: 'authorization-text',
                // token: JSON.parse(sessionStorage.getItem('isLogin')).data.password
            },
            onChange(info) {
                if (info.file.code !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.code === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                } else if (info.file.code === 'error') {
                    message.error(`${info.file.name} 上传失败`);
                }
            },
            beforeUpload: this.beforeUpload
        };

        const props = {

            beforeUpload: file => {
                this.importShips(file)
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };

        return (
            <div className='shipmonitoring contentBox'>
                <div className="cardManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">船舶管理</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="cardManagement_title_menuSelect">

                         <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                北斗卡号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>
                            </div>
                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                船   名：
                                <Input placeholder="请输入" style={{width: '70%'}} name='shipName' value={this.state.shipName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                设备编号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='deviceNumber' value={this.state.deviceNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>


                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                区域：
                                {/*<Input placeholder="请输入" style={{width: '70%'}} name='regionName' onChange={value => this._changeValue(value)}/>*/}
                                {/*{title && <p className="rsselect-title">{title}：</p>}*/}
                                {<TreeSelect
                                    style={{
                                        width: '70%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    id={this.state.value}
                                    value={this.state.regionName}
                                    allowClear={true}
                                    treeNodeLabelProp="regionName"
                                    placeholder="Please select"
                                    treeData={this.state.treeData}
                                    // treeDefaultExpandAll
                                    onChange={this.onChange}
                                    onSelect={this.onSelect}
                                    {...rest}>
                                </TreeSelect>}
                            </div>
                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                安装时间：<Input placeholder="请输入" style={{width: '70%'}} name='installTime' value={this.state.installTime} onChange={value => this._changeValue(value)}/>
                            </div>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectShipDetails()}
                            >查询</Button>


                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={() =>{
                                    this.setState({
                                        cardNumber: '',
                                        shipName: '',
                                        regionName: '',
                                        regionId: '',
                                        installTime: '',
                                    },() =>{

                                    })
                                }}

                            >重置</Button>

                        </div>

                    </div>
                    <div className="cardManagement_title_buttons">
                                    {/**   <div className="cardManagement_title_buttons_button" onClick={() => this.auditingClickShow_All()}>批量审批</div> **/}
                        <div className="cardManagement_title_buttons_button" > <Upload
                            {...props}
                            style={{ paddingTop: 19 }}
                        >
                            导入
                        </Upload></div>
                        <div className="cardManagement_title_buttons_button" onClick={() => {
                            this.exportShipList()
                        }}>导出</div>

                   {/*     <div  className="cardManagement_title_buttons_button" onClick={() => {
                            this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})
                        }}>下载导入模板</div> */}
                        <div className="cardManagement_title_buttons_button"
                             onClick={this.addClickShow} style={{borderRight: '1px solid #8D959C'}}>新增
                        </div>


                    </div>
                </div>

                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div className="shipManagement">
                    <Table
                        columns={listColumns}
                        rowSelection={distribute_rowSelect}
                        dataSource={nowModalData ? nowModalData : ShipGroupData}
                        rowKey={record => record.id}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectShipDetails(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        scroll={{
                            x: 1500,
                            y: 500,
                        }}
                        // scroll={{x:1700}}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={addColumnsList}
                    closeModal={this.closeModal}
                    submit={this.insertShipInstall}
                    title="新增"
                />
                {/*{*/}
                {/*    this.state.addShow && <div className="modifyShipData">*/}
                {/*        <h3>新增</h3>*/}
                {/*        <Form formItemList={addColumnsList} cancel={true} submit={this.insertShipInstall} closeModal={this.closeModal} />*/}
                {/*    </div>*/}
                {/*}*/}

                {/*<ModalForm*/}
                {/*    visible={this.state.modifyShow}*/}
                {/*    formItemList={modifyList}*/}
                {/*    closeModal={this.closeModal}*/}
                {/*    submit={this.updateShipInstall}*/}
                {/*    title="修改"*/}
                {/*/>*/}

                {
                    this.state.modify && <div className="modifyShipData">
                        <h3>修改</h3>
                        <Form formItemList={modifyList} cancel={true} submit={this.getNewShipData} closeModal={this.closeModal} className={"shipManage"}/>
                    </div>
                }
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.refuseMultipleAuditingShip}
                    title="批量审批"
                    onOk={this.multipleAuditingShip}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.cardNumber}"此卡将被停用，确认停用吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="停用提醒"
                    onOk={this.deleteShip}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.shipName}"确认停用吗？`}</span>
                </Modal>
                {/*
                 Modal的修改
                */}
                <Modal
                    visible={this.state.auditingShow_All}
                    onCancel={this.refuseMultipleAuditingShip}
                    cancelText = "拒绝"
                    okText = "通过"
                    title="审批提醒"
                    onOk={this.multipleAuditingShip}>
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`确认多个待审核信息无误，审批通过吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.Label}
                    onCancel={this.closeModal}
                    title="标签管理"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={600}
                >
                    <AddFormItem data={this.state.labelData} onOk={this.bindShipDevice} onClose={this.unBindShipDevice} />
                </Modal>
                <Modal
                    visible={this.state.unbingShow}
                    onCancel={this.closeModal}
                    title="解绑提醒"
                    onOk={this.unbingDevice}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.unbingData.cardNumber}"数据解绑后不可恢复，确认走解绑流程吗？`}</span>
                </Modal>
            </div>
        )
    }
}
export default ShipMonitoring