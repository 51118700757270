// 推送用户查询
import React, { Component } from 'react'
import './css/system.css'
import AddFormItem from './components/addFormItem/AddFormItem'
import {Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload, Menu, Dropdown} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
import {
    getArgs,
    getUgroupTree,
    selectClientPush,
} from '../../data/dataStore'
import $ from "jquery";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
let format = 'YYYY-MM-DD HH:mm:ss';
//今天0点
const piker1 = moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss');
//今天23点59
const piker2 = moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss');
class ClientPush extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            cardNumber:'',
            shipName:'',
            clientName:'',
            regionId:'',
            reportTime:'',
            //上个月今天
            startTime: moment(piker1).format('YYYY-MM-DD HH:mm:ss'),
            //今天的年月日
            endTime: moment(piker2).format('YYYY-MM-DD HH:mm:ss'),
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: null,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            userData: [],
            userGroupData: [],
            testData:[],
            loadingServiceFlag: false,
            trajectoryDateList: [

                //本月

                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')


            ],//上报时间
        }
        this.selectClientPush = this.selectClientPush.bind(this)
    }

    componentDidMount() {
        // this.selectClientPush()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }

    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectClientPush()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.selectClientPush(this.state.name, 'select')
            }
            )
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectClientPush()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }


    // 分页查询
    async selectClientPush(value, type, pageSizeApi, coloums,id) {
        this.setState({ loading: true })
        let {startTime,endTime,pagination } = this.state;
        let params = {
            // cardNumber: this.state.cardNumber,
            clientName: this.state.clientName,
            //增加开始时间与结束时间参数
            // startTime,
            // endTime,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
            // regionName: this.state.regionName,
            // regionId: this.state.regionId,
        }
        let data = await selectClientPush(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UallArr: data.rows,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                    this.setState({
                        ulshow: false,
                        dataSource: data.rows,
                        pageTotal: data.total,
                        loading: false
                    })
                }
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }




    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            //添加的对话框
            addShow: false,
            //删除提示框
            deleteShow: false,
            //标签
            Label: false,
            //修改的对话框
            modify: false,
            //
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }



    //分页筛选
    async getArgs(value) {
        let params = {

        }
        let data = await getArgs(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.rows,

            })


        } else {
        }
    }

    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectClientPush()
        });
    }
    // 用户组管理的接口
    // 层级树查询
    async getUgroupTree(id) {
        let params = {
            id: id ? id : '1',
        }
        let data = await getUgroupTree(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.testData)
            this.setState({
                userGroupData: newData,
                selectedKeys
            })
        }
    }


    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }



    render() {
        let { dataSource, selectedKeys,  pageTotal} = this.state;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let {treeData, ...rest } = this.props;
        let ullAllss = [...new Set(ullAlls)];

        //列表字段
        const columns = [
            {
                title: '客户名称',
                key: 'clientName',
                dataIndex: 'clientName',
            },
            {
                title: '登录ip',
                key: 'ip',
                dataIndex: 'ip',
            },
            {
                title: '登录状态',
                key: 'status',
                dataIndex: 'status',
            },
            {
                title: '最近登录时间',
                key: 'loginTime',
                dataIndex: 'loginTime',
                render: (loginTime) => {
                    if (loginTime != null) {
                        let time = moment(parseInt(loginTime)).format('YYYY-MM-DD HH:mm:ss')
                        return time;
                        }
                },
            },
            {
                title: '最近登出时间',
                key: 'logoutTime',
                dataIndex: 'logoutTime',
                render: (logoutTime) => {
                    if (logoutTime != null) {
                        let time = moment(parseInt(logoutTime)).format('YYYY-MM-DD HH:mm:ss');
                        return time;
                    }
                },
            },

            {
                title: '主题名',
                key: 'topicName',
                dataIndex: 'topicName',
            },
            {
                title: '消费组',
                key: 'groupId',
                dataIndex: 'groupId',
            },
            {
                title: '分区',
                key: 'partition',
                dataIndex: 'partition',
            },
            {
                title: '消息总数',
                key: 'messageCount',
                dataIndex: 'messageCount',
            },

            {
                title: '已消费数',
                key: 'consumedCount',
                dataIndex: 'consumedCount',
            },

            {
                title: '待消费数',
                key: 'tobeConsumedCount',
                dataIndex: 'tobeConsumedCount',
            },


            // {
            //     title: '区域',
            //     key: 'regionName',
            //     dataIndex: 'regionName',
            // },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },

        ]



        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">客户推送查询</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                客户名称：
                                <Input placeholder="请输入" style={{width: '70%'}} name='clientName' value={this.state.clientName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectClientPush()}
                            >查询</Button>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cardNumber: '',
                                            shipName: '',
                                            regionName: '',
                                            regionId: '',


                                        }, () => {
                                            //
                                        })
                                    }
                                }

                            >重置</Button>

                        </div>

                    </div>


                </div>

                <div className="cardManagement">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{y:515}}
                        rowKey={(record)=>record.cardNumber}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectClientPush(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>



            </div>
        )
    }
}
export default ClientPush