import { postData, postNewData, postJSONData,postFormData, postJSONDataNew,getData} from "../util/common";
// 登录页面
export function login(data) {
    return postJSONData("/login", data);
}
// 登出接口
export function logout(data) {
    return postData("/logout", data);
}
// 图层信息
export function getLayerInfo(data) {
    return postData("/layer/getLayerInfo", data);
}
// 系统配置
export function system_config(data) {
    return postData("/system/config", data);
}
// 船舶超时报警
export function timeoutAlarmStatistics(data) {
    return postData("/timeoutAlarmStatistics", data);
}
//航次统计报表
export function selectVoyageStatistics(data) {
    return postJSONData("/selectVoyageStatistics", data);
}
//短信发送历史
export function msgStatistics(data) {
    return postJSONData("/msgStatistics", data);
}
//进出港统计
export function leaveAndArrivalStatistics(data) {
    return postJSONData("/leaveAndArrivalStatistics", data);
}
//历史报警统计
export function alarmStatistics(data) {
    return postJSONData("/alarmStatistics", data);
}
//历史报警今日统计
export function alarmSimpleStatisticsToday(data) {
    return postJSONData("/alarmSimpleStatisticsToday", data);
}
//导出
export function exportRangeStatistics(data) {
    return postData("/exportRangeStatistics", data);
}


//航程统计报表
export function selectRangeStatistics(data) {
    return postJSONData("/selectRangeStatistics", data);
}
//分页查询参数
export function getArgs(data) {
    return postData("/getArgs", data);
}
//模糊搜索
export function selectShip(data) {
    return postJSONData("/selectShip", data);
}
//搜索船名和标签id
export function fastSelectShip(data) {
    return postData("/fastSelectShip", data);
}
//资源结构树
export function getTree(data) {
    return postData("/getTree", data);
}
//重点区域
export function specialRegionStatistics(data) {
    return postJSONData("/specialRegionStatistics", data);
}
//进出港
export function portStatistics(data) {
    return postJSONData("/portStatistics", data);
}
//报警
export function alarmSimpleStatistics(data) {
    return postJSONData("/alarmSimpleStatistics", data);
}
//查询轨迹
export function findObjTrackMulti(data) {
    return postData("/ship/getBdPositionListSparse", data);
}
//查询所有船的位置
export function allshipPos(data) {
    return postNewData("/lbs/position/list/last/from/resource", data);
}

export function searchByPolygonAndCircle(data) {
    return postJSONData("/lbs/position/search/polygon", data);
}
export function searchByPolygonAndCircle_trajectory(data) {
    return postJSONData("/lbs/position/find/track/polygon", data);
}

export function insertPortAreaInfo (data) {
    return postJSONData("/insertPortAreaInfo", data);
}

export function updateArea (data) {
    return postJSONData("/updateArea", data);
}

export function deleteArea (data) {
    return postNewData("/deleteArea", data);
}
//获取table数值
export function getIdentificationInfo(data) {
    return postJSONData("/getIdentificationInfo", data);
}
//停靠点筛选
export function selectAreaSimple(data) {
    return postData("/selectAreaSimple", data)
}
//字典表查询
export function getDict(data) {
    return postData("/getDict", data)
}
//分页查询
export function selectShipPage(data) {
    return postData("/selectShipPage", data)
}
/*
*
*北斗卡---卡片管理
*
*
* */
//查询北斗卡
export function selectCardDetails(data) {
    return postJSONData("/ship/webGetBdInfoList", data)
}

//查询告警信息
export function selectAlarmList(data) {
    return postData("/alarmLog/getAlarmLog", data)
}

//查询电池电量信息
export function selectBatteryHistory(data) {
    return postData("/lastStatus/getBatteryHistory", data)
}

//删除北斗卡
export function deleteCard(data) {
    return postJSONData("/cardBd2/getCardBd2List", data)
}
//查询II代北斗卡
export function selectIICardDetails(data) {
    return postData("/cardBd2/getCardBd2List", data)
}
//新增II代北斗卡
export function insertIICard(data) {
    return postJSONData("/cardBd2/createBd2Card",data)
    
}
//删除II代北斗卡
export function deleteIICard(data) {
    return postData("/cardBd2/removeBd2Card", data)
}
//批量注销北斗卡
export function deleteIICardByIds(data) {
    return postJSONData("/cardBd2/batchRemoveBd2Card", data)
}
//修改II代北斗卡
export function updateIICardDetail(data) {
    return postJSONData("/cardBd2/modifyCardBd2", data)
}
//导入II代北斗卡
export function importBd2Cards(data){
    return postFormData("/cardBd2/importBd2Cards", data)
}
//导出II代北斗卡所有数据
export function exportIICard(data){
    return postFormData("/export/importBd2Cards",data)
}
//查询北斗卡历史
export function selectBdCardLogs(data){
    return postData("/bdcardLog/getBd2CardLog",data)
}
//删除发货信息
// export function deleteDeviceSend(data){
//     return postData("/bd2DeviceSend/deleteDeviceSend",data)
// }
//查询权限所管理等区域列表
export function selectByRegion(data){
    return postData("/region/getRegionCheckTree",data)
}

//查询区域列表
export function selectRegion(data){
    return postData("/region/getRegionTree",data)
}

//新增区域
export function insertRegion(data) {
    return postJSONData("/region/createMenu", data)
}
//删除区域
export function deleteRegion(data){
    return postData("/region/delRegion",data)
}
//修改区域信息
export function updateRegionDetail(data) {
    return postJSONData("/region/modifyRegion", data)
}
// 增加维修记录
export function addRepairLog(data) {
    return postJSONData("/bdFaultLog/createBdFaultLog", data)
}
// 查询维修记录
export function getBdFaultLog(data) {
    return postJSONData("/bdFaultLog/getBdFaultLogList", data)
}
// 查询维修最新记录
export function getBdFaultLastLog(data) {
    return postJSONData("/bdFaultLog/getBdFaultLastLog", data)
}
//导入维修最新记录
export function importRepairLog(data) {
    return postFormData("/bdFaultLog/importRepairLog", data)
}


//查询设备发货列表
// export function selectDeviceSend(data) {
//     return postData("/bd2DeviceSend/getDeviceBd2List", data)
// }
//查询设备列表
export function selectDeviceDetails(data) {
    return postData("/bd2Device/getDeviceBd2List", data)
}

//新增设备发货
// export function insertDeviceSend(data) {
//     return postJSONData("/bd2DeviceSend/createDevice", data)
// }

//新增设备
export function insertDevice(data) {
    return postJSONData("/bd2Device/createDevice", data)
}

//解绑设备
export function unbingDevice(data) {
    return postData("/ship/unBingBd2Device", data)
}

//报废设备
export function scrapBd2Device(data) {
    return postData("/bd2Device/scrapBd2Device", data)
}

//修改设备发货
// export function updateDeviceSend(data) {
//     return postJSONData("/bd2DeviceSend/modifyDevice", data)
// }
//修改设备
export function updateDevice(data) {
    return postJSONData("/bd2Device/modifyDevice", data)
}

//新增设备
export function insertShip(data) {
    return postJSONData("/ship/installShipDevice", data)
}


//新增安装信息
export function insertShipInstall(data) {
    return postJSONData("/ship/installShipDevice", data)
}

//新增安装信息
export function updateShipInstall(data) {
    return postJSONData("/ship/updateShipInstall", data)
}

//删除船舶
export function deleteShip(data) {
    return postData("/ship/deleteShip", data)
}

//查询船舶列表(实际是多表联查   包含船的信息   设备信息  北斗信息)
export function selectShipDetails(data) {
    return postData("/ship/getShipList", data)
}
//审核成功
export function auditingShip(data) {
    //
    return postData("/ship/agreeShipApprove", data)
}
//审核失败
export function refuseAuditingShip(data) {
    return postData("/ship/refuseShipApprove", data)
}
//多个审批成功
export function multipleAuditingShip(data){
    return postJSONData("/ship/agreeShipApproveList",data)
}
//批量审批拒绝
export function refuseMultipleAuditingShip(data){
    return postJSONData("/ship/refuseShipApproveList",data)
}
//修改船舶详情
export function updateShipDetail(data) {
    return postJSONData("/updateShipDetail", data)
}
//绑定设备
export function bindShipDevice(data) {
    return postJSONData("/bindShipDevice", data)
}
//新增重点区域监控
export function insertShipMonitorInfo(data) {
    return postJSONData("/insertShipMonitorInfo", data)
}
//重点监控区域查询
export function selectShipMonitorInfoObscure(data) {
    return postData("/selectShipMonitorInfoObscure", data)
}
//删除重点监控区域
export function deleteShipMonitorInfo(data) {
    return postData("/deleteShipMonitorInfo", data)
}
/*
 *@functionName: selectRangeQuery
 *@description: { 航程统计查询 }
 *@interface:{ http://localhost:8201/selectRangeQuery }
 *@params: {
 *ParamsName | MustOption |     Type     |      Explain
 *@shipName:       否	        string	        船舶名称
 *@startTime:      是	        string	        查询开始时间
 *@endTime:        是	        string	        查询结束时间
 *}
 *@author: ZDong
 *@date: 2020-04-07 10:16:46
 *@version: V1.0.0
*/
export function selectRangeQuery(data) {
    console.log('轨迹查询接口入参---------',data);
    return postJSONData("/ship/getBdPosionList", data)
}

//黑名单管理查询
export function register(data){
    return postJSONData("/register",data)
}
//黑名单管理查询
export function selectShipBlackListObscure(data){
    return postData("/selectShipBlackListObscure",data)
} 
//删除黑名单
export function deleteShipBlackListInfo(data){
    return postData("/deleteShipBlackListInfo",data)
} 
//区域停靠点查询
export function getAreaInfoObscure(data){
    return postData("/getAreaInfoObscure",data)
} 
//大屏停靠点监控
export function homePortMonitor(data){
    return postData("/homePortMonitor",data)
} 
//大屏停靠点监控
export function queryAllCamera(data){
    return postData("/camera/queryAll",data)
} 
export function async_areaList(data){
    return postJSONData("/area/list",data)
} 
//大屏航程统计
export function selectRangeStatisticsToday(data){
    return postData("/selectRangeStatisticsToday",data)
} 
//大屏船舶统计
export function shipTypeStatistics(data){
    return postData("/shipTypeStatistics",data)
} 
//查询设备参数
export function selectDeviceParameter(data){
    return postData("/selectDeviceParameter",data)
} 
//查询设备参数
export function deleteDeviceParameter(data){
    return postData("/deleteDeviceParameter",data)
} 
//查询设备参数
export function updateDeviceParameter(data){
    return postData("/updateDeviceParameter",data)
} 
//修改黑名单
export function updateShipBlackListInfo(data){
    return postJSONData("/updateShipBlackListInfo",data)
} 
//用户查询
export function selectUser(data){
    return postData("/user/getUserList",data)
}
//用户删除
export function deleteUserById(data){
    return postData("/user/deleteUserPost",data)
}
//修改密码
export function updatePwd(data){
    return postData("/user/resetPassword",data)
} 
//用户新增
export function insertUser(data){
    return postJSONData("/user/createUser",data)
} 
//用户修改
export function updateUser(data){
    return postJSONData("/user/modifyUser",data)
}
//查询北斗2代卡列表
export function selectBd2CardList(data){
    return postData("/cardBd2/getCardBd2List",data)

}
//新增功能权限点
export function insertService(data){
    return postJSONData("/gbac/insertService",data)
} 
//查询界面
export function selectMenuList(data){
    return postData("/menu/getMenuList",data)
}
//新增菜单界面
export function addMenu(data){
    return postJSONData("/menu/createMenu",data)
}
//修改菜单界面
export function updateMenu(data){
    return postJSONData("/menu/modifyMenu",data)
}
//删除界面
export function deleteMenu(data){
    return postData("/menu/deleteMenu",data)
}

//----
export function insertPermission(data){
    return postJSONData("/gbac/insertPermission",data)
} 
//新增资源
export function insertRgroup(data){
    return postJSONData("/gbac/insertRgroup",data)
} 
//新增用户组
export function insertUgroup(data){
    return postJSONData("/gbac/insertUgroup",data)
} 
//修改功能权限点
export function updateService(data){
    return postJSONData("/gbac/updateService",data)
} 
//修改界面
export function updatePermission(data){
    return postJSONData("/gbac/updatePermission",data)
} 
//修改权限
export function updateRgroup(data){
    return postJSONData("/gbac/updateRgroup",data)
} 
//修改用户组
export function updateUroup(data){
    return postJSONData("/gbac/updateUroup",data)
} 
// 绑定资源和资源组
export function bindRgroupRelation(data){
    return postJSONData("/gbac/bindRgroupRelation",data)
} 
//节点层级树查询
export function getServiceTree(data){
    return postData("/menu/getMenuTree",data)
    // return postData("/role/getRoleList",data)
}
//权限查询菜单层级树所有
export function getMenuTree(data){
    return postData("/menu/getMenuCheckTree",data)
    // return postData("/userRole/getRoleIdsByUserId",data)
}
//资源层级树
export function getResCardRole(data){
    return postJSONData("/role/bingCardRole",data)
}
export function unbindCardDevice(data) {
    return postJSONData("/bd2Device/unBingBd2Device", data)
}
/*
*
*
*
*
*
*
*
*
*
* 用户树形菜单
*
*
*
*
*
*
*
*
* */

//角色列表
export function selectRoleDetails(data) {
    return postData("/role/getRoleList", data)
}
export function getRoleList(data) {
    return postData("/role/getRoleCheck", data)
}
//getUgroupTree
export function getUgroupTree(data) {
    return postData("/role/getRoleList", data)
}
//删除角色
export function deleteRoleById(data){
    return postData("/role/delRole",data)
}
//新增角色
export function insertRole(data){
    return postJSONData("/role/createRole",data)
}
//修改角色
export  function updateRole(data){
    return postJSONData("/role/modifyRole",data)
}
//批量删除用户组<<<<<
export function deleteUgroupByIds(data){
    return postData("/role/delRole",data)
}
//绑定界面
export function bindRolePermission(data){
    return postJSONData("/menuRole/bingMenuRole",data)
}
//绑定资源
export function bindRes(data){
    return postJSONData("/gbac/bindRgroup",data)
}
//界面层级树删除界面
export function deletePermissionById(data){
    return postData("/gbac/deletePermissionById",data)
} 
//权限层级树删除界面
export function deleteRgroupById(data){
    return postData("/gbac/deleteRgroupById",data)
} 
//权限层级树删除用户组
export function deleteUgroupById(data){
    return postData("/gbac/deleteUgroupById",data)
} 

//节点层级树批量删除
export function deleteServiceByIds(data){
    return postData("/gbac/deleteServiceByIds",data)
} 
//界面层级树批量删除
export function deletePermissionByIds(data){
    return postData("/gbac/deletePermissionByIds",data)
} 
//权限层级树批量删除
export function deleteRgroupByIds(data){
    return postData("/gbac/deleteRgroupByIds",data)
} 
//绑定功能权限
export function bindServiceUgroup(data){
    return postData("/gbac/bindServiceUgroup",data)
}
//绑定资源
export function bindRgRole(data){
    return postJSONData("/regionRole/bingRegionRole",data)
}
//绑定用户权限
export function bindUserRole(data){
    return postJSONData("/user/distributionUserRole",data)

} 
//查询对应资源组的绑定和未绑定
export function selectResourceByRgroupId(data){
    return postData("/gbac/selectResourceByRgroupId",data)
}
//查询对应资源组的绑定和未绑定
export function removeAlarm(data){
    return postData("/removeAlarm",data)
} 
//查询违规记录
export function enforcement(data){
    return postData("/law/enforcement/list",data)
} 
//查询违规记录
export function mineShipRegionStatistics(data){
    return postData("/mineShipRegionStatistics",data)
} 
//大屏报警
export function mineAlarmStatistics(data){
    return postJSONData("/mineAlarmStatistics",data)
} 
// 设备
//设备查询
export function selectDeviceParameterAndStatus(data){
    return postJSONData("/hczd/selectDeviceParameterAndStatus",data)
} 
// 确定指令下发
export function batchCommandSend(data){
    return postData("/hczd/batchCommandSend", data)
}

// 获取固件名称
export function queryFirmwareName(data){
    return postJSONData("/hczd/queryFirmwareName", data)
}

// 查固件版本
export function queryVersionByName(data){
    return postJSONData("/hczd/queryVersionByName", data)
}

// 确认固件升级
export function firmwareUpgrade(data){
    return postJSONData("/hczd/firmwareUpgrade", data)
}

// 固件升级状态列表
export function deciceUpgradeStatus(data){
    return postJSONData("/hczd/deciceUpgradeStatus", data)
}

// 固件列表查询
export function queryFirmwareList(data){
    return postData("/hczd/queryFirmwareList", data)
}
//指令查询
export function queryCommand(data){
    return postJSONData("/hczd/queryCommand", data)
}
//指令参数查询
export function queryParameter(data){
    return postData("/hczd/queryParameter", data)
}
// 删除固件列表
export function deleteFirmware(data){
    return postData("/hczd/deleteFirmware", data)
}

// 上传
export function UploadFirmware(data){
    return postFormData("/hczd/uploadFirmware", data)
}
//导入发货信息
// export function importDeviceSend(data){
//     return postFormData("/bd2DeviceSend/importBd2DeviceSend", data)
//     // return postFormData("/bd2Device/importBd2Device", data)
// }
//导入设备信息
export function importDevice(data){
    return postFormData("/bd2Device/importBd2Device", data)
}
//导入船只Excel
export function importShips(data){
    return postFormData("/ship/importShip", data)
}
//设备管理 设备参数及配置 设备类型筛选
export function selectDeviceType(data){
    return postData("/hczd/selectDeviceType", data)
}
//设备参数查询，通过id
export function selectDeviceParameterById(data){
    return postData("/hczd/selectDeviceParameterById", data)
}
//批量设备参数设置
export function batchUpdateParameters(data){
    return postJSONData("/hczd/batchUpdateParameters", data)
}
//固件升级状态列表查询
export function deviceUpgrade(data){
    return postData("/hczd/deviceUpgradeStatus", data)
}
//固件升级状态历史查询
export function upgradeHistory(data){
    return postData("/hczd/upgradeHistory", data)
}
//查询固件名是否重复
export function queryFileName(data){
    return postData("/hczd/queryFileByName", data)
}
//查询固件名称
export function queryFirmwareVersionName(data){
    return postData("/hczd/queryFirmwareVersionName", data)
}
//根据固件名称查询固件版本
export function queryFirmwareVersionInfo(data){
    return postData("/hczd/queryFirmwareVersionInfo", data)
}
//查询固件版本是否重复
export function queryFirmwareByVersion(data){
    return postData("/hczd/queryFirmwareByVersion", data)
}
//发送设备参数查询指令接口
export function queryParameters(data){
    return postJSONData("/hczd/queryParameters", data)
}
//密码确认
export function secondCheck(data){
    return postData("/system/secondCheck ", data)
}
//解锁
export function updateLoginCount(data){
    return postData("/gbac/updateLoginCount", data)
}
//查询短信
export function getMessage(data) {
    return postJSONData("/web/getMessage", data);
}
//短信发送接口
export function sendMessage(data) {
    return postData("/sendMessage", data);
}
//查询北斗卡号
export function cardNumTypefastSelectShip(data) {
    return postData("/app/fastSelectShip", data);
}
//查询最新原始报文列表
export function selectOriginalMsg(data){
    return postData("/originalLast/getOrginalLastList",data)
}
//查询短信
export function selectSms(data){
    return postData("/sms/getSmsList",data)
}

// 导出北斗卡导入模板
export function exportBd2CardTemplate(data) {
    return postData("/cardBd2/exportBd2CardTemplate", data);
}

// 查询设备类型
export function selectDeviceTypeList(data){
    return postData("/deviceType/getDeviceTypeList",data)
}
// 增加设备类型
export function addDeviceType(data){
    return postJSONData("/deviceType/createDeviceType",data)
}
// 修改设备类型
export function updateDeviceType(data){
    return postJSONData("/deviceType/modifyDeviceType",data)
}
// 删除设备类型
export function delDeviceType(data){
    return postData("/deviceType/deleteDeviceType",data)
}
// 查询离线信息
export function selectOfflineInfo(data){
    return postData("/report/getOfflineInfo",data)
}

// 查询北斗操作日志
export function selectBdCardLog(data){
    return postData("/bdcardLog/getBd2CardLog",data)
}

// 导出原始操作日志
// export function exportOriginalList(data){
//     return postData("/originalLast/exportOriginalList",data)
// }


// 查询有哪些指挥机
export function selectAllCmds(data) {
    return postData("/cmd/getAllCmds", data);
}

// 查询某指挥机的卡号集合
export function selectCmdCards(data) {
    return postData("/cmd/getCards", data);
}


// 查询指挥机发送情况
export function selectCmdReport(data) {
    return postData("/cmd/getCmdReport", data);
}

// 查询指挥机发送情况详情
export function getCmdReportDetail(data) {
    return postData("/cmd/getCmdReportDetail", data);
}

// 查询推送情况
export function selectClientPush(data) {
    return postData("/clientPush/getClientPush", data);
}

// 查询推送客户配置情况
export function selectPushClientManage(data) {
    return postData("/clientPush/getPushClientManageList", data);
}

// 查询指定时间范围内某指挥机的发送成功和失败次数
export function selectCmdStatistics(data) {
    return postData("/cmd/getCmdStatistics", data);
}

// 查询指定时间范围内某指挥机某卡号的发送成功和失败次数
export function selectCardStatistics(data) {
    return postData("/cmd/getCardStatistics", data);
}
// 北斗II卡导出
export function exportBdCardList(data) {
    return postData("/cardBd2/exportBdCardList", data);
}
// 北斗综合明细查询导出
export function exportBdInfoList(data) {
    return postJSONData("/ship/exportBdInfoList", data);
}
// 船舶信息导出
export function exportShipList(data){
    return postData("/ship/exportShipList",data);
}
// 导出船舶管理模板
export function exportShipTemplate(data) {
    return postData("/cardBd2/exportBdCardList", data);
}
// 设备信息导出
export function exportDevilceList(data){
    return postData("/bd2Device/exportDevilceList",data);
}
// 原始信息查询导出
export function exportOriginalList(data){
    return postData("/originalLast/exportOriginalList",data);
}

// 添加推送客户
export function addClientPush(data){
    return postJSONData("/clientPush/createClientPush",data);
}

// 修改推送客户
export function updateClientPush(data){
    return postJSONData("/clientPush/modifyClientPush",data);
}

// 删除推送客户
export function delClientPush(data){
    return postData("/clientPush/delClientPush",data);
}

export let PsInterface = async (value, successCallBack = () => { }, errorCallBack = () => { }) => {
    console.log(value, 'PsInterface111')
    let params = {};
    if (value) {
        params = { ...value };
    }
    if (value && value.params && value.params.isExport) {
        let data = await PsInterface(params).then((res) => {
            if (res.ok) {
                res.blob().then((blob) => {
                    var a = document.createElement('a');
                    var url = window.URL.createObjectURL(blob);
                    var filename = "列表数据.xlsx";
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
            } else {
                return Promise.reject("导出失败");
            }

            // let cloneRes = res.clone();
            // try {
            //     let d = cloneRes.json();
            //     d.then((data) => {
            //     });
            // } catch (ex) {

            // }
            // return res;
        })
    } else {
        let antd = require('antd')
        let data = await PsInterface(params)
            .then(res => { return res.json() }).catch(ex => { });
        if (data && data.code === 200) {
            if (data) {
            }
            successCallBack(data, params);
        } else if (data && data.code === 500) {
            console.log("后端返回错误码500，登出")

            antd.message.error(data.msg, 2)

            errorCallBack(data, params)
        } else {
            antd.message.error('资源数据获取失败，请检查网络环境是否正常，再次重试....' + data.msg, 2)
            console.log('资源数据获取失败，请检查网络环境是否正常，再次重试....')
            errorCallBack(data, params)
        }
    }
}
// 7天统计信息查询
export function select7DaysDataMsg(data) {
    return postJSONData("/postionCount/getBdPostionInfo", data);
}
