import React, { Component } from "react";
import "./home.css";
import { exportExcel } from "xlsx-oc";
// import TrackQuerySelectArea from '../TrackQuery/TrackQuerySelectArea.js';
import TrackQuery from "../TrackQuery/TrackQuery.js";
import config_JSON from ".././default_JSON/Default_map.json";
import {
  Input,
  Tabs,
  Tree,
  Select,
  Icon,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Tooltip,
} from "antd";
import DetailModal from "../../componts/DetailModal/DetailModal";
import PsTree from "../../componts/PsTree/PsTree";
import FuzzySearch from "../../componts/FuzzySearch/FuzzySearch";
import moment from "moment";
import RealTimeDetail from "./Toolkit/cameraTool/RealTimeDetail";
import {
  fastSelectShip,
  selectShip,
  getTree,
  allshipPos,
  selectShipMonitorInfoObscure,
  specialRegionStatistics,
  homePortMonitor,
  async_areaList,
  selectShipBlackListObscure,
  searchByPolygonAndCircle,
  queryAllCamera,
  getLayerInfo,
  selectCardDetails,
} from "../../data/dataStore";
import MyComponentFactory from "./Toolkit/MyComponentFactoryPackage/MyComponentFactory";
import ComponentWorkspace from "./Toolkit/MyComponentFactoryPackage/ComponentWorkspace";
import ShipDetail from "../ShipDetail/ShipDetail";

const { Search } = Input;
const { TreeNode, DirectoryTree } = Tree;
const { Option } = Select;
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1733642_5j6blefdkn7.js",
});
const mapStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};
//批量引入图片
// const requireContext = require.context("../../assets/img", true, /^\.\/.*\.png$/);
// const images = requireContext.keys().map(requireContext);
const maths = {
  menu: "",
  menuLight: "",
  locus: "",
  locusLight: "",
  port: "",
  portLight: "",
  mapSwitch: "",
  mapSwitchLight: "",
  tool: "",
  toolLight: "",
  point: "",
  pointLight: "",
  ellipse: "",
  rectangle: "",
  range: "",
  polygon: "",
  area: "",
  gps: "",
  cameraBlue: "",
  cameraRed: "",
  active: "",
};
Object.keys(maths).forEach(
  (item) => (maths[item] = require("../../assets/img/" + item + ".png"))
);

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
class Home extends ComponentWorkspace {
  constructor(props, context) {
    super(props, context);
    this.state = {
      title_onClick_showCarmeraInfoData: false,
      controlLayerData: false,
      toolArr: [
        {
          img: maths.locus,
          act: maths.locusLight,
          name: "轨迹查询",
          click: false,
          key: "locus",
        },
        {
          img: maths.port,
          act: maths.portLight,
          name: "停靠点监控",
          click: false,
          key: "port",
        },
        {
          img: maths.point,
          act: maths.pointLight,
          name: "重点区域管理",
          click: false,
          key: "point",
        },
        {
          img: maths.mapSwitch,
          act: maths.mapSwitchLight,
          name: "地图开关",
          click: false,
          key: "mapSwitch",
        },
        {
          img: maths.tool,
          act: maths.toolLight,
          name: "工具箱",
          click: false,
          key: "tool",
        },
      ],
      text_Measure_DropdownButton: "测量工具",
      text_Measure_DropdownButton_search: "搜索工具",
      menuShow: true, //控制树形的显示和隐藏
      ulshow: false, //控制搜索到的显示和隐藏
      name: "",
      toolShow: false,
      switchShow: false,
      pointShow: false,
      portShow: false,
      locusShow: false,
      switchValue: undefined,
      layerTitle: undefined,
      areaShow: false,
      ulArr: [],
      switchArr: [
        { title: "地图信息" },
        // { name: '卫星遥感图', key: 'weixing', optionArr: [{ name: '遥感图-2017', key: 2017 }, { name: '遥感图-2018', key: 2018 }, { name: '遥感图-2019', key: 2019 }] },
        {
          name: "影像底图",
          key: "default_WMTS_tdRemoteSensing",
          target: "default_WMTS_tdRemoteSensing",
          defaultChecked: false,
          // optionArr: [
          //     { name: '遥感影像底图', key: 'default_WMTS_tdRemoteSensing', target: 'default_WMTS_tdRemoteSensing', defaultChecked: false }
          // ]
        },
        // { name: '航拍图', key: 'hangpai', optionArr: [{ name: '航拍图-2017', key: 'hp2017' }, { name: '航拍图-2018', key: 'hp2018' }, { name: '航拍图-2019', key: 'hp2019' },] },
        { title: "图层信息" },
        {
          name: "聚合图层",
          key: "layers_ship_all_bigZoom1_Cluster",
          groupName: "layers_ship",
          isControlAllGroup: false,
          target: "layers_ship_all_bigZoom1_Cluster",
          defaultChecked: true,
          reverseControl: {
            targets: ["layers_ship_all_bigZoom1"],
            groupName: "layers_ship",
          },
        },
        {
          name: "人员船舶图层",
          key: "layers_ship",
          groupName: "layers_ship",
          isControlAllGroup: true,
          target: [
            "layers_ship_all_smallZoom",
            "layers_ship_all_bigZoom",
            "layer_selectShip",
            "layers_ship_all_bigZoom1_Cluster",
          ],
          defaultChecked: true,
        },
        {
          name: "摄像头图层",
          key: "layers_queryAllCamera",
          target: "layers_queryAllCamera",
          defaultChecked: true,
        },
        {
          name: "停靠点图层",
          key: "target_mapLayer_Polygon_gangkou",
          target: "target_mapLayer_Polygon_gangkou",
          defaultChecked: true,
        },
        {
          name: "重点区域图层",
          key: "target_mapLayer_Polygon_pointArea",
          target: "target_mapLayer_Polygon_pointArea",
          defaultChecked: true,
        },
        {
          name: "电子围栏图层",
          key: "target_mapLayer_Polygon_dianzi",
          target: "target_mapLayer_Polygon_dianzi",
          defaultChecked: true,
        },
        {
          name: "SOS船舶报警",
          key: "layer_shipAlarm",
          interval: {
            intervalName: "interval_flashingMask_alert",
            targets: ["layer_tileLayer_mask"],
            intervalCallBackFun: this.getInterval_flashingMask_alert,
          },
          target: "layer_shipAlarm",
          groupName: "layers_ship",
          defaultChecked: false,
          disabled: false,
        },
        // { name: '停靠点图层', key: 'target_mapLayer_Polygon_gangkou', optionArr: [{ name: '全选7个', key: '' }, { name: '第一个', key: '1' }, { name: '第2个', key: '2' },] },
        // { name: '重点区域图层', key: 'target_mapLayer_Polygon_pointArea', optionArr: [{ name: '全部停靠点', key: '' }, { name: '吴中港', key: 'wz' }, { name: '虎口', key: 'hk' },] },
        // { name: '电子围栏图层', key: 'target_mapLayer_Polygon_dianzi', optionArr: [{ name: '重点区域', key: '' }, { name: '不知道', key: 'dont' }, { name: '无所谓', key: 'noway' },] },
      ],
      loading: false,
      treeData: [],
      allship: [],
      mapArr: [],
      pointDetailData: {},
      pointData: [],
      portData: [],
      blackData: [],
      blackName: "",
      pointArr: [],
      pointName: "",
      shipShow: true,
    };
    this.layersStatus_history = {};
    let mp3File = require("./mp3/radar_alarm.mp3");
    this.mp3_radar_alarm = new Audio(mp3File);
    this.mp3_radar_alarm.loop = true; //播放 mp3这个音频对象
    this.getTree = this.getTree.bind(this);
  }
  componentDidMount() {
    this.getLayerInfo();
    // this.getTree();
    // this.specialRegionStatistics();
    // this.homePortMonitor();
    // this.async_areaList({ areaType: 300002 });
    // this.queryAllCamera();
    // this.selectShipMonitorInfoObscure();
    // this.selectShipBlackListObscure();
    // this.allshipPos(true);

    console.log('获取船名与北斗卡号',this.props)
    console.log('获取船名与北斗卡号',this.props.bdInfo?.cardNumber)
    //this.props.bdInfo?.cardNumber&&this.selectCardDetails({cardNumber: [this.props.bdInfo?.cardNumber],shipName: this.props.bdInfo?.shipName})
    this.props.bdInfo?.cardNumber&&this.selectCardDetails({cardNumber: [this.props.bdInfo?.cardNumber], pageNo: 1,
      //此处可改变每页数据显示的大小  pagination.pageSize=100
      pageSize: 10})



    let obj = this;
    this.interval_allshipPos = setInterval(function () {
      let isOpen = false;
      obj.state.switchArr.map((item) => {
        if (item.key == "layers_ship") {
          isOpen = item.defaultChecked;
        }
      });
      // isOpen && obj.allshipPos();
    }, 180000);
    this.state.componentRepository.map.createGraticuleLayer({
      opacity: 1.0,
      targetSize: 120,
      maxLines: 200,
      // callback: (graticuleLayer) => {
      //     thisObj.createThinningGrid(graticuleLayer);
      // }
    });

    let homeCenterPoint = JSON.parse(localStorage.getItem("homeCenterPoint"));
    if (homeCenterPoint) {
      let endZoom = 14;
      let noWorkingZoom = 13;
      // this.state.componentRepository.map.changeZoomAndCenter_point(homeCenterPoint, endZoom, noWorkingZoom);
    }
  }
  componentWillUnmount() {
    this.mp3_radar_alarm.pause();
    clearInterval(this.interval_allshipPos);
    clearInterval(this.interval_flashingMask_alert);
    this.state.componentRepository.map.clearAllListener();
  }

  getInterval_flashingMask_alert = () => {
    // let obj = this;
    // let tileImg = require("./img/mask_red_map.png")
    // let params = {
    //     url: tileImg,
    //     visible: false,
    //     key: "layer_tileLayer_mask",
    //     opacity: 0.3,
    //     target: 'layer_tileLayer_mask',
    //     groupName: 'layers_ship',
    // }
    // this.state.componentRepository.map.addTile(params);
    // if (typeof (this.layersStatus_history[params.target]) == 'undefined') {
    //     this.layersStatus_history[params.target] = params;
    // }else{
    //     params = this.layersStatus_history[params.target];
    // }
    // let params_alert = {
    //     ...params
    // }
    // let interval_flashingMask_alert;
    // if (this.layersStatus_history[params.target].visible) {
    //     interval_flashingMask_alert = setInterval(function () {
    //         if (obj.state.componentRepository) {
    //             if (params_alert.visible) {
    //                 obj.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layer_tileLayer_mask']);
    //                 params_alert.visible = false;
    //             } else {
    //                 obj.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layer_tileLayer_mask']);
    //                 params_alert.visible = true;
    //             }
    //             if (obj.layersStatus_history['layer_tileLayer_mask']) {
    //                 obj.layersStatus_history['layer_tileLayer_mask'].visible = params_alert.visible;
    //             }
    //         }
    //     }, 500);
    // }
    // return interval_flashingMask_alert;
  };


  //根据图层数据接口的数据，重新装配地图配置文件
  assembling_map_defaultJSON = (data) => {
    /*
    *
    *
    *
    *转化成文件内部JSON对象
    *
    *
    *
    * */

    const mapJSON = {
      "map_componentConfig": {
        "type": "Map",
        "attribute": {
          "target": "map_home",
          "center": "[100.21214, 31.19858]",
          "zoom": "10",
          "minZoom": "5",
          "maxZoom": "26",
          "height": "94vh",
          "layers":{
            "wms":[
              {
                "optionalParams":{
                  "visible":true,
                  "zIndex":"11"
                },
                "target":"yuqu",
                "layerName":"yuqu:yuqu",
                "url":"http://pushi.ubinavi.com.cn:26688/geoserver/yuqu/wms?service=WMS"
              }
            ]
          }
        }
      }
    }
    //使用内部JSON对象初始化地图中心点和相关参数和属性
    let attribute = mapJSON.map_componentConfig.attribute;
    let layers = attribute.layers;
    if (typeof layers == "undefined" && data.length > 0) {
      layers = {
        wms: [],
      };
    } else if (typeof layers.wms == "undefined") {
      layers = {
        ...layers,
        wms: [],
      };
    }
    data.map((item, index) => {
      item.children.map((itemC, indexC) => {
        layers.wms.push({
          optionalParams: {
            visible: false,
            zIndex: "13",
          },
          target: itemC.target,
          layerName: itemC.layerName,
          url: itemC.layerUrl,
        });
      });
    });
    mapJSON.map_componentConfig.attribute.layers = layers;
    this.state.componentRepository.map.addWMSLayer(layers);
  };

  //根据图层数据接口的数据，重新装配图层数组switchArr
  assembling_layerInfoArr = (data) => {
    let switchArr = this.state.switchArr;
    data.map((item, index) => {
      let layerMenu = {
        name: item.name,
        key: item.unique + "home",
        defaultChecked: false,
        optionArr: [],
      };
      if (item.children && item.children.length > 0) {
        item.children.map((itemC, indexC) => {
          let layer = {
            name: itemC.layerTitle,
            key: itemC.target,
            target: itemC.target,
            defaultChecked: false,
          };

          layerMenu.optionArr.push(layer);
        });
      }
      switchArr.splice(1, 0, layerMenu);
    });
    if (switchArr.length != this.state.switchArr.length) {
      this.setState({
        switchArr,
      });
    }
  };
  // 图层资源（遥感和航拍）
  async getLayerInfo() {
    let params = {};
    let data = await getLayerInfo(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      let arr = [];
      let olyArrs = [
        {
          id: 3,
          layerCategory: 303002,
          layerName: "RS:taihu2",
          layerTitle: "2020年太湖影像图-2",
          layerUrl: "http://221.181.88.134:6706/geoserver/RS/wms?service=WMS",
          releaseType: 302903,
          target: "taihu2",
        },
      ];

      if (data.data) {
        data.data.map((item) => {
          if (item.name == "遥感影像图") {
            item.children = item.children.concat(olyArrs);
            // item.children = item.children.reverse()

            console.log(item.children, "12qweqe13123");
          }
        });
      }

      console.log(data.data, arr, "1213123");

      this.setState(
        {
          data_layersInfo: data.rows,
        },
        () => {
          this.assembling_map_defaultJSON(this.state.data_layersInfo);
          this.assembling_layerInfoArr(this.state.data_layersInfo);
        }
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  close_videoDetailModal() {
    this.setState({
      videoDetailModal: false,
    });
  }
  setTitle_showCarmeraInfoData = (name) => {
    if (name) {
      this.setState({
        title_onClick_showCarmeraInfoData: name,
      });
    }
  };
  //  点击跳出Modal详情事件
  onClick_showCarmeraInfo = (data) => {
    //  RealTimeDetail
    console.log(data, "------------------------onClick_showCarmeraInfo----");
    let onClick_showCarmeraInfoData = data;
    let title_onClick_showCarmeraInfoData =
      this.state.title_onClick_showCarmeraInfoData;
    console.log(
      onClick_showCarmeraInfoData,
      "---------title_onClick_showCarmeraInfoData--------------"
    );
    if (data.id) {
      title_onClick_showCarmeraInfoData = data.name;
      onClick_showCarmeraInfoData = {
        [data.id]: data,
      };
    } else {
      if (onClick_showCarmeraInfoData) {
        Object.keys(onClick_showCarmeraInfoData).map((key, index) => {
          if (index == 0) {
            title_onClick_showCarmeraInfoData =
              onClick_showCarmeraInfoData[key].name;
          }
        });
      }
    }
    this.setState({
      videoDetailModal: true,
      onClick_showCarmeraInfoData: onClick_showCarmeraInfoData,
      title_onClick_showCarmeraInfoData: title_onClick_showCarmeraInfoData,
    });
  };

  //通知提醒框函数
  openNotification = (placement, data) => {
    let description_text = (
      <div>
        <Row>
          <Col span={8}>所属区域:</Col>
          <Col span={14}>{data && data.harborName}</Col>
        </Row>
        <Row>
          <Col span={8}>船舶所属人:</Col>
          <Col span={14}>{data && data.owerName}</Col>
        </Row>
        <Row>
          <Col span={8}>错误信息:</Col>
          <Col span={14}>没有船舶详细信息！！！</Col>
        </Row>
      </div>
    );
    notification.error({
      message: (data ? data.shipName : "未知") + " ，没有详细信息！！！",
      description: description_text,
      placement,
    });
  };

  // 圈选搜索
  async searchByPolygonAndCircle(value) {
    let params = {
      ...value,
    };
    let data = await searchByPolygonAndCircle(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      console.log(
        data.data,
        "--------------searchByPolygonAndCircle-----------------"
      );
      // if (type === 'tree') {//地图点击和树点击效果一样，暂时不需要区分
      this.setState(
        {
          data_Table_searchArea: data.data,
          areaShow: true,
        },
        () => {}
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  // 搜索船名和标签id
  async fastSelectShip(value, type) {
    let params = {
      shipNameOrDeviceId: value,
    };
    let data = await fastSelectShip(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      if (type === "select") {
        //地图点击和树点击效果一样，暂时不需要区分
        this.setState({
          ulArr: data.data,
          ulArrlb: data.data.lbsPositionList,
          ulshow: true,
          menuShow: true,
          loading: false,
        });
      } else {
        this.setState({
          ulshow: false,
          shipData: data.data[0],
          loading: false,
          // shipModal:true
        });
      }
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }

  onClick_map_point = (datas) => {
    let homeCenterPoint = {};
    if (datas && datas.length > 0 && datas[0]) {
      homeCenterPoint = {
        lat: datas[0].lat,
        lon: datas[0].lon,
      };
    }
    localStorage.setItem("homeCenterPoint", JSON.stringify(homeCenterPoint));

    if (datas && datas.length > 0 && datas[0].isCamera) {
      this.onClick_showCarmeraInfo(datas[0]);
    } else if (datas && datas.length > 0 && datas[0].isShip) {
      // let shipImg = require('./img/ship_selected_blue.png');
      // let shipImgSelectedLawEnforcement = require('./img/ship_selected_LawEnforcement.png');
      // if (datas[0].shipType==302403) {
      //     datas[0].pointImg = shipImgSelectedLawEnforcement;
      // }else{
      //     datas[0].pointImg = shipImg;
      // }
      // let params = {
      //     target: 'layer_selectShip',
      //     zIndex: 15,
      //     clear: true,
      //     visible:true,
      //     groupName: 'layers_ship'
      // }
      // // this.state.componentRepository.map.click_addPointAnimation(this.state.shipData, params);
      // this.state.componentRepository.map.showMap_addPoints([datas[0]], params);
      // let isOpen = false;
      // let switchArr = this.state.switchArr;
      // switchArr.map(item => {
      //     if (item.key == 'layers_ship') {
      //         isOpen = item.defaultChecked;
      //     }
      // })
      // if (isOpen) {
      //     this.state.componentRepository.map.changeZoomAndCenter_point(datas[0],18);
      // }
      //   let params = {
      //       target: 'layer_selectShip',
      //       zIndex: 15,
      //       clear: true,
      //       visible:true,
      //       groupName: 'layers_ship'
      //   }
      //   this.state.componentRepository.map.click_addPointAnimation(datas[0], params);
      this.selectShip(datas[0].deviceId, undefined, datas[0]);
    }
  };
  onClick_map_empty = () => {
    return
    this.state.componentRepository.map.closeLayerVisibleByGroupName(
      "layers_ship",
      ["layer_selectShip", "layer_selectShip_active"],
      true
    );
    if (this.layersStatus_history["layer_selectShip"]) {
      this.layersStatus_history["layer_selectShip"].visible = false;
    }
    // this.setState({
    //   shipModal: false,
    // });
  };

  // 模糊搜索
  async selectShip(value, type, pointData) {
    let params = {
      shipOrDeviceId: value,
    };
    let data = await selectShip(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      let objectName;
      if (pointData) {
        data.data[0].lbsPositionList[0]={
            ...data.data[0].lbsPositionList[0],
            ...pointData,
        }
      }
      if (
        data.data[0] &&
        data.data[0].lbsPositionList &&
        data.data[0].lbsPositionList[0] &&
        data.data[0].lbsPositionList[0].lon != null &&
        data.data[0].lbsPositionList[0].lat != null
      ) {
        if (data.data[0].lbsPositionList) {
          data.data[0] = {
            ...data.data[0].lbsPositionList[0],
            ...data.data[0],
            ...pointData,
          };
        }
        // let shipImg = require('./img/ship_' + data.data[0].shipType + '.png');
        // data.data[0].pointImg = shipImg;
        data.data[0].pointImg = data.data[0].url
          ? data.data[0].url
          : "../../assets/img/mxfc.png";
        this.setState(
          {
            ulshow: false,
            shipData: data.data[0],
            loading: false,
            // shipModal:true
          },
          () => {
            console.log(this.state.shipData, "shipData");
            params = {
              target: "layer_selectShip",
              zIndex: 15,
              clear: true,
              visible: true,
              groupName: "layers_ship",
            };
            if (this.layersStatus_history[params.target]) {
            } else {
              this.layersStatus_history = {
                ...this.layersStatus_history,
                [params.target]: params,
              };
            }
            this.setState({
              shipModal: true,
            });
            // this.state.componentRepository.map.click_addPointAnimation(this.state.shipData, params);
            let shipDataIDDict = this.state.shipDataIDDict;
            if (
              shipDataIDDict &&
              shipDataIDDict[this.state.shipData.objectName]
            ) {
              let shipDataOld = {
                ...shipDataIDDict[this.state.shipData.objectName],
              };
              if (shipDataOld) {
                // let shipImg = require('./img/ship_' + shipDataOld.shipType + '.png');
                // shipDataOld.pointImg = shipImg;
                shipDataOld.pointImg = shipDataOld.url
                  ? shipDataOld.url
                  : "http://221.181.88.134:8081/pjresource/wzbd/ship_302401.png";

                this.state.componentRepository.map.showMap_addPoints(
                  [shipDataOld],
                  params
                );
              }
            } else {
              this.state.componentRepository.map.showMap_addPoints(
                [this.state.shipData],
                params
              );
            }
            let isOpen = false;
            let switchArr = this.state.switchArr;
            switchArr.map((item) => {
              if (item.key == "layers_ship") {
                isOpen = item.defaultChecked;
              }
            });
            if (isOpen) {
              let endZoom = 19;
              let noWorkingZoom = 15;

              //添加选中框
              let { lon, lat } = this.state.shipData;

              let activeData = {
                lon,
                lat,
                pointImg: maths["active"],
              };

              let params_active = {
                target: "layer_selectShip_active",
                zIndex: 15,
                clear: true,
                visible: true,
                groupName: "layers_ship",
              };
              this.state.componentRepository.map.showMap_addPoints(
                [activeData],
                params_active
              );
              //

              this.state.componentRepository.map.changeZoomAndCenter_point(
                this.state.shipData,
                undefined,
                undefined
              );
            }
          }
        );
      } else {
        pointData.shipName = pointData.name;
        data.data[0] = {
          ...pointData,
        };
        this.openNotification("topLeft", data.data[0]);
      }
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }

  // 资源结构树
  async getTree() {
    let params = {
      typeResourceGroup: "100802",
    };
    let data = await getTree(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      this.setState({
        treeData: data.data,
      });
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  // 黑名单
  async selectShipBlackListObscure() {
    let params = {
      shipName: this.state.blackName,
      pageIndex: 1,
      pageSize: 99999,
    };
    let data = await selectShipBlackListObscure(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      console.log(data.data, "blackData");
      this.setState({
        blackData: data.data.items,
      });
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  // 重点监控
  async selectShipMonitorInfoObscure() {
    let params = {
      shipName: this.state.pointName,
      pageIndex: 1,
      pageSize: 99999,
    };
    let data = await selectShipMonitorInfoObscure(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      this.setState({
        pointArr: data.data.items,
      });
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  // 重点区域管理
  async specialRegionStatistics() {
    let params = {
      startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
      endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let data = await specialRegionStatistics(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      let children = [];
      data.data.children.map((item, index) => {
        if (item.geom) {
          item.geom = JSON.parse(item.geom);
          item.name = item.areaName;
          children = [...children, item];
        }
      });
      data.data.children = children;

      this.setState(
        {
          pointData: data.data.children,
        },
        () => {
          let params = {
            target: "target_mapLayer_Polygon_pointArea",
            zIndex: 11,
            color: "rgba(255,127,0,0.5)",
            isChangeZoomAndCenter: false,
          };
          this.state.componentRepository.map.showMap_DBData(
            this.state.pointData,
            params
          );
        }
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  // 停靠点管理
  async homePortMonitor() {
    let params = {
      startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
      endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let data = await homePortMonitor(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      let children = [];
      data.data.children.map((item, index) => {
        if (item.geom) {
          item.geom = JSON.parse(item.geom);
          item.name = item.areaName;
          children = [...children, item];
        }
      });
      data.data.children = children;
      this.setState(
        {
          portData: data.data.children,
        },
        () => {
          let params = {
            target: "target_mapLayer_Polygon_gangkou",
            zIndex: 11,
            color: "rgba(0,153,0,0.5)",
            isChangeZoomAndCenter: false,
          };
          this.state.componentRepository.map.showMap_DBData(
            this.state.portData,
            params
          );
        }
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  // 区域查询 （根据区域类型获取相应的区域 Ps：比如限定区300002）
  async async_areaList(value) {
    let params = value;
    params = {
      ...params,
      startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
      endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let data = await async_areaList(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      data.data.map((item, index) => {
        item.geom = JSON.parse(item.geom);
        item.name = item.areaName;
      });
      this.setState(
        {
          limitAreaData: data.data,
        },
        () => {
          let params = {
            target: "target_mapLayer_Polygon_dianzi",
            zIndex: 11,
            color: "rgba(249,225,3,0.5)",
            isChangeZoomAndCenter: false,
          };
          this.state.componentRepository.map.showMap_DBData(
            this.state.limitAreaData,
            params
          );
        }
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }

  //获取所有摄像头信息
  async queryAllCamera(value) {
    let params = {};
    if (value) {
      params = value;
    }
    let data = await queryAllCamera(params)
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data.code === 200) {
      console.log(data.data, "-----------queryAllCamera---------");
      let cameraImg = require("./img/cameraBlue.png");
      data.data.map((item, index) => {
        item.pointImg = cameraImg;
        item.isCamera = true;
        item.isClickPoint = true;
        item.name = item.cameraName;
      });
      this.setState(
        {
          data_queryAllCamera: data.data,
        },
        () => {
          params = {
            target: "layers_queryAllCamera",
            zIndex: 15,
            clear: true,
            visible: true,
            // groupName: 'layers_ship'
          };
          if (this.layersStatus_history[params.target]) {
          } else {
            this.layersStatus_history = {
              ...this.layersStatus_history,
              [params.target]: params,
            };
          }
          this.state.componentRepository.map.showMap_addPoints(
            this.state.data_queryAllCamera,
            params
          );
        }
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }

  onMove_map_empty = () => {
    this.state.componentRepository.map.closeLayerVisibleByGroupName(
      "groupName_overlay",
      ["target_overlay_mouseMove"]
    );
  };

  // 获取所有船的位置
  async allshipPos(isInit) {
    if (this.state.allship && this.state.allship.length > 0) {
      return;
    }
    let data = await allshipPos()
      .then((res) => {
        return res.json();
      })
      .catch((ex) => {});
    if (data && data.code == 200) {
      let shipDataIDDict = {};
      let demoAlarmShip = [];
      data.data.forEach((item, index) => {
        let location = {};
        location.lon = item.lon;
        location.lat = item.lat;
        item.location = location;
        item.oid = item.id;
        item.isClickPoint = true;
        item.name = item.objectName;
        item.isShip = true;
        item.pointTextColor = "#0066CC";
        // let shipImg = require('./img/ship_0.png');
        // let shipImg = require('./img/ship_' + item.shipType + '.png');
        item.pointImg = item.url
          ? item.url
          : "http://221.181.88.134:8081/pjresource/wzbd/ship_302401.png";

        if (item.emergencyStatus && item.emergencyStatus == 1) {
          item.pointColor = "red";
          demoAlarmShip = demoAlarmShip.concat(item);
          data.data.splice(index, 1);
        }
        // shipDataIDDict = {
        //     ...shipDataIDDict,
        //     [item.objectName]: item,
        // }
        shipDataIDDict[item.objectName] = item;
        data.data[index].mouseOverCallback = (values_) => {
          let id_selectedShowPop = document.createElement("div");
          id_selectedShowPop.setAttribute(
            "style",
            "width:150px;height:30px;background-color:#fff;text-align: center;padding: 5px;border-radius: 5px;position: relative;"
          );
          id_selectedShowPop.setAttribute("id", "id_selectedShowPop");
          let id_selectedShowPop_arrow = document.createElement("div");
          id_selectedShowPop_arrow.setAttribute(
            "style",
            "width:0px;height:0px;border-top: 10px solid rgba(255,255,255,0.9);border-left: 10px solid rgba(255,255,255,0.0);border-right: 10px solid rgba(255,255,255,0.0);position: absolute;"
          );
          id_selectedShowPop.innerHTML = values_.name;
          id_selectedShowPop.appendChild(id_selectedShowPop_arrow);
          let params_overlay = {
            target: "target_overlay_mouseMove",
            groupName: "groupName_overlay",
            div: id_selectedShowPop,
            coordinate: [values_.lon, values_.lat],
            offset: [-20, -20],
          };
          this.state.componentRepository.map.addOverlay_withDiv(params_overlay);
        };
      });
      if (demoAlarmShip.length > 0) {
        // this.interval_flashingMask_alert = this.getInterval_flashingMask_alert()
        let params = {
          target: "layer_shipAlarm",
          zIndex: 15,
          clear: false,
          groupName: "layers_ship",
          visible: true,
          // interval:
          // {
          //     intervalName: 'interval_flashingMask_alert',
          //     intervalCallBackFun: this.getInterval_flashingMask_alert,
          // },
        };
        this.state.componentRepository.map.changeZoomAndCenter_point(
          demoAlarmShip[0]
        );
        this.state.componentRepository.map.showMap_addPoints(
          demoAlarmShip,
          params
        );
        // demoAlarmShip.map((item, index) => {
        //     data.data = [
        //         ...data.data,
        //         item
        //     ]
        //     let params = {
        //         target: 'layer_shipAlarm',
        //         zIndex: 15,
        //         clear: false,
        //         groupName: 'layers_ship',
        //         visible: false,
        //         interval:
        //         {
        //             intervalName: 'interval_flashingMask_alert',
        //             intervalCallBackFun: this.getInterval_flashingMask_alert,
        //         },
        //     }
        //     this.state.componentRepository.map.showMap_addPoints([this.state.shipData], params);
        //     // this.state.componentRepository.map.init_addPointAnimation(item, params);
        //     if (this.layersStatus_history[params.target]) {
        //     } else {
        //         this.layersStatus_history = {
        //             ...this.layersStatus_history,
        //             [params.target]: params,
        //         }
        //     }
        // })
      } else {
        this.state.componentRepository.map.changeZoomAndCenter_point(
          data.data[0]
        );
      }
      this.setState(
        {
          allship: data.data,
          shipDataIDDict,
        },
        () => {
          this.state.componentRepository.map.clearAllListener();
          let params = {};
          let switchArr = this.state.switchArr;
          let isOpen = false;
          switchArr.map((item) => {
            if (item.key == "layers_ship") {
              isOpen = item.defaultChecked;
            }
          });
          if (
            isOpen &&
            this.state.shipData &&
            this.state.shipData.lbsPositionList &&
            this.state.shipData.lbsPositionList[0] &&
            this.state.shipData.lbsPositionList[0].lon != null &&
            this.state.shipData.lbsPositionList[0].lat != null
          ) {
            // this.state.componentRepository.map.click_addPointAnimation(
            //   this.state.shipData.lbsPositionList[0]
            // );
            params = {
              target: "layer_selectShip",
              zIndex: 15,
              clear: true,
              groupName: "layers_ship",
              visible: true,
            };
            if (this.layersStatus_history[params.target]) {
            } else {
              this.layersStatus_history = {
                ...this.layersStatus_history,
                [params.target]: params,
              };
            }
            this.state.componentRepository.map.click_addPointAnimation(
              this.state.shipData,
              params
            );
            this.state.componentRepository.map.showMap_addPoints(
              [this.state.shipData],
              params
            );
            this.state.componentRepository.map.changeZoomAndCenter_point(
              this.state.shipData
            );
          }
          console.log(this.state.allship, "ddd");
          // params = {
          //     target: 'layers_ship_all_smallZoom',
          //     zIndex: 14,
          //     isOverRule: true,
          //     havePointText: false,
          //     clear: true,
          //     groupName: 'layers_ship'
          // }
          // if (isInit) {
          //     params.visible = true;
          // } else {
          //     params = { ...this.layersStatus_history[params.target] }
          // }
          // if (this.layersStatus_history[params.target]) {
          // } else {
          //     this.layersStatus_history = {
          //         ...this.layersStatus_history,
          //         [params.target]: params,
          //     }
          // }
          // if (isOpen) {
          //     this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);

          // }
          params = {
            target: "layers_ship_all_bigZoom1",
            zIndex: 14,
            isOverRule: false,
            havePointText: false,
            clear: true,
            groupName: "layers_ship",
          };
          if (isInit) {
            params.visible = false;
          } else {
            params = { ...this.layersStatus_history[params.target] };
          }
          if (this.layersStatus_history[params.target]) {
          } else {
            this.layersStatus_history = {
              ...this.layersStatus_history,
              [params.target]: params,
            };
          }
          isOpen &&
            this.state.componentRepository.map.showMap_addPoints(
              this.state.allship,
              params
            );
          params = {
            target: "layers_ship_all_bigZoom1_Cluster",
            zIndex: 14,
            isOverRule: false,
            havePointText: false,
            clear: true,
            groupName: "layers_ship",
            isCluster: true,
          };
          if (isInit) {
            params.visible = true;
          } else {
            params = { ...this.layersStatus_history[params.target] };
          }
          if (this.layersStatus_history[params.target]) {
          } else {
            this.layersStatus_history = {
              ...this.layersStatus_history,
              [params.target]: params,
            };
          }
          isOpen &&
            this.state.componentRepository.map.showMap_addPoints(
              this.state.allship,
              params
            );
          // params = {
          //     target: 'layers_ship_all_bigZoom_withName',
          //     zIndex: 14,
          //     isOverRule: false,
          //     havePointText: true,
          //     clear: true,
          //     groupName: 'layers_ship'
          // }
          // if (isInit) {
          //     params.visible = false;
          // } else {
          //     params = { ...this.layersStatus_history[params.target] }
          // }
          // if (this.layersStatus_history[params.target]) {
          // } else {
          //     this.layersStatus_history = {
          //         ...this.layersStatus_history,
          //         [params.target]: params,
          //     }
          // }
          // isOpen && this.state.componentRepository.map.showMap_addPoints(this.state.allship, params);

          // console.log(this.layersStatus_history, '------layersStatus_history--------------allshipPos------------')
          if (
            isOpen &&
            isInit &&
            typeof this.interval_ChangeResolution_layers_ship == "undefined"
          ) {
            this.interval_ChangeResolution_layers_ship =
              this.state.componentRepository.map.addEventChangeResolution(
                this.callBack_addEventChangeResolution_ship
              );
          }

          // this.state.componentRepository.map.addHeatMapLayer(this.state.allship, { target: 'layer_heatMapDemo', zIndex: 15 });//热力图测试
        }
      );
    } else if (data && data.code == 505) {
      message.error(data.msg);
      if (this.props.history) {
        this.props.history.push("/login");
      }
    }
  }
  //根据北斗卡号或船舶名称查询该船详细信息
  async selectCardDetails(params){
    let data = await selectCardDetails(params)
        .then(res=>{
          return res.json()
        })
        .catch((ex)=>{})
    console.log('data-----------'+JSON.stringify(params),data);
    if(data && data.code==200){
      console.log(111111111111)
      this.setState({
        shipModal: true,
        shipData:{...data.rows[0],pointImg:"http://221.181.88.134:8081/pjresource/wzbd/ship_302401.png"},
      },()=>{
        //定位船只
        //添加选中框
        console.log('this.state.shipData========',this.state.shipData)
        let { lon, lat ,pointImg} = this.state.shipData;
        let activeData = {
          lon,
          lat,
          // pointImg: maths["active"],
          pointImg,
        };
        let params_active = {
          target: "layer_selectShip_active",
          zIndex: 15,
          clear: true,
          visible: true,
          groupName: "layers_ship",
        };
        this.state.componentRepository.map.showMap_addPoints(
            [activeData],
            params_active
        );
        this.state.componentRepository.map.changeZoomAndCenter_point(
            this.state.shipData,
            undefined,
            undefined
        );
      });
    }


  }
  callBack_addEventChangeResolution_ship = (map) => {
    let { controlLayerData, switchArr } = this.state;
    console.log(controlLayerData, "gkjkjgkjkjg");
    let shipShow = this.state.shipShow;
    console.log(map, "123131414");

    let zoom = map.getView().getZoom();

    let isOpenCluster = true;
    switchArr.map((item, index) => {
      if (item.key == "layers_ship_all_bigZoom1_Cluster") {
        isOpenCluster = item.defaultChecked;
      }
    });

    console.log(zoom && zoom, "12314141414");
    if (
      zoom >= 12 &&
      shipShow
      // && this.layersStatus_history['layers_ship_all_bigZoom']
      // && !this.layersStatus_history['layers_ship_all_bigZoom'].visible
      // && this.layersStatus_history['layers_ship_all_smallZoom']
      // && this.layersStatus_history['layers_ship_all_smallZoom'].visible
    ) {
      !isOpenCluster &&
        this.state.componentRepository.map.openLayerVisibleByGroupName(
          "layers_ship",
          ["layers_ship_all_bigZoom1"]
        );
      !isOpenCluster &&
        this.state.componentRepository.map.closeLayerVisibleByGroupName(
          "layers_ship",
          ["layers_ship_all_bigZoom1_Cluster"]
        );
      if (
        !isOpenCluster &&
        this.layersStatus_history["layers_ship_all_bigZoom1"]
      ) {
        this.layersStatus_history["layers_ship_all_bigZoom1"].visible = true;
      }
      if (
        !isOpenCluster &&
        this.layersStatus_history["layers_ship_all_bigZoom1_Cluster"]
      ) {
        this.layersStatus_history[
          "layers_ship_all_bigZoom1_Cluster"
        ].visible = false;
      }
    } else if (
      zoom < 12 &&
      shipShow
      // && this.layersStatus_history['layers_ship_all_bigZoom']
      // && this.layersStatus_history['layers_ship_all_bigZoom'].visible
      // && this.layersStatus_history['layers_ship_all_smallZoom']
      // && !this.layersStatus_history['layers_ship_all_smallZoom'].visible
    ) {
      isOpenCluster &&
        this.state.componentRepository.map.openLayerVisibleByGroupName(
          "layers_ship",
          ["layers_ship_all_bigZoom1_Cluster"]
        );
      isOpenCluster &&
        this.state.componentRepository.map.closeLayerVisibleByGroupName(
          "layers_ship",
          ["layers_ship_all_bigZoom1"]
        );

      if (
        isOpenCluster &&
        this.layersStatus_history["layers_ship_all_bigZoom1"]
      ) {
        this.layersStatus_history["layers_ship_all_bigZoom1"].visible = false;
      }
      if (
        isOpenCluster &&
        this.layersStatus_history["layers_ship_all_bigZoom1_Cluster"]
      ) {
        this.layersStatus_history[
          "layers_ship_all_bigZoom1_Cluster"
        ].visible = true;
      }
    }
    // else if (
    //     zoom >= 19 && shipShow
    //     // && this.layersStatus_history['layers_ship_all_bigZoom_withName']
    //     // && !this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible
    // ) {
    //     console.log(zoom, '13213131313')
    //     this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom_withName']);
    //     this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_smallZoom']);
    //     this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom']);
    //     if (this.layersStatus_history['layers_ship_all_bigZoom_withName']) {
    //         this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible = true;
    //     }
    //     if (this.layersStatus_history['layers_ship_all_smallZoom']) {
    //         this.layersStatus_history['layers_ship_all_smallZoom'].visible = false;
    //     }
    //     if (this.layersStatus_history['layers_ship_all_bigZoom']) {
    //         this.layersStatus_history['layers_ship_all_bigZoom'].visible = false;
    //     }

    // }
    // else if (
    //     zoom < 19 && shipShow
    //     // && this.layersStatus_history['layers_ship_all_bigZoom_withName']
    //     // && this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible
    // ) {
    //     this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom_withName']);
    //     this.state.componentRepository.map.closeLayerVisibleByGroupName('layers_ship', ['layers_ship_all_smallZoom']);
    //     this.state.componentRepository.map.openLayerVisibleByGroupName('layers_ship', ['layers_ship_all_bigZoom']);
    //     if (this.layersStatus_history['layers_ship_all_bigZoom_withName']) {
    //         this.layersStatus_history['layers_ship_all_bigZoom_withName'].visible = false;
    //     }
    //     if (this.layersStatus_history['layers_ship_all_smallZoom']) {
    //         this.layersStatus_history['layers_ship_all_smallZoom'].visible = false;
    //     }
    //     if (this.layersStatus_history['layers_ship_all_bigZoom']) {
    //         this.layersStatus_history['layers_ship_all_bigZoom'].visible = true;
    //     }

    // }

    if (zoom > 14 && controlLayerData) {
      let checked = true;
      let data = {
        name: "遥感影像图",
        TrueAndFalse: "lcc",
        key: "3c5c7527-433f-4a8f-940e-16ff126b5389home",
        defaultChecked: false,
        optionArr: [
          {
            name: "2020年太湖影像图-1",
            key: "taihu1",
            target: "taihu1",
            defaultChecked: false,
          },
          {
            name: "2020年太湖影像图-2",
            key: "taihu2",
            target: "taihu2",
            defaultChecked: true,
          },
        ],
      };
      this.controlLayer(data, checked);
    } else if (zoom < 15 && controlLayerData) {
      let checked = true;
      let data = {
        name: "遥感影像图",
        TrueAndFalse: "lcc",
        key: "3c5c7527-433f-4a8f-940e-16ff126b5389home",
        defaultChecked: false,
        optionArr: [
          {
            name: "2020年太湖影像图-1",
            key: "taihu1",
            target: "taihu1",
            defaultChecked: true,
          },
          {
            name: "2020年太湖影像图-2",
            key: "taihu2",
            target: "taihu2",
            defaultChecked: false,
          },
        ],
      };
      this.controlLayer(data, checked);
    }
  };

  // 输入框为空时搜索列表不显示
  controlList = (eve, value) => {
    if (!eve.target.value) {
      this.setState({
        ulshow: false,
        name: "",
      });
    } else {
      this.setState(
        {
          name: eve.target.value,
          shipNameOrDeviceId: name,
        },
        () => {
          // this.fastSelectShip(this.state.name, 'select')
        }
      );
    }
  };
  //点击搜索列表的事件
  clickSearchData = (data) => {
    console.log(data, "dddddddddd");
    let { allship } = this.state;
    let oldData = undefined;
    allship.map((item, index) => {
      if (item.objectName == data.objectName) {
        console.log(item);
        oldData = { ...item };
      }
    });
    if (oldData) {
      this.selectShip(data.objectName, undefined, oldData);
    } else {
      this.selectShip(data.objectName);
    }
    this.setState({
      name: data.objectName,
    });
  };
  // 工具条的控制===>轨迹展示
  clickTool = (index, key) => {
    let arr = this.state.toolArr;

    switch (key) {
      case "locus":
        this.setState(
          {
            locusShow: !this.state.locusShow,
            toolShow: false,
            switchShow: false,
            pointShow: false,
            portShow: false,
            default_shipData: undefined,
          },
          () => {
            if (!this.state.locusShow) {
              for (let i in arr) {
                arr[i].click = false;
              }
              this.setState({
                toolArr: arr,
              });
            }
          }
        );
        break;
      case "locus_detail":
        this.setState(
          {
            locusShow: !this.state.locusShow,
            toolShow: false,
            switchShow: false,
            pointShow: false,
            portShow: false,
            default_shipData: this.state.shipData,
          },
          () => {
            if (!this.state.locusShow) {
              for (let i in arr) {
                arr[i].click = false;
              }
              this.setState({
                toolArr: arr,
              });
            }
          }
        );
        break;
      case "port":
        this.setState(
          {
            portShow: !this.state.portShow,
            toolShow: false,
            switchShow: false,
            pointShow: false,
            locusShow: false,
          },
          () => {
            if (!this.state.portShow) {
              for (let i in arr) {
                arr[i].click = false;
              }
              this.setState({
                toolArr: arr,
              });
            }
          }
        );
        break;
      case "point":
        this.setState(
          {
            pointShow: !this.state.pointShow,
            toolShow: false,
            switchShow: false,
            portShow: false,
            locusShow: false,
          },
          () => {
            if (!this.state.pointShow) {
              for (let i in arr) {
                arr[i].click = false;
              }
              this.setState({
                toolArr: arr,
              });
            }
          }
        );
        break;
      case "mapSwitch":
        this.setState(
          {
            switchShow: !this.state.switchShow,
            toolShow: false,
            pointShow: false,
            portShow: false,
            locusShow: false,
          },
          () => {
            if (!this.state.switchShow) {
              for (let i in arr) {
                arr[i].click = false;
              }
              this.setState({
                toolArr: arr,
              });
            }
          }
        );
        break;
      case "tool":
        this.setState(
          {
            toolShow: !this.state.toolShow,
            switchShow: false,
            pointShow: false,
            portShow: false,
            locusShow: false,
          },
          () => {
            if (!this.state.toolShow) {
              for (let i in arr) {
                arr[i].click = false;
              }
              this.setState({
                toolArr: arr,
              });
            }
          }
        );
        break;
    }
    for (let i in arr) {
      arr[i].click = false;
      arr[index].click = true;
    }
    this.setState({
      toolArr: arr,
    });
  };
  showMore = () => {
    this.setState(
      {
        menuShow: !this.state.menuShow,
      },
      () => {
        if (this.state.menuShow) {
          this.setState({
            ulshow: false,
          });
        }
      }
    );
  };
  closeModal = () => {
    let arr = this.state.toolArr;
    for (let i in arr) {
      arr[i].click = false;
    }
    this.setState(
      {
        toolArr: arr,
        locusShow: false,
      },
      () => {
        console.log(this.state.allship, "------------closeModal------------");
      }
    );
  };
  resetModal_trajectory = () => {
    this.setState(
      {
        locusShow: false,
        default_shipData: undefined,
      },
      () => {
        this.setState({
          locusShow: true,
        });
      }
    );
  };
  closeShipDetail = () => {
    this.setState({
      shipModal: false,
      shipData: null,
    });
    this.state.componentRepository.map.closeLayerVisibleByGroupName(
      "layers_ship",
      ["layer_selectShip", "layer_selectShip_active"]
    );
  };
  closeArea = () => {
    this.setState({
      areaShow: false,
      detailShow: false,
    });
  };
  clickTree = (key, data) => {
    let name = data.node.props.entiy.objectName;
    let nodeType = data.node.props.entiy.nodeType;
    if (nodeType) {
    } else {
      this.selectShip(name, "tree");
    }
  };
  clickTree_locationShip = (key, data) => {
    this.selectShip(data.deviceId, "tree");
  };
  clickSearch = (key) => {
    let text_Measure_DropdownButton = this.state.text_Measure_DropdownButton;
    if (key == "measure") {
      if (text_Measure_DropdownButton == "取消测量") {
        this.setState(
          {
            text_Measure_DropdownButton: "测量工具",
          },
          () => {
            this.state.componentRepository.map.handleButtonClick_Measure();
          }
        );
      }
    }
    let text_Measure_DropdownButton_search =
      this.state.text_Measure_DropdownButton_search;
    if (key == "measure_search") {
      if (text_Measure_DropdownButton_search == "取消搜索") {
        this.setState(
          {
            text_Measure_DropdownButton_search: "搜索工具",
          },
          () => {
            this.state.componentRepository.map.handleButtonClick_Measure_search();
          }
        );
      }
    }
    switch (key[0]) {
      case "range":
        this.setState(
          {
            text_Measure_DropdownButton: "取消测量",
            text_Measure_DropdownButton_search: "搜索工具",
          },
          () => {
            this.state.componentRepository.map.handleButtonClick_Measure_search();
            this.state.componentRepository.map.handleMenuClick_Measure(key[0]);
          }
        );
        break;
      case "area":
        this.setState(
          {
            text_Measure_DropdownButton: "取消测量",
            text_Measure_DropdownButton_search: "搜索工具",
          },
          () => {
            this.state.componentRepository.map.handleButtonClick_Measure_search();
            this.state.componentRepository.map.handleMenuClick_Measure(key[0]);
          }
        );
        break;
      case "rectangle":
        this.setState({
          areaShow: true,
        });
        break;
      case "Circle":
        this.setState(
          {
            text_Measure_DropdownButton_search: "取消搜索",
            text_Measure_DropdownButton: "测量工具",
          },
          () => {
            this.state.componentRepository.map.handleButtonClick_Measure();
            this.state.componentRepository.map.handleMenuClick_Measure_search(
              key[0]
            );
          }
        );
        break;
      case "Polygon":
        this.setState(
          {
            text_Measure_DropdownButton_search: "取消搜索",
            text_Measure_DropdownButton: "测量工具",
          },
          () => {
            this.state.componentRepository.map.handleButtonClick_Measure();
            this.state.componentRepository.map.handleMenuClick_Measure_search(
              key[0]
            );
          }
        );
        break;
    }
  };
  //获取今天的时间
  getNowTime = (type, newDate) => {
    var date = newDate ? new Date("" + newDate) : new Date();
    // var date = newDate ? newDate : new Date();
    // console.log(newDate,'date')
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    if (type == "start") {
      var currentdate =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        strDate +
        " 00" +
        ":" +
        "00" +
        ":" +
        "00";
    } else if (type == "now") {
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var min =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var sec =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var currentdate =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        strDate +
        " " +
        hours +
        ":" +
        min +
        ":" +
        sec;
    } else if (type == "end") {
      var currentdate =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        strDate +
        " 23" +
        ":" +
        "59" +
        ":" +
        "59";
      console.log(currentdate, "end");
    }
    return currentdate;
  };
  controlLayer = (data, checked) => {
    let { shipShow } = this.state;
    let visibleShow = true;
    let shipVisit = false;
    console.log(data, checked, "1342424252");

    if (data.name == "人员船舶图层") {
      if (checked == true) (shipShow = true), (shipVisit = true);
    }

    if (data.TrueAndFalse && shipVisit) {
      shipShow = true;
    }
    console.log(shipShow, shipVisit, "235325346");
    this.setState({
      shipShow,
    });

    if (!data.TrueAndFalse && data.optionArr) {
      if (data.name == "遥感影像图") {
        // data.optionArr = data.optionArr.reverse()
        visibleShow = checked;
        console.log(data.optionArr, "121341414214");
        this.setState({
          controlLayerData: checked,
          switchValue: data.target,
          switchName: data.layerTitle,
        });
      }
    }
    console.log(visibleShow, "kjgkjgkkg");
    // let { mapArr } = this.state
    let mapArr = [];

    // if (mapArr.length > 0) {
    //     var result = mapArr.some(item => {
    //         if (item.key == data.key) {
    //             return true
    //         }
    //     })
    //     console.log(result) // 如果listArr数组对象中含有id:'',就会返回true，否则返回false
    //     if (result) { // 如果存在
    //         mapArr.forEach((val) => {
    //             if (val.key === data.key) {
    //                 if (typeof (checked) === 'boolean') {
    //                     val.visible = checked
    //                 } else {
    //                     val.key = checked
    //                 }
    //             }
    //         })
    //     } else {
    //         mapArr.push({
    //             key: data.key,
    //             visible: checked,
    //             name: data.name
    //         })
    //     }
    // } else {
    mapArr.push({
      key: data.key,
      visible: checked,
      name: data.name,
      optionArr: data.optionArr,
      groupName: data.groupName,
      isControlAllGroup: data.isControlAllGroup,
      interval: data.interval,
    });
    // }

    let switchArr = this.state.switchArr;
    let controlGroupObjStatus = {}; //获取所有控制group图层组的开关状态
    switchArr.map((itemSwitchArr, indexSwitchArr) => {
      if (itemSwitchArr.key == data.key) {
        if (typeof checked == "boolean") {
          itemSwitchArr.defaultChecked = checked;
        } else {
          itemSwitchArr.defaultChecked = true;
          itemSwitchArr.optionArr.map((itemOptionArr) => {
            if (checked == itemOptionArr.target) {
              itemOptionArr.defaultChecked = true;
            } else {
              itemOptionArr.defaultChecked = false;
            }
          });
        }
      }
      if (itemSwitchArr.key == itemSwitchArr.groupName) {
        controlGroupObjStatus = {
          ...controlGroupObjStatus,
          [itemSwitchArr.groupName]: itemSwitchArr.defaultChecked,
        };
      }
    });
    switchArr.map((itemSwitchArr, indexSwitchArr) => {
      if (itemSwitchArr.key != itemSwitchArr.groupName) {
        if (
          typeof controlGroupObjStatus[itemSwitchArr.groupName] !=
            "undefined" &&
          !controlGroupObjStatus[itemSwitchArr.groupName]
        ) {
          itemSwitchArr.disabled = true;
        } else if (
          typeof controlGroupObjStatus[itemSwitchArr.groupName] !=
            "undefined" &&
          controlGroupObjStatus[itemSwitchArr.groupName]
        ) {
          itemSwitchArr.disabled = false;
        }
      }
    });
    this.setState(
      {
        mapArr,
        switchArr,
      },
      () => {
        console.log(this.state.mapArr, "mapArr");
        let reverseControl = data.reverseControl;
        if (reverseControl) {
          if (checked) {
            this.state.componentRepository.map.closeLayerVisibleByGroupName(
              reverseControl.groupName,
              reverseControl.targets
            );
          } else {
            this.state.componentRepository.map.openLayerVisibleByGroupName(
              reverseControl.groupName,
              reverseControl.targets
            );
          }
        }
        this.state.mapArr.map((item, index) => {
          let visible = item.visible;
          let key = item.key;
          let optionArr = item.optionArr;
          let groupName = item.groupName;
          let isControlAllGroup = item.isControlAllGroup;
          let interval = item.interval;
          let intervalName = "interval_ChangeResolution_" + groupName;
          if (typeof optionArr == "undefined") {
            if (visible) {
              if (groupName) {
                if (isControlAllGroup) {
                  let childrens_open = [];
                  let layersStatus_history_keys = Object.keys(
                    this.layersStatus_history
                  );
                  if (
                    layersStatus_history_keys &&
                    layersStatus_history_keys.length > 0
                  ) {
                    layersStatus_history_keys.map((item, index) => {
                      let params = this.layersStatus_history[item];
                      if (params.groupName && params.groupName == groupName) {
                        if (params && params.visible) {
                          childrens_open = [...childrens_open, params.target];
                          if (params.interval) {
                            let intervalCallBackFun =
                              params.interval.intervalCallBackFun;
                            if (
                              typeof this[params.interval.intervalName] ==
                              "undefined"
                            ) {
                              this[params.interval.intervalName] =
                                intervalCallBackFun();
                            }
                            if (
                              this.layersStatus_history["layer_shipAlarm"] &&
                              this.mp3_radar_alarm
                            ) {
                              this.mp3_radar_alarm.play();
                            }
                          }
                        }
                      }
                    });
                  }

                  if (typeof this[intervalName] == "undefined") {
                    this[intervalName] =
                      this.state.componentRepository.map.addEventChangeResolution(
                        this.callBack_addEventChangeResolution_ship
                      );
                  }
                } else {
                  this.state.componentRepository.map.openLayerVisibleByGroupName(
                    groupName,
                    [key]
                  );
                  if (this.layersStatus_history[key]) {
                    this.layersStatus_history[key].visible = visible;
                  }
                }
              } else {
                this.state.componentRepository.map.openLayerVisibleBylayerName(
                  key
                );
                if (this.layersStatus_history[key]) {
                  this.layersStatus_history[key].visible = visible;
                }
              }
              if (interval) {
                let intervalCallBackFun = interval.intervalCallBackFun;
                if (typeof this[interval.intervalName] == "undefined") {
                  this[interval.intervalName] = intervalCallBackFun();
                }
                if (
                  this.layersStatus_history["layer_shipAlarm"] &&
                  this.mp3_radar_alarm
                ) {
                  this.mp3_radar_alarm.play();
                }
                interval.targets.map((item, index) => {
                  if (this.layersStatus_history[item]) {
                    this.layersStatus_history[item].visible = visible;
                  }
                });
                this.state.componentRepository.map.openLayerVisibleByGroupName(
                  groupName,
                  [interval.targets]
                );
              }
            } else {
              if (groupName) {
                if (isControlAllGroup) {
                  let { shipShow } = this.state;
                  this.setState({
                    shipShow: false,
                  });
                  this.state.componentRepository.map.closeLayerVisibleByGroupName(
                    groupName
                  );
                  if (this[intervalName]) {
                    // this.state.componentRepository.map.clearEventChangeResolution(this.callBack_addEventChangeResolution_ship, this[intervalName]);
                    this[intervalName] = undefined;
                  }
                  let layersStatus_history_keys = Object.keys(
                    this.layersStatus_history
                  );
                  if (
                    layersStatus_history_keys &&
                    layersStatus_history_keys.length > 0
                  ) {
                    layersStatus_history_keys.map((item, index) => {
                      let params = this.layersStatus_history[item];
                      if (params.groupName && params.groupName == groupName) {
                        if (params) {
                          if (params.interval) {
                            if (this[params.interval.intervalName]) {
                              clearInterval(this[params.interval.intervalName]);
                              this[params.interval.intervalName] = undefined;
                              this.state.componentRepository.map.closeLayerVisibleByGroupName(
                                groupName,
                                [params.interval.targets],
                                true
                              );
                            }
                            if (this.mp3_radar_alarm) {
                              this.mp3_radar_alarm.pause();
                            }
                          }
                        }
                      }
                    });
                  }
                } else {
                  this.state.componentRepository.map.closeLayerVisibleByGroupName(
                    groupName,
                    [key]
                  );
                  if (this.layersStatus_history[key]) {
                    this.layersStatus_history[key].visible = visible;
                  }
                }
              } else {
                this.state.componentRepository.map.closeLayerVisibleBylayerName(
                  key
                );
                if (this.layersStatus_history[key]) {
                  this.layersStatus_history[key].visible = visible;
                }
              }
              if (interval) {
                if (this[interval.intervalName]) {
                  clearInterval(this[interval.intervalName]);
                  this[interval.intervalName] = undefined;
                  this.state.componentRepository.map.closeLayerVisibleByGroupName(
                    groupName,
                    [interval.targets],
                    true
                  );
                }
                if (this.mp3_radar_alarm) {
                  this.mp3_radar_alarm.pause();
                }
              }
            }
          } else {
            if (visible) {
              if (optionArr && optionArr.length > 0) {
                let haveTrue = false;
                optionArr.map((item_optionArr, index_optionArr) => {
                  if (item_optionArr.defaultChecked) {
                    this.state.componentRepository.map.openLayerVisibleBylayerName(
                      item_optionArr.target
                    );
                    if (this.layersStatus_history[item_optionArr.target]) {
                      this.layersStatus_history[item_optionArr.target].visible =
                        visible;
                    }
                    haveTrue = true;
                  } else {
                    this.state.componentRepository.map.closeLayerVisibleBylayerName(
                      item_optionArr.target
                    );
                    if (this.layersStatus_history[item_optionArr.target]) {
                      this.layersStatus_history[item_optionArr.target].visible =
                        visible;
                    }
                  }
                });
                if (!haveTrue) {
                  this.state.componentRepository.map.openLayerVisibleBylayerName(
                    optionArr[0].target
                  );
                  if (this.layersStatus_history[optionArr[0].target]) {
                    this.layersStatus_history[optionArr[0].target].visible =
                      visible;
                  }
                }
              }
            } else {
              if (optionArr && optionArr.length > 0) {
                optionArr.map((item_optionArr, index_optionArr) => {
                  this.state.componentRepository.map.closeLayerVisibleBylayerName(
                    item_optionArr.target
                  );
                  if (this.layersStatus_history[item_optionArr.target]) {
                    this.layersStatus_history[item_optionArr.target].visible =
                      visible;
                  }
                });
              }
            }
          }
        });
      }
    );
  };

  preventFormEditToInputFile(event) {
    var keycode;
    if (event.which) {
      keycode = event.which;
    }
    if (keyCode == 13) {
      return false;
    }
    return true;
  }
  getMapClickShip = (id) => {
    this.selectShip(id, "map");
    console.log(id, "iiiiiiiiii");
  };
  showDetail = () => {
    this.setState({
      detailShow: true,
    });
  };

  //数据 字典
  getDictData = (type) => {
    let dictData = JSON.parse(sessionStorage.getItem("dictData"));
    let arr = {};
    if (dictData) {
      dictData.forEach((item) => {
        if (item.category === type) {
          arr = {
            ...arr,
            [item.typeCode]: item.typeName,
          };
        }
      });
    }
    return arr;
  };
  onClick_Table_row_portManageTable = (e, record) => {
    console.log(
      record,
      "--------record----------onClick_Table_row_portManageTable--------"
    );
    let polygonsDatas = [record];
    // let params = {
    //     target: 'target_mapLayer_Polygon_pointArea',
    //     zIndex: 11,
    //     color: 'rgba(255,127,0,0.5)',
    //     isChangeZoomAndCenter: true,
    // }
    this.state.componentRepository.map.changeZoomAndCenter_for_geom(
      polygonsDatas
    );
  };
  //导出
  exportDefaultExcel = (coloums, dockInfoData) => {
    delete coloums[coloums.length - 1];
    if (dockInfoData) {
      dockInfoData.map((item) => {
        // if (item.eventStatus == 1) {
        //     item.eventStatus = "未解除"
        // } else {
        //     item.eventStatus = "已解除"
        // }
        item.shipType = this.getDictData("ship_type")[item.shipType];
      });
    }
    let headers = [];
    coloums.map((item, index) => {
      let kv = {
        k: item.dataIndex ? item.dataIndex : item.key,
        v: item.title,
      };
      headers = headers.concat(kv);
    });
    if (dockInfoData && dockInfoData.length > 0) {
      exportExcel(headers, dockInfoData, "导出数据.xlsx");
    } else {
      message.error("导出数据失败！！！");
    }
  };
  //度°分′秒″转度
  ToDigital = (strDu, strFen, strMiao, len) => {
    len = len > 6 || typeof len == "undefined" ? 6 : len; //精确到小数点后最多六位
    strDu = typeof strDu == "undefined" || strDu == "" ? 0 : parseFloat(strDu);
    strFen =
      typeof strFen == "undefined" || strFen == ""
        ? 0
        : parseFloat(strFen) / 60;
    strMiao =
      typeof strMiao == "undefined" || strMiao == ""
        ? 0
        : parseFloat(strMiao) / 3600;
    var digital = strDu + strFen + strMiao;
    if (digital == 0) {
      return "";
    } else {
      return digital.toFixed(len);
    }
  };
  //度转度°分′秒″
  ToDegrees = (val, type) => {
    if (typeof val == "undefined" || val == "") {
      return "";
    }
    val = String(val);
    var i = val.indexOf(".");
    var strDu = i < 0 ? val : val.substring(0, i); //获取度
    var strFen = 0;
    var strMiao = 0;
    if (i > 0) {
      var strFen = "0" + val.substring(i);
      strFen = strFen * 60 + "";
      i = strFen.indexOf(".");
      if (i > 0) {
        strMiao = "0" + strFen.substring(i);
        strFen = strFen.substring(0, i); //获取分
        strMiao = strMiao * 60 + "";
        i = strMiao.indexOf(".");
        strMiao = strMiao.substring(0, i + 4); //取到小数点后面三位
        strMiao = parseFloat(strMiao).toFixed(2); //精确小数点后面两位
      }
    }
    let result = "";
    switch (type) {
      case "lon":
        result = strDu + "°" + strFen + "′" + strMiao + "″E";
        break;
      case "lat":
        result = strDu + "°" + strFen + "′" + strMiao + "″N";
        break;

      default:
        break;
    }
    return result;
  };
  render() {
    const {
      toolArr,
      menuShow,
      ulshow,
      ulArr,
      ulArrlb,
      name,
      toolShow,
      switchShow,
      switchValue,
      switchName,
      pointShow,
      portShow,
      locusShow,
      switchArr,
      shipModal,
      areaShow,
      pointData,
      portData,
      limitAreaData,
      detailShow,
      blackData,
      pointArr,
    } = this.state;
    let onClick_showCarmeraInfoData = this.state.onClick_showCarmeraInfoData;
    let title_onClick_showCarmeraInfoData =
      this.state.title_onClick_showCarmeraInfoData;
    console.log(
      this.getDictData("ship_type"),
      "-------------getDictData--------------"
    );
    console.log(switchArr, "switchArr123");
    const columns1 = [
      {
        title: "重点区域",
        key: "areaName",
        dataIndex: "areaName",
        align: "center",
        render: (text, record) => {
          return (
            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) =>
                  this.onClick_Table_row_portManageTable(e, record)
                }
              >
                {text}
              </span>
            </div>
          );
        },
      },
      {
        title: "区域当前总数",
        key: "inCount",
        dataIndex: "inCount",
        align: "center",
        render: (text, record, index) => {
          return <span style={{ color: "#00C160" }}>{text}</span>;
        },
      },
      {
        title: "区域驶离总数",
        key: "leaveCount",
        dataIndex: "leaveCount",
        align: "center",
        render: (text, record, index) => {
          return <span style={{ color: "#F31C11" }}>{text}</span>;
        },
      },
    ];
    const columns2 = [
      {
        title: "停靠点",
        key: "areaName",
        dataIndex: "areaName",
        align: "center",
        render: (text, record) => {
          return (
            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) =>
                  this.onClick_Table_row_portManageTable(e, record)
                }
              >
                {text}
              </span>
            </div>
          );
        },
      },
      // {
      //     title:'区域',
      //     key:'area',
      //     dataIndex:'area',
      //     align:"center",
      // },
      {
        title: "当前停靠",
        key: "inCount",
        dataIndex: "inCount",
        align: "center",
        render: (text, record, index) => {
          return <span style={{ color: "#00C160" }}>{text}</span>;
        },
      },
      {
        title: "今日驶离",
        key: "leaveCount",
        dataIndex: "leaveCount",
        align: "center",
        render: (text, record, index) => {
          return <span style={{ color: "#F31C11" }}>{text}</span>;
        },
      },
    ];
    const columns3 = [
      {
        title: "船名",
        key: "objectName",
        dataIndex: "objectName",
        ellipsis: true,
        width: 150,
        fixed: "left",
        filterIcon: (filtered) => <Icon type="search" />,
      },
      {
        title: "船主",
        key: "owerName",
        dataIndex: "owerName",
        ellipsis: true,
        width: 100,
        // fixed: 'left',
      },
      {
        title: "船主联系方式",
        key: "owerPhone",
        dataIndex: "owerPhone",
        ellipsis: true,
        width: 150,
        // fixed: 'left',
      },
      {
        title: "船籍港",
        key: "harborName",
        dataIndex: "harborName",
        ellipsis: true,
        width: 100,
        // fixed: 'left',
      },
      {
        title: "标签号",
        key: "deviceId",
        dataIndex: "deviceId",
        ellipsis: true,
        width: 200,
      },
      {
        title: "类型",
        key: "shipType",
        dataIndex: "shipType",
        ellipsis: true,
        width: 50,
        filters: this.state.chilrenList ? this.state.chilrenList : [],
        onFilter: (value, record) =>
          record.num ? record.num.indexOf(value) === 0 : null,
        render: (text, record) => {
          let color = "black";
          let value = this.getDictData("ship_type")[text];
          return (
            <span
              style={{
                color: color,
                fontFamily: "SourceHanSansCN-Regular",
                fontSize: 12,
                // backgroundColor: 'rgba(19, 35, 130, 0.1)'
              }}
              // draggable="true"
              // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
            >
              {value}
            </span>
          );
        },
      },

      {
        title: "属地",
        key: "regionName",
        dataIndex: "regionName",
        ellipsis: true,
        width: 150,
        filters: this.state.chilrenList ? this.state.chilrenList : [],
        onFilter: (value, record) =>
          record.leave ? record.leave.indexOf(value) === 0 : null,
      },
      {
        title: "经度",
        dataIndex: "lon",
        key: "lon",
        width: 180,
        ellipsis: true,
        render: (text, record) => {
          text = this.ToDegrees(text, "lon");
          return (
            <span
            // style={ {
            //     color: color,
            //     fontFamily: 'SourceHanSansCN-Regular',
            //     fontSize: 12
            //     // backgroundColor: 'rgba(19, 35, 130, 0.1)'
            // } }
            // draggable="true"
            // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
            >
              {text}
            </span>
          );
        },
      },
      {
        title: "纬度",
        dataIndex: "lat",
        key: "lat",
        width: 180,
        ellipsis: true,
        render: (text, record) => {
          text = this.ToDegrees(text, "lat");
          return (
            <span
            // style={ {
            //     color: color,
            //     fontFamily: 'SourceHanSansCN-Regular',
            //     fontSize: 12
            //     // backgroundColor: 'rgba(19, 35, 130, 0.1)'
            // } }
            // draggable="true"
            // onMouseUp={ (e) => this.onClick_carmeraListRow(e, record) }
            >
              {text}
            </span>
          );
        },
      },
      {
        title: "定位时间",
        key: "locationTime",
        dataIndex: "locationTime",
        ellipsis: true,
        width: 180,
        sorter: (a, b) => {
          if (a.locationTime > b.locationTime) {
            return 1;
          } else if (a.locationTime < b.locationTime) {
            return -1;
          } else {
            return 0;
          }
        },
        sortDirections: ["descend", "ascend"],
        // render: (data, record) => {
        //     let time = this.getNowTime('now', record.locationTime)
        //     return time
        // }
      },
      {
        title: "定位",
        key: "Location",
        dataIndex: "Location",
        ellipsis: true,
        width: 50,
        fixed: "right",
        // render: (text, record, index) => {
        //   return <img src={maths.gps} />;
        // },
        render: (text, record) => {
          let color = "black";
          return (
            <div>
              <img
                src={maths.gps}
                style={{
                  cursor: "pointer",
                }}
                onClick={(e) => this.clickTree_locationShip(e, record)}
              />
            </div>
          );
        },
      },
    ];
    const columns4 = [
      {
        title: "船名",
        key: "shipName",
        dataIndex: "shipName",
        width: "40%",
        // align: "center",
        // render: (a, record) => {
        //     return <span onClick={() => this.selectShip(record.shipName)}>{record.shipName}</span>
        //     // return <span onClick={()=>this.clickMenuTable(record)}>{record.shipName}</span>
        // }
      },
    ];

    let defaultDataToChild = {
      pointData,
      portData,
      limitAreaData,
    };
    console.log(this.state.shipData, "this.props.text----112112112-----------");
    return (
      <div className="home">
        <img
          src={
            "http://pushi.ubinavi.com.cn:8081/pjresource/wzbd/ship_302409.png"
          }
          style={{ position: "absolute", bottom: "5%", right: "1%", zIndex: 2 }}
        ></img>
        <div className="home_top">
          <div style={{display:"flex",justifyContent:"space-around"}}>
            {/*<div style={{marginTop:12}}>返回按钮</div>*/}
            <Button
                style={{height:40,marginRight:15}}
                type="primary"
                // onClick={()=>this.props.history.go(-1)}
                onClick={()=>history.go(-1)}
            >返回上一级</Button>
            {/*<div className="searchBox">*/}
            {/*  <div className="menuBox">*/}

            {/*    <img*/}
            {/*        src={menuShow ? maths.menu : maths.menuLight}*/}
            {/*        onClick={this.showMore}*/}
            {/*        style={{ cursor: "pointer" }}*/}
            {/*    />*/}
            {/*    {!menuShow && (*/}
            {/*        <div className="moreMsg">*/}
            {/*          <Tabs>*/}
            {/*            <Tabs.TabPane tab="船舶列表" key="1">*/}
            {/*              <PsTree*/}
            {/*                  dataSource={this.state.treeData}*/}
            {/*                  onSelect={this.clickTree}*/}
            {/*              />*/}
            {/*            </Tabs.TabPane>*/}
            {/*            <Tabs.TabPane tab="重点监控" key="2">*/}
            {/*              <Table*/}
            {/*                  columns={columns4}*/}
            {/*                  dataSource={pointArr}*/}
            {/*                  pagination={false}*/}
            {/*                  showHeader={false}*/}
            {/*                  bodyStyle={{*/}
            {/*                    cursor: "pointer",*/}
            {/*                  }}*/}
            {/*                  onRow={(record) => {*/}
            {/*                    return {*/}
            {/*                      onClick: () => this.selectShip(record.shipName), // 点击行*/}
            {/*                      // onDoubleClick: event => { },*/}
            {/*                      // onContextMenu: event => { },*/}
            {/*                      // onMouseEnter: event => { }, // 鼠标移入行*/}
            {/*                      // onMouseLeave: event => { },*/}
            {/*                      // onDragStart: (e) => this.onMouseDown_carmeraListRow(e, record),*/}
            {/*                      // onMouseUp: (e) => this.onClick_carmeraListRow(e, record),*/}
            {/*                      // onMouseDown: (e) => this.onMouseDown_carmeraListRow(e, record),*/}
            {/*                    };*/}
            {/*                  }}*/}
            {/*              />*/}
            {/*            </Tabs.TabPane>*/}
            {/*            <Tabs.TabPane tab="黑名单" key="3">*/}
            {/*              <Table*/}
            {/*                  columns={columns4}*/}
            {/*                  dataSource={blackData}*/}
            {/*                  pagination={false}*/}
            {/*                  showHeader={false}*/}
            {/*                  bodyStyle={{*/}
            {/*                    cursor: "pointer",*/}
            {/*                  }}*/}
            {/*                  onRow={(record) => {*/}
            {/*                    return {*/}
            {/*                      onClick: () => this.selectShip(record.shipName), // 点击行*/}
            {/*                      // onDoubleClick: event => { },*/}
            {/*                      // onContextMenu: event => { },*/}
            {/*                      // onMouseEnter: event => { }, // 鼠标移入行*/}
            {/*                      // onMouseLeave: event => { },*/}
            {/*                      // onDragStart: (e) => this.onMouseDown_carmeraListRow(e, record),f*/}
            {/*                      // onMouseUp: (e) => this.onClick_carmeraListRow(e, record),*/}
            {/*                      // onMouseDown: (e) => this.onMouseDown_carmeraListRow(e, record),*/}
            {/*                    };*/}
            {/*                  }}*/}
            {/*              />*/}
            {/*            </Tabs.TabPane>*/}
            {/*          </Tabs>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*  <Search*/}
            {/*      value={this.state.name}*/}
            {/*      placeholder="船舶名称/标签号"*/}
            {/*      value={name}*/}
            {/*      onSearch={(value) => this.fastSelectShip(value, "select")}*/}
            {/*      enterButton*/}
            {/*      className="search"*/}
            {/*      size="large"*/}
            {/*      onChange={(value) => this.controlList(value)}*/}
            {/*  />*/}
            {/*  {ulshow && (*/}
            {/*      <ul className="searchList">*/}
            {/*        {this.state.ulArr &&*/}
            {/*        this.state.ulArr.map((item, index) => (*/}
            {/*            <li*/}
            {/*                key={item.name}*/}
            {/*                onClick={this.clickSearchData.bind(this, item)}*/}
            {/*                style={{*/}
            {/*                  cursor: "pointer",*/}
            {/*                  fontSize: 16,*/}
            {/*                  display: "flex",*/}
            {/*                  alignItems: "center",*/}
            {/*                }}*/}
            {/*            >*/}
            {/*              <MyIcon style={{ height: 12 }} type="icon-sousuo" />{" "}*/}
            {/*              &nbsp;&nbsp;&nbsp;*/}
            {/*              <span style={{ fontSize: 14 }}>*/}
            {/*            {item.objectName}*/}
            {/*          </span>{" "}*/}
            {/*              &nbsp;&nbsp;*/}
            {/*              <span*/}
            {/*                  style={{ fontSize: 12, color: "rgba(99,99,99,0.9)" }}*/}
            {/*              >*/}
            {/*            {item.deviceId}*/}
            {/*          </span>*/}
            {/*              &nbsp;&nbsp;*/}
            {/*              <span>*/}
            {/*            {ulArrlb &&*/}
            {/*            ulArrlb.map((items, index) => (*/}
            {/*                <span>{items.deviceId}</span>*/}
            {/*            ))}*/}
            {/*          </span>*/}
            {/*            </li>*/}
            {/*        ))}*/}
            {/*      </ul>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>

          {/*<ul className="toolBox">*/}
          {/*  {toolArr.map((item, index) => (*/}
          {/*    <li*/}
          {/*      key={index}*/}
          {/*      onClick={this.clickTool.bind(this, index, item.key)}*/}
          {/*    >*/}
          {/*      {item.line && <i>|</i>}*/}
          {/*      <img src={item.click ? item.act : item.img} />*/}
          {/*      <span*/}
          {/*        style={item.click ? { color: "#169cfd" } : { color: "#999" }}*/}
          {/*      >*/}
          {/*        {item.name}*/}
          {/*      </span>*/}
          {/*    </li>*/}
          {/*  ))}*/}
          {/*</ul>*/}
          {/*{pointShow && (*/}
          {/*  <div className="pointBox">*/}
          {/*    <Table*/}
          {/*      columns={columns1}*/}
          {/*      dataSource={pointData}*/}
          {/*      pagination={false}*/}
          {/*      locale={{*/}
          {/*        filterConfirm: "确定",*/}
          {/*        filterReset: "重置",*/}
          {/*        emptyText: "暂无数据",*/}
          {/*      }}*/}
          {/*      // showHeader={ false }*/}
          {/*      scroll={{*/}
          {/*        y: "400px",*/}
          {/*        x: "100%",*/}
          {/*        scrollToFirstRowOnChange: true,*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{portShow && (*/}
          {/*  <div className="portBox">*/}
          {/*    <Table*/}
          {/*      columns={columns2}*/}
          {/*      dataSource={portData}*/}
          {/*      pagination={false}*/}
          {/*      width="40%"*/}
          {/*      locale={{*/}
          {/*        filterConfirm: "确定",*/}
          {/*        filterReset: "重置",*/}
          {/*        emptyText: "暂无数据",*/}
          {/*      }}*/}
          {/*      // showHeader={ false }*/}
          {/*      scroll={{*/}
          {/*        y: "400px",*/}
          {/*        x: "100%",*/}
          {/*        scrollToFirstRowOnChange: true,*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{switchShow && (*/}
          {/*  <div className="switchBox">*/}
          {/*    {switchArr.map((item, index) => {*/}
          {/*      if (item.title) {*/}
          {/*        return <h4 key={index}>{item.title}</h4>;*/}
          {/*      } else {*/}
          {/*        if (item.optionArr) {*/}
          {/*          if (item.name == "无人机航拍图") {*/}
          {/*            return (*/}
          {/*              <div*/}
          {/*                key={item.key ? item.key : index}*/}
          {/*                className="mapSwitch"*/}
          {/*              >*/}
          {/*                <Switch*/}
          {/*                  checkedChildren="开"*/}
          {/*                  unCheckedChildren="关"*/}
          {/*                  size="small"*/}
          {/*                  checked={item.defaultChecked}*/}
          {/*                  onChange={this.controlLayer.bind(this, item)}*/}
          {/*                />{" "}*/}
          {/*                <span className="switchName">{item.name}</span>*/}
          {/*                <Select*/}
          {/*                  style={{*/}
          {/*                    overflow: "hidden",*/}
          {/*                    textOverflow: "ellipsis",*/}
          {/*                    whiteSpace: "nowrap",*/}
          {/*                  }}*/}
          {/*                  defaultValue={item.optionArr[0].key}*/}
          {/*                  size="small"*/}
          {/*                  onSelect={this.controlLayer.bind(this, item)}*/}
          {/*                >*/}
          {/*                  {item.optionArr.map((val) => (*/}
          {/*                    <Option*/}
          {/*                      title={val.name}*/}
          {/*                      key={val.key}*/}
          {/*                      value={val.key}*/}
          {/*                    >*/}
          {/*                      {val.name}*/}
          {/*                    </Option>*/}
          {/*                  ))}*/}
          {/*                </Select>*/}
          {/*              </div>*/}
          {/*            );*/}
          {/*          } else if (item.name == "遥感影像图") {*/}
          {/*            return (*/}
          {/*              <div*/}
          {/*                key={item.key ? item.key : index}*/}
          {/*                className="mapSwitch"*/}
          {/*              >*/}
          {/*                <Switch*/}
          {/*                  checkedChildren="开"*/}
          {/*                  unCheckedChildren="关"*/}
          {/*                  size="small"*/}
          {/*                  checked={item.defaultChecked}*/}
          {/*                  onChange={this.controlLayer.bind(this, item)}*/}
          {/*                />{" "}*/}
          {/*                <span className="switchName">{item.name}</span>*/}
          {/*                <Select*/}
          {/*                  style={{*/}
          {/*                    overflow: "hidden",*/}
          {/*                    textOverflow: "ellipsis",*/}
          {/*                    whiteSpace: "nowrap",*/}
          {/*                  }}*/}
          {/*                  defaultValue={item.optionArr[1].name}*/}
          {/*                  size="small"*/}
          {/*                  onSelect={this.controlLayer.bind(this, item)}*/}
          {/*                >*/}
          {/*                  /!* { *!/*/}
          {/*                  /!* // item.optionArr.map((val) => ( *!/*/}

          {/*                  <Option*/}
          {/*                    title={*/}
          {/*                      item.optionArr[0] && item.optionArr[0].name*/}
          {/*                    }*/}
          {/*                    key={item.optionArr[0] && item.optionArr[0].key}*/}
          {/*                    value={item.optionArr[0] && item.optionArr[0].key}*/}
          {/*                  >*/}
          {/*                    {item.optionArr[0] && item.optionArr[0].name}*/}
          {/*                  </Option>*/}
          {/*                  /!* // )) *!/*/}
          {/*                  /!* } *!/*/}
          {/*                </Select>*/}
          {/*              </div>*/}
          {/*            );*/}
          {/*          }*/}
          {/*        } else {*/}
          {/*          return (*/}
          {/*            <div*/}
          {/*              key={item.key ? item.key : index}*/}
          {/*              className="mapSwitch"*/}
          {/*            >*/}
          {/*              <Switch*/}
          {/*                checkedChildren="开"*/}
          {/*                unCheckedChildren="关"*/}
          {/*                defaultChecked={item.defaultChecked}*/}
          {/*                disabled={item.disabled}*/}
          {/*                size="small"*/}
          {/*                onChange={this.controlLayer.bind(this, item)}*/}
          {/*              />{" "}*/}
          {/*              <span className="switchName">{item.name}</span>*/}
          {/*            </div>*/}
          {/*          );*/}
          {/*        }*/}
          {/*      }*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{toolShow && (*/}
          {/*  <div className="survey">*/}
          {/*    <Tree*/}
          {/*      showIcon*/}
          {/*      defaultExpandAll*/}
          {/*      switcherIcon={<Icon type="down" />}*/}
          {/*      onSelect={this.clickSearch}*/}
          {/*    >*/}
          {/*      <TreeNode*/}
          {/*        title={this.state.text_Measure_DropdownButton}*/}
          {/*        key="measure"*/}
          {/*      >*/}
          {/*        <TreeNode*/}
          {/*          icon={<img src={maths.range} />}*/}
          {/*          title="距离测量"*/}
          {/*          key="range"*/}
          {/*          disableCheckbox*/}
          {/*        />*/}
          {/*        <TreeNode*/}
          {/*          icon={<img src={maths.area} />}*/}
          {/*          title="面积测量"*/}
          {/*          key="area"*/}
          {/*        />*/}
          {/*      </TreeNode>*/}
          {/*      <TreeNode*/}
          {/*        title={this.state.text_Measure_DropdownButton_search}*/}
          {/*        key="measure_search"*/}
          {/*      >*/}
          {/*        /!* <TreeNode icon={<img src={maths.rectangle} />} title="矩形搜索" key="rectangle" /> *!/*/}
          {/*        <TreeNode*/}
          {/*          icon={<img src={maths.ellipse} />}*/}
          {/*          title="圆形搜索"*/}
          {/*          key="Circle"*/}
          {/*        />*/}
          {/*        <TreeNode*/}
          {/*          icon={<img src={maths.polygon} />}*/}
          {/*          title="多边形搜索"*/}
          {/*          key="Polygon"*/}
          {/*        />*/}
          {/*      </TreeNode>*/}
          {/*    </Tree>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        {/* <HomeMap getMapClickShip={this.getMapClickShip} NewText={this.state.mapShipData} findShip={this.state.shipData}/> */}
        <Modal
          title={title_onClick_showCarmeraInfoData}
          visible={this.state.videoDetailModal}
          onCancel={this.close_videoDetailModal.bind(this)}
          width={1200}
          bodyStyle={{ width: "1200px", height: "850px" }}
          footer={null}
          centered={true}
          destroyOnClose={true}
        >
          <div>
            <RealTimeDetail
              onClick_showCarmeraInfoData={
                this.state.onClick_showCarmeraInfoData
              }
              setTitle_showCarmeraInfoData={this.setTitle_showCarmeraInfoData}
            ></RealTimeDetail>
          </div>
        </Modal>
        <div
          className="GISMAP_Home_div"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            // left: '24.5%',
            // top: '43%',
            // backgroundColor: 'rgba(19,35,130,0.31)',
            // border: '1px solid white'
          }}
        >
          <MyComponentFactory
            type={"map"}
            componentLoad={this.componentLoad}
            // playerMapPointCarera={this.playerMapPointCarera}
            parentObj={this}
            config_JSON={config_JSON}
          ></MyComponentFactory>
        </div>
        <Modal
          visible={locusShow}
          footer={null}
          onCancel={this.closeModal}
          title="轨迹查询"
          bodyStyle={{ height: "80vh", padding: 0 }}
          destroyOnClose={true}
          width="90vw"
        >
          <TrackQuery
            treeData={this.state.treeData}
            default_shipData={this.state.default_shipData}
            config_JSON={config_JSON}
            parentObj={this}
            defaultDataToChild={defaultDataToChild}
          />
          {/* <TrackQuerySelectArea treeData={this.state.treeData} default_shipData={this.state.default_shipData} config_JSON={config_JSON}/> */}
        </Modal>
        {shipModal && (
          <DetailModal
            data={this.state.shipData}
            closeModal={this.closeShipDetail}
            showDetail={this.showDetail}
            clickTool={this.clickTool}
            // maskClosable={false}
          />
        )}
        {areaShow && (
          <div className="searchArea">
            <h4>
              搜索区域 ({this.state.data_Table_searchArea.length}艘)
              <Button
                style={{ marginRight: "5%" }}
                type={"primary"}
                onClick={() => {
                  this.exportDefaultExcel(
                    columns3,
                    this.state.data_Table_searchArea
                  );
                }}
              >
                导出
              </Button>
              <Icon type="close" onClick={this.closeArea} />
            </h4>
            <Table
              columns={columns3}
              dataSource={this.state.data_Table_searchArea}
              style={{ height: "80%" }}
              // rowSelection={rowSelection}
              size="middle"
              pagination={false}
              locale={{
                filterConfirm: "确定",
                filterReset: "重置",
                emptyText: "暂无数据",
              }}
              // showHeader={ false }
              scroll={{
                y: "400px",
                x: "500px",
                scrollToFirstRowOnChange: true,
              }}
            />
            {/* <div className="btnBox">
                            <Button>取消</Button>
                            <Button type="primary">轨迹</Button>
                        </div> */}
          </div>
        )}
        <Modal className=""></Modal>
        {detailShow && (
          <ShipDetail
            modifyData={this.state.shipData}
            closePage={this.closeArea}
          />
        )}
      </div>
    );
  }
}
export default Home;
