import React, { Component } from 'react';
import { Form, Input, Select, Button, Icon, message } from 'antd';
const { Option } = Select;
let id = 0;
export class AddFormItem extends Component {
  constructor(props){
    super(props)
    this.state={
      keys:[]
    }
  }
  componentDidMount(){
    const { data, } = this.props;
    let devices = data.devices
    let arr=[]
    if(devices){
      devices.map((item,index)=>{
        arr.push(index)
      })
      this.setState({
        keys:arr
      })
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.data!=this.props.data){
      const { data, } = nextProps;
    let devices = data.devices
    let arr=[]
    if(devices){
      devices.map((item,index)=>{
        arr.push(index)
      })
      this.setState({
        keys:arr
      })
    }
    }
  }
  remove = k => {
    const { form } = this.props;
    let {keys}=this.state;
    // can use data-binding to get
    // const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
    this.setState({
      keys:keys.filter(key => key !== k),
    })
  };

  add = () => {
    const { form } = this.props;
    let {keys}=this.state;
    // can use data-binding to get
    // const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(++id);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
    this.setState({
      keys:nextKeys,
    })
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log(values,'fansil')
        if (this.props.onOk) {
          this.props.onOk(names)
        }
        // console.log('Received values of form: ', values);
        // console.log('Merged values:', keys.map(key => names[key]));
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { data, } = this.props;
    let {keys}=this.state;
    let devices = data.devices
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };
    // getFieldDecorator('keys', { initialValue: [0] });
    
    // const keys = getFieldValue('keys');
    // console.log(keys, 'deviceskeys')
    const formItems = keys&&keys.length>0&&keys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? '标签号码' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`names[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: (
            // index === 0 ? 
            {
            deviceId: devices.length > 0&&devices[index] ? devices[index].deviceId : '',
            cardNumType: devices.length > 0&&devices[index] ? devices[index].cardNumType : '',
            deviceType: devices.length > 0&&devices[index] ? devices[index].deviceType : '',
          } 
          // : ''
          ),
        })(<PriceInput disabled={devices.length > 0&&devices[index]?true:false}/>)}
        {keys.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="delete"
            onClick={() => this.remove(k)}
          />
        ) : null}
      </Form.Item>
    ));
    return (
      <Form
      // onSubmit={this.handleSubmit}
      >
        <Form.Item {...formItemLayout} label="船舶名称">
          {getFieldDecorator('shipName', {
          })(<span>{data && data.shipName}</span>)}
        </Form.Item>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
            <Icon type="plus" /> 新增
                </Button>
        </Form.Item>
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button
            htmlType="reset"
            style={{ marginRight: '25%', marginLeft: '15%' }}
            onClick={() => { this.props.onClose() }}
          >
            取消
                </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.handleSubmit}
          >
            确定
                </Button>
        </Form.Item>
      </Form>
    )
  }
}


export default Form.create()(AddFormItem)


class PriceInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  componentDidMount() {
    let dictData = JSON.parse(sessionStorage.getItem('dictData'))
    let cardTypeArr = this.getDictData("card_num_type")
    let deviceTypeArr = this.getDictData("device_type")
    this.setState({
      cardTypeArr,
      deviceTypeArr
    })
  }
  getDictData = (type) => {
    let dictData = JSON.parse(sessionStorage.getItem('dictData'))
    let arr = []
    if (dictData) {
      dictData.forEach((item) => {
        if (item.category === type) {
          arr.push({
            text: item.typeName,
            value: item.typeCode
          })
        }
      })
    }
    return arr;
  }
  handleNumberChange = e => {
    const number = parseInt(e.target.value || 0, 10);
    if (isNaN(number)) {
      message.warning("请输入数字")
      return;
    }
    this.triggerChange({ deviceId: number });
  };

  handleCurrencyChange = cardNumType => {
    this.triggerChange({ cardNumType });
  };
  handleDeviceChange = deviceType => {
    this.triggerChange({ deviceType });
  };

  triggerChange = changedValue => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { size, value,disabled } = this.props;
    console.log(this.props, 'xxxxxxx')
    return (
      <span>
        <Input
          type="text"
          size={size}
          value={value.deviceId}
          onChange={this.handleNumberChange}
          style={{ width: '35%', marginRight: '3%' }}
        disabled={disabled}
        />
        <Select
          value={value.deviceType}
          size={size}
          style={{ width: '37%', marginRight: '3%' }}
          onChange={this.handleDeviceChange}
        >
          {
            this.state.deviceTypeArr && this.state.deviceTypeArr.map((item) => (
              <Option key={item.value} value={item.value}>{item.text}</Option>
            ))
          }
        </Select>
        <Select
          value={value.cardNumType}
          size={size}
          style={{ width: '16%', marginRight: '2%' }}
          onChange={this.handleCurrencyChange}
        >
          {
            this.state.cardTypeArr && this.state.cardTypeArr.map((item) => (
              <Option key={item.value} value={item.value}>{item.text}</Option>
            ))
          }
        </Select>

      </span>
    );
  }
}