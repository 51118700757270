// 客户推送管理
import React, { Component } from 'react'
import './css/system.css'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload } from 'antd';
import moment from 'moment'
import SearchTop from './components/seachTop/SeachTop'
import ModalForm from '../../componts/ModalForm/index'
import {
    selectPushClientManage,
    addClientPush,
    updateClientPush,
    delClientPush,
} from '../../data/dataStore'
import {defaultTextAlign} from "ol/render/canvas";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
// 引入jQuery
import $ from 'jquery'
class ClientPushManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            historyShow: false,
            deleteData: {},
            historyData: {},
            selectedKeys: [],
            cardNumber:'',
            clientName:'',
            taskNum:'',
            Label: false,
            ulshow: false,
            pageIndex: 1,
            pageSize: 10,
            shipOrDeviceId: '',
            dataSource: [],
            modifyShow: false,
            deleteShow: false,
            pageTotal: '',
            userGroupData: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
            trajectoryDateList: [
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
            ],//上报时间
            flowList: [],
        }
        this.selectPushClientManage = this.selectPushClientManage.bind(this)
        this.addClientPush = this.addClientPush.bind(this)
        this.updateClientPush = this.updateClientPush.bind(this)
        this.delClientPush = this.delClientPush.bind(this)
    }
    componentDidMount() {
        //查询客户端信息
        this.selectPushClientManage()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.exportBdCardList(names)
        this.setState({
            name: names
        })
    }
    // 分页查询
    async selectPushClientManage(value, type) {
        this.setState({ loading: true })
        let {pagination} = this.state;
        let params = {
            clientName: this.state.clientName,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await selectPushClientManage(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            // console.log("<<<"+JSON.stringify(data))
            let selectedKeys = []
            //remark--->data.row
            //let newData = this.setKey(data.rows);
            //selectedKeys = this.getdefSelectKey(data.rows)
            //let errMsg = data.row.remark;
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys,
                        // warnMsg: data.row.remark
                        loading: false,

                    }
                )
            } else {
                this.setState({
                        //给dataSource赋值
                        dataSource: data.rows,
                        userGroupData: data.rows,
                        UallArr: data.rows,
                        ulshow: false,
                        pageTotal: data.total,
                        selectedKeys,
                        // warnMsg: data.row.remark
                        loading: false,
                    }
                )
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }

    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        // console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }

    // 扁平化多重嵌套数组对象
    flatten = (arr) => {
        let myArr = []
        for (const item of arr) {
            myArr.push(item);
            if (item.children && item.children.length > 0) {
                myArr.push(...this.flatten(item.children))
            }
        }
        return myArr
    }

    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.exportBdCardList()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

    // 新增推送配置
    async addClientPush(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await addClientPush(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            this.selectPushClientManage()
        } else  {
            message.error("新增失败："+data.message)
        }
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }

        //推送配置修改
    async updateClientPush(obj) {
        let { modifyData } = this.state;
        obj.id = modifyData.id
        // console.log('modify----'+JSON.stringify(obj))
        let data = await updateClientPush(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("修改成功")
            this.selectPushClientManage()
        } else {
            message.error("修改失败："+data.message)
        }
        this.setState({
            modifyShow: false,
        })
    }

    modifyClick = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data,
        })
    }

    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            auditingShow:false,
            modifyShow:false,
            auditingShow_All: false,
            Label: false,
        })
    }

     deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }

    // 删除推送客户
    async delClientPush() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.id,
        }
        let data = await delClientPush(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectPushClientManage()
        } else  {
            message.error("删除失败："+data.message)
        }
        this.setState({
            deleteShow: false,
        })
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectPushClientManage()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }




    render() {
        let {nowModalData,userGroupData,modifyData,selectedKeys,ulshow,selectMenuDataObj = {} ,pageTotal} = this.state;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '客户名称',
                key: 'clientName',
                dataIndex: 'clientName',
                ellipsis: true,
            },
            // {
            //     title: '推送密码',
            //     key: 'password',
            //     dataIndex: 'password',
            //     ellipsis: true,
            // },
            {
                title: '区域',
                key: 'regionIds',
                dataIndex: 'regionIds',
                ellipsis: true,
            },
            {
                title: '非本区卡号',
                key: 'specialCardNumber',
                dataIndex: 'specialCardNumber',
                ellipsis: true,
            },
            {
                title: '排除的卡号',
                key: 'ignoreCardNumber',
                dataIndex: 'ignoreCardNumber',
                ellipsis: true,
            },
            {
                title: '推送topic',
                key: 'topicName',
                dataIndex: 'topicName',
                ellipsis: true,
            },
            {
                title: '消费组',
                key: 'groupId',
                dataIndex: 'groupId',
                ellipsis: true,

            },
            // {
            //     title: '状态',
            //     key: 'status',
            //     dataIndex: 'status',
            //     render: (status) => {
            //         let newText;
            //         if (status == 0) {
            //             newText = '启用'
            //         }else if(status == 1){
            //             newText = '禁用'
            //         }
            //         return newText;
            //     },
            //
            // },
            {
                title: '协议类型',
                key: 'protocol',
                dataIndex: 'protocol',
                ellipsis: true,

            },
            {
                title: '指令类型',
                key: 'command',
                dataIndex: 'command',
                ellipsis: true,

            },
            {
                title: '开关',
                key: 'switchType',
                dataIndex: 'switchType',
                ellipsis: true,

            },

            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
                ellipsis: true,
                render: (text) => {
                    return text ? text : "--"
                }
            },
            // {
            //     title: '菜单分配',
            //     key: 'guanli2',
            //     ellipsis: true,
            //     render: (text, record) => {
            //         return <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.clickManagement(record, 2)}>管理</span>
            //     },
            // },

            {
                title: '操作',
                key: 'detele',
                ellipsis: true,
                width: 200,
                render: (text, record) => {
                    return <div>

                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modifyClick(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 5px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>
                },
            },
        ]
        const addFormItemList = [
            {
                label: '客户名称', //表单label
                id: 'clientName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '设备型号不能为空!' }] }
            },
            {
                label: '推送密码', //表单label
                id: 'password', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '推送密码不能为空!' }] }
            },
            {
                label: '区域', //表单label
                id: 'regionIds', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '非本区卡号', //表单label
                id: 'specialCardNumber', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入额外推送的卡号'} />, //表单受控组件

            },
            {
                label: '忽略的卡号', //表单label
                id: 'ignoreCardNumber', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入不推送的卡号'} />, //表单受控组件

            },
            {
                label: 'topic主题', //表单label
                id: 'topicName', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '消费组', //表单label
                id: 'groupId', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '协议类型', //表单label
                id: 'protocol', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '指令类型', //表单label
                id: 'command', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            {
                label: '开关', //表单label
                id: 'switchType', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },

            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
         ]
        const modifyList = [
            {
                label: '客户名称', //表单label
                id: 'clientName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.clientName ,rules: [{ required: true, message: '客户名称不能为空!' }]}
            },
            {
                label: '推送密码', //表单label
                id: 'password', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.password ,rules: [{ required: true, message: '密码不能为空!' }]}
            },
            {
                label: '区域', //表单label
                id: 'regionIds', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.regionIds }
            },
            {
                label: '非本区卡号', //表单label
                id: 'specialCardNumber', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.specialCardNumber }

            },
            {
                label: '忽略的卡号', //表单label
                id: 'ignoreCardNumber', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.ignoreCardNumber }
            },
            {
                label: 'topic主题', //表单label
                id: 'topicName', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.topicName }
            },


            {
                label: '消费组', //表单label
                id: 'groupId', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.groupId }
            },
            {
                label: '协议类型', //表单label
                id: 'protocol', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.protocol }
            },
             {
                label: '指令类型', //表单label
                id: 'command', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.command }
            },
             {
                label: '开关', //表单label
                id: 'switchType', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.switchType }
            },

            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.remark }
            },
        ]

        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">客户推送配置</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                客户名称：
                                <Input placeholder="请输入" style={{width: '70%'}} name='clientName' value={this.state.clientName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>
                        {/*<div className="cardManagement_title_menuSelect_part">*/}
                        {/*    <div className="cardManagement_title_menuSelect_part_div">*/}
                        {/*        时间：*/}
                        {/*        <Input placeholder="请输入" style={{width: '70%'}} name='taskNum' onChange={value => this._changeValue(value)}/>*/}
                        {/*    </div>*/}

                        {/*</div>*/}


                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectPushClientManage()}
                            >查询</Button>


                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={() =>{
                                    this.setState({
                                        clientName: '',

                                    },() =>{

                                    })

                                }}

                            >重置</Button>

                        </div>


                       <div className="cardManagement_title_menuSelect_part4" style={{marginTop:0,marginLeft:50}}>
                            <SearchTop
                                adding="true"
                                onclick={this.addClickShow}
                            />

                        </div>

                    </div>

                </div>

                <div className="cardIIManagement">
                    <Table
                        columns={columns}
                        //rowSelection={distribute_rowSelect}
                        onRowClick={this.onRowClick}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:515}}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {
                                // console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectPushClientManage(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    submit={this.updateClientPush}
                    title="修改"
                />
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={addFormItemList}
                    closeModal={this.closeModal}
                    submit={this.addClientPush}
                    title="新增"
                />
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.delClientPush}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.clientName}"客户推送配置将要被删除，删后不可恢复，确认删除吗？`}</span>
                </Modal>
            </div>
        )
    }
}
export default ClientPushManage