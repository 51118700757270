// 违法记录查询
import React, { Component } from 'react'
import { Table, Button, Modal,Select,Input, Icon, Carousel, Popover, } from 'antd';
import { enforcement,getArgs } from '../../data/dataStore'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1733642_k1h9s3il2ff.js'
})
const { TextArea, Search } = Input;
class Delinquency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Label: false,
      ulshow: false,
      dataSource: [],
      shipName: '',
      pageIndex: 0,
      pageSize: 2,
      name:'',
      pageTotal: null,
      monitorType: '302301',
      loading: false,
      labelSize: 1,
      pageTotal: null,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10000,
      },
      loading: false,
      shipTypeList: this.getDictData("ship_type"),
      weiguiType: this.getDictData("event_type")
    }
    this.enforcement = this.enforcement.bind(this)
  }
  componentDidMount() {
    this.getArgs()
    this.enforcement();
    let dictData = JSON.parse(sessionStorage.getItem("dictData"))
    this.setState({
      dictData
    })
  }

  //输入框为空时搜索列表不显示
  controlList = (eve) => {
    console.log(eve, 'vvvvvv')
    if (!eve.target.value) {
      this.setState({
        ulshow: false,
        name: ''
      }, () => {
        this.enforcement()
      })
    } else {
      this.setState({
        name: eve.target.value,
        shipName: name,
      }, () => {
        this.enforcement(this.state.name, 'select')
      }
      )
    }
  }
  //点击搜索列表的事件
  clickSearchDatavalue = (names) => {
    this.enforcement(names)
    this.setState({
      name: names
    })

    console.log(names, '666')
  }
    //分页筛选
    async getArgs(value) {
      let params = {
  
      }
      let data = await getArgs(params)
        .then(res => {
          return res.json();
        })
        .catch(ex => { });
  
      if (data && data.code == 200) {
        this.setState({
          objectNameArr: data.data,
        })
  
  
      } else {
      }
    }
  
  getColumnSearchProps = () => ({
    filterDropdown: () => {
      let { objectNameArr } = this.state
      return <div style={{ padding: 8, display: 'block' }}>
        <div>
          <Select style={{ width: 100 }} placeholder={'请选择'}
            showSearch
          >
            {
              objectNameArr && objectNameArr.eventType.map((item) => (
                <Option
                  onClick={this.clickSearchData.bind(this, item)}
                  value={item} >{item}</ Option>
              ))
            }
          </Select>,
        </div>
        <div>
        </div>
      </div>
    }
  });
  //点击搜索
  clickSearchData = (value) => {
    this.setState({
      lawTypeNameArr: value,
    }, () => {
      this.enforcement()
    });
  }
  //分页查询
  async enforcement(value, type) {
    // this.setState({loading:true})
    let { pagination, name,lawTypeNameArr } = this.state;
    let params = {
      shipName: value ? value : name,
      lawTypeName:lawTypeNameArr ? lawTypeNameArr:'',
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }

    let data = await enforcement(params)
      .then(res => {
        return res.json();
      })
      .catch(ex => { });
    if (data && data.code == 200) {
      if (type === 'select') {
        this.setState({
          ullAll: data.data.items,
          ulshow: true,
        }
        )
      } else {
        pagination.total = data.data.pageTotal
        this.setState({
          dataSource: data.data.items,
          pageTotal: data.pageTotal,
          pagination,
          ulshow: false,
        })
      }
      pagination.total = data.data.pageTotal
    } else {
    }
  }

  clickImg = (data) => {
    this.setState({
      lookImg: true,
      imgArr: data.images
    })
  }
  getSearchValue = (value) => {
    let { dataSource } = this.state;
    let newArr;
    if (value) {
      newArr = dataSource.filter((item, b, c) => {
        console.log(value, item.shipName.indexOf(value), 'item')
        return item.shipName.indexOf(value) != -1
      })
    } else {
      newArr = dataSource
    }

    this.setState({
      newArr
    }, () => {
      this.enforcement()
    })
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      // pagination: { ...pagination }
    }, () => {
      // this.enforcement()
    })
    console.log(pagination, filters, sorter, 'sorter')
  }
  getDictData = (type) => {
    let dictData = JSON.parse(sessionStorage.getItem('dictData'))
    let arr = []
    if (dictData) {
      dictData.forEach((item) => {
        if (item.category === type) {
          arr.push({
            text: item.typeName,
            value: item.typeCode
          })
        }
      })
    }
    return arr;
  }
  render() {
    let { dataSource, shipTypeList, imgArr, lookImg, newArr, ulshow } = this.state;
    let ullAlls = this.state.ullAll && this.state.ullAll.map((item, index) => (item.shipName))
    let ullAllss = [...new Set(ullAlls)];
    console.log(dataSource, 'imgArr')
    const columns = [
      {
        title: '船名',
        key: 'shipName',
        dataIndex: 'shipName',
      },
      {
        title: '船舶类型',
        key: 'shipType',
        dataIndex: 'shipType',

        render: (text, record) => {
          let name;
          shipTypeList && shipTypeList.forEach(item => {
            if (text == item.value) {
              name = item.text
            }
          });
          return <span>{name ? name : text}</span>
        }
      },
      {
        title: '发现时间',
        key: 'foundTime',
        dataIndex: 'foundTime',
        sorter: (a, b) => {
          if (a.foundTime > b.foundTime) {
            return 1;
          } else if (a.foundTime < b.foundTime) {
            return -1;
          } else {
            return 0;
          }
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '属地',
        key: 'regionName',
        dataIndex: 'regionName',
      },
      {
        title: '违规类型',
        key: 'lawTypeName',
        dataIndex: 'lawTypeName',
        ...this.getColumnSearchProps('lawTypeName'),
        // filters: weiguiType,
        // onFilter: (value, record) => record.lawTypeName=== value,
        // render:(text)=>{
        //     let name;
        //     weiguiType&&weiguiType.forEach((item)=>{
        //         if(text==item.value){
        //             name=item.text
        //         }
        //     })
        //     return name?name:text
        // }
      },
      {
        title: '报告员',
        key: 'reporterName',
        dataIndex: 'reporterName',
      },
      {
        title: '位置描述',
        key: 'placeDesc',
        dataIndex: 'placeDesc',
        render: (text) => {
          return <Popover content={text} trigger="hover">
            <p className="hiddenString">{text}</p>
          </Popover>
        }
      },
      {
        title: '问题描述',
        key: 'problemDesc',
        dataIndex: 'problemDesc',
        render: (text) => {
          return <Popover content={text} trigger="hover">
            <p className="hiddenString">{text!='undefined'?text:'--'}</p>
          </Popover>
        }
      },
      {
        title: '照片证明',
        key: 'img',
        render: (text, record) => {
          return <Icon type="picture" style={{ fontSize: 18 }} onClick={() => this.clickImg(record)} />
        },
      },
    ]
    return (
      <div className="contentBox">
        <div>
          <div style={{ display: 'flex' }}>
            <Input 
            style={{ width: 180 }} 
            value={this.state.name} 
            className='Searchvalue' 
            onSearch={value => this.selectShipMonitorInfoObscure(value, 'select')} 
            onChange={value => this.controlList(value)} 
            onPressEnter={() => this.enforcement()}
            placeholder="请输入船名" />
          </div>
          {
            ulshow && <ul className="searchList">
              {
                ullAllss && ullAllss.map((item, index) => (
                  <li
                    key={item.index}
                    onClick={this.clickSearchDatavalue.bind(this, item)}
                    style={{
                      cursor: 'pointer',
                      fontSize: 16,
                      display: 'flex',
                      alignItems: "center",
                    }}
                  ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                  </li>
                ))
              }
            </ul>
          }
        </div>
        {/* <Search
                placeholder="请输入船名"
                onSearch={this.getSearchValue}
                style={{ width: 200 }}
                /> */}
        <div>
          <Table
            columns={columns}
            dataSource={newArr ? newArr : dataSource}
            loading={this.state.loading}
            pagination={{
              defaultPageSize: 8,
              // showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
              // showSizeChanger: true,
              showQuickJumper: true,
            }}            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          visible={lookImg}
          onCancel={() => { this.setState({ lookImg: false }) }}
          footer={false}
          title="查看照片"
          width={800}
          className="imgModal"
          destroyOnClose
        >
          {
            imgArr && imgArr.map((item) => (
              <Carousel autoplay>
                <img src={"/api" + item} />
              </Carousel>
            ))
          }
        </Modal>
      </div>
    )
  }
}
export default Delinquency