// 设备参数及配置
import React, { Component } from 'react'
import SearchTop from '../System/components/seachTop/SeachTop'
import { Table, Input, Popover, Popconfirm, Switch, Select, InputNumber, Divider, Icon, Modal, message, Button, Form, Steps, Row, Col, Progress, Checkbox } from 'antd';
import ModalForm from '../../componts/ModalFormDevice';
import ModalForms from '../../componts/ModalForm';
import '../System/css/system.css'
import {
    updateDeviceParameter,
    deleteDeviceParameter,
    selectDeviceParameterById,
    batchCommandSend,
    selectDeviceType,
    queryCommand,
    firmwareUpgrade,
    queryFirmwareVersionName,
    queryFirmwareVersionInfo,
    queryParameter,
    deviceUpgrade,
    selectDeviceParameterAndStatus,
    batchUpdateParameters,
    queryParameters,
    secondCheck,
} from '../../data/dataStore'
import './ManagementDevice.css'
import update from '../System/img/update.png'
import edit from '../System/img/edit.png'
const { Step } = Steps;
const { Search } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
const plainOptions = [
    { label: newFunction(), value: 'serverIP' },
    { label: '数据服务器端口', value: 'serverPort' },
    { label: '升级服务器ip', value: 'updateIP' },
    { label: '升级服务器端口', value: 'updatePort' },
];
const plainOptionsValue = ['serverIP', 'serverPort', 'updateIP', 'updatePort']
const currencyplainOptions = [
    { label: 'Terminal ID', value: 'terminalId' },
    { label: 'Firmware Major Version', value: 'firmwareMajor' },
    { label: 'Firmware Minor Version', value: 'firmwareMinor' },
    { label: 'Hardware Major Version', value: 'hardwareMajor' },
    { label: 'Hardware Minor Version', value: 'hardwareMinor' },
    { label: 'SIM card CCID', value: 'simCcid' },
    { label: 'GSM模块序列号', value: 'gsmSerialNum' },
    { label: '定时模式下数据上报间隔', value: 'uploadIntervalTimeMode' },
    { label: '定时模式下位置采集间隔', value: 'positionIntervalTimeMode' },
    { label: '实时模式下运动时位置采集间隔', value: 'positionIntervalTimeModeMotion' },
    { label: '实时模式下运动时数据上报间隔', value: 'uploadIntervalTimeModeMotion' },
    { label: '实时模式下静止时的定位数据上报间隔', value: 'uploadIntervalTimeModeRest' },
    { label: 'GPRS 连接方式', value: 'gprsConnectType' },
    { label: '定时工作区间开启时间', value: 'startHourTimeMode' },
    { label: '定时工作区间关闭时间', value: 'endHourTimeMode' },
    { label: 'G-Sensor move threshold. Uint: 3.91mg', value: 'gSensorMoveThreshold' },
    { label: '倾斜报警触发门限', value: 'tiltAlarmThreshold' },
    { label: '电池电量不足报警触发门限', value: 'lowBatteryAlarmThreshold' },
    { label: '电池电量不足时数据上报间隔', value: 'uploadIntervalLowBattery' },
];
const currencyplainOptionsValue = [
    'terminalId',
    'firmwareMajor',
    'firmwareMinor',
    'hardwareMajor',
    'hardwareMinor',
    'simCcid', 'gsmSerialNum', 'uploadIntervalTimeMode', 'positionIntervalTimeMode',
    'positionIntervalTimeModeMotion', 'uploadIntervalTimeModeMotion', 'uploadIntervalTimeModeRest',
    'gprsConnectType', 'startHourTimeMode', 'endHourTimeMode', 'gSensorMoveThreshold',
    'tiltAlarmThreshold', 'lowBatteryAlarmThreshold', 'uploadIntervalLowBattery'
]
const PortableplainOptions = [
    { label: 'GPRS 的开启时长', value: 'gprsOpeningTime' },
    { label: '电池电压低于预设值，触发报警', value: 'lowBatteryAlarmThresholdBd' },
    { label: '电池电压值', value: 'batteryVoltageBd' },
    { label: '北斗上报的中心卡地址', value: 'centerCardNum' },
    { label: '产品批次号', value: 'productBatchNum' },
    { label: '厂商标识', value: 'manufacturerTarget' },
    { label: '北斗发送次数', value: 'bdSendTimes' },
    { label: 'GPS定位次数', value: 'gpsLocationTimes' },
    { label: 'GPS定位失败次数', value: 'gpsLocationFailedTimes' },
    { label: '设备复位次数', value: 'deviceResetTimes' },
    { label: '设备日志信息', value: 'deviceLogFlag' },
    { label: 'Device Time', value: 'deviceTime' },
];
const PortableplainOptionsValue = ['gprsOpeningTime', 'lowBatteryAlarmThresholdBd', 'batteryVoltageBd', 'centerCardNum',
    'productBatchNum', 'manufacturerTarget', 'bdSendTimes', 'gpsLocationTimes', 'gpsLocationFailedTimes', 'deviceResetTimes', 'deviceLogFlag', 'deviceTime'
]
const BeidouplainOptions = [
    { label: 'Gprs 模式下位置采集时间', value: 'positionIntervalGprsMode' },
    { label: 'Gprs 模式下位置采集个数', value: 'positionNumGprsMode' },
    { label: '北斗模式下位置采集时间', value: 'positionIntervalBdMode' },
    { label: '北斗模式下位置采集个数', value: 'positionNumBdMode' },
    { label: '北斗模式下上报地址（北斗卡号）', value: 'uploadBdcardBdMode' },
    { label: '低功耗下 gnss 开启时间', value: 'gnssOpeningLowPowerMode' },
    { label: '低功耗下 rdss 发送时间', value: 'rdssSendingLowPowerMode' },
    { label: '低功耗下 gprs 发送时间', value: 'gprsSendingLowPowerMode' },
    { label: '示位标报警功能', value: 'beaconAlarmFlag' },
    { label: '外置报警按钮', value: 'externalAlarmFlag' },
    { label: '国防动员功能', value: 'nationalDefenseMobilizationFlag' },
    { label: '令牌功能', value: 'tokenFlag' },
];
const BeidouplainOptionsValue = ['positionIntervalGprsMode', 'positionNumGprsMode', 'positionIntervalBdMode', 'positionNumBdMode',
    'uploadBdcardBdMode', 'gnssOpeningLowPowerMode', 'rdssSendingLowPowerMode', 'gprsSendingLowPowerMode',
    'beaconAlarmFlag', 'externalAlarmFlag', 'nationalDefenseMobilizationFlag', 'tokenFlag']
const ManholeplainOptions = [
    { label: '数据发送时间', value: 'uploadIntervalJg' },
    { label: '倾角校正值', value: 'tiltCorrectionAngleJg' },
    { label: '拆卸角度检测值', value: 'tiltAlarmThresholdJg' },
];
const ManholeplainOptionsValue = ['uploadIntervalJg', 'tiltCorrectionAngleJg', 'tiltAlarmThresholdJg']
class DeviceConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            indeterminate: false,
            checkAll1: false,
            checkAll2: false,
            checkAll3: false,
            checkAll4: false,
            checkAll5: false,
            parameterDescTrue: true,
            serverHostTrue: true,
            serverHostTrueShow: true,
            FirmwareupdateShow: false,
            parameterList: [],
            modifyShow: false,
            serverHostvalue: '',
            DeviceData: [],
            serverPortvalue: '',
            checkedTrue: true,
            visible: false,
            deviceId: [],
            updateHostvalue: '',
            updatePortvalue: '',
            ulshow: false,
            checkedList: [],
            currencyList: [], PortableList: [], BeidouList: [], ManholeList: [],
            checked: true,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10000,
            },
            shipName: '',
            hidden: true,
            name: "",
            write: true,
            paramSetVis: false,
            paramInquireVis: false,
            instructionVis: false,
            alarmReleaseVis: false,
            alarmResetVis: false,
            firmwareUpgradeVis: false,
            logVis: false,
        }
        this.deleteDeviceParameter = this.deleteDeviceParameter.bind(this)
        this.batchCommandSend = this.batchCommandSend.bind(this)
        this.batchUpdateParameters = this.batchUpdateParameters.bind(this)
        this.firmwareUpgrade = this.firmwareUpgrade.bind(this)
        this.queryParameters = this.queryParameters.bind(this)
    }
    进入页面初始请求的数据
    componentDidMount() {
        this.selectDeviceParameterAndStatus();
        this.deviceUpgrade();
        this.queryCommand();
        this.queryFirmwareVersionName();
        let dictData = JSON.parse(sessionStorage.getItem("dictData"))
        let deviceTypeArr = [];
        dictData && dictData.forEach((item) => {
            if (item.category === "device_type") {
                deviceTypeArr.push({
                    text: item.typeName,
                    value: item.typeCode
                })
            }
        })
        this.setState({
            deviceTypeArr,
        })
    }
    //通过id查询设备参数
    async selectDeviceParameterById(keyArr) {
        let { serverHostvalue, serverPortvalue, updateHostvalue, updatePortvalue } = this.state
        let params = {
            deviceId: keyArr,
        }

        let data = await selectDeviceParameterById(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {

            console.log(data.data, '设备')
            this.setState({
                DeviceData: data.data,
                modifShow: true,
                serverHostvalue: data.data && data.data.serverHost,
                serverPortvalue: data.data && data.data.serverPort,
                updateHostvalue: data.data && data.data.updateHost,
                updatePortvalue: data.data && data.data.updatePort,
            })
        } else {
        }
    }
    //发送设备参数查询指令接口
    async queryParameters(ParameterqueryAllList) {
        let { } = this.state
        let params = {
        }

        let data = await queryParameters(ParameterqueryAllList)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("查询成功")
            console.log(data.data, '设备')
            this.setState({
                DeviceData: data.data,
                paramInquireVis: false
            })
        } else {
            message.error("不支持当前设备")
        }
    }
    // 查询固件升级状态
    async deviceUpgrade() {
        let { pagination } = this.state;
        let params = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await deviceUpgrade(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            pagination.total = data.data.pageTotal
            this.setState({
                deviceUpgradeData: data.data.items,
                pagination,
                loading: false
            })

            console.log(data.data.items, 'deviceUpgradeData')
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    //批量设备参数设置
    async batchUpdateParameters(value) {

        let data = await batchUpdateParameters(value)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("设置成功")
            this.setState({
                modifShow: false
            })
        } else {
            message.error("不支持当前设备")
        }
    }
    //密码确认
    async secondCheck() {
        let { PasswordconfirmationClickData } = this.state
        let params = {
            secondPassword: PasswordconfirmationClickData
        }
        let data = await secondCheck(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            this.setState({
                serverHostTrueShow: false,
                PasswordconfirmationShow: false,
            })
            message.success("密码正确")
        } else {
            message.error("密码错误请重试")
        }
    }
    //指令下发
    async batchCommandSend() {
        let { commandCode, versionName, deviceId, deviceType, parameterCode } = this.state
        console.log(commandCode, 'parameterCode')
        let params
        if (parameterCode == 0 || parameterCode == 1) {
            params = {
                deviceId: deviceId,
                deviceType: deviceType,
                commandCode: versionName,
                parameterCode: parameterCode,
            }
        } else {
            params = {
                deviceId: deviceId,
                deviceType: deviceType,
                commandCode: versionName,
            }
        }
        let data = await batchCommandSend(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("指令下发成功")
            this.setState({

            })
        } else if (data.code == 417) {
            message.error("当前设备不支持报警指令下发")
        }
    }
    //固件升级
    async firmwareUpgrade() {
        let { commandCode, selectedRow, deviceUploadeArr, deviceType, majorVersion, minorVersion, updateVersionInfoId, retryMax, versionName } = this.state
        console.log(deviceUploadeArr, 'deviceUploadeArr')
        let deviceUploadList = []
        deviceUploadeArr.map((item, index) => {
            deviceUploadList.push(
                Object.assign(
                    item,
                    { majorVersion: this.state.majorVersion },
                    { minorVersion: this.state.minorVersion },
                    { retryMax: this.state.retryMax },
                    { updateVersionInfoId: this.state.updateVersionInfoId },
                    { versionName: this.state.versionName },
                    { srcVersionName: '' }
                )
            )
        })
        let params = deviceUploadList
        let data = await firmwareUpgrade(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data.code == 200) {
            message.success("升级成功")
            this.setState({

            })
        } else {
            message.error("升级失败")
        }
    }
    //指令查询接口获取参数
    async queryCommand(value) {
        let params = {
        }
        let data = await queryCommand(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                queryCommandData: data.data,
            })


        } else {
        }
    }
    //查询固件名称
    async queryFirmwareVersionName(value) {
        let params = {
        }
        let data = await queryFirmwareVersionName(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                queryFirmwareVersionNameData: data.data,
            })


        } else {
        }
    }
    //指令参数查询
    async queryParameter(value) {
        let { versionName } = this.state

        let params = {
            commandCode: versionName
        }
        let data = await queryParameter(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                queryParameterData: data.data,
            })
            if (data.data && data.data.length > 0) {
                this.setState({
                    parameterDescTrue: false
                })
            } else {
                this.setState({
                    parameterDescTrue: true
                })
            }

        } else {
        }
    }
    //根据固件名称查询固件版本
    async queryFirmwareVersionInfo(value) {
        let { versionName } = this.state

        let params = {
            versionName: versionName
        }
        let data = await queryFirmwareVersionInfo(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                queryFirmwareVersionInfoData: data.data,
                parameterDescTrue:false,
                queryFirmwareVersionInfoData2:data.data
            })


        } else {
            this.setState({
                parameterDescTrue:true,
            })
        }
    }
    //批量参数设置
    getNewShipData = (value) => {
        let { DeviceData, ParameterArr } = this.state;
        console.log(DeviceData, 'value1231')
        let ParameterList1 = []
        if (DeviceData == undefined || DeviceData.length == 0) {
            Object.keys(value).map((item) => {
                if (typeof (value[item]) == 'undefined') {
                    delete value[item];
                }
            }
            )
            console.log(value, 'value3414')


        } else {
            Object.keys(value).map((key) => {
                let val = value[key]
                if (typeof (val) != 'undefined') {
                    if (typeof (DeviceData[key]) != 'undefined') {
                        if (DeviceData[key] == val) {
                            delete value[key];
                        }
                    } else {
                        delete value[key];
                    }
                } else {
                    delete value[key];
                }


            })
        }
        ParameterArr.map((item, index) => {
            ParameterList1.push(
                Object.assign(
                    item,
                    value
                )
            )
        })
        this.batchUpdateParameters(ParameterList1)
        console.log(ParameterList1, 'ParameterArr1231')

    }
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            // let { eventStatus } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 150 }} placeholder={'请选择设备类型'}
                        showSearch
                    >
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301010)}
                                value={301010} >船讯宝</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301013)}
                                value={301013} >船讯宝电池单发设备</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301510)}
                                value={301510} >海电院太阳能设备</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301011)}
                                value={301011} >自研太阳能设备</ Option>
                        }
                        {
                            <Option
                                onClick={this.clickSearchData.bind(this, 301012)}
                                value={301012} >便携式终端</ Option>
                        }
                    </Select>,
            </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData = (value) => {
        this.setState({
            eventStatusArr: value,
        }, () => {
            this.selectDeviceParameterAndStatus()
        });
    }
    // 删除设备参数
    async deleteDeviceParameter() {
        let { deleteData } = this.state;
        console.log(deleteData, 'deleteData')
        let params = {
            id: deleteData.id,
        }
        let data = await deleteDeviceParameter(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")

        } else {
            message.error("删除失败")
        }
        this.setState({
            deleteShow: false
        })
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: '',
            }, () => {
                this.selectDeviceParameterAndStatus()
            })
        } 
        else {
            this.setState({
                name: eve.target.value,
                objectName: name,
            }, () => {
                this.selectDeviceParameterAndStatus(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.selectDeviceParameterAndStatus(data.deviceId)
        this.setState({
            name: data.objectName
        })
    }
    //固件升级浮窗
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };
    // 查询设备参数
    async selectDeviceParameterAndStatus(value, type) {
        let { shipName, pagination, name, eventStatusArr } = this.state;
        let params = {
            queryCondition: value ? value : name,
            deviceType: eventStatusArr,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
        }
        let data = await selectDeviceParameterAndStatus(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            data.data.items && data.data.items.map((item) => {
                item.key = item.deviceId
            })
            if (type === 'select') {
                this.setState({ loading: true })
                let UrlArr = {};
                data.data.items = data.data.items.reduce((preVal, curVal) => {
                    UrlArr[curVal.deviceId] ? '' : UrlArr[curVal.deviceId] = preVal.push(curVal);
                    return preVal
                }, [])
                this.setState({
                    UrlArr:data.data.items,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                pagination.total = data.data.pageTotal
                let UrlArr = {};
                data.data.items = data.data.items.reduce((preVal, curVal) => {
                    UrlArr[curVal.deviceId] ? '' : UrlArr[curVal.deviceId] = preVal.push(curVal);
                    return preVal
                }, [])
                this.setState({
                    ulshow: false,
                    dataSource: data.data.items,
                    pagination,
                    loading: false
                })
            }


        } else {
            // this.props.history.push('/login')
        }
    }
    //筛选
    async filterDeviceType(params) {
        let data = await selectDeviceParameterAndStatus(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code === 200) {
            this.setState({
                dataSource: data.data.items,
            })
        }
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            // pagination: { ...pagination }
        }, () => {
            // this.selectDeviceParameterAndStatus()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRow) => {
        console.log(selectedRow, '选择框')
        this.setState({
            selectedRowKeys,
            selectedRow
        })
    }
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }
    closeModal1 = () => {
        this.setState({
            PasswordconfirmationShow: false,
        })
    }
    closeModal = () => {
        let { } = this.state
        this.setState({
            checkedList: [],
            serverHostTrueShow: true,
            paramSetVis: false,
            checkAll1: false,
            checkAll2: false,
            checkAll3: false,
            checkAll4: false,
            checkAll5: false,
            parameterDescTrue:true,
            checkedTrue: false,
            checkedListArr: false,
            currencyList: [],
            indeterminate1: false,
            indeterminate2: false,
            indeterminate3: false,
            indeterminate4: false,
            indeterminate5: false,

            PortableList: [],
            BeidouList: [],
            ManholeList: [],
            modifShow: false,
            CommandissueShow: false,
            paramInquireVis: false,
            instructionVis: false,
            alarmReleaseVis: false,
            alarmResetVis: false,
            firmwareUpgradeVis: false,
            logVis: false,
            FirmwareupdateShow: false,
        })
    }
    //点击确定，发送参数查询指令
    Parameterquerconfirmation = () => {
        let { ParameterqueryAllList, parameterListArr, checkedList, currencyList, PortableList, BeidouList, ManholeList } = this.state
        let checkedListAll = checkedList.concat(currencyList, PortableList, BeidouList, ManholeList);
        ParameterqueryAllList['parameterList'] = checkedListAll
        console.log(checkedListAll, 'Parameterquerconfirmation')
        this.queryParameters(ParameterqueryAllList)
    }
    switchChange = (checked) => {
        this.setState({
            write: !checked
        })
    }
    controlList1 = (value) => {
        console.log(value, 'vvvvvv')

        this.setState({
            versionName: value,
        }, () => {
            this.queryParameter()
        })
    }

    controlList5 = (value) => {
        let { queryFirmwareVersionInfoData} =this.state
        let majorVersion = '';
        let minorVersion= '';
        let updateVersionInfoId=value;
        queryFirmwareVersionInfoData && queryFirmwareVersionInfoData.map((item)=>{
            if(value == item.id){
                majorVersion = item.majorversion,
                minorVersion = item.minorversion
            }
        })
        console.log(updateVersionInfoId,majorVersion,minorVersion,'valueewpiyt')

        // let majorVersion = value.majorversion
        // let minorVersion = value.minorversion
        // let updateVersionInfoId = value.id
        this.setState({
            majorVersion,
            minorVersion,
            updateVersionInfoId
        })

    }
    controlList4 = (value) => {
        console.log(value, '')

        this.setState({
            versionName: value,
        }, () => {
            this.queryFirmwareVersionInfo()
        })
    }
    controlList2 = (value) => {
        console.log(value, 'vvvvvv')

        this.setState({
            parameterCode: value
        })

    }
    controlList3 = (eve) => {
        this.setState({
            value: eve.target.value
        })
        // console.log(eve.target.value, 'value')
    }
    PasswordconfirmationClick = (eve) => {
        console.log(eve.target.value, 'PasswordconfirmationClick')
        this.setState({
            PasswordconfirmationClickData: eve.target.value
        })
    }
    PasswordconfirmationSubmit = () => {
        let { PasswordconfirmationClickData } = this.state
        // this.setState({
        //     PasswordconfirmationShow:false
        // })
        this.secondCheck(PasswordconfirmationClickData)
    }
    Numberfailures = (value) => {
        console.log(value, 'valuess')
        this.setState({
            retryMax: value
        })
    }
    //参数查询复选框
    checkboxCLick = (value) => {
        console.log(value, 'checkboxCLick')
        this.setState({
            parameterListArr: value
        })
    }
    // 参数设置
    ParameterAll = () => {
        let { selectedRowKeys, selectedRow } = this.state;
        console.log(selectedRowKeys, 'RangePicker')
        const deviceTypeArr = selectedRow && selectedRow.map(o => o.deviceType)
        const deviceType = deviceTypeArr && deviceTypeArr[0]
        const deviceId = selectedRow && selectedRow.map(o => o.deviceId)
        let ParameterArr = []
        console.log(deviceId, '890')
        Array.prototype.allValuesSame = function () {
            for (var i = 1; i < this.length; i++) {
                if (this[i] !== this[0])
                    return false;
            }
            return true;
        }
        selectedRow && selectedRow.forEach((item, index) => {
            ParameterArr.push({
                deviceId: item.deviceId,
                // deviceId:[1],
                deviceType: item.deviceType,
            })
        })
        var b = deviceTypeArr && deviceTypeArr.allValuesSame();
        if (selectedRowKeys.length == 1) {
            this.selectDeviceParameterById(selectedRowKeys)
            this.setState({
                modifShow: true,
                deviceId: deviceId,
                serverHostTrue: true,
                ParameterArr: ParameterArr,
                deviceType: deviceType,
            })
        } else if (selectedRowKeys.length > 1 && b) {
            this.setState({
                modifShow: true,
                deviceId: deviceId,
                serverHostTrue: true,
                deviceType: deviceType,
                DeviceData: [],
                ParameterArr: ParameterArr,
            })

        } else if (selectedRowKeys.length > 1 && b != true) {
            message.warning('请选择同一设备类型船舶')
        } else {
            message.warning('请先选择设备')
        }
    }
    //参数查询
    ParameterqueryAll = () => {
        let { selectedRowKeys, selectedRow, parameterListArr } = this.state;
        const deviceTypeArr = selectedRow && selectedRow.map(o => o.deviceType)
        const deviceType = deviceTypeArr && deviceTypeArr[0]
        const deviceId = selectedRow && selectedRow.map(o => o.deviceId)
        let ParameterArr = []
        let ParameterqueryAllList = {}
        ParameterqueryAllList['terminalIdList'] = deviceId
        ParameterqueryAllList['deviceType'] = deviceType
        console.log(ParameterqueryAllList, '890')
        Array.prototype.allValuesSame = function () {
            for (var i = 1; i < this.length; i++) {
                if (this[i] !== this[0])
                    return false;
            }
            return true;
        }


        selectedRow && selectedRow.forEach((item, index) => {
            ParameterArr.push({
                deviceId: item.deviceId,
                deviceType: item.deviceType,
            })
        })
        console.log(deviceId, 'ParameterArr')
        var b = deviceTypeArr && deviceTypeArr.allValuesSame();
        if (selectedRowKeys.length == 1) {
            // this.selectDeviceParameterById(selectedRowKeys)
            this.setState({
                paramInquireVis: true,
                deviceId: deviceId,
                serverHostTrue: true,
                ParameterArr: ParameterArr,
                ParameterqueryAllList: ParameterqueryAllList,
                deviceType: deviceType,
            })
        } else if (selectedRowKeys.length > 1 && b) {
            this.setState({
                paramInquireVis: true,
                deviceId: deviceId,
                serverHostTrue: true,
                deviceType: deviceType,
                ParameterqueryAllList: ParameterqueryAllList,
                ParameterArr: ParameterArr,
            })

        } else if (selectedRowKeys.length > 1 && b != true) {
            message.warning('请选择同一设备类型船舶')
        } else {
            message.warning('请先选择设备')
        }
    }
    //指令下发
    CommandissueAll = () => {
        let { selectedRowKeys, selectedRow } = this.state;
        const deviceTypeArr = selectedRow && selectedRow.map(o => o.deviceType)
        const deviceType = deviceTypeArr && deviceTypeArr[0]
        const deviceId = selectedRow && selectedRow.map(o => o.deviceId)
        console.log(deviceType, '890')
        Array.prototype.allValuesSame = function () {
            for (var i = 1; i < this.length; i++) {
                if (this[i] !== this[0])
                    return false;
            }
            return true;
        }
        var b = deviceTypeArr && deviceTypeArr.allValuesSame();
        if (selectedRowKeys.length == 1) {
            this.setState({
                CommandissueShow: true,
                deviceId: deviceId,
                deviceType: deviceType,

            })

        } else if (selectedRowKeys.length > 1 && b) {
            this.setState({
                CommandissueShow: true,
                deviceId: deviceId,
                deviceType: deviceType,
            })
            console.log(deviceId, 'deviceId')

        } else if (selectedRowKeys.length > 1 && b != true) {
            message.warning('请选择同一设备类型船舶')
        } else {
            message.warning('请先选择设备')
        }
    }
    //全选
    onCheckAllChange = (e) => {
        let { checkedList } = this.state
        this.setState({
            checkedList: e.target.checked ? plainOptionsValue : [],
            checkAll1: e.target.checked,
            indeterminate1: false,
        });
        if (e.target.checked) {
            this.setState({
                checkedList: plainOptionsValue,
                indeterminate1: false,

            })
        }

    };
    checkboxCLick = checkedList => {
        this.setState({
            checkedList,
            indeterminate1: !!checkedList.length && checkedList.length < plainOptionsValue.length,
            checkAll1: checkedList.length === plainOptionsValue.length,
        });
    };
    //通用参数全选
    currencyAllChange = (e) => {
        let { currencyList } = this.state
        this.setState({
            checkAll2: e.target.checked,
            currencyList: e.target.checked ? currencyplainOptionsValue : [],
            indeterminate1: false,

        });
        if (e.target.checked) {
            this.setState({
                currencyList: currencyplainOptionsValue,
                indeterminate1: false,
            })
        }

    };
    currencyCLick = currencyList => {
        this.setState({
            currencyList,
            indeterminate2: !!currencyList.length && currencyList.length < currencyplainOptionsValue.length,
            checkAll2: currencyList.length === currencyplainOptionsValue.length,
        });
    };
    //便携北斗参数全选
    PortableAllChange = (e) => {
        let { PortableList } = this.state
        this.setState({
            PortableList: e.target.checked ? PortableplainOptionsValue : [],
            checkAll3: e.target.checked,
            indeterminate1: false,
        });
        if (e.target.checked) {
            this.setState({
                PortableList: PortableplainOptionsValue,
                indeterminate1: false,
            })
        }

    };
    PortableCLick = PortableList => {
        this.setState({
            PortableList,
            indeterminate3: !!PortableList.length && PortableList.length < PortableplainOptionsValue.length,
            checkAll3: PortableList.length === PortableplainOptionsValue.length,
        });
    };
    //北斗类设备参数全选
    BeidouAllChange = (e) => {
        let { BeidouList } = this.state
        this.setState({
            BeidouList: e.target.checked ? PortableplainOptionsValue : [],
            checkAll4: e.target.checked,
            indeterminate1: false,
        });
        if (e.target.checked) {
            this.setState({
                BeidouList: BeidouplainOptionsValue,
                indeterminate1: false,
            })
        }

    };
    BeidouCLick = BeidouList => {
        this.setState({
            BeidouList,
            indeterminate4: !!BeidouList.length && BeidouList.length < BeidouplainOptionsValue.length,
            checkAll4: BeidouList.length === BeidouplainOptionsValue.length,
        });
    };
    //井盖设备参数全选
    ManholeAllChange = (e) => {
        let { ManholeList } = this.state
        this.setState({
            checkAll5: e.target.checked,
            ManholeList: e.target.checked ? ManholeplainOptionsValue : [],
            indeterminate1: false,
        });
        if (e.target.checked) {
            this.setState({
                ManholeList: ManholeplainOptionsValue,
                indeterminate1: false,
            })
        }

    };
    ManholeCLick = ManholeList => {
        this.setState({
            ManholeList,
            indeterminate5: !!ManholeList.length && ManholeList.length < ManholeplainOptionsValue.length,
            checkAll5: ManholeList.length === ManholeplainOptionsValue.length,
        });
    };
    //固件升级
    FirmwareupdateClick = () => {
        let { selectedRowKeys, selectedRow, majorVersion, minorVersion, updateVersionInfoId, retryMax, versionName } = this.state;
        let lcc = 'lichangchag'
        console.log(majorVersion, minorVersion, updateVersionInfoId, retryMax, 'controlList4')
        const deviceTypeArr = selectedRow && selectedRow.map(o => o.deviceType)
        const deviceType = deviceTypeArr && deviceTypeArr[0]
        const deviceId = selectedRow && selectedRow.map(o => o.deviceId)
        const deviceUploadeArr = []
        let deviceUploadList = []
        selectedRow && selectedRow.forEach((item, index) => {
            deviceUploadeArr.push({
                deviceId: item.deviceId,
                deviceType: item.deviceType,
                srcVersionName: item.firmwareMajorVersion,
                srcMinorVersion: item.firmwareMinorVersion,
                cardNumType: item.cardNumType
            })
        })
        console.log(deviceUploadeArr, '890')
        Array.prototype.allValuesSame = function () {
            for (var i = 1; i < this.length; i++) {
                if (this[i] !== this[0])
                    return false;
            }
            return true;
        }
        var b = deviceTypeArr && deviceTypeArr.allValuesSame();
        if (selectedRowKeys.length == 1) {
            this.setState({
                FirmwareupdateShow: true,
                deviceId: deviceId,
                deviceUploadeArr: deviceUploadeArr,
                deviceType: deviceType,

            })

        } else if (selectedRowKeys.length > 1 && b) {
            this.setState({
                FirmwareupdateShow: true,
                deviceUploadeArr: deviceUploadeArr,
                deviceId: deviceId,
                deviceType: deviceType,
            })
            console.log(deviceId, 'deviceId')

        } else if (selectedRowKeys.length > 1 && b != true) {
            message.warning('请选择同一设备类型船舶')
        } else {
            message.warning('请选择设备')
        }
    }
    AlarmreleaseClick = () => {
        let { selectedRowKeys, selectedRow, commandCode, parameterCode } = this.state;
        const deviceTypeArr = selectedRow && selectedRow.map(o => o.deviceType)
        const deviceType = deviceTypeArr && deviceTypeArr[0]
        const deviceId = selectedRow && selectedRow.map(o => o.deviceId)
        console.log(deviceType, '89')
        Array.prototype.allValuesSame = function () {
            for (var i = 1; i < this.length; i++) {
                if (this[i] !== this[0])
                    return false;
            }
            return true;
        }
        var b = deviceTypeArr && deviceTypeArr.allValuesSame();
        if (selectedRowKeys.length == 1) {
            this.setState({
                deviceId: deviceId,
                deviceType: deviceType,
                commandCode: 13,
                parameterCode: 0,
            }, () => {
                this.batchCommandSend()
            })

        } else if (selectedRowKeys.length > 1 && b) {
            this.setState({
                deviceId: deviceId,
                deviceType: deviceType,
                commandCode: 13,
                parameterCode: 0,
            }, () => {
                this.batchCommandSend()
            })
            console.log(deviceId, 'deviceId')

        } else if (selectedRowKeys.length > 1 && b != true) {
            message.warning('请选择同一设备类型船舶')
        } else {
            message.warning('请先选择设备')
        }
    }
    serverHostClick = () => {
        this.setState({
            serverHostTrue: false,
            PasswordconfirmationShow: true,
        })
    }
    change = (event) => {
        this.setState({
            serverHostvalue: event.target.value
        });
    };
    serverPortchange = (event) => {
        this.setState({
            serverPortvalue: event
        });
    };
    updateHostchange = (event) => {
        this.setState({
            updateHostvalue: event.target.value
        });
    };
    updatePortchange = (event) => {
        this.setState({
            updatePortvalue: event
        });
    };
    render() {
        let {
            selectedRowKeys,
            dictData,
            serverHost,
            deviceTypeArr,
            queryFirmwareVersionInfoData,
            parameterDescTrue,
            serverHostTrueShow,
            serverHostTrue,
            queryCommandData,
            queryFirmwareVersionNameData,
            queryParameterData,
            DeviceData,
            modifyShow,
            ulshow,
            dataSource,
            modifyData,
            deviceUpgradeData,
            checkedValues,
            checked, checkedListAll,
            checkedTrue,
            indeterminate1,
            indeterminate2,
            indeterminate3,
            indeterminate4,
            indeterminate5,
            checkAll1,
            checkAll2,
            checkAll3,
            checkAll4,
            checkAll5,
            value, serverHostvalue, serverPortvalue, updateHostvalue, updatePortvalue
        } = this.state;
        let DeviceDataSe = DeviceData && DeviceData.serverHost
        console.log(typeof (DeviceDataSe), 'skhfgl')
        let firmwareData = []
        // serverHostvalue=DeviceData && DeviceData.serverHost ? DeviceData && DeviceData.serverHost : '请输入'
        deviceUpgradeData && deviceUpgradeData.map((item) => (
            firmwareData.push(
                {
                    deviceId: item.deviceId,
                    uploadIndex: item.uploadIndex,
                    uploadMax: item.uploadMax
                }
            )
        ))
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        serverHost = {

            // disabled:true
        }

        const columns = [
            {
                title: '标签ID',
                key: 'deviceId',
                dataIndex: 'deviceId',
                width: 180,
                fixed: 'left'
            },
            {
                title: '船舶名称',
                key: 'objectName',
                dataIndex: 'objectName',
                width: 180,
                fixed: 'left',
            },
            {
                title: '设备类型',
                key: 'deviceType',
                dataIndex: 'deviceType',
                width: 200,
                render: (text) => {
                    let newText;
                    if (deviceTypeArr) {
                        deviceTypeArr.forEach((item) => {
                            if (item.value == text) {
                                newText = item.text
                            }
                        })
                    }
                    return newText
                },
                ...this.getColumnSearchProps('deviceType'),
            },
            {
                title: '更新时间',
                key: 'locationTime',
                dataIndex: 'locationTime',
                width: 180,
                sorter: (a, b) => {
                    if (a.locationTime > b.locationTime) {
                        return 1;
                    } else if (a.locationTime < b.locationTime) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
            },
            {
                title: '固件版本',
                key: 'firmwareMajorVersion',
                dataIndex: 'firmwareMajorVersion',
                width: 180,
                render: (text, row, index) => {
                    return (<span>{row.firmwareMajorVersion}.{row.firmwareMinorVersion}</span>)
                },
            },
            {
                title: '硬件版本',
                key: 'hardwareMajorVersion',
                dataIndex: 'hardwareMajorVersion',
                width: 180,
                render: (text, row, index) => {
                    return (<span>{row.hardwareMajorVersion}.{row.hardwareMinorVersion}</span>)
                },
            },
            {
                title: '数据服务器',
                key: 'serverPort',
                dataIndex: 'serverPort',
                width: 250,
                render: (text, row, index) => {
                    return (<span>{row.serverHost} : {row.serverPort}</span>)
                },
            },
            {
                title: '更新服务器',
                key: 'updatePort',
                dataIndex: 'updatePort',
                width: 250,
                render: (text, row, index) => {
                    return (<span>{row.updateHost} : {row.updatePort}</span>)
                },
            },
            {
                title: '日志',
                key: 'log',
                width: 100,
                fixed: 'right',
                render: (text, record) => {
                    return <div>
                        <span
                        // style={{ color: '#1890FF', cursor: 'pointer' }} 
                        >日志</span>
                    </div>

                },
            },
        ]
        const FirmwareupdateList = [
            {
                label: '固件升级:', //表单label
                id: 'commandName', //表单Item的Key值
                component: <Select
                    // style={{
                    //     position: 'absolute',
                    //     width: 200,
                    //     // left: 200
                    // }}
                    onSelect={value => this.controlList4(value)}>
                    {queryFirmwareVersionNameData && queryFirmwareVersionNameData.map((item, index) => (
                        // return <option key={index}>{item.commandName}</option>
                        <Select.Option value={item} >{item}</Select.Option>
                    ))}

                </Select>,
                options: { rules: [{ required: true, message: '固件升级指令不能为空不能为空!' }] }
            },
            {
                label: '目标版本:', //表单label
                id: 'parameterDesc', //表单Item的Key值

                component: <Select
                    // style={{
                    //     position: 'absolute',
                    //     width: 200,
                    //     // // left: 200
                    // }}
                    disabled={parameterDescTrue}
                    onSelect={value => this.controlList5(value)}
                >
                    {queryFirmwareVersionInfoData && queryFirmwareVersionInfoData.map((item) => (
                        <Select.Option key={item.id}  >{item.majorversion}.{item.minorversion}</Select.Option>
                    ))}

                </Select>,
                options: {
                    rules: [{ required: true, message: '目标版本不能为空!' }]
                }
            },
            {
                label: '允许失败次数：',
                id: 'uploadIntervalTimeMode',
                component: <InputNumber
                    onChange={value => this.Numberfailures(value)}
                    oninput="value=value.replace(/[^\d]/g,'')"
                    style={{
                        // position: 'absolute',
                        width: 300,
                        // left: 200
                    }}
                    placeholder="请输入" />,
                options: {
                    rules: [{ required: true, message: '允许失败次数不能为空!' }]
                }
            },
        ]
        const CommandissueList = [
            {
                label: '指令选择:', //表单label
                id: 'commandName', //表单Item的Key值
                component: <Select
                    // style={{
                    //     position: 'absolute',
                    //     width: 200,
                    //     // left: 200
                    // }}
                    onSelect={value => this.controlList1(value)}>
                    {queryCommandData && queryCommandData.map((item, index) => (
                        // return <option key={index}>{item.commandName}</option>
                        <Select.Option value={item.commandCode} >{item.commandName}</Select.Option>
                    ))}

                </Select>,
                options: { rules: [{ required: true, message: '指令选择不能为空!' }] }
            },
            {
                label: '指令内容:', //表单label
                id: 'parameterDesc', //表单Item的Key值

                component: <Select
                    // style={{
                    //     position: 'absolute',
                    //     width: 200,
                    //     // left: 200
                    // }}
                    disabled={parameterDescTrue}
                    onSelect={value => this.controlList2(value)}
                >
                    {queryParameterData && queryParameterData.map((item) => (
                        <option value={item.parameterValue}>{item.parameterDesc}</option>
                    ))}

                </Select>,
                options: {
                    // rules: [{ required: true, message: '船舶名称不能为空!' }]
                }
            },
        ]
        const PasswordconfirmationList = [
            {
                label: '输入密码',
                id: 'Passwordconfirmation',
                component: <Input
                    type="password"
                    onChange={value => this.PasswordconfirmationClick(value)}
                    onPressEnter={this.PasswordconfirmationSubmit}
                ></Input>,
                options: [{ required: true, message: '密码不能为空!' }]
            },
        ]
        const formItemList = [
            {
                label: '数据服务器ip',
                id: 'serverHost',
                component: (
                    <div style={{ display: 'flex' }}>
                        <span
                            style={{
                                position: 'absolute',
                                left: -287,
                                width: 176, zIndex: 8,
                                backgroundColor: 'rgba(255,255,255)',
                                fontWeight: 600

                            }}
                        >数据服务器IP:</span>
                        <Input
                            value={this.state.serverHostvalue}
                            onChange={this.change}
                            style={{
                                position: 'absolute',
                                left: -150,
                                zIndex: 10,
                                height: 32

                            }}
                            disabled={serverHostTrueShow}
                        />
                        <img src={edit}
                            style={{
                                position: 'absolute',
                                left: 38,
                                top: 4,
                                cursor: 'pointer',
                                zIndex: 999
                            }}
                            alt='edit'
                            onClick={this.serverHostClick} />
                    </div>

                ),
                options: { initialValue: DeviceData && DeviceData.serverHost }
            },
            {
                label: '数据服务器端口',
                id: 'serverPort',
                component: (
                    <div style={{ display: 'flex' }}>
                        <span
                            style={{
                                position: 'absolute',
                                left: -287,
                                width: 176, zIndex: 8,
                                backgroundColor: 'rgba(255,255,255)',
                                fontWeight: 600

                            }}
                        >数据服务器端口:</span>
                        <InputNumber
                            value={this.state.serverPortvalue}
                            onChange={this.serverPortchange}
                            min='1'
                            max='65535'
                            precision={0}
                            style={{
                                position: 'absolute',
                                left: -145,
                                zIndex: 10,
                                width: 180,

                            }}
                            disabled={serverHostTrueShow}
                        // placeholder="请输入" 
                        />
                        <img src={edit}
                            style={{
                                position: 'absolute',
                                left: 45,
                                top: 4,
                                cursor: 'pointer'
                            }}
                            alt='edit'
                            onClick={this.serverHostClick} />
                    </div>

                ),
                options: { initialValue: DeviceData && DeviceData.serverPort }
            },
            {
                label: '升级服务器ip',
                id: 'updateHost',
                component: (
                    <div style={{ display: 'flex' }} >
                        <span
                            style={{
                                position: 'absolute',
                                left: -287,
                                width: 176, zIndex: 8,
                                backgroundColor: 'rgba(255,255,255)',
                                fontWeight: 600

                            }}
                        >升级服务器IP:</span>
                        <Input
                            value={this.state.updateHostvalue}
                            onChange={this.updateHostchange}
                            style={{
                                position: 'absolute',
                                left: -150,
                                zIndex: 10,
                                height: 32
                            }}
                            disabled={serverHostTrueShow}
                        />
                        <img src={edit}
                            style={{
                                position: 'absolute',
                                left: 38,
                                top: 4,
                                cursor: 'pointer',
                                zIndex: 999

                            }}
                            alt='edit'
                            onClick={this.serverHostClick} />
                    </div>

                ),
                options: { initialValue: DeviceData && DeviceData.updateHost }
            },
            {
                label: '升级服务器端口',
                id: 'updatePort',
                component: (
                    <div style={{ display: 'flex' }}>
                        <span
                            style={{
                                position: 'absolute',
                                left: -287,
                                width: 176, zIndex: 8,
                                backgroundColor: 'rgba(255,255,255)',
                                fontWeight: 600

                            }}
                        >升级服务器端口:</span>
                        <InputNumber
                            min='1'
                            max='65535'
                            precision={0}
                            value={this.state.updatePortvalue}
                            onChange={this.updatePortchange}
                            style={{
                                position: 'absolute',
                                left: -145,
                                zIndex: 10,
                                width: 180,

                            }}
                            disabled={serverHostTrueShow}
                        // placeholder="请输入" 
                        />
                        <img src={edit}
                            style={{
                                position: 'absolute',
                                left: 45,
                                top: 4,
                                cursor: 'pointer'
                            }}
                            alt='edit'

                            onClick={this.serverHostClick} />
                        <br></br>
                    </div>

                ),
                options: { initialValue: DeviceData && DeviceData.updatePort }
            },
            {
                label: '通用参数',
                id: 'divider1',
                component: (
                    <div className="dibider">
                        <span style={{ fontWeight: 700 }}>通用参数</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '定时模式下数据上报间隔(秒)：',
                id: 'uploadIntervalTimeMode',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入"
                />,
                options: { initialValue: DeviceData && DeviceData.uploadIntervalTimeMode }
            },
            {
                label: '定时模式下位置采集间隔(秒)：',
                id: 'positionIntervalTimeMode',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入"
                />,
                options: { initialValue: DeviceData && DeviceData.positionIntervalTimeMode }
            },
            {
                label: '实时模式下运动时位置采集间隔(秒):',
                id: 'uploadIntervalTimeModeMotion',
                component: <InputNumber
                    placeholder="请输入"
                    min='1'
                    max='1800'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.uploadIntervalTimeModeMotion }
            },
            {
                label: '实时模式下运动时数据上报间隔(秒)',
                id: 'positionIntervalTimeModeMotion',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入"
                />,
                options: { initialValue: DeviceData && DeviceData.positionIntervalTimeModeMotion }
            },
            {
                label: '实时模式下静止时的定位数据上报间隔（单点） (单位： 小时)',
                id: 'uploadIntervalTimeModeRest',
                component: <InputNumber
                    min='1'
                    max='23'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.uploadIntervalTimeModeRest }
            },
            {
                label: 'GPRS 连接方式：',
                id: 'gprsConnectType',
                component: <Select style={{ width: 100 }} placeholder={DeviceData && DeviceData.gprsConnectType}>
                    {
                        <Option
                            value={0} >短连接</ Option>
                    }
                    {
                        <Option
                            value={1} >长连接</ Option>
                    }
                </Select>,
                options: { initialValue: DeviceData && DeviceData.gprsConnectType }
            },
            {
                label: '定时工作区间开启时间(北京时间 24 小时制，最小单位：小时)',
                id: 'startHourTimeMode',
                component: <InputNumber
                    min='1'
                    max='23'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.startHourTimeMode }
            },
            {
                label: '定时工作区间关闭时间(北京时间 24 小时制，最小单位：小时)',
                id: 'endHourTimeMode',
                component: <InputNumber
                    min='1'
                    max='23'
                    precision={0}
                    // parser='value => /^\d+$/.text(value)?value:1' 
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.endHourTimeMode }
            },
            {
                label: 'G-Sensor move threshold. Uint: 3.91mg：',
                id: 'gSensorMoveThreshold',
                component: <InputNumber
                    min='1'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.gSensorMoveThreshold }
            },
            {
                label: '倾斜报警触发门限（单位：度）',
                id: 'tiltAlarmThreshold',
                component: <InputNumber min='1' precision={0} placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.tiltAlarmThreshold }
            },
            {
                label: '电池电量不足报警触发门限（单位： 0.1V）',
                id: 'lowBatteryAlarmThreshold',
                component: <InputNumber min='1' precision={0} placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.lowBatteryAlarmThreshold }
            },
            {
                label: '电池电量不足时数据上报间隔（单位： 秒）',
                id: 'uploadIntervalLowBattery',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.uploadIntervalLowBattery }
            },
            {
                label: '便携式北斗终端参数',
                id: 'divider1',
                component: (
                    <div className="dibider">
                        <span style={{ fontWeight: 700 }}>便携式北斗终端参数</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: 'GPRS 的开启时长(单位秒)',
                id: 'gprsOpeningTime',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.gprsOpeningTime }
            },
            {
                label: '电池电压低于预设值，触发报警(秒):',
                id: 'lowBatteryAlarmThresholdBd',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.lowBatteryAlarmThresholdBd }
            },
            {
                label: '北斗上报的中心卡地址：',
                id: 'centerCardNum',
                component: <InputNumber

                    placeholder="请输入"
                    min='1000'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.centerCardNum }
            },
            {
                label: '设备日志信息：',
                id: 'deviceLogFlag',
                component: <Select style={{ width: 100 }} placeholder={DeviceData && DeviceData.deviceLogFlag}>
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 0)}
                            value={0} >关闭</ Option>
                    }
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 1)}
                            value={1} >开启</ Option>
                    }
                </Select>,
                options: { initialValue: DeviceData && DeviceData.deviceLogFlag }
            },
            {
                label: '北斗类设备参数',
                id: 'divider1',
                component: (
                    <div className="dibider">
                        <span style={{ fontWeight: 700 }}>北斗类设备参数</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: 'Gprs 模式下位置采集时间(单位：秒)：',
                id: 'positionIntervalGprsMode',
                component: <InputNumber placeholder="请输入"
                    min='1'
                    max='1800'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.positionIntervalGprsMode }
            }, {
                label: 'Gprs 模式下位置采集个数：',
                id: 'positionNumGprsMode',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.positionNumGprsMode }
            }, {
                label: '北斗模式下位置采集时间(单位：秒)：',
                id: 'positionIntervalBdMode',
                component: <InputNumber placeholder="请输入"
                    min='1'
                    max='1800'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.positionIntervalBdMode }
            }, {
                label: '北斗模式下位置采集个数：',
                id: 'positionNumBdMode',
                component: <InputNumber
                    min='1'
                    max='1800'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.positionNumBdMode }
            },
            {
                label: '北斗模式下上报地址（北斗卡号）：',
                id: 'uploadBdcardBdMode',
                component: <InputNumber
                    min='1000'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.uploadBdcardBdMode }
            },
            {
                label: '低功耗下 gnss 开启时间：',
                id: 'gnssOpeningLowPowerMode',
                component: <InputNumber
                    min='0'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.gnssOpeningLowPowerMode }
            },
            {
                label: '低功耗下 rdss 发送时间：',
                id: 'rdssSendingLowPowerMode',
                component: <InputNumber
                    min='0'
                    precision={0}
                    placeholder="请输入" />,
                options: { initialValue: DeviceData && DeviceData.rdssSendingLowPowerMode }
            },
            {
                label: '低功耗下 gprs 发送时间：',
                id: 'gprsSendingLowPowerMode',
                component: <InputNumber placeholder="请输入"
                    min='0'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.gprsSendingLowPowerMode }
            },
            {
                label: '示位标报警功能：',
                id: 'beaconAlarmFlag',
                component: <Select style={{ width: 100 }} placeholder={'请选择'}>
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 0)}
                            value={0} >关闭</ Option>
                    }
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 1)}
                            value={1} >开启</ Option>
                    }
                </Select>,
                options: { initialValue: DeviceData && DeviceData.beaconAlarmFlag }
            },

            {
                label: '外置报警按钮：',
                id: 'externalAlarmFlag',
                component: <Select style={{ width: 100 }} placeholder={'请选择'}>
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 0)}
                            value={0} >关闭</ Option>
                    }
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 1)}
                            value={1} >开启</ Option>
                    }
                </Select>,
                options: { initialValue: DeviceData && DeviceData.externalAlarmFlag }
            },
            {
                label: '国防动员功能：',
                id: 'nationalDefenseMobilizationFlag',
                component: <Select style={{ width: 100 }} placeholder={'请选择'}>
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 0)}
                            value={0} >关闭</ Option>
                    }
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 1)}
                            value={1} >开启</ Option>
                    }
                </Select>,
                options: { initialValue: DeviceData && DeviceData.nationalDefenseMobilizationFlag }
            },
            {
                label: '令牌功能：',
                id: 'tokenFlag',
                component: 
                <Select style={{ width: 100 }} placeholder={'请选择'}>
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 0)}
                            value={0} >关闭</ Option>
                    }
                    {
                        <Option
                            // onClick={this.clickSearchData.bind(this, 1)}
                            value={1} >开启</ Option>
                    }
                </Select>,
                options: { initialValue: DeviceData && DeviceData.tokenFlag }
            },

            {
                label: '井盖设备参数',
                id: 'divider3',
                component: (
                    <div className="dibider">
                        <span style={{ fontWeight: 700 }}>井盖设备参数</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '数据发送时间(秒)：',
                id: 'uploadIntervalJg',
                component: <InputNumber placeholder="请输入"
                    min='1'
                    max='1800'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.uploadIntervalJg }
            },
            {
                label: '倾角校正值(度):',
                id: 'tiltCorrectionAngleJg',
                component: <InputNumber
                    min='1'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.tiltCorrectionAngleJg }
            },
            {
                label: '拆卸角度检测值(度)：',
                id: 'tiltAlarmThresholdJg',
                component: <InputNumber
                    min='1'
                    precision={0}
                />,
                options: { initialValue: DeviceData && DeviceData.tiltAlarmThresholdJg }
            },
        ]

        return (
            <div className='deviceManagement contentBox'>

                <div>
                    <label>设备查询: </label>
                    <Input
                        value={this.state.name}
                        style={{
                            width: '32vh'
                        }}
                        className='Searchvalue'
                        onSearch={value => this.selectDeviceParameterAndStatus(value, 'select')}
                        onChange={value => this.controlList(value)}
                        onPressEnter={() => this.selectDeviceParameterAndStatus()}
                        placeholder="船舶名称/标签ID"
                    />

                    {/* <label>资源组: </label>
                    <Select className='sourceGroup'></Select> */}
                    <Button type='primary' style={{ position: 'absolute', right: 120 }} onClick={() => this.selectDeviceParameterAndStatus()}>查询</Button>
                    <Button
                        style={{ position: 'absolute', right: 40 }}
                        onClick={() => {
                            this.setState({
                                name: '',
                                eventStatusArr: '',
                                DeviceData: [],
                            }, () => {
                                this.selectDeviceParameterAndStatus()
                            })
                        }}
                    >重置</Button>
                    <div>
                        <Button
                            onClick={
                                this.ParameterAll
                            }
                        >参数设置</Button>
                        <Button
                            //  onClick={() => { this.setState({ paramInquireVis: true }) }}
                            onClick={
                                this.ParameterqueryAll
                            }
                        >参数查询</Button>
                        <Button onClick={
                            this.CommandissueAll
                        }>指令下发</Button>
                        <Button
                            onClick={
                                this.AlarmreleaseClick
                            }
                        >报警解除</Button>
                        <Button>报警复位</Button>
                        <Button
                            onClick={
                                this.FirmwareupdateClick
                            }
                        >固件升级</Button>
                        <Popover
                            content={
                                <div>
                                    {firmwareData && firmwareData.map((item, index) => (
                                        <li style={{ display: 'flex' }}>
                                            <span>{item.deviceId}</span><Progress percent={item.uploadIndex / item.uploadMax} size="small" />
                                        </li>
                                    ))}
                                </div>
                            }
                            overlayStyle={{ width: 300 }}
                            title="固件升级"
                            trigger="click"
                            visible={this.state.visible}
                            placement="leftBottom"
                            onVisibleChange={this.handleVisibleChange}
                        >
                            <img src={update} className='updateIcon'
                                style={{ cursor: 'pointer' }}
                                alt='update' />
                        </Popover>
                    </div>
                </div>
                {
                    ulshow && <ul className="searchList"
                        style={{
                            position: 'absolute',
                            left: 83,
                            width: '32vh'
                        }}
                    >
                        {
                            this.state.UrlArr && this.state.UrlArr.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.objectName}</span>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ fontSize: 12, color: 'rgba(99,99,99,0.9)' }}>{item.deviceId}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div style={{ width: '100%' }}>
                    <Table
                        columns={columns}
                        rowSelection={rowSelection}
                        dataSource={dataSource}
                        scroll={{ x: 2000 }}
                        loading={this.state.loading}
                        pagination={{
                            defaultPageSize: 10,
                            showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
                            //   showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    title='参数设置'
                    visible={this.state.modifShow}
                    closeModal={this.closeModal}
                    formItemList={formItemList}
                    width='43%'
                    submit={this.getNewShipData}
                >
                </ModalForm>
                <ModalForm
                    title='密码确认'
                    visible={this.state.PasswordconfirmationShow}
                    closeModal={this.closeModal1}
                    formItemList={PasswordconfirmationList}
                    width='35%'
                    submit={this.PasswordconfirmationSubmit}
                >
                </ModalForm>
                <Modal
                    title='参数查询'
                    visible={this.state.paramInquireVis}
                    onCancel={this.closeModal}
                    onOk={this.Parameterquerconfirmation}
                    width='50%'
                    className='Parameterquerconfirmation'

                >
                    <div style={{ padding: 10, borderStyle: 'solid', borderWidth: 0.1, borderColor: 'Rgba(235,235,235)' }}>
                        <Checkbox
                            style={{ float: 'right' }}
                            indeterminate={this.state.indeterminate1}
                            checked={this.state.checkAll1}
                            onChange={this.onCheckAllChange}
                        >
                            全部
                         </Checkbox>
                        <br></br>
                        <Checkbox.Group
                            className='plainOptions'
                            onChange={value => this.checkboxCLick(value)}
                            value={this.state.checkedList}
                            options={plainOptions}
                        >
                        </Checkbox.Group>
                    </div>

                    <div style={{ padding: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 0.1, borderColor: 'Rgba(235,235,235)' }}>
                        <span style={{ fontWeight: 700 }}>通用参数</span>
                        <Checkbox
                            style={{ float: 'right' }}
                            onChange={this.currencyAllChange}
                            indeterminate={this.state.indeterminate2}
                            checked={this.state.checkAll2}
                        >
                            全部
                         </Checkbox>
                        <br></br>
                        <Checkbox.Group
                            className='currencCss'
                            onChange={value => this.currencyCLick(value)}
                            value={this.state.currencyList}
                            options={currencyplainOptions}
                        >
                        </Checkbox.Group>
                    </div>
                    <div style={{ padding: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 0.1, borderColor: 'Rgba(235,235,235)' }}>
                        <span style={{ fontWeight: 700 }}>便携式北斗终端参数</span>
                        <Checkbox
                            style={{ float: 'right' }}
                            onChange={this.PortableAllChange}
                            indeterminate={this.state.indeterminate3}
                            checked={this.state.checkAll3}
                        >
                            全部
                         </Checkbox>
                        <br></br>
                        <Checkbox.Group
                            className='currencCss1'
                            onChange={value => this.PortableCLick(value)}
                            value={this.state.PortableList}
                            options={PortableplainOptions}
                        >
                        </Checkbox.Group>

                    </div>
                    <div style={{ padding: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 0.1, borderColor: 'Rgba(235,235,235)' }}>
                        <span style={{ fontWeight: 700 }}>北斗类设备参数</span>
                        <Checkbox
                            style={{ float: 'right' }}
                            onChange={this.BeidouAllChange}
                            indeterminate={this.state.indeterminate4}
                            checked={this.state.checkAll4}
                        >
                            全部
                         </Checkbox>
                        <br></br>
                        <Checkbox.Group
                            className='currencCss1'
                            onChange={value => this.BeidouCLick(value)}
                            value={this.state.BeidouList}
                            options={BeidouplainOptions}
                        >
                        </Checkbox.Group>
                    </div>
                    <div style={{ padding: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 0.1, borderColor: 'Rgba(235,235,235)' }}>
                        <span style={{ fontWeight: 700 }}>井盖设备参数</span>
                        <Checkbox
                            style={{ float: 'right' }}
                            onChange={this.ManholeAllChange}
                            indeterminate={this.state.indeterminate5}
                            checked={this.state.checkAll5}
                        >
                            全部
                         </Checkbox>
                        <br></br>
                        <Checkbox.Group
                            className='currencCss1'
                            onChange={value => this.ManholeCLick(value)}
                            value={this.state.ManholeList}
                            options={ManholeplainOptions}
                        >
                        </Checkbox.Group>
                    </div>
                </Modal>
                <ModalForms
                    title='指令下发'
                    formItemList={CommandissueList}
                    visible={this.state.CommandissueShow}
                    closeModal={this.closeModal}
                    width='30%'
                    submit={this.batchCommandSend}
                >
                </ModalForms>
                <ModalForms
                    title='固件升级'
                    formItemList={FirmwareupdateList}
                    visible={this.state.FirmwareupdateShow}
                    closeModal={this.closeModal}
                    width='30%'
                    submit={this.firmwareUpgrade}
                ></ModalForms>
                <Modal
                    title='日志'
                    visible={this.state.logVis}
                    onCancel={this.closeModal}
                >
                    <div>
                        <Steps progressDot current={1} direction="vertical">
                            <Step title="Finished" description="This is a description. This is a description." />
                            <Step title="Finished" description="This is a description. This is a description." />
                            <Step title="In Progress" description="This is a description. This is a description." />
                        </Steps>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default DeviceConfig
function newFunction() {
    return '数据服务器ip';
}
