import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { MultiPoint, Point } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Feature from 'ol/Feature';
import { getVectorContext } from 'ol/render';
import { easeOut } from 'ol/easing';
import { unByKey } from 'ol/Observable';
import {
    fromLonLat,
    get,
    transform,
    getTransform
  } from 'ol/proj.js';
var listenerKeyCList={};
var listener_postrenderC;
var listenerKeyA;
var listener_postrenderA;
let clickIdNow = 'first';
var feature;
var features={};
export default {
    addPointAnimation(map, layer, pointFeature) {
        feature = pointFeature;
        var source = layer.getSource();
        // var source = new VectorSource({
        //     wrapX: false
        //   });
        //   var vector = new VectorLayer({
        //     source: source
        //   });
        //   map.addLayer(vector);

        function addRandomFeature() {
            var x = Math.random() * 360 - 180;
            var y = Math.random() * 180 - 90;
            var geom = new Point(fromLonLat([x, y]));
            var feature = new Feature(geom);
            source.addFeature(feature);
        }

        var duration = 3000;
        var isFinish = false;
        var start;
        function flash(feature) {
            isFinish = false;
            start = new Date().getTime();
            listenerKeyA = map.map.on('postcompose', animate);

        }
        function animate(event) {
            var vectorContext = event.vectorContext;
            var frameState = event.frameState;
            var flashGeom = feature.getGeometry().clone();
            var elapsed = frameState.time - start;
            var elapsedRatio = elapsed / duration;
            // radius will be 5 at start and 30 at end.
            var radius = easeOut(elapsedRatio) * 25 + 5;
            var opacity = easeOut(0.6 - elapsedRatio);

            var style = new Style({
                image: new CircleStyle({
                    radius: radius,
                    stroke: new Stroke({
                        color: 'rgba(220,20,60, ' + opacity + ')',
                        width: 1 + opacity,
                    }),
                    fill: new Fill({
                        color: 'rgba(220,20,60, ' + opacity + ')',
                        width: 1 + opacity,
                    }),
                })
            });

            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
            if (elapsed > duration) {
                listenerKeyA = map.map.un('postcompose', animate);
                unByKey(listenerKeyA);
                isFinish = true;
                // return;
            }
            // tell OpenLayers to continue postrender animation
            // map.map.render();
        }
        function postrender_bind(e) {
            if (isFinish) {
                console.log(isFinish, e, '-----source.on(addfeature)-----------SelectPointAnimation------2----')
                flash(feature);
            }
        }
        // console.log(feature, '-----feature-----------SelectPointAnimation-----1-----')
        // flash(feature);
        map.map.on('click', function (e) {
            // if (listener_postrenderA) {
            //     // listener_postrenderA = map.map.un('postrender', postrender_bind);
            //     unByKey(listener_postrenderA);
            // }
            // if (listenerKeyA) {
            //     // listenerKeyA = map.map.un('postcompose', animate);
            //     unByKey(listenerKeyA);
            // }
            // if (listener_postrenderC) {
            //     // listener_postrenderC = map.map.un('postrender', postrender_bind);
            //     unByKey(listener_postrenderC);
            // }
            // if (listenerKeyCList && listenerKeyCList.length > 0) {
            //     listenerKeyCList.map((item,index)=>{
            //         unByKey(item);
            //     })
            // }
            let pixel = this.getEventPixel(e.originalEvent);
            this.forEachFeatureAtPixel(pixel, function (f) {
                console.log(f, '----------------forEachFeatureAtPixel---------------------')
                feature = f;
                listener_postrenderA = map.map.on('postrender', postrender_bind);
                flash(feature);
                // map.map.render();
            });
        });
        // source.on('addfeature', function (e) {
        //     feature = e.feature;
        //         flash(feature);
        // });
    },
    click_addPointAnimation(map, layer, pointFeature, clickId) {
        feature = pointFeature;
        features={
            ...features,
            [clickId]:pointFeature,
        }
        var source = layer.getSource();
        // var source = new VectorSource({
        //     wrapX: false
        //   });
        //   var vector = new VectorLayer({
        //     source: source
        //   });
        //   map.addLayer(vector);

        function addRandomFeature() {
            var x = Math.random() * 360 - 180;
            var y = Math.random() * 180 - 90;
            var geom = new Point(fromLonLat([x, y]));
            var feature = new Feature(geom);
            source.addFeature(feature);
        }

        var duration = 3000;
        var isFinish = false;
        var start;
        function flash() {
            isFinish = false;
            start = new Date().getTime();
            if (typeof(listenerKeyCList[clickId])=='undefined') {
                let listenerKeyC = map.map.on('postcompose', animate);
                listenerKeyCList = {
                    ...listenerKeyCList,
                    [clickId]: listenerKeyC,
                }
            }

        }
        function animate(event) {
            var vectorContext = event.vectorContext;
            var frameState = event.frameState;
            var flashGeom = feature.getGeometry().clone();
            var elapsed = frameState.time - start;
            var elapsedRatio = elapsed / duration;
            // radius will be 5 at start and 30 at end.
            var radius = easeOut(elapsedRatio) * 25 + 20;
            var opacity = easeOut(0.6 - elapsedRatio);

            var style = new Style({
                image: new CircleStyle({
                    radius: radius,
                    stroke: new Stroke({
                        color: 'rgba(220,20,60, ' + opacity + ')',
                        width: 1 + opacity
                    }),
                    fill: new Fill({
                        color: 'rgba(220,20,60, ' + opacity + ')',
                        width: 1 + opacity,
                    }),
                })
            });
            if (source) {
                source.clear();
            }
            Object.keys(features).map((key)=>{
                let value = features[key];
                value.setStyle(style);
                source.addFeature(value);
            })
            layer.setSource(source);
            // layer.setVisible(true);
            // vectorContext.setStyle(style);
            // vectorContext.drawGeometry(flashGeom);
            if (elapsed > duration) {
                // listenerKeyC = map.map.un('postcompose', animate);
                // unByKey(listenerKeyC);
                isFinish = true;
                // return;
            }
            // tell OpenLayers to continue postrender animation
            // map.map.render();
        }
        function postrender_bind(e) {
            if (isFinish) {
                flash();
            }
        }
        // console.log(feature, '-----feature-----------SelectPointAnimation-----1-----')
        // flash(feature);
        function startSelectAnimation() {
            clickIdNow = clickId;
            // if (listener_postrenderA) {
            //     // listener_postrenderA = map.map.un('postrender', postrender_bind);
            //     unByKey(listener_postrenderA);
            // }
            // if (listenerKeyA) {
            //     // listenerKeyA = map.map.un('postcompose', animate);
            //     unByKey(listenerKeyA);
            // }
            // if (listener_postrenderC) {
            //     // listener_postrenderC = map.map.un('postrender', postrender_bind);
            //     unByKey(listener_postrenderC);
            // }
            // if (listenerKeyCList && listenerKeyCList.length > 0) {
            //     listenerKeyCList.map((item,index)=>{
            //         unByKey(item);
            //     })
            // }
            listener_postrenderC = map.map.on('postrender', postrender_bind);
            flash();
            // map.map.render();
        }
        // if (clickIdNow=='first' || clickIdNow!=clickId ) {
        startSelectAnimation();
        // }
    },
    clearAllListener(map) {
        if (listener_postrenderA) {
            unByKey(listener_postrenderA);
        }
        if (listenerKeyA) {
            unByKey(listenerKeyA);
        }
        if (listener_postrenderC) {
            unByKey(listener_postrenderC);
        }
        if (listenerKeyCList && Object.keys(listenerKeyCList).length > 0) {
            Object.keys(listenerKeyCList).map((key)=>{
                unByKey(listenerKeyCList[key]);
            })
            listenerKeyCList={}
        }
    }
}