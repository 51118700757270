import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col, Icon, message, Button } from 'antd';
import { batchCommandSend } from "../../data/dataStore"
import './detailModal.css'
import axios from 'axios'
import moment from "moment";
const maths = {
    'chuan': "",
    'chuanRed': "",
    'gpss': "",
    'locusSmall': "",
    'shuaxin': "",
    'warning': "",
    'msf': "",
    'kong': "",
    'yi': "",
    'er': '',
    'san': '',
    'man': '',
}
const key = 'updatable';
Object.keys(maths).forEach(item => maths[item] = require("../../assets/img/" + item + ".png"))
export class DetailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            switch_Button: false,
            visble_label: 302501,
            label_01: '',
            label_02: '',
        }
        this.batchCommandSend = this.batchCommandSend.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)

    }

    componentDidMount() {
    }
    async batchCommandSend() {
        let dataship = this.props.data

        let params = {
            deviceId: dataship.deviceId,
            deviceType: dataship.deviceType,
            commandCode: 5,
        }
        let data = await batchCommandSend(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("示位成功")
            this.setState({

            })
        } else if (data && data.code) {

        }
    }
    // handleClick = () => {
    //     let device = this.props.data.deviceStatusList[0]
    //     if (device.deviceType == 301510) {
    //         fetch(`http://192.168.2.126:8080/PJWorkNum/api/device/configMonitor.json?userName=admin&password=123456&transmitFreq=&deviceNos=${device.deviceId}`)
    //             .then((res) => {
    //                 return res.json()
    //             })
    //             .then(data => {
    //                 console.log(data, 'getdata1')
    //                 if (data.code === 0) {
    //                     message.success('示位操作已开启!')
    //                 }
    //                 return data;
    //             })
    //             .catch(ex => console.log(ex, 'dicteeee'))
    //     } else if (device.deviceType == 301011) {
    //         fetch(`http://192.168.2.164:8899/${device.deviceId}/turn/on/light/tcp`)
    //             .then((res) => {
    //                 return res.json()
    //             })
    //             .then(data => {
    //                 console.log(data, 'getdata2')
    //                 if (data.code === 0) {
    //                     message.success('示位操作已开启!')
    //                 }
    //                 return data;
    //             })
    //             .catch(ex => console.log(ex, 'dicteeee'))
    //     }
    // }
    handleRefresh = () => {
        let device = this.props.data.deviceStatusList[0]
        if (device.deviceType == 301510) {
            fetch(`http://192.168.2.126:8080/PJWorkNum/api/device/sendUpdateCommand.json?userName=admin&password=123456&deviceNos=${device.deviceId}`)
                .then((res) => {
                    return res.json()
                })
                .then(data => {
                    console.log(data, 'getdata1')
                    if (data.code === 0) {
                        message.success('刷新成功！')
                    }
                    return data;
                })
                .catch(ex => console.log(ex, 'dicteeee'))
        }
    }
    getDictName = (type) => {
        let dictData = JSON.parse(sessionStorage.getItem('dictData'))
        let name;
        dictData && dictData.forEach((item) => {
            if (item.typeCode === type) {
                name = item.typeName
            }
        })
        return name;
    }
    xuanzedianliang = (data) => {
        console.log(data, 'xxxxxx111')
        let myimg = maths.man;
        if (data == 0) {
            myimg = maths.kong;
        }
        else if (data > 0 && data < 20) {
            myimg = maths.yi;
        }
        else if (data >= 20 && data < 40) {
            myimg = maths.er;
        }
        else if (data >= 40 && data < 60) {
            myimg = maths.san;
        }
        else if (data >= 60) {
            myimg = maths.man;
        }
        // switch (data){
        //     case 0:
        //         myimg= maths.kong;
        //     break;
        //     case data>0&&data<20:
        //         myimg= maths.yi;
        //     break;
        //     case data>=20&&data<40:
        //         myimg= maths.er;
        //     break;
        //     case data>=40&&data<60:
        //         myimg= maths.san;
        //     break;
        //     case data>=60:
        //         myimg= maths.man;
        //     break;
        // }
        return myimg;
    }
    clickSwitchLabel1 = () => {
        this.setState({
            visble_label: 302501
        })
    }
    clickSwitchLabel2 = () => {
        this.setState({
            visble_label: 302500
        })
    }
    //度°分′秒″转度
    ToDigital = (strDu, strFen, strMiao, len) => {
        len = (len > 6 || typeof (len) == "undefined") ? 6 : len;//精确到小数点后最多六位   
        strDu = (typeof (strDu) == "undefined" || strDu == "") ? 0 : parseFloat(strDu);
        strFen = (typeof (strFen) == "undefined" || strFen == "") ? 0 : parseFloat(strFen) / 60;
        strMiao = (typeof (strMiao) == "undefined" || strMiao == "") ? 0 : parseFloat(strMiao) / 3600;
        var digital = strDu + strFen + strMiao;
        if (digital == 0) {
            return "";
        } else {
            return digital.toFixed(len);
        }

    }
    //度转度°分′秒″
    ToDegrees = (val, type) => {
        if (typeof (val) == "undefined" || val == "") {
            return "";
        }
        val = String(val);
        var i = val.indexOf('.');
        var strDu = i < 0 ? val : val.substring(0, i);//获取度
        var strFen = 0;
        var strMiao = 0;
        if (i > 0) {
            var strFen = "0" + val.substring(i);
            strFen = strFen * 60 + "";
            i = strFen.indexOf('.');
            if (i > 0) {
                strMiao = "0" + strFen.substring(i);
                strFen = strFen.substring(0, i);//获取分
                strMiao = strMiao * 60 + "";
                i = strMiao.indexOf('.');
                strMiao = strMiao.substring(0, i + 4);//取到小数点后面三位
                strMiao = parseFloat(strMiao).toFixed(2);//精确小数点后面两位
            }
        }
        let result = '';
        switch (type) {
            case 'lon':
                result = strDu + "°" + strFen + "′" + strMiao + '″E';
                break;
            case 'lat':
                result = strDu + "°" + strFen + "′" + strMiao + '″N';
                break;

            default:
                break;
        }
        return result;
    }

    render() {
        let { data } = this.props;
        let { switch_Button, visble_label, label_02, label_01 } = this.state
        let basicData = {};
        // if (this.props.data?.lbsPositionList && this.props.data.lbsPositionList.length == 1) {
        //     basicData = this.props.data.lbsPositionList[0] ? this.props.data.lbsPositionList[0] : {}
        // } else if (this.props.data?.lbsPositionList?.length == 2) {
        //     switch_Button = true
        //     this.props.data.lbsPositionList.map((item) => {
        //         if (item.cardNumType == visble_label) {
        //             basicData = item
        //         }
        //         if (item.cardNumType == 302501) {
        //             label_01 = item.deviceId
        //         }
        //         if (item.cardNumType == 302500) {
        //             label_02 = item.deviceId
        //         }
        //     })
        //
        //     //     let cardNumType0 = this.props.data.lbsPositionList[0].cardNumType ? this.props.data.lbsPositionList[0].cardNumType : {}
        //     //    if(visble_label && cardNumType0 && cardNumType0  == 302501 ){
        //     //     basicData = this.props.data.lbsPositionList[0]? this.props.data.lbsPositionList[0] : {}
        //     //    }else{
        //     //     basicData = this.props.data.lbsPositionList[1]? this.props.data.lbsPositionList[1] : {}
        //     //    }
        // }
        if (this.props.data) {
            const {
                dir:heading,
                lat,
                lon,
                speed,
                locationTime
            }=this.props.data
            basicData.heading = heading;
            basicData.lat = lat;
            basicData.lon = lon;
            basicData.speed = speed;
            basicData.locationTime = moment(locationTime).format("YYYY-MM-DD  h:mm:ss");
        }
        console.log('basicData----------------',basicData);
        // let basicData1 = this.props.data?.lbsPositionList[1] ? this.props.data.lbsPositionList[1] : {};
        let sundevicd = basicData && basicData.deviceId ? basicData && basicData.deviceId : "-";
        // let sundevicd1 = basicData1 && basicData1.deviceId ? basicData1 && basicData1.deviceId : "-"
        // let sundevicdsun = ''
        // if (sundevicd1 == '0') {
        //     sundevicdsun = sundevicd
        // } else {
        //     sundevicdsun = sundevicdsun
        // }
        // console.log(sundevicd, sundevicd1, sundevicdsun, 'sundevicd')
        let devData = {};
        // if (this.props.data?.deviceStatusList.length == 1) {
        //     devData = this.props.data.deviceStatusList[0] ? this.props.data.deviceStatusList[0] : {}
        // } else if (this.props.data?.deviceStatusList.length == 2) {
        //     switch_Button = true
        //     this.props.data.deviceStatusList.map((item) => {
        //         if (item.cardNumType == visble_label) {
        //             devData = item
        //         }
        //     })
        //     //     let cardNumType0 = this.props.data.deviceStatusList[0].cardNumType ? this.props.data.deviceStatusList[0].cardNumType : {}
        //     //    if(visble_label && cardNumType0 && cardNumType0 == 302501 ){
        //     //     devData = this.props.data.deviceStatusList[0]? this.props.data.deviceStatusList[0] : {}
        //     //    }else{
        //     //     devData = this.props.data.deviceStatusList[1]? this.props.data.deviceStatusList[1] : {}
        //     //    }
        // }
        let myImg = this.xuanzedianliang(devData.powerRate) ? this.xuanzedianliang(devData.powerRate) : '';
        // let shipTypename = this.getDictName(data.shipType);
        console.log('this.props===========',this.props)
        let shipTypename = this.getDictName(data.type);
        let posModeName = '';
        let disassemble = '';
        let gpsStatusName = '';
        let posStatusName = '';
        let moveStatusName = '';
        let powerRateName = '';
        let urgencyReportStateName = '';
        let voltage;
        let Fallingwateralarm = ''
        if (devData) {
            let arr = [devData.voltage1, devData.voltage2, devData.voltage3]
            arr.sort(function (a, b) {
                return a - b;
            })
            voltage = arr[arr.length - 1];
            switch (devData.fallingWaterStatus) {
                case 0: Fallingwateralarm = '正常'; break;
                case 1: Fallingwateralarm = '落水'; break;
                case 1: Fallingwateralarm = false; break;
            }
        }
        //  if(devData){ if (devData.powerRate = 0){  
        //     powerRateName = "../../assets/img/00.png"
        // }else if(devData.powerRate>0 && devData.powerRate <= 40){
        //     powerRateName = "../../assets/img/20.png"
        // }else if(devData.powerRate>40 && devData.powerRate <= 60){
        //     powerRateName = "../../assets/img/40.png"
        // }
        // else if(devData.powerRate>60 && devData.powerRate <= 80){
        //     powerRateName = "../../assets/img/60.png"
        // }
        // else{
        //     powerRateName = "../../assets/img/1000.png"
        // }}
        switch (devData.moveStatus) {
            case 0: moveStatusName = '静止'; break;
            case 1: moveStatusName = '开始移动'; break;
            case 2: moveStatusName = '停止移动'; break;
            case 4: moveStatusName = '移动'; break;
            case -1: moveStatusName = '默认'; break;
        }
        switch (devData.emergencyStatus) {
            case 0: urgencyReportStateName = '正常'; break;
            case 1: urgencyReportStateName = '告警'; break;
        }
        switch (devData.gpsStatus) {
            case 0: gpsStatusName = '正常'; break;
            case 1: gpsStatusName = '故障'; break;
        }
        switch (basicData.posStatus) {
            case 0: posStatusName = '未定位'; break;
            case 1: posStatusName = '已定位'; break;
        }
        switch (devData.disassembleStatus) {
            case 0: disassemble = '正常'; break;
            case 1: disassemble = '拆卸'; break;
        }
        switch (basicData.posMode) {
            case 0: posModeName = 'GPS'; break;
            case 1: posModeName = 'BDS'; break;
            case 2: posModeName = 'LBS'; break;
            case 3: posModeName = 'GPS/BDS'; break;
        }
        return (
            <div className="detailModal" id='detail'>
                <div className="title">
                    <h3>
                        <img src={ maths.chuan } />
                        {/*{ data.objectName ? data.objectName : '' }*/}
                        { data.shipName ? data.shipName : '' }
                    </h3>
                    <Icon type="close" onClick={ () => this.props.closeModal() } />
                </div>
                { switch_Button && <div className='Switch_label_style'>
                    <Button onClick={ this.clickSwitchLabel1 }>{ label_01 ? label_01 : '' }</Button>  <Button onClick={ this.clickSwitchLabel2 }>{ label_02 ? label_02 : '' }</Button>
                </div> }

                <Row className="content">
                    <Col span={ 24 } className='msgTitle'>
                        <i className="bulei"></i>
                        <h4>基本信息</h4>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span >船舶类型：</span><span>{ shipTypename ? shipTypename : '-' }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>方向：</span><span>
                            { basicData && basicData.heading ? basicData && basicData.heading : '-' }
                        </span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>纬度：</span><span>{ basicData && basicData.lat ? basicData && this.ToDegrees(basicData.lat, 'lat') : '-' }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>经度：</span><span> { basicData && basicData.lon ? basicData && this.ToDegrees(basicData.lon, 'lon') : "-" }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>速度：</span><span>{ parseInt(basicData && (basicData.speed) / 1.852) ? parseInt(basicData && (basicData.speed) / 1.852) : "-" }(节)</span>
                    </Col>

                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>定位方式：</span><span>{ posModeName ? posModeName : '-' }
                        </span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>定位状态：</span><span >{ posStatusName ? posStatusName : '-' }</span>
                    </Col>

                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>定位精度：</span><span>
                            { (devData && devData.hdop) && ((devData.hdop != -999999999) ? (devData && devData.hdop) : '-') }

                        </span>
                    </Col>

                    {/* <Col span={12}>
                        <span>航程：</span><span>{basicData&&basicData.}</span>
                    </Col> */}
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>运动状态：</span><span>{ moveStatusName ? moveStatusName : '-' }</span>
                    </Col>
                    {/* <Col span={12}>
                        <span>进出港次数：</span><span>{basicData&&basicData.}</span>
                    </Col> */}

                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>定位时间：</span><span>{ basicData && basicData.locationTime ? basicData && basicData.locationTime : '-' }</span>
                    </Col>
                    {/* <Col span={12}>
                        <span>违规次数：</span><span style={{color:'#EA0D0D'}}>{basicData&&basicData.}</span>
                    </Col> */}
                </Row>
                <Row className="content1">
                    <Col span={ 24 } className='msgTitle'>
                        <i className="bulei"></i>
                        <h4>设备信息</h4>
                    </Col>
                    <Col span={ 12 } style={ { width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } } className='deviceId'>
                        {/*<span>标签：</span><span title={sundevicdsun ?  111:222 }>{ sundevicd }</span>*/}
                        <span>标签：</span><span title={111 }>{ sundevicd }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>电量：</span><img src={ myImg } />
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span >拆卸状态：</span><span>{ disassemble ? disassemble : '-' }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>电压：</span><span>{ voltage ? voltage : "-" }(v)</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span >GPS状态：</span><span>{ gpsStatusName ? gpsStatusName : '-' }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>
                            信号强度：
                        </span><span>
                            { (devData && devData.signalStrength) && ((devData.signalStrength != -999999999) ? (devData && devData.signalStrength) : '-') }
                        </span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }> <span>卫星数量：</span><span>{ devData && devData.satelliteCount && (devData.satelliteCount != -999999999) ? (devData && devData.satelliteCount) : "-" }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>X轴：</span><span>                                { devData && devData.xValue ? devData && devData.xValue : "-" }
                        </span>
                    </Col>

                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>
                            太阳能电池电压：

                        </span><span>
                            { devData && devData.voltage3 ? devData && devData.voltage3 : '-' }
                        </span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>Y轴：</span><span>{ devData && devData.yValue ? devData && devData.yValue : "-" }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>倾斜角度：</span><span>{ devData && devData.angle }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 200 } }>
                        <span>Z轴：</span><span> { devData && devData.zValue }</span>
                    </Col>
                    <Col span={ 12 } style={ { width: 150 } }>
                        <span>SOS告警：</span><span >{ urgencyReportStateName ? urgencyReportStateName : '-' }</span>
                    </Col>
                    { Fallingwateralarm ? <Col span={ 12 } style={ { width: 200 } }>
                        <span>落水报警：</span><span>{ Fallingwateralarm }</span>
                    </Col> : '' }
                </Row>
                <Row className="content_bottom">
                    {/* <Col span={3}><img src={maths.msf}/> <Link to={{pathname:"/shipDetail",state:this.props.data}}>详情</Link></Col> */ }
                    {/*<Col span={ 5 }><img src={ maths.msf } /> <span style={ { cursor: 'pointer' } } onClick={ () => this.props.showDetail() }>详情</span></Col>*/}
                    {/*<Col span={ 1 }><b>|</b></Col>*/}
                    <Col span={ 5 }><img src={ maths.locusSmall } /> <span style={ { cursor: 'pointer' } } onClick={ () => { this.props.clickTool(0, 'locus_detail') } }>轨迹</span></Col>
                    {/*<Col span={ 1 }><b>|</b></Col>*/}
                    {/*<Col span={ 5 }><img src={ maths.gpss } /> <span style={ { cursor: 'pointer' } } onClick={ this.batchCommandSend }>示位</span></Col>*/}
                    {/*<Col span={ 1 }><b>|</b></Col>*/}
                    {/*<Col span={ 5 }><img src={ maths.shuaxin } /> <span style={ { cursor: 'pointer' } } onClick={ this.handleRefresh }>刷新</span></Col>*/}
                </Row>
            </div>
        )
    }
}

export default DetailModal
