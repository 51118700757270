import React, { Component } from 'react'

import TrackQuerySelectArea from '../TrackQuery/TrackQuerySelectArea'

import config_JSON from './default_JSON/Default_map.json'






class AreaTrajectorySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div>
                <TrackQuerySelectArea config_JSON={config_JSON} history={this.props.history}/>
            </div>
        );
    }
}
export default AreaTrajectorySearch;