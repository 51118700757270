// 北斗综合查询
import React, { Component } from 'react'
import './css/system.css'
import AddFormItem from './components/addFormItem/AddFormItem'
import {Table, Button, Modal, Input, Icon, Select, message, DatePicker} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import {CarryOutOutlined, FolderOpenOutlined} from "@ant-design/icons";
import config_JSON from ".././default_JSON/Default_map.json";
import {
    selectCardDetails,
    selectByRegion,
    exportBdInfoList
} from '../../data/dataStore'
import $ from "jquery";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search, TextArea} = Input;
let format = 'YYYY-MM-DD HH:mm:ss';
//上个月的今天
const piker1 = moment(moment().subtract(1,'month').format('YYYY-MM-DD'));
//今天
const piker2 = moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss');
// 滚动条参数
const scroll = {
    // 如果最终结果表格内容与表格头部无法对齐。
    // 表格头需要增加空白列，弹性布局
    width: '100%',
    // 最大高度，内容超出该高度会出现滚动条
    height: 515,
}
class BdInfoSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            regionId: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            cardNumber:[],
            shipName:'',
            regionName:'',
            reportTime:'',

            //上个月今天
            // startTime: moment(piker1).format('YYYY-MM-DD HH:mm:ss'),
            //今天的年月日
            // endTime: moment(piker2).format('YYYY-MM-DD HH:mm:ss'),
            // 上个月今天
            startTime: null,
            //今天的年月日
            endTime: null,
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: null,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            userData: [],
            userGroupData: [],
            testData:[],
            loadingServiceFlag: false,
            trajectoryDateList: [
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
            ],//上报时间
            // 空树形结构数组
            treeData : [

            ],
            //树选中的值
            value: undefined,
            visible: false,
            blur:false,
            textAreaValue:[],
            downloadFileUrl:'',
        }
        this.selectCardDetails = this.selectCardDetails.bind(this)
        this.selectByRegion = this.selectByRegion.bind(this)
    }
    async downloadFile(value, type, pageSizeApi, coloums,id){
        // await exportBdInfoList()
        // const {downloadFileUrl}=this.state;
        // if (downloadFileUrl !==''){
        //     // window.open(downloadFileUrl);
        //     location.href=downloadFileUrl
        // }else {
        //     message.warning('当前没有可下载的报表')
        // }
        this.setState({ loading: true })
        let {startTime,endTime,pagination } = this.state;
        let params = {
            cardNumber: this.state.textAreaValue,
            shipName: this.state.shipName,
            startTime,
            endTime,
            // pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            // pageSize: pagination.pageSize,
            regionId: this.state.regionId
        }
        let data = await exportBdInfoList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        loading: false,
                        // downloadFileUrl:data.message,
                    }
                )
            } else {
                this.setState({
                    loading: false,
                    // downloadFileUrl:data.message,
                })
            }
            const downloadFileUrl=data.message;
            if (downloadFileUrl !==''){
                // window.open(downloadFileUrl);
                location.href=downloadFileUrl
            }else {
                message.warning('当前没有可下载的报表')
            }
        } else if (data && data.code == 401) {
            this.props.history.push('/login')
        }

    }
    onChangeBdCard = ({ target: { value } }) => {
        this.setState({textAreaValue:value});
    };
    showModal = () => {
        this.setState({
            visible: true,
            textAreaValue:[],
        });
    };

    handleOk = e => {
        // console.log("this.state.textAreaValue",this.state.textAreaValue);
        const value = this.state.textAreaValue
        const arr = value.length!==0?value.split('\n'):[];
        // console.log('arrarrarrarr',arr)
        this.setState({
            visible: false,
            textAreaValue:arr
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            textAreaValue:[]
        });
    };
    //暂时没用
    // static defaultProps = {
    //     // title: "123",
    //     treeData: [
    //
    //     ],
    // }
    /**
     * 递归生成数据  因为后端给的字段没有和ant design相关的 title value等属性值
     * 所以需要递归修改data属性值，配合treeSelect规范数据
     * @param {Object} data 原始数据
     */
    handleData = (data) => {
        const item = [];
        data.map((list) => {
            const newData = {};
            newData.value = list.regionName;
            newData.id = list.id
            newData.title = list.regionName;
            newData.children = list.children ? this.handleData(list.children) : []; // 如果还有子集，就再次调用自己
            item.push(newData);
            return false
        });
        //console.log("<<<"+JSON.stringify(item))
        return item;
    }
    onChange = value => {
        console.log("<<<"+value);
        this.setState({
            value,
            regionName:value
        });
    };
    onSelect = (value,node,extra) => {
        console.log("<<<"+(node['props']['id']))
        console.log("<<<"+value,node.toString(),extra )
        this.setState({
            node,
            regionId:node['props']['id']
        })
    }

    selectByTime = (e, value) => {
        let startTime = value[0] + " 00:00:00";
        let endTime = value[1];
        endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
        console.log("<<<"+startTime)
        console.log("<<<"+endTime)
        this.setState({
            startTime: startTime==' 00:00:00'?null:startTime,
            endTime: endTime==' 23:59:59'?null:endTime,

        })
    }


    /**
      *
      *
      *修改左边的icon
      *
      * */
    // renderTreeNode  = (data) => data.map(item => {
    //     console.log("<<<"+item.children)
    //     if (item.children) {
    //         return (
    //             <treeNode
    //                 key={item.id}
    //                 title={item.regionName}
    //             >
    //                 item['icon'] = [<FolderOpenOutlined key={item.title}/>]
    //                 this.renderTreeNode(item.children)
    //
    //
    //             </treeNode>
    //         )
    //
    //     } else {
    //         return (
    //             <treeNode
    //                 key={item.id}
    //                 title={item.regionName}
    //             >
    //                 item['icon'] = [<FolderOpenOutlined key={item.title}/>]
    //                 this.renderTreeNode(item.children)
    //
    //
    //             </treeNode>
    //         )
    //         item['icon'] = [<CarryOutOutlined key={item.title}/>]
    //     }
    //     return item
    // })

    componentDidMount() {
        this.selectByRegion()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    // 查询区域树形结构
    async selectByRegion(value, type) {
        let params = {

        }
        let data = await selectByRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            //console.log(">>>>>>>>>>selectByRegion"+JSON.stringify(data))
            let treeSelectData = this.handleData(data.rows);
            if (type === 'select') {
                this.setState({
                    treeData: treeSelectData,
                    }
                )
            } else {
                this.setState({
                    treeData: treeSelectData,
                })
            }
            //console.log(">>>>>>>>>>selectByRegion<<<<<<<<<<<<<<<<"+JSON.stringify(this.state.treeData))
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectCardDetails()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.selectCardDetails(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectCardDetails(names)
        this.setState({
            name: names
        })
    }

    // 分页查询
    async selectCardDetails(value, type, pageSizeApi, coloums,id) {
        this.setState({ loading: true })
        let {startTime,endTime,pagination } = this.state;
        let params = {
            cardNumber: this.state.textAreaValue,
            shipName: this.state.shipName,
            startTime,
            endTime,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
            regionId: this.state.regionId
        }
        let data = await selectCardDetails(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UallArr: data.rows,
                    ulshow: true,
                    loading: false,
                    downloadFileUrl:data.message,
                }
                )
            } else {
                    this.setState({
                        ulshow: false,
                        dataSource: data.rows,
                        pageTotal: data.total,
                        loading: false,
                        downloadFileUrl:data.message,

                    })
                }
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectCardDetails()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

    modify = (data) => {
        this.setState({
            modify: true,
            modifyData: data,
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }

    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            //添加的对话框
            addShow: false,
            //删除提示框
            deleteShow: false,
            //标签
            Label: false,
            //修改的对话框
            modify: false,
            //
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }
    exportDefault = () => {
        this.setState({
        }, () => {
            this.exportShipTemplate()
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectCardDetails()
        })
        console.log("455"+pagination, filters, sorter, 'sorter')
    }
    getNewShipData = (value) => {
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.id = modifyData.id
        this.updateShipDetail(value)
        console.log(value, 'getNewShipData')
    }
    // 获取文件后缀
    getFileSuffix = name => {
        const index = name.lastIndexOf(".");
        const len = name.length;
        return name.substring(index, len) || "-";
    };

    beforeUpload = file => {
        const suffix = this.getFileSuffix(file.name);
        const isLt2M = file.size / 1024 / 1024 < 2;
        const isValid = suffix === ".xlsx" || suffix === ".xls";
        if (!isValid) {
            message.warn("上传失败，请选择.xlsx或.xls格式");
        }
        //   if (!isLt2M) {
        //     message.warn("上传失败，大小不能超过2M");
        //   }
        return isValid;
    }

    //筛选
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            let { objectNameArr } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 100 }} placeholder={'请选择'}
                        showSearch
                        multiple
                    >
                        {
                            objectNameArr && objectNameArr.regionNames.map((item) => (
                                <Option
                                    onClick={this.clickSearchData1.bind(this, item)}
                                    value={item} >{item}</Option>
                            ))
                        }
                    </Select>,
          </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectCardDetails()
        });
    }

    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    // 点击表格管理的事件
    clickManage = () => {
        this.getUgroupTree(1)
        this.setState({
            manageShow: true,
            manageData: '1'
        })
    }
    showTrackDetail=(record)=>{
        let Map_Attribute = config_JSON.map_componentConfig.attribute;
        // 获取pages根目录的default_JSON文件夹下的Default_map.json
        console.log("MapJson>>>",">>>>"+JSON.stringify(Map_Attribute));
        console.log("record.lon----当前经度-----",">>>"+JSON.stringify(record.lon))
        console.log("record.lat----当前纬度-----",">>>"+JSON.stringify(record.lat))
        const lonText = JSON.stringify(record.lon);
        const latText = JSON.stringify(record.lat);
        // const strLon = lonText*1;
        // const strLat = latText*1;
        // console.log("record.lon----{当前经度}-----",">>>"+strLon)
        // console.log("record.lat----{当前纬度}-----",">>>"+strLat)
        // "[110.71558333333334, 39.244350000000004]"
        //let modifyJsonMap = Map_Attribute["center"] = "['"+lonText+"','"+latText+"']"
        let modifyJsonMap = Map_Attribute["center"] = JSON.parse(JSON.stringify('['+lonText+','+latText+']'));
        //let modifyJsonMap = Map_Attribute["center"] = [strLon,strLat];
        //let modifyJsonMap = Map_Attribute["target"] = "MAP_TARGET"
        console.log("MapJson>>>after--------",">>>>"+JSON.stringify(modifyJsonMap));
        console.log('record.cardNumber--------',record.cardNumber);
        console.log('record.shipName--------',record.shipName);
        console.log(record,'record-----')
        // this.setState({
        //     cardNumber:record.cardNumber,
        //     shipName:record.shipName,
        // })
        // this.props.history.push('/home',{
        //     cardNumber:record.cardNumber,
        //     shipName:record.shipName,
        // })

        this.props.history.push({
            pathname: '/home', state: {
                cardNumber:record.cardNumber,
                shipName:record.shipName,
            }})
    }
    render() {
        let { dataSource, modifyData,startTime,endTime,trajectoryDateList, pageTotal, textAreaValue} = this.state;

        let {treeData, ...rest } = this.props;
        //列表字段
        const columns = [
            {
                title: '所属区域',
                key: 'regionName',
                dataIndex: 'regionName',
            },
            {
                title: '卡号',
                key: 'cardNumber',
                dataIndex: 'cardNumber',
            },
            {
                title: '船名',
                key: 'shipName',
                dataIndex: 'shipName',
                // render: (data) => {
                //     return (
                //         <div className="ellipsis" style={{
                //             float: 'float',
                //             maxWidth: '100px',
                //             overflow: 'hidden',
                //             whiteSpace: 'nowrap',
                //             textOverflow: 'ellipsis',
                //         }} title={data}
                //         >
                //             {data}
                //         </div>
                //     )
                // }
            },
            {
                title: '船主',
                key: 'owner',
                dataIndex: 'owner',
            },
            {
                title: '电话',
                key: 'phone',
                dataIndex: 'phone',
                // render: (data) => {
                //     return (
                //         <div className="ellipsis" style={{
                //             float: 'float',
                //             maxWidth: '100px',
                //             overflow: 'hidden',
                //             whiteSpace: 'nowrap',
                //             textOverflow: 'ellipsis',
                //         }} title={data}
                //         >
                //             {data}
                //         </div>
                //     )
                // }
            },
            {
                title: '北斗型号',
                key: 'deviceType',
                dataIndex: 'deviceType',
                // render: (data) => {
                //     return (
                //         <div className="ellipsis" style={{
                //             float: 'float',
                //             maxWidth: '100px',
                //             overflow: 'hidden',
                //             whiteSpace: 'nowrap',
                //             textOverflow: 'ellipsis',
                //         }} title={data}
                //         >
                //             {data}
                //         </div>
                //     )
                // }
            },
            {
                title: '设备编号',
                key: 'deviceNumber',
                dataIndex: 'deviceNumber',
                // render: (data) => {
                //     return (
                //         <div className="ellipsis" style={{
                //             float: 'float',
                //             maxWidth: '100px',
                //             overflow: 'hidden',
                //             whiteSpace: 'nowrap',
                //             textOverflow: 'ellipsis',
                //         }} title={data}
                //         >
                //             {data}
                //         </div>
                //     )
                // }
            },
            {
                title: '安装日期',
                key: 'installTime',
                dataIndex: 'installTime',
                // render: (data) => {
                //     return (
                //         <div className="ellipsis" style={{
                //             float: 'float',
                //             maxWidth: '100px',
                //             overflow: 'hidden',
                //             whiteSpace: 'nowrap',
                //             textOverflow: 'ellipsis',
                //         }} title={data}
                //         >
                //             {data}
                //         </div>
                //     )
                // }
            },
            {
                title: '最新报位',
                key: 'locationTime',
                dataIndex: 'locationTime',
                render: (locationTime) => {
                    if (locationTime != null){
                        let time = moment(parseInt(locationTime)).format('YYYY-MM-DD HH:mm:ss')
                        return (
                            <div className="ellipsis" style={{
                                float: 'float',
                                maxWidth: '100px',

                                textOverflow: 'ellipsis',
                            }} title={time}
                            >
                                {time}


                            </div>
                        )
                    }else {
                        return ''
                    }
                },
                sorter: (a, b) => a.locationTime - b.locationTime,
            },
            {
                title: '经度',
                key: 'lon',
                dataIndex: 'lon',
                // render: (lon) => {
                //     if(lon != null){
                //         let txt =  lon.toString().substring(0,9)
                //         return (
                //             <div className="ellipsis" style={{
                //                 float: 'float',
                //                 maxWidth: '100px',
                //                 overflow: 'hidden',
                //                 whiteSpace: 'nowrap',
                //                 textOverflow: 'ellipsis',
                //             }} title={txt}
                //             >
                //                 {txt}
                //             </div>
                //         )
                //     }else {
                //         return ''
                //     }
                //
                // }

            },
            {
                title: '纬度',
                key: 'lat',
                width:'50',
                dataIndex: 'lat',
                // render: (lat) => {
                //     if(lat != null) {
                //         let txt = lat.toString().substring(0, 9)
                //         return (
                //             <div className="ellipsis" style={{
                //                 float: 'float',
                //                 maxWidth: '100px',
                //                 overflow: 'hidden',
                //                 whiteSpace: 'nowrap',
                //                 textOverflow: 'ellipsis',
                //             }} title={txt}
                //             >
                //                 {txt}
                //             </div>
                //         )
                //     }else {
                //         return ''
                //     }
                // }
            },
            {
                title: '是否船电',
                key: 'electricity',
                dataIndex: 'electricity',
                render: (data) => {
                    if (data == '0'){
                        return (
                            <div className="ellipsis" style={{
                                float: 'float',
                                maxWidth: '50px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }} title={data}
                            >
                                {'否'}
                            </div>
                        )

                    }else {
                        return (
                            <div className="ellipsis" style={{
                                float: 'float',
                                maxWidth: '50px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }} title={data}
                            >
                                {'是'}
                            </div>
                        )
                    }

                }
            },
            {
                title: '是否拆卸',
                key: 'disassemble',
                dataIndex: 'disassemble',
                render: (data) => {
                    // return data
                    if (data == '0'){
                        return (
                            <div className="ellipsis" style={{
                                float: 'float',
                                maxWidth: '50px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }} title={data}
                            >
                                {'否'}
                            </div>
                        )

                    }else {
                        return (
                            <div className="ellipsis" style={{
                                float: 'float',
                                maxWidth: '50px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }} title={data}
                            >
                                {'是'}
                            </div>
                        )
                    }
                }
            },
                {
                title: '电池1',
                key: 'voltage1',
                    width:'50',
                dataIndex: 'voltage1',
            },
                {
                title: '电池2',
                key: 'voltage2',
                    width:'50',
                dataIndex: 'voltage2',
            },
                {
                title: '电池3',
                key: 'voltage3',
                    width:'50',
                dataIndex: 'voltage3',
            },
                {
                title: '电池4',
                key: 'voltage4',
                width:'50',
                dataIndex: 'voltage4',
            },
            {
                title: '操作',
                key: 'detele',
                width:'150',
                render: (text, record) => {
                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer'}} onClick={() => this.modify(record)}>详情</span>
                        <span style={{ color: '#DCDCDC', margin: '0 5px'}}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer'}} onClick={()=>this.showTrackDetail(record)}>位置</span>
                        {/*<span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={()=>this.props.history.push('/home')}>轨迹查询（待）</span>*/}
                        {/*<span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>*/}
                        {/*<span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modify(record)}>海图位置（待）</span>*/}
                </div>
                },
            },
        ]
        //添加对话框
        const formItemList = [
            {
                label: '卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '卡号不能为空!' }] }
            },
            {
                label: '频度', //表单label
                id: 'frequency', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '通播号', //表单label
                id: 'multicastNumber', //表单Item的Key值shipTypeArr
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '37%', marginRight: '3%' }}
                // onChange={this.handleDeviceChange}
                >
                    {
                        this.state.shipTypeArr && this.state.shipTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select> //表单受控组件
            },
            {
                label: '任务单', //表单label
                id: 'taskNum', //表单Item的Key值
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '37%', marginRight: '3%' }}
                // onChange={this.handleDeviceChange}
                >
                    {
                        this.state.deviceTypeArr && this.state.deviceTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select>
                , //表单受控组件
            },
            {
                label: '备注', //表单label
                id: 'status', //表单Item的Key值
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '16%', marginRight: '2%' }}
                // onChange={this.handleCurrencyChange}
                >
                    {
                        this.state.cardTypeArr && this.state.cardTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select>, //表单受控组件
            },
            {
                label: '卡状态', //表单label
                id: 'Territoriality', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },]

        let type_status = [{"value":0,"text":"新录入"},{"value":1,"text":"已发货"},
                           {"value":2,"text":"正常使用"},{"value":3,"text":"随设备返修"},
                           {"value":4,"text":"报废待回收"},{"value":5,"text":"解绑"},
                           {"value":6,"text":"已回收"},{"value":10,"text":"待注销"},
                           {"value":11,"text":"已注销"},{"value":12,"text":"丢失"},
                           {"value":96,"text":"录入待审"},{"value":97,"text":"发货待审"},
                           {"value":98,"text":"安装待审"},{"value":99,"text":"注销待审"},]
        const modifyList = [
            {
                label: '北斗5信息',
                id: 'divider1',
                component: (
                    <div className="dibider">
                        <i className="bluei"></i>
                        <span style={{ marginLeft: 8 }}>北斗信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '北斗卡号',
                id: 'cardNumber',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.cardNumber }
            },
            {
                label: '通信比特数',
                id: 'bits',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.bits }
            },
            {
                label: '频度',
                id: 'frequency',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.frequency }
            },
            {
                label: '通播号',
                id: 'multicastNumber',
                component: <Input placeholder="请输入" />,
                options: {
                    initialValue: modifyData && modifyData.multicastNumber,
                    // rules: [{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确!' }]
                }
            },
            {
                label: '任务单号',
                id: 'taskNum',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.taskNum }
            },
            {
                label: '卡状态',
                id: 'status',
                component: <Select placeholder={'请选择'}>
                    {
                        type_status.map((item) => (
                            <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                        ))
                    }
                </Select>, //表单受控组件
                options: { initialValue: modifyData && modifyData.status}

            },

            {
                label: '最新报位时间',
                id: 'locationTime',
                component: <Input placeholder="请输入" format={format} />,
                /*
                *  render: (gmtCreate) => {
                    let time = moment(parseInt(gmtCreate)).format('YYYY-MM-DD ')
                    return time;
                },
                *
                * */
                options: { initialValue: modifyData && modifyData.locationTime ? moment(parseInt(modifyData.locationTime)).format('YYYY-MM-DD HH:mm:ss'):''},
                // options: { initialValue: modifyData && modifyData.locationTime ? moment(parseInt(modifyData.locationTime) , format) : '' }
            },
            {
                label: '经度',
                id: 'lon',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.lon }
            },
            {
                label: '纬度',
                id: 'lat',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.lat }
            },
            {
                label: '航速',
                id: 'speed',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.speed }
            },
            {
                label: '航向',
                id: 'dir',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.dir }
            },
            {
                label: '创建时间',
                id: 'gmtCreate',

                component: <Input placeholder="请输入" format={format} />,
                options: { initialValue: modifyData && modifyData.gmtCreate ? moment(parseInt(modifyData.gmtCreate)).format('YYYY-MM-DD HH:mm:ss'):''},
                // render: (gmtCreate) => {
                //     return moment(parseInt(gmtCreate)).format('YYYY-MM-DD ');
                // },
                // render: (gmtCreate) => {
                //     let time = moment(parseInt(gmtCreate)).format('YYYY-MM-DD ')
                //     return time;
                // },
            },

            /*
            *
            *船的=-信息
            *
            *
            * */


            {
                label: '船体信息',
                id: 'divider2',
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{ marginLeft: 8 }}>船体信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '船名',
                id: 'shipName',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.shipName }
            },
            {
                label: '船的类型',
                id: 'type',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.type }
            },
            {
                label: '船主',
                id: 'owner',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.owner }
            },
            {
                label: '电话',
                id: 'phone',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.phone }
            },
            {
                label: '地址',
                id: 'address',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.address }
            },
            {
                label: '区域名称',
                id: 'regionName',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.regionName }
            },
            {
                label: '身份证号',
                id: 'idCard',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.idCard }
            },
            {
                label: '安装时间',
                id: 'installTime',
                component: <Input placeholder="请输入" format={format} />,
                options: { initialValue: modifyData && modifyData.installTime ? moment((modifyData.installTime)).format('YYYY-MM-DD HH:mm:ss'):''},
            },
            {
                label: '安装人',
                id: 'installer',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.installer }
            },
            {
                label: '船长（米）',
                id: 'length',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.length }
            },
            {
                label: '船宽（米）',
                id: 'width',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.width }
            },
            {
                label: '船高（米）',
                id: 'height',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.height }
            },
            {
                label: '设备信息',
                id: 'divider3',
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{ marginLeft: 8 }}>设备信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '设备ID',
                id: 'deviceId',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceId }
            },
            {
                label: '设备名称',
                id: 'deviceName',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceName }
            },
            {
                label: '收费类型',
                id: 'chargeType',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.chargeType }
            },
            {
                label: '设备型号',
                id: 'deviceType',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceType }
            },
            {
                label: '设备编号',
                id: 'deviceNumber',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceNumber }
            },
            {
                label: '显示屏型号',
                id: 'displayType',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.displayType }
            },
            {
                label: '显示屏编号',
                id: 'displayNumber',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.displayNumber }
            },


            /*
            *
            *
            *注意： DateTimePiker  use old  way  is  wrong,so   for   now   way  to  achieve .
            *
            *
            * */
            {
                label: '发货时间',
                id: 'sendDate',
                component: <Input placeholder="请输入" format={format} />,
                // options: { initialValue: modifyData && modifyData.sendDate ? moment(modifyData.sendDate, format) : '' }
                options: { initialValue: modifyData && modifyData.sendDate ? moment((modifyData.sendDate)).format('YYYY-MM-DD HH:mm:ss'):''},
                //
                // component: <Input placeholder="请输入" format={format} />,
                // options: { initialValue: modifyData && modifyData.sendDate ? moment(parseInt(modifyData.sendDate)).format('YYYY-MM-DD HH:mm:ss'):''},
            },
            {
                label: 'IMEI',
                id: 'imei',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.imei }
            },
            {
                label: 'ICCID',
                id: 'iccid',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.iccid }
            },
               {
                label: '电池1',
                id: 'voltage1',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.voltage1 }
            },
                {
                label: '电池2',
                id: 'voltage2',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.voltage2 }
            },
                {
                label: '电池3',
                id: 'voltage3',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.voltage3 }
            },
                {
                label: '电池4',
                id: 'voltage4',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.voltage4 }
            },
        ]
        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">北斗综合查询</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                北斗卡号：
                                <Input placeholder="请输入,一行一个,支持多卡号" style={{width: '200px'}} name='cardNumber' value={this.state.textAreaValue} onChange={value => this._changeValue(value)} onClick={()=>{this.showModal()}} />
                            </div>

                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                船名：  <Input placeholder="请输入" style={{width: '200px'}} name='shipName' value={this.state.shipName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                报位时间：
                                <RangePicker
                                    // locale={locale}
                                    style={{
                                        width: '70%',
                                        alignItems: 'center'
                                    }}
                                    // defaultValue={[moment(startTime), moment(endTime)]}
                                    ranges={{
                                        今日: [moment(), moment()],
                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                        '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                                        '今年': [moment().startOf('year'), moment().endOf('year')],
                                    }}
                                    // value={[moment(startTime), moment(endTime)]}
                                    onChange={this.selectByTime}

                                />
                            </div>
                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                区域：
                                {/*<Input placeholder="请输入" style={{width: '70%'}} name='regionName' onChange={value => this._changeValue(value)}/>*/}
                                {/*{title && <p className="rsselect-title">{title}：</p>}*/}
                                {<TreeSelect
                                    style={{
                                        width: '70%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    id={this.state.value}
                                    value={this.state.regionName}
                                    allowClear={true}
                                    treeNodeLabelProp="regionName"
                                    placeholder="Please select"
                                    treeData={this.state.treeData}
                                    // treeDefaultExpandAll
                                    onChange={this.onChange}
                                    onSelect={this.onSelect}
                                    {...rest}>
                                </TreeSelect>}
                            </div>

                        </div>


                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectCardDetails()}
                            >查询</Button>

                        </div>
                        <div className="cardManagement_title_menuSelect_part4">

                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:10,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cardNumber: [],
                                            textAreaValue: '',
                                            shipName: '',
                                            regionName: '',
                                            regionId: '',


                                        }, () => {
                                            //
                                        })
                                    }
                                }

                            >重置</Button>
                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    left:20,
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.downloadFile()}
                            >下载</Button>

                        </div>
                    </div>
                    {/*<div className="noLineStyleManagement_title_menuSelect" style={{marginTop:40}}>*/}
                    {/*    <div className="cardManagement_title_menuSelect_part">*/}
                    {/*        <div className="cardManagement_title_menuSelect_part_div">*/}
                    {/*            设备编号：*/}
                    {/*            <Input placeholder="请输入" style={{width: '200px'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*    */}
                    {/*</div>*/}


                </div>

                <div className="cardManagement" style={{overflow:"auto",overflowX:"hidden"}}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{y:515}}
                        // 此scroll选项必须开启，宽高与react-custom-scrollbars插件一致
                        //scroll={{y: scroll.height, x: scroll.width}}
                        //scroll="{ x:'100%',y:515 }"
                        rowKey={(record)=>record.cardNumber}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectCardDetails(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertShip}
                    title="新增"
                />
                {
                    this.state.modify && <div className="modifyShipData">
                        <h3>北斗卡详情</h3>
                        <Form formItemList={modifyList} cancel={true} submit={this.closeModal} closeModal={this.closeModal} className={"bdCard"}/>
                    </div>
                    // <Form formItemList={addColumnsList} cancel={true} submit={this.insertShipInstall} closeModal={this.closeModal} />
                    // <Form formItemList={modifyList} cancel={true} submit={this.getNewShipData} closeModal={this.closeModal} />
                }
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.deleteShip}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.shipName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.Label}
                    onCancel={this.closeModal}
                    title="标签管理"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={600}
                >
                    <AddFormItem data={this.state.labelData} onOk={this.bindShipDevice} onClose={this.closeModal} />
                </Modal>
                <Modal
                    visible={this.state.manageShow}
                    onCancel={this.closeModal}
                    title="分配管理"
                    // footer={false}
                    width={826}
                    className="fenpeiModal123"
                    onOk={this.bindUgroupRelation}
                >
                    <Search
                        placeholder="模糊搜索"
                        onSearch={value => this.getSearchValue(this.state.userGroupData, value)}
                        style={{ width: 200, float: 'right', margonBottom: 50 }}
                    />

                </Modal>
                <Modal
                    title="请输入北斗卡号"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <TextArea
                        value={textAreaValue}
                        onChange={this.onChangeBdCard}
                        placeholder="请输入北斗卡号，确保一行一个,会自动转换格式,为空默认查全部"
                        // autoSize={{ minRows: 10, maxRows: 10 }}
                        style={{height:150}}
                    />
                </Modal>
            </div>
        )
    }
}
export default BdInfoSearch