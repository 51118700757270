// 区域管理
import React, { Component } from 'react'
import './css/system.css'
import { Table, Modal, Input, Icon, Select, message, DatePicker} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import Form from '../../componts/form/Form'
import {
    insertRegion,
    deleteRegion,
    updateRegionDetail,
    selectRegion
} from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class RegionMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: null,
            userGroupData: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
            flowList: [],
            regionName:''
        }
        this.insertRegion = this.insertRegion.bind(this)
        this.deleteRegion = this.deleteRegion.bind(this)
        this.updateRegionDetail = this.updateRegionDetail.bind(this)
        //查询区域列表数据
        this.selectRegion = this.selectRegion.bind(this)
    }
    componentDidMount() {
        //查询区域列表
        this.selectRegion();
    }

    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectIICardDetails(names)
        this.setState({
            name: names
        })
    }
    // 查询区域信息分页查询
    async selectRegion(value, type, pageSizeApi, coloums,id) {
        let { pagination, regionName} = this.state;
        let params = {
            id: id ? id : '',
            pageNo: pagination.current,
            pageSize: pageSizeApi ? pageSizeApi : pagination.pageSize,
        }
        let data = await selectRegion(params)

            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.rows)
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys
                    }

                )
            } else {
                this.setState({
                        //给dataSource赋值
                        dataSource: data.rows,
                        userGroupData: data.rows,
                        pageTotal: data.pageTotal,
                        // UallArr: data.rows,
                        // ulshow: false,
                        pagination,
                        selectedKeys
                    }

                )
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }

    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }

    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectIICardDetails()
        })
    }

    // 新增区域节点
    async insertRegion(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await insertRegion(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.selectRegion()
            message.success("新增成功")
            callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败")
        }
        this.setState({
            addShow: false,
            deleteShow: false,
            // historyShow: false
        })
    }

    // 修改区域详情
    async updateRegionDetail(obj) {
        console.log(obj, 'oooooooooo')
        let data = await updateRegionDetail(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                modify: false,
            })
            this.selectRegion()
            message.success("修改成功")
        } else if (data.code == 500) {
            message.error("修改失败")
        }
        this.setState({
            deleteShow: false,
            // historyShow: false

        })
    }

    addClickShow = (data) => {
        this.setState({
            addShow: true,
            addData: data,
        })
    }
    modify = (data) => {
        this.setState({
            modify: true,
            modifyData: data,
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }

    // 删除区域节点
    async deleteRegion() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.id,
        }
        let data = await deleteRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectRegion()
        } else if (data.code == 500) {
            message.error("删除失败:"+data.message)
        }
        this.setState({
            deleteShow: false,
        })
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            historyShow: false,
            Label: false,
            modify: false,
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectRegion()
        })
        console.log("505"+pagination, filters, sorter, 'sorter')
    }
    //北斗卡修改
    getNewRegionData = (value) => {
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.id = modifyData.id
        this.updateRegionDetail(value)
        console.log(value, 'getNewRegionData')
    }


    //筛选
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            let { objectNameArr } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 100 }} placeholder={'请选择'}
                            showSearch
                            multiple
                    >
                        {
                            objectNameArr && objectNameArr.regionNames.map((item) => (
                                <Option
                                    onClick={this.clickSearchData1.bind(this, item)}
                                    value={item} >{item}</Option>
                            ))
                        }
                    </Select>,
                </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectIICardDetails()
        });
    }

    distribute_onChage = (selectedRowKeys) => {
        let keyArr = this.getSelectKey(this.state.userGroupData, selectedRowKeys)
        console.log("+++++++++++++590++++++++++++"+keyArr);
        this.setState({
            selectedKeys: keyArr
        })
    }

    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }


    render() {
        let {flowList ,regionName, dataSource, modifyData,addData,nowModalData,userGroupData,selectedKeys,deleteAll, UallArr, LabelSize, ulshow, labelData, shipTypeArr,selectMenuDataObj = {} } = this.state;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
            {
                title: '区域名称',
                key: 'regionName',
                dataIndex: 'regionName',
            },
            {
                title: '区域id',
                key: 'id',
                dataIndex: 'id',
            },
            {
                title: '父级区域id',
                key: 'parentId',
                dataIndex: 'parentId',
                // ...this.getColumnSearchProps('parentId'),
            },
         // {
         //        title: '创建时间',
         //        key: 'gmtCreate',
         //        dataIndex: 'gmtCreate',
         //    },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },
            {
                title: '操作',
                key: 'detele',

                render: (text, record) => {
                    return <div>
                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() =>this.addClickShow(record)}>新增</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modify(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>

                    </div>
                },
            },
        ]
        //添加对话框
        const formItemList = [
            {
                label: '区域名称', //表单label
                id: 'regionName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '区域名称!' }] }
            },
            {
                label: '父级区域id', //表单label
                id: 'parentId', //表单Item的Key值
                component: <Input placeholder={'请输入'}/>, //表单受控组件
                options: { initialValue: addData && addData.id }
            },

            // {
            //     label: 'id', //表单label
            //     id: 'id', //表单Item的Key值
            //     component: <Input placeholder={'请输入'}/>, //表单受控组件
            //     options: { initialValue: addData && addData.id }
            // },

            {
                label: '备注',
                id: 'remark',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
          ]
        const modifyList = [
            {
                label: '区域名称', //表单label
                id: 'regionName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.regionName }
            },
            {
                label: '父级区域id', //表单label
                id: 'parentId', //表单Item的Key值
                component: <Input placeholder={'请输入'} readOnly/>, //表单受控组件
                options: { initialValue: modifyData && modifyData.parentId }
            },

            {
                label: 'id', //表单label
                id: 'id', //表单Item的Key值
                component: <Input placeholder={'请输入'} readOnly/>, //表单受控组件
                options: { initialValue: modifyData && modifyData.id }
            },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.remark }
            },

        ]

        return (
            <div className='shipmonitoring contentBox'>
                <div className="RegionManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">区域管理</div>

                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }

                <div className="cardIIManagement">
                    {/*
                    //
                    //
                    //列表显示数据
                    //
                    //
                    //

                    */}
                    <Table
                        columns={columns}
                        //rowSelection={distribute_rowSelect}
                        //去掉复选框
                        rowSelection={{distribute_rowSelect}}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:600}}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],

                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectRegion(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertRegion}
                    title="新增"/>

                {
                    this.state.modify && <div className="modifyShipData">
                        <h3>修改</h3>
                        <Form formItemList={modifyList} cancel={true} submit={this.getNewRegionData} closeModal={this.closeModal} />
                    </div>
                }
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提示"
                    onOk={this.deleteRegion}>
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.regionName}"此区域将被删除，确认删除吗？`}</span>
                </Modal>
                

            </div>

        )
    }
}
export default RegionMonitoring