// 系统管理
import React, { Component } from 'react'
import './css/system.css'
import ShipMonitoring from './ShipMonitoring'
import UserMonitoring from './UserMonitoring'
import Authorization from './Authorization'
import DeviceConfig from '../test/DeviceConfig'
import FirmwareStatus from '../test/FirmwareStatus'
import FirmwareList from '../test/FirmwareList'
import {Menu, Icon, Popconfirm, Layout} from 'antd';
import { logout } from '../../data/dataStore'
import BdInfoSearch from './BdInfoSearch';
import DeviceMonitoring from './DeviceMonitoring';
import RegionMonitoring from './RegionMonitoring';
import CardIIMonitoring from './CardIIMonitoring';
import MenuConfig from './MenuConfig';
import SmsMonitoring from './SmsMonitoring';
import OriginalMsg from './OriginalMsg';
import DeviceType from './DeviceType';
import OnlineRateRule from './OnlineRateRule';
import AlarmRule from './AlarmRule';
import RepairLog from './RepairLog';
import {Badge, Button, Dropdown, Tabs} from 'antd';
import CmdReportMonitoring from './CmdReportMonitoring';
import OfflineReportMonitoring from './OfflineReportMonitoring';
import CommanderSearch from './CommanderSearch';
import CommanderCardListSearch from './CommanderCardListSearch';
import CmdStatisticsSearch from './CmdStatisticsSearch';
import CmdCardStatisticsSearch from './CmdCardStatisticsSearch';
import OfflineReportMonitoring1 from './Select7daysDataMsg';
import Alarmhistory from './AlarmHistory';
import BatteryHistory from './BatteryHistory';
import CardLog from './CardLog';
import DeviceManage from './DeviceManage';
import {BIG_SCREEN_ADDRESS} from '../../config';
import QueryMultipleCards from './QueryMultipleCards';
import Select7daysDataMsg from "./Select7daysDataMsg";
import ClientPush from "./ClientPush";
import ClientPushManage from "./ClientPushManage";
const SubMenu = Menu.SubMenu;
const MyIcon=Icon.createFromIconfontCN({
    scriptUrl:'//at.alicdn.com/t/font_1733642_oqfja1ub1h.js'
})
const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;

export default class EnforceTheLaw extends Component {
    constructor(props){
        super(props)
        this.state={
            //首选项
            defaultKey:'userMonitoring',
            //默认标签页
            activeKey:'userMonitoring',
            alreadyTabsArr:['userMonitoring'],
            baseUIList: [],
            bdUIList: [],
            reportUIList: [],
        }
    }
    componentDidMount(){
        const {dispatch,location}=this.props;
        console.log(location,'location')
        let defaultKey;

        if(location.state){//判断当前有参数
            defaultKey=location.state.key;
            this.setState({
                defaultKey
            })
        }
        //获取login信息
        let userData = JSON.parse(sessionStorage.getItem('isLoginUserName'))
        // let menuJson = JSON.parse(sessionStorage.getItem('menuJson'));

        // let menuJson = JSON.parse(sessionStorage.getItem('menuJson'))
        // console.log('<<<menuJson<<<<<<<<<'+menuJson)
        // console.log('<<<menuJson<<<<<<<<<'+sessionStorage.getItem('menuJson'))
        // let bdUIList = JSON.parse(menuJson)['bdUIList']

        // for (let key in menuJson) {
        //     // console.log('<<<key<<<<<<<<<' + key);
        //     // console.log('<<<key<<<<<<<<<' + menuJson[key]);
        //     if (key == 'baseUIList') {
        //         console.log('<<<key<<<<<<<<<' + key);
        //         console.log('<<<baseUIList<<<<<<<<<' + menuJson[key]);
        //         this.setState({
        //            baseUIList : menuJson[key],
        //         })
        //         console.log('<<<baseUIList<<<<<<<<<' + baseUIList);
        //      }
        //  }

        // console.log('<<<bdUIList---------------'+bdUIList)

        this.setState({
            userData,
            // menuJson
        })
    }
    changepage=(item,key)=>{
        const {alreadyTabsArr}=this.state
        // console.log('item.key-------',item.key)
        this.setState({
            defaultKey:item.key,
            eventStatusArr:'',
            //去重
            alreadyTabsArr:Array.from(new Set([...alreadyTabsArr,item.key]))
        })
    }
    // 登出接口
    async logout(value) {
        console.log('value----',value)
        let data = await logout(value)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            window.sessionStorage.clear()
            this.props.history.replace('/login')
        }
    }
    handleClick = () => {
        this.setState({
        }, () => {
            this.logout()
        })
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    //选择标签页回掉
    onChange = activeKey => {
        this.setState({ defaultKey: activeKey });
    };
    onEdit = (targetKey, action) => {
        // console.log('targetKey, action',targetKey, action)
        this[action](targetKey);
    };
    remove = targetKey => {
        let { activeKey, alreadyTabsArr} = this.state;
        // console.log('remove----targetKey',targetKey,'activeKey',activeKey)
        if (alreadyTabsArr.length==1)return
        const panes = alreadyTabsArr.filter(item=>item!=targetKey);
        if (panes.length ){
            if (panes.length>0){
                activeKey =panes[panes.length-1];
            }else {
                activeKey =panes[0];
            }
        }
        this.setState({alreadyTabsArr:panes,defaultKey:activeKey})
    };

    render() {
        let {defaultKey,allsmsDatas,msgShow2,allAlarm,warnShow,userdata}=this.state;
        // this.setState({
        //     userdata,
        // })
        // let menuJson = JSON.parse(sessionStorage.getItem('menuJson'));
        let baseUIList = JSON.parse(sessionStorage.getItem('baseUIList'));
        let bdUIList = JSON.parse(sessionStorage.getItem('bdUIList'));
        let reportUIList = JSON.parse(sessionStorage.getItem('reportUIList'));



        // let baseUIList =JSON.stringify(menuJson.baseUIList);
        // let baseUIList = baseUIListJson.toString().replace(/"-/g,'').replace(/-"/g,'')


        // console.log('<<<<<<<<menuJson<<<<<<<<<<<<<<<<'+JSON.stringify(menuJson))
        // console.log('<<<<<<<<baseUIList<<<<<<<<<<<<<<<<'+JSON.stringify(baseUIList));
        // console.log('<<<<<<<<bdUIList<<<<<<<<<<<<<<<<'+JSON.stringify(bdUIList));
        // console.log('<<<<<<<<reportUIList<<<<<<<<<<<<<<<<'+JSON.stringify(reportUIList));
        // let obj = JSON.parse(menuJson);
        // console.log('<<<<<<<<obj<<'+ (menuJson))
        // let REG_PAGES =JSON.stringify(menuJson.baseUIList);
        //注册页面展示
        let REG_PAGES = {
            'userMonitoring': { title: '用户管理', content: <UserMonitoring history={this.props.history} />, key: 'userMonitoring',icon:'icon-yonghu', },
            'authorization' : { title: '权限管理', content: <Authorization history={this.props.history} />, key: 'authorization',icon:'icon-kaiqiquanxian', },
            'bdInfoSearch': { title: '北斗综合明细查询', content: <BdInfoSearch history={this.props.history} />, key: 'bdInfoSearch',icon:'icon-yonghu', },
            'cardIIMonitoring': { title: '北斗卡管理', content: <CardIIMonitoring history={this.props.history}/>, key: 'cardIIMonitoring',icon:'icon-chuan', },
            // 'deviceMonitoring': { title: '设备发货', content: <DeviceMonitoring history={this.props.history} />, key: 'deviceMonitoring',icon:'icon-yonghu', },
            'deviceManage': { title: '设备管理', content: <DeviceManage history={this.props.history} />, key: 'deviceManage',icon:'icon-yonghu', },
            'deviceType': { title: '设备类型', content: <DeviceType history={this.props.history} />, key: 'deviceType',icon:'icon-yonghu', },
            'ruleManage': { title: '规则管理', content: <DeviceType history={this.props.history} />, key: 'deviceType',icon:'icon-yonghu', },
            //设备安装
            'shipMonitoring': { title: '船舶管理', content: <ShipMonitoring history={this.props.history}/>, key: 'shipMonitoring',icon:'icon-chuan', },
            'regionMonitoring': { title: '区域管理', content: <RegionMonitoring history={this.props.history}/>, key: 'regionMonitoring',icon:'icon-chuan', },
            //菜单管理
            'menuConfig': { title: '菜单管理', content: <MenuConfig history={this.props.history}/>, key: 'menuConfig',icon:'icon-chuan', },
            'smsMonitoring': { title: '北斗短信查询', content: <SmsMonitoring history={this.props.history} />, key: 'smsMonitoring',icon:'icon-shebeiguanli', },
            'originalMsg': { title: '最新原始信息查询', content: <OriginalMsg history={this.props.history} />, key: 'originalMsg',icon:'icon-yonghu', },
            'queryMultipleCards': { title: '北斗多卡号查询', content: <QueryMultipleCards history={this.props.history} />, key: 'queryMultipleCards',icon:'icon-yonghu', },
            // 'onlineRateRule': { title: '在线率规则', content: <OnlineRateRule history={this.props.history}/>, key: 'onlineRateRule',icon:'icon-chuan', },
            // 'alarmRule': { title: '告警规则', content: <AlarmRule history={this.props.history} />, key: 'alarmRule',icon:'icon-yonghu', },
            //'cmdReportMonitoring': { title: '指挥机统计报表', content: <CmdReportMonitoring history={this.props.history} />, key: 'cmdReportMonitoring',icon:'icon-yonghu', },
            'offlineReportMonitoring': { title: '离线统计报表', content: <OfflineReportMonitoring history={this.props.history} />, key: 'offlineReportMonitoring',icon:'icon-yonghu', },
            // 'commanderSearch': { title: '查询所有指挥机', content: <CommanderSearch history={this.props.history} />, key: 'commanderSearch',icon:'icon-yonghu', },
            'alarmhistory': { title: '报警记录查询', content: <Alarmhistory history={this.props.history} />, key: 'alarmhistory',icon:'icon-yonghu', },
            'batteryHistory': { title: '电池电量查询', content: <BatteryHistory history={this.props.history} />, key: 'batteryHistory',icon:'icon-yonghu', },
            'cardLog': { title: '北斗操作日志', content: <CardLog history={this.props.history} />, key: 'cardLog',icon:'icon-yonghu', },
            'select7daysDataMsg': { title: '7天统计信息查询', content: <Select7daysDataMsg history={this.props.history} />, key: 'select7daysDataMsg',icon:'icon-yonghu', },
            'clientPush': { title: '客户推送查询', content: <ClientPush history={this.props.history} />, key: 'clientPush',icon:'icon-yonghu', },
            'cmdReportMonitoring': { title: '指挥机统计报表', content: <CmdReportMonitoring history={this.props.history} />, key: 'cmdReportMonitoring',icon:'icon-yonghu', },
            'clientPushManage': { title: '客户推送配置', content: <ClientPushManage history={this.props.history} />, key: 'clientPushManage',icon:'icon-yonghu', },
            'repairLog': { title: '维修记录', content: <RepairLog history={this.props.history} />, key: 'repairLog',icon:'icon-yonghu', },
        };
        // const device = {
        //     'deviceConfig': {title:'设备参数及配置',content: <DeviceConfig history={this.props.history}/>,key:'deviceConfig'},
        //     'firmwareStatus': {title:'固件升级状态',content: <FirmwareStatus history={this.props.history}/>,key:'firmwareStatus'},
        //     'firmwareList': {title:'固件列表',content: <FirmwareList history={this.props.history}/>,key:'firmwareList'}
        // }
        //基础管理导航菜单

        // console.log(baseUIList)
        //   Object.keys(baseUIList).map(item => (
        //       console.log(baseUIList[item].key),
        //       console.log(baseUIList[item].title)


                                        // ))


        // const keysArray = Object.keys(baseUIList);
        // const valuesArray = Object.values(baseUIList);
        //
        // const mappedData = keysArray.map((key, index) => {
        //   return { key: key, value: valuesArray[index] };
        // });
        //     console.log('bmappedData============'+mappedData);


        // let base =JSON.stringify(menuJson.baseUIList);
        // let baseMap = new Map(Object.entries(baseUIString))
        // // const map = new Map(Object.entries(base))
        // // console.log(Object.prototype.toString.call(map))
        // console.log('333333='+baseMap)
        // console.log(Object.prototype.toString.call(base))
        // let baseUIList =eval('('+base+')');

        // let baseUIList = {
        //     "userMonitoring": { title: '用户管理', content: <UserMonitoring history={this.props.history} />, key: 'userMonitoring',icon:'icon-yonghu', },
        //     'authorization': { title: '权限管理', content: <Authorization history={this.props.history} />, key: 'authorization',icon:'icon-kaiqiquanxian', },
        //     'menuConfig': { title: '菜单管理', content: <MenuConfig history={this.props.history} />, key: 'menuConfig',icon:'icon-kaiqiquanxian', },
        // }
 // baseUIList = {
// 	"authorization":  { title: '权限管理', content: <Authorization history={this.props.history} />, key: 'authorization',icon:'icon-kaiqiquanxian', },
// 	"menuConfig":  { title: '菜单管理', content: <MenuConfig history={this.props.history} />, key: 'menuConfig',icon:'icon-kaiqiquanxian', },
// 	"userMonitoring":  { title: '用户管理', content: <UserMonitoring history={this.props.history} />, key: 'userMonitoring',icon:'icon-yonghu', }
// }

      // baseUIList =
      //   "  {\"authorization\":{\"title\":\"权限管理\",\"key\":\"authorization\"},\"menuConfig\":{\"title\":\"菜单管理\",\"key\":\"menuConfig\"},\"userMonitoring\":{\"title\":\"用户管理\",\"key\":\"userMonitoring\"}}"
// {"authorization":  {'title':'权限管理','key':'authorization' }  ,"menuConfig":  {'title':'菜单管理','key':'menuConfig' }  ,"userMonitoring":  {'title':'用户管理','key':'userMonitoring' }  }

      // baseUIList = [{"title":"权限管理","key":"authorization"},{"title":"菜单管理","key":"menuConfig"},{"title":"用户管理","key":"userMonitoring"}];
     //   for (var i in baseUIList1) {
     //       console.log(baseUIList1[i].title);
     //       console.log(baseUIList1[i].key);
     //   }






       // let basemap = new Map(Object.entries(JSON.parse(baseUIList)));
      // console.log('basemap------'+basemap.toString())
    // let basemap1 = new Map(Object.entries(JSON.parse({"authorization":{"title":"权限管理","key":"authorization"},"menuConfig":{"title":"菜单管理","key":"menuConfig"}})))




        // console.log(Object.prototype.toString.call(baseMap))
        // baseMap.delete("userMonitoring");
        // console.log(Object.prototype.toString.call(baseMap))
        // console.log(baseMap.get)
        // console.log(baseMap)
        // console.log(baseMap)

        // let baseMap = new Map(Object.entries(baseUIList))
        // let base = menuJson.baseUIList;
        //
        // // console.log(base)
        // let map = new Map();
        //      // console.log(Object.prototype.toString.call(baseUIList))
        // for(let mapKey of baseMap.keys()){
        //
        //     // console.log(mapKey)
        //     for(let jsonKey in base){
        //         // console.log(jsonKey)
        //
        //         if(mapKey==jsonKey){
        //             map.set(mapKey,baseMap.get(mapKey))
        //
        //         }
        //     }
        // }
        // // console.log(map)
        // baseUIList =     Object.fromEntries(map.entries());

        //北斗管理导航菜单
        // let bdUIList = {
        //     'cardIIMonitoring': { title: '北斗卡管理', content: <CardIIMonitoring history={this.props.history}/>, key: 'cardIIMonitoring',icon:'icon-chuan', },
        //     // 'deviceMonitoring': { title: '设备发货', content: <DeviceMonitoring history={this.props.history} />, key: 'deviceMonitoring',icon:'icon-yonghu', },
        //     'deviceManage': { title: '设备管理', content: <DeviceManage history={this.props.history} />, key: 'deviceManage',icon:'icon-yonghu', },
        //     'shipMonitoring': { title: '船舶管理', content: <ShipMonitoring history={this.props.history}/>, key: 'shipMonitoring',icon:'icon-chuan', },
        //     'regionMonitoring': { title: '区域管理', content: <RegionMonitoring history={this.props.history}/>, key: 'regionMonitoring',icon:'icon-chuan', },
        //     'deviceType': { title: '设备类型', content: <DeviceType history={this.props.history} />, key: 'deviceType',icon:'icon-yonghu', },
        //     'clientPushManage': { title: '客户推送配置', content: <ClientPushManage history={this.props.history} />, key: 'clientPushManage',icon:'icon-yonghu', },
        //     'cardLog': { title: '北斗操作日志', content: <CardLog history={this.props.history} />, key: 'cardLog',icon:'icon-yonghu', },
        //     }

        // let bdMap = new Map(Object.entries(bdUIList))
        // let bd = menuJson.bdUIList;
        // // console.log('111111111111111111'+Object.prototype.toString.call(bd))
        // console.log('222222'+JSON.stringify(menuJson))
        // console.log('4444'+JSON.stringify(menuJson.bdUIList))
        // // console.log('5555'+JSON.stringify(bd))
        // // console.log('3333333333333333'+Object.prototype.toString.call(menuJson))
        // let map2 = new Map();
        // for(let mapKey of bdMap.keys()){
        //     // console.log(mapKey)
        //     console.log('0000000000000000000000000000000000')
        //     for(let jsonKey in bd){
        //         console.log(jsonKey)
        //         if(mapKey==jsonKey){
        //             map2.set(mapKey,bdMap.get(mapKey))
        //         }
        //     }
        // }
        // // console.log(map2)
        // bdUIList =     Object.fromEntries(map2.entries());


        const ruleUIList = {
            // 'onlineRateRule': { title: '在线率规则', content: <OnlineRateRule history={this.props.history}/>, key: 'onlineRateRule',icon:'icon-chuan', },
            // 'alarmRule': { title: '告警规则', content: <AlarmRule history={this.props.history} />, key: 'alarmRule',icon:'icon-yonghu', },
        }
        //统计查询导航菜单
        // let reportUIList = {
        //     'bdInfoSearch': { title: '北斗综合明细查询', content: <BdInfoSearch history={this.props.history} />, key: 'bdInfoSearch',icon:'icon-yonghu', },
        //     'smsMonitoring': { title: '北斗短信查询', content: <smsMonitoring history={this.props.history} />, key: 'smsMonitoring',icon:'icon-yonghu', },
        //     'originalMsg': { title: '最新原始信息查询', content: <OriginalMsg history={this.props.history} />, key: 'originalMsg',icon:'icon-yonghu', },
        //     // 'queryMultipleCards': { title: '北斗多卡号查询', content: <QueryMultipleCards history={this.props.history} />, key: 'queryMultipleCards',icon:'icon-yonghu', },
        //
        //     // 'offlineReportMonitoring': { title: '离线统计报表', content: <OfflineReportMonitoring history={this.props.history} />, key: 'offlineReportMonitoring',icon:'icon-yonghu', },
        //     'select7daysDataMsg': { title: '7天统计信息查询', content: <Select7daysDataMsg history={this.props.history} />, key: 'select7daysDataMsg',icon:'icon-yonghu', },
        //     'alarmhistory': { title: '报警记录查询', content: <Alarmhistory history={this.props.history} />, key: 'alarmhistory',icon:'icon-yonghu', },
        //     'batteryHistory': { title: '电池电量查询', content: <BatteryHistory history={this.props.history} />, key: 'batteryHistory',icon:'icon-yonghu', },
        //     'clientPush': { title: '客户推送查询', content: <ClientPush history={this.props.history} />, key: 'clientPush',icon:'icon-yonghu', },
        //     'cmdReportMonitoring': { title: '指挥机统计报表', content: <CmdReportMonitoring history={this.props.history} />, key: 'cmdReportMonitoring',icon:'icon-yonghu', },
        //
        //
        // }
        const cmdUIList = {
            // 'commanderSearch': { title: '查询所有指挥机', content: <CommanderSearch history={this.props.history} />, key: 'commanderSearch',icon:'icon-yonghu', },
            // 'commanderCardListSearch': { title: '查询指挥机的卡号集合', content: <CommanderCardListSearch history={this.props.history} />, key: 'commanderCardListSearch',icon:'icon-yonghu', },
            // 'cmdStatisticsSearch': { title: '查询指挥机的发送统计', content: <CmdStatisticsSearch history={this.props.history} />, key: 'cmdStatisticsSearch',icon:'icon-yonghu', },
            // 'cmdCardStatisticsSearch': { title: '查询指挥卡的发送统计', content: <CmdCardStatisticsSearch history={this.props.history} />, key: 'cmdCardStatisticsSearch',icon:'icon-yonghu', },
        }
        return (
            <div>
            <div className='header'>
                <div className='header_left'>
                    <ul>
                        {/*<li>实时监控</li>*/}
                        {/*<li>实时台风</li>*/}
                        {/*<li>运营统计</li>*/}
                        <li>系统管理</li>
                        <a target='_blank' href={BIG_SCREEN_ADDRESS}>大屏入口</a>
                    </ul>
                </div>
                <div className='header_right'>

                    <Dropdown
                        overlay={
                            <div>
                                <div className='dropMenu1'>
                                    <Button onClick={this.handleClick} >退出登录</Button >
                                </div>
                            </div>
                        }
                    >
                        <span>
                            <Icon type='user' style={{ marginRight: 10 }} />
                            {this.state.userData}
                            <Icon type='down' style={{ margin: '0 10px', color: '#fff' }} />
                        </span>
                    </Dropdown>

                </div>
            </div>
            {/*<div className='system' style={{display:'flex',alignContent:'space-between'}}>*/}
            <div className='system'>
                <Layout>
                    <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.toggleCollapsed}>
                        {/*<div className = 'menu' style= {{ width : this.state.collapsed?80:256,  position: 'absolute', zIndex:2, top:'60px'}} ref={this.controlWidth}>*/}
                        <div className = 'menu' >
                            {/*<Button type='primary' onClick={this.toggleCollapsed} style={{ marginBottom: 16,marginLeft:25 }}>*/}
                            {/*    <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />*/}
                            {/*</Button>*/}
                            <Menu
                                selectedKeys={[defaultKey]}
                                mode='inline'
                                theme='dark'
                                inlineCollapsed={this.state.collapsed}
                                onClick={this.changepage}
                            >

                                <SubMenu key='sub2' title={ <span><Icon type='dashboard' /><span>基础管理</span></span> }>
                                    {
                                        baseUIList?
                                        Object.keys(baseUIList).map(item => (
                                          <Menu.Item key={baseUIList[item].key}>
                                            <span>{baseUIList[item].title}</span>
                                          </Menu.Item>
                                        )):[]
                                        // Object.keys(baseUIList).map(item => (
                                        //     <Menu.Item key={baseUIList[item].key}>
                                        //         <span>{baseUIList[item].title}</span>
                                        //     </Menu.Item>
                                        // ))
                                    }
                                </SubMenu>


                                <SubMenu key='sub3' title={ <span><Icon type='dashboard' /><span>北斗管理</span></span> }>
                                    {
                                        bdUIList?
                                        Object.keys(bdUIList).map(item => (
                                            <Menu.Item key={bdUIList[item].key}>
                                                <span>{bdUIList[item].title}</span>
                                            </Menu.Item>
                                        )):[]
                                    }

                                </SubMenu>


                                <SubMenu key='sub5' title={ <span><Icon type='dashboard' /><span>统计查询</span></span> }>
                                    {
                                        reportUIList?
                                        Object.keys(reportUIList).map(item => (
                                            <Menu.Item key={reportUIList[item].key}>
                                                <span>{reportUIList[item].title}</span>
                                            </Menu.Item>
                                        )):[]
                                    }
                                </SubMenu>
                            }
                            </Menu>
                        </div>
                    </Sider>
                    {/*<Layout>*/}

                        {/*<Content>*/}
                        <div  id='content'>
                            <Tabs
                                hideAdd
                                onChange={this.onChange}
                                activeKey={this.state.defaultKey}
                                type='editable-card'
                                onEdit={this.onEdit}

                            >
                                {this.state.alreadyTabsArr.map(pane => (
                                    <TabPane tab={REG_PAGES[pane].title} key={REG_PAGES[pane].key}>
                                        {REG_PAGES[pane].content}
                                    </TabPane>
                                ))}
                            </Tabs>
                            {/*<div  id='content'>*/}
                            {/*    {*/}
                            {/*        REG_PAGES[defaultKey]?*/}
                            {/*            [REG_PAGES[defaultKey]].map((item,index)=>(*/}
                            {/*                <div key={item.key+index}>*/}
                            {/*                    {item.content}*/}
                            {/*                </div>*/}
                            {/*            )):*/}
                            {/*            [device[defaultKey]].map((item,index)=>(*/}
                            {/*                <div key={item.key}>*/}
                            {/*                    {item.content}*/}
                            {/*                </div>*/}
                            {/*            ))*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                        {/*</Content>*/}

                    {/*</Layout>*/}
                </Layout>

            </div>
            </div>
        )
    }
}
