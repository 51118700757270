import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { MultiPoint, Point, LineString } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import {
  fromLonLat,
  get,
  transform,
  getTransform
} from 'ol/proj.js';
import moment from 'moment'
// showMap_trajectory_points = (points) => { //在地图显示轨迹
//   if (points) {
//   } else {
//       points = [
//           {
//               time: '2020-03-30 10:00:00',
//               lon: 102.245722,
//               lat: 27.865106,
//           },
//           {
//               time: '2020-03-30 10:05:00',
//               lon: 102.245463,
//               lat: 27.788754,
//           },
//           {
//               time: '2020-03-30 10:08:00',
//               lon: 102.381849,
//               lat: 27.808182,
//           },
//           {
//               time: '2020-03-30 10:30:00',
//               lon: 102.352495,
//               lat: 27.876032,
//           },
//       ]
//   }
//   let vectorTrajectory = this.map.layers.vectorTrajectory;
//   TrajectoryAnimation.addTrajectoryAnimation(this.map, vectorTrajectory, points);
// }

let listener_postcompose;
let isStart=false;
export default {
  start_TrajectoryAnimation() {
      isStart=true;
  },
  stop_TrajectoryAnimation() {
      isStart=false;
  },
  clear_TrajectoryAnimation() {
    if (listener_postcompose) {
      unByKey(listener_postcompose);
    }
  },
  addTrajectoryAnimation(map, vectorTrajectory, points) {
    var tileLayer = new TileLayer({
      source: new OSM()
    });

    // var map = new Map({
    //   layers: [tileLayer],
    //   target: 'map',
    //   view: new View({
    //     center: [0, 0],
    //     zoom: 2
    //   })
    // });

    var imageStyleFinish = new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: 'yellow' }),
        stroke: new Stroke({ color: 'red', width: 1 })
      })
    });
    var imageStyleStandard = new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: 'grey' }),
        stroke: new Stroke({ color: 'grey', width: 1 })
      })
    });

    var headInnerImageStyle = new Style({
      image: new CircleStyle({
        radius: 2,
        fill: new Fill({ color: 'blue' })
      })
    });

    var headOuterImageStyle = new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: 'black' })
      })
    });

    var n = 4;
    var omegaTheta = 30000; // Rotation period in ms
    var R = 7e6;
    var r = 2e6;
    var p = 2e6;
    let coordinatesTest = [
      [102.245722, 27.865106],
      [102.245463, 27.788754],
      [102.381849, 27.808182],
      [102.352495, 27.876032],
    ];



    function getStyleF() {
      var style = new Style({
        fill: new Fill({//填充样式
          color: 'rgba(255, 0, 0, 0.5)'
        }),
        stroke: new Stroke({//边框样式
          color: 'red',
          width: 3,
          lineCap: 'square'
        }),
        image: new CircleStyle({//点样式使用一个圆
          radius: 7,
          fill: new Fill({
            color: 'red'
          })
        })
      });
      return style;
    }
    function getStyleS() {
      var style = new Style({
        fill: new Fill({//填充样式
          color: 'rgba(255, 255, 255, 0.5)'
        }),
        stroke: new Stroke({//边框样式
          color: 'gray',
          width: 3,
          lineCap: 'square'
        }),
        image: new CircleStyle({//点样式使用一个圆
          radius: 7,
          fill: new Fill({
            color: 'gray'
          })
        })
      });
      return style;
    }
    console.log(patchPoint(points[0], points[1]), '--------pointsAfterPatch----------------patchPoint-------------------')

    // let coordinatesAfterPathAll = patchPointsAllFun();

    function patchPointsAllFun() {
      if (!points) {
        return [];
      }
      let coordinatesAfterPathAll = [];
      for (let index = 0, len = points.length; index < (len - 1); index++) {
        const pointStart = points[index];
        const pointEnd = points[index + 1];
        let patchPoints = patchPoint(pointStart, pointEnd);
        coordinatesAfterPathAll = [
          ...coordinatesAfterPathAll,
          ...patchPoints,
        ]
      }
      return coordinatesAfterPathAll;
    }
    function patchPoint(pointStart, pointEnd, indexNum) {
      let patchPointConstant = 10000;//ms
      let timeStart = moment(pointStart.time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      let timeEnd = moment(pointEnd.time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      let timeInterval = timeEnd - timeStart;//两点间时间间隔
      // let patchPointCount = timeInterval / patchPointConstant;//需要补点的数量
      let patchPointCount = 100;//需要补点的数量
      let patchPointInterval = (pointEnd.lon - pointStart.lon) / patchPointCount;//补点间的经度间隔
      let k = (pointEnd.lat - pointStart.lat) / (pointEnd.lon - pointStart.lon);//两边斜比率
      let pointsAfterPatch = [pointStart];
      let coordinatesAfterPath = [
        // transform([pointStart.lon, pointStart.lat], 'EPSG:4326', 'EPSG:3857'),
      ]
      for (let index = 0; index < patchPointCount; index++) {
        let x = pointStart.lon + patchPointInterval * (index + 1);
        let y = k * (x - pointEnd.lon) + pointEnd.lat;
        let time = moment(timeStart + patchPointConstant * (index + 1)).format('YYYY-MM-DD HH:mm:ss');
        pointsAfterPatch = [
          ...pointsAfterPatch,
          {
            time: time,
            lon: x,
            lat: y,
          }
        ]
        coordinatesAfterPath = [
          ...coordinatesAfterPath,
          transform([x, y], 'EPSG:4326', 'EPSG:3857'),
        ]
      }
      // pointsAfterPatch = [
      //   ...pointsAfterPatch,
      //   transform([pointEnd.lon, pointEnd.lat], 'EPSG:4326', 'EPSG:3857'), ,
      // ]
      return coordinatesAfterPath[indexNum];
    }
    function createStops(points) {
      let coordinatesStops = [];
      points.map((item, index) => {
        coordinatesStops = [
          ...coordinatesStops,
          transform(
            [item.lon, item.lat],
            'EPSG:4326', 'EPSG:3857'),
        ]
      })
      return coordinatesStops;
    };
    let indexFlag = 1;//当前节点数索引
    let indexNum = 0;//当前节点内补点索引
    var coordinatesAll = createStops(points);
    var coordinatesAfterPathAllFinish = [coordinatesAll[0]];
    var coordinatesAfterPathAllStandard=[];
    let speed = 5;
    let progress = 0;
    listener_postcompose = map.map.on('postcompose', function (event) {
      progress += 1;
      var vectorContext = event.vectorContext;
      // var vectorContext = getVectorContext(event);
      var frameState = event.frameState;
      var theta = 2 * Math.PI * frameState.time / omegaTheta;
      // let coordinatesAfterPathAllFinish = coordinatesAfterPathAll.slice(0, indexFlag);
      // let coordinatesAfterPathAllStandard = coordinatesAfterPathAll.slice(indexFlag, (coordinatesAfterPathAll.length - 1));
      if (progress % speed == 0 && isStart) {
        indexFlag+=1;
        //临时
        coordinatesAfterPathAllFinish = coordinatesAll.slice(0, indexFlag);
        coordinatesAfterPathAllStandard = coordinatesAll.slice(indexFlag>0?indexFlag-1:indexFlag, (coordinatesAll.length - 1));


        //通过当前节点+下一个节点计算出，节点间运行补点，按照自增长索引加载补点
        // let pointStart = coordinatesAll[indexFlag];
        // let pointEnd = coordinatesAll[indexFlag + 1];
        // let pointSupply = patchPoint(pointStart,pointEnd,indexNum);



        //   // // 最后一个标记点的坐标
        //   // var lngX = coordinatesAfterPathAllFinish[coordinatesAfterPathAllFinish.length-1][0];
        //   // var lngY = coordinatesAfterPathAllFinish[coordinatesAfterPathAllFinish.length-1][1];

        //   // var interval = setInterval(function () {
        //   //   // lngX = lngX - Math.random() * 30;
        //   //   // lngY = lngY + Math.random() * 30;
        //   //   var newPoint = [lngX, lngY];
        //   //   coordinatesAfterPathAllFinish.shift();
        //   //   coordinatesAfterPathAllFinish.push(newPoint);
        //   //   geometryT.setCoordinates(coordinatesAfterPathAllFinish);
        //   // }, 3000);

        //   // setTimeout(function () {
        //   //   clearInterval(interval);
        //   // }, 5000);
        //   // for (i = 0; i < n; ++i) {
        //   //   var t = theta + 2 * Math.PI * i / n;
        //   //   var x = (R + r) * Math.cos(t) + p * Math.cos((R + r) * t / r);
        //   //   var y = (R + r) * Math.sin(t) + p * Math.sin((R + r) * t / r);
        //   //   let lonlatMax = obj.map([x,y], 'EPSG:3857', 'EPSG:4326')
        //   //   coordinates.push([x, y]);
        //   // }

        //   // vectorContext.setStyle(imageStyleStandard);
        //   // vectorContext.drawGeometry(new MultiPoint(coordinatesAfterPathAllStandard));
        // // vectorContext.drawLineString(geometryF);11
        
      }
      if (coordinatesAfterPathAllFinish.length>0) {
        vectorContext.setStyle(imageStyleFinish);
        vectorContext.drawGeometry(new MultiPoint(coordinatesAfterPathAllFinish));
        var headPoint = new Point(coordinatesAfterPathAllFinish[coordinatesAfterPathAllFinish.length - 1]);
        
        vectorContext.setStyle(headOuterImageStyle);
        vectorContext.drawGeometry(headPoint);
        
        vectorContext.setStyle(headInnerImageStyle);
        vectorContext.drawGeometry(headPoint);
      }
      
      //实例一个线(标记点)的全局变量
      var geometryF; //线,Point 点,Polygon 面
      var geometryS; //线,Point 点,Polygon 面
      
      //   // //添加标记点
      function addPonitToGeometry(arrF, arrS) {
        geometryF = new LineString(arrF);
        geometryS = new LineString(arrS);
        // for (var i = 0; i < arr.length; i++) {
          //   geometryT.appendCoordinate(arr[i]);
          // }
        }
        addPonitToGeometry(coordinatesAfterPathAllFinish,coordinatesAfterPathAllStandard);
        
        var LineStringFeatureF = new Feature(geometryF); //绘制线的数据
        var LineStringFeatureS = new Feature(geometryS); //绘制线的数据
        vectorTrajectory.getSource().clear();
        //设置样式
        LineStringFeatureF.setStyle(getStyleF());
        LineStringFeatureS.setStyle(getStyleS());
        // vectorTrajectory.setStyle(getStyle())
        //添加轨迹
        vectorTrajectory.getSource().addFeature(LineStringFeatureF);
        vectorTrajectory.getSource().addFeature(LineStringFeatureS);
      if (indexFlag == (coordinatesAll.length - 1)) {
        indexFlag = 1;
        progress=0;
        coordinatesAfterPathAllFinish=[];
        // unByKey(listener_postcompose);
      } else {
        // var interval = setInterval(function () {
        map.map.render();
        // indexFlag++;
        // }, 3000);
        // setTimeout(function () {
        //   clearInterval(interval);
        // }, 3100);
      }
    });
    map.map.render();

  }
}