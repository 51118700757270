// 报警记录查询
import React, { Component } from 'react'
import './css/system.css'
import AddFormItem from './components/addFormItem/AddFormItem'
import {Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload, Menu, Dropdown, TreeSelect} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import {
    getArgs,
    insertShip,
    getUgroupTree,
    exportShipTemplate,
    deleteCard,
    updateShipDetail,
    bindShipDevice,
    importShips,
    selectAlarmList,
    selectByRegion
} from '../../data/dataStore'
import $ from "jquery";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
let format = 'YYYY-MM-DD HH:mm:ss';
//今天0点
const piker1 = moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss');
//今天23点59
const piker2 = moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss');
class Alarmhistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            Label: false,
            ulshow: false,
            modify: false,
            pageIndex: 1,
            pageSize: 10,
            cardNumber:'',
            shipName:'',
            regionName:'',
            regionId:'',
            reportTime:'',

            //上个月今天
            startTime: moment(piker1).format('YYYY-MM-DD HH:mm:ss'),
            //今天的年月日
            endTime: moment(piker2).format('YYYY-MM-DD HH:mm:ss'),
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: null,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            userData: [],
            userGroupData: [],
            testData:[],
            loadingServiceFlag: false,
            trajectoryDateList: [

                //本月
                // moment(moment().startOf('month')),
                // moment(moment().endOf('month'))
                moment(moment().subtract(1,'month').format('YYYY-MM-DD')),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')
                // moment(moment().endOf('month'))
                // moment(moment().format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss'),

                // moment(moment.add(-1,'M')),
                // moment(moment.add(0,'M'))

            ],//上报时间
            //选中船舶信息
            strs: undefined,
            // 空树形结构数组
            treeData : [

            ],
        }
        this.deleteCard = this.deleteCard.bind(this)
        this.selectByRegion = this.selectByRegion.bind(this)
        this.selectAlarmList = this.selectAlarmList.bind(this)
        this.insertShip = this.insertShip.bind(this)
        this.bindShipDevice = this.bindShipDevice.bind(this)
    }

    componentDidMount() {
        this.selectByRegion()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    // 查询区域树形结构
    async selectByRegion(value, type) {
        let params = {
        }
        let data = await selectByRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let treeSelectData = this.handleData(data.rows);
            if (type === 'select') {
                this.setState({
                        treeData: treeSelectData,
                    }
                )
            } else {
                this.setState({
                    treeData: treeSelectData,
                })
            }
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    //导出模板接口
    async exportShipTemplate() {
        let params = {

        }
        let data = await exportShipTemplate(params)
            .then(res => {
                if (res.ok) {
                    res.blob().then((blob) => {
                        var a = document.createElement('a');
                        var url = window.URL.createObjectURL(blob);
                            var filename = "卡管理导入模板.xlsx";
                        a.href = url;
                        a.download = filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                } else {
                    return Promise.reject("导出失败");
                }
            })
            .catch(ex => {
                let exMsg = ex instanceof Object ? ex.toString() : ex;
                message.error(exMsg, 5);
            });

    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectAlarmList()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.selectAlarmList(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectAlarmList(names)
        this.setState({
            name: names
        })
    }
    selectByTime = (e, value) => {

        let startTime = value[0] + " 00:00:00";
        let endTime = value[1];
        endTime = (endTime == (moment().format('YYYY-MM-DD'))) ? endTime + " " + moment().format('HH:mm:ss') : endTime + " " + "23:59:59"
        console.log("<<<"+startTime)
        console.log("<<<"+endTime)
        this.setState({
            startTime: startTime,
            endTime: endTime,

        })
    }

    // 分页查询
    async selectAlarmList(value, type, pageSizeApi, coloums,id) {
        this.setState({ loading: true })
        let {startTime,endTime,pagination } = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            shipName: this.state.shipName,
            //增加开始时间与结束时间参数
            startTime,
            endTime,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
            regionName: this.state.regionName,
            regionId: this.state.regionId,
        }
        let data = await selectAlarmList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UallArr: data.rows,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                    this.setState({
                        ulshow: false,
                        dataSource: data.rows,
                        pageTotal: data.total,
                        loading: false
                    })
                }
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    //导出
    exportDefaultExcel = (e, coloums) => {
        let dockInfoData = this.state.dataSource;
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (dockInfoData && dockInfoData.length > 0) {
            exportExcel(headers, dockInfoData, '航次统计报表.xlsx');
        }
        else {
            message.error('导出数据失败！！！');
        }
    }
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectAlarmList()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 新增卡
    async insertShip(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await insertShip(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败")
        }
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }
    // 绑定设备
    async bindShipDevice(arr) {
        let { labelData } = this.state;
        let params = [];
        console.log(arr, 'oooooooooo')
        arr.forEach((val) => {
            if (val) {
                params.push({
                    objectName: labelData.shipName,
                    objectId: labelData.objectInfoId,
                    deviceId: val.deviceId,
                    deviceType: val.deviceType,
                    cardNumType: val.cardNumType,
                })
            }

        })
        console.log(params, 'params')
        let data = await bindShipDevice(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success("绑定成功")
            this.selectAlarmList()
        } else {
            message.error(data.msg)
        }
        this.setState({
            Label: false,
        })
    }
    exportDefaultExcelApi = (e, coloums) => {
        this.selectAlarmList(undefined, undefined, 9999999, coloums)
    }
    //导出
    exportDefaultExcel = (e, coloums) => {

        let dockInfoData = this.state.AlldataSource;
        delete coloums[7]
        if (dockInfoData) {
            dockInfoData.map((items) => {
                if (this.state.shipTypeArr) {
                    this.state.shipTypeArr.map((item) => {
                        if (item.value == items.shipType) {
                            items.shipType = item.text
                        }
                    })
                }
            })
        }
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (dockInfoData && dockInfoData.length > 0) {
            exportExcel(headers, dockInfoData, '船舶管理.xlsx');
        }
        else {
            message.error('导出数据失败！！！');
        }
    }
    // 修改卡详情
    async updateShipDetail(obj) {
        console.log(obj, 'oooooooooo')
        let data = await updateShipDetail(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                modify: false,
            })
            this.selectAlarmList()
            message.success("修改成功")
        } else if (data.code == 500) {
            message.error("修改失败")
        }
        this.setState({
            deleteShow: false,
        })
    }
    // 导入卡
    async importShips(fileData) {
        this.setState({ loading: true })
        let formData = new FormData()
        formData.append("file", fileData)
        // console.log(params,'params')
        let data = await importShips(formData)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success("上传成功")
        } else {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
             loading: true
        })
    }
    Labelmanagement = (data) => {
        console.log(data, 'shipName1111')
        this.setState({
            Label: true,
            labelData: data
        }, () => {
            console.log(this.state.labelData, 'shipName1')
        })
    }
    modify = (data) => {
        this.setState({
            modify: true,
            modifyData: data,
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 删除卡
    async deleteCard() {
        let { deleteData } = this.state;
        console.log(deleteData, 'deleteData')
        let params = {
            id: deleteData.id,
        }
        console.log(deleteData, params, 'params')
        let data = await deleteCard(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectAlarmList()
        } else if (data.code == 500) {
            message.error("删除失败")
        }
        this.setState({
            deleteShow: false,
        })
    }
    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            //添加的对话框
            addShow: false,
            //删除提示框
            deleteShow: false,
            //标签
            Label: false,
            //修改的对话框
            modify: false,
            //
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }
    exportDefault = () => {
        this.setState({
        }, () => {
            this.exportShipTemplate()
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectAlarmList()
        })
        console.log("455"+pagination, filters, sorter, 'sorter')
    }
    getNewShipData = (value) => {
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.id = modifyData.id
        this.updateShipDetail(value)
        console.log(value, 'getNewShipData')
    }
    // 获取文件后缀
    getFileSuffix = name => {
        const index = name.lastIndexOf(".");
        const len = name.length;
        return name.substring(index, len) || "-";
    };

    beforeUpload = file => {
        const suffix = this.getFileSuffix(file.name);
        const isLt2M = file.size / 1024 / 1024 < 2;
        const isValid = suffix === ".xlsx" || suffix === ".xls";
        if (!isValid) {
            message.warn("上传失败，请选择.xlsx或.xls格式");
        }
        //   if (!isLt2M) {
        //     message.warn("上传失败，大小不能超过2M");
        //   }
        return isValid;
    }
    //分页筛选
    async getArgs(value) {
        let params = {

        }
        let data = await getArgs(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.rows,

            })


        } else {
        }
    }
    //筛选
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            let { objectNameArr } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 100 }} placeholder={'请选择'}
                        showSearch
                        multiple
                    >
                        {
                            objectNameArr && objectNameArr.regionNames.map((item) => (
                                <Option
                                    onClick={this.clickSearchData1.bind(this, item)}
                                    value={item} >{item}</Option>
                            ))
                        }
                    </Select>,
          </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectAlarmList()
        });
    }
    // 用户组管理的接口
    // 层级树查询
    async getUgroupTree(id) {
        let params = {
            id: id ? id : '1',
        }
        let data = await getUgroupTree(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.testData)
            this.setState({
                userGroupData: newData,
                selectedKeys
            })
        }
    }


    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    // 绑定用户组
    async bindUgroupRelation(id) {
        let { selectedKeys, manageData } = this.state
        console.log(selectedKeys, manageData, 'manageData')
        let params = {
            id: manageData.id,
            ugroupIds: [...selectedKeys]
        }
        let data = await bindUgroupRelation(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.getUgroupTree(manageData.id)
            message.success("绑定成功")
        } else {
            message.error("绑定失败")
        }
    }
    // 点击表格管理的事件
    clickManage = () => {
        this.getUgroupTree(1)
        this.setState({
            manageShow: true,
            manageData: '1'
        })
    }
    showTrackDetail=(record)=>{
        console.log('record.cardNumber--------',record.cardNumber);
        console.log('record.shipName--------',record.shipName);
        console.log(record,'record-----')
        // this.setState({
        //     cardNumber:record.cardNumber,
        //     shipName:record.shipName,
        // })
        // this.props.history.push('/home',{
        //     cardNumber:record.cardNumber,
        //     shipName:record.shipName,
        // })

        this.props.history.push({
            pathname: '/home', state: {
                cardNumber:record.cardNumber,
                shipName:record.shipName,
            }})
    }
    /**
     * 递归生成数据  因为后端给的字段没有和ant design相关的 title value等属性值
     * 所以需要递归修改data属性值，配合treeSelect规范数据
     * @param {Object} data 原始数据
     */
    handleData = (data) => {
        const item = [];
        data.map((list) => {
            const newData = {};
            newData.value = list.regionName;
            newData.id = list.id
            newData.title = list.regionName;
            newData.children = list.children ? this.handleData(list.children) : []; // 如果还有子集，就再次调用自己
            item.push(newData);
            return false
        });
        //console.log("<<<"+JSON.stringify(item))
        return item;
    }
    onChange = (value, label, extra) => {
        console.log("<<<value, label, extra"+value, label, extra);
        this.setState({
            // value,
            regionName:value,
            regionId:extra['triggerNode']?extra['triggerNode']['props']['id']:''
        });
    };
    onChange2 = (value, label, extra) => {
        console.log("<<<value, label, extra"+value, label, extra);
        this.setState({
            value,
            // regionName:value,
            // regionId:extra['triggerNode']?extra['triggerNode']['props']['id']:''
        });
    };
    render() {
        let { dataSource, modifyData, UallArr,startTime,endTime, LabelSize, ulshow, labelData, shipTypeArr,userData,nowModalData, selectedKeys, userGroupData,  trajectoryDateList, pageTotal} = this.state;
        // console.log(modifyData, 'modifyData')
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let {treeData, ...rest } = this.props;
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        //列表字段
        const columns = [
            {
                title: '卡号',
                key: 'cardNumber',
                dataIndex: 'cardNumber',
            },
            {
                title: '船名',
                key: 'shipName',
                dataIndex: 'shipName',
            },
            {
                title: '区域',
                key: 'regionName',
                dataIndex: 'regionName',
            },

            {
                title: '报警类型',
                key: 'typeName',
                dataIndex: 'typeName',
            },

            {
                title: '报警时间',
                key: 'reportTime',
                dataIndex: 'reportTime',
                render: (reportTime) => {
                    if (reportTime != null) {
                        let time = moment(parseInt(reportTime)).format('YYYY-MM-DD HH:MM:ss ');
                        return time;
                    }
                },
            },

            {
                title: '原始数据识别码',
                key: 'uid',
                dataIndex: 'uid',

            },

            // {
            //     title: '操作',
            //     key: 'detele',
            //     render: (text, record) => {
            //         return <div>
            //             <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modify(record)}>解除</span>
            //
            //     </div>
            //     },
            // },
        ]
        //添加对话框
        const formItemList = [
            {
                label: '卡4号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '卡号不能为空!' }] }
            },
            {
                label: '频度', //表单label
                id: 'frequency', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '通播号', //表单label
                id: 'multicastNumber', //表单Item的Key值shipTypeArr
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '37%', marginRight: '3%' }}
                // onChange={this.handleDeviceChange}
                >
                    {
                        this.state.shipTypeArr && this.state.shipTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select> //表单受控组件
            },
            {
                label: '任务单', //表单label
                id: 'taskNum', //表单Item的Key值
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '37%', marginRight: '3%' }}
                // onChange={this.handleDeviceChange}
                >
                    {
                        this.state.deviceTypeArr && this.state.deviceTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select>
                , //表单受控组件
            },
            {
                label: '备注', //表单label
                id: 'status', //表单Item的Key值
                component: <Select
                    placeholder={'请选择'}
                // style={{ width: '16%', marginRight: '2%' }}
                // onChange={this.handleCurrencyChange}
                >
                    {
                        this.state.cardTypeArr && this.state.cardTypeArr.map((item) => (
                            <Option key={item.value} value={item.value}>{item.text}</Option>
                        ))
                    }
                </Select>, //表单受控组件
            },
            {
                label: '项目名', //表单label
                id: 'projectName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '卡状态', //表单label
                id: 'Territoriality', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },]
        /*
       *
       *
       *北斗卡详情
       *
       * */
        const modifyList = [
            {
                label: '北斗5信息',
                id: 'divider1',
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{ marginLeft: 8 }}>北斗信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '北斗卡号',
                id: 'cardNumber',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.cardNumber }
            },
            {
                label: '通信比特数',
                id: 'bits',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.bits }
            },
            {
                label: '频度',
                id: 'frequency',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.frequency }
            },
            {
                label: '通播号',
                id: 'multicastNumber',
                component: <Input placeholder="请输入" />,
                options: {
                    initialValue: modifyData && modifyData.multicastNumber,
                    // rules: [{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确!' }]
                }
            },
            {
                label: '项目名称',
                id: 'projectName',
                component: <Input placeholder="请输入" />,
                options: {
                    initialValue: modifyData && modifyData.projectName,
                    // rules: [{ pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/, message: '身份证格式不正确!' }]
                }
            },
            {
                label: '任务单号',
                id: 'taskNum',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.taskNum }
            },
            {
                label: '卡状态',
                id: 'status',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.status }
            },
            {
                label: '最新报位时间',
                id: 'locationTime',
                component: <Input placeholder="请输入" format={format} />,
                /*
                *  render: (gmtCreate) => {
                    let time = moment(parseInt(gmtCreate)).format('YYYY-MM-DD ')
                    return time;
                },
                *
                * */
                options: { initialValue: modifyData && modifyData.locationTime ? moment(parseInt(modifyData.locationTime)).format('YYYY-MM-DD HH:mm:ss'):''},
                // options: { initialValue: modifyData && modifyData.locationTime ? moment(parseInt(modifyData.locationTime) , format) : '' }
            },
            {
                label: '经度',
                id: 'lon',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.lon }
            },
            {
                label: '纬度',
                id: 'lat',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.lat }
            },
            {
                label: '航速',
                id: 'speed',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.speed }
            },
            {
                label: '航向',
                id: 'dir',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.dir }
            },
            {
                label: '创建时间',
                id: 'gmtCreate',

                component: <Input placeholder="请输入" format={format} />,
                options: { initialValue: modifyData && modifyData.gmtCreate ? moment(parseInt(modifyData.gmtCreate)).format('YYYY-MM-DD HH:mm:ss'):''},
                // render: (gmtCreate) => {
                //     return moment(parseInt(gmtCreate)).format('YYYY-MM-DD ');
                // },
                // render: (gmtCreate) => {
                //     let time = moment(parseInt(gmtCreate)).format('YYYY-MM-DD ')
                //     return time;
                // },
            },

            /*
            *
            *船的=-信息
            *
            *
            * */


            {
                label: '船体信息',
                id: 'divider2',
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{ marginLeft: 8 }}>船体信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '船名',
                id: 'shipName',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.shipName }
            },
            {
                label: '船的类型',
                id: 'type',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.type }
            },
            {
                label: '船主',
                id: 'owner',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.owner }
            },
            {
                label: '电话',
                id: 'phone',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.phone }
            },
            {
                label: '地址',
                id: 'address',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.address }
            },
            {
                label: '区域名称',
                id: 'regionName',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.regionName }
            },
            {
                label: '身份证号',
                id: 'idCard',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.idCard }
            },
            {
                label: '安装时间',
                id: 'installTime',
                component: <Input placeholder="请输入" format={format} />,
                options: { initialValue: modifyData && modifyData.installTime ? moment((modifyData.installTime)).format('YYYY-MM-DD HH:mm:ss'):''},
            },
            {
                label: '安装人',
                id: 'installer',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.installer }
            },
            {
                label: '船长（米）',
                id: 'length',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.length }
            },
            {
                label: '船宽（米）',
                id: 'width',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.width }
            },
            {
                label: '船高（米）',
                id: 'height',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.height }
            },
            {
                label: '设备信息',
                id: 'divider3',
                component: (
                    <div className="dibider">
                        <i className="bluei"></i><span style={{ marginLeft: 8 }}>设备信息</span>
                    </div>
                ),
                divider: true,
            },
            {
                label: '设备ID',
                id: 'deviceId',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceId }
            },
            {
                label: '设备名称',
                id: 'deviceName',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceName }
            },
            {
                label: '收费类型',
                id: 'chargeType',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.chargeType }
            },
            {
                label: '设备型号',
                id: 'deviceType',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceType }
            },
            {
                label: '设备编号',
                id: 'deviceNumber',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.deviceNumber }
            },
            {
                label: '显示屏型号',
                id: 'displayType',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.displayType }
            },
            {
                label: '显示屏编号',
                id: 'displayNumber',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.displayNumber }
            },
            /*
            *
            *
            *注意： DateTimePiker  use old  way  is  wrong,so   for   now   way  to  achieve .
            *
            *
            * */
            {
                label: '发货时间',
                id: 'sendDate',
                component: <Input placeholder="请输入" format={format} />,
                // options: { initialValue: modifyData && modifyData.sendDate ? moment(modifyData.sendDate, format) : '' }
                options: { initialValue: modifyData && modifyData.sendDate ? moment((modifyData.sendDate)).format('YYYY-MM-DD HH:mm:ss'):''},
                //
                // component: <Input placeholder="请输入" format={format} />,
                // options: { initialValue: modifyData && modifyData.sendDate ? moment(parseInt(modifyData.sendDate)).format('YYYY-MM-DD HH:mm:ss'):''},
            },
            {
                label: 'IMEI',
                id: 'imei',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.imei }
            },
            {
                label: 'ICCID',
                id: 'iccid',
                component: <Input placeholder="请输入" />,
                options: { initialValue: modifyData && modifyData.iccid }
            },


        ]
        //树形图区域
        // const columnsTree = [
        //     {
        //         title: '用户组名称',
        //         key: 'label',
        //         dataIndex: 'label',
        //     },
        //     {
        //         title: '类别',
        //         key: 'type',
        //         dataIndex: 'type',
        //         filters: type_ugroup,
        //         onFilter: (value, record) => record.type === value,
        //         render: (text) => {
        //             let newType;
        //             console.log("+++++++++++++++++++++++++++++++"+type_ugroup)
        //             type_ugroup.forEach((item) => {
        //                 if (text === item.value) {
        //                     newType = item.text
        //                     console.log("+++++++++++++++++++++++++++++++"+type_ugroup.type)
        //                 }
        //             })
        //             return newType
        //         }
        //     },
        //     {
        //         title: '层级',
        //         key: 'path',
        //         dataIndex: 'path',
        //     },
        //     {
        //         title: '备注',
        //         key: 'remark',
        //         dataIndex: 'remark',
        //         render: (text) => {
        //             return text ? text : '--'
        //         }
        //     },
        // ]

        // const upload = {
        //     name: 'file',
        //     action: '/bd3api/importShips',
        //     headers: {
        //         authorization: 'authorization-text',
        //         // token: JSON.parse(sessionStorage.getItem('isLogin')).data.password
        //     },
        //     onChange(info) {
        //         if (info.file.code !== 'uploading') {
        //             console.log(info.file, info.fileList);
        //         }
        //         if (info.file.code === 'done') {
        //             message.success(`${info.file.name} 上传成功`);
        //         } else if (info.file.code === 'error') {
        //             message.error(`${info.file.name} 上传失败`);
        //         }
        //     },
        //     beforeUpload: this.beforeUpload
        // };

        const props = {

            beforeUpload: file => {
                this.importShips(file)
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };

        return (
            <div className='shipmonitoring contentBox'>
                <div className="othersManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">报警记录查询</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                卡号：
                                <Input placeholder="请输入" style={{width: '70%'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                船名：  <Input placeholder="请输入" style={{width: '70%'}} name='shipName' value={this.state.shipName} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                时间：
                                <RangePicker
                                    // locale={locale}
                                    style={{
                                        width: '70%',
                                        alignItems: 'center'
                                    }}
                                    defaultValue={[moment(startTime), moment(endTime)]}
                                    ranges={{
                                        今日: [moment(), moment()],
                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                        '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                                        '今年': [moment().startOf('year'), moment().endOf('year')],
                                    }}
                                    value={[moment(startTime), moment(endTime)]}
                                    allowClear={false}
                                    onChange={this.selectByTime}

                                />
                            </div>
                        </div>
    {/*     <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                区域：

                                {<TreeSelect
                                    style={{
                                        width: '70%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    id={this.state.value}
                                    value={this.state.regionName}
                                    allowClear={true}
                                    treeNodeLabelProp="regionName"
                                    placeholder="Please select"
                                    treeData={this.state.treeData}
                                    // treeDefaultExpandAll
                                    onChange={this.onChange}
                                    onSelect={this.onSelect}
                                    {...rest}>
                                </TreeSelect>}
                            </div>
                        </div>
       */}

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    border: 'unset'
                                }}
                                onClick={() => this.selectAlarmList()}
                            >查询</Button>

                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cardNumber: '',
                                            shipName: '',
                                            regionName: '',
                                            regionId: '',

                                        }, () => {
                                            //
                                        })
                                    }
                                }

                            >重置</Button>

                        </div>

                    </div>

                    {/*<div className="cardManagement_title_buttons">*/}
                    {/*    <div className="cardManagement_title_buttons_button" onClick={() => {*/}
                    {/*        this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})*/}
                    {/*    }} style={{borderRight: '1px solid #8D959C'}}>导出</div>*/}

                    {/*</div>*/}
                </div>

                <div className="cardManagement">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{y:515}}
                        rowKey={(record)=>record.cardNumber}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectAlarmList(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertShip}
                    title="新增"
                />
                {
                    this.state.modify && <div className="modifyShipData">
                        <h3>北斗卡详情</h3>
                        <Form formItemList={modifyList} cancel={true} submit={this.getNewShipData} closeModal={this.closeModal} />
                    </div>
                }
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.deleteShip}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.shipName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.Label}
                    onCancel={this.closeModal}
                    title="标签管理"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={600}
                >
                    <AddFormItem data={this.state.labelData} onOk={this.bindShipDevice} onClose={this.closeModal} />
                </Modal>
                <Modal
                    visible={this.state.manageShow}
                    onCancel={this.closeModal}
                    title="分配管理"
                    // footer={false}
                    width={826}
                    className="fenpeiModal123"
                    onOk={this.bindUgroupRelation}
                >
                    <Search
                        placeholder="模糊搜索"
                        onSearch={value => this.getSearchValue(this.state.userGroupData, value)}
                        style={{ width: 200, float: 'right', margonBottom: 50 }}
                    />

                </Modal>
            </div>
        )
    }
}
export default Alarmhistory