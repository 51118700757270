// 设备管理
import React, { Component } from 'react'
import './css/system.css'
import AddFormItem from './components/addFormItem/AddFormItem'
import {Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload, Spin, TreeSelect} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import {
    selectDeviceDetails,
    getArgs,
    insertDevice,
    exportShipTemplate,
    unbingDevice,
    updateShipDetail,
    unbindCardDevice,
    importDevice,
    updateDevice,
    scrapBd2Device,
    exportDevilceList,
    selectByRegion,

} from '../../data/dataStore'
import {Dropdown, Menu} from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
// 引入jQuery
import $ from 'jquery'
class DeviceManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            deleteData: {},
            unbingData:{},
            Label: false,
            ulshow: false,
            modifyShow: false,
            modifyData: {},
            pageNo: 1,
            pageSize: 10,
            cardNumber:'',
            deviceNumber:'',
            regionId: '',
            regionName: '',
            sendDate:'',
            shipOrDeviceId: '',
            dataSource: [],
            pageTotal: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
            loading: false,
            labelSize: 1,
            // 空树形结构数组
            treeData : [

            ],
            //发货时间
            sendDeviceTime:'',
            // 查询按钮触发的flag
            Flag:'false'
        }
        this.unbingDevice = this.unbingDevice.bind(this)
        this.selectDeviceDetails = this.selectDeviceDetails.bind(this)
        this.insertDevice = this.insertDevice.bind(this)
        this.updateDevice = this.updateDevice.bind(this)
        this.unbindCardDevice = this.unbindCardDevice.bind(this)
        this.exportDevilceList = this.exportDevilceList.bind(this)
        this.selectByRegion = this.selectByRegion.bind(this)
    }
    componentDidMount() {
        //查询设备的详情
        // this.selectDeviceDetails()
        this.selectByRegion()
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    // 查询区域树形结构
    async selectByRegion(value, type) {
        let params = {

        }
        let data = await selectByRegion(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            //console.log(">>>>>>>>>>selectByRegion"+JSON.stringify(data))
            let treeSelectData = this.handleData(data.rows);
            if (type === 'select') {
                this.setState({
                        treeData: treeSelectData,
                    }
                )
            } else {
                this.setState({
                    treeData: treeSelectData,
                })
            }
            //console.log(">>>>>>>>>>selectByRegion<<<<<<<<<<<<<<<<"+JSON.stringify(this.state.treeData))
        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    /**
     * 递归生成数据  因为后端给的字段没有和ant design相关的 title value等属性值
     * 所以需要递归修改data属性值，配合treeSelect规范数据
     * @param {Object} data 原始数据
     */
    handleData = (data) => {
        const item = [];
        data.map((list) => {
            const newData = {};
            newData.value = list.regionName;
            newData.id = list.id
            newData.title = list.regionName;
            newData.children = list.children ? this.handleData(list.children) : []; // 如果还有子集，就再次调用自己
            item.push(newData);
            return false
        });
        //console.log("<<<"+JSON.stringify(item))
        return item;
    }
    onChange = (value, label, extra) => {
        console.log("<<<value, label, extra"+value, label, extra);
        this.setState({
            // value,
            regionName:value,
            regionId:extra['triggerNode']?extra['triggerNode']['props']['id']:''
        });
    };
    onChange2 = (value, label, extra) => {
        console.log("<<<value, label, extra"+value, label, extra);
        this.setState({
            value,
            // regionName:value,
            // regionId:extra['triggerNode']?extra['triggerNode']['props']['id']:''
        });
    };
    // onSelect = (value,node,extra) => {
    //     console.log("<<<"+(node['props']['id']))
    //     console.log("<<<"+value,node.toString(),extra )
    //     this.setState({
    //         node,
    //         regionId:node['props']['id']
    //     })
    // }
    //导出模板接口
    async exportShipTemplate() {
        let params = {

        }
        let data = await exportShipTemplate(params)
            .then(res => {
                if (res.ok) {
                    res.blob().then((blob) => {
                        var a = document.createElement('a');
                        var url = window.URL.createObjectURL(blob);
                        var filename = "设备管理导入模板.xlsx";
                        a.href = url;
                        a.download = filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                } else {
                    return Promise.reject("导出失败");
                }
            })
            .catch(ex => {
                let exMsg = ex instanceof Object ? ex.toString() : ex;
                message.error(exMsg, 5);
            });

    }
     //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectDeviceDetails()
            })
        } else {
            this.setState({
                    name: eve.target.value,
                    shipName: name,
                }, () => {
                    this.selectDeviceDetails(this.state.name, 'select')
                }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectDeviceDetails(names)
        this.setState({
            name: names
        })
    }
    // 分页查询
    async selectDeviceDetails(value, type) {
        let { pagination,Flag} = this.state;
        if (Flag === 'true'){
            this.setState({
                pagination: {
                    current: 1,
                    pageSize: 10,
                },
            })

        }
        let params = {
            cardNumber: this.state.cardNumber,
            sendDate: this.state.sendDate,
            deviceNumber: this.state.deviceNumber,
            regionId: this.state.regionId,
            regionName: this.state.regionName,
            //sendDate: this.state.sendDeviceTime,
            pageNo: pagination.current,
            //此处可改变每页数据显示的大小  pagination.pageSize=100
            pageSize: pagination.pageSize,
        }
        console.log(JSON.stringify(params))
        let data = await selectDeviceDetails(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                    UallArr: data.rows,
                    ulshow: true,
                }
                )
            }
            else {

                this.setState({
                        //给dataSource赋值
                        dataSource: data.rows,
                        UallArr: data.rows,
                        ulshow: false,
                        pageTotal: data.total
                    }

                )
                console.log("<<<<<<<<<<<<<<<<<"+data.total)
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    ///导出Excel
    async exportDevilceList(value, type) {
        this.setState({ loading: true })
        let { datas } = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            sendDate: this.state.sendDate,
            deviceNumber: this.state.deviceNumber,
            regionName: this.state.regionName
        }
        let data = await exportDevilceList(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            if (type === 'select') {
                this.setState({
                        datas: data,
                    }
                )
            } else {
                location.href = data.message
                this.setState({
                    datas: data,
                    loading: false
                })

            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectDeviceDetails()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 新增设备
    async insertDevice(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await insertDevice(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败:"+data.message)
        }
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }
    // 绑定设备
    async unbindCardDevice(arr) {
        let { labelData } = this.state;
        let params = [];
        console.log(arr, 'oooooooooo')
        arr.forEach((val) => {
            if (val) {
                params.push({

                    id: labelData.id,

                })
            }

        })
        console.log(params, 'params')
        let data = await bindShipDevice(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success("绑定成功")
            this.selectDeviceDetails()
        } else {
            message.error(data.msg)
        }
        this.setState({
            Label: false,
        })
    }
    exportDefaultExcelApi = (e, coloums) => {
        this.selectDeviceDetails(undefined, undefined, 9999999, coloums)
    }
    //导出
    exportDefaultExcel = (e, coloums) => {

        let dockInfoData = this.state.AlldataSource;
        delete coloums[7]
        if (dockInfoData) {
            dockInfoData.map((items) => {
                if (this.state.shipTypeArr) {
                    this.state.shipTypeArr.map((item) => {
                        if (item.value == items.shipType) {
                            items.shipType = item.text
                        }
                    })
                }
            })
        }
        let headers = [];
        coloums.map((item, index) => {
            let kv = {
                k: item.dataIndex ? item.dataIndex : item.key,
                v: item.title
            }
            headers = headers.concat(kv);
        })
        if (dockInfoData && dockInfoData.length > 0) {
            exportExcel(headers, dockInfoData, '设备管理.xlsx');
        }
        else {
            message.error('导出数据失败！！！');
        }
    }
    // 导入Excel设备
    async importDevices(fileData) {
        this.setState({ loading: true })
        let formData = new FormData()
        formData.append("file", fileData)
        // console.log(params,'params')
        let data = await importDevice(formData)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("上传成功:"+data.message)
            this.selectDeviceDetails()
            // this.setState({ loading: false })
        } else {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
             loading: false
        })
    }

    modifyClickShow = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data,
        })
    }
    scrapBd2Device = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    unbingDeviceShow= (data) => {
        this.setState({
            unbingShow: true,
            unbingData: data
        })
    }
    // 解绑
    async unbingDevice() {
        let { unbingData } = this.state;
        console.log(unbingData, 'deleteData')
        let params = {
            id: unbingData.id,
        }
        console.log(unbingData, params, 'params')
        let data = await unbingDevice(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("解绑成功")
            this.selectDeviceDetails()
        } else if (data.code == 500) {
            message.error("解绑失败:"+data.message)
        }
        this.setState({
            unbingShow: false,
        })
    }

        // 报废
    async scrapDevice() {
        let { unbingData } = this.state;
        console.log(unbingData, 'deleteData')
        let params = {
            id: unbingData.id,
        }
        // console.log(unbingData, params, 'params')
        let data = await scrapDevice(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("报废成功")
            this.selectDeviceDetails()
        } else if (data.code == 500) {
            message.error("报废失败:"+data.message)
        }
        // this.setState({
        //     unbingShow: false,
        // })
    }

    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            Label: false,
            modifyShow: false,
        })
    }
    exportDefault = () => {
        this.setState({
        }, () => {
            this.exportShipTemplate()
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectDeviceDetails()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    async updateDevice(value)  {
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.id = modifyData.id
        // this.updateShipDetail(value)
        let data = await updateDevice(value)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("修改成功")
            this.selectDeviceDetails()
        } else {
            message.error("修改失败："+data.message)
        }
        this.setState({
            modifyShow: false,
        })
        // console.log(value, 'updateDevice')
    }

    //分页筛选
    async getArgs(value) {
        let params = {

        }
        let data = await getArgs(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.data,

            })


        } else {
        }
    }
    //筛选
    getColumnSearchProps = () => ({
        filterDropdown: () => {
            let { objectNameArr } = this.state
            return <div style={{ padding: 8, display: 'block' }}>
                <div>
                    <Select style={{ width: 100 }} placeholder={'请选择'}
                            showSearch
                            multiple
                    >
                        {
                            objectNameArr && objectNameArr.regionNames.map((item) => (
                                <Option
                                    onClick={this.clickSearchData1.bind(this, item)}
                                    value={item} >{item}</Option>
                            ))
                        }
                    </Select>,
                </div>
                <div>
                </div>
            </div>
        }
    });
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectDeviceDetails()
        });
    }
    //回车搜索
    onKeyup = (e) => {
        // this.selectDeviceDetails()
    }
    render() {
        let { dataSource, modifyData,pageTotal,Flag } = this.state;
        let {treeData, ...rest } = this.props;
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const columns = [
            {
                title:'设备编号',
                key:'deviceNumber',
                dataIndex:'deviceNumber',
            },
            {
                title: '北斗卡号',
                key: 'cardNumber',
                dataIndex: 'cardNumber',
            },

            {
                title: 'IMEI号',
                key: 'imei',
                dataIndex: 'imei',
            },
            {
                title: 'ICCID1号',
                key: 'iccid',
                dataIndex: 'iccid',
            },
            {
                title: '发货日期',
                key: 'sendDate',
                dataIndex: 'sendDate',
            },

            {
                title: '归属区域', //表单label
                key: 'regionName', //表单Item的Key值
                dataIndex: 'regionName',
            },
            {
                title: '设备类型',
                key: 'deviceType',
                dataIndex: 'deviceType',
                //
                // render: (text) => {
                //     let newText;
                //     if (shipTypeArr) {
                //         shipTypeArr.forEach((item) => {
                //             if (item.value == text) {
                //                 newText = item.text
                //             }
                //         })
                //     }
                //     return newText
                // },
            },

            {
                title: '显示屏型号',
                key: 'displayType',
                dataIndex: 'displayType',
                // ...this.getColumnSearchProps('displayType'),
            },
            {
                title: '显控屏编号',
                key: 'displayNumber',
                dataIndex: 'displayNumber',
            },
            {
                title: '蓝牙编号',
                key: 'blueNumber',
                dataIndex: 'blueNumber',
            },
            {
                title: '设备状态',
                key: 'status',
                dataIndex: 'status',
                render: (status) => {
                    let newText;
                    if (status == 0) {
                        newText = '已绑定'
                    } else if (status == 1) {
                        newText = '解绑'
                    }
                    return newText;
                },
            },
            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },


            {
                title: '操作',
                key: 'detele',
                width: 300,
                render: (text, record) => {
                    return <div>

                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modifyClickShow(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                {/**   <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.unbingDeviceShow(record)}>解绑</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.scrapBd2Device(record)}>报废</span> **/}
                    </div>
                },
            },
        ]
        const addFormItemList = [
            {
                label: '设备号', //表单label
                id: 'deviceNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '设备号不能为空!' }] }
            },
            {
                label: '北斗卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '北斗卡号不能为空!' }] }
            },

            {
                label: 'IMEI号', //表单label
                id: 'imei', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: 'ICCID号', //表单label
                id: 'iccid', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
           // {
           //      label: '区域id', //表单label
           //      id: 'regionId', //表单Item的Key值
           //      component: <Select
           //          placeholder={'请选择'}
           //          // style={{ width: '16%', marginRight: '2%' }}
           //          // onChange={this.handleCurrencyChange}
           //      >
           //          {
           //              this.state.cardTypeArr && this.state.cardTypeArr.map((item) => (
           //                  <Option key={item.value} value={item.value}>{item.text}</Option>
           //              ))
           //          }
           //      </Select>, //表单受控组件
           //  },
            {
                label: '区域名称', //表单label
                id: 'regionName', //表单Item的Key值
                // component: <Input placeholder={'请输入'} />, //表单受控组件

                component: <TreeSelect
                    style={{
                        width: '70%',
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    id={this.state.value}
                    allowClear={true}
                    treeNodeLabelProp="regionName"
                    placeholder="请选择"
                    treeData={this.state.treeData}
                    // treeDefaultExpandAll
                    onChange={this.onChange2}
                    // onSelect={this.onSelect}
                    {...rest}>
                </TreeSelect>,
                options: { rules: [{ required: true, message: '区域号不能为空!' }] }
            },
            {
                label: '项目名', //表单label
                id: 'projectName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '设备类型', //表单label
                id: 'deviceType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '屏型号', //表单label
                id: 'displayType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '屏编号', //表单label
                id: 'displayNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '屏厂家', //表单label
                id: 'displayFactory', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '蓝牙编号', //表单label
                id: 'blueNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '发货日期',
                id: 'sendDate',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            {
                label: '备注',
                id: 'remark',
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
            ]
        /*
        *
        *修改
        *
        * */
        const modifyFormItemList = [
            {
                label: '设备号', //表单label
                id: 'deviceNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} readOnly/>, //表单受控组件
                options: { rules: [{ required: true, message: '设备号不能为空!' }], initialValue: modifyData.deviceNumber ? modifyData.deviceNumber : '' }
            },
            {
                label: '北斗卡号', //表单label
                id: 'cardNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.cardNumber ? modifyData.cardNumber : '' }
            },

            {
                label: 'IMEI号', //表单label
                id: 'imei', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.imei ? modifyData.imei : '' }
            },
            {
                label: 'ICCID号', //表单label
                id: 'iccid', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.iccid ? modifyData.iccid : '' }
            },
           // {
           //      label: '区域id', //表单label
           //      id: 'regionId', //表单Item的Key值
           //      component: <Select
           //          placeholder={'请选择'}
           //          // style={{ width: '16%', marginRight: '2%' }}
           //          // onChange={this.handleCurrencyChange}
           //      >
           //          {
           //              this.state.cardTypeArr && this.state.cardTypeArr.map((item) => (
           //                  <Option key={item.value} value={item.value}>{item.text}</Option>
           //              ))
           //          }
           //      </Select>, //表单受控组件
           //  },
            {
                label: '区域名称', //表单label
                id: 'regionName', //表单Item的Key值
                // component: <Input placeholder={'请输入'} />, //表单受控组件
                component: <TreeSelect
                    style={{
                        width: '70%',
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    id={this.state.value}
                    allowClear={true}
                    treeNodeLabelProp="regionName"
                    placeholder="请选择"
                    treeData={this.state.treeData}
                    // treeDefaultExpandAll
                    onChange={this.onChange2}
                    // onSelect={this.onSelect}
                    {...rest}>
                </TreeSelect>,
            },
            {
                label: '项目名', //表单label
                id: 'projectName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.projectName ? modifyData.projectName : '' }
            },
            {
                label: '设备类型', //表单label
                id: 'deviceType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.deviceType ? modifyData.deviceType : '' }
            },
            {
                label: '屏型号', //表单label
                id: 'displayType', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.displayType ? modifyData.displayType : '' }
            },
            {
                label: '屏编号', //表单label
                id: 'displayNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.displayNumber ? modifyData.displayNumber : '' }
            },
            {
                label: '屏厂家', //表单label
                id: 'displayFactory', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.displayFactory ? modifyData.displayFactory : '' }
            },
            {
                label: '蓝牙编号', //表单label
                id: 'blueNumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.blueNumber ? modifyData.blueNumber : '' }
            },
            // {
            //     label: '设备状态',
            //     id: 'status',
            //     component: <Select
            //         placeholder={'请选择'}
            //         // style={{ width: '16%', marginRight: '2%' }}
            //         // onChange={this.handleCurrencyChange}
            //     >
            //         {
            //             this.state.status && this.state.status.map((item) => (
            //                 <Option key={item.value} value={item.value}>{item.text}</Option>
            //             ))
            //         }
            //     </Select>, //表单受控组件
            // },
            {
                label: '发货日期',
                id: 'sendDate',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.sendDate ? modifyData.sendDate : '' }
            },
            {
                label: '备注',
                id: 'remark',
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData.remark ? modifyData.remark : '' }
            },
        ]
        const upload = {
            name: 'file',
            action: '/bd3api/importShips',
            headers: {
                authorization: 'authorization-text',
                // token: JSON.parse(sessionStorage.getItem('isLogin')).data.password
            },
            onChange(info) {
                if (info.file.code !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.code === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                } else if (info.file.code === 'error') {
                    message.error(`${info.file.name} 上传失败`);
                }
            },
            beforeUpload: this.beforeUpload
        };

        const props = {

            beforeUpload: file => {
                this.importDevices(file)
                // this.setState({ loading: true })
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };

        return (
            // <Spin spinning={this.state.loadingServiceFlag} size={'large'}>
            <div className='shipmonitoring contentBox'>
                <div className="cardManagement_title cardTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">设备管理</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="cardManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                卡号：
                                <Input placeholder="请输入" style={{width: '60%'}} name='cardNumber' value={this.state.cardNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                设备号：
                                <Input placeholder="请输入" style={{width: '60%'}} name='deviceNumber' value={this.state.deviceNumber} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                区域：
                                {<TreeSelect
                                    style={{
                                        width: '70%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    value={this.state.regionName}
                                    id={this.state.value}
                                    allowClear={true}
                                    treeNodeLabelProp="regionName"
                                    placeholder="Please select"
                                    treeData={this.state.treeData}
                                    // treeDefaultExpandAll
                                    onChange={this.onChange}
                                    onSelect={this.onSelect}
                                    {...rest}>
                                </TreeSelect>}
                            </div>
                        </div>
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                发货日期：
                                <Input placeholder="请输入" style={{width: '60%'}} name='sendDate' value={this.state.sendDate} onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    // backgroundColor: '#81BEF8',
                                    border: 'unset'
                                }}
                                    onClick={() => {
                                        this.setState({
                                            Flag:'true'

                                        },() =>{
                                            this.selectDeviceDetails()
                                        })
                                    }}
                            >查询</Button>


                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}
                                onClick={
                                    () => {
                                        this.setState({
                                            cardNumber: '',
                                            deviceNumber: '',
                                            regionName: '',
                                            regionId: '',
                                            sendDate: '',

                                        },() => {

                                        })
                                    }
                                }

                            >重置</Button>

                        </div>

                    </div>
                    <div className="cardManagement_title_buttons">
                        {/*<div className="cardManagement_title_buttons_button" onClick={() => {*/}
                        {/*    this.request_exportExcelToNative({fileName: 'Card_ManegMent_Data.xlsx'})*/}
                        {/*}}>导出</div>*/}
                        <div className="cardManagement_title_buttons_button" > <Upload
                            {...props}
                            style={{ paddingTop: 19 }}>
                                导入
                        </Upload>
                        </div>
                        <div  className="cardManagement_title_buttons_button" onClick={() => {
                            this.exportDevilceList()
                        }}>导出</div>
                        <div className="cardManagement_title_buttons_button"
                             onClick={this.addClickShow} style={{borderRight: '1px solid #8D959C'}}>新增
                        </div>


                    </div>
                </div>

                <div className="deviceManagement">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{y:490}}
                        rowKey={record => record.id}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectDeviceDetails(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={addFormItemList}
                    closeModal={this.closeModal}
                    submit={this.insertDevice}
                    title="新增"
                />
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyFormItemList}
                    closeModal={this.closeModal}
                    submit={this.updateDevice}
                    title="修改"
                />

                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="报废提醒"
                    onOk={this.scrapDevice}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.cardNumber}"数据报废后不可恢复，确认走报废流程吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.unbingShow}
                    onCancel={this.closeModal}
                    title="解绑提醒"
                    onOk={this.unbingDevice}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.unbingData.cardNumber}"数据解绑后不可恢复，确认走解绑流程吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.Label}
                    onCancel={this.closeModal}
                    title="标签管理"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={600}
                >
                    <AddFormItem data={this.state.labelData} onOk={this.bindShipDevice} onClose={this.closeModal} />
                </Modal>
            </div>
            // </Spin>
        )
    }
}
export default DeviceManage