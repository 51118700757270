// 黑名单管理
import React, { Component } from 'react'
import SearchTop from './components/SearchTop/SearchTop'
import { fastSelectShip, selectShipBlackListObscure, insertShipMonitorInfo, deleteShipBlackListInfo, updateShipBlackListInfo } from '../../data/dataStore';
import { Table, Input, Modal, Icon, DatePicker, Select, message } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import './enforceTheLaw.css'
import moment from 'moment'
import Form from '../../componts/form/Form'
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
const { TextArea } = Input;
function onChange(date, dateString) {//时间选择
    console.log(date, dateString);
}
let format = 'YYYY-MM-DD HH:mm:ss';
function handleChange(value) {
    console.log(`selected ${value}`);
}
export default class Blacklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            deleteData: {},
            modify: false,
            dataSource: [],
            Label: false,
            name: '',
            ulshow: false,
            pageIndex: 0,
            pageSize: 100,
            shipName: '',
            pageTotal: null,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10000,
                showQuickJumper: true
            },
            loading: false,
            labelSize: 1,
        }
        this.selectShipBlackListObscure = this.selectShipBlackListObscure.bind(this)
        this.insertShipMonitorInfo = this.insertShipMonitorInfo.bind(this)
        this.deleteShipBlackListInfo = this.deleteShipBlackListInfo.bind(this)

    }
    componentDidMount() {
        this.selectShipBlackListObscure();
        this.fastSelectShip()
    }
    // 搜索船名和标签id
    async fastSelectShip(value) {
        let params = {
        }

        let data = await fastSelectShip(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data && data.code == 200) {
            this.setState({
                objectNameArr: data.data,
            })


        } else if (data && data.code == 505) {
            if (this.props.history) {
                this.props.history.push('/login')
            }
        }
    }
    //输入框为空时搜索列表不显示
    controlList = (eve) => {
        console.log(eve, 'vvvvvv')
        if (!eve.target.value) {
            this.setState({
                ulshow: false,
                name: ''
            }, () => {
                this.selectShipBlackListObscure()
            })
        } else {
            this.setState({
                name: eve.target.value,
                shipName: name,
            }, () => {
                this.selectShipBlackListObscure(this.state.name, 'select')
            }
            )
        }
    }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectShipBlackListObscure(names)
        this.setState({
            name: names
        })
    }
    //分页查询
    async selectShipBlackListObscure(value, type) {
        this.setState({ loading: true })
        let { pagination, shipName, name } = this.state;
        let params = {
            shipName: value ? value : name,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            remark: '',
            monitorType: '302301'
        }

        let data = await selectShipBlackListObscure(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });

        if (data.code == 200) {

            if (type === 'select') {
                this.setState({
                    ualArr: data.data.items,
                    ulshow: true,
                    loading: false
                }
                )
            } else {
                pagination.total = data.data.pageTotal
                this.setState({
                    ulshow: false,
                    loading: false,
                    dataSource: data.data.items,
                    pageTotal: data.pageTotal,
                    pagination,
                    loading: false
                })
            }
        } else {
        }
    }
    modifyClickShow = (data) => {
        this.setState({
            modify: true,
            modifyData: data
        })
    }
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectShipBlackListObscure()
        })
    }

    // 修改船舶详情
    async updateShipBlackListInfo(obj) {
        console.log(obj, 'oooooooooo')
        let data = await updateShipBlackListInfo(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.setState({
                modify: false
            })
            message.success("修改成功")
        } else if (data.code == 500) {
            message.success("修改失败")
        }
        this.setState({
            deleteShow: false,
        }, () => {
            this.selectShipBlackListObscure()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }
    // 新增船
    async insertShipMonitorInfo(obj) {
        obj.monitorType = 302300
        let data = await insertShipMonitorInfo(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("新增成功")
            this.setState({
                addShow: false
            })
        } else if (data.code == 500) {
            message.error("船舶不存在或者船舶已经在黑名单中")
            this.setState({
                addShow: true
            })
        }
        this.setState({
            deleteShow: false,
        }, () => {
            this.selectShipBlackListObscure()
        })
    }
    Labelmanagement = (data) => {
        this.setState({
            Label: true,
            labelData: data
        })
    }
    deleteClickShow = (data) => {
        console.log(data)
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 删除船
    async deleteShipBlackListInfo() {
        let { deleteData } = this.state;
        let params = {
            id: deleteData.id,
        }
        console.log(deleteData, params, 'params')
        let data = await deleteShipBlackListInfo(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectShipBlackListObscure()
        } else if (data.code == 500) {
            message.error("删除失败")
        }
        this.setState({
            deleteShow: false,
        })
    }
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            modify: false,
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            // pagination: { ...pagination }
        }, () => {
            // this.selectShipBlackListObscure()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }
    getNewShipData = (value) => {
        let { modifyData } = this.state;
        for (let i in value) {
            if (typeof (value[i]) == 'object') {
                value[i] = value[i]._i
            }
        }
        value.objectInfoId = modifyData.objectInfoId
        this.updateShipBlackListInfo(value)
        console.log(value, 'getNewShipData')
    }
    render() {
        let { dataSource, modifyData, ualArr, ulshow, LabelSize, labelData, ulArr, objectNameArr } = this.state;
        let ullAlls = this.state.ualArr && this.state.ualArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        console.log(objectNameArr, 'objectNameArr')

        const columns = [
            {
                title: '船名',
                key: 'shipName',
                dataIndex: 'shipName',
            },
            {
                title: '黑名单原因',
                key: 'remark',
                dataIndex: 'remark',
            },
            {
                title: '管理员',
                key: 'creatorName',
                dataIndex: 'creatorName',
            },
            {
                title: '时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
                sorter: (a, b) => {
                    if (a.gmtCreate > b.gmtCreate) {
                        return 1;
                    } else if (a.gmtCreate < b.gmtCreate) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '操作',
                key: 'fool',
                width: 200,
                render: (text, record) => {
                    return <div style={{}}>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.modifyClickShow(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>
                }
            },
        ]
        const modifyList = [


            {
                label: '黑名单原因', //表单label
                id: 'remark', //表单Item的Key值
                component: <TextArea placeholder={'请输入'} maxlength={30} style={{ height: 20 }} />,

            },
        ]
        const formItemList = [
            {
                label: '船舶名称', //表单label
                id: 'shipName', //表单Item的Key值
                component: <Select placeholder={'请选择'}
                    showSearch
                    optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        objectNameArr && objectNameArr.map((item) => (
                            <Select.Option value={item.objectName} >{item.objectName}</Select.Option>
                        ))
                    }
                </Select>,
                options: { rules: [{ required: true, message: '船舶名称不能为空!' }] }
            },
            {
                label: '拉黑原因',
                id: 'remark',
                options: {
                    rules: [
                        {
                            required: true,
                            message: '请输入黑名单原因！',
                        },
                        {
                            max: 30,
                            message: '不许超过30！！！',
                        },
                    ],
                },
                component:
                    <TextArea
                        placeholder={'请输入'}
                        style={{ height: 10 }}
                        allowClear={true}
                        autoSize={{
                            minRows: 3,
                            maxRows: 5,
                        }}
                        maxLength={30}
                    />,
            }]
        return (
            <div className="contentBox">
                <div style={{ display: 'flex' }}>
                    <Input
                        value={this.state.name}
                        className='Searchvalue'
                        onChange={value => this.controlList(value)}
                        onPressEnter={() => this.selectShipBlackListObscure()}
                        placeholder="请输入船名" />
                    <SearchTop className="SearchTopvalue" onclick={this.addClickShow} onSearch={this.getSearchValue} />
                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div>
                    <Table
                        columns={columns}
                        dataSource={this.state.dataSource}
                        loading={this.state.loading}
                        pagination={{
                          defaultPageSize: 10,
                          showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
                        //   showSizeChanger: true,
                          showQuickJumper: true,
                        }}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={formItemList}
                    closeModal={this.closeModal}
                    submit={this.insertShipMonitorInfo}
                    title="新增"
                />
                {/* {
                    this.state.modify&&<div className="modifyShipData">
                        <h3>修改</h3>
                        <Form formItemList={modifyList} cancel={true} submit={this.getNewShipData} closeModal={this.closeModal}/>
                    </div>
                } */}
                <ModalForm
                    visible={this.state.modify}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    submit={this.getNewShipData}
                    title="修改"
                />
                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    onOk={this.deleteShipBlackListInfo}
                    title="删除提醒"

                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.shipName}"数据删除后不可恢复，确认删除吗？`}</span>
                </Modal>
            </div>
        )
    }
}
