// 告警类型
import React, { Component } from 'react'
import './css/system.css'
import SearchTop from './components/seachTop/SeachTop'
import AddFormItem from './components/addFormItem/AddFormItem'
import { Table, Button, Modal, Input, Icon, Select, message, DatePicker, Upload } from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import { exportExcel } from 'xlsx-oc';
import Form from '../../componts/form/Form'
import moment from 'moment'

import {
    selectDeviceTypeList,
    deleteIICardByIds,
    getArgs,
    addMenu,
    updateMenu,
    exportShipTemplate,
    deleteMenu,
    updateIICardDetail,
    bindShipDevice ,
    importBd2Cards
} from '../../data/dataStore'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
let format = 'YYYY-MM-DD HH:mm:ss';
class AlarmRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addShow: false,
            deleteData: {},
            selectedKeys: [],
            menuName:'',
            taskNum:'',
            Label: false,
            ulshow: false,
            modifyShow: false,

            shipOrDeviceId: '',
            dataSource: [],
            userGroupData: [],
            pageTotal: '',
            pageNo: 1,
            pageSize: 10,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            loading: false,
            labelSize: 1,
            selectMenuDataObj: {

            },
        }
        this.deleteMenu = this.deleteMenu.bind(this)
        this.selectDeviceTypeList = this.selectDeviceTypeList.bind(this)
        this.addMenu = this.addMenu.bind(this)
        this.updateMenu = this.updateMenu.bind(this)
        // this.bindShipDevice = this.bindShipDevice.bind(this)
    }
    componentDidMount() {
        //查询设备类型-列表
        this.selectDeviceTypeList()
    }


    //input统一赋值法
    _changeValue(e){
        this.setState({
            [e.target.name] : e.target.value
            })
        }
    //点击搜索列表的事件
    clickSearchDatavalue = (names) => {
        this.selectDeviceTypeList(names)
        this.setState({
            menuName: names
        })
    }

    // 分页查询
    async selectDeviceTypeList(value, type, pageSizeApi, coloums,id) {
        // this.setState({ loading: true })
        let { pagination, menuName, cardNumber, taskNum,pageTotal } = this.state;
        let params = {
            // deviceName: deviceName,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
        }
        console.log('11122222-----'+JSON.stringify(params))
        let data = await selectDeviceTypeList(params)

            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            let selectedKeys = []
            let newData = this.setKey(data.rows);
            selectedKeys = this.getdefSelectKey(data.rows)
            if (type === 'select') {
                this.setState({
                        UallArr: newData,
                        ulshow: true,
                        selectedKeys
                    }

                )
            } else {
                this.setState({
                        //给dataSource赋值
                        dataSource: newData,
                        userGroupData: newData,
                        UallArr: newData,
                        ulshow: false,
                        selectedKeys,
                        pageTotal: data.total
                    }

                )
            }

        } else if (data && data.code == 505) {
            this.props.history.push('/login')
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }
    getdefSelectKey = (data) => {
        let arr = []
        console.log(data)
        data.forEach((item) => {
            item.key = item.id
            if (item.hasAcl) {
                arr.push(item.id)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return arr
    }
    //导出
    // exportDefaultExcel = (e, coloums) => {
    //     let dockInfoData = this.state.dataSource;
    //     let headers = [];
    //     coloums.map((item, index) => {
    //         let kv = {
    //             k: item.dataIndex ? item.dataIndex : item.key,
    //             v: item.title
    //         }
    //         headers = headers.concat(kv);
    //     })
    //     if (dockInfoData && dockInfoData.length > 0) {
    //         exportExcel(headers, dockInfoData, '航次统计报表.xlsx');
    //     }
    //     else {
    //         message.error('导出数据失败！！！');
    //     }
    // }
    //搜索数据
    getSearchValue = (value) => {
        this.setState({
            shipName: value
        }, () => {
            this.selectDeviceTypeList()
        })
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

    // // 绑定设备
    // async bindShipDevice(arr) {
    //     let { labelData } = this.state;
    //     let params = [];
    //     console.log(arr, 'oooooooooo')
    //     arr.forEach((val) => {
    //         if (val) {
    //             params.push({
    //                 objectName: labelData.shipName,
    //                 objectId: labelData.objectInfoId,
    //                 deviceId: val.deviceId,
    //                 deviceType: val.deviceType,
    //                 cardNumType: val.cardNumType,
    //             })
    //         }
    //
    //     })
    //     console.log(params, 'params')
    //     let data = await bindShipDevice(params)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data && data.code == 200) {
    //
    //         message.success("绑定成功")
    //         this.selectDeviceTypeList()
    //     } else {
    //         message.error(data.msg)
    //     }
    //     this.setState({
    //         Label: false,
    //     })
    // }
    // //导出默认Excel的api
    // exportDefaultExcelApi = (e, coloums) => {
    //     this.selectDeviceTypeList(undefined, undefined, 9999999, coloums)
    // }
    // //导出
    // exportDefaultExcel = (e, coloums) => {
    //
    //     let dockInfoData = this.state.AlldataSource;
    //     delete coloums[7]
    //     if (dockInfoData) {
    //         dockInfoData.map((items) => {
    //             if (this.state.shipTypeArr) {
    //                 this.state.shipTypeArr.map((item) => {
    //                     if (item.value == items.shipType) {
    //                         items.shipType = item.text
    //                     }
    //                 })
    //             }
    //         })
    //     }
    //     let headers = [];
    //     coloums.map((item, index) => {
    //         let kv = {
    //             k: item.dataIndex ? item.dataIndex : item.key,
    //             v: item.title
    //         }
    //         headers = headers.concat(kv);
    //     })
    //     if (dockInfoData && dockInfoData.length > 0) {
    //         exportExcel(headers, dockInfoData, '船舶管理.xlsx');
    //     }
    //     else {
    //         message.error('导出数据失败！！！');
    //     }
    // }
    // // 修改II北斗详情
    // async updateIICardDetail(obj) {
    //     console.log(obj, 'oooooooooo')
    //     let data = await updateIICardDetail(obj)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data && data.code == 200) {
    //         this.setState({
    //             modifyShow: false,
    //         })
    //         this.selectDeviceTypeList()
    //         message.success("修改成功")
    //     } else if (data.code == 500) {
    //         message.error(data.message)
    //     }
    //     this.setState({
    //         deleteShow: false,
    //     })
    // }
    // // 导入北斗II卡
    // async importBd2Cards(fileData) {
    //     this.state.loading=true;
    //     let formData = new FormData()
    //     formData.append("file", fileData)
    //     // console.log(params,'params')
    //     let data = await importBd2Cards(formData)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data && data.code == 200) {
    //
    //         message.success("上传成功")
    //     } else {
    //         message.error(data.msg)
    //     }
    //     this.setState({
    //         deleteShow: false,
    //         loading: false
    //     })
    // }
    // Labelmanagement = (data) => {
    //     console.log(data, 'shipName1111')
    //     this.setState({
    //         Label: true,
    //         labelData: data
    //     }, () => {
    //         console.log(this.state.labelData, 'shipName1')
    //     })
    // }
    modify = (data) => {
        // console.log('gogogo-----'+JSON.stringify(data))
        this.setState({
            modifyShow: true,
            modifyData: data,
        })
    }
    deleteClickShow = (data) => {
        this.setState({
            deleteShow: true,
            deleteData: data
        })
    }
    // 新增菜单
    async addMenu(obj, callback) {
        obj.aliasName = obj.shipName
        let data = await addMenu(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            this.selectDeviceTypeList()
            message.success("新增成功")
            callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败："+data.message)
        }
        this.setState({
            addShow: false,
            deleteShow: false,
        })
    }
    // 删除菜单
    async deleteMenu() {
        let { deleteData } = this.state;
        console.log("========================d当前删除的 ID是-------------------"+deleteData.id)
        // console.log(deleteData, 'deleteData')
        let params = {
            id: deleteData.id,
        }
        // console.log(deleteData, params, 'params')
        let data = await deleteMenu(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("删除成功")
            this.selectDeviceTypeList()
        } else if (data.code == 500) {
            message.error("删除失败："+data.message)
        }
        this.setState({
            deleteShow: false,
        })
    }

        //菜单修改
    async updateMenu(obj) {
        let { modifyData } = this.state;
        obj.id = modifyData.id
        // console.log('modify----'+JSON.stringify(obj))
        let data = await updateMenu(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            message.success("修改成功")
            this.selectDeviceTypeList()
        } else if (data.code == 500) {
            message.error("修改失败："+data.message)
        }
        this.setState({
            modifyShow: false,
        })
    }

    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            Label: false,
            modifyShow: false,
        })
    }
    // exportDefault = () => {
    //     this.setState({
    //     }, () => {
    //         this.exportShipTemplate()
    //     })
    // }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectDeviceTypeList()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }

    // // 获取文件后缀
    // getFileSuffix = name => {
    //     const index = name.lastIndexOf(".");
    //     const len = name.length;
    //     return name.substring(index, len) || "-";
    // };

    // beforeUpload = file => {
    //     const suffix = this.getFileSuffix(file.name);
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     const isValid = suffix === ".xlsx" || suffix === ".xls";
    //     if (!isValid) {
    //         message.warn("上传失败，请选择.xlsx或.xls格式");
    //     }
    //     //   if (!isLt2M) {
    //     //     message.warn("上传失败，大小不能超过2M");
    //     //   }
    //     return isValid;
    // }
    // //筛选
    // getColumnSearchProps = () => ({
    //     filterDropdown: () => {
    //         let { objectNameArr } = this.state
    //         return <div style={{ padding: 8, display: 'block' }}>
    //             <div>
    //                 <Select style={{ width: 100 }} placeholder={'请选择'}
    //                         showSearch
    //                         multiple
    //                 >
    //                     {
    //                         objectNameArr && objectNameArr.regionNames.map((item) => (
    //                             <Option
    //                                 onClick={this.clickSearchData1.bind(this, item)}
    //                                 value={item} >{item}</Option>
    //                         ))
    //                     }
    //                 </Select>,
    //             </div>
    //             <div>
    //             </div>
    //         </div>
    //     }
    // });
    //点击搜索
    clickSearchData1 = (value) => {
        this.setState({
            regionNameValue: value,
        }, () => {
            this.selectDeviceTypeList()
        });
    }
    //回车搜索
    onKeyup = (e) => {
        // this.selectDeviceTypeList()
    }
    //用户分配用户组的选中
    // distribute_onChage = (selectedRowKeys) => {
    //     let keyArr = this.getSelectKey(this.state.userGroupData, selectedRowKeys)
    //     console.log("+++++++++++++590++++++++++++"+keyArr);
    //     this.setState({
    //         selectedKeys: keyArr
    //     })
    // }

    getSelectKey = (data, key) => {
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }
    // // 批量注销北斗卡
    // async deleteIICardByIds() {
    //     let { selectedKeys } = this.state
    //     console.log("___________________"+selectedKeys)
    //     let params = {
    //         ids: selectedKeys
    //     }
    //     console.log("....646......"+selectedKeys);
    //     let data = await deleteIICardByIds(params)
    //         .then(res => {
    //             return res.json();
    //         })
    //         .catch(ex => { });
    //     if (data && data.code == 200) {
    //         message.success("删除成功")
    //         this.setState({
    //             deleteAll: false,
    //         })
    //         this.selectDeviceTypeList()
    //     } else {
    //         message.error("删除失败："+data.message)
    //     }
    // }
    // deleteAllShow = (data) => {
    //     let { selectedRowKeys } = this.state;
    //     if (selectedRowKeys) {
    //         this.setState({
    //             deleteAll: true,
    //         })
    //
    //     } else {
    //         message.warning("请先选择要删除的数据！")
    //     }
    // }


    render() {
        let { dataSource, modifyData,nowModalData,userGroupData,selectedKeys,deleteAll, UallArr, LabelSize, ulshow, labelData, shipTypeArr,selectMenuDataObj = {} ,pageTotal} = this.state;
        console.log(modifyData, 'modifyData')
        let ullAlls = this.state.UallArr && this.state.UallArr.map((item, index) => (item.shipName))
        let ullAllss = [...new Set(ullAlls)];
        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };
        const columns = [
             {
                title: '规则名称',
                key: 'ruleName',
                dataIndex: 'ruleName',
                ellipsis: true,
            },
            {
                title: '区域名',
                key: 'protocol',
                dataIndex: 'protocol',
            },
            {
                title:'告警时间',
                key:'factory',
                dataIndex:'factory',
            },
            {
                title: '优先度',
                key: 'priority',
                dataIndex: 'priority',
            },
            {
                title: '启用状态',
                key: 'network',
                dataIndex: 'network',
            },

            {
                title: '备注',
                key: 'remark',
                dataIndex: 'remark',
            },

            {
                title: '操作',
                key: 'detele',
                width: 300,
                render: (text, record) => {
                    return <div>

                        <span style={{ color: '#1890FF', cursor: 'pointer', margin: '0 10px' }} onClick={() => this.modify(record)}>修改</span>
                        <span style={{ color: '#DCDCDC', margin: '0 10px' }}>|</span>
                        <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => this.deleteClickShow(record)}>删除</span>
                    </div>
                },
            },
        ]
        //添加对话框
        const addFormItemList = [
            {
                label: '菜单名称', //表单label
                id: 'menuName', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '菜单名称不能为空!' }] }
            },
            {
                label: '菜单编码', //表单label
                id: 'menuCode', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '菜单编码不能为空!' }] }
            },
            {
                label: '上级编码', //表单label
                id: 'parentCode', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },
            // {
            //     label: '菜单类型', //表单label
            //     id: 'type', //表单Item的Key值shipTypeArr
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            //
            // },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
            },
         ]
        const modifyList = [
            {
                label: '菜单名', //表单label
                id: 'menuName', //表单Item的Key值
                component: <Input placeholder={'请输入'} readOnly/>, //表单受控组件
                options: { initialValue: modifyData && modifyData.menuName }
            },
            {
                label: '菜单编码', //表单label
                id: 'menuCode', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.menuCode }
            },
            {
                label: '上级编码', //表单label
                id: 'parentCode', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.parentCode }

            },
            // {
            //     label: '状态', //表单label
            //     id: 'status', //表单Item的Key值
            //     component: <Input placeholder={'请输入'} />, //表单受控组件
            //     options: { initialValue: modifyData && modifyData.status }
            // },
            {
                label: '备注', //表单label
                id: 'remark', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { initialValue: modifyData && modifyData.remark }
            },

        ]


        const props = {

            beforeUpload: file => {
                this.importBd2Cards(file)
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };

        return (
            <div className='menuConfig contentBox'>
                <div className="othersManagement_title menuTitle">
                    <div className="cardManagement_title_leftVerticalBar"/>
                    <div className="cardManagement_title_text">告警规则</div>
                    <div className="cardManagement_title_rightLine"/>
                    <div className="noLineStyleManagement_title_menuSelect">
                        <div className="cardManagement_title_menuSelect_part">
                            <div className="cardManagement_title_menuSelect_part_div">
                                设备名称：
                                <Input placeholder="请输入" style={{width: '70%'}} name='menuName' onChange={value => this._changeValue(value)}/>
                            </div>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    backgroundColor: '#81BEF8',
                                    border: 'unset'
                                }}
                                onClick={() => this.selectDeviceTypeList()}
                            >查询</Button>


                        </div>
                        <div className="cardManagement_title_menuSelect_part4">
                            <Button
                                type={'primary'}
                                style={{
                                    width: 100,
                                    left:30,
                                    backgroundColor: '#FE7247',
                                    border: 'unset'
                                }}

                            >重置</Button>

                        </div>

                        <div className="cardManagement_title_menuSelect_part4" style={{marginTop:0,marginLeft:50}}>
                            <SearchTop
                                adding="true"
                                onclick={this.addClickShow}
                            />

                        </div>


                    </div>

                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            ullAllss && ullAllss.map((item, index) => (
                                <li
                                    key={item.index}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item}</span>
                                </li>
                            ))
                        }
                    </ul>
                }

                <div className="cardIIManagement">
                    {/*
                    //
                    //
                    //列表显示数据
                    //
                    //
                    //

                    */}
                    <Table
                        columns={columns}
                        // rowSelection={distribute_rowSelect}
                        dataSource={nowModalData ? nowModalData : userGroupData}
                        //设置table内部滚动
                        scroll={{y:515}}
                        //此行代码用以解决唯一key值问题
                        rowKey={record=>record.key}
                        pagination={{
                            ...this.state.pagination,
                            // position: ['bottomCenter'],
                            // showQuickJumper: true,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectDeviceTypeList(selectMenuDataObj);
                                })
                            }
                        }}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={addFormItemList}
                    closeModal={this.closeModal}
                    submit={this.addMenu}
                    title="新增"
                />
                <ModalForm
                    visible={this.state.modifyShow}
                    formItemList={modifyList}
                    closeModal={this.closeModal}
                    submit={this.updateMenu}
                    title="修改"
                />

                <Modal
                    visible={this.state.deleteShow}
                    onCancel={this.closeModal}
                    title="删除提醒"
                    onOk={this.deleteMenu}
                >
                    <Icon type="question-circle" style={{ color: '#F19149', fontSize: 30, marginRight: 10 }} />
                    <span>{`"${this.state.deleteData.menuName}"菜单将要被删除，删后不可恢复，确认删除吗？`}</span>
                </Modal>
                <Modal
                    visible={this.state.Label}
                    onCancel={this.closeModal}
                    title="标签管理"
                    className="labelModal"
                    footer={false}
                    destroyOnClose
                    width={600}
                >
                    <AddFormItem data={this.state.labelData} onOk={this.bindShipDevice} onClose={this.closeModal} />
                </Modal>
            </div>
        )
    }
}
export default AlarmRule