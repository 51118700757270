// 维修记录
import React, { Component } from 'react'
import './css/system.css'
import {Table, Input, Modal, Icon, Select, message, Button, Upload,Divider} from 'antd';
import ModalForm from '../../componts/ModalForm/index'
import {

    updateLoginCount,
    getBdFaultLastLog,
    getBdFaultLog,
    addRepairLog, importRepairLog,
} from '../../data/dataStore'
import moment from 'moment';
// 引入jQuery
import $ from 'jquery'
const { TextArea, Search } = Input;

const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1733642_5j6blefdkn7.js'
})
class RepairLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShow: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            deleteData: {},
            ulshow: false,
            selectedKeys: [],
            userName: '',
            cardNumber: '',
            id: '',
            name:'',
            status: 0,
            searchData: [],
            pasValue: '',
            modifyData: {},
            roleData: [],
            pageNo: 1,
            pageSize: 10,
            pageTotal: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
                showTotal: (total, range) => `当前 ${range[0]} 至 ${range[1]} 共 ${total} 条`,
            },
        }
        this.selectRepairLogList = this.selectRepairLogList.bind(this)
        this.addRepairLog = this.addRepairLog.bind(this)
    }
    componentDidMount() {
        this.selectRepairLogList()
        let user = JSON.parse(sessionStorage.getItem('isLogin'))
        this.setState({
            admin:user.data
        })
        // 去掉所有input的autocomplete,显示指定的除外
        $(function () {
            $('input:not([autocomplete]),textarea:not([autocomplete]),select:not([autocomplete])').attr('autocomplete', 'off');
        });
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pagination: { ...pagination }
        }, () => {
            this.selectRepairLogList()
        })
        console.log(pagination, filters, sorter, 'sorter')
    }

    //输入框为空时搜索列表不显示
    // controlList = (eve) => {
    //     console.log(eve, 'vvvvvv')
    //     if (!eve.target.value) {
    //         this.setState({
    //             ulshow: false,
    //             name: ''
    //         }, () => {
    //             this.selectRepairLogList()
    //         })
    //     } else {
    //         this.setState({
    //                 cardNumber: eve.target.value,
    //                     }, () => {
    //                         let     selectIICardDetails(params)
    //
    //                 .then(res => {
    //                     return res.json();
    //                 })
    //                 .catch(ex => { });
    //                     }
    //         )
    //     }
    // }
    //点击搜索列表的事件
    clickSearchDatavalue = (data) => {
        this.selectRepairLogList(data.name)
        this.setState({
            name: data.name
        })
    }

    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.roleId
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }

        // 分页查询
    async selectRepairLogList(value, type) {
        let { pagination } = this.state;
        let params = {
            cardNumber: this.state.cardNumber,
            status: this.state.status,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
        }
        console.log(params.status+'==========================')
        // 0 查最新数据
        if (params.status == 0) {
            let data = await getBdFaultLastLog(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => {
                });
            if (data && data.code == 200) {
                let selectedKeys = []
                let newData = this.setKey(data.rows);
                // selectedKeys = this.getBdFaultLastLog(data.rows)
                if (type === 'select') {
                    this.setState({
                            searchDataArr: newData,
                            ulshow: true,
                            selectedKeys
                        }
                    )
                } else {
                    this.setState({
                            //给dataSource赋值
                            dataSource: newData,
                            // userGroupData: newData,
                            searchData: newData,
                            // ulshow: false,
                            selectedKeys,
                            pageTotal: data.total
                        }
                    )
                }

            } else if (data && data.code == 505) {
                this.props.history.push('/login')
            }
        //     1查历史数据
        }else {
            let data = await getBdFaultLog(params)
                .then(res => {
                    return res.json();
                })
                .catch(ex => {
                });
            if (data && data.code == 200) {
                let selectedKeys = []
                let newData = this.setKey(data.rows);
                // selectedKeys = this.getBdFaultLastLog(data.rows)
                if (type === 'select') {
                    this.setState({
                            searchDataArr: newData,
                            ulshow: true,
                            selectedKeys
                        }
                    )
                } else {
                    this.setState({
                            //给dataSource赋值
                            dataSource: newData,
                            userGroupData: newData,
                            searchData: newData,
                            ulshow: false,
                            selectedKeys,
                            pageTotal: data.total
                        }
                    )
                }

            } else if (data && data.code == 505) {
                this.props.history.push('/login')
            }
        }
    }
    setKey = (data) => {
        data.forEach((item) => {
            item.key = item.id
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        return data
    }




    getdefSelectKey = (data) => {
        console.log("<<<",data)
        let arr = []
        data.forEach((item) => {
            item.key = item.roleId
            if (item.check) {
                arr.push(item.roleId)
            }
            let keyArr = Object.keys(item)
            for (let j = 0; j < keyArr.length; j++) {
                if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                    this.setKey(item[keyArr[j]])
                }
            }
        })
        console.log(">>>",arr)
        return arr
    }
    addClickShow = () => {
        this.setState({
            addShow: true
        })
    }

        // 新增维修类型
    async addRepairLog(obj, callback) {
        console.log(obj.toString())
        let data = await addRepairLog(obj)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {
            // this.selectRepairLogList(obj)
            message.success("新增成功")
            this.setState({
                addShow: false,
            })
            // callback.onSuccess()
        } else if (data.code == 500) {
            message.error("新增失败："+data.message)
        }

    }
    // 点击表格修改事件
    modifyData = (data) => {
        this.setState({
            modifyShow: true,
            modifyData: data
        })
    }

    // 点击表格解锁事件
    async updateLoginCount(user){
        let {admin}=this.state;
        console.log(admin,user,'dddddd')
        let params={
            id:user.name,
            adminId:admin.name
        }
        let data= await updateLoginCount(params)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if(data&&data.code==200){
            message.success(data.message)
        }else{
            message.error(data.message)
        }
    }
    // 点击表格管理的事件
    clickManage = (data) => {
        //this.getUgroupTree(data.id)
        console.log(">>>",">>>"+JSON.stringify(data))
        this.getRoleList(data.userId)
        this.setState({
            manageShow: true,
            manageData: data
        })
    }
    //关闭对话框按钮
    closeModal = () => {
        this.setState({
            addShow: false,
            deleteShow: false,
            resetShow: false,
            modifyShow: false,
            manageShow: false,
        })
    }

   // controlList = (eve) => {
   //      console.log(eve, 'vvvvvv')
   //      if (!eve.target.value) {
   //          this.setState({
   //              ulshow: false,
   //              cardNumber: ''
   //          }, () => {
   //              this.selectRepairLogList()
   //          })
   //      } else {
   //          this.setState({
   //                  cardNumber: eve.target.value,
   //              }, () => {
   //                  this.selectRepairLogList(this.state.cardNumber, 'select')
   //              }
   //          )
   //      }
   //  }

    // 导入
    async importRepairLog(fileData) {
         this.setState({ loading: true })
        let formData = new FormData()
        formData.append("file", fileData)
        // console.log(params,'params')
        let data = await importRepairLog(formData)
            .then(res => {
                return res.json();
            })
            .catch(ex => { });
        if (data && data.code == 200) {

            message.success(data.message)
            this.selectRepairLogList()
        } else {
            message.error(data.message)
        }
        this.setState({
            deleteShow: false,
            loading: false
        })
    }

    // 扁平化多重嵌套数组对象
    flatten = (arr) => {
        let myArr = []
        for (const item of arr) {
            myArr.push(item);
            if (item.children && item.children.length > 0) {
                myArr.push(...this.flatten(item.children))
            }
        }
        return myArr
    }
    // 模糊搜索
    getSearchValue = (data, value) => {
        let dataSource = this.flatten(data)
        let newArr;
        if (value) {
            newArr = dataSource.filter((item) => {
                console.log(value, item.name.indexOf(value), 'item')
                return item.name.indexOf(value) != -1
            })
        } else {
            newArr = data
        }

        this.setState({
            nowModalData: newArr
        })
    }
    getSelectKey = (data, key) => {
        console.log('-----------------------checking------------------------------'+JSON.stringify(data),JSON.stringify(key))
        let arr = [...key]
        let keyArr = []
        data.forEach((item) => {
            if (!item.click) {
                key.forEach((val) => {
                    if (item.id == val) {
                        item.click = true;

                        keyArr = Object.keys(item)
                        for (let j = 0; j < keyArr.length; j++) {
                            if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                                let newdata = this.flatten(item[keyArr[j]])
                                newdata.forEach((i) => {
                                    arr.push(i.id)
                                })
                            }
                        }
                    }
                })
            } else {
                keyArr = Object.keys(item)
                for (let j = 0; j < keyArr.length; j++) {
                    if (Array.isArray(item[keyArr[j]]) && item[keyArr[j]].length > 0) {
                        let newdata = this.flatten(item[keyArr[j]])
                        newdata.forEach((i) => {
                            arr.push(i.id)
                            arr = arr.filter((val) => val != i.id)
                        })
                        item.click = false
                    }
                }
            }
        })
        return arr
    }

    handleChange = (value) => {
      console.log(`selected ${value}`);
            this.setState({
                status: value,
            })
    };
        //input统一赋值法
    _changeValue(e){
        console.log('ee======'+e.target.value);
        this.setState({
            [e.target.name] : e.target.value
            })
        };

    render() {
        let { searchData, modifyData, searchDataArr, nowModalData, ulshow, selectedKeys, roleData, pageTotal ,importRepairLog} = this.state
        const secretKey = '123456789';

        const distribute_rowSelect = {
            selectedRowKeys: selectedKeys,
            onChange: this.distribute_onChage,
        };

        //用户类型下拉框
        let searchStatus = [{value:"0",text:"最新数据"},{value:"1",text:"历史数据"}]


        //列表字段
        const columns = [
           {
                title: '北斗卡号',
                key: 'bdnumber',
                dataIndex: 'bdnumber',
                ellipsis: true,
            },
            {
                title: '故障类型',
                key: 'faultType',
                dataIndex: 'faultType',
            },
            {
                title:'故障原因',
                key:'faultReason',
                dataIndex:'faultReason',
            },
            {
                title:'故障结果',
                key:'status',
                dataIndex:'status',
            },
            {
                title: '备注',
                key: 'ramark',
                dataIndex: 'ramark',
            },
            {
                title: '录入时间',
                key: 'gmtCreate',
                dataIndex: 'gmtCreate',
                render: (gmtCreate) => {
                    let time = moment(parseInt(gmtCreate)).format('YYYY-MM-DD HH:mm:ss')
                    return time;

                },
            },
            {
                title: '录入账号',
                key: 'account',
                dataIndex: 'account',

            },


        ]

        const props = {

            beforeUpload: file => {
                this.importRepairLog(file)
                return false;
            },
            // fileList,
            onChange: this.onChangeupload,
        };

        //添加用户的表单
        const addFormItemList = [
             {
                label: '北斗卡号', //表单label
                id: 'bdnumber', //表单Item的Key值
                component: <Input placeholder={'请输入'} />, //表单受控组件
                options: { rules: [{ required: true, message: '北斗卡号不能为空!' }] }
            },

            {
                label: '故障类型：',
                id: 'faultType',
                component: <Select style={{ width: 200 }} placeholder={'请选择'}>
                    {
                        <Select.Option value={'无数据'} >无数据</Select.Option>
                    }
                    {
                        <Select.Option value={'回报成功率低0%~30%'} >回报成功率低0%~30%</Select.Option>
                    }
                    {
                        <Select.Option value={'回报成功率低30%~70%'} >回报成功率低30%~70%</Select.Option>
                    }
                    {
                        <Select.Option value={'电池电量问题'} >电池电量问题</Select.Option>
                    }
                    {
                        <Select.Option value={'报警问题'} >报警问题</Select.Option>
                    }
                    {
                        <Select.Option value={'定位无效'} >定位无效</Select.Option>
                    }
                    {
                        <Select.Option value={'推送问题'} >推送问题</Select.Option>
                    }
                    {
                        <Select.Option value={'其他问题'} >其他问题</Select.Option>
                    }
                </Select>,
                options:  { rules: [{ required: true, message: '不能为空!' }] }
            },

            {
                label: '故障原因：',
                id: 'faultReason',
                component: <Select style={{ width: 200 }} placeholder={'请选择'} >
                    {
                        <Select.Option value={'设备被遮挡'} >设备被遮挡</Select.Option>
                    }
                                        {
                        <Select.Option value={'设备被拆卸'} >设备被拆卸</Select.Option>
                    }
                                        {
                        <Select.Option value={'设备倾斜'} >设备倾斜</Select.Option>
                    }
                                        {
                        <Select.Option value={'北斗卡故障'} >北斗卡故障</Select.Option>
                    }

                    {
                        <Select.Option value={'设备故障需维修'} >设备故障需维修</Select.Option>
                    }
                    {
                        <Select.Option value={'电池电量低'} >电池电量低</Select.Option>
                    }
                    {
                        <Select.Option value={'太阳能不充电'} >太阳能不充电</Select.Option>
                    }
                    {
                        <Select.Option value={'显示屏故障'} >显示屏故障</Select.Option>
                    }
                    {
                        <Select.Option value={'线缆问题'} >线缆问题</Select.Option>
                    }
                    {
                        <Select.Option value={'设备进水'} >设备进水</Select.Option>
                    }
                    {
                        <Select.Option value={'其他情况'} >其他情况</Select.Option>
                    }

                </Select>,
                options:  { rules: [{ required: true, message: '不能为空!' }] }
            },

            {
                label: '处理结果：',
                id: 'status',
                component: <Select style={{ width: 200 }} placeholder={'请选择'}>
                    {
                        <Select.Option value={'已解决'} >已解决</Select.Option>
                    }
                    {
                        <Select.Option value={'已解决，无需维修'} >已解决，无需维修</Select.Option>
                    }
                    {
                        <Select.Option value={'未解决，需要维修'} >未解决，需要维修</Select.Option>
                    }
                    {
                        <Select.Option value={'待核实'} >待核实</Select.Option>
                    }
                    {
                        <Select.Option value={'其他情况'} >其他情况</Select.Option>
                    }
                </Select>,
                options:  { rules: [{ required: true, message: '不能为空!' }] }
            },

            {
                label: '备注', //表单label
                id: 'ramark', //表单Item的Key值shipTypeArr
                component: <Input placeholder={'请输入'} />, //表单受控组件

            },

        ]


        return (
            <div className='shipmonitoring contentBox'>
                <div>
                    <div>
                    <label style={{width:150,marginTop:50,marginLeft:10}}>北斗卡号: </label>
                    <Input
                        style={{ display: 'inline', width: 215,marginLeft:10,marginBottom:10}}
                        value={this.state.cardNumber}
                        className='Searchvalue'
                        name='cardNumber'
                        onChange={value => this._changeValue(value)}
                        placeholder="请输入北斗卡号" />

                     <label style={{width:150,marginTop:50,marginLeft:10}}>查询状态: </label>
                     <Select
                                placeholder={'请选择'}
                                style={ {width:150, marginLeft:10,marginBottom:10}}
                                name='status'
                                defaultValue="0"
                                onChange={value => this.handleChange(value)} >
                                {
                                    searchStatus.map((item) => (
                                        <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>
                                    ))
                                }
                    </Select>

                    <Button type='primary' style={{ position: 'absolute', right: 280 }} onClick={() => this.selectRepairLogList()}>查询</Button>

                    <Button
                        style={{ position: 'absolute',
                            right: 200,
                            color:"white",
                        backgroundColor: '#FE7247',
                        border: 'unset' }}
                             onClick={() => {
                                 this.setState({
                                     cardNumber: '',
                                 }, () => {
                                     this.selectRepairLogList()
                                 })
                             }}
                    >重置</Button>

                    <Button onClick={() => this.addClickShow()} type="primary" style={{ position: 'absolute', right: 120 }}>新增</Button>

                      <Upload
                            {...props}
                            style={{ position: 'absolute',
                            right: 360, }}
                        >
                            <Button
                                style={{ position: 'absolute',
                            right: 360 }}
                                className='UploadOutlinedStyle'
                            >
                                导入
                    </Button>
                        </Upload>
                    </div>
                </div>
                {
                    ulshow && <ul className="searchList">
                        {
                            this.state.searchDataArr && this.state.searchDataArr.map((item, index) => (
                                <li
                                    key={item.id}
                                    onClick={this.clickSearchDatavalue.bind(this, item)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: 16,
                                        display: 'flex',
                                        alignItems: "center",
                                    }}
                                ><MyIcon style={{ height: 12 }} type="icon-sousuo" /> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 14 }} >{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                }
                <div className="userManagement">
                    <Table
                        columns={columns}
                        dataSource={searchData}
                        scroll={{y:600}}
                        //此行代码用以解决唯一key值问题
                        rowKey={record=>record.id}
                        pagination={{
                            ...this.state.pagination,
                            showSizeChanger: true,//设置每页显示数据条数
                            //设置每页现实的数据量数---数组   里面一定要加单引号  只会显示数字
                            pageSizeOptions:['10','50','100','1000'],
                            total: pageTotal,
                            onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                                console.log(pageSize);
                                let { selectMenuDataObj = {} } = this.state;
                                selectMenuDataObj = {
                                    ...selectMenuDataObj,
                                    pageIndex: current,
                                    pageSize
                                }
                                this.setState({
                                    selectMenuDataObj, pageIndex: current, pageSize
                                }, () => {
                                    this.selectRepairLogList(selectMenuDataObj);
                                })
                            }
                        }}
                        onChange={this.handleTableChange}

                    />
                </div>
                <ModalForm
                    visible={this.state.addShow}
                    formItemList={addFormItemList}
                    closeModal={this.closeModal}
                    submit={this.addRepairLog}
                    title="新增"
                />


            </div>
        )
    }
}
export default RepairLog