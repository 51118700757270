import React from 'react';
import ReactDom from 'react-dom';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import './index.css';
import "antd/dist/antd.css";
import './assets/css/base.css';
import App from './App';
import store from './store/index'
import { Provider } from 'react-redux';
import { BrowserRouter, Route, HashRouter } from 'react-router-dom'
ReactDom.render(
    <Provider store={store}>
        {/* <LocaleProvider locale={zh_CN}> */}
        <HashRouter basename="/bd3">
            <Route component={App} />
        </HashRouter>
        {/* </LocaleProvider> */}
    </Provider>
    ,
    document.getElementById('root')
);

